import Axios from "axios";
import { axiosClient } from "../client";

const queryString = require("query-string");

const HARDWARE_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS}/core/modules/${locationId}/hardware`;

  const REVENUE_CENTER_REPORT_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/reports/sales/revenueCenter`;

export const fetchAllHardwareReportInfo = (locationId) => {
  const config = {
    method: "GET",
    url: HARDWARE_INFO_GET_ALL(locationId),
    headers: {},
  };

  return Axios.request(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchAllRevenueCenterInfo = (token, locationId, filterData, basedOnShift) => 
axiosClient({
  method: "GET",
  url: queryString.stringifyUrl({
    url: REVENUE_CENTER_REPORT_INFO_GET_ALL(locationId),
    query: {  where: filterData, based_on_shift: basedOnShift }}, {sort: false, arrayFormat: 'comma', skipNull: true, skipEmptyString: true},
    
  ),
  headers: {
    "X-Role-Location": locationId,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

