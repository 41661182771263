import _ from "lodash";
import moment, { Moment } from "moment";
import { MOMENT_MINUTES } from "./consts";

/* Here, when the open time is given in moment format, 
the number of minutes corresponding to that open time is returned.
ex:- Given 9.00 a.m as the open time, it return 540 minutes.  */
export const convertTimeToMinuteOfDay = (time: moment.Moment) =>
  moment.duration(time.format("HH:mm")).asMinutes();

/* When the number of hours and dates a given in the Moment format, 
the starting day of the week is taken as Sunday.
Then, the start date of the week corresponding to that date is returned in moment format.
ex:- Given 9.00 a.m as the open time and date is 02/22/2022(moment format), 
Then if this date is Tuesday, the return date is 02/20/2022(moment format).  */
const convertMinuteOfWeekToTime = (
  minuteOfWeek: number,
  timeNow: string | Moment,
) => {
  const weekStartSundayTime = moment(timeNow).startOf("week");
  const calculatedTime = moment(timeNow)
    .startOf("week")
    .add(minuteOfWeek, "minutes");

  if (calculatedTime.isDST() && !weekStartSundayTime.isDST()) {
    return calculatedTime.subtract(1, "hour");
  }

  if (!calculatedTime.isDST() && weekStartSundayTime.isDST()) {
    return calculatedTime.add(1, "hour");
  }
  return calculatedTime;
};

const convertTimeToMinuteOfWeek = (time: Moment) => {
  const mow = time.day() * 1440 + time.hours() * 60 + time.minutes();
  return mow;
};

interface OpeningTimeNode {
  minuteOfWeek: number;
  durationMins: number;
}

interface DayNode {
  day: string | undefined;
  openTime: number;
  closeTime: number;
}

// export const destructMinuteOfWeekArray = (
//   minuteOfWeekArray: Array<OpeningTimeNode>,
// ) => {
//   return minuteOfWeekArray.map((minuteOfWeekNode: OpeningTimeNode) => {
//     const closeTimeMinute =
//       minuteOfWeekNode.minuteOfWeek + minuteOfWeekNode.durationMins;
//     const dayNode: DayNode = {
//       day: getDayByMinuteOfWeek(minuteOfWeekNode.minuteOfWeek),
//       openTime: minuteOfWeekNode.minuteOfWeek,
//       closeTime:
//         closeTimeMinute <= 10080 ? closeTimeMinute : closeTimeMinute - 10080,
//     };
//     return dayNode;
//   });
// };

/* When the number of minutes ([1440, 4320]), start time, and end time is given in the moment format, 
Returns data closeTime, day, durationMins, minuteOfWeek, openTime.
durationMins is the number of hours per day and 
minutesOfWeek is the number of hours from Sunday to the relevant day. */
export const constructMinuteOfWeekArray = (
  selectedDays: Array<number>,
  openTime: moment.Moment,
  closeTime: moment.Moment,
) => {
  let constructedArr = selectedDays.map((dayStartMinute: number) => ({
    day: getDayByDayStartMinuteOfWeek(dayStartMinute),
    openTime: openTime.format("HH:mm"),
    closeTime: closeTime.format("HH:mm"),
    minuteOfWeek: dayStartMinute + convertTimeToMinuteOfDay(openTime),
    durationMins: closeTime.isAfter(openTime)
      ? closeTime.diff(openTime, MOMENT_MINUTES)
      : 1440 - Math.abs(closeTime.diff(openTime, MOMENT_MINUTES)),
  }));
  let finalArr: any = [];

  constructedArr.forEach((node) => {
    let updatedNode = { ...node };

    if (updatedNode.durationMins + updatedNode.minuteOfWeek > 10080) {
      const overflow =
        updatedNode.durationMins + updatedNode.minuteOfWeek - 10080;
      updatedNode.durationMins -= overflow;

      finalArr = [
        ...finalArr,
        {
          day: "sun",
          openTime: openTime.format("HH:mm"),
          closeTime: closeTime.format("HH:mm"),
          minuteOfWeek: 0,
          durationMins: overflow,
        },
      ];
    }
    finalArr = [...finalArr, updatedNode];
  });
  return finalArr;
};

/* input = [{openTime: '11:49', closeTime: '11:49', id: 2, 
             nodeList: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                        {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]}]
   output = {tue: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
             thu:  {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]} */
export const combineMinuteOfWeekArrays = (
  availabilityTimeSelectionList: Array<any>,
) => {
  const mowArrayList = availabilityTimeSelectionList.map(
    (node) => node.nodeList,
  );
  return _.groupBy(mowArrayList.flat(), (openingTimeMow) => openingTimeMow.day);
};

const isOpenCloseTimeIncludes = (
  openTime: string,
  closeTime: string,
  openCloseTimeList: Array<any>,
) => {
  let result = false;
  openCloseTimeList.forEach((node) => {
    if (node.openTime === openTime && node.closeTime === closeTime) {
      result = true;
    }
  });
  return result;
};

/* When the minuteOfWeekArray(closeTime: "", day: "", durationMins: , minuteOfWeek: , openTime: "") 
list is entered, the corresponding open time and close time are returned. */
export const getOpenCloseTimesList = (minuteOfWeekArray: Array<any>) => {
  let openCloseTimeList: any = [];
  minuteOfWeekArray.forEach((node: any) => {
    const isIncluded = isOpenCloseTimeIncludes(
      node.openTime,
      node.closeTime,
      openCloseTimeList,
    );
    if (!isIncluded) {
      openCloseTimeList = [
        ...openCloseTimeList,
        {
          openTime: node.openTime,
          closeTime: node.closeTime,
        },
      ];
    }
  });
  return openCloseTimeList;
};

/* When the minuteOfWeekArray and openCloseTimesList are entered, 
add the openCloseTimesList to a list and insert the 
minuteOfWeekArray into a nodeList in the same list. */
export const deconstructMinuteOfWeekArray = (
  minuteOfWeekArray: Array<any>,
  openCloseTimesList: Array<any>,
) => {
  let separatedArray = _.cloneDeep(openCloseTimesList);
  minuteOfWeekArray.forEach((node: any, idxId: number) => {
    const idx = separatedArray.findIndex(
      (openTimeArr: any) =>
        openTimeArr.openTime === node.openTime &&
        openTimeArr.closeTime === node.closeTime,
    );
    separatedArray[idx].id = idxId;
    if (!_.isEmpty(separatedArray[idx].nodeList)) {
      separatedArray[idx].nodeList = [...separatedArray[idx].nodeList, node];
    } else {
      separatedArray[idx].nodeList = [node];
    }
  });
  return separatedArray;
};

/* If the given date and time in one field match another field, 
then 2 fields are included in one field as they are not required. 
Therefore only one field is returned. */
export const normalizeTimeSelections = (dayMowList: Array<any>) => {
  let normalizedTimeList: any = [];

  const sortedDayMowList = _.orderBy(
    dayMowList,
    ["minuteOfWeek", "durationMins"],
    ["asc", "desc"],
  );

  let remainArray = _.cloneDeep(sortedDayMowList);

  while (remainArray.length) {
    const mainCompNode = remainArray[0];

    const compArray = _.cloneDeep(remainArray);
    compArray.shift();

    const openingMinute = mainCompNode.minuteOfWeek;
    const closingMinute = mainCompNode.minuteOfWeek + mainCompNode.durationMins;

    let earliestOpenTime = openingMinute;
    let latestCloseTime = closingMinute;

    compArray.forEach((node: any, idx: number) => {
      const compNodeOpeningMinute = node.minuteOfWeek;
      const compNodeClosingMinute = node.minuteOfWeek + node.durationMins;

      if (closingMinute >= compNodeOpeningMinute) {
        earliestOpenTime = openingMinute;
        latestCloseTime = Math.max(compNodeClosingMinute, closingMinute);
        _.remove(
          remainArray,
          (nodeRm) =>
            nodeRm.minuteOfWeek === node.minuteOfWeek &&
            nodeRm.durationMins === node.durationMins,
        );
      }
    });
    normalizedTimeList.push({
      day: mainCompNode.day,
      openTime: convertMinuteOfWeekToTime(earliestOpenTime, moment()).format(
        "HH:mm",
      ),
      closeTime: convertMinuteOfWeekToTime(latestCloseTime, moment()).format(
        "HH:mm",
      ),
      minuteOfWeek: earliestOpenTime,
      durationMins: latestCloseTime - earliestOpenTime,
    });
    _.remove(
      remainArray,
      (nodeRm) =>
        nodeRm.minuteOfWeek === mainCompNode.minuteOfWeek &&
        nodeRm.durationMins === mainCompNode.durationMins,
    );
  }
  return normalizedTimeList;
};

/* The date starts a Sunday at 12 a.m and there are 1440 minutes in a day.
Here the number of minutes per day is returned. */
export const getStartMinuteOfWeekByDay = (day: string) => {
  switch (day) {
    case "sun":
      return 0;
    case "mon":
      return 1440;
    case "tue":
      return 2880;
    case "wed":
      return 4320;
    case "thu":
      return 5760;
    case "fri":
      return 7200;
    case "sat":
      return 8640;
    default:
      break;
  }
};

/* When the number of minutes is given, the relevant date is returned. */
const getDayByDayStartMinuteOfWeek = (dayStartMinute: number) => {
  switch (dayStartMinute) {
    case 0:
      return "sun";
    case 1440:
      return "mon";
    case 2880:
      return "tue";
    case 4320:
      return "wed";
    case 5760:
      return "thu";
    case 7200:
      return "fri";
    case 8640:
      return "sat";
    default:
      break;
  }
};

export const replaceWENwithWEDdeliveryOptions_DEPRECATED = (
  deliveryOptions: Array<Object>,
) =>
  deliveryOptions.map((option: any) => {
    const correctedOption = { ...option };
    if (!_.isEmpty(option.availability)) {
      const correctedAvailability = option.availability.map((avail: any) => {
        if (avail.day === "wen") {
          return { ...avail, day: "wed" };
        }
        return avail;
      });
      correctedOption.availability = correctedAvailability;
    }
    return correctedOption;
  });
