import {
  Box,
  Card,
  Grid,
  Hidden,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { CustomTheme } from "../../../../../types/customTheme";
import StockLevelRow from "../../../../../components/common/StockLevelRow";
import IncrementDecrementTextboxDefault from "../../../../../components/common/IncrementDecrementTextbox/IncrementDecrementTextboxDefault";

const useStyles = makeStyles((theme: CustomTheme) => ({
  departmentCardStyle: {
    backgroundColor: "#84803D",
    "&:hover": {
      backgroundColor: "#a5a380",
    },
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_highlight_background}`,
    overflowY: "auto",
    boxShadow: "none",
    cursor: "pointer",
  },
  departmentDisableCardStyle: {
    backgroundColor: theme.palette.background.entity_highlight_background,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_highlight_background}`,
    overflowY: "auto",
    boxShadow: "none",
    cursor: "pointer",
  },
  statusColorRed: {
    height: "50px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.red.main,
  },
  statusColorRedMobile: {
    height: "100px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.red.main,
  },
  statusColorYellow: {
    height: "50px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.yellow.main,
  },
  statusColorYellowMobile: {
    height: "100px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.yellow.main,
  },
  statusColorGreen: {
    height: "50px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.green.main,
  },
  statusColorGreenMobile: {
    height: "100px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.green.main,
  },
}));

export interface DesktopNavProps {
  item: any;
  registerElementWithTitle: any;
  selectedStockOrderItemIdList: any;
  handleDecrement: any;
  selectedStockOrderItemObject: any;
  handleOrderQty: any;
  handleIncrement: any;
  handleSubmit: any;
  handleChangeCheckBox: any;
  handleDisabled: any;
  getStatusMessage: any;
  isDisable: any;
  handleOrderQtyEmpty: any;
  initialOrderQty: any;
  stockLevelNode: any;
  isError: any;
  groupIndex: any;
}

/**
 * This component represents the view for displaying stock order items when the active group ID is zero.
 * It shows the stock order items organized by departments, allowing users to select items, adjust quantities,
 * and perform various actions.
 */
const StockOrderItemActiveGroupIdZero: React.FunctionComponent<
  DesktopNavProps
> = ({
  item,
  registerElementWithTitle,
  selectedStockOrderItemIdList,
  handleDecrement,
  selectedStockOrderItemObject,
  handleOrderQty,
  handleIncrement,
  handleSubmit,
  handleChangeCheckBox,
  handleDisabled,
  getStatusMessage,
  isDisable,
  handleOrderQtyEmpty,
  initialOrderQty,
  stockLevelNode,
  isError,
  groupIndex,
}) => {
  const [sortDepartmentData, setSortDepartmentData] = useState<any>([]);
  const classes = useStyles();

  useEffect(() => {
    const sortedArray = [...item.stockDepartmentId].sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    setSortDepartmentData(sortedArray);
  }, [item.stockDepartmentId]);

  const handleStatus = (stockOrder: any) => {
    const { id } = stockOrder;
    if (stockLevelNode[id]) {
      const { stockLevel, warningThreshold, criticalThreshold } =
        stockLevelNode[id];

      if (stockLevel < criticalThreshold) {
        return <Box className={classes.statusColorRed}></Box>;
      } else if (stockLevel < warningThreshold) {
        return <Box className={classes.statusColorYellow}></Box>;
      } else {
        return <Box className={classes.statusColorGreen}></Box>;
      }
    } else {
      const { stockLevel, warningThreshold, criticalThreshold } = stockOrder;
      if (0 < criticalThreshold) {
        return <Box className={classes.statusColorRed}></Box>;
      } else if (0 < warningThreshold) {
        return <Box className={classes.statusColorYellow}></Box>;
      } else {
        return <Box className={classes.statusColorGreen}></Box>;
      }
    }
  };

  const handleStatusMobile = (stockOrder: any) => {
    const { id } = stockOrder;
    if (stockLevelNode[id]) {
      const { stockLevel, warningThreshold, criticalThreshold } =
        stockLevelNode[id];

      if (stockLevel < criticalThreshold) {
        return <Box className={classes.statusColorRedMobile}></Box>;
      } else if (stockLevel < warningThreshold) {
        return <Box className={classes.statusColorYellowMobile}></Box>;
      } else {
        return <Box className={classes.statusColorGreenMobile}></Box>;
      }
    } else {
      const { stockLevel, warningThreshold, criticalThreshold } = stockOrder;
      if (0 < criticalThreshold) {
        return <Box className={classes.statusColorRedMobile}></Box>;
      } else if (0 < warningThreshold) {
        return <Box className={classes.statusColorYellowMobile}></Box>;
      } else {
        return <Box className={classes.statusColorGreenMobile}></Box>;
      }
    }
  };

  const theme: CustomTheme = useTheme();
  const tabletScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      {!_.isEmpty(sortDepartmentData) &&
        sortDepartmentData.map((department: any, index: any) => (
          <div
            ref={(ref) =>
              registerElementWithTitle(`department ${department.id}`, ref)
            }
          >
            <div
              style={{
                backgroundColor: theme.palette.background.entity_background,
                border: `1px solid ${theme.palette.background.entity_background}`,
                overflowY: "auto",
                boxShadow: "none",
                position: "sticky",
                zIndex: 10,
                top: mobileScreen ? "280px" : tabletScreen ? "240px" : "176px",
                paddingTop: "4px",
                paddingBottom: "4px",
              }}
            >
              <Card
                style={{
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_highlight_background}`,
                  marginBottom: "12px",
                  marginTop: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 10px",
                  }}
                >
                  <div style={{ display: "block" }}>
                    <Typography>{item.name}</Typography>
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      {department.name}
                    </Typography>
                  </div>
                </div>
              </Card>
            </div>
            <div
              style={{
                marginTop: index === 0 && groupIndex === 1 ? "170px" : "0px",
              }}
            ></div>
            <div
              style={{
                marginTop:
                  mobileScreen && index === 0
                    ? "270px"
                    : tabletScreen && index === 0
                    ? "230px"
                    : "0px",
              }}
            ></div>
            <Grid
              container
              spacing={1}
              style={{
                marginTop: index === 0 && groupIndex === 1 ? "28px" : "4px",
                marginBottom: "8px",
              }}
            >
              {!_.isEmpty(department.item) &&
                department.item.map(
                  (stockOrder: any) =>
                    department.name !== "Online Hidden" &&
                    department.name !== "Templates" && (
                      <Grid item xs={12} style={{ marginTop: "4px" }}>
                        <Card
                          style={{
                            backgroundColor:
                              selectedStockOrderItemIdList.includes(
                                stockOrder.id,
                              )
                                ? isError.includes(stockOrder.id)
                                  ? theme.palette.custom.red.main
                                  : "#84803D"
                                : theme.palette.background
                                    .entity_highlight_background,
                            borderRadius: "10px",
                            border: `1px solid ${theme.palette.background.entity_highlight_background}`,
                            overflowY: "auto",
                            boxShadow: "none",
                            cursor: "pointer",
                          }}
                          onClick={(event: any) => {
                            selectedStockOrderItemIdList.includes(stockOrder.id)
                              ? handleChangeCheckBox(
                                  event,
                                  stockOrder.id,
                                  stockOrder,
                                )
                              : handleOrderQtyEmpty(stockOrder.id, "10");
                          }}
                        >
                          <Hidden xsDown>
                            <div
                              style={{
                                padding: "7px 8px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>{handleStatus(stockOrder)}</div>
                                <div style={{ minWidth: "200px" }}>
                                  <Typography
                                    align="left"
                                    style={{
                                      fontSize: "14px",
                                      marginLeft: "12px",
                                      marginBottom: "2px",
                                      display: "flex",
                                      justifyContent: "start",
                                    }}
                                  >
                                    {stockOrder.name}
                                  </Typography>
                                </div>
                                <StockLevelRow
                                  autoUnitConvert={stockOrder.autoUnitConvert}
                                  showRemainderSep={stockOrder.showRemainderSep}
                                  displaySuffix={stockOrder.displaySuffix}
                                  inputMultiplier={stockOrder.inputMultiplier}
                                  inputSuffix={stockOrder.inputSuffix}
                                  stockLevel={
                                    stockLevelNode[stockOrder.id]
                                      ? stockLevelNode[stockOrder.id].stockLevel
                                      : "0"
                                  }
                                />
                              </div>
                              <div style={{ width: "300px" }}>
                                <IncrementDecrementTextboxDefault
                                  handleOrderQty={handleOrderQty}
                                  value={
                                    selectedStockOrderItemObject[stockOrder.id]
                                      ?.orderQty
                                  }
                                  id={stockOrder.id}
                                  handleIncrement={handleIncrement}
                                  handleDecrement={handleDecrement}
                                  isDisable={
                                    !selectedStockOrderItemIdList.includes(
                                      stockOrder.id,
                                    )
                                  }
                                  displaySuffix={stockOrder.displaySuffix}
                                  handleOrderQtyEmpty={handleOrderQtyEmpty}
                                  isNeedNegativeIcon={true}
                                />
                              </div>
                            </div>
                          </Hidden>
                          <Hidden smUp>
                            <div
                              style={{
                                padding: "7px 8px",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div>{handleStatusMobile(stockOrder)}</div>

                              <div style={{ display: "block", width: "100%" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginRight: "4px",
                                  }}
                                >
                                  <div style={{ minWidth: "220px" }}>
                                    <Typography
                                      align="left"
                                      style={{
                                        fontSize: "14px",
                                        marginLeft: "12px",
                                        marginBottom: "2px",
                                        display: "flex",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {stockOrder.name}
                                    </Typography>
                                  </div>
                                  <StockLevelRow
                                    autoUnitConvert={stockOrder.autoUnitConvert}
                                    showRemainderSep={
                                      stockOrder.showRemainderSep
                                    }
                                    displaySuffix={stockOrder.displaySuffix}
                                    inputMultiplier={stockOrder.inputMultiplier}
                                    inputSuffix={stockOrder.inputSuffix}
                                    stockLevel={
                                      stockLevelNode[stockOrder.id]
                                        ? stockLevelNode[stockOrder.id]
                                            .stockLevel
                                        : "0"
                                    }
                                  />
                                </div>
                                <div style={{ margin: "0px 4px 0px 12px" }}>
                                  <IncrementDecrementTextboxDefault
                                    handleOrderQty={handleOrderQty}
                                    value={
                                      selectedStockOrderItemObject[
                                        stockOrder.id
                                      ]?.orderQty
                                    }
                                    id={stockOrder.id}
                                    handleIncrement={handleIncrement}
                                    handleDecrement={handleDecrement}
                                    isDisable={
                                      !selectedStockOrderItemIdList.includes(
                                        stockOrder.id,
                                      )
                                    }
                                    displaySuffix={stockOrder.displaySuffix}
                                    handleOrderQtyEmpty={handleOrderQtyEmpty}
                                    isNeedNegativeIcon={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </Hidden>
                        </Card>
                      </Grid>
                    ),
                    // <Grid
                    //   item
                    //   xs={12}
                    //   style={{
                    //     padding: "0px 12px",
                    //   }}
                    // >
                    //   <div
                    //     style={{
                    //       display: "flex",
                    //       justifyContent: "space-between",
                    //       alignItems: "center",
                    //       marginLeft: "8px",
                    //       height: "40px",
                    //     }}
                    //   >
                    //     <Typography
                    //       style={
                    //         tabletScreen
                    //           ? {
                    //               paddingLeft: "80px",
                    //               fontSize: "12px",
                    //             }
                    //           : {
                    //               paddingLeft: "80px",
                    //               fontSize: "12px",
                    //             }
                    //       }
                    //     >
                    //       {stockOrder.name}
                    //     </Typography>
                    //     <div
                    //       style={{
                    //         display: "flex",
                    //         alignItems: "center",
                    //       }}
                    //     >
                    //       {selectedStockOrderItemIdList.includes(stockOrder.id) && (
                    //         <>
                    //           <IncrementDecrementTextbox
                    //             handleOrderQty={handleOrderQty}
                    //             value={
                    //               selectedStockOrderItemObject[stockOrder.id]
                    //                 ?.orderQty
                    //             }
                    //             id={stockOrder.id}
                    //             handleIncrement={handleIncrement}
                    //             handleDecrement={handleDecrement}
                    //             isDisable={isDisable}
                    //           />
                    //         </>
                    //       )}
                    //       <Checkbox
                    //         checked={selectedStockOrderItemIdList.includes(
                    //           stockOrder.id,
                    //         )}
                    //         value={stockOrder.id}
                    //         onChange={(event) =>
                    //           handleChangeCheckBox(event, stockOrder.id, stockOrder)
                    //         }
                    //         size="small"
                    //         inputProps={{
                    //           "aria-label": "controlled",
                    //         }}
                    //         disabled={isDisable}
                    //       />
                    //     </div>
                    //   </div>
                    // </Grid>
                )}
            </Grid>
          </div>
        ))}
    </div>
  );
};

export default StockOrderItemActiveGroupIdZero;
