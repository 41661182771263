import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { useRouteMatch } from "react-router-dom";
import {
  createCategoryInfo,
  fetchSingleCategoryInfo,
  updateCategoryInfo,
} from "../../../../../services/menuApp/menuService";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../../utils/consts";

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  setCategoryName: any;
  categoryName: any;
  isCategoryNameEmpty: any;
  setIsCategoryNameEmpty: any;
  handleCloseCategoryAddModal: any;
  isLoadingButton: any;
  setIsLoadingButton: any;
  selectedMenuId: any;
  setSuccess: any;
  setError: any;
  selectedCategory: any;
  isValidCategory: any;
  buttonSize: any;
  isValidKiosk: any;
  color: any;
  menuNodeList: any;
  setMenuNodeList: any;
}

const CategoryAddModal: React.FunctionComponent<StrategyAddEditModalProps> = ({
  isOpen,
  setIsOpen,
  setCategoryName,
  categoryName,
  isCategoryNameEmpty,
  setIsCategoryNameEmpty,
  handleCloseCategoryAddModal,
  isLoadingButton,
  setIsLoadingButton,
  selectedMenuId,
  setSuccess,
  setError,
  selectedCategory,
  isValidCategory,
  buttonSize,
  isValidKiosk,
  color,
  menuNodeList,
  setMenuNodeList,
}) => {
  const handleDisable = () => {};
  const match: any = useRouteMatch();
  const handleSubmitCategoryInfo = async () => {
    setIsLoadingButton(true);

    if (_.isEmpty(categoryName)) {
      setIsCategoryNameEmpty(true);
    } else {
      const body = {
        name: categoryName,
        locationId: match.params.locationId,
        menuId: selectedMenuId,
      };

      try {
        const summaryString = `${categoryName} Category is added`;
        const res = await createCategoryInfo(
          match.params.locationId,
          body,
          "Menus Changed",
          summaryString,
          "menu",
        );
        const cloneMenuNodeList = _.cloneDeep(menuNodeList);
        if(!cloneMenuNodeList[selectedMenuId].category) {
          cloneMenuNodeList[selectedMenuId].category = {}
        }
        cloneMenuNodeList[selectedMenuId].category[res.data.data.id] = res.data.data;
        cloneMenuNodeList[selectedMenuId].category[res.data.data.id]["wrapper"] = {}
        setMenuNodeList(cloneMenuNodeList);

        setSuccess(SUCCESSFULLY_CREATED);
        setIsLoadingButton(false)
        setIsCategoryNameEmpty(false);
        setIsOpen(false);
       
      } catch (err) {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
        setIsLoadingButton(false);
      }
    }
  };

  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>Name</Typography>
              <TextfieldDefaultNew
                id="sign-password-input"
                name="sign-password-input"
                onChange={(e: any) => {
                  setIsCategoryNameEmpty("");
                  setCategoryName(e.target.value);
                }}
                value={categoryName}
                type="text"
              />
              {isCategoryNameEmpty && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please enter name.
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleCloseCategoryAddModal}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={handleDisable()}
            isLoadingPage={isLoadingButton}
            color={"green"}
            onClick={handleSubmitCategoryInfo}
          >
            {"Create"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default CategoryAddModal;
