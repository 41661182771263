import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import {
  fetchSingleMenuInfo,
  updateMenuInfo,
} from "../../../../../services/menuApp/menuService";
import { useRouteMatch } from "react-router-dom";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../../utils/consts";

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  setMenuName: any;
  menuName: any;
  isMenuNameEmpty: any;
  setIsMenuNameEmpty: any;
  handleCloseMenuEditModal: any;
  isLoadingButton: any;
  setType: any;
  type: any;
  brand: any;
  setBrand: any;
  typeInitial: any;
  brandInitial: any;
  menuNameInitial: any;
  menu: any;
  setError: any;
  setIsLoadingButton: any;
  menuNodeList: any;
  setMenuNodeList: any;
}

const MenuEditModal: React.FunctionComponent<StrategyAddEditModalProps> = ({
  isOpen,
  setIsOpen,
  setMenuName,
  menuName,
  isMenuNameEmpty,
  setIsMenuNameEmpty,
  handleCloseMenuEditModal,
  isLoadingButton,
  setType,
  type,
  brand,
  setBrand,
  typeInitial,
  brandInitial,
  menuNameInitial,
  menu,
  setError,
  setIsLoadingButton,
  menuNodeList,
  setMenuNodeList,
}) => {
  const match: any = useRouteMatch();

  const handleDisable = () => {
    if (
      menuName === menuNameInitial &&
      type === typeInitial &&
      brand === brandInitial
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleGetSingleMenu = async () => {
    try {
      const res = await fetchSingleMenuInfo(match.params.locationId, menu.id);
      handleEditMenuInfo(res.data.data.version);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  };

  const handleEditMenuInfo = async (version?: any) => {
    setIsLoadingButton(true);

    const body = {
      name: menuName,
      id: menu.id,
      locationId: match.params.locationId,
      brand: brand,
      type: type,
      version: version || menu.version,
    };
    if (_.isEmpty(menuName)) {
      setIsMenuNameEmpty(true);
    } else {
      let summary = [];
      if (menu.name !== menuName) {
        summary.push(`Menu name changed from ${menu.name} to ${menuName}`);
      }

      if (menu.brand !== brand) {
        summary.push(`Brand changed from ${menu.brand} to ${brand}`);
      }

      if (menu.type !== type) {
        summary.push(`Type changed from ${menu.type} to ${type}`);
      }
      const summaryString = summary.join("\\n");

      try {
        const res = await updateMenuInfo(
          match.params.locationId,
          body,
          "Menus Changed",
          summaryString,
          "menu",
        );

        if (res.data.status === 409) {
          handleGetSingleMenu();
        } else {
          const cloneMenuNodeList = _.cloneDeep(menuNodeList);
          const menuCategory = cloneMenuNodeList[menu.id]?.category;
          cloneMenuNodeList[menu.id] = res.data.data;
          cloneMenuNodeList[menu.id]["category"] = menuCategory || {};
          setMenuNodeList(cloneMenuNodeList);
          setIsLoadingButton(false);
          setIsMenuNameEmpty(false);
          setIsOpen(false);
        }
      } catch (err) {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
        setIsLoadingButton(false);
      }
    }
  };

  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>Add New Menu</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>Name</Typography>
              <TextfieldDefaultNew
                id="sign-password-input"
                name="sign-password-input"
                onChange={(e: any) => {
                  setIsMenuNameEmpty("");
                  setMenuName(e.target.value);
                }}
                value={menuName}
                type="text"
              />
              {isMenuNameEmpty && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please enter name.
                  </Typography>
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>Type</Typography>
              <TextfieldDefaultNew
                id="sign-password-input"
                name="sign-password-input"
                onChange={(e: any) => {
                  setType(e.target.value);
                }}
                value={type}
                type="text"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>Brand</Typography>
              <TextfieldDefaultNew
                id="sign-password-input"
                name="sign-password-input"
                onChange={(e: any) => {
                  setBrand(e.target.value);
                }}
                value={brand}
                type="text"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleCloseMenuEditModal}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={handleDisable()}
            isLoadingPage={isLoadingButton}
            color={"orange"}
            onClick={handleEditMenuInfo}
          >
            {"Save"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default MenuEditModal;
