import * as React from "react";
import ShapeLine from "../../shapes/ShapeLine";

export interface PreviewMenuProps {
  menuTitleTextColor: string;
  categoryTitleTextColor: string;
  itemTitleTextColor: string;
  itemDescriptionTextColor: string;
}

/* Show user selected color for color preview box.
(menuTitleTextColor, categoryTitleTextColor, itemTitleTextColor, itemDescriptionTextColor) */
const PreviewMenu: React.FunctionComponent<PreviewMenuProps> = ({
  menuTitleTextColor,
  categoryTitleTextColor,
  itemTitleTextColor,
  itemDescriptionTextColor,
}) => {

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ShapeLine length={2} color={menuTitleTextColor} />
        <div style={{ margin: 4 }}></div>
        <ShapeLine length={2} color={itemTitleTextColor} />
        <div style={{ margin: 4 }}></div>
        <ShapeLine length={1} color={itemTitleTextColor} />
        <div style={{ margin: 4 }}></div>
      </div>
      <div style={{ margin: 32 }}></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ShapeLine length={4} color={categoryTitleTextColor} height={3} />
      </div>
      <div style={{ margin: 32 }}></div>
      <PreviewMenuItem
        itemTitleTextColor={itemTitleTextColor}
        itemDescriptionTextColor={itemDescriptionTextColor}
      />
      <PreviewMenuItem
        itemTitleTextColor={itemTitleTextColor}
        itemDescriptionTextColor={itemDescriptionTextColor}
      />
      <PreviewMenuItem
        itemTitleTextColor={itemTitleTextColor}
        itemDescriptionTextColor={itemDescriptionTextColor}
      />
      <PreviewMenuItem
        itemTitleTextColor={itemTitleTextColor}
        itemDescriptionTextColor={itemDescriptionTextColor}
      />
      <PreviewMenuItem
        itemTitleTextColor={itemTitleTextColor}
        itemDescriptionTextColor={itemDescriptionTextColor}
      />
    </>
  );
};

export interface MenuItemPreviewProps {
  itemTitleTextColor: string;
  itemDescriptionTextColor: string;
}

const PreviewMenuItem: React.FunctionComponent<MenuItemPreviewProps> = ({
  itemTitleTextColor,
  itemDescriptionTextColor,
}) => {
  return (
    <div style={{ marginBottom: 24 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ShapeLine length={3} color={itemTitleTextColor} height={2} />
        <div style={{ margin: 16 }}></div>
        <ShapeLine length={1} color={itemTitleTextColor} height={2} />
      </div>
      <div style={{ margin: 2 }}></div>
      <ShapeLine length={4} color={itemDescriptionTextColor} height={1} />
    </div>
  );
};

export default PreviewMenu;
