import Dialog from "@material-ui/core/Dialog";
import * as React from "react";
import { ContentState, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import TextEditor from "./textEditor/TextEditor";
import { Button, DialogActions } from "@material-ui/core";
import htmlToDraft from "html-to-draftjs";
import _ from "lodash";

export interface HtmlEditorProps {
  open: boolean;
  handleClose: any;
  htmlEditorState: any;
  handleSubmitEditorState: any;
}

/* Using this component, changes to the banner title and subtitle are made using an HTML editor. 
   Here, the title and subtitle font style can be changed.  */
const HtmlEditor: React.FunctionComponent<HtmlEditorProps> = ({
  open,
  handleClose,
  htmlEditorState,
  handleSubmitEditorState,
}) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(),
  );
  const [isDisable, setIsDisable] = React.useState(false);

  /* Checking if values ​​exist in the editor. */
  React.useEffect(() => {
    const contentBlock = htmlToDraft(htmlEditorState);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);

    // Only if the editor has a value, the option to edit the banner or add a new banner is given.
    if (_.isEmpty(htmlEditorState)) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [htmlEditorState, open]);

  /* Identify HTML editor changes and change the state using editorState */
  const handleChangeEditorState = (editorState: any) => {
    // Check whether the HTML editor has value or not.
    if (editorState.getCurrentContent().hasText()) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
    setEditorState(editorState);
  };

  /* Submit the data after editing. */
  const handleSubmit = () => {
    /* Checking if the editor has a value. */
    if (!_.isEmpty(editorState)) {
      handleSubmitEditorState(editorState);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
      <TextEditor
        editorState={editorState}
        handleChangeEditorState={handleChangeEditorState}
      />
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button disabled={isDisable} onClick={handleSubmit} color="secondary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HtmlEditor;
