import { applyMiddleware, createStore, combineReducers } from "redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";

import { AuthInitialState, AuthReducer } from "./reducer/auth";
import { RestaurantInitialState, RestaurantReducer } from "./reducer/restaurant";

const getInitialState = () => ({
  auth: AuthInitialState,
  restaurant: RestaurantInitialState,
});

const rootReducer = combineReducers({
  auth: AuthReducer,
  restaurant: RestaurantReducer
});

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);
  const store = createStore(rootReducer, getInitialState(), composedEnhancers);
  return store;
}
