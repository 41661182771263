import * as React from "react";
import {
  Button,
  DialogActions,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import DialogCommon from "../../../../components/dialogs/DialogCommon";

export interface ConfirmDeleteDialogProps {
  open: boolean;
  setOpen: any;
  confirmAction: any;
}

const ConfirmDeleteDialogDefault: React.FunctionComponent<
  ConfirmDeleteDialogProps
> = ({ open, setOpen, confirmAction }) => {

  return (
    <DialogCommon open={open} setOpen={setOpen}>
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" align="left" style={{ fontWeight: "bold" }}>
          Are you sure you want to reset usage values?
        </Typography>
        <Typography variant="caption" align="left">
          This action cannot be undone.
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={confirmAction} color="secondary">
          Confirm
        </Button>
      </DialogActions>
    </DialogCommon>
  );
};

export default ConfirmDeleteDialogDefault;
