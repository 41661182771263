import React, { useContext, useEffect, useState } from "react";
import InventoryAppRoutes, {
  getSidebarRoutes,
} from "./routes/InventoryAppRoutes";
import { ThemeProvider } from "@material-ui/core/styles";
import { initTheme } from "./theme/primary";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { GlobalContext } from "../../root/App";
import { CssBaseline } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";
import { getCookie } from "../../utils/cookies";
import { fetchAllMasterLocations } from "../../services/inventory/masterLocations";
import { getIsAuthorized } from "../../utils/authorities";
import Authorities from "../../auth/authorities";
import { fetchAllLocations } from "../../services/locationApp/locationService";
import { getFilterListFromArrayObject } from "../../utils/commonArrayMap";
import { handleChangeRestaurant } from "../../utils";
import queryString from "query-string";
import { INVENTORY_QUERY_PARAMS } from "../../utils/consts";

export interface PrestoExpressInventoryAppProps {}

/**
 * PrestoExpressInventoryApp Component
 *
 * This React component represents the main application for the Presto Express Inventory module.
 * It includes functionality for fetching location information, handling sidebar changes based on user roles,
 * and rendering the primary layout with associated routes.
 *
 * Components:
 *  - InventoryAppRoutes: Module-specific routes for the Inventory app.
 *  - PrimaryLayout: The main layout component containing the sidebar and routing logic.
 */
const PrestoExpressInventoryApp: React.FunctionComponent<
  PrestoExpressInventoryAppProps
> = () => {
  const [sidebarRoutes, setSidebarRoutes] = useState<any>([]);
  const [isOpenDesktopSideBar, seIsOpenDesktopSideBar] = useState(false);

  const { globalState } = useContext(GlobalContext);
  const theme = initTheme({ isDark: globalState.isDarkMode });
  const match: any = useRouteMatch();

  /**
   * Asynchronous function to fetch master location information based on the provided locationId from the server.
   *
   * @returns {Promise} A Promise that resolves to the fetched master location data or rejects with an error.
   */
  const handleGetMasterLocations = async () => {
    try {
      // Fetch master location data using the provided locationId
      const res = await fetchAllMasterLocations(match.params.locationId);

      // Return the data from the response
      return res.data;
    } catch (error) {}
  };

  /**
   * Asynchronous function to fetch location information based on the locationId from the server.
   * It utilizes cookies, API calls, and utility functions to update relevant parts of the application state.
   */
  const getLocationInfo = async () => {
    try {
      // Retrieve the idToken from cookies
      const idToken: any = getCookie("idToken");

      // Fetch all locations using the idToken
      const res = await fetchAllLocations(idToken);

      // Filter the location data based on the provided locationId
      const location = getFilterListFromArrayObject(
        res.data.data,
        match.params.locationId,
      );

      // Update the restaurant information based on the fetched location
      handleChangeRestaurant(location[0]);

      // Update the sidebar based on the new location information
      handleChangeSidebar();
    } catch (error) {}
  };

  /**
   * Asynchronous function to handle changes to the sidebar based on the current locationId.
   * It fetches master location information, determines the user's authorization,
   * and updates the sidebar accordingly.
   */
  const handleChangeSidebar = async () => {
    // Get the current sidebar routes
    const sidebar = getSidebarRoutes();

    // Check if a locationId is provided
    if (match.params.locationId) {
      // Fetch master location information
      const masterLocations = await handleGetMasterLocations();

      // Check if the master location is not found (status 404) and the user is not authorized to read stock configuration
      if (
        masterLocations.status === 404 &&
        !getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ)
      ) {
        // Update the sidebar with only top-level routes (no child routes)
        const updatedSidebar = sidebar.filter((data) =>
          _.isEmpty(data.childRoutes),
        );
        setSidebarRoutes(updatedSidebar);
      } else {
        let updatedSidebar: any = [];

        // Check if the user is authorized to read stock configuration
        if (getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ)) {
          // Use the original sidebar if the user has the necessary authorization
          updatedSidebar = sidebar;
        } else {
          // Extract master location information
          const { masterLocationId, locationId } = masterLocations?.data;

          // Check if the master location is empty or matches the locationId
          if (_.isEmpty(masterLocationId) || masterLocationId === locationId) {
            // Use the original sidebar if the conditions are met
            updatedSidebar = sidebar;
          } else {
            // Update the sidebar with only top-level routes (no child routes)
            updatedSidebar = sidebar.filter((data) =>
              _.isEmpty(data.childRoutes),
            );
          }
        }

        // Set the updated sidebar
        setSidebarRoutes(updatedSidebar);
      }
    } else {
      // Use the original sidebar if no locationId is provided
      setSidebarRoutes(sidebar);
    }
  };

  /**
   * Effect hook to trigger the retrieval of location information when the locationId changes.
   * It calls the getLocationInfo function to update relevant parts of the application state.
   * Dependencies: [match.params.locationId] - ensures the effect runs when the locationId changes.
   */
  useEffect(() => {
    // Call the function to fetch location information when the locationId changes
    getLocationInfo();
  }, [match.params.locationId]);

  /**
   * Extracts the 'app' parameter from the query string in the window's location.
   * Uses the queryString library to parse the query string and retrieve the 'app' value.
   *
   * @constant {string} selectedApp - The value of the 'app' parameter from the query string.
   */
  const selectedApp = queryString.parse(window.location.search).app;
  const isAppEmbedded = queryString.parse(window.location.search).isAppEmbedded;
  const token = queryString.parse(window.location.search).t;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <PrimaryLayout
        sidebarRoutes={sidebarRoutes}
        handleChangeSidebar={handleChangeSidebar}
        seIsOpenDesktopSideBar={seIsOpenDesktopSideBar}
        isNedSidebarOpen={true}
      >
        {(sidebarRoutes.length > 0 ||
          selectedApp === INVENTORY_QUERY_PARAMS ||
          token) &&
          (isAppEmbedded === "true" ? (
            <div style={{ margin: "0px 20px" }}>
              <InventoryAppRoutes isOpenDesktopSideBar={isOpenDesktopSideBar} />
            </div>
          ) : (
            <InventoryAppRoutes isOpenDesktopSideBar={isOpenDesktopSideBar} />
          ))}
      </PrimaryLayout>
    </ThemeProvider>
  );
};

export default PrestoExpressInventoryApp;
