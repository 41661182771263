import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import {
  fetchCategoryMenu,
  fetchMenuOption,
} from "../../../../services/menuApp/categoryMenuService";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { getCookie } from "../../../../utils/cookies";
import Loading from "./Loading";

export interface MenuInfoProps {}

/* Get selected menu information, category menu details and location information using API call at initial load. */
const MenuInfo: React.FunctionComponent<MenuInfoProps> = () => {
  const [menuNodeList, setMenuNodeList] = useState<any>([]);
  const [menuOptionNodeList, setMenuOptionNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /* Get all category menu information using API call and response data set to menuNodeList state. */
  const getCategoryMenuInfo = async () => {
    try {
      const res = await fetchCategoryMenu(
        match.params.publishedMenuId,
        idToken,
      );
      getMenuOptionInfo();
      setMenuNodeList(res.data.data);
      setIsLoading(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /* Get all menu option information using API call and response data set to menuOptionNodeList state. */
  const getMenuOptionInfo = async () => {
    try {
      const res = await fetchMenuOption(match.params.locationId);
      setMenuOptionNodeList(res.data.data);
      setIsLoading(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  useEffect(() => {
    getCategoryMenuInfo();
    setIsLoading(true);
  }, []);

  return (
    <>
      <Box>
        <Loading
          getCategoryMenuInfo={getCategoryMenuInfo}
          getMenuOptionInfo={getMenuOptionInfo}
          isLoading={isLoading}
          menuOptionNodeList={menuOptionNodeList}
          menuNodeList={menuNodeList}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(MenuInfo, Authorities.MENU_READ);
