import React from "react";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefault from "../../../../../components/textField/TextfieldDefault";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../../utils/enum";

export interface StockGroupAddModalProps {
  isOpenGroupAddModal: boolean;
  setIsOpenGroupAddModal: any;
  isLoadingToast: any;
  groupName: any;
  handleChangeGroupName: any;
  handleCreateNewGroup: any;
}

/**
 * StockGroupAddModal Component
 *
 * This component represents a modal for adding a new stock group.
 * Users can enter the name of the new group and create it.
 */
const StockGroupAddModal: React.FunctionComponent<StockGroupAddModalProps> = ({
  isOpenGroupAddModal,
  setIsOpenGroupAddModal,
  isLoadingToast,
  groupName,
  handleChangeGroupName,
  handleCreateNewGroup,
}) => {
  return (
    <DialogCommonDefault
      open={isOpenGroupAddModal}
      setOpen={setIsOpenGroupAddModal}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
    >
      <DialogTitle>Add New Group</DialogTitle>
      <DialogContent>
        <TextfieldDefault
          id="groupName"
          name="groupName"
          type="text"
          label="Group Name"
          disabled={isLoadingToast}
          value={groupName}
          onChange={handleChangeGroupName}
        />
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px", marginRight: "16px" }}>
        <ButtonCommon
          disabled={isLoadingToast}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "12px",
          }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
          onClick={() => {
            setIsOpenGroupAddModal(false);
          }}
        >
          Cancel
        </ButtonCommon>

        <ButtonCommon
          disabled={!groupName || isLoadingToast}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
          }}
          color={buttonColors.CREATE_BUTTON_COLOR}
          onClick={handleCreateNewGroup}
        >
          Create
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default StockGroupAddModal;
