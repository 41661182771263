import Dialog from "@material-ui/core/Dialog";
import * as React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@material-ui/core";
import DialogCommon from "../../dialogs/DialogCommon";
import ButtonCommon from "../../buttons/ButtonCommon";
import { buttonColors } from "../../../utils/enum";

export interface PasswordResetModalProps {
  open: boolean;
  setOpen: any;
  email: any;
  isLoading: any;
  handleConfirmPasswordReset: any;
}

/* Common modal UI.. */
const PasswordResetModal: React.FunctionComponent<PasswordResetModalProps> = ({
  open,
  setOpen,
  email,
  isLoading,
  handleConfirmPasswordReset,
}) => {
  return (
    <DialogCommon open={open} setOpen={setOpen}>
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent style={{marginBottom: "14px"}}>
        An email to reset your password will be sent to {email}.
      </DialogContent>
      <DialogActions>
        <ButtonCommon
          onClick={() => {
            setOpen(false);
          }}
          disabled={isLoading}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "14px",
          }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
        >
          Cancel
        </ButtonCommon>

        <ButtonCommon
          disabled={isLoading}
          isLoadingPage={isLoading}
          onClick={handleConfirmPasswordReset}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "14px",
          }}
          color={buttonColors.CREATE_BUTTON_COLOR}
        >
          cONFIRM
        </ButtonCommon>
      </DialogActions>
    </DialogCommon>
  );
};

export default PasswordResetModal;
