import {
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import _ from "lodash";
import React from "react";
import { HeaderCellSort } from "@table-library/react-table-library/sort";
import CardCommon from "../../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../../types/customTheme";

const useStyles = (
  nodesLength: number,
  nodesHeadersLength: number,
  firstColumnWidth: string,
  columnWidth: string,
  footerLength: any,
) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        paddingLeft: "16px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
      },
      textStyle: {
        display: "flex",
        justifyContent: "center",
      },
      endTextStyle: {
        display: "flex",
        justifyContent: "end",
        paddingRight: "16px",
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.table_header_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      headerRowFinal: {
        fontSize: "13px",
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(n+${nodesLength + 2}):nth-of-type(-n+${
          nodesLength + footerLength
        })`]: {
          color: theme.palette.custom["blue"].contrastText,
          backgroundColor: theme.palette.custom.blue.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.blue.light,
          },
        },
        [`&:nth-of-type(${nodesLength + footerLength + 1})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      headerRowFinalPurpleColor: {
        fontSize: "13px",
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(n+${nodesLength + 2}):nth-of-type(-n+${
          nodesLength + footerLength
        })`]: {
          color: theme.palette.custom["purple"].contrastText,
          backgroundColor: theme.palette.custom.purple.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.purple.light,
          },
        },
        [`&:nth-of-type(${nodesLength + footerLength + 1})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      headerRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      baseCellRow: {
        placeItems: "center",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: firstColumnWidth,
          width: firstColumnWidth,
        },
        [theme.breakpoints.down("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
        },
        [theme.breakpoints.down("lg")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
            [`&:nth-of-type(${nodesHeadersLength})`]: {
              minWidth: columnWidth,
              width: columnWidth,
              "& .css-16gtl2w": {
                display: "flex",
                justifyContent: "end",
                fontWeight: "bold",
                paddingRight: "12px",
              },
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: firstColumnWidth,
            width: firstColumnWidth,
          },
        },

        [theme.breakpoints.down("md")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: firstColumnWidth,
            width: firstColumnWidth,
          },
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: firstColumnWidth,
            width: firstColumnWidth,
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: "35%",
            width: "35%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: "35%",
            width: "35%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "50%",
            width: "50%",
          },
        },
      },
    }),
  );

export interface AvailabilitySelectionModalProps {
  nodesLength: any;
  nodesHeadersLength: any;
  firstColumnWidth: any;
  columnWidth: any;
  tableName: any;
  nodes: any;
  sort: any;
  footer: any;
  headersList: any;
  footerLength: any;
  max: any;
}

/* This creates the other payments, vat audit, pay in out, canceled sale, void transaction, 
discount, mode summery,  platform summery,  and channel summery tables. */
const GenerateTable: React.FunctionComponent<
  AvailabilitySelectionModalProps
> = ({
  nodesLength,
  nodesHeadersLength,
  firstColumnWidth,
  columnWidth,
  tableName,
  nodes,
  sort,
  footer,
  headersList,
  footerLength,
  max,
}) => {
  const maxWidth = useMediaQuery(max);
  const maxWidthCustom = useMediaQuery("(max-width: 1600px)");
  const classes: any = useStyles(
    nodesLength,
    nodesHeadersLength,
    firstColumnWidth,
    columnWidth,
    footerLength,
  )();

  const theme: CustomTheme = useTheme();

  return (
    <div style={{ margin: "16px" }}>
      {!_.isEmpty(nodes) && (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginBottom: "12px",
            marginLeft: "2px",
          }}
        >
          <Typography variant="h6">{tableName}</Typography>
        </div>
      )}
      <CardCommon>
        <Table
          data={nodes}
          sort={sort}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
        >
          {(tableList) => (
            <>
              <Header style={{ zIndex: 0 }}>
                <HeaderRow className={classes.headerStyle}>
                  {headersList.map((data: any, index: any) => {
                    return (
                      <HeaderCellSort
                        sortKey={data}
                        pinLeft={index === 0 ? true : false}
                        className={classes.baseCellRow}
                      >
                        <Typography
                          variant="caption"
                          className={
                            index === 0
                              ? classes.startTextStyle
                              : classes.textStyleHeader
                          }
                          style={{
                            fontWeight: "bold",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                        >
                          {data}
                        </Typography>
                      </HeaderCellSort>
                    );
                  })}
                </HeaderRow>
              </Header>

              <Body className={classes.bodyStyle}>
                {tableList.map((item, bodyIndex) => (
                  <>
                    <Row
                      key={item.gross20}
                      item={item}
                      className={classes.headerRow}
                    >
                      {Object.keys(item).map((data: any, index: any) => {
                        return (
                          <>
                            <Cell
                              pinLeft={index === 0 ? true : false}
                              className={classes.baseCellRow}
                            >
                              <Typography
                                variant="caption"
                                className={
                                  index === 0
                                    ? classes.startTextStyle
                                    : index + 1 === Object.keys(item).length
                                    ? classes.endTextStyle
                                    : classes.textStyle
                                }
                              >
                                {item[data]}
                              </Typography>
                            </Cell>
                          </>
                        );
                      })}
                    </Row>

                    {!_.isEmpty(footer[0]) &&
                      bodyIndex + 1 === tableList.length &&
                      footer.map((data1: any, footerIndex: any) => (
                        <>
                          {" "}
                          <Row
                            className={
                              data1["Type"] === "payIn" ||
                              data1["Type"] === "payOut"
                                ? classes.headerRowFinalPurpleColor
                                : classes.headerRowFinal
                            }
                            item={item}
                          >
                            {Object.keys(item).map((data: any, index: any) => {
                              return (
                                <>
                                  <Cell
                                    pinLeft={index === 0 ? true : false}
                                    className={classes.baseCellRow}
                                  >
                                    <Typography
                                      variant="caption"
                                      className={
                                        index === 0
                                          ? classes.startTextStyle
                                          : index + 1 ===
                                            Object.keys(item).length
                                          ? classes.endTextStyle
                                          : classes.textStyle
                                      }
                                      style={{
                                        fontWeight: "bold",
                                        color: "white",
                                      }}
                                    >
                                      {data1[data]}
                                    </Typography>
                                  </Cell>
                                </>
                              );
                            })}
                          </Row>
                        </>
                      ))}
                  </>
                ))}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>
    </div>
  );
};

export default GenerateTable;
