import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBRtk6eY0MCeqDTSeHGhphTJg6zE90kXOQ",
  authDomain: "presto-express-global.firebaseapp.com",
  databaseURL: "https://presto-express-global.firebaseio.com",
  projectId: "presto-express-global",
  storageBucket: "presto-express-global.appspot.com",
  messagingSenderId: "15388670024",
  appId: "1:15388670024:web:fad27e8bd38809005b007d",
  measurementId: "G-MBF95QSVBE",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;

// var firebaseConfig = {
//   apiKey: "AIzaSyBRtk6eY0MCeqDTSeHGhphTJg6zE90kXOQ",
//   authDomain: "presto-express-global.firebaseapp.com",
//   databaseURL: "https://presto-express-global.firebaseio.com",
//   projectId: "presto-express-global",
//   storageBucket: "presto-express-global.appspot.com",
//   messagingSenderId: "15388670024",
//   appId: "1:15388670024:web:fb7994747e280c1b5b007d",
//   measurementId: "G-8GSMYLS6K0"
// };