import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  HeaderCellSort,
  useSort,
} from "@table-library/react-table-library/sort";
import "jspdf-autotable";
import { Icon } from "@iconify/react";

import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { handleDownloadCsv } from "./convertPdfExcel/convertDataToExcel";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";
import { sortNumbers, sortStrings } from "../../../../utils/commonArrayMap";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";

export interface PaymentReportInfoNodeProps {
  cashierReportNodeList: any;
  locationSelectorList: any;
  filterDetails: any;
  filterData: any;
  basedOnShift: any;
  locationSelectedList: any;
  handleOnClickText: any;
  userDetails: any;
}

const useStyles = (nodesLength: number, bodyLength: number) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        paddingLeft: "16px",
        fontSize: "12px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: "12px",
      },
      endTextStyle: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontSize: "12px",
        paddingRight: "20px",
      },
      endTextStyleIndexFour: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontSize: "12px",
      },
      headerEndTextStyle: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
        paddingRight: "8px",
      },
      footerEndTextStyle: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
      },
      footerEndTextStyles: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
        paddingRight: "20px",
      },
      textStyle: {
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.table_header_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      headerRowFinal: {
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(${nodesLength + 2})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      finalTotalRow: {
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(${bodyLength + 1})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
        [`&:nth-of-type(n+${bodyLength + 2}):nth-of-type(-n+${
          nodesLength + 1
        })`]: {
          color: theme.palette.custom["purple"].contrastText,
          backgroundColor: theme.palette.custom.purple.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.purple.light,
          },
        },
      },
      totalRow: {
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(n)": {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      headerRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      baseCellRow: {
        placeItems: "center",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "20%",
          width: "20%",
        },
        [theme.breakpoints.down("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${5})`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${6})`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${3})`]: {
            minWidth: "28%",
            width: "28%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${4})`]: {
            minWidth: "28%",
            width: "28%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "32%",
            width: "32%",
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${3})`]: {
            minWidth: "40%",
            width: "40%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${4})`]: {
            minWidth: "40%",
            width: "40%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "48%",
            width: "48%",
          },
        },
      },
    }),
  );

/**
 * CashierSummaryReportNode Component
 *
 * This component renders a table displaying payment report data.
 * It also provides options to export the report as PDF or CSV.
 * Users can view sale details by clicking on items in the table.
 */
const CashierSummaryReportNode: React.FunctionComponent<
  PaymentReportInfoNodeProps
> = ({
  cashierReportNodeList,
  locationSelectorList,
  filterDetails,
  filterData,
  basedOnShift,
  locationSelectedList,
  handleOnClickText,
  userDetails,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [headersList, setHeadersList] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [nodesLength, setNodeLength] = useState(0);
  const [bodyLength, setBodyLength] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const maxWidthCustom = useMediaQuery("(max-width: 1600px)");
  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    setNodes({ nodes: [] });
  }, [params.get("startDate")]);

  /* Creating the data for the Payment report table and creating the data for pdf and excel files.
       Using a API request */
  useEffect(() => {
    let clonePaymentReportNode: any = _.cloneDeep(cashierReportNodeList);
    let footer: any = [];

    if (!_.isEmpty(clonePaymentReportNode)) {
      const cashierSummaryNodeList: any = [];

      const totals: any = {
        Name: "Grand Total",
        noOfOrders: 0,
        salesTotal: 0,
        discounts: 0,
        noOfVoids: 0,
        voidsTotal: 0,
      };

      Object.entries(clonePaymentReportNode).forEach(([key, value]: any) => {
        const noOfOrders = value.totalSales;
        const salesTotal = parseFloat(value.totalSalesValue).toFixed(2);
        const discounts = parseFloat(value.totalDiscountValue).toFixed(2);
        const noOfVoids = value.totalVoids;
        const voidsTotal = parseFloat(value.totalVoidValue).toFixed(2);

        cashierSummaryNodeList.push({
          name: userDetails[key]?.name || key,
          noOfOrders,
          salesTotal,
          discounts,
          noOfVoids,
          voidsTotal,
        });

        totals.noOfOrders += noOfOrders;
        totals.salesTotal += parseFloat(salesTotal);
        totals.discounts += parseFloat(discounts);
        totals.noOfVoids += noOfVoids;
        totals.voidsTotal += parseFloat(voidsTotal);
      });

      // Ensure totals have the correct decimal places
      totals.salesTotal = totals.salesTotal.toFixed(2);
      totals.discounts = totals.discounts.toFixed(2);
      totals.voidsTotal = totals.voidsTotal.toFixed(2);

      console.log("sdfsdf45dfsgdfg", cashierSummaryNodeList, totals);

      // Adding the total of all the locations obtained above to the 'footer'.
      footer.push(totals);

      // Set pdf headers
      setPdfHeaders([
        { title: "Name", field: "name" },
        { title: "No Of Orders", field: "noOfOrders" },
        { title: "Sales Total", field: "salesTotal" },
        { title: "Discounts", field: "discounts" },
        { title: "No Of Voids", field: "noOfVoids" },
        { title: "Voids Total", field: "voidsTotal" },
      ]);

      // Set excel headers
      setExcelHeaders([
        { header: "Name", key: "name", width: 52, height: 68 },
        { header: "No Of Orders", key: "noOfOrders", width: 32, height: 68 },
        { header: "Sales Total", key: "salesTotal", width: 32, height: 68 },
        {
          header: "Discounts",
          key: "discounts",
          width: 32,
          height: 68,
        },
        {
          header: "No Of Voids",
          key: "noOfVoids",
          width: 32,
          height: 68,
        },
        {
          header: "Voids Total",
          key: "voidsTotal",
          width: 32,
          height: 68,
        },
      ]);

      // Set table headers
      setHeadersList([
        "Name",
        "No Of Orders",
        "Sales Total",
        "Discounts",
        "No Of Voids",
        "Voids Total",
      ]);

      setNodeLength(cashierSummaryNodeList.length + 1);

      // Payment report length
      setBodyLength(cashierSummaryNodeList.length);

      // set the table data in the state
      setNodes({ nodes: cashierSummaryNodeList });

      // set the table footer in the state
      // setFooter(footer);

      // Merge the body and footer of PDF/ Excel.
      // const merged = [...cashierSummaryNodeList, ...footer];
      // const merged = [...cashierSummaryNodeList];

      // set the pdf excel data in the state
      setNodesPdfExcel(cashierSummaryNodeList);
    }
  }, [cashierReportNodeList, userDetails]);

  // Create a pay In Out sorting function
  const sort = useSort(
    nodes,
    {},
    {
      sortFns: {
        Location: (array) => sortStrings(array, "Location"),
        "Payment Method": (array) => sortStrings(array, "Method"),
        "Payment Received": (array) => sortNumbers(array, "Amount"),
        "Percentage Of Total": (array) => sortStrings(array, "Percentage"),
      },
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const theme: CustomTheme = useTheme();
  const classes: any = useStyles(nodesLength, bodyLength)();
  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
            {!_.isEmpty(nodes.nodes) && (
              <div
                style={
                  maxWidthLocationChips
                    ? {
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "8px",
                      }
                    : { marginLeft: "16px" }
                }
              >
                <MenuButtonCommon
                  id="basic-button"
                  variant="contained"
                  style={{
                    fontSize: 11,
                    height: "38px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  color="blue"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <GetAppIcon />
                  <Typography
                    variant="body1"
                    style={{ paddingLeft: "16px", color: "white" }}
                  >
                    Export
                  </Typography>
                </MenuButtonCommon>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    style: {
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    },
                  }}
                  style={{ top: "56px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadPdf(
                        "p",
                        pdfHeaders,
                        nodesPdfExcel,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as PDF
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadCsv(
                        excelHeaders,
                        nodesPdfExcel,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as CSV
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <CardCommon>
        <Table
          data={nodes}
          sort={sort}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
        >
          {(tableList) => (
            <>
              <Header style={{ zIndex: 0 }}>
                <HeaderRow className={classes.headerStyle}>
                  {headersList.map((data: any, index: any) => (
                    <HeaderCellSort
                      sortKey={data}
                      pinLeft={index === 0 ? true : false}
                      className={classes.baseCellRow}
                    >
                      <Typography
                        variant="caption"
                        className={
                          index === 0
                            ? classes.startTextStyle
                            : classes.textStyleHeader
                        }
                        style={{
                          color: theme.palette.custom.orange.contrastText,
                          fontWeight: "bold",
                        }}
                      >
                        {data}
                      </Typography>
                    </HeaderCellSort>
                  ))}
                </HeaderRow>
              </Header>

              <Body className={classes.bodyStyle}>
                {tableList.map((item, bodyIndex) => (
                  <>
                    <Row
                      key={item.gross20}
                      item={item}
                      className={classes.headerRow}
                    >
                      {Object.keys(item).map((data: any, index: any) => (
                        <>
                          <Cell
                            pinLeft={index === 0 ? true : false}
                            className={classes.baseCellRow}
                          >
                            <Typography
                              variant="caption"
                              align="left"
                              className={
                                index === 0
                                  ? classes.startTextStyle
                                  : index === 5
                                  ? classes.endTextStyle
                                  : classes.textStyle
                              }
                              style={
                                data === "name"
                                  ? { fontWeight: "bold" }
                                  : { fontWeight: "normal" }
                              }
                            >
                              {item[data]}
                            </Typography>
                          </Cell>
                        </>
                      ))}
                    </Row>
                    {/* {bodyIndex + 1 === tableList.length &&
                      footer.map((data1: any, footerIndex: any) => (
                        <>
                          <Row
                            className={classes.finalTotalRow}
                            key={item.gross20}
                            item={item}
                          >
                            {Object.keys(item).map((data: any, index: any) => (
                              <>
                                <Cell
                                  pinLeft={index === 0 ? true : false}
                                  className={classes.baseCellRow}
                                  onClick={() =>
                                    handleOpenSaleListModalUsingFooter(
                                      data1,
                                      index,
                                      false,
                                    )
                                  }
                                >
                                  <Typography
                                    variant="caption"
                                    style={{
                                      fontWeight: "bold",
                                      color: "white",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    className={
                                      index === 0
                                        ? classes.startTextStyle
                                        : index === 5
                                        ? footerIndex === 0
                                          ? classes.footerEndTextStyles
                                          : classes.footerEndTextStyle
                                        : classes.textStyle
                                    }
                                  >
                                    {data1[data]}
                                  </Typography>
                                </Cell>
                              </>
                            ))}
                          </Row>
                        </>
                      ))} */}
                  </>
                ))}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>
    </>
  );
};

export default CashierSummaryReportNode;
