import _ from "lodash";
import moment from "moment";

export const handleFilterDataDetailsCustomer = (
  locationFilterList: any,
  createdStartDate: any,
  createdEndDate: any,
  lastOrderStartDate: any,
  lastOrderEndDate: any,
) => {
  let location: any = "";
  let createdDate: any = "";
  let lastOrderDate: any = "";
  let allFilteredList: any = "";

  if (!_.isEmpty(locationFilterList)) {
    location = locationFilterList.join(", ");
  }

  if (!_.isEmpty(createdEndDate)) {
    if (createdStartDate === createdEndDate) {
      createdDate = moment(createdStartDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    } else {
      createdDate =
        moment(createdStartDate, "YYYY-MM-DD").format("YYYY-MM-DD") +
        " - " +
        moment(createdEndDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    }
  }

  if (!_.isEmpty(lastOrderEndDate)) {
    if (lastOrderStartDate === lastOrderEndDate) {
      lastOrderDate = moment(lastOrderStartDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    } else {
      lastOrderDate =
        moment(lastOrderStartDate, "YYYY-MM-DD").format("YYYY-MM-DD") +
        " - " +
        moment(lastOrderEndDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    }
  }

   allFilteredList = [location, createdDate, lastOrderDate];

  const filtered = allFilteredList.filter((data: any) => !_.isEmpty(data));

  return filtered.join("*");
};
