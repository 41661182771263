// export const getItemListByCatMenu = (categoryMenuList: Array<any>) => {
//   const items = Object.values(categoryMenuList).map((catMenu: any) =>
//     Object.values(catMenu.data).map((category: any) =>
//       Object.values(category.data).map((item: any) => item),
//     ),
//   );
//   return items.flat().flat();
// };

/* Get categoryList using a categoryMenuList. */
export const getCategoryListByCatMenu = (categoryMenuList: Array<any>) => {
  const categories = Object.values(categoryMenuList);
  return categories.flat();
};
