import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import _ from "lodash";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import { DeliveryTpe } from "../../../../utils/enum";
import { DELIVERY_TYPE_DINEIN } from "../../../../utils/consts";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import AvailabilitySelection from "../../../../components/time/AvailabilitySelection";
import {
  isEqualArrayObject,
  isEqualArrays,
} from "../../../../utils/checkArrayEqual";

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  setMenuName: any;
  menuName: any;
  deliveryTypesList: any;
  setDeliveryTypesList: any;
  handleSubmit: any;
  availability: any;
  handleSubmitAvailability: any;
  setAvailabilityErrorMessage: any;
  isDeliveryTypeEmpty: any;
  setIsNameEmpty: any;
  setIsDeliveryTypeEmpty: any;
  isNameEmpty: any;
  isAvailabilityEmpty: any;
  isLoadingButton: any;
  isEdit: any;
  isDuplicate: any;
  handleReset: any;
  deliveryTypesListInitial: any;
  availabilityInitial: any;
  strategyNameInitial: any;
}

/**
 * StrategyAddEditModal Component
 *
 * This component is responsible for rendering a modal dialog for adding, editing, or duplicating a strategy.
 * It includes fields for strategy name, delivery types (Dine In, Delivery, Takeout), and availability.
 * The user can select delivery types using switches and set availability using an availability selection component.
 * Additionally, the component provides options for canceling, saving, or creating the strategy.
 */
const StrategyAddEditModal: React.FunctionComponent<
  StrategyAddEditModalProps
> = ({
  isOpen,
  setIsOpen,
  setMenuName,
  menuName,
  deliveryTypesList,
  setDeliveryTypesList,
  handleSubmit,
  availability,
  handleSubmitAvailability,
  setAvailabilityErrorMessage,
  isDeliveryTypeEmpty,
  setIsNameEmpty,
  setIsDeliveryTypeEmpty,
  isNameEmpty,
  isAvailabilityEmpty,
  isLoadingButton,
  isEdit,
  isDuplicate,
  handleReset,
  deliveryTypesListInitial,
  availabilityInitial,
  strategyNameInitial,
}) => {

  /**
   * Function isEqual
   *
   * This function compares two arrays of objects to check if they are equal.
   * Equality is determined based on whether the arrays contain the same objects, regardless of their order.
   */
  const handleDisable = () => {
    // Check if the strategy name, delivery types list, and availability are equal to their initial values
    if (
      menuName === strategyNameInitial 
    ) {
      // If all values are equal, disable the submit button
      return true;
    } else {
      // If any value has changed, enable the submit button
      return false;
    }
  };

  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>{`${isEdit ? "Edit" : "Add New"} Strategy`}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>Name</Typography>
              <TextfieldDefaultNew
                id="sign-password-input"
                name="sign-password-input"
                onChange={(e: any) => {
                  setIsNameEmpty("");
                  setMenuName(e.target.value);
                }}
                value={menuName}
                type="text"
              />
              {isNameEmpty && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please enter name.
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleReset}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={handleDisable()}
            isLoadingPage={isLoadingButton}
            color={isEdit ? "orange" : isDuplicate ? "blue" : "green"}
            onClick={handleSubmit}
          >
            {isEdit ? "Save" : isDuplicate ? "Duplicate" : "Create"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default StrategyAddEditModal;
