import { Button, MenuItem, Typography } from "@material-ui/core";
import React from "react";
import _ from "lodash";

export interface DepartmentSelectMobileViewProps {
  handleButtonClick: any;
  activeGroupId: any;
  selectedStockOrderItemsNode: any;
  selectedDepartmentId: any;
  isDisable: any;
  handleClose: any;
  searchValue: any;
}

/**
 * This component represents the mobile view for selecting departments in the stock order interface.
 * It displays a list of department buttons for the user to choose from.
 */
const DepartmentSelectMobileView: React.FunctionComponent<
  DepartmentSelectMobileViewProps
> = ({
  handleButtonClick,
  activeGroupId,
  selectedStockOrderItemsNode,
  selectedDepartmentId,
  isDisable,
  handleClose,
  searchValue,
}) => {
  return (
    <>
      {selectedStockOrderItemsNode.map(
        (department: any, index: any) =>
          (activeGroupId === index ||
            activeGroupId === 0 ||
            activeGroupId === 1 ||
            searchValue) &&
          department.stockDepartmentId.map(
            (data: any) =>
              department.name !== "Online Hidden" &&
              department.name !== "Templates" && (
                <div>
                  <MenuItem
                    onClick={() => handleButtonClick(`department ${data.id}`)}
                    style={{
                      textTransform: "none",
                      display: "flex",
                      justifyContent: "start",
                    }}
                    disabled={isDisable}
                  >
                    <Typography
                      style={
                        selectedDepartmentId === data.id
                          ? { fontWeight: "bold" }
                          : { fontWeight: "normal" }
                      }
                    >
                      {data.name}
                    </Typography>
                  </MenuItem>
                </div>
              ),
          ),
      )}
    </>
  );
};

export default DepartmentSelectMobileView;
