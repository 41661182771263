import {
  SET_AUTH_USER,
  AUTHENTICATION_PENDING,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_ERROR,
} from "../actions/auth";

export const AuthInitialState = {
  authUser: {
    email: "",
  },
  isLoading: false,
  error: {},
};

interface Action {
  type: string;
  payload: any;
}

export const AuthReducer = (state = AuthInitialState, action: Action) => {
  switch (action.type) {
    case SET_AUTH_USER:
      return {
        ...state,
        authUser: action.payload,
      };

    case AUTHENTICATION_PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        authUser: action.payload,
        isLoading: false,
      };

    case AUTHENTICATION_ERROR:
      return { ...state, error: action.payload, isLoading: false };

    default:
      return state;
  }
};
