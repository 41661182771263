import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  ListItemIcon,
  useTheme,
  Typography,
  Tooltip,
  Divider,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { CustomTheme } from "../../../types/customTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      borderRadius: "0 48px 48px 0",
      border: "none",
    },
    root: {
      borderRadius: "0 48px 48px 0",
      border: "none",
    },
    customDivider: {
      backgroundColor: theme.palette.secondary.main, // Use your primary color here
      height: "2px",
      width: "80%",
      margin: "4px 0px 4px 18px",
      borderRadius: "10px",
    },
    customRedDivider: {
      backgroundColor: theme.palette.custom.red.main,
      height: "2px",
      width: "80%",
      margin: "4px 0px 4px 18px",
      borderRadius: "10px",
    },
    customDividerNotOpenSidebar: {
      backgroundColor: theme.palette.secondary.main, // Use your primary color here
      height: "2px",
      width: "16px",
      margin: "4px 0px 4px 22px",
      borderRadius: "10px",
    },
    customDividerInternalNotOpenSidebar: {
      backgroundColor: theme.palette.custom.red.main,
      height: "2px",
      width: "16px",
      margin: "4px 0px 4px 22px",
      borderRadius: "10px",
    },
  }),
);

export interface SubMenuProps {
  route: any;
  title: any;
  icon: any;
  openSideBar: Boolean;
  setOpenSideBar: any;
}

/* This component is used when one application has several other sub-applications. */
const SubMenu: React.SFC<SubMenuProps> = ({
  route,
  title,
  icon,
  openSideBar,
  setOpenSideBar,
}) => {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  let match = useRouteMatch();
  const { pathname } = useLocation();
  const theme: CustomTheme = useTheme();

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (window.location.href.includes("/admin/")) {
      handleClick();
    }
  }, []);

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      {route.isAuthorized &&
        (!openSideBar ? (
          <div>
            <Typography
              variant="body2"
              style={
                route.id === "internal"
                  ? {
                      fontWeight: "bold",
                      marginTop: "8px",
                      color: theme.palette.custom.red.main,
                    }
                  : {
                      fontWeight: "bold",
                      marginTop: "8px",
                      color: theme.palette.secondary.main,
                    }
              }
            >
              {icon}
            </Typography>
            <Divider
              className={
                route.id === "internal"
                  ? classes.customDividerInternalNotOpenSidebar
                  : classes.customDividerNotOpenSidebar
              }
            />
            <Collapse in={true} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {route.childRoutes &&
                  route.childRoutes.map((subRoute: any) => (
                    <Tooltip
                      title={
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold", color: "white" }}
                        >
                          {subRoute.title}
                        </Typography>
                      }
                    >
                      <ListItem
                        button
                        alignItems="center"
                        component={Link}
                        to={match.url + subRoute.path}
                        key={route.path}
                        selected={pathname.includes(subRoute.path)}
                        className={classes.nested}
                        style={{ height: 48 }}
                        onClick={() => setOpenSideBar(false)}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: "unset",
                            color:
                              route.id === "internal"
                                ? theme.palette.custom.red.main
                                : theme.palette.secondary.main,
                          }}
                        >
                          {subRoute.icon}
                        </ListItemIcon>
                      </ListItem>
                    </Tooltip>
                  ))}
              </List>
            </Collapse>
          </div>
        ) : (
          <>
            <Typography
              variant="body2"
              style={{
                margin: "8px 0px 0px 18px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "start",
                color:
                  route.id === "internal"
                    ? theme.palette.custom.red.main
                    : theme.palette.secondary.main,
              }}
            >
              {title}
            </Typography>
            <Divider
              className={
                route.id === "internal"
                  ? classes.customRedDivider
                  : classes.customDivider
              }
            />

            <Collapse in={true} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {route.childRoutes &&
                  route.childRoutes.map((subRoute: any) => (
                    <ListItem
                      button
                      alignItems="center"
                      component={Link}
                      to={match.url + subRoute.path}
                      key={route.path}
                      selected={pathname.includes(subRoute.path)}
                      className={classes.nested}
                      style={{ height: 48 }}
                      onClick={() =>
                        matchesLargeScreen && setOpenSideBar(false)
                      }
                    >
                      <ListItemIcon
                        style={{
                          minWidth: "unset",
                          color:
                            route.id === "internal"
                              ? theme.palette.custom.red.main
                              : theme.palette.secondary.main,
                        }}
                      >
                        {subRoute.icon}
                      </ListItemIcon>
                      <span style={{ margin: 4 }}></span>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body2"
                            style={{
                              color: theme.palette.custom.orange.contrastText,
                            }}
                          >
                            {subRoute.title}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          </>
        ))}
    </div>
  );
};

export default SubMenu;

// import Collapse from "@material-ui/core/Collapse";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
// import React, { useEffect } from "react";
// import {
//   makeStyles,
//   createStyles,
//   Theme,
//   ListItemIcon,
//   useTheme,
//   Typography,
//   Tooltip,
// } from "@material-ui/core";
// import { Link, useRouteMatch, useLocation } from "react-router-dom";
// import { CustomTheme } from "../../../types/customTheme";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     nested: {
//       paddingLeft: theme.spacing(3),
//       borderRadius: "0 48px 48px 0",
//       border: "none",
//     },
//     root: {
//       borderRadius: "0 48px 48px 0",
//       border: "none",
//     },
//   }),
// );

// export interface SubMenuProps {
//   route: any;
//   title: any;
//   icon: any;
//   openSideBar: Boolean;
//   setOpenSideBar: any;
// }

// /* This component is used when one application has several other sub-applications. */
// const SubMenu: React.SFC<SubMenuProps> = ({
//   route,
//   title,
//   icon,
//   openSideBar,
//   setOpenSideBar,
// }) => {
//   const classes = useStyles();
//   const [open, setOpen] = React.useState(false);
//   let match = useRouteMatch();
//   const { pathname } = useLocation();

//   const handleClick = () => {
//     setOpen(!open);
//   };

//   useEffect(() => {
//     if (window.location.href.includes("/admin/")) {
//       handleClick();
//     }
//   }, []);

//   const theme: CustomTheme = useTheme();
//   return (
//     <div>
//       {!openSideBar ? (
//         <div>
//           <Tooltip
//             title={
//               <Typography
//                 variant="body2"
//                 style={{ fontWeight: "bold", color: "white" }}
//               >
//                 {title}
//               </Typography>
//             }
//           >
//             <ListItem className={classes.root} button onClick={handleClick}>
//               <ListItemIcon
//                 style={{
//                   minWidth: "unset",
//                   color: theme.palette.custom.orange.contrastText,
//                 }}
//               >
//                 {icon}
//               </ListItemIcon>
//               {open ? <ExpandLess /> : <ExpandMore />}
//             </ListItem>
//           </Tooltip>
//           <Collapse in={open} timeout="auto" unmountOnExit>
//             <List component="div" disablePadding>
//               {route.childRoutes &&
//                 route.childRoutes.map((subRoute: any) => (
//                   <Tooltip
//                     title={
//                       <Typography
//                         variant="body2"
//                         style={{ fontWeight: "bold", color: "white" }}
//                       >
//                         {subRoute.title}
//                       </Typography>
//                     }
//                   >
//                     <ListItem
//                       button
//                       alignItems="center"
//                       component={Link}
//                       to={match.url + subRoute.path}
//                       key={route.path}
//                       selected={pathname.includes(subRoute.path)}
//                       className={classes.nested}
//                       style={{ height: 48 }}
//                       onClick={() => setOpenSideBar(false)}
//                     >
//                       <ListItemIcon
//                         style={{
//                           minWidth: "unset",
//                           color: theme.palette.custom.orange.contrastText,
//                         }}
//                       >
//                         {subRoute.icon}
//                       </ListItemIcon>
//                     </ListItem>
//                   </Tooltip>
//                 ))}
//             </List>
//           </Collapse>
//         </div>
//       ) : (
//         <>
//           <ListItem className={classes.root} button onClick={handleClick}>
//             <ListItemIcon
//               style={{
//                 minWidth: "unset",
//                 color: theme.palette.custom.orange.contrastText,
//               }}
//             >
//               {icon}
//             </ListItemIcon>
//             <span style={{ margin: 4 }}></span>
//             <ListItemText
//               primary={
//                 <Typography
//                   variant="body2"
//                   style={{
//                     color: theme.palette.custom.orange.contrastText,
//                   }}
//                 >
//                   {title}
//                 </Typography>
//               }
//             />
//             {open ? <ExpandLess /> : <ExpandMore />}
//           </ListItem>
//           <Collapse in={open} timeout="auto" unmountOnExit>
//             <List component="div" disablePadding>
//               {route.childRoutes &&
//                 route.childRoutes.map((subRoute: any) => (
//                   <ListItem
//                     button
//                     alignItems="center"
//                     component={Link}
//                     to={match.url + subRoute.path}
//                     key={route.path}
//                     selected={pathname.includes(subRoute.path)}
//                     className={classes.nested}
//                     style={{ height: 48 }}
//                     onClick={() => setOpenSideBar(false)}
//                   >
//                     <ListItemIcon
//                       style={{
//                         minWidth: "unset",
//                         color: theme.palette.custom.orange.contrastText,
//                       }}
//                     >
//                       {subRoute.icon}
//                     </ListItemIcon>
//                     <span style={{ margin: 4 }}></span>
//                     <ListItemText
//                       primary={
//                         <Typography
//                           variant="body2"
//                           style={{
//                             color: theme.palette.custom.orange.contrastText,
//                           }}
//                         >
//                           {subRoute.title}
//                         </Typography>
//                       }
//                     />
//                   </ListItem>
//                 ))}
//             </List>
//           </Collapse>
//         </>
//       )}
//     </div>
//   );
// };

// export default SubMenu;
