import React, { useState } from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CustomTheme } from "../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: "none",
      width: "160px",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface PaginationUsingSelectorProps {
  handleChangePaginationUsingSelector: any;
  pageSize: any;
  isShift: any;
  isCustomer: any;
}

/* Show the page size selection dropdown. */
const PaginationUsingSelector: React.FunctionComponent<
  PaginationUsingSelectorProps
> = ({
  handleChangePaginationUsingSelector,
  pageSize,
  isShift,
  isCustomer,
}) => {
  // Set how many orders are showing on page and after api call
  const handleChangePageSize = (e: any) => {
    const { value } = e.target;
    handleChangePaginationUsingSelector(value);
  };

  const defaultMenuItemList = ["10", "20", "50", "100"];
  const customerMenuItemList = [
    "10",
    "25",
    "50",
    "100",
    "200",
    "500",
    "1000",
    "1500",
    "2000",
  ];

  const menuItemList = isCustomer ? customerMenuItemList : defaultMenuItemList;
  const classes = useStyles();
  return (
    <>
      <Grid
        item
        sm={6}
        md={5}
        xs={12}
        style={{ display: "flex", justifyContent: "start" }}
      >
        <TextField
          fullWidth
          id="pagination"
          select
          label="Select page size"
          value={pageSize}
          className={classes.textField}
          onChange={handleChangePageSize}
          variant="outlined"
          size="small"
        >
          {isShift && <MenuItem value="all">All</MenuItem>}
          {menuItemList.map((menuItem: any) => (
            <MenuItem value={menuItem}>{menuItem}</MenuItem>
          ))}
        </TextField>
      </Grid>
    </>
  );
};

export default PaginationUsingSelector;
