import React from "react";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import { Icon } from "@iconify/react";

import CardCommon from "../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles<any>((theme: CustomTheme) =>
  createStyles({
    root: {
      placeItems: "center",
      padding: "0px 12px 2px 10px",
      height: "108px",
      [theme.breakpoints.down("xs")]: {
        height: "80px",
        padding: "0px 12px 0px 8px",
      },
    },
    imgStyle: {
      width: "48px",
      height: "48px",
      [theme.breakpoints.down("xs")]: {
        width: "28px",
        height: "28px",
      },
    },
    currentAmountStyle: {
      display: "flex",
      justifyContent: "end",
      fontSize: "29px",
      fontWeight: 500,
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("xs")]: {
        fontSize: "19px",
      },
    },
    titleStyle: {
      display: "flex",
      justifyContent: "end",
      fontSize: "11px",
      marginTop: "1px",
      color: theme.palette.custom.grey.main,
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
        marginTop: "2px",
      },
    },
    previousAmountStyle: {
      display: "flex",
      justifyContent: "end",
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px",
      },
    },
    titleUpIconStyle: {
      color: theme.palette.custom.red.light,
      paddingRight: "2px",
      display: "flex",
      alignItems: "center",
      width: "28px",
      height: "28px",
      [theme.breakpoints.down("xs")]: {
        width: "16px",
        height: "16px",
        paddingRight: "1px",
      },
    },
    titleDownIconStyle: {
      color: theme.palette.custom.green.light,
      paddingRight: "2px",
      display: "flex",
      alignItems: "center",
      width: "28px",
      height: "28px",
      [theme.breakpoints.down("xs")]: {
        width: "16px",
        height: "16px",
        paddingRight: "1px",
      },
    },
  }),
);

export interface DashboardDetailsCardProps {
  salesTotalObject: any;
}

/*
 * DashboardDetailsCard is a React functional component responsible for rendering a card
 * displaying details of sales total. It receives a salesTotalObject containing information
 * such as current amount, previous amount, icon, and title, and renders these details
 * along with an up or down icon indicating the change in sales.
 * The component adjusts its appearance based on screen size using Material-UI's breakpoints.
 */
const DashboardDetailsCard: React.FunctionComponent<
  DashboardDetailsCardProps
> = ({ salesTotalObject }) => {
  /* This outputs whether the icon should be displayed up or down. */
  const handleDropUpDownIcon = () => {
    // If the current amount is greater than the previous amount, display the up icon.
    if (
      parseFloat(salesTotalObject.currentAmount) >
      parseFloat(salesTotalObject.previousAmount)
    ) {
      return (
        <Typography className={classes.titleDownIconStyle}>
          <Icon icon="raphael:arrowup" className={classes.titleDownIconStyle} />
        </Typography>
      );
    } else if (
      parseFloat(salesTotalObject.currentAmount) <
      parseFloat(salesTotalObject.previousAmount)
    ) {
      // If the previous amount is greater than the current amount, display the up icon.
      return (
        <Typography className={classes.titleUpIconStyle}>
          <Icon icon="raphael:arrowdown" className={classes.titleUpIconStyle} />
        </Typography>
      );
    }
  };

  const classes = useStyles();
  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Grid container className={classes.root}>
          <Grid item xs={2}>
            <img
              src={salesTotalObject.icon}
              alt="Sales Total Icon"
              className={classes.imgStyle}
            />
          </Grid>
          <Grid item xs={10} style={{ display: "flex", justifyContent: "end" }}>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {handleDropUpDownIcon()}
                <Typography className={classes.titleStyle}>
                  {salesTotalObject.title}
                </Typography>
              </div>
              <Typography className={classes.currentAmountStyle}>
                {salesTotalObject.currentAmount}
              </Typography>
              <Typography
                variant={"body2"}
                color="secondary"
                className={classes.previousAmountStyle}
              >
                {salesTotalObject.previousAmount}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </CardCommon>
    </>
  );
};

export default DashboardDetailsCard;
