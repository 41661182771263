import React from "react";
import { Chip, Typography, createStyles, makeStyles } from "@material-ui/core";
import CardCommon from "../../card/CardCommon";
import { CustomTheme } from "../../../types/customTheme";

export interface VoidSummeryProps {
  locationSelectedList: any;
  handleOnClickText: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    chipStyle: {
      backgroundColor: theme.palette.background.default,
      margin: "4px",
      "&&:focus": {
        backgroundColor: theme.palette.background.default,
      },
    },
    name: {
      fontWeight: "bold",
      paddingRight: "8px",
      color: theme.palette.custom.blue.contrastText,
    },
  }),
);

const LocationDetailsCard: React.FunctionComponent<VoidSummeryProps> = ({
  locationSelectedList,
  handleOnClickText,
}) => {
  const classes = useStyles();
  return (
    <div>
      {locationSelectedList.length > 0 && (
        <CardCommon color={"blue"}>
          <div
            style={{
              margin: "4px 2px 4px 10px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Typography className={classes.name}>
              {locationSelectedList.length > 1 ? "Locations: " : "Location: "}
            </Typography>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {locationSelectedList &&
                locationSelectedList.map((locationDetails: any) => (
                  <Chip
                    label={locationDetails.label}
                    className={classes.chipStyle}
                    onClick={() => handleOnClickText()}
                    style={{ cursor: "pointer" }}
                  />
                ))}
            </div>
          </div>
        </CardCommon>
      )}
    </div>
  );
};

export default LocationDetailsCard;
