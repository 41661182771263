import { Box, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useRouteMatch } from "react-router-dom";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllClosePeriodInfo } from "../../../../services/eatprestoApp/closePeriodService";
import ClosePeriodTypes from "./ClosePeriodLoading";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";

/**
 * ClosePeriod Component:
 * 
 * This component represents the main view for managing close periods. It fetches and displays
 * close period-related information based on the selected location. It also handles errors and
 * loading states while fetching data.
 */
const ClosePeriod: React.FunctionComponent<{}> = () => {
  const [closePeriodNodeList, setClosePeriodNodeList] = useState<any>([]);
  // const [locationData, setLocationData] = useState({ businessDisplayName: "" });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const match: any = useRouteMatch();

  /* Get all close period information using API call and response data set to closePeriodNodeList state. */
  const getClosePeriodInfo = async () => {
    try {
      const res = await fetchAllClosePeriodInfo(match.params.locationId);
      setClosePeriodNodeList(res.data.data);
      setIsLoading(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /* Get location information */
  // const fetchBasicInfo = async () => {
  //   try {
  //     const res = await getLocationBasicInfo(match.params.locationId);
  //     setLocationData(res.data.data);
  //   } catch (error) {
  //     setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
  //   }
  // };
  useEffect(() => {
    document.title = "Eat Presto - Discount";
    setIsLoading(true);
    getClosePeriodInfo();
    // fetchBasicInfo();
  }, []);

  return (
    <div>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box style={{ marginTop: "18px" }}>
        <ClosePeriodTypes
          nodes={closePeriodNodeList}
          isLoading={isLoading}
          getClosePeriodInfo={getClosePeriodInfo}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </div>
  );
};

export default withAuthority(ClosePeriod, Authorities.DISCOUNT_READ);
