import React, { useEffect, useState } from "react";
import { useRouteMatch, useLocation } from "react-router";
import { Box } from "@material-ui/core";
import _ from "lodash";

import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import { eatprestoOrderFilterObject } from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import { fetchAllDailySaleReportInfoInEatpresto } from "../../../../services/eatprestoApp/dailySaleReportService";
import moment from "moment";
import DailyOrderReportTab from "./DailyOrderReportTab";

export interface DailyOrderReportProps {}

/**
 * DailyOrderReport Component
 *
 * The DailyOrderReport component is responsible for displaying the payment summary report
 * for a specific location within the PrestoExpress application. It utilizes Material-UI
 * components and various utility functions to fetch and display payment-related information.
 */
const DailyOrderReport: React.FunctionComponent<DailyOrderReportProps> = () => {
  const [orderReportNodeListDaily, setOrderReportNodeListDaily] = useState<any>(
    [],
  );
  const [orderReportNodeListWeekly, setOrderReportNodeListWeekly] =
    useState<any>([]);
  const [orderReportNodeListMonthly, setOrderReportNodeLisMonthly] =
    useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /**
   * Function Name: handleStructureData
   *
   * This function converts raw order data into a structured format for further processing.
   * Return: An array containing structured order data
   */
  const handleStructureData = (
    input: any,
    orderTimeStart: any,
    orderTimeEnd: any,
  ) => {
    const output: any = []; // Initialize an empty array to store the structured order data
    const dateMap: any = {}; // Initialize an empty object to map each date to its corresponding order data
    const currentDate = new Date(orderTimeStart); // Convert the start time to a Date object
    const finalDate = new Date(orderTimeEnd); // Convert the end time to a Date object

    // Create a mapping for each date within the specified range with initial values
    while (currentDate <= finalDate) {
      const dateString = moment(currentDate).format("YYYY-MM-DD"); // Format the current date as a string
      dateMap[dateString] = {
        // Create an entry in the dateMap object for the current date
        date: dateString, // Store the date string
        orders: 0, // Initialize the number of orders for the date to 0
        total: 0, // Initialize the total order amount for the date to 0
        cash: 0, // Initialize the total cash amount for the date to 0
        card: 0, // Initialize the total card amount for the date to 0
        takeout: 0, // Initialize the total takeout amount for the date to 0
        delivery: 0, // Initialize the total delivery amount for the date to 0
      };

      currentDate.setDate(currentDate.getDate() + 1); // Move to the next date
    }

    // Process each order and update the corresponding dateMap entry
    input.forEach((order: any) => {
      const {
        orderDate,
        numberOfOrders,
        totalOrderAmount,
        paymentType,
        deliveryType,
      } = order;

      // If the order date doesn't exist in the dateMap, create a new entry
      if (!dateMap[orderDate]) {
        dateMap[orderDate] = {
          // Create an entry in the dateMap object for the order date
          date: orderDate, // Store the order date
          orders: 0, // Initialize the number of orders for the date to 0
          total: 0, // Initialize the total order amount for the date to 0
          cash: 0, // Initialize the total cash amount for the date to 0
          card: 0, // Initialize the total card amount for the date to 0
          takeout: 0, // Initialize the total takeout amount for the date to 0
          delivery: 0, // Initialize the total delivery amount for the date to 0
        };
      }

      // Update the values in the dateMap based on the order details
      // Increment the number of orders for the date
      dateMap[orderDate].orders += numberOfOrders;
      // Add the total order amount to the date's total
      dateMap[orderDate].total += totalOrderAmount;

      // Update the payment type-specific totals for the date
      if (paymentType === "cash") {
        // Add the total order amount to the cash total for the date
        dateMap[orderDate].cash += totalOrderAmount;
      } else if (paymentType === "card") {
        // Add the total order amount to the card total for the date
        dateMap[orderDate].card += totalOrderAmount;
      }

      // Update the delivery type-specific totals for the date
      if (deliveryType === "takeout") {
        // Add the total order amount to the takeout total for the date
        dateMap[orderDate].takeout += totalOrderAmount;
      } else if (deliveryType === "delivery") {
        // Add the total order amount to the delivery total for the date
        dateMap[orderDate].delivery += totalOrderAmount;
      }
    });

    // Iterate through the dateMap entries and format numerical values
    for (const date in dateMap) {
      // Get the current dateMap entry
      const item = dateMap[date];
      // Check if the total order amount is 0
      if (parseFloat(item.total) === 0) {
        // If the total is 0, format numerical values to "-" for display
        item.orders = "-";
        item.total = "-";
        item.cash = "-";
        item.card = "-";
        item.takeout = "-";
        item.delivery = "-";
      } else {
        // If the total is not 0, format numerical values to two decimal places
        item.total = item.total === 0 ? "0" : item.total.toFixed(2);
        item.cash = item.cash === 0 ? "0" : item.cash.toFixed(2);
        item.card = item.card === 0 ? "0" : item.card.toFixed(2);
        item.takeout = item.takeout === 0 ? "0" : item.takeout.toFixed(2);
        item.delivery = item.delivery === 0 ? "0" : item.delivery.toFixed(2);
      }

      // Push the formatted dateMap entry to the output array
      output.push(item);
    }

    // Sort the output array by date
    output.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime(),
    );

    // Return the structured order data
    return output;
  };

  /**
   * Function Name: convertToWeeklySummary
   *
   * This function converts daily order data into weekly summaries.
   * Return: An array containing weekly summary objects
   */
  const convertToWeeklySummary = (data: any) => {
    // Initialize an empty object to store weekly summaries
    const weeks: any = {};

    // Iterate through the daily order data
    data.forEach((item: any) => {
      // Convert the date string to a Date object
      const date = new Date(item.date);
      // Get the year of the current date
      const currentYear = new Date(item.date).getFullYear();

      // Calculate the week number and append the appropriate suffix
      const weekNumber = `${currentYear} ${getISOWeek(date)}${
        getISOWeek(date) === 1
          ? "st"
          : getISOWeek(date) === 2
          ? "nd"
          : getISOWeek(date) === 3
          ? "rd"
          : "th"
      } Week`;

      // If the week summary doesn't exist, create a new entry
      if (!weeks[weekNumber]) {
        weeks[weekNumber] = {
          // Create an entry in the weeks object for the week
          date: weekNumber, // Store the week identifier
          orders: 0, // Initialize the number of orders for the week to 0
          total: 0, // Initialize the total order amount for the week to 0
          cash: 0, // Initialize the total cash amount for the week to 0
          card: 0, // Initialize the total card amount for the week to 0
          takeout: 0, // Initialize the total takeout amount for the week to 0
          delivery: 0, // Initialize the total delivery amount for the week to 0
        };
      }

      // If the total order amount is not "-", update the week summary
      if (item.total !== "-") {
        weeks[weekNumber].orders += item.orders; // Add the number of orders to the week's total
        weeks[weekNumber].total += parseFloat(item.total); // Add the total order amount to the week's total
        weeks[weekNumber].cash += parseFloat(item.cash); // Add the cash amount to the week's total
        weeks[weekNumber].card += parseFloat(item.card); // Add the card amount to the week's total
        weeks[weekNumber].takeout += parseFloat(item.takeout); // Add the takeout amount to the week's total
        weeks[weekNumber].delivery += parseFloat(item.delivery); // Add the delivery amount to the week's total
      }
    });

    // Iterate through the weeks object and format numerical values
    for (const week in weeks) {
      // Check if the total order amount is 0
      if (parseFloat(weeks[week].total) === 0) {
        // If the total is 0, format numerical values to "-" for display
        weeks[week].orders = "-";
        weeks[week].total = "-";
        weeks[week].cash = "-";
        weeks[week].card = "-";
        weeks[week].takeout = "-";
        weeks[week].delivery = "-";
      } else {
        // If the total is not 0, format numerical values to two decimal places
        weeks[week].total =
          weeks[week].total === 0 ? "0" : weeks[week].total.toFixed(2);
        weeks[week].cash =
          weeks[week].cash === 0 ? "0" : weeks[week].cash.toFixed(2);
        weeks[week].card =
          weeks[week].card === 0 ? "0" : weeks[week].card.toFixed(2);
        weeks[week].takeout =
          weeks[week].takeout === 0 ? "0" : weeks[week].takeout.toFixed(2);
        weeks[week].delivery =
          weeks[week].delivery === 0 ? "0" : weeks[week].delivery.toFixed(2);
      }
    }

    // Return an array of weekly summary objects
    return Object.values(weeks);
  };

  /**
   * Function Name: getISOWeek
   *
   * This function calculates the ISO week number for a given date.
   * Return: The ISO week number of the given date
   */
  const getISOWeek = (date: any) => {
    // Create a new Date object from the input date
    const temp: any = new Date(date);
    // Calculate the day number (0-6) with Sunday as 0
    const dayNumber = (temp.getDay() + 6) % 7;
    // Adjust the date to the nearest Thursday (ISO week starts on Monday)
    temp.setDate(temp.getDate() - dayNumber + 3);

    // Create a new Date object for the start of the year
    const startOfYear: any = new Date(temp.getFullYear(), 0, 1);
    // Calculate the week number
    const weekNumber = Math.floor(
      (temp - startOfYear) / (24 * 60 * 60 * 1000 * 7) + 1,
    );

    // Return the calculated ISO week number
    return weekNumber;
  };

  /**
   * Function Name: groupByMonth
   *
   * This function groups structureData by month and calculates aggregated values for each month.
   * Return: An array of objects containing aggregated structureData for each month
   */
  const groupByMonth = (structureData: any) => {
    // Initialize an empty object to store grouped structureData
    const output: any = {};

    // Iterate through each item in the input structureData array
    structureData.forEach((item: any) => {
      // Extract year and month from the date string
      const [year, month] = item.date.split("-");

      // Get the month name from the date
      const monthName = moment(item.date).format("MMMM");

      // Create a unique key for each month
      const key = `${year}-${month}`;

      // If the key doesn't exist in the output object, create a new entry with initial values
      if (!output[key]) {
        output[key] = {
          date: `${year} ${monthName}`,
          orders: 0,
          total: 0,
          cash: 0,
          card: 0,
          takeout: 0,
          delivery: 0,
        };
      }

      // Update the aggregated values based on the current item
      if (item.total !== "-") {
        output[key].orders += item.orders;
        output[key].total += parseFloat(item.total);
        output[key].cash += parseFloat(item.cash);
        output[key].card += parseFloat(item.card);
        output[key].takeout += parseFloat(item.takeout);
        output[key].delivery += parseFloat(item.delivery);
      }
    });

    // Iterate through each month in the output object and format numerical values
    for (const month in output) {
      if (parseFloat(output[month].total) === 0) {
        output[month].orders = "-";
        output[month].total = "-";
        output[month].cash = "-";
        output[month].card = "-";
        output[month].takeout = "-";
        output[month].delivery = "-";
      } else {
        output[month].total =
          output[month].total === 0 ? "0" : output[month].total.toFixed(2);
        output[month].cash =
          output[month].cash === 0 ? "0" : output[month].cash.toFixed(2);
        output[month].card =
          output[month].card === 0 ? "0" : output[month].card.toFixed(2);
        output[month].takeout =
          output[month].takeout === 0 ? "0" : output[month].takeout.toFixed(2);
        output[month].delivery =
          output[month].delivery === 0
            ? "0"
            : output[month].delivery.toFixed(2);
      }
    }

    // Return an array of values from the output object
    return Object.values(output);
  };

  /**
   * Function Name: handleOrderMonthlyWeeklyYearly
   *
   * This function handles the processing of order data for monthly, weekly, and yearly reports.
   */
  const handleOrderMonthlyWeeklyYearly = (
    response: any,
    orderTimeStart: any,
    orderTimeEnd: any,
  ) => {
    // Check if the response is not empty
    if (!_.isEmpty(response)) {
      // Process the data to get the structured format
      const structureData = handleStructureData(
        response,
        orderTimeStart,
        orderTimeEnd,
      );

      // Convert the structured data to weekly summary
      const outputWeekly = convertToWeeklySummary(structureData);

      // Group the structured data by month
      const outputMonthly = groupByMonth(structureData);

      // Set the weekly, daily, and monthly reports in the state variables
      setOrderReportNodeListWeekly(outputWeekly);
      setOrderReportNodeListDaily(structureData);
      setOrderReportNodeLisMonthly(outputMonthly);
    } else {
      // If the response is empty, set empty arrays for weekly, daily, and monthly reports
      setOrderReportNodeListWeekly([]);
      setOrderReportNodeListDaily([]);
      setOrderReportNodeLisMonthly([]);
    }

    // Set loading state to false
    setIsLoading(false);
  };

  /**
   * Function Name: getDailySaleReportInfoInEatpresto
   *
   * Retrieves daily sale report information for Eatpresto and handles data processing.
   *
   */
  const getDailySaleReportInfoInEatpresto = async (filter: any) => {
    // Clear daily order report list
    setOrderReportNodeListDaily([]);
    try {
      // Fetch daily sale report information from the server
      const response = await fetchAllDailySaleReportInfoInEatpresto(
        idToken,
        match.params.locationId,
        filter,
      );

      // Handle processing of order data for monthly, weekly, and yearly reports
      handleOrderMonthlyWeeklyYearly(
        response.data.data, // Order data from the response
        filter.orderTimeStart, // Start time for filtering orders
        filter.orderTimeEnd, // End time for filtering orders
      );
    } catch (err) {
      // Set loading state to false
      setIsLoading(false);
      // Set error message for unexpected error
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Fetches and updates the location selector list based on the provided search name.
   *
   * @param {string} searchName - The search name used for filtering locations.
   */
  const getAllFilterLocation = async (searchName: any) => {
    try {
      // Fetch all filter locations using the provided search name
      const response = await fetchAllFilterLocations(searchName);

      // Process the fetched data to create a list of
      if (!_.isEmpty(response.data.data)) {
        const locationList = response.data.data.map((location: any) => ({
          id: location.id,
          label: location.businessDisplayName,
        }));

        // Update the location selector list and set the flag indicating location information is retrieved
        setLocationSelectorList(locationList);
      } else {
        setLocationSelectorList([]);
      }

      setIsGetLocationInfo(true);
    } catch (error) {
      // Handle errors by setting an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * useEffect hook to set the document title and fetch initial location filter data.
   * It runs only once when the component mounts.
   */
  useEffect(() => {
    // Set the document title for the page
    document.title = "Eatpresto - Daily Orders Report";

    // Fetch initial location filter data with an empty searchName
    getAllFilterLocation("");
  }, []);

  /**
   * Handles the filter data and triggers the update of the payment report.
   */
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    setIsLoading(true);

    // Fetch and update the payment report information based on the provided filter data and shift flag
    getDailySaleReportInfoInEatpresto(filterData);

    // Set the filter data and shift flag
    setFilterData(filterData);
    setBasedOnShift(basedOnShift);
  };

  /**
   * Handles the click event on the text, opens the filter card, and scrolls to the top.
   */
  const handleOnClickText = () => {
    setOpenFilterCard(true);

    // Scroll to the top of the window with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={eatprestoOrderFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={orderReportNodeListDaily}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          filterTitle="Daily Orders report for"
          topic="Daily Orders Report"
          isNeedFilterDetails={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
          payInOut={false}
          isInitialLoad={isInitialLoad}
          setIsInitialLoad={setIsInitialLoad}
          initialParamSize={5}
          isOrderReport={true}
          isWeekDateRange={true}
        >
          <DailyOrderReportTab
            orderReportNodeListDaily={orderReportNodeListDaily}
            filterDetails={filterDetails}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
            orderReportNodeListWeekly={orderReportNodeListWeekly}
            orderReportNodeListMonthly={orderReportNodeListMonthly}
          />
        </SaleReport>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(DailyOrderReport, Authorities.DASHBOARD_READ);
