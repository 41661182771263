import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Box } from "@material-ui/core";
import _ from "lodash";

import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import { fetchAllPaymentReportInfo } from "../../../../services/salesApp/paymentReportService";
import {
  cashierSummaryFilterObject,
  discountSummaryFilterObject,
  paymentFilterObject,
} from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import { fetchAllCashierSummaryReportInfo } from "../../../../services/salesApp/cashierSummaryReportService";
import { fetchAllUserInfo } from "../../../../services/salesApp/salesService";
import { fetchAllHardwareReportInfo } from "../../../../services/salesApp/revenueCenterReport";
import DiscountSummaryReportNode from "./DiscountSummaryReportNode";
import { fetchAllDiscountInfo } from "../../../../services/discountInfoService";
import {   fetchAllDiscountSummaryReportInfo } from "../../../../services/salesApp/discountSummaryReportService";
import { fetchAllDiscountInfoSales } from "../../../../services/globalService";

/**
 * DiscountSummaryReport Component
 *
 * The DiscountSummaryReport component is responsible for displaying the payment summary report
 * for a specific location within the PrestoExpress application. It utilizes Material-UI
 * components and various utility functions to fetch and display payment-related information.
 */
const DiscountSummaryReport: React.FunctionComponent<{}> = () => {
  const [discountReportNodeList, setDiscountReportNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [userDetails, setUserDetails] = useState<any>({});
  const [discountInfo, setDiscountInfo] = useState<any>({});
  const [terminalSelectorList, setTerminalSelectorList] = useState<any>([]);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  const getUserInfo = async () => {
    try {
      const res = await fetchAllUserInfo(idToken, match.params.locationId);
      console.log("amsdsadsdfdsfsdf", res);
      setUserDetails(res.data.data);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const getDiscountInfo = async () => {
    try {
      const res = await fetchAllDiscountInfoSales(match.params.locationId);
      console.log("sfsdgfdg45fsdffgds", res)
      setDiscountInfo(res.data.data);
      setIsGetLocationInfo(true);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Asynchronous function to fetch payment report information based on filter criteria.
   * It updates the component state with the fetched data or handles errors appropriately.
   */
  const getDiscountReportInfo = async (filter: any, basedOnShift: boolean) => {
    // Clear the existing payment report node list
    setDiscountReportNodeList([]);
    try {
      // Fetch payment report information from the server
      const response = await fetchAllDiscountSummaryReportInfo(
        idToken,
        match.params.locationId,
        filter,
      );

      setDiscountReportNodeList(response.data.data);
      setIsLoading(false);

      // Update the state with the fetched data or an empty array if no data is available
    } catch (err) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Fetches and updates the location selector list based on the provided search name.
   *
   * @param {string} searchName - The search name used for filtering locations.
   */
  const getAllFilterLocation = async (searchName: any) => {
    try {
      // Fetch all filter locations using the provided search name
      const response = await fetchAllFilterLocations(searchName);

      // Process the fetched data to create a list of
      if (!_.isEmpty(response.data.data)) {
        const locationList = response.data.data.map((location: any) => ({
          id: location.id,
          label: location.businessDisplayName,
        }));

        // Update the location selector list and set the flag indicating location information is retrieved
        setLocationSelectorList(locationList);
        getDiscountInfo();
      } else {
        setLocationSelectorList([]);
      }
    } catch (error) {
      // Handle errors by setting an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * useEffect hook to set the document title and fetch initial location filter data.
   * It runs only once when the component mounts.
   */
  useEffect(() => {
    // Set the document title for the page
    document.title = "Sale - Discount Summary Report";

    getUserInfo();
    // Fetch initial location filter data with an empty searchName
    getAllFilterLocation("");
  }, []);

  /**
   * Handles the filter data and triggers the update of the payment report.
   */
  const handleFilterData = (
    filterData: any,
    basedOnShift: boolean,
    count: any,
  ) => {
    setIsLoading(true);

    // Fetch and update the payment report information based on the provided filter data and shift flag
    getDiscountReportInfo(filterData, basedOnShift);
    console.log("Sdfdfdgdfgdfsdf", filterData);
    // Set the filter data and shift flag
    setFilterData(filterData);
    setBasedOnShift(basedOnShift);
  };

  /**
   * Handles the click event on the text, opens the filter card, and scrolls to the top.
   */
  const handleOnClickText = () => {
    setOpenFilterCard(true);

    // Scroll to the top of the window with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={discountSummaryFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={discountReportNodeList}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          filterTitle="Discount summary report for"
          topic="Discount Summary"
          isNeedFilterDetails={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
          payInOut={false}
          isInitialLoad={isInitialLoad}
          setIsInitialLoad={setIsInitialLoad}
          initialParamSize={5}
          isRemoveBasedOnShift={true}
          isRemoveDueAndOrderTime={true}
          isNeedTerminal={false}
          terminalSelectorList={terminalSelectorList}
        >
          <DiscountSummaryReportNode
            discountReportNodeList={discountReportNodeList}
            locationSelectorList={locationSelectorList}
            filterDetails={filterDetails}
            filterData={filterData}
            basedOnShift={basedOnShift}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
            userDetails={userDetails}
            discountInfo={discountInfo}
          />
        </SaleReport>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(DiscountSummaryReport, Authorities.SALE_READ);
