import { ThemeProvider } from "@material-ui/core/styles";
import React, { useContext } from "react";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { GlobalContext } from "../../root/App";
import AccountsAppRoutes from "./routes/AccountsAppRoutes";
import { initTheme } from "./theme/primary";
import CssBaseline from "@material-ui/core/CssBaseline";

export interface PrestoExpressAccountsAppProps {}

const PrestoExpressAccountsApp: React.FunctionComponent<
  PrestoExpressAccountsAppProps
> = () => {
  const { globalState } = useContext(GlobalContext);

  const theme = initTheme({ isDark: true });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PrimaryLayout sidebarRoutes={[]}>
        <AccountsAppRoutes />
      </PrimaryLayout>
    </ThemeProvider>
  );
};

export default PrestoExpressAccountsApp;
