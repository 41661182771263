import React, { useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import _ from "lodash";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import TextfieldCommon from "../../../../components/textField/TextfieldCommon";

export interface passwordAddModalProps {
  newPassword: any;
  setNewPassword: any;
  setSuccess: any;
}

/**
 * PasswordAdd Component:
 *
 * This React component represents the password section in the permission add modal. It
 * displays a generated password, allows copying the password, and provides an option
 * to show/hide the password.
 */

const PasswordAdd: React.FunctionComponent<passwordAddModalProps> = ({
  newPassword,
  setNewPassword,
  setSuccess,
}) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  /**
   * handleChangeVisible Function:
   *
   * This function is responsible for toggling the visibility of the password.
   * It updates the state variable isShowPassword to the opposite of its current value,
   * thereby toggling between showing and hiding the password.
   */
  const handleChangeVisible = () => {
    setIsShowPassword(!isShowPassword);
  };

  /**
   * handleCopyPassword Function:
   *
   * This function is responsible for copying the generated password to the clipboard.
   * It sets a success message, indicating that the password has been copied, and then
   * uses the Clipboard API to write the generated password to the clipboard.
   */
  const handleCopyPassword = () => {
    // Set a success message to indicate that the password has been copied
    setSuccess("Copied!");

    // Use the Clipboard API to write the generated password to the clipboard
    navigator.clipboard.writeText(newPassword);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography align="left" variant="body1" style={{ marginLeft: "4px" }}>
          Your user account has been successfully created. A unique password has
          been generated for your initial login. Please copy the password below,
          as it will not be displayed again for security reasons:
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ margin: "16px 0px", display: "flex", alignItems: "center" }}
      >
        <TextfieldCommon
          id="password"
          name="password"
          type={isShowPassword ? "text" : "password"}
          label=""
          onChange={() => setNewPassword(newPassword)}
          value={newPassword}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleChangeVisible}
                  edge="end"
                >
                  {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Tooltip title="Copy Password">
          <Button style={{ marginLeft: "24px" }} onClick={handleCopyPassword}>
            <FileCopyOutlinedIcon />
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Typography align="left" variant="body1" style={{ marginLeft: "4px" }}>
          For your security, we recommend changing this password upon your first
          login.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PasswordAdd;
