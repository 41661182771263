import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { useRouteMatch } from "react-router-dom";
import {
  createWrapper,
  fetchSingleProductWrapper,
  updateWrapperInfo,
} from "../../../../../services/menuApp/menuService";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_UPDATED,
} from "../../../../../utils/consts";
import { CustomTheme } from "../../../../../types/customTheme";
import { AxiosError } from "axios";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    button: {
      position: "relative",
      overflow: "hidden",
      borderBottomRightRadius: "10px",
    },
    imgBoxStyle: {
      margin: "7px",
      marginLeft: "10px",
      borderRadius: "10px",
      height: "120px",
      [theme.breakpoints.down("sm")]: {
        height: "92px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
    colorStripStyle: {
      width: "4px",
      height: "112px",
      borderRadius: "10px",
      marginRight: "12px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
      },
    },
    titleStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",

      [theme.breakpoints.down("sm")]: {
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    imgBoxNotAvailableStyle: {
      marginRight: "7px",
      marginTop: "10px",
      borderRadius: "10px",
      marginBottom: "10px",
      height: "114px",
      width: "114px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
        width: "80px",
      },
      backgroundColor: theme.palette.background.imgBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      objectFit: "cover",
      width: "114px",
      height: "114px",
      [theme.breakpoints.down("sm")]: {
        width: "88px",
        height: "84px",
      },
    },
    boxStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    },
    priceStyle: {
      marginBottom: "4px",
    },
  }),
);

export interface DirectoryAddModalProps {
  item: any;
  wrapper: any;
  category: any;
  isOpenWrapperEditModal: any;
  isRoot: any;
  setError: any;
  menuNodeList: any;
  menuId: any;
  setMenuNodeList: any;
  setSuccess: any;
  handleOpenDirectoryEditModal: any;
}

const DirectoryEditAndVisibility: React.FunctionComponent<
  DirectoryAddModalProps
> = ({
  item,
  wrapper,
  category,
  isOpenWrapperEditModal,
  isRoot,
  setError,
  menuNodeList,
  menuId,
  setMenuNodeList,
  setSuccess,
  handleOpenDirectoryEditModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const match: any = useRouteMatch();
  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  const handleGetSingleProductWrapperValidChange = async (product: any) => {
    try {
      const res = await fetchSingleProductWrapper(
        match.params.locationId,
        product.id,
      );
      handleChangeValidInWrapper(product, res.data.data.version);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  const handleChangeValidInWrapper = async (
    item: any,
    productVersion?: any,
  ) => {
    setError("");
    setIsLoading(true);
    const body = {
      locationId: match.params.locationId,
      valid: !item.valid,
      id: item.id,
      version: productVersion || item.version,
    };

    try {
      let summary = [];

      if (!item.valid !== item.valid) {
        summary.push(
          `Product id ${item.id} valid changed from ${
            item.valid
          } to ${!item.valid}`,
        );
      }

      const summaryString = summary.join("\\n");

      const res = await updateWrapperInfo(
        match.params.locationId,
        body,
        "Menus Changed",
        summaryString,
        "menu",
      );
      if (res.data.status === 409) {
        handleGetSingleProductWrapperValidChange(item);
      } else {
        const cloneMenuNode = _.cloneDeep(menuNodeList);
        if (isRoot) {
          cloneMenuNode[menuId].category[category.id].item[res.data.data.id] =
            res.data.data;
        } else {
          const updateProductName = (node: any) => {
            // Check if the current node is a product and has the specified ID
            if (node.id === item.id) {
              node.valid = res.data.data.valid;
              node.version = res.data.data.version;
              return true; // Indicates the product was found and updated
            }

            // Recursively traverse nested wrappers
            if (node.wrapper) {
              for (const key in node.wrapper) {
                if (Object.hasOwnProperty.call(node.wrapper, key)) {
                  const child = node.wrapper[key];
                  if (updateProductName(child)) {
                    return true; // Exit early if the product was found and updated
                  }
                }
              }
            }

            return false; // Indicates the product was not found in this branch
          };

          // Find and update the product name
          if (
            cloneMenuNode[menuId] &&
            cloneMenuNode[menuId].category[category.id]
          ) {
            updateProductName(
              cloneMenuNode[menuId].category[category.id].wrapper[wrapper.id],
            );
          }
        }

        setMenuNodeList(cloneMenuNode);
        setIsLoading(false);
        setSuccess(SUCCESSFULLY_UPDATED);
      }
    } catch (error) {
      const err: any = error as AxiosError;

      const errorMessage = err.response ? err.response.data : err.message;

      setError(errorMessage.error);
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        size={"small"}
        onClick={() =>
          handleOpenDirectoryEditModal(
            item,
            wrapper,
            category,
            isOpenWrapperEditModal,
            isRoot,
          )
        }
        className={classes.button}
        style={{
          minWidth: "32px",
          height: "36px",
          backgroundColor: theme.palette.custom.yellow.main,
          marginRight: "5px",
        }}
      >
        <EditIcon
          style={{
            fontSize: "16px",
            color: theme.palette.custom.yellow.contrastText,
          }}
        />
      </Button>

      <Button
        size={"small"}
        className={classes.button}
        style={{
          minWidth: "32px",
          height: "36px",
          marginLeft: "12px",
          backgroundColor: item?.valid
            ? theme.palette.custom.green.main
            : theme.palette.custom.red.main,
        }}
        onClick={() => handleChangeValidInWrapper(item)}
      >
        {/* VisibilityIcon */}
        {isLoading ? (
          <CircularProgress color="inherit" size={16} />
        ) : item?.valid ? (
          <VisibilityIcon
            style={{
              fontSize: "16px",
              color: theme.palette.custom.red.contrastText,
            }}
          />
        ) : (
          <VisibilityOffIcon
            style={{
              fontSize: "16px",
              color: theme.palette.custom.red.contrastText,
            }}
          />
        )}
      </Button>
    </>
  );
};

export default DirectoryEditAndVisibility;
