import axios from "axios";

const SALE_DISCOUNT_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS}/core/modules/${locationId}/discounts`;

// eslint-disable-next-line import/prefer-default-export
export async function createCustomToken(token) {
  return axios({
    url: `${process.env.REACT_APP_CUSTOM_TOKEN}/create_custom_token`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export async function fetchAllDiscountInfoSales(locationId) {
  return axios({
    url: SALE_DISCOUNT_INFO_GET_ALL(locationId),
    method: "GET",
  });
}
