import React from "react";
import _ from "lodash";
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import { CustomTheme } from "../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: "none",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        height: "16px",
      },
      '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
        {
          "-webkit-appearance": "none",
          margin: 0,
        },
    },
    buttonStyle: {
      backgroundColor: theme.palette.custom.blue.main,
      "&:hover": {
        backgroundColor: theme.palette.custom.blue.light,
      },
      color: "white",
      height: "36px",
      padding: "0px",
      marginTop: "2px",
    },
    buttonStyleEmpty: {
      backgroundColor: theme.palette.background.entity_background,
      "&:hover": {
        backgroundColor: theme.palette.background.entity_background,
      },
      color: "white",
      height: "36px",
      padding: "0px",
      marginTop: "2px",
    },
  }),
);

export interface IncrementDecrementTextboxProps {
  handleOrderQty: any;
  value: any;
  id: string;
  handleIncrement: any;
  handleDecrement: any;
  isDisable?: any;
  displaySuffix: any;
  handleOrderQtyEmpty: any;
  isNeedNegativeIcon: any
}

/**
 * This component provides an input field with increment and decrement buttons for adjusting quantity values.
 * It is commonly used in various parts of the application to allow users to modify quantities easily.
 */
const IncrementDecrementTextboxDefault: React.FunctionComponent<
  IncrementDecrementTextboxProps
> = ({
  handleOrderQty,
  value,
  id,
  handleIncrement,
  handleDecrement,
  isDisable,
  displaySuffix,
  handleOrderQtyEmpty,
  isNeedNegativeIcon
}) => {
  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Button
        className={
          !isDisable || parseFloat(value) > 0 || value
            ? classes.buttonStyle
            : classes.buttonStyleEmpty
        }
        disabled={value === undefined}
        style={{ maxWidth: "44px", minWidth: "44px" }}
        onClick={(event: any) => {
          event.stopPropagation();
          handleDecrement(id);
        }}
      >
        <RemoveIcon
          style={{
            fontSize: "16px",
          }}
        />
      </Button>
      {!isDisable ? (
        <TextField
          id="outputMultiplier"
          name="outputMultiplier"
          label=""
          type="number"
          style={{
            width: "148px",
            marginLeft: "2px",
            marginRight: "2px",
          }}
          variant="outlined"
          className={classes.textField}
          margin="dense"
          disabled={isDisable}
          value={value}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(event: any) => {
            event.stopPropagation();
            handleOrderQty(id, event);
          }}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment style={{ fontSize: "7px" }} position="end">
                  <Typography style={{ fontSize: "12px" }}>
                    {displaySuffix}
                  </Typography>
                </InputAdornment>
              </>
            ),
            startAdornment: (
              <>
                {isNeedNegativeIcon&&<InputAdornment position="start">-</InputAdornment>}
              </>
            ),
            style: { fontSize: "24px" },
          }}
        />
      ) : (
        <Button
          style={{
            background: "transparent",
            width: "80px",
            marginLeft: "2px",
            marginRight: "2px",
            fontSize: "20px",
            color: theme.palette.background.imgBackground,
            maxWidth: "44px",
            minWidth: "44px",
          }}
          onClick={(event: any) => {
            handleOrderQtyEmpty(id, "10");
          }}
        >
          N/A
        </Button>
      )}
      <Button
        className={!isDisable ? classes.buttonStyle : classes.buttonStyleEmpty}
        disabled={isDisable}
        onClick={(event: any) => {
          event.stopPropagation();
          handleIncrement(id);
        }}
        style={{ maxWidth: "44px", minWidth: "44px" }}
        size="small"
      >
        <AddIcon
          style={{
            fontSize: "16px",
          }}
        />
      </Button>
    </div>
  );
};

export default IncrementDecrementTextboxDefault;
