import React, { useCallback, useEffect, useState } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import CardCommon from "../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../types/customTheme";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";

export interface StockItemDetailsNodeProps {
  nodeData: any;
  locationName: any;
  updateTime: any;
  createdTime: any;
  handleOpenEditStockItemsModal: any;
  handleOpenEditMapProductsItemsModal: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    gridStyle: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "20px",
    },
  }),
);

/**
 * StockItemDetailsNode Component
 *
 * This component is responsible for displaying details of a stock item node within the orders details tab.
 * It displays information such as location, name, barcode, critical threshold, input multiplier, input suffix,
 * SKU, warning threshold, display suffix, created time, and update time.
 * The component also provides options to update the stock item and add map products.
 */
const StockItemDetailsNode: React.FunctionComponent<StockItemDetailsNodeProps> = ({
  nodeData,
  locationName,
  updateTime,
  createdTime,
  handleOpenEditStockItemsModal,
  handleOpenEditMapProductsItemsModal,
}) => {
  const [deliveryType, setDeliveryType] = useState("");

  const [criticalThreshold, setCriticalThreshold] = useState("");
  const [inputMultiplier, setInputMultiplier] = useState("");
  const [inputSuffix, setInputSuffix] = useState("");
  const [sku, setSku] = useState("");
  const [warningThreshold, setWarningThreshold] = useState("");
  const [name, setName] = useState("");
  const [barcode, setBarcode] = useState("");
  const [displaySuffix, setDisplaySuffix] = useState("");

  /* Update states after getting the order details information. */
  const setNodeDataToState = useCallback((nodeData) => {
    const {
      barcode,
      criticalThreshold,
      inputMultiplier,
      inputSuffix,
      sku,
      warningThreshold,
      name,
      displaySuffix,
    } = nodeData;
    setDisplaySuffix(displaySuffix);
    setBarcode(barcode);
    setWarningThreshold(warningThreshold);
    setName(name);
    setSku(sku);
    setInputSuffix(inputSuffix);
    setInputMultiplier(inputMultiplier);
    setCriticalThreshold(criticalThreshold);
    setDeliveryType(deliveryType);
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  return (
    <CardCommon backgroundColor={"entity_background"}>
      <Grid container style={{ margin: "10px", marginRight: "50px" }}>
        <Grid item xs={12} md={6}>
          <CardCommon backgroundColor={"entity_highlight_background"}>
            <Grid container style={{ marginTop: "12px" }}>
              <Grid
                item
                xs={6}
                style={{
                  marginBottom: "8px",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <ButtonCommon
                  style={{
                    fontSize: 11,
                    marginRight: 4,
                    width: "160px",
                    marginLeft: "20px",
                  }}
                  variant="contained"
                  color={"green"}
                  onClick={() => handleOpenEditStockItemsModal(nodeData)}
                >
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: "inherit",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    {" "}
                    Update Stock Item
                  </Typography>
                </ButtonCommon>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  marginBottom: "8px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <ButtonCommon
                  style={{
                    fontSize: 11,
                    width: "160px",
                    marginRight: "16px",
                  }}
                  variant="contained"
                  color={"green"}
                  onClick={() =>
                    handleOpenEditMapProductsItemsModal(nodeData.id)
                  }
                >
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: "inherit",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    {" "}
                    Add Map Products
                  </Typography>
                </ButtonCommon>
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "8px" }}>
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    paddingLeft: "20px",
                    marginTop: "4px",
                  }}
                  align="left"
                >
                  Location
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.gridStyle}
                style={{ marginTop: "4px", marginBottom: "8px" }}
              >
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {locationName}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "8px" }}>
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    paddingLeft: "20px",
                    marginTop: "4px",
                  }}
                  align="left"
                >
                  Name
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.gridStyle}
                style={{ marginTop: "4px", marginBottom: "8px" }}
              >
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "8px" }}>
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    paddingLeft: "20px",
                    marginTop: "4px",
                  }}
                  align="left"
                >
                  Barcode
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.gridStyle}
                style={{ marginTop: "4px", marginBottom: "8px" }}
              >
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {barcode}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "8px" }}>
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    paddingLeft: "20px",
                    marginTop: "4px",
                  }}
                  align="left"
                >
                  Critical Threshold
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.gridStyle}
                style={{ marginTop: "4px", marginBottom: "8px" }}
              >
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {criticalThreshold}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "8px" }}>
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    paddingLeft: "20px",
                    marginTop: "4px",
                  }}
                  align="left"
                >
                  Input Multiplier
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.gridStyle}
                style={{ marginTop: "4px", marginBottom: "8px" }}
              >
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {inputMultiplier}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "8px" }}>
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    paddingLeft: "20px",
                    marginTop: "4px",
                  }}
                  align="left"
                >
                  Input Suffix
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.gridStyle}
                style={{ marginTop: "4px", marginBottom: "8px" }}
              >
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {inputSuffix}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "8px" }}>
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    paddingLeft: "20px",
                    marginTop: "4px",
                  }}
                  align="left"
                >
                  Sku
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.gridStyle}
                style={{ marginTop: "4px", marginBottom: "8px" }}
              >
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {sku}
                </Typography>
              </Grid>

              <Grid item xs={5} style={{ marginBottom: "8px" }}>
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    paddingLeft: "20px",
                    marginTop: "4px",
                  }}
                  align="left"
                >
                  Warning Threshold
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.gridStyle}
                style={{ marginTop: "4px", marginBottom: "8px" }}
              >
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {warningThreshold}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "8px" }}>
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    paddingLeft: "20px",
                    marginTop: "4px",
                  }}
                  align="left"
                >
                  Display Suffix
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.gridStyle}
                style={{ marginTop: "4px", marginBottom: "8px" }}
              >
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {displaySuffix}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "8px" }}>
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    paddingLeft: "20px",
                    marginTop: "4px",
                  }}
                  align="left"
                >
                  Created Time
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.gridStyle}
                style={{ marginTop: "4px", marginBottom: "8px" }}
              >
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {createdTime}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ marginBottom: "8px" }}>
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    paddingLeft: "20px",
                    marginTop: "4px",
                  }}
                  align="left"
                >
                  Update Time
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                className={classes.gridStyle}
                style={{ marginTop: "4px", marginBottom: "8px" }}
              >
                <Typography
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {updateTime}
                </Typography>
              </Grid>
            </Grid>{" "}
          </CardCommon>
        </Grid>
      </Grid>
    </CardCommon>
  );
};

export default StockItemDetailsNode;
