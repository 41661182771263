import React from "react";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { CustomTheme } from "../../../../types/customTheme";

export interface VoidSummeryProps {
  topic: any;
  filterDate: any;
  handleOnClickText: any;
}

const SalePageTitleAndLocationDetails: React.FunctionComponent<
  VoidSummeryProps
> = ({ topic, filterDate, handleOnClickText }) => {
  const theme: CustomTheme = useTheme();
  return (
    <Grid
      container
      // direction="row"
      // alignItems="center"
    >
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "start",
          marginTop: "36px",
        }}
      >
        <Typography
          variant="h4"
          style={{ fontWeight: "bold", cursor: "pointer" }}
          color="secondary"
          align="left"
          onClick={() => handleOnClickText()}
        >
          {topic}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "start" }}
        onClick={() => handleOnClickText()}
      >
        <Typography
          variant="body1"
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: theme.palette.custom.orange.contrastText,
          }}
        >
          {filterDate}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SalePageTitleAndLocationDetails;
