import { axiosClient } from "../../../services/client";

const ThemeService = {
  /**
   * Get constructed url for theme api
   */
  getThemeConfigUrl: (locationId: any) => {
    return `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/theme`;
  },

  /**
   * set you default headers
   */
  getDefaultHeaders: () => {
    return { "Content-Type": "application/json" };
  },

  /**
   * fetch config from the server
   */
  getThemeConfig: (locationId: string) => {
    return axiosClient({
      url: ThemeService.getThemeConfigUrl(locationId),
      method: "GET",
      headers: {
        "X-Role-Location": locationId,
        ...ThemeService.getDefaultHeaders(),
      },
    });
  },

  /**
   * update theme config on server
   */
  updateThemeConfig: (locationId: string, data: Object) => {
    return axiosClient({
      url: ThemeService.getThemeConfigUrl(locationId),
      method: "PUT",
      data: data,
      headers: {
        "X-Role-Location": locationId,
        ...ThemeService.getDefaultHeaders(),
      },
    });
  },
};

export default ThemeService;
