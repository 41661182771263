import { axiosClient } from "../client";


const queryString = require("query-string");

const VOID_TRANSACTION_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/void-transaction`;

export const fetchAllVoidTransactionInfo = (token, locationId, size, currentPage, shortingMethod, shortingHeaderName, filterData) => 
axiosClient({
  method: "GET",
  url: queryString.stringifyUrl({
    url: VOID_TRANSACTION_INFO_GET_ALL(locationId),
    query: { size: size, page: currentPage, sort: [shortingHeaderName, shortingMethod], where: filterData }}, {sort: false, arrayFormat: 'comma', skipNull: true, skipEmptyString: true},
    
  ),
  headers: {
    "X-Role-Location": locationId,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});
