import _ from "lodash";
import moment from "moment";

/* The filter location and date range are returned. */
export const handleFilterDataDetails = (
  locationFilterList: any,
  salesStartDate: any,
  salesEndDate: any,
) => {
  let location: any = "";
  let date: any = "";
  if (!_.isEmpty(locationFilterList)) {
    location = locationFilterList.join(", ");
  }

  if (!_.isEmpty(salesEndDate)) {
    if (salesStartDate === salesEndDate) {
      date = moment(salesStartDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    } else {
      date =
        moment(salesStartDate, "YYYY-MM-DD").format("YYYY-MM-DD") +
        " - " +
        moment(salesEndDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    }
  }

  const allFilteredList = [location, date];

  const filtered = allFilteredList.filter((data: any) => !_.isEmpty(data));

  return filtered.join("*");
};
