import React from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";

import _ from "lodash";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import CardCommon from "../../../../../components/card/CardCommon";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";

export interface DirectoryOrProductSelectionModalProps {
  isOpen: any;
  setIsOpen: any;
  handleOpenDirectoryModal: any;
  handleOpenProductModal: any;
  handleCloseDirectoryOrProductSelectionModal: any;
}

const DirectoryOrProductSelectionModal: React.FunctionComponent<
  DirectoryOrProductSelectionModalProps
> = ({
  isOpen,
  setIsOpen,
  handleOpenDirectoryModal,
  handleOpenProductModal,
  handleCloseDirectoryOrProductSelectionModal,
}) => {
  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={false}
    >
      <DialogTitle>Select Directory or Product</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              onClick={handleOpenDirectoryModal}
              style={{
                width: "100%",
                height: "80px",
                padding: "0px",
                margin: "0px",
              }}
            >
              <CardCommon
                backgroundColor={"entity_background"}
                style={{
                  width: "100%",
                  height: "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="material-symbols-outlined">folder_open</span>
                <Typography style={{marginLeft: "20px"}}>Add New Directory</Typography>
              </CardCommon>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleOpenProductModal}
              style={{
                width: "100%",
                height: "80px",
                padding: "0px",
                margin: "0px",
                borderRadius: "10px",
              }}
            >
              <CardCommon
                backgroundColor={"entity_background"}
                style={{
                  width: "100%",
                  height: "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="material-symbols-outlined">inventory_2</span>
                <Typography style={{marginLeft: "20px"}}>Add New Product</Typography>
              </CardCommon>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: "16px", marginBottom: "12px" }}>
        <ButtonCommon
          variant="contained"
          style={{ fontSize: 11, width: "100px", marginRight: 8 }}
          color="yellow"
          onClick={handleCloseDirectoryOrProductSelectionModal}
        >
          Cancel
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default DirectoryOrProductSelectionModal;
