import React from "react";
import SaveIcon from "@material-ui/icons/Save";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    dropdownStyle: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      backgroundColor: theme.palette.background.entity_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface InputBoxDefaultProps {
  selectedPaymentType: any;
  handleUpdateDiscount: any;
  discountPercent: any;
  setDiscountPercent: any;
  initialDiscount: any;
}

/* Function component for the discount selector */
const DiscountSelector: React.FunctionComponent<InputBoxDefaultProps> = ({
  selectedPaymentType,
  handleUpdateDiscount,
  discountPercent,
  setDiscountPercent,
  initialDiscount,
}) => {

  /* Function to handle the change event for the dropdown menu */
  const handleSelectPaymentType = (e: any) => {
    const { value } = e.target;
    setDiscountPercent(value);
  };

  const classes = useStyles();
  return (
    <>
      <Grid item xs={4} style={{ marginBottom: "4px", marginTop: "8px" }}>
        <TextField
          fullWidth
          id={selectedPaymentType}
          name={selectedPaymentType}
          select
          size="small"
          label="Discount Percentage"
          value={discountPercent}
          onChange={handleSelectPaymentType}
          className={classes.dropdownStyle}
          variant="outlined"
        >
          {/* Creating an array of 21 items (0 to 20) and mapping over it to create the menu items */}
          {[...Array(21)].map((_, i) => (
            <MenuItem key={i} value={i * 5}>
              {i * 5}%
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2} style={{ marginBottom: "4px", marginTop: "8px" }}>
        <Button
          disabled={parseFloat(initialDiscount) === parseFloat(discountPercent)}
          onClick={() => handleUpdateDiscount(discountPercent)}
        >
          <SaveIcon />
        </Button>
      </Grid>
    </>
  );
};

export default DiscountSelector;
