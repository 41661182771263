import * as React from "react";
import { Button, DialogActions, DialogTitle } from "@material-ui/core";
import DialogCommon from "./DialogCommon";

export interface ConfirmDeleteDialogCommonProps {
  open: boolean;
  setOpen: any;
  confirmAction: any;
}

/* This component shows the modal for confirming the deletion of an item. */
const ConfirmDeleteDialogCommon: React.FunctionComponent<
  ConfirmDeleteDialogCommonProps
> = ({ open, setOpen, confirmAction }) => {
  return (
    <DialogCommon open={open} setOpen={setOpen}>
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to delete this option ?"}
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={confirmAction} color="secondary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </DialogCommon>
  );
};

export default ConfirmDeleteDialogCommon;
