import React, { useCallback, useEffect, useState } from "react";
import ColorSelection from "../ColorSelection";
import PreviewBox from "../SectionBox/PreviewBox";
import SectionBoxWrapper from "../SectionBox/SectionBoxWrapper";
import PreviewCheckout from "./PreviewCheckout";

export interface SectionBoxCheckoutProps {
  themeConfig: any;
  onSubmit: any;
}

/* Update checkout information and users can view all checkout information using card. */
const SectionBoxCheckout: React.FunctionComponent<SectionBoxCheckoutProps> = ({
  themeConfig,
  onSubmit,
}) => {
  const [backgroundColor, setBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState("");

  /* Update states initial load and after update checkout information. */
  const setInitialData = useCallback(() => {
    if(backgroundColor) return;

    const {
      accentColor,
      accentTextColor
    } = themeConfig

    setBackgroundColor(accentColor);
    setTextColor(accentTextColor);
  }, [backgroundColor, themeConfig]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  /* Send an API call to update checkout information after click save button. */
  const handleSave = () => {
    onSubmit({
      accentColor: backgroundColor,
      accentTextColor: textColor,
    });
  };

  const {
    accentColor,
    accentTextColor,
    titleTextColor,
    secondaryColor,
    secondaryTextColor,
  } = themeConfig;

  return (
    <>
      <SectionBoxWrapper
        sectionTitle={"Checkout"}
        onSubmit={handleSave}
        onCancel={setInitialData}
        colorChangeSection={
          <>
            <ColorSelection
              title={"Background Color"}
              colorCode={backgroundColor}
              setColor={(color: any) => setBackgroundColor(color.hex)}
              setInitialData={() => setBackgroundColor(accentColor)}
            />
            <ColorSelection
              title={"Menu Text Color"}
              colorCode={textColor}
              setColor={(color: any) => setTextColor(color.hex)}
              setInitialData={() => setTextColor(accentTextColor)}
            />
          </>
        }
        previewSection={
          <PreviewBox bgColor={backgroundColor}>
            <PreviewCheckout
              backgroundColor={backgroundColor}
              textColor={textColor}
              stepperColor={titleTextColor}
              buttonColor={secondaryColor}
              buttonTextColor={secondaryTextColor}
            />
          </PreviewBox>
        }
      />
    </>
  );
};

export default SectionBoxCheckout;
