import Dialog from "@material-ui/core/Dialog";
import * as React from "react";
import { CustomTheme } from "../../types/customTheme";
import { useTheme } from "@material-ui/core";

export interface DialogCommonProps {
  open: boolean;
  setOpen: any;
  children: any;
}

/* Common modal UI.. */
const DialogCommon: React.FunctionComponent<DialogCommonProps> = ({
  open,
  setOpen,
  children,
}) => {
  const theme: CustomTheme = useTheme();
  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 10,
          border: `1px solid ${theme.palette.background.entity_border}`,
        },
      }}
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick
    >
      {children}
    </Dialog>
  );
};

export default DialogCommon;
