import { axiosClient } from "../client";

// API endpoints
const API_GET_LOCATION_BASIC_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}/location/${locationId}/basic-info`;

const API_UPDATE_LOCATION_BASIC_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}/location/${locationId}/basic-info`;

const API_GET_LOCATIONS_ALL = `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}/get-all`;

// const fetchLocationList = () => {
//   return axiosClient.get(AUTH_ENDPOINT);
// };

export const getAllLocations = () => {
  return axiosClient({
    method: "GET",
    url: API_GET_LOCATIONS_ALL,
  });
};

export const getLocationBasicInfo = (locationId) => {
  return axiosClient({
    method: "GET",
    url: API_GET_LOCATION_BASIC_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
};

export const updateLocationBasicInfo = (locationId, data) => {
  return axiosClient({
    method: "PUT",
    url: API_UPDATE_LOCATION_BASIC_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    data,
  });
};
