import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import PrintIcon from "@material-ui/icons/Print";
import queryString from "query-string";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import CategoryMenuDropDown from "./CategoryMenuDropDown";
import CatMenuPopUp from "./CatMenuPopUp";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import ProductWrapper from "./ProductWrapper";
import { fetchProductGetByWrappedId } from "../../../../services/menuApp/printerMenuService";
import { useRouteMatch } from "react-router-dom";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import EmptyModal from "./Modal/EmptyModal";
import { convertByoName } from "../../../../utils/convertByoName";
import { CustomTheme } from "../../../../types/customTheme";

interface Props {
  isAppEmbedded: any;
}

const useStyles = makeStyles<any, Props>((theme: CustomTheme) =>
  createStyles({
    root: {
      zIndex: 5,
      marginTop: ({ isAppEmbedded }) => isAppEmbedded==="true"?"0px":"54px",
      [theme.breakpoints.up("sm")]: {
        marginTop: ({ isAppEmbedded }) => isAppEmbedded==="true"?"0px":"62px",
      },
      [theme.breakpoints.up("1280")]: {
        marginTop: ({ isAppEmbedded }) => isAppEmbedded==="true"?"0px":"8px",
      },
    },
    categoryTitle: {
      backgroundColor: theme.palette.background.default,
      position: "sticky",
      top: ({ isAppEmbedded }) => isAppEmbedded==="true"?-1:194,
      zIndex: 1,
      fontSize: "20px",
      paddingTop: ({ isAppEmbedded }) => isAppEmbedded==="true"?"116px":"0px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "32px",
        top: ({ isAppEmbedded }) => isAppEmbedded==="true"?-1:200,
        paddingTop: ({ isAppEmbedded }) => isAppEmbedded==="true"?"104px":"20px",
      },
      [theme.breakpoints.up("1280")]: {
        fontSize: "32px",
        top: ({ isAppEmbedded }) => isAppEmbedded==="true"?-1:62,
        paddingTop: ({ isAppEmbedded }) => isAppEmbedded==="true"?"80px":"52px",
      },
    },
    boxStyleOffline: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.default,
      right: 10,
      left: 10,
      zIndex: 50,
    },
    boxStyleOfflineLargeScreen: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.default,
      right: 10,
      zIndex: 50,
    },
  }),
);

export interface MenuInfoPageProps {
  menuNodeList: any;
  linkedIdList: any;
}

/**
 * Component: MenuInfoPage
 *
 * This component is responsible for rendering the menu information page. It displays category menus along with their products,
 * allows users to select a category menu, view its products, and perform actions such as printing and mapping.
 * It also handles error states and displays an alert in case of unexpected errors.
 */
const MenuInfoPage: React.SFC<MenuInfoPageProps> = ({
  menuNodeList,
  linkedIdList,
}) => {
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [selectedCatMenuTitle, setSelectedCatMenuTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [removedLinkedIdList, setRemovedLinkedIdList] = useState<any>([]);
  const [removedLinkedIdLists, setRemovedLinkedIdLists] = useState<any>({});
  const [isColorChange, setIsColorChange] = useState("");
  const [isOpenMappingModal, setIsOpenMappingModal] = useState(false);
  const [isStack, setIsStack] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [printerMappingItemName, setPrinterMappingItemName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [
    selectedPrinterMappingItemOrCategoryId,
    setSelectedPrinterMappingItemOrCategoryId,
  ] = useState("");
  const [type, setType] = useState("");
  const [revertExpand, setRevertExpand] = useState("");
  const [isOpenStackProductModal, setIsOpenStackProductModal] = useState(false);
  const [stackNode, setStackNode] = useState<any>([]);

  const match: any = useRouteMatch();
  /**
   * Function: handleChangeCatMenu
   *
   * This function is responsible for handling the change event when selecting a category menu.
   * It updates the selected category menu title state and filters the department list based on the selected menu.
   */

  const handleChangeCatMenu = (event: any) => {
    // Set the selected category menu title
    setSelectedCatMenuTitle(event.name);

    // Filter the department list based on the selected menu ID
    const filterDepartment = menuNodeList.filter(
      (data: any) => data.id === event.id,
    );

    // Set the filtered department list
    setDepartmentList(filterDepartment[0].department);
  };

  /**
   * useEffect Hook: useEffect to Update Selected Category Menu and Department List
   *
   * This useEffect hook is responsible for updating the selected category menu title and department list
   * when the menuNodeList changes. It runs whenever the menuNodeList state is updated.
   */
  useEffect(() => {
    // Check if the menuNodeList is not empty
    if (!_.isEmpty(menuNodeList)) {
      // Extract the first menu object from the menuNodeLis
      const menu: any = Object.values(menuNodeList);

      // Set the selected category menu title to the name of the first menu
      setSelectedCatMenuTitle(menu[0].name);

      // Extract the department list from the first menu object
      const filterDepartment = menu[0].department;

      // Set the department list state variable
      setDepartmentList(filterDepartment);
    }
  }, [menuNodeList]);

  /**
   * handleClickLinkedId Function: Handle Click Event for Linked IDs
   *
   * This function is responsible for handling the click event when linked IDs are selected.
   * It filters the selected IDs from the event data, compares them with the existing linked ID list,
   * and updates the state variables removedLinkedIdList and revertExpand accordingly.
   */

  const handleClickLinkedId = (e: any, category: any) => {
    const cloneIdLiST = _.cloneDeep(removedLinkedIdList);
    setRevertExpand(e.id);
    cloneIdLiST.push(e.id);
    const cloneIdLiSTs = _.cloneDeep(removedLinkedIdLists);

    if (e.directoryLevel === 1) {
      cloneIdLiSTs[e.id] = {
        id: e.id,
        directoryLevel: e.directoryLevel,
      };
    } else {
      cloneIdLiSTs[category.directoryId] = {
        id: e.id,
        directoryLevel: e.directoryLevel,
      };
    }
    // Update removedLinkedIdList by concatenating existing removed IDs with the new ones
    setRemovedLinkedIdList(cloneIdLiST);
    setRemovedLinkedIdLists(cloneIdLiSTs);
  };

  /**
   * handleUpdateIdList Function: Handle Update of Linked ID List
   *
   * This function is responsible for updating the list of removed linked IDs when a product is updated.
   * It filters out the ID of the updated product from the removed linked ID list and updates the necessary state variables.
   */
  const handleUpdateIdList = (event: any, product: any) => {
    // Check if an event exists and stop its propagation to prevent further event handling
    if (event) {
      event.stopPropagation();
    }

    // Filter out the ID of the updated product from the removed linked ID list
    const removeId = removedLinkedIdList.filter(
      (data: any) => data !== product.directoryId,
    );

    const removeIds = Object.keys(removedLinkedIdList).filter((data: any) => {
      return data !== product.directoryId;
    });

    // Close the mapping modal
    setIsOpenMappingModal(false);

    // Update the removedLinkedIdList state with the filtered list of IDs
    setRemovedLinkedIdList(removeId);
    setRemovedLinkedIdLists(removeIds);

    // Set the color change state to indicate the change in the updated product
    setIsColorChange(product.directoryId);
  };

  const handleProductGetByWrappedId = async (wrappedId: any) => {
    try {
      // Fetch global product information from the backend
      const res = await fetchProductGetByWrappedId(
        match.params.locationId,
        wrappedId,
      );

      if (res.data.data.length === 1) {
        // // Set the selected printer mapping item or category ID state variable
        setSelectedPrinterMappingItemOrCategoryId(res.data.data[0].id);

        // // Open the mapping modal by setting the isOpenMappingModal state variable to true
        setIsOpenMappingModal(true);
      } else {
        if (res.data.data[0].isByo) {
          // // Set the selected printer mapping item or category ID state variable
          setSelectedPrinterMappingItemOrCategoryId(res.data.data[0].id);

          // // Open the mapping modal by setting the isOpenMappingModal state variable to true
          setIsOpenMappingModal(true);
        } else {
          setIsOpenStackProductModal(true);
          setStackNode(res.data.data);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      // Handle unexpected errors by setting the error state and isLoading state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * handleOpenMappingModal Function: Handle Opening Mapping Modal
   *
   * This function is responsible for opening the mapping modal when triggered by an event.
   * It sets the necessary state variables such as category name, printer mapping item name,
   * selected printer mapping item or category ID, and modal type.
   * The modal is then displayed to the user.
   */
  const handleOpenMappingModal = (
    event: any,
    category: any,
    item: any,
    categoryOrItem: string,
    isMap?: any,
  ) => {
    // Check if an event exists and stop its propagation to prevent further event handling
    if (event) {
      event.stopPropagation();
    }
    setIsOpenModal(true);
    setIsLoading(true);
    // // Set the category name state variable
    setCategoryName(category.name);

    // // Set the printer mapping item name state variable
    setPrinterMappingItemName(
      convertByoName(item.name, item?.size, item?.isByo),
    );

    // // Set the modal type state variable
    setType(categoryOrItem);
    if (categoryOrItem === "item" && isMap) {
      setIsStack(true);
    } else {
      setIsStack(false);
    }
    if (categoryOrItem === "item" && !isMap) {
      handleProductGetByWrappedId(item.id);
    } else {
      // // Set the selected printer mapping item or category ID state variable
      setSelectedPrinterMappingItemOrCategoryId(item.id);
      setIsOpenModal(true);
      setIsLoading(false);
      setIsOpenStackProductModal(false);
      // // Open the mapping modal by setting the isOpenMappingModal state variable to true
      setIsOpenMappingModal(true);
    }
  };

  const handleNavigate = (product: any, level: any, data: any) => {
    const cloneRemovedLinkedIdLists = _.cloneDeep(removedLinkedIdLists);
    cloneRemovedLinkedIdLists[data.directoryId].id = product.id;
    cloneRemovedLinkedIdLists[data.directoryId].directoryLevel = level;
    setRemovedLinkedIdLists(cloneRemovedLinkedIdLists);
  };

  const handleClearStrip = (product: any) => {
    const cloneRemovedLinkedIdLists = _.cloneDeep(removedLinkedIdLists);
    delete cloneRemovedLinkedIdLists[product.id];
    setRemovedLinkedIdLists(cloneRemovedLinkedIdLists);
  };
  const isAppEmbedded = queryString.parse(window.location.search).isAppEmbedded;

  const classes = useStyles({isAppEmbedded});
  const maxWidthCustom = useMediaQuery("(max-width: 1280px)");
  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        {!_.isEmpty(menuNodeList) && (
          <Grid
            item
            xs={12}
            className={
              maxWidthCustom
                ? classes.boxStyleOffline
                : classes.boxStyleOfflineLargeScreen
            }
          >
            <div
              style={
                maxWidthCustom
                  ? { display: "flex", justifyContent: "end", width: "100%", marginTop: isAppEmbedded === "true"?"40px":"0px" }
                  : { display: "flex", marginTop: isAppEmbedded === "true"?"40px":"0px" }
              }
            >
              {selectedCatMenuTitle && (
                <CategoryMenuDropDown
                  categoryMenuList={menuNodeList}
                  selectedCatMenuTitle={selectedCatMenuTitle}
                  handleChangeCatMenu={handleChangeCatMenu}
                />
              )}

              {departmentList && (
                <CatMenuPopUp
                  setAnchorEl={setAnchorEl}
                  anchorEl={anchorEl}
                  departmentList={departmentList}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
      {!_.isEmpty(menuNodeList) ? (
        departmentList.map((category: any, index: any) => {
          return (
            <div>
              <Typography
                align="left"
                className={classes.categoryTitle}
                id={category.name}
              >
                {category.name}
                <Button
                  style={{ marginLeft: "8px" }}
                  onClick={(event) => {
                    handleOpenMappingModal(
                      event,
                      category,
                      category,
                      "category",
                      false,
                    );
                  }}
                >
                  <PrintIcon />
                </Button>
              </Typography>
              <Grid
                container
                style={{
                  marginTop: index === 0 && maxWidthCustom && isAppEmbedded !== "true" ? "70px" : "0px",
                }}
              >
                <ProductWrapper
                  wrapperProduct={category.product}
                  removedLinkedIdList={removedLinkedIdList}
                  handleUpdateIdList={handleUpdateIdList}
                  index={index}
                  isColorChange={isColorChange}
                  revertExpand={revertExpand}
                  handleOpenMappingModal={handleOpenMappingModal}
                  category={category}
                  handleClickLinkedId={handleClickLinkedId}
                  removedLinkedIdLists={removedLinkedIdLists}
                  handleNavigate={handleNavigate}
                  handleClearStrip={handleClearStrip}
                />
              </Grid>
            </div>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "16%",
          }}
        >
          <Typography variant="h3">No Menus Available.</Typography>
        </div>
      )}
      <EmptyModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        isOpenMappingModal={isOpenMappingModal}
        setError={setError}
        printerMappingItemName={printerMappingItemName}
        selectedPrinterMappingItemOrCategoryId={
          selectedPrinterMappingItemOrCategoryId
        }
        type={type}
        selectedCatMenuTitle={selectedCatMenuTitle}
        categoryName={categoryName}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isOpenStackProductModal={isOpenStackProductModal}
        setIsOpenStackProductModal={setIsOpenStackProductModal}
        stackNode={stackNode}
        handleOpenMappingModal={handleOpenMappingModal}
        setIsOpenMappingModal={setIsOpenMappingModal}
        isStack={isStack}
      />
      {/* {isOpenMappingModal && (
        <MappingModal
          isOpen={isOpenMappingModal}
          setIsOpen={setIsOpenMappingModal}
          setError={setError}
          printerMappingItemName={printerMappingItemName}
          selectedPrinterMappingItemOrCategoryId={
            selectedPrinterMappingItemOrCategoryId
          }
          type={type}
          selectedCatMenuTitle={selectedCatMenuTitle}
          categoryName={categoryName}
        />
      )}

      {isOpenStackProductModal && (
        <StackModal
          isOpen={isOpenStackProductModal}
          setIsOpen={setIsOpenStackProductModal}
          stackNode={stackNode}
          handleOpenMappingModal={handleOpenMappingModal}
          category={{name: categoryName}}
        />
      )} */}
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(MenuInfoPage, Authorities.MENU_READ);
