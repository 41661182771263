import { useTheme } from "@material-ui/core";
import React from "react";
import _ from "lodash";

export const  handleStockLevelPdfAndExcel = (
  autoUnitConvert: any,
  showRemainderSep: any,
  inputMultiplier: any,
  stockLevel: any,
  displaySuffix: any,
  inputSuffix: any,
) => {
  if (autoUnitConvert) {
    if (showRemainderSep) {
      let quotient = 0;
      let remainder = 0;
      if (
        inputMultiplier !== "" &&
        parseFloat(inputMultiplier.toString()) !== 0
      ) {
        quotient = Math.floor(stockLevel / inputMultiplier);
        remainder = Math.round(stockLevel % inputMultiplier);
      }
      if (parseFloat(inputMultiplier) > parseFloat(stockLevel)) {
        return (
          formatNumberWithDynamicDecimals(parseFloat(stockLevel)) +
          " " +
          (displaySuffix ? displaySuffix : "")
        );
      } else {
        return remainder !== 0
          ? quotient +
              " " +
              (inputSuffix ? inputSuffix : "") +
              " " +
              (remainder.toString().slice(0, 3) +
                " " +
                (displaySuffix ? displaySuffix : ""))
          : quotient + " " + (inputSuffix ? inputSuffix : "");
      }
    } else {
      if (parseFloat(stockLevel) < parseFloat(inputMultiplier)) {
        return (
          formatNumberWithDynamicDecimals(parseFloat(stockLevel)) +
          " " +
          (displaySuffix ? displaySuffix : "")
        );
      } else {
        return (
          ((inputMultiplier === "" || parseFloat(inputMultiplier)) === 0
            ? 0
            : Number.isInteger(
                parseFloat(stockLevel) / parseFloat(inputMultiplier),
              )
            ? parseFloat(stockLevel) / parseFloat(inputMultiplier)
            : formatNumberWithDynamicDecimals(
                parseFloat(stockLevel) / parseFloat(inputMultiplier),
              )) + " " + (inputSuffix ? inputSuffix : "")
        );
      }
    }
  } else {
    return (
      roundToDecimalPlacesUsingSlice(stockLevel) +
      " " +
      (displaySuffix ? displaySuffix : "")
    );
  }
};

const formatNumberWithDynamicDecimals = (number: any) => {
  const decimalCount = (number.toString().split(".")[1] || []).length;

  if (decimalCount === 0) {
    return parseFloat(number.toFixed(3)); // Show up to three decimals for integers
  } else if (decimalCount <= 3) {
    return parseFloat(number.toFixed(decimalCount)); // Show the exact number of decimals for non-integers with up to three decimals
  } else {
    return parseFloat(number.toFixed(3)); // Round values with more than three decimals to three decimals
  }
};

const roundToDecimalPlacesUsingSlice = (number: any) => {
  // Convert the number to a string
  const numString = parseFloat(number).toFixed(3).toString();

  // Find the position of the dot (decimal point)
  const dotIndex = numString.indexOf(".");

  // If the dot is not found, return the original number
  if (dotIndex === -1) {
    return number;
  }

  // Use slice to extract the desired number of decimal places
  const roundedString = numString.slice(0, dotIndex + 3 + 1);

  // Convert the result back to a number
  return parseFloat(roundedString);
};



export const handleStockLevel = (
  autoUnitConvert: any,
  showRemainderSep: any,
  inputMultiplier: any,
  stockLevel: any,
  displaySuffix: any,
  inputSuffix: any,
) => {
  if (autoUnitConvert) {
    if (showRemainderSep) {
      let quotient = 0;
      let remainder = 0;
      if (
        inputMultiplier !== "" &&
        parseFloat(inputMultiplier.toString()) !== 0
      ) {
        quotient = Math.floor(stockLevel / inputMultiplier);
        remainder = Math.round(stockLevel % inputMultiplier);
      }
      if (parseFloat(inputMultiplier) > parseFloat(stockLevel)) {
        const data = {
          0: formatNumberWithDynamicDecimals(parseFloat(stockLevel)),
          1: displaySuffix ? displaySuffix : "",
        };
        return data;
      } else {
        let data = {};
        if (remainder !== 0) {
          data = {
            0: quotient,
            1: inputSuffix ? inputSuffix : "",
            2: remainder.toString().slice(0, 3),
            3: displaySuffix ? displaySuffix : "",
          };
        } else {
          data = { 0: quotient, 1: inputSuffix ? inputSuffix : "" };
        }
        return data;
      }
    } else {
      if (parseFloat(stockLevel) < parseFloat(inputMultiplier)) {
        const data = {
          0: formatNumberWithDynamicDecimals(parseFloat(stockLevel)),
          1: displaySuffix ? displaySuffix : "",
        };
        return data;
      } else {
        const data = {
          0:
            (inputMultiplier === "" || parseFloat(inputMultiplier)) === 0
              ? 0
              : Number.isInteger(
                  parseFloat(stockLevel) / parseFloat(inputMultiplier),
                )
              ? parseFloat(stockLevel) / parseFloat(inputMultiplier)
              : formatNumberWithDynamicDecimals(
                  parseFloat(stockLevel) / parseFloat(inputMultiplier),
                ),
          1: inputSuffix ? inputSuffix : "",
        };
        return data;
      }
    }
  } else {
    const data = {
      0: roundToDecimalPlacesUsingSlice(stockLevel),
      1: displaySuffix ? displaySuffix : "",
    };
    return data;
  }
};





