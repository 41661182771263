import React from "react";
import {
  Typography,
} from "@material-ui/core";

export interface VoidSummeryProps {
  title: any;
}

const SaleTitle: React.FunctionComponent<VoidSummeryProps> = ({ title }) => {
  return (
    <Typography
      variant="h4"
      component="div"
      style={{
        paddingBottom: 16,
        fontWeight: "bold",
        marginTop: "28px",
      }}
    >
      {title}
    </Typography>
  );
};

export default SaleTitle;
