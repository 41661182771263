import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import _, { isEqual } from "lodash";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import moment from "moment";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import StorefrontIcon from "@material-ui/icons/Storefront";

import OrdersInfoChannel from "./OrdersInfoChannel";
import CardCommon from "../../../../components/card/CardCommon";
import { OrderChannelDetailsObject } from "../../../../utils/consts/list";
import { CustomTheme } from "../../../../types/customTheme";

export interface OrdersDetailsTabProps {
  nodeData: any;
  locationName: any;
  orderChannel: any;
  orderChannelImage: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    flexEndFirstVal: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "20px",
      marginTop: "8px",
    },
    flexEndSecondVal: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "20px",
    },
    flexEndOrderChannel: {
      marginTop: "8px",
      paddingRight: "24px",
    },
    iconStyle: {
      fontSize: "20px",
      marginRight: "12px",
      color: theme.palette.custom.green.main,
    },
    rightSideTopAlign: {
      marginTop: "40px",
      paddingLeft: "5px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px",
        paddingLeft: "0px",
      },
    },
    gridTopAlignRightSide: {
      marginTop: "12px",
    },
    gridAlign: {
      display: "flex",
      marginTop: "28px",
      marginLeft: "36px",
      [theme.breakpoints.down("lg")]: {
        marginTop: "32px",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: "12px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "32px",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: "4px",
      },
    },
    rightSideGridAlign: {
      marginLeft: "48px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "8px",
      },
    },
    leftGridStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "start",
      paddingRight: "5px",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0px",
      },
    },
    leftAlign: {
      marginLeft: "25px",
    },
    topAlign: {
      marginTop: "10px",
    },
    firstColumnMargin: {
      marginRight: "0px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        marginRight: "20px",
      },
    },
    channelNameStyle: {
      marginLeft: "12px",
    },
    captionStyle: {
      justifyContent: "start",
      display: "flex",
      marginLeft: "5px",
    },
    gridTopAlign: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
    },
    locationNameStyle: {
      marginLeft: "4px",
      backgroundColor: theme.palette.custom.blue.main,
    },
    style: {
      marginBottom: "16px",
      marginRight: "20px",
      marginTop: "2px",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    dividerStyle: {
      marginTop: "12px",
      marginBottom: "16px",
      width: "100%",
      height: "2px",
      marginLeft: "20px",
      marginRight: "20px",
      backgroundColor: theme.palette.background.entity_background,
    },
    textColor: {
      color: theme.palette.custom.blue.contrastText,
    },
  }),
);

/**
 * OrderDetailsTab Component
 *
 * This component is responsible for rendering detailed information about an order.
 * It utilizes Material-UI components such as Grid, Typography, and icons for layout
 * and visual representation of various order details.
 */
const OrderDetailsTab: React.FunctionComponent<OrdersDetailsTabProps> = ({
  nodeData,
  locationName,
  orderChannel,
  orderChannelImage,
}) => {
  const [orderTime, setOrderTime] = useState("");
  const [dueTime, setDueTime] = useState("");
  const [deliveryCost, setDeliveryCost] = useState("");
  const [customerInfo, setCustomerInfo] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });
  const [items, setItems] = useState<any>([]);
  const [itemTotal, setItemTotal] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [discountTotal, setDiscountTotal] = useState("");
  const [discountInfo, setDiscountInfo] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [serviceCharge, setServiceCharge] = useState("");
  const [extraCharges, setExtraCharges] = useState({});
  const [extOrderId, setExtOrderId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [extBrandId, setExtBrandId] = useState("");
  const [note, setNote] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    postCode: "",
    extraAddressInfo: "",
  });
  const [deliveryType, setDeliveryType] = useState("");
  const [fulfilledBy, setFulfilledBy] = useState("");
  const [deliveryIsAsap, setDeliveryIsAsap] = useState(false);

  /* Update states after getting the order details information. */
  const setNodeDataToState = useCallback((nodeData) => {
    const {
      orderTime,
      dueTime,
      deliveryCost,
      paymentAmount,
      items,
      discountTotal,
      discountInfo,
      serviceCharge,
      extraCharges,
      paymentType,
      extOrderId,
      brandId,
      extBrandId,
      note,
      deliveryAddress,
      deliveryType,
      fulfilledBy,
      deliveryIsAsap,
      customer,
    } = nodeData;
    setOrderTime(moment(orderTime).format("MMM D, YYYY HH:mm"));
    setDueTime(moment(dueTime).format("MMM D, YYYY HH:mm"));
    setDeliveryCost(deliveryCost);
    setPaymentAmount(paymentAmount);
    setCustomerInfo(customer);
    setItems(items);
    setDiscountTotal(discountTotal);
    let itemAmount = 0;

    if (!_.isEmpty(items)) {
      items.forEach((item: any) => {
        // The amount of one item is multiplied by the number of items.
        itemAmount = itemAmount + parseFloat(item.price) * item.quantity;
        // If there is a sub-item, get the total of the items of that sub-item.
        if (!_.isEmpty(item.subItems)) {
          item.subItems.forEach((subItems: any) => {
            itemAmount =
              itemAmount + parseFloat(subItems.price) * item.quantity;
          });
        }
      });
    }
    setItemTotal(itemAmount);
    setPaymentAmount(paymentAmount);
    setDiscountInfo(discountInfo);
    setPaymentType(paymentType);
    setServiceCharge(serviceCharge);
    setExtraCharges(extraCharges);
    setExtOrderId(extOrderId);
    setBrandId(brandId);
    setExtBrandId(extBrandId);
    setNote(note);
    setDeliveryAddress(deliveryAddress);
    setDeliveryType(deliveryType);
    setFulfilledBy(fulfilledBy);
    setDeliveryIsAsap(deliveryIsAsap);
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  /* Create a orderChannel pin. */
  const handleOrderChannelPin = (nodeData: any) => {
    // Destructure the platform and orderChannel properties from the nodeData object
    const { platform, orderChannel } = nodeData;
    // If the platform is "online"
    if (platform === "online") {
      // Return a Chip component with a background color based on the orderChannel and a label with the channel name
      if (orderChannel === "wix") {
        return (
          <Chip
            className={classes.channelNameStyle}
            style={{
              backgroundColor: OrderChannelDetailsObject["eatpresto"].color,
            }}
            label={
              <Typography
                variant="body1"
                className={classes.textColor}
                align="left"
              >
                {OrderChannelDetailsObject["eatpresto"].channelName}
              </Typography>
            }
          />
        );
      } else if (!_.isEmpty(OrderChannelDetailsObject[orderChannel])) {
        return (
          <Chip
            className={classes.channelNameStyle}
            style={{
              backgroundColor: OrderChannelDetailsObject[orderChannel].color,
            }}
            label={
              <Typography
                variant="body1"
                className={classes.textColor}
                align="left"
              >
                {OrderChannelDetailsObject[orderChannel].channelName}
              </Typography>
            }
          />
        );
      } else {
        return (
          <Chip
            className={classes.channelNameStyle}
            style={{
              backgroundColor: OrderChannelDetailsObject["default"].color,
            }}
            label={
              <Typography
                variant="body1"
                className={classes.textColor}
                align="left"
              >
                {orderChannel}
              </Typography>
            }
          />
        );
      }
    } else {
      // If the platform is not "online", return a Chip component with a fixed background color and a label with "EPOS"
      return (
        <Chip
          className={classes.channelNameStyle}
          style={{ backgroundColor: "#F7931E" }}
          label={
            <Typography
              variant="body1"
              className={classes.textColor}
              align="left"
            >
              EPOS
            </Typography>
          }
        />
      );
    }
  };

  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  return (
    <CardCommon backgroundColor={"entity_background"}>
      <Grid container style={{ margin: "10px" }}>
        <Grid item xs={12} md={6} className={classes.leftGridStyle}>
          <Grid container>
            <Grid item xs={10} className={classes.style}>
              <Chip
                className={classes.locationNameStyle}
                label={
                  <Typography
                    variant="body1"
                    className={classes.textColor}
                    align="left"
                  >
                    {locationName}
                  </Typography>
                }
              />
              {handleOrderChannelPin(nodeData)}
            </Grid>

            <Box className={classes.firstColumnMargin}>
              <CardCommon backgroundColor={"entity_highlight_background"}>
                <Grid container style={{ marginTop: "12px" }}>
                  {!_.isEmpty(items) && (
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingLeft: "20px",
                        color: theme.palette.custom.orange.contrastText,
                      }}
                    >
                      Quantity
                    </Grid>
                  )}
                  {!_.isEmpty(items) && (
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingLeft: "24px",
                        color: theme.palette.custom.orange.contrastText,
                      }}
                    >
                      Item
                    </Grid>
                  )}
                  {!_.isEmpty(items) && (
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "20px",
                        color: theme.palette.custom.orange.contrastText,
                      }}
                    >
                      Price
                    </Grid>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Box className={classes.dividerStyle}></Box>
                  </div>

                  {!_.isEmpty(items)
                    ? items.map((item: any) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={3}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                paddingLeft: "20px",
                                marginTop: "4px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  fontWeight: "bolder",
                                }}
                              >
                                {item.quantity}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                paddingLeft: "8px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                align="left"
                                style={{
                                  display: "flex",
                                  fontWeight: "bolder",
                                }}
                              >
                                {item.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                variant="body2"
                                align="left"
                                style={{
                                  justifyContent: "flex-end",
                                  display: "flex",
                                  fontWeight: "bolder",
                                  paddingRight: "20px",
                                }}
                              >
                                £
                                {(
                                  item.quantity * parseFloat(item.price)
                                ).toFixed(2)}
                              </Typography>
                            </Grid>
                            {!_.isEmpty(item.subItems)
                              ? item.subItems.map((subItem: any) => {
                                  return (
                                    <>
                                      <Grid item xs={3}></Grid>
                                      <Grid
                                        item
                                        xs={7}
                                        style={{
                                          paddingLeft: "12px",
                                          marginBottom: "4px",
                                        }}
                                      >
                                        <Typography
                                          variant="caption"
                                          align="left"
                                          className={classes.captionStyle}
                                        >
                                          - {subItem.name}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{
                                          justifyContent: "flex-end",
                                          display: "flex",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        <Typography
                                          variant="caption"
                                          align="left"
                                        >
                                          £
                                          {(
                                            item.quantity *
                                            parseFloat(subItem.price)
                                          ).toFixed(2)}
                                        </Typography>
                                      </Grid>
                                    </>
                                  );
                                })
                              : null}
                          </>
                        );
                      })
                    : null}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "24px",
                      marginBottom: "24px",
                    }}
                  >
                    <Box className={classes.dividerStyle}></Box>
                  </div>

                  {!_.isEmpty(items) && (
                    <>
                      <Grid
                        item
                        xs={6}
                        className={classes.topAlign}
                        style={{ paddingLeft: "20px" }}
                      >
                        <Typography
                          variant="body2"
                          align="left"
                          style={{
                            fontSize: "12px",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                        >
                          Sub Total
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={classes.gridTopAlign}
                        style={{ paddingRight: "20px" }}
                      >
                        <Typography
                          align="left"
                          style={{
                            fontSize: "12px",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                        >
                          £{itemTotal.toFixed(2)}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {!_.isEmpty(discountTotal) &&
                    !isEqual(discountTotal, "0.00") && (
                      <>
                        <Grid
                          item
                          xs={6}
                          style={{ paddingLeft: "20px", marginTop: "8px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Discount
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.flexEndFirstVal}>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            £{discountTotal}
                          </Typography>
                        </Grid>
                        {!_.isEmpty(discountInfo)
                          ? discountInfo.map((info: any) => {
                              return (
                                <>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ paddingLeft: "20px" }}
                                  >
                                    <Typography
                                      variant="caption"
                                      align="left"
                                      className={classes.captionStyle}
                                    >
                                      {info.title}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    className={classes.flexEndSecondVal}
                                  >
                                    <Typography
                                      variant="caption"
                                      align="left"
                                      style={{ fontSize: "11px" }}
                                    >
                                      £{parseFloat(info.amount).toFixed(2)}
                                    </Typography>
                                  </Grid>
                                </>
                              );
                            })
                          : null}
                      </>
                    )}

                  {!_.isEmpty(deliveryCost) &&
                    !isEqual(deliveryCost, "0.00") && (
                      <>
                        <Grid
                          item
                          xs={6}
                          style={{ paddingLeft: "20px", marginTop: "8px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Delivery Cost
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classes.flexEndFirstVal}
                          style={{ paddingRight: "20px", marginTop: "8px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            £{deliveryCost}
                          </Typography>
                        </Grid>
                      </>
                    )}

                  {!_.isEmpty(serviceCharge) &&
                    !isEqual(serviceCharge, "0.00") && (
                      <>
                        <Grid
                          item
                          xs={6}
                          style={{ paddingLeft: "20px", marginTop: "8px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Service Charge
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.flexEndFirstVal}>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            £{serviceCharge}
                          </Typography>
                        </Grid>
                      </>
                    )}

                  {!_.isEmpty(extraCharges) && (
                    <Grid
                      item
                      xs={12}
                      style={{ paddingLeft: "20px", marginTop: "8px" }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: theme.palette.custom.orange.contrastText,
                        }}
                        align="left"
                      >
                        Extra Charges
                      </Typography>
                    </Grid>
                  )}

                  {!_.isEmpty(extraCharges) &&
                    Object.entries(extraCharges).map(([key, value]) => {
                      return (
                        <>
                          <Grid item xs={6} style={{ paddingLeft: "20px" }}>
                            <Typography
                              variant="caption"
                              align="left"
                              className={classes.captionStyle}
                            >
                              {key}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            className={classes.flexEndSecondVal}
                          >
                            <Typography variant="caption" align="left">
                              £{value}
                            </Typography>
                          </Grid>
                        </>
                      );
                    })}

                  {!_.isEmpty(paymentAmount) &&
                    !isEqual(paymentAmount, "0.00") && (
                      <>
                        <Grid item xs={6} style={{ marginTop: "16px" }}>
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                              paddingLeft: "20px",
                            }}
                            align="left"
                          >
                            Payment Amount
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.flexEndFirstVal}>
                          <Typography
                            style={{
                              fontSize: "12px",
                              marginTop: "8px",
                              color: theme.palette.custom.orange.contrastText,
                              paddingLeft: "20px",
                            }}
                            align="left"
                          >
                            £{paymentAmount}
                          </Typography>
                        </Grid>
                      </>
                    )}

                  {!_.isEmpty(paymentType) && (
                    <>
                      <Grid
                        item
                        xs={6}
                        className={classes.topAlign}
                        style={{ paddingLeft: "20px", marginTop: "8px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Payment Method
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={classes.gridTopAlign}
                        style={{ paddingRight: "20px", marginTop: "8px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {paymentType}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={6} style={{ marginBottom: "16px" }}>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: theme.palette.custom.orange.contrastText,
                        paddingLeft: "20px",
                        marginTop: "8px",
                      }}
                      align="left"
                    >
                      Order is Already Paid
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.flexEndSecondVal}
                    style={{ marginTop: "8px", marginBottom: "16px" }}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: theme.palette.custom.orange.contrastText,
                      }}
                      align="left"
                    >
                      {_.isEqual(paymentType, "cash") ? "yes" : "No"}
                    </Typography>
                  </Grid>
                </Grid>{" "}
              </CardCommon>

              <Grid container>
                <Grid item xs={12} style={{ marginTop: "12px" }}>
                  <CardCommon backgroundColor={"entity_highlight_background"}>
                    <Grid container style={{ marginBottom: "12px" }}>
                      <Grid
                        item
                        xs={10}
                        style={{ marginTop: "12px", paddingLeft: "16px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Intergration Info
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {!_.isEmpty(orderChannelImage) && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                marginLeft: "21px",
                                marginTop: "8px",
                              }}
                            >
                              <OrdersInfoChannel
                                orderChannelImage={orderChannelImage}
                              />
                            </Grid>
                          )}
                        </Typography>
                        {!_.isEmpty(orderChannel) && (
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", justifyContent: "start" }}
                          >
                            <Typography
                              style={{
                                fontSize: "17px",
                                fontWeight: "bolder",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              {orderChannel}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "32px",
                          paddingRight: "12px",
                        }}
                      >
                        <Typography
                          align="left"
                          style={{
                            color: theme.palette.custom.orange.contrastText,
                          }}
                        >
                          <InfoOutlinedIcon fontSize="large" />
                        </Typography>
                      </Grid>
                      <Box className={classes.dividerStyle}></Box>

                      {!_.isEmpty(extOrderId) && (
                        <Grid
                          item
                          xs={12}
                          style={{ paddingLeft: "16px", marginBottom: "12px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Ext Order Id
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {extOrderId}
                          </Typography>
                        </Grid>
                      )}

                      {!_.isEmpty(brandId) && (
                        <Grid
                          item
                          xs={12}
                          style={{ paddingLeft: "16px", marginBottom: "12px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Brand Id
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {brandId}
                          </Typography>
                        </Grid>
                      )}

                      {!_.isEmpty(extBrandId) && (
                        <Grid
                          item
                          xs={12}
                          style={{ paddingLeft: "16px", marginBottom: "12px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Ext Brand Id
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {extBrandId}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </CardCommon>
                </Grid>
              </Grid>
            </Box>

            {/* <Grid item xs={12} style={{ display: "flex", marginTop: "24px" }}>
              <InfoOutlinedIcon className={classes.iconStyle} />
              <Typography variant="body2" align="left">
                INTEGRATION INFO
              </Typography>
            </Grid> */}

            {/* {!_.isEmpty(orderChannelImage) && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginLeft: "25px",
                }}
              >
                <OrdersInfoChannel orderChannelImage={orderChannelImage} />
              </Grid>
            )}

            {!_.isEmpty(orderChannel) && (
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "start" }}
              >
                <Typography
                  variant="caption"
                  align="left"
                  style={{
                    marginLeft: "25px",
                  }}
                >
                  {orderChannel}
                </Typography>
              </Grid>
            )} */}
          </Grid>
        </Grid>

        <Grid item md={6} xs={12} className={classes.rightSideTopAlign}>
          <Box
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.gridTopAlignRightSide}
                style={{ paddingRight: "20px" }}
              >
                <CardCommon backgroundColor={"entity_highlight_background"}>
                  <Grid container>
                    <Grid
                      item
                      xs={10}
                      style={{ marginTop: "12px", paddingLeft: "16px" }}
                    >
                      {!_.isEmpty(deliveryType) && (
                        <>
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Order Type
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {!_.isEmpty(deliveryType) ? deliveryType : ""}
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "14px",
                        paddingRight: "12px",
                      }}
                    >
                      <Typography
                        align="left"
                        style={{
                          color: theme.palette.custom.orange.contrastText,
                        }}
                      >
                        <ReceiptOutlinedIcon fontSize="large" />
                      </Typography>
                    </Grid>
                    <Box className={classes.dividerStyle}></Box>
                    {!_.isEmpty(dueTime) && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "16px", marginBottom: "12px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Pickup Time
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {dueTime}
                        </Typography>
                      </Grid>
                    )}
                    {_.isEqual(deliveryIsAsap, true) && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "16px", marginBottom: "12px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Due At
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {"SCHEDULED"}
                        </Typography>
                      </Grid>
                    )}
                    {!_.isEmpty(orderTime) && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "16px", marginBottom: "12px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Order Time
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {orderTime}
                        </Typography>
                      </Grid>
                    )}
                    {!_.isEmpty(note) && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "16px", marginBottom: "12px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Order Note
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {note}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </CardCommon>
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.gridTopAlignRightSide}
                style={{ paddingRight: "20px" }}
              >
                <CardCommon backgroundColor={"entity_highlight_background"}>
                  <Grid container>
                    <Grid
                      item
                      xs={10}
                      style={{ marginTop: "12px", paddingLeft: "16px" }}
                    >
                      <Typography
                        style={{
                          fontSize: "11px",
                          fontWeight: "lighter",
                          color: theme.palette.custom.orange.contrastText,
                        }}
                        align="left"
                      >
                        Fulfilled by
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "17px",
                          fontWeight: "bolder",
                          color: theme.palette.custom.orange.contrastText,
                        }}
                        align="left"
                      >
                        {!_.isEmpty(fulfilledBy) ? fulfilledBy : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "14px",
                        paddingRight: "12px",
                      }}
                    >
                      <Typography
                        align="left"
                        style={{
                          color: theme.palette.custom.orange.contrastText,
                        }}
                      >
                        <StorefrontIcon fontSize="large" />
                      </Typography>
                    </Grid>
                    <Box className={classes.dividerStyle}></Box>
                    {(!_.isEmpty(customerInfo.firstName) ||
                      !_.isEmpty(customerInfo.lastName) ||
                      !_.isEmpty(customerInfo.phoneNumber) ||
                      !_.isEmpty(customerInfo.email)) && (
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{ paddingLeft: "16px", marginBottom: "12px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Name
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {!_.isEmpty(customerInfo.firstName)
                              ? customerInfo.firstName
                              : "" + " " + !_.isEmpty(customerInfo.lastName)
                              ? customerInfo.lastName
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ paddingLeft: "16px", marginBottom: "12px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Phone
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {!_.isEmpty(customerInfo.phoneNumber)
                              ? customerInfo.phoneNumber
                              : "Not available"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ paddingLeft: "16px", marginBottom: "12px" }}
                        ></Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ paddingLeft: "16px", marginBottom: "12px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Email
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {!_.isEmpty(customerInfo.email)
                              ? customerInfo.email
                              : "Not available"}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    {(!_.isEmpty(deliveryAddress.addressLine1) ||
                      !_.isEmpty(deliveryAddress.addressLine2) ||
                      !_.isEmpty(deliveryAddress.city) ||
                      !_.isEmpty(deliveryAddress.country) ||
                      !_.isEmpty(deliveryAddress.postCode)) && (
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{ paddingLeft: "16px", marginBottom: "12px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Delivery Address
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {deliveryAddress.extraAddressInfo}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardCommon>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </CardCommon>
  );
};

export default OrderDetailsTab;
