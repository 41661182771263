import Cookie from "js-cookie";

/* Update the cookie data */
export const setCookie = (key: string, value: any, config: any) => {
  return Cookie.set(key, value, { ...config });
};

/* Get the cookie data */
export const getCookie = (key: string) => {
  return Cookie.get(key);
};

/* Remove the cookie data */
export const removeCookie = (key: string) => {
  return Cookie.remove(key);
};

export const removeAllCookies = () => {
  Object.keys(Cookie.get()).forEach(function (key) {
    Cookie.remove(key, {
      domain: process.env.REACT_APP_ALLOWED_DOMAIN,
    });
  });
};
