import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import React from "react";
import { CustomTheme } from "../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      width: "200px",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: "10px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface Props {
  handleChangeSelector: any;
  label: any;
  selectedValue: any;
  selectorList: any;
  disable: any;
}

/* Select one item uses this component. */
const CustomSelector: React.FunctionComponent<Props> = ({
  handleChangeSelector,
  label,
  selectedValue,
  selectorList,
  disable,
}) => {
  /* Providing selected value with selector name. */
  const handleSelectedData = (e: any) => {
    handleChangeSelector(label, e.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const classes = useStyles();
  return (
    <>
      {/* <TextField
        label="Normal select"
        select
        value={selectedValue}
        onChange={handleSelectedData}
      >
        {selectorList.map((value: any) => (
          <MenuItem value={value.id}>
                {value.label}
                <Checkbox checked={selectedValue.indexOf(value.label) > -1} />
          </MenuItem>
        ))}
      </TextField> */}
      <FormControl
        variant="outlined"
        disabled={disable}
        style={{ margin: "1px", width: "100%", border: "none" }}
        size="small"
      >
        <InputLabel id="selector-label">{label}</InputLabel>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          labelId="selector-label-id"
          id="selector-id"
          value={selectedValue}
          label={label}
          multiple
          onChange={handleSelectedData}
          className={classes.root}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          {selectorList.map((value: any) => (
            <MenuItem
              onClick={handleClose}
              key={value.id}
              value={value.id}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {value.label}
              <Checkbox checked={selectedValue.includes(value.id)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default CustomSelector;
