import { axiosClient } from "../client";

const queryString = require("query-string");

const SUPPLIER_INFO_GET_ALL_GIVEN_LOCATION = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/supplier/location/${locationId}`;

const SUPPLIER_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/supplier`;

const SUPPLIER_INFO_GET_SINGLE = (locationId, supplierId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/supplier/id/${supplierId}`;

const SUPPLIER_SELECTED_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockSupplierMap`;

const DELETE_STOCK_SUPPLIER_MAP = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockSupplierMap`;

const STOCK_ITEMS_FOR_SUPPLIER_INFO_GET_ALL = (locationId, id) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockItem/supplier/${id}`;

export const fetchAllSuppliersInfo = (
  locationId,
  size,
  currentPage,
  shortingMethod,
  shortingHeaderName,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: SUPPLIER_INFO_GET_ALL_GIVEN_LOCATION(locationId),
        query: {
          size: size,
          page: currentPage,
          sort: [shortingHeaderName, shortingMethod],
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const deleteStockSupplierMap = (locationId, data) =>
  axiosClient({
    method: "DELETE",
    url: DELETE_STOCK_SUPPLIER_MAP(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchAllSelectedSuppliersInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: SUPPLIER_SELECTED_INFO_GET_ALL(locationId),
        query: {
          size: 100000,
          page: 0,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchAllStockItemForSupplierInfo = (locationId, id) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: STOCK_ITEMS_FOR_SUPPLIER_INFO_GET_ALL(locationId, id),
        query: {
          size: 100000,
          page: 1,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchAllSupplierInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: SUPPLIER_INFO_GET_ALL(locationId),
        query: {
          size: 100000,
          page: 1,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchSingleSupplierInfo = (locationId, supplierId) =>
  axiosClient({
    method: "GET",
    url: SUPPLIER_INFO_GET_SINGLE(locationId, supplierId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
