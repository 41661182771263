import React from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import {
  Button,
  Grid,
  Typography,
  createStyles,
  fade,
  makeStyles,
} from "@material-ui/core";
import CategoryMenuList from "./CategoryMenuList";
import CategoryMenuListWrapper from "./CategoryMenuListWrapper";
import WrapperDirectory from "./WrapperDirectory";
import ProductDirectory from "./ProductDirectory";
import DirectoryEditAndVisibility from "./Modal/DirectoryEditAndVisibility";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      zIndex: 5,
      [theme.breakpoints.up("sm")]: {
        marginTop: "62px",
      },
      [theme.breakpoints.up("1280")]: {
        marginTop: "0px",
      },
    },
    button: {
      position: "relative",
      overflow: "hidden",
      "&:before": {
        content: '""',
        position: "absolute",
        bottom: 0,
        right: 0,
        borderLeft: "8px solid transparent", // Adjust the border size as needed
        borderBottom: `8px solid ${theme.palette.background.entity_background}`, // Adjust the color as needed
        width: 0,
      },
    },
    categoryStyle: {
      backgroundColor: theme.palette.background.default,
      position: "sticky",
      zIndex: 1,
      display: "flex",
      top: 126,
      paddingTop: "28px",
      [theme.breakpoints.up("600")]: {
        top: 200,
      },
      [theme.breakpoints.up("960")]: {
        top: 131,
      },
      [theme.breakpoints.up("1280")]: {
        top: 63,
        paddingTop: "41px",
        // paddingTop: "52px",
      },
    },
    categoryTitle: {
      fontSize: "20px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "32px",
      },
      [theme.breakpoints.up("1280")]: {
        fontSize: "32px",
      },
    },
    categoryEditIconStyle: {
      marginLeft: "12px",
      marginTop: "4px",
    },
    boxStyleOffline: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.default,
      left: 10,
      right: 10,
      zIndex: 50,
    },
    boxStyleOfflineLargeScreen: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.default,
      right: 10,
      zIndex: 50,
    },
    addNodeButton: {
      height: "134px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "none",
      borderRadius: "10px",
      backgroundColor: theme.palette.background.entity_background,
      "&:hover": {
        backgroundColor: fade(
          theme.palette.background.paper,
          theme.palette.action.hoverOpacity,
        ),
      },
    },
  }),
);

export interface LoadingProps {
  wrapper: any;
  handleClickLinkedId: any;
  category: any;
  isEmptyProductList: any;
  handleClearEmptyList: any;
  handleOpenProductEditModal: any;
  handleOpenMappingModal: any;
  isLoadProduct: any;
  getProductWrapperInfo: any;
  menuNode: any;
  categoryNode: any;
  setSuccess: any;
  removedLinkedIdLists: any;
  handleClearStrip: any;
  productVersion: any;
  handleNavigate: any;
  handleOpenProductOrDirectoryModals: any;
  setIsProductAdd: any;
  handleOpenProductEditModalDirectory: any;
  handleOpenMappingModalDirectory: any;
  handleChangeValidInWrapperDirectory: any;
  index: any;
  menuNodeList: any;
  setMenuNodeList: any;
  wrapperId: any;
  menuId: any;
  categoryId: any;
  firstWrapperId: any;
  isRoot: any;
  isOpenWrapperEditModal: any;
  setError: any;
  handleOpenDirectoryEditModal: any;
}

/* WrapperNode the page until data is retrieved from the API call. */
const WrapperNode: React.FunctionComponent<LoadingProps> = ({
  wrapper,
  handleClickLinkedId,
  category,
  isEmptyProductList,
  handleClearEmptyList,
  handleOpenProductEditModal,
  handleOpenMappingModal,
  isLoadProduct,
  getProductWrapperInfo,
  menuNode,
  categoryNode,
  setSuccess,
  removedLinkedIdLists,
  handleClearStrip,
  productVersion,
  handleNavigate,
  handleOpenProductOrDirectoryModals,
  setIsProductAdd,
  handleOpenProductEditModalDirectory,
  handleOpenMappingModalDirectory,
  handleChangeValidInWrapperDirectory,
  index,
  menuNodeList,
  setMenuNodeList,
  wrapperId,
  menuId,
  categoryId,
  firstWrapperId,
  isRoot,
  isOpenWrapperEditModal,
  setError,
  handleOpenDirectoryEditModal,
}) => {
  const classes = useStyles();
  return (
    <>
      {wrapper.directory ? (
        <Grid item md={6} xs={12} lg={4}>
          <CardCommon
            backgroundColor={"default"}
            style={{ borderRadius: "10px", marginTop: "14px" }}
          >
            <CategoryMenuListWrapper
              item={wrapper}
              handleClickLinkedId={handleClickLinkedId}
              category={wrapper}
              directoryLevel={1}
              categoryId={category.id}
            />
          </CardCommon>
        </Grid>
      ) : (
        <Grid item md={6} xs={12} lg={4} style={{ paddingTop: "22px" }}>
          <CardCommon
            backgroundColor={"default"}
            style={{ borderRadius: "10px" }}
          >
            <CategoryMenuList
              item={wrapper}
              handleOpenProductEditModal={handleOpenProductEditModal}
              handleOpenMappingModal={handleOpenMappingModal}
              category={category}
              wrapper={wrapper}
              isLoadProduct={isLoadProduct}
              getProductWrapperInfo={getProductWrapperInfo}
              menuNode={menuNode}
              categoryNode={categoryNode}
              setSuccess={setSuccess}
              isOpenWrapperEditModal={true}
              productVersion={productVersion}
              menuNodeList={menuNodeList}
              setMenuNodeList={setMenuNodeList}
              wrapperId={wrapperId}
              menuId={menuId}
              categoryId={categoryId}
              firstWrapperId={firstWrapperId}
              isRoot={true}
            />
          </CardCommon>
        </Grid>
      )}

      {wrapper?.wrapper && Object.values(wrapper?.wrapper)?.length > 0
        ? Object.keys(removedLinkedIdLists).includes(wrapper.id.toString()) && (
            <Grid
              container
              spacing={2}
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                marginBottom: "8px",
                marginTop: "8px",
              }}
            >
              <CardCommon
                backgroundColor={"entity_highlight_directory_background"}
                style={{
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    paddingLeft: "14px",
                    paddingRight: "14px",
                    marginBottom: "6px",
                  }}
                >
                  {removedLinkedIdLists[wrapper.id.toString()]
                    ?.directoryLevel === wrapper.directoryLevel &&
                    removedLinkedIdLists[wrapper.id.toString()]?.id ===
                      wrapper.id && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          marginTop: "12px",
                        }}
                      >
                        <Button
                          onClick={() => handleNavigate(wrapper, 1, wrapper)}
                        >
                          <Typography variant="h6">{wrapper.name}</Typography>
                        </Button>
                        <Button onClick={() => handleClearStrip(wrapper)}>
                          <HighlightOffIcon />
                        </Button>
                        <DirectoryEditAndVisibility
                          item={wrapper}
                          wrapper={wrapper}
                          category={category}
                          isOpenWrapperEditModal={isOpenWrapperEditModal}
                          isRoot={false}
                          setError={setError}
                          menuNodeList={menuNodeList}
                          menuId={menuId}
                          setMenuNodeList={setMenuNodeList}
                          setSuccess={setSuccess}
                          handleOpenDirectoryEditModal={
                            handleOpenDirectoryEditModal
                          }
                        />
                      </Grid>
                    )}

                  {wrapper?.wrapper &&
                    Object.values(wrapper?.wrapper)?.length > 0 &&
                    Object.values(wrapper?.wrapper).map((data1: any) => (
                      <>
                        {data1.directory ? (
                          <>
                            <WrapperDirectory
                              removedLinkedIdLists={removedLinkedIdLists}
                              wrapper={wrapper}
                              item={wrapper}
                              data={data1}
                              handleClickLinkedId={handleClickLinkedId}
                              category={category}
                            />

                            {/* Level 2 */}
                            {removedLinkedIdLists[wrapper.id.toString()]
                              ?.directoryLevel === data1.directoryLevel &&
                              removedLinkedIdLists[wrapper.id.toString()]
                                ?.id === data1.id && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    marginTop: "12px",
                                  }}
                                >
                                  <Button
                                    onClick={() =>
                                      handleNavigate(wrapper, 1, wrapper)
                                    }
                                  >
                                    <Typography variant="h6">
                                      {wrapper.name}
                                    </Typography>
                                  </Button>
                                  <Typography variant="h6">{"->"}</Typography>
                                  <Button>
                                    <Typography variant="h6">
                                      {data1.name}
                                    </Typography>
                                  </Button>
                                  <Button
                                    onClick={() => handleClearStrip(wrapper)}
                                  >
                                    <HighlightOffIcon />
                                  </Button>
                                  <DirectoryEditAndVisibility
                                    item={data1}
                                    wrapper={wrapper}
                                    category={category}
                                    isOpenWrapperEditModal={
                                      isOpenWrapperEditModal
                                    }
                                    isRoot={false}
                                    setError={setError}
                                    menuNodeList={menuNodeList}
                                    menuId={menuId}
                                    setMenuNodeList={setMenuNodeList}
                                    setSuccess={setSuccess}
                                    handleOpenDirectoryEditModal={
                                      handleOpenDirectoryEditModal
                                    }
                                  />
                                </Grid>
                              )}

                            {data1?.wrapper &&
                              Object.values(data1?.wrapper)?.length > 0 &&
                              Object.values(data1?.wrapper).map(
                                (data2: any) => (
                                  <>
                                    {data2.directory ? (
                                      <>
                                        <WrapperDirectory
                                          removedLinkedIdLists={
                                            removedLinkedIdLists
                                          }
                                          wrapper={wrapper}
                                          item={data1}
                                          data={data2}
                                          handleClickLinkedId={
                                            handleClickLinkedId
                                          }
                                          category={category}
                                        />

                                        {/* Level 3 */}
                                        {removedLinkedIdLists[
                                          wrapper.id.toString()
                                        ]?.directoryLevel ===
                                          data2.directoryLevel &&
                                          removedLinkedIdLists[
                                            wrapper.id.toString()
                                          ]?.id === data2.id && (
                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                display: "flex",
                                                justifyContent: "start",
                                                alignItems: "center",
                                                marginTop: "12px",
                                              }}
                                            >
                                              <Button
                                                onClick={() =>
                                                  handleNavigate(
                                                    wrapper,
                                                    1,
                                                    wrapper,
                                                  )
                                                }
                                              >
                                                <Typography variant="h6">
                                                  {wrapper.name}
                                                </Typography>
                                              </Button>
                                              <Typography variant="h6">
                                                {"->"}
                                              </Typography>
                                              <Button
                                                onClick={() =>
                                                  handleNavigate(
                                                    data1,
                                                    2,
                                                    wrapper,
                                                  )
                                                }
                                              >
                                                <Typography variant="h6">
                                                  {data1.name}
                                                </Typography>
                                              </Button>
                                              <Typography variant="h6">
                                                {"->"}
                                              </Typography>
                                              <Button>
                                                <Typography variant="h6">
                                                  {data2.name}
                                                </Typography>
                                              </Button>
                                              <Button
                                                onClick={() =>
                                                  handleClearStrip(wrapper)
                                                }
                                              >
                                                <HighlightOffIcon />
                                              </Button>

                                              <DirectoryEditAndVisibility
                                                item={data1}
                                                wrapper={wrapper}
                                                category={category}
                                                isOpenWrapperEditModal={
                                                  isOpenWrapperEditModal
                                                }
                                                isRoot={false}
                                                setError={setError}
                                                menuNodeList={menuNodeList}
                                                menuId={menuId}
                                                setMenuNodeList={
                                                  setMenuNodeList
                                                }
                                                setSuccess={setSuccess}
                                                handleOpenDirectoryEditModal={
                                                  handleOpenDirectoryEditModal
                                                }
                                              />
                                            </Grid>
                                          )}

                                        {data2?.wrapper &&
                                          Object.values(data2?.wrapper)
                                            ?.length > 0 &&
                                          Object.values(data2?.wrapper).map(
                                            (data3: any) => (
                                              <>
                                                {data3.directory ? (
                                                  <>
                                                    <WrapperDirectory
                                                      removedLinkedIdLists={
                                                        removedLinkedIdLists
                                                      }
                                                      wrapper={wrapper}
                                                      item={data2}
                                                      data={data3}
                                                      handleClickLinkedId={
                                                        handleClickLinkedId
                                                      }
                                                      category={category}
                                                    />

                                                    {/* Level 4 */}
                                                    {removedLinkedIdLists[
                                                      wrapper.id.toString()
                                                    ]?.directoryLevel ===
                                                      data3.directoryLevel &&
                                                      removedLinkedIdLists[
                                                        wrapper.id.toString()
                                                      ]?.id === data3.id && (
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "start",
                                                            alignItems:
                                                              "center",
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          <Button
                                                            onClick={() =>
                                                              handleNavigate(
                                                                wrapper,
                                                                1,
                                                                wrapper,
                                                              )
                                                            }
                                                          >
                                                            <Typography variant="h6">
                                                              {wrapper.name}
                                                            </Typography>
                                                          </Button>
                                                          <Typography variant="h6">
                                                            {"->"}
                                                          </Typography>
                                                          <Button
                                                            onClick={() =>
                                                              handleNavigate(
                                                                data1,
                                                                2,
                                                                wrapper,
                                                              )
                                                            }
                                                          >
                                                            <Typography variant="h6">
                                                              {data1.name}
                                                            </Typography>
                                                          </Button>
                                                          <Typography variant="h6">
                                                            {"->"}
                                                          </Typography>
                                                          <Button
                                                            onClick={() =>
                                                              handleNavigate(
                                                                data2,
                                                                3,
                                                                wrapper,
                                                              )
                                                            }
                                                          >
                                                            <Typography variant="h6">
                                                              {data2.name}
                                                            </Typography>
                                                          </Button>
                                                          <Typography variant="h6">
                                                            {"->"}
                                                          </Typography>
                                                          <Button>
                                                            <Typography variant="h6">
                                                              {data3.name}
                                                            </Typography>
                                                          </Button>
                                                          <Button
                                                            onClick={() =>
                                                              handleClearStrip(
                                                                wrapper,
                                                              )
                                                            }
                                                          >
                                                            <HighlightOffIcon />
                                                          </Button>

                                                          <DirectoryEditAndVisibility
                                                            item={data2}
                                                            wrapper={wrapper}
                                                            category={category}
                                                            isOpenWrapperEditModal={
                                                              isOpenWrapperEditModal
                                                            }
                                                            isRoot={false}
                                                            setError={setError}
                                                            menuNodeList={
                                                              menuNodeList
                                                            }
                                                            menuId={menuId}
                                                            setMenuNodeList={
                                                              setMenuNodeList
                                                            }
                                                            setSuccess={
                                                              setSuccess
                                                            }
                                                            handleOpenDirectoryEditModal={
                                                              handleOpenDirectoryEditModal
                                                            }
                                                          />
                                                        </Grid>
                                                      )}

                                                    {data3?.wrapper &&
                                                      Object.values(
                                                        data3?.wrapper,
                                                      )?.length > 0 &&
                                                      Object.values(
                                                        data3?.wrapper,
                                                      ).map((data4: any) => (
                                                        <>
                                                          {data4.directory ? (
                                                            <>
                                                              <WrapperDirectory
                                                                removedLinkedIdLists={
                                                                  removedLinkedIdLists
                                                                }
                                                                wrapper={
                                                                  wrapper
                                                                }
                                                                item={data3}
                                                                data={data4}
                                                                handleClickLinkedId={
                                                                  handleClickLinkedId
                                                                }
                                                                category={
                                                                  category
                                                                }
                                                              />

                                                              {/* Level 5 */}
                                                              {removedLinkedIdLists[
                                                                wrapper.id.toString()
                                                              ]
                                                                ?.directoryLevel ===
                                                                data4.directoryLevel &&
                                                                removedLinkedIdLists[
                                                                  wrapper.id.toString()
                                                                ]?.id ===
                                                                  data4.id && (
                                                                  <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      justifyContent:
                                                                        "start",
                                                                      alignItems:
                                                                        "center",
                                                                      marginTop:
                                                                        "12px",
                                                                    }}
                                                                  >
                                                                    <Button
                                                                      onClick={() =>
                                                                        handleNavigate(
                                                                          wrapper,
                                                                          1,
                                                                          wrapper,
                                                                        )
                                                                      }
                                                                    >
                                                                      <Typography variant="h6">
                                                                        {
                                                                          wrapper.name
                                                                        }
                                                                      </Typography>
                                                                    </Button>
                                                                    <Typography variant="h6">
                                                                      {"->"}
                                                                    </Typography>
                                                                    <Button
                                                                      onClick={() =>
                                                                        handleNavigate(
                                                                          data1,
                                                                          2,
                                                                          wrapper,
                                                                        )
                                                                      }
                                                                    >
                                                                      <Typography variant="h6">
                                                                        {
                                                                          data1.name
                                                                        }
                                                                      </Typography>
                                                                    </Button>
                                                                    <Typography variant="h6">
                                                                      {"->"}
                                                                    </Typography>
                                                                    <Button
                                                                      onClick={() =>
                                                                        handleNavigate(
                                                                          data2,
                                                                          3,
                                                                          wrapper,
                                                                        )
                                                                      }
                                                                    >
                                                                      <Typography variant="h6">
                                                                        {
                                                                          data2.name
                                                                        }
                                                                      </Typography>
                                                                    </Button>
                                                                    <Typography variant="h6">
                                                                      {"->"}
                                                                    </Typography>
                                                                    <Button
                                                                      onClick={() =>
                                                                        handleNavigate(
                                                                          data3,
                                                                          4,
                                                                          wrapper,
                                                                        )
                                                                      }
                                                                    >
                                                                      <Typography variant="h6">
                                                                        {
                                                                          data3.name
                                                                        }
                                                                      </Typography>
                                                                    </Button>
                                                                    <Typography variant="h6">
                                                                      {"->"}
                                                                    </Typography>
                                                                    <Button>
                                                                      <Typography variant="h6">
                                                                        {
                                                                          data4.name
                                                                        }
                                                                      </Typography>
                                                                    </Button>
                                                                    <Button
                                                                      onClick={() =>
                                                                        handleClearStrip(
                                                                          wrapper,
                                                                        )
                                                                      }
                                                                    >
                                                                      <HighlightOffIcon />
                                                                    </Button>

                                                                    <DirectoryEditAndVisibility
                                                                      item={
                                                                        data3
                                                                      }
                                                                      wrapper={
                                                                        wrapper
                                                                      }
                                                                      category={
                                                                        category
                                                                      }
                                                                      isOpenWrapperEditModal={
                                                                        isOpenWrapperEditModal
                                                                      }
                                                                      isRoot={
                                                                        false
                                                                      }
                                                                      setError={
                                                                        setError
                                                                      }
                                                                      menuNodeList={
                                                                        menuNodeList
                                                                      }
                                                                      menuId={
                                                                        menuId
                                                                      }
                                                                      setMenuNodeList={
                                                                        setMenuNodeList
                                                                      }
                                                                      setSuccess={
                                                                        setSuccess
                                                                      }
                                                                      handleOpenDirectoryEditModal={
                                                                        handleOpenDirectoryEditModal
                                                                      }
                                                                    />
                                                                  </Grid>
                                                                )}

                                                              {data4?.wrapper &&
                                                                Object.values(
                                                                  data4?.wrapper,
                                                                )?.length > 0 &&
                                                                Object.values(
                                                                  data4?.wrapper,
                                                                ).map(
                                                                  (
                                                                    data5: any,
                                                                  ) => (
                                                                    <>
                                                                      {data5.directory ? (
                                                                        <>
                                                                          <WrapperDirectory
                                                                            removedLinkedIdLists={
                                                                              removedLinkedIdLists
                                                                            }
                                                                            wrapper={
                                                                              wrapper
                                                                            }
                                                                            item={
                                                                              data4
                                                                            }
                                                                            data={
                                                                              data5
                                                                            }
                                                                            handleClickLinkedId={
                                                                              handleClickLinkedId
                                                                            }
                                                                            category={
                                                                              category
                                                                            }
                                                                          />

                                                                          {/* Level 6 */}
                                                                          {removedLinkedIdLists[
                                                                            wrapper.id.toString()
                                                                          ]
                                                                            ?.directoryLevel ===
                                                                            data5.directoryLevel &&
                                                                            removedLinkedIdLists[
                                                                              wrapper.id.toString()
                                                                            ]
                                                                              ?.id ===
                                                                              data5.id && (
                                                                              <Grid
                                                                                item
                                                                                xs={
                                                                                  12
                                                                                }
                                                                                style={{
                                                                                  display:
                                                                                    "flex",
                                                                                  justifyContent:
                                                                                    "start",
                                                                                  alignItems:
                                                                                    "center",
                                                                                  marginTop:
                                                                                    "12px",
                                                                                }}
                                                                              >
                                                                                <Button
                                                                                  onClick={() =>
                                                                                    handleNavigate(
                                                                                      wrapper,
                                                                                      1,
                                                                                      wrapper,
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <Typography variant="h6">
                                                                                    {
                                                                                      wrapper.name
                                                                                    }
                                                                                  </Typography>
                                                                                </Button>
                                                                                <Typography variant="h6">
                                                                                  {
                                                                                    "->"
                                                                                  }
                                                                                </Typography>
                                                                                <Button
                                                                                  onClick={() =>
                                                                                    handleNavigate(
                                                                                      data1,
                                                                                      2,
                                                                                      wrapper,
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <Typography variant="h6">
                                                                                    {
                                                                                      data1.name
                                                                                    }
                                                                                  </Typography>
                                                                                </Button>
                                                                                <Typography variant="h6">
                                                                                  {
                                                                                    "->"
                                                                                  }
                                                                                </Typography>
                                                                                <Button
                                                                                  onClick={() =>
                                                                                    handleNavigate(
                                                                                      data2,
                                                                                      3,
                                                                                      wrapper,
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <Typography variant="h6">
                                                                                    {
                                                                                      data2.name
                                                                                    }
                                                                                  </Typography>
                                                                                </Button>
                                                                                <Typography variant="h6">
                                                                                  {
                                                                                    "->"
                                                                                  }
                                                                                </Typography>
                                                                                <Button
                                                                                  onClick={() =>
                                                                                    handleNavigate(
                                                                                      data3,
                                                                                      4,
                                                                                      wrapper,
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <Typography variant="h6">
                                                                                    {
                                                                                      data3.name
                                                                                    }
                                                                                  </Typography>
                                                                                </Button>
                                                                                <Typography variant="h6">
                                                                                  {
                                                                                    "->"
                                                                                  }
                                                                                </Typography>
                                                                                <Button
                                                                                  onClick={() =>
                                                                                    handleNavigate(
                                                                                      data4,
                                                                                      5,
                                                                                      wrapper,
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <Typography variant="h6">
                                                                                    {
                                                                                      data4.name
                                                                                    }
                                                                                  </Typography>
                                                                                </Button>
                                                                                <Typography variant="h6">
                                                                                  {
                                                                                    "->"
                                                                                  }
                                                                                </Typography>
                                                                                <Button>
                                                                                  <Typography variant="h6">
                                                                                    {
                                                                                      data5.name
                                                                                    }
                                                                                  </Typography>
                                                                                </Button>
                                                                                <Button
                                                                                  onClick={() =>
                                                                                    handleClearStrip(
                                                                                      wrapper,
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <HighlightOffIcon />
                                                                                </Button>

                                                                                <DirectoryEditAndVisibility
                                                                                  item={
                                                                                    data4
                                                                                  }
                                                                                  wrapper={
                                                                                    wrapper
                                                                                  }
                                                                                  category={
                                                                                    category
                                                                                  }
                                                                                  isOpenWrapperEditModal={
                                                                                    isOpenWrapperEditModal
                                                                                  }
                                                                                  isRoot={
                                                                                    false
                                                                                  }
                                                                                  setError={
                                                                                    setError
                                                                                  }
                                                                                  menuNodeList={
                                                                                    menuNodeList
                                                                                  }
                                                                                  menuId={
                                                                                    menuId
                                                                                  }
                                                                                  setMenuNodeList={
                                                                                    setMenuNodeList
                                                                                  }
                                                                                  setSuccess={
                                                                                    setSuccess
                                                                                  }
                                                                                  handleOpenDirectoryEditModal={
                                                                                    handleOpenDirectoryEditModal
                                                                                  }
                                                                                />
                                                                              </Grid>
                                                                            )}

                                                                          {data5?.wrapper &&
                                                                            Object.values(
                                                                              data5?.wrapper,
                                                                            )
                                                                              ?.length >
                                                                              0 &&
                                                                            Object.values(
                                                                              data5?.wrapper,
                                                                            ).map(
                                                                              (
                                                                                data6: any,
                                                                              ) => (
                                                                                <>
                                                                                  {data6.directory ? (
                                                                                    <>
                                                                                      <WrapperDirectory
                                                                                        removedLinkedIdLists={
                                                                                          removedLinkedIdLists
                                                                                        }
                                                                                        wrapper={
                                                                                          wrapper
                                                                                        }
                                                                                        item={
                                                                                          data5
                                                                                        }
                                                                                        data={
                                                                                          data6
                                                                                        }
                                                                                        handleClickLinkedId={
                                                                                          handleClickLinkedId
                                                                                        }
                                                                                        category={
                                                                                          category
                                                                                        }
                                                                                      />

                                                                                      {/* Level 7 */}
                                                                                      {removedLinkedIdLists[
                                                                                        wrapper.id.toString()
                                                                                      ]
                                                                                        ?.directoryLevel ===
                                                                                        data6.directoryLevel &&
                                                                                        removedLinkedIdLists[
                                                                                          wrapper.id.toString()
                                                                                        ]
                                                                                          ?.id ===
                                                                                          data6.id && (
                                                                                          <Grid
                                                                                            item
                                                                                            xs={
                                                                                              12
                                                                                            }
                                                                                            style={{
                                                                                              display:
                                                                                                "flex",
                                                                                              justifyContent:
                                                                                                "start",
                                                                                              alignItems:
                                                                                                "center",
                                                                                              marginTop:
                                                                                                "12px",
                                                                                            }}
                                                                                          >
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  wrapper,
                                                                                                  1,
                                                                                                  wrapper,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  wrapper.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  data1,
                                                                                                  2,
                                                                                                  wrapper,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data1.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  data2,
                                                                                                  3,
                                                                                                  wrapper,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data2.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  data3,
                                                                                                  4,
                                                                                                  wrapper,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data3.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  data4,
                                                                                                  5,
                                                                                                  wrapper,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data4.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  data5,
                                                                                                  6,
                                                                                                  wrapper,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data5.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button>
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data6.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleClearStrip(
                                                                                                  wrapper,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <HighlightOffIcon />
                                                                                            </Button>

                                                                                            <DirectoryEditAndVisibility
                                                                                              item={
                                                                                                data5
                                                                                              }
                                                                                              wrapper={
                                                                                                wrapper
                                                                                              }
                                                                                              category={
                                                                                                category
                                                                                              }
                                                                                              isOpenWrapperEditModal={
                                                                                                isOpenWrapperEditModal
                                                                                              }
                                                                                              isRoot={
                                                                                                false
                                                                                              }
                                                                                              setError={
                                                                                                setError
                                                                                              }
                                                                                              menuNodeList={
                                                                                                menuNodeList
                                                                                              }
                                                                                              menuId={
                                                                                                menuId
                                                                                              }
                                                                                              setMenuNodeList={
                                                                                                setMenuNodeList
                                                                                              }
                                                                                              setSuccess={
                                                                                                setSuccess
                                                                                              }
                                                                                              handleOpenDirectoryEditModal={
                                                                                                handleOpenDirectoryEditModal
                                                                                              }
                                                                                            />
                                                                                          </Grid>
                                                                                        )}

                                                                                      {data6?.wrapper &&
                                                                                        Object.values(
                                                                                          data6?.wrapper,
                                                                                        )
                                                                                          ?.length >
                                                                                          0 &&
                                                                                        Object.values(
                                                                                          data6?.wrapper,
                                                                                        ).map(
                                                                                          (
                                                                                            data7: any,
                                                                                          ) => (
                                                                                            <>
                                                                                              {data7.directory ? (
                                                                                                <>
                                                                                                  <WrapperDirectory
                                                                                                    removedLinkedIdLists={
                                                                                                      removedLinkedIdLists
                                                                                                    }
                                                                                                    wrapper={
                                                                                                      wrapper
                                                                                                    }
                                                                                                    item={
                                                                                                      data6
                                                                                                    }
                                                                                                    data={
                                                                                                      data7
                                                                                                    }
                                                                                                    handleClickLinkedId={
                                                                                                      handleClickLinkedId
                                                                                                    }
                                                                                                    category={
                                                                                                      category
                                                                                                    }
                                                                                                  />

                                                                                                  {/* Level 8 */}
                                                                                                  {removedLinkedIdLists[
                                                                                                    wrapper.id.toString()
                                                                                                  ]
                                                                                                    ?.directoryLevel ===
                                                                                                    data7.directoryLevel &&
                                                                                                    removedLinkedIdLists[
                                                                                                      wrapper.id.toString()
                                                                                                    ]
                                                                                                      ?.id ===
                                                                                                      data7.id && (
                                                                                                      <Grid
                                                                                                        item
                                                                                                        xs={
                                                                                                          12
                                                                                                        }
                                                                                                        style={{
                                                                                                          display:
                                                                                                            "flex",
                                                                                                          justifyContent:
                                                                                                            "start",
                                                                                                          alignItems:
                                                                                                            "center",
                                                                                                          marginTop:
                                                                                                            "12px",
                                                                                                        }}
                                                                                                      >
                                                                                                        <Button
                                                                                                          onClick={() =>
                                                                                                            handleNavigate(
                                                                                                              wrapper,
                                                                                                              1,
                                                                                                              wrapper,
                                                                                                            )
                                                                                                          }
                                                                                                        >
                                                                                                          <Typography variant="h6">
                                                                                                            {
                                                                                                              wrapper.name
                                                                                                            }
                                                                                                          </Typography>
                                                                                                        </Button>
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            "->"
                                                                                                          }
                                                                                                        </Typography>
                                                                                                        <Button
                                                                                                          onClick={() =>
                                                                                                            handleNavigate(
                                                                                                              data1,
                                                                                                              2,
                                                                                                              wrapper,
                                                                                                            )
                                                                                                          }
                                                                                                        >
                                                                                                          <Typography variant="h6">
                                                                                                            {
                                                                                                              data1.name
                                                                                                            }
                                                                                                          </Typography>
                                                                                                        </Button>
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            "->"
                                                                                                          }
                                                                                                        </Typography>
                                                                                                        <Button
                                                                                                          onClick={() =>
                                                                                                            handleNavigate(
                                                                                                              data2,
                                                                                                              3,
                                                                                                              wrapper,
                                                                                                            )
                                                                                                          }
                                                                                                        >
                                                                                                          <Typography variant="h6">
                                                                                                            {
                                                                                                              data2.name
                                                                                                            }
                                                                                                          </Typography>
                                                                                                        </Button>
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            "->"
                                                                                                          }
                                                                                                        </Typography>
                                                                                                        <Button
                                                                                                          onClick={() =>
                                                                                                            handleNavigate(
                                                                                                              data3,
                                                                                                              4,
                                                                                                              wrapper,
                                                                                                            )
                                                                                                          }
                                                                                                        >
                                                                                                          <Typography variant="h6">
                                                                                                            {
                                                                                                              data3.name
                                                                                                            }
                                                                                                          </Typography>
                                                                                                        </Button>
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            "->"
                                                                                                          }
                                                                                                        </Typography>
                                                                                                        <Button
                                                                                                          onClick={() =>
                                                                                                            handleNavigate(
                                                                                                              data4,
                                                                                                              5,
                                                                                                              wrapper,
                                                                                                            )
                                                                                                          }
                                                                                                        >
                                                                                                          <Typography variant="h6">
                                                                                                            {
                                                                                                              data4.name
                                                                                                            }
                                                                                                          </Typography>
                                                                                                        </Button>
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            "->"
                                                                                                          }
                                                                                                        </Typography>
                                                                                                        <Button
                                                                                                          onClick={() =>
                                                                                                            handleNavigate(
                                                                                                              data5,
                                                                                                              6,
                                                                                                              wrapper,
                                                                                                            )
                                                                                                          }
                                                                                                        >
                                                                                                          <Typography variant="h6">
                                                                                                            {
                                                                                                              data5.name
                                                                                                            }
                                                                                                          </Typography>
                                                                                                        </Button>
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            "->"
                                                                                                          }
                                                                                                        </Typography>
                                                                                                        <Button
                                                                                                          onClick={() =>
                                                                                                            handleNavigate(
                                                                                                              data6,
                                                                                                              7,
                                                                                                              wrapper,
                                                                                                            )
                                                                                                          }
                                                                                                        >
                                                                                                          <Typography variant="h6">
                                                                                                            {
                                                                                                              data6.name
                                                                                                            }
                                                                                                          </Typography>
                                                                                                        </Button>
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            "->"
                                                                                                          }
                                                                                                        </Typography>
                                                                                                        <Button>
                                                                                                          <Typography variant="h6">
                                                                                                            {
                                                                                                              data7.name
                                                                                                            }
                                                                                                          </Typography>
                                                                                                        </Button>
                                                                                                        <Button
                                                                                                          onClick={() =>
                                                                                                            handleClearStrip(
                                                                                                              wrapper,
                                                                                                            )
                                                                                                          }
                                                                                                        >
                                                                                                          <HighlightOffIcon />
                                                                                                        </Button>

                                                                                                        <DirectoryEditAndVisibility
                                                                                                          item={
                                                                                                            data6
                                                                                                          }
                                                                                                          wrapper={
                                                                                                            wrapper
                                                                                                          }
                                                                                                          category={
                                                                                                            category
                                                                                                          }
                                                                                                          isOpenWrapperEditModal={
                                                                                                            isOpenWrapperEditModal
                                                                                                          }
                                                                                                          isRoot={
                                                                                                            false
                                                                                                          }
                                                                                                          setError={
                                                                                                            setError
                                                                                                          }
                                                                                                          menuNodeList={
                                                                                                            menuNodeList
                                                                                                          }
                                                                                                          menuId={
                                                                                                            menuId
                                                                                                          }
                                                                                                          setMenuNodeList={
                                                                                                            setMenuNodeList
                                                                                                          }
                                                                                                          setSuccess={
                                                                                                            setSuccess
                                                                                                          }
                                                                                                          handleOpenDirectoryEditModal={
                                                                                                            handleOpenDirectoryEditModal
                                                                                                          }
                                                                                                        />
                                                                                                      </Grid>
                                                                                                    )}

                                                                                                  {data7?.wrapper &&
                                                                                                    Object.values(
                                                                                                      data7?.wrapper,
                                                                                                    )
                                                                                                      ?.length >
                                                                                                      0 &&
                                                                                                    Object.values(
                                                                                                      data7?.wrapper,
                                                                                                    ).map(
                                                                                                      (
                                                                                                        data8: any,
                                                                                                      ) => (
                                                                                                        <>
                                                                                                          {data8.directory ? (
                                                                                                            <>
                                                                                                              <WrapperDirectory
                                                                                                                removedLinkedIdLists={
                                                                                                                  removedLinkedIdLists
                                                                                                                }
                                                                                                                wrapper={
                                                                                                                  wrapper
                                                                                                                }
                                                                                                                item={
                                                                                                                  data7
                                                                                                                }
                                                                                                                data={
                                                                                                                  data8
                                                                                                                }
                                                                                                                handleClickLinkedId={
                                                                                                                  handleClickLinkedId
                                                                                                                }
                                                                                                                category={
                                                                                                                  category
                                                                                                                }
                                                                                                              />

                                                                                                              {/* Level 9 */}
                                                                                                              {removedLinkedIdLists[
                                                                                                                wrapper.id.toString()
                                                                                                              ]
                                                                                                                ?.directoryLevel ===
                                                                                                                data8.directoryLevel &&
                                                                                                                removedLinkedIdLists[
                                                                                                                  wrapper.id.toString()
                                                                                                                ]
                                                                                                                  ?.id ===
                                                                                                                  data8.id && (
                                                                                                                  <Grid
                                                                                                                    item
                                                                                                                    xs={
                                                                                                                      12
                                                                                                                    }
                                                                                                                    style={{
                                                                                                                      display:
                                                                                                                        "flex",
                                                                                                                      justifyContent:
                                                                                                                        "start",
                                                                                                                      alignItems:
                                                                                                                        "center",
                                                                                                                      marginTop:
                                                                                                                        "12px",
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <Button
                                                                                                                      onClick={() =>
                                                                                                                        handleNavigate(
                                                                                                                          wrapper,
                                                                                                                          1,
                                                                                                                          wrapper,
                                                                                                                        )
                                                                                                                      }
                                                                                                                    >
                                                                                                                      <Typography variant="h6">
                                                                                                                        {
                                                                                                                          wrapper.name
                                                                                                                        }
                                                                                                                      </Typography>
                                                                                                                    </Button>
                                                                                                                    <Typography variant="h6">
                                                                                                                      {
                                                                                                                        "->"
                                                                                                                      }
                                                                                                                    </Typography>
                                                                                                                    <Button
                                                                                                                      onClick={() =>
                                                                                                                        handleNavigate(
                                                                                                                          data1,
                                                                                                                          2,
                                                                                                                          wrapper,
                                                                                                                        )
                                                                                                                      }
                                                                                                                    >
                                                                                                                      <Typography variant="h6">
                                                                                                                        {
                                                                                                                          data1.name
                                                                                                                        }
                                                                                                                      </Typography>
                                                                                                                    </Button>
                                                                                                                    <Typography variant="h6">
                                                                                                                      {
                                                                                                                        "->"
                                                                                                                      }
                                                                                                                    </Typography>
                                                                                                                    <Button
                                                                                                                      onClick={() =>
                                                                                                                        handleNavigate(
                                                                                                                          data2,
                                                                                                                          3,
                                                                                                                          wrapper,
                                                                                                                        )
                                                                                                                      }
                                                                                                                    >
                                                                                                                      <Typography variant="h6">
                                                                                                                        {
                                                                                                                          data2.name
                                                                                                                        }
                                                                                                                      </Typography>
                                                                                                                    </Button>
                                                                                                                    <Typography variant="h6">
                                                                                                                      {
                                                                                                                        "->"
                                                                                                                      }
                                                                                                                    </Typography>
                                                                                                                    <Button
                                                                                                                      onClick={() =>
                                                                                                                        handleNavigate(
                                                                                                                          data3,
                                                                                                                          4,
                                                                                                                          wrapper,
                                                                                                                        )
                                                                                                                      }
                                                                                                                    >
                                                                                                                      <Typography variant="h6">
                                                                                                                        {
                                                                                                                          data3.name
                                                                                                                        }
                                                                                                                      </Typography>
                                                                                                                    </Button>
                                                                                                                    <Typography variant="h6">
                                                                                                                      {
                                                                                                                        "->"
                                                                                                                      }
                                                                                                                    </Typography>
                                                                                                                    <Button
                                                                                                                      onClick={() =>
                                                                                                                        handleNavigate(
                                                                                                                          data4,
                                                                                                                          5,
                                                                                                                          wrapper,
                                                                                                                        )
                                                                                                                      }
                                                                                                                    >
                                                                                                                      <Typography variant="h6">
                                                                                                                        {
                                                                                                                          data4.name
                                                                                                                        }
                                                                                                                      </Typography>
                                                                                                                    </Button>
                                                                                                                    <Typography variant="h6">
                                                                                                                      {
                                                                                                                        "->"
                                                                                                                      }
                                                                                                                    </Typography>
                                                                                                                    <Button
                                                                                                                      onClick={() =>
                                                                                                                        handleNavigate(
                                                                                                                          data5,
                                                                                                                          6,
                                                                                                                          wrapper,
                                                                                                                        )
                                                                                                                      }
                                                                                                                    >
                                                                                                                      <Typography variant="h6">
                                                                                                                        {
                                                                                                                          data5.name
                                                                                                                        }
                                                                                                                      </Typography>
                                                                                                                    </Button>
                                                                                                                    <Typography variant="h6">
                                                                                                                      {
                                                                                                                        "->"
                                                                                                                      }
                                                                                                                    </Typography>
                                                                                                                    <Button
                                                                                                                      onClick={() =>
                                                                                                                        handleNavigate(
                                                                                                                          data6,
                                                                                                                          7,
                                                                                                                          wrapper,
                                                                                                                        )
                                                                                                                      }
                                                                                                                    >
                                                                                                                      <Typography variant="h6">
                                                                                                                        {
                                                                                                                          data6.name
                                                                                                                        }
                                                                                                                      </Typography>
                                                                                                                    </Button>
                                                                                                                    <Typography variant="h6">
                                                                                                                      {
                                                                                                                        "->"
                                                                                                                      }
                                                                                                                    </Typography>
                                                                                                                    <Button
                                                                                                                      onClick={() =>
                                                                                                                        handleNavigate(
                                                                                                                          data6,
                                                                                                                          7,
                                                                                                                          wrapper,
                                                                                                                        )
                                                                                                                      }
                                                                                                                    >
                                                                                                                      <Typography variant="h6">
                                                                                                                        {
                                                                                                                          data7.name
                                                                                                                        }
                                                                                                                      </Typography>
                                                                                                                    </Button>
                                                                                                                    <Typography variant="h6">
                                                                                                                      {
                                                                                                                        "->"
                                                                                                                      }
                                                                                                                    </Typography>
                                                                                                                    <Button>
                                                                                                                      <Typography variant="h6">
                                                                                                                        {
                                                                                                                          data8.name
                                                                                                                        }
                                                                                                                      </Typography>
                                                                                                                    </Button>
                                                                                                                    <Button
                                                                                                                      onClick={() =>
                                                                                                                        handleClearStrip(
                                                                                                                          wrapper,
                                                                                                                        )
                                                                                                                      }
                                                                                                                    >
                                                                                                                      <HighlightOffIcon />
                                                                                                                    </Button>

                                                                                                                    <DirectoryEditAndVisibility
                                                                                                                      item={
                                                                                                                        data7
                                                                                                                      }
                                                                                                                      wrapper={
                                                                                                                        wrapper
                                                                                                                      }
                                                                                                                      category={
                                                                                                                        category
                                                                                                                      }
                                                                                                                      isOpenWrapperEditModal={
                                                                                                                        isOpenWrapperEditModal
                                                                                                                      }
                                                                                                                      isRoot={
                                                                                                                        false
                                                                                                                      }
                                                                                                                      setError={
                                                                                                                        setError
                                                                                                                      }
                                                                                                                      menuNodeList={
                                                                                                                        menuNodeList
                                                                                                                      }
                                                                                                                      menuId={
                                                                                                                        menuId
                                                                                                                      }
                                                                                                                      setMenuNodeList={
                                                                                                                        setMenuNodeList
                                                                                                                      }
                                                                                                                      setSuccess={
                                                                                                                        setSuccess
                                                                                                                      }
                                                                                                                      handleOpenDirectoryEditModal={
                                                                                                                        handleOpenDirectoryEditModal
                                                                                                                      }
                                                                                                                    />
                                                                                                                  </Grid>
                                                                                                                )}

                                                                                                              {data8?.wrapper &&
                                                                                                                Object.values(
                                                                                                                  data7?.wrapper,
                                                                                                                )
                                                                                                                  ?.length >
                                                                                                                  0 &&
                                                                                                                Object.values(
                                                                                                                  data8?.wrapper,
                                                                                                                ).map(
                                                                                                                  (
                                                                                                                    data9: any,
                                                                                                                  ) => (
                                                                                                                    <>
                                                                                                                      {data9.directory ? (
                                                                                                                        <>
                                                                                                                          <WrapperDirectory
                                                                                                                            removedLinkedIdLists={
                                                                                                                              removedLinkedIdLists
                                                                                                                            }
                                                                                                                            wrapper={
                                                                                                                              wrapper
                                                                                                                            }
                                                                                                                            item={
                                                                                                                              data8
                                                                                                                            }
                                                                                                                            data={
                                                                                                                              data9
                                                                                                                            }
                                                                                                                            handleClickLinkedId={
                                                                                                                              handleClickLinkedId
                                                                                                                            }
                                                                                                                            category={
                                                                                                                              category
                                                                                                                            }
                                                                                                                          />
                                                                                                                        </>
                                                                                                                      ) : (
                                                                                                                        <ProductDirectory
                                                                                                                          item={
                                                                                                                            data8
                                                                                                                          }
                                                                                                                          wrapper={
                                                                                                                            wrapper
                                                                                                                          }
                                                                                                                          handleOpenProductEditModal={
                                                                                                                            handleOpenProductEditModal
                                                                                                                          }
                                                                                                                          handleOpenMappingModal={
                                                                                                                            handleOpenMappingModal
                                                                                                                          }
                                                                                                                          category={
                                                                                                                            category
                                                                                                                          }
                                                                                                                          isLoadProduct={
                                                                                                                            isLoadProduct
                                                                                                                          }
                                                                                                                          getProductWrapperInfo={
                                                                                                                            getProductWrapperInfo
                                                                                                                          }
                                                                                                                          menuNode={
                                                                                                                            menuNode
                                                                                                                          }
                                                                                                                          categoryNode={
                                                                                                                            categoryNode
                                                                                                                          }
                                                                                                                          setSuccess={
                                                                                                                            setSuccess
                                                                                                                          }
                                                                                                                          productVersion={
                                                                                                                            productVersion
                                                                                                                          }
                                                                                                                          removedLinkedIdLists={
                                                                                                                            removedLinkedIdLists
                                                                                                                          }
                                                                                                                          data={
                                                                                                                            data9
                                                                                                                          }
                                                                                                                          menuNodeList={
                                                                                                                            menuNodeList
                                                                                                                          }
                                                                                                                          setMenuNodeList={
                                                                                                                            setMenuNodeList
                                                                                                                          }
                                                                                                                          wrapperId={
                                                                                                                            wrapperId
                                                                                                                          }
                                                                                                                          menuId={
                                                                                                                            menuId
                                                                                                                          }
                                                                                                                          categoryId={
                                                                                                                            categoryId
                                                                                                                          }
                                                                                                                          firstWrapperId={
                                                                                                                            firstWrapperId
                                                                                                                          }
                                                                                                                          isRoot={
                                                                                                                            false
                                                                                                                          }
                                                                                                                        />
                                                                                                                      )}
                                                                                                                    </>
                                                                                                                  ),
                                                                                                                )}
                                                                                                            </>
                                                                                                          ) : (
                                                                                                            <ProductDirectory
                                                                                                              item={
                                                                                                                data7
                                                                                                              }
                                                                                                              wrapper={
                                                                                                                wrapper
                                                                                                              }
                                                                                                              handleOpenProductEditModal={
                                                                                                                handleOpenProductEditModal
                                                                                                              }
                                                                                                              handleOpenMappingModal={
                                                                                                                handleOpenMappingModal
                                                                                                              }
                                                                                                              category={
                                                                                                                category
                                                                                                              }
                                                                                                              isLoadProduct={
                                                                                                                isLoadProduct
                                                                                                              }
                                                                                                              getProductWrapperInfo={
                                                                                                                getProductWrapperInfo
                                                                                                              }
                                                                                                              menuNode={
                                                                                                                menuNode
                                                                                                              }
                                                                                                              categoryNode={
                                                                                                                categoryNode
                                                                                                              }
                                                                                                              setSuccess={
                                                                                                                setSuccess
                                                                                                              }
                                                                                                              productVersion={
                                                                                                                productVersion
                                                                                                              }
                                                                                                              removedLinkedIdLists={
                                                                                                                removedLinkedIdLists
                                                                                                              }
                                                                                                              data={
                                                                                                                data8
                                                                                                              }
                                                                                                              menuNodeList={
                                                                                                                menuNodeList
                                                                                                              }
                                                                                                              setMenuNodeList={
                                                                                                                setMenuNodeList
                                                                                                              }
                                                                                                              wrapperId={
                                                                                                                wrapperId
                                                                                                              }
                                                                                                              menuId={
                                                                                                                menuId
                                                                                                              }
                                                                                                              categoryId={
                                                                                                                categoryId
                                                                                                              }
                                                                                                              firstWrapperId={
                                                                                                                firstWrapperId
                                                                                                              }
                                                                                                              isRoot={
                                                                                                                false
                                                                                                              }
                                                                                                            />
                                                                                                          )}
                                                                                                        </>
                                                                                                      ),
                                                                                                    )}
                                                                                                </>
                                                                                              ) : (
                                                                                                <ProductDirectory
                                                                                                  item={
                                                                                                    data6
                                                                                                  }
                                                                                                  wrapper={
                                                                                                    wrapper
                                                                                                  }
                                                                                                  handleOpenProductEditModal={
                                                                                                    handleOpenProductEditModal
                                                                                                  }
                                                                                                  handleOpenMappingModal={
                                                                                                    handleOpenMappingModal
                                                                                                  }
                                                                                                  category={
                                                                                                    category
                                                                                                  }
                                                                                                  isLoadProduct={
                                                                                                    isLoadProduct
                                                                                                  }
                                                                                                  getProductWrapperInfo={
                                                                                                    getProductWrapperInfo
                                                                                                  }
                                                                                                  menuNode={
                                                                                                    menuNode
                                                                                                  }
                                                                                                  categoryNode={
                                                                                                    categoryNode
                                                                                                  }
                                                                                                  setSuccess={
                                                                                                    setSuccess
                                                                                                  }
                                                                                                  productVersion={
                                                                                                    productVersion
                                                                                                  }
                                                                                                  removedLinkedIdLists={
                                                                                                    removedLinkedIdLists
                                                                                                  }
                                                                                                  data={
                                                                                                    data7
                                                                                                  }
                                                                                                  menuNodeList={
                                                                                                    menuNodeList
                                                                                                  }
                                                                                                  setMenuNodeList={
                                                                                                    setMenuNodeList
                                                                                                  }
                                                                                                  wrapperId={
                                                                                                    wrapperId
                                                                                                  }
                                                                                                  menuId={
                                                                                                    menuId
                                                                                                  }
                                                                                                  categoryId={
                                                                                                    categoryId
                                                                                                  }
                                                                                                  firstWrapperId={
                                                                                                    firstWrapperId
                                                                                                  }
                                                                                                  isRoot={
                                                                                                    false
                                                                                                  }
                                                                                                />
                                                                                              )}
                                                                                            </>
                                                                                          ),
                                                                                        )}
                                                                                    </>
                                                                                  ) : (
                                                                                    <ProductDirectory
                                                                                      item={
                                                                                        data5
                                                                                      }
                                                                                      wrapper={
                                                                                        wrapper
                                                                                      }
                                                                                      handleOpenProductEditModal={
                                                                                        handleOpenProductEditModal
                                                                                      }
                                                                                      handleOpenMappingModal={
                                                                                        handleOpenMappingModal
                                                                                      }
                                                                                      category={
                                                                                        category
                                                                                      }
                                                                                      isLoadProduct={
                                                                                        isLoadProduct
                                                                                      }
                                                                                      getProductWrapperInfo={
                                                                                        getProductWrapperInfo
                                                                                      }
                                                                                      menuNode={
                                                                                        menuNode
                                                                                      }
                                                                                      categoryNode={
                                                                                        categoryNode
                                                                                      }
                                                                                      setSuccess={
                                                                                        setSuccess
                                                                                      }
                                                                                      productVersion={
                                                                                        productVersion
                                                                                      }
                                                                                      removedLinkedIdLists={
                                                                                        removedLinkedIdLists
                                                                                      }
                                                                                      data={
                                                                                        data6
                                                                                      }
                                                                                      menuNodeList={
                                                                                        menuNodeList
                                                                                      }
                                                                                      setMenuNodeList={
                                                                                        setMenuNodeList
                                                                                      }
                                                                                      wrapperId={
                                                                                        wrapperId
                                                                                      }
                                                                                      menuId={
                                                                                        menuId
                                                                                      }
                                                                                      categoryId={
                                                                                        categoryId
                                                                                      }
                                                                                      firstWrapperId={
                                                                                        firstWrapperId
                                                                                      }
                                                                                      isRoot={
                                                                                        false
                                                                                      }
                                                                                    />
                                                                                  )}
                                                                                </>
                                                                              ),
                                                                            )}
                                                                        </>
                                                                      ) : (
                                                                        <ProductDirectory
                                                                          item={
                                                                            data4
                                                                          }
                                                                          wrapper={
                                                                            wrapper
                                                                          }
                                                                          handleOpenProductEditModal={
                                                                            handleOpenProductEditModal
                                                                          }
                                                                          handleOpenMappingModal={
                                                                            handleOpenMappingModal
                                                                          }
                                                                          category={
                                                                            category
                                                                          }
                                                                          isLoadProduct={
                                                                            isLoadProduct
                                                                          }
                                                                          getProductWrapperInfo={
                                                                            getProductWrapperInfo
                                                                          }
                                                                          menuNode={
                                                                            menuNode
                                                                          }
                                                                          categoryNode={
                                                                            categoryNode
                                                                          }
                                                                          setSuccess={
                                                                            setSuccess
                                                                          }
                                                                          productVersion={
                                                                            productVersion
                                                                          }
                                                                          removedLinkedIdLists={
                                                                            removedLinkedIdLists
                                                                          }
                                                                          data={
                                                                            data5
                                                                          }
                                                                          menuNodeList={
                                                                            menuNodeList
                                                                          }
                                                                          setMenuNodeList={
                                                                            setMenuNodeList
                                                                          }
                                                                          wrapperId={
                                                                            wrapperId
                                                                          }
                                                                          menuId={
                                                                            menuId
                                                                          }
                                                                          categoryId={
                                                                            categoryId
                                                                          }
                                                                          firstWrapperId={
                                                                            firstWrapperId
                                                                          }
                                                                          isRoot={
                                                                            false
                                                                          }
                                                                        />
                                                                      )}
                                                                    </>
                                                                  ),
                                                                )}
                                                            </>
                                                          ) : (
                                                            <ProductDirectory
                                                              item={data3}
                                                              wrapper={wrapper}
                                                              handleOpenProductEditModal={
                                                                handleOpenProductEditModal
                                                              }
                                                              handleOpenMappingModal={
                                                                handleOpenMappingModal
                                                              }
                                                              category={
                                                                category
                                                              }
                                                              isLoadProduct={
                                                                isLoadProduct
                                                              }
                                                              getProductWrapperInfo={
                                                                getProductWrapperInfo
                                                              }
                                                              menuNode={
                                                                menuNode
                                                              }
                                                              categoryNode={
                                                                categoryNode
                                                              }
                                                              setSuccess={
                                                                setSuccess
                                                              }
                                                              productVersion={
                                                                productVersion
                                                              }
                                                              removedLinkedIdLists={
                                                                removedLinkedIdLists
                                                              }
                                                              data={data4}
                                                              menuNodeList={
                                                                menuNodeList
                                                              }
                                                              setMenuNodeList={
                                                                setMenuNodeList
                                                              }
                                                              wrapperId={
                                                                wrapperId
                                                              }
                                                              menuId={menuId}
                                                              categoryId={
                                                                categoryId
                                                              }
                                                              firstWrapperId={
                                                                firstWrapperId
                                                              }
                                                              isRoot={false}
                                                            />
                                                          )}
                                                        </>
                                                      ))}
                                                  </>
                                                ) : (
                                                  <ProductDirectory
                                                    item={data2}
                                                    wrapper={wrapper}
                                                    handleOpenProductEditModal={
                                                      handleOpenProductEditModal
                                                    }
                                                    handleOpenMappingModal={
                                                      handleOpenMappingModal
                                                    }
                                                    category={category}
                                                    isLoadProduct={
                                                      isLoadProduct
                                                    }
                                                    getProductWrapperInfo={
                                                      getProductWrapperInfo
                                                    }
                                                    menuNode={menuNode}
                                                    categoryNode={categoryNode}
                                                    setSuccess={setSuccess}
                                                    productVersion={
                                                      productVersion
                                                    }
                                                    removedLinkedIdLists={
                                                      removedLinkedIdLists
                                                    }
                                                    data={data3}
                                                    menuNodeList={menuNodeList}
                                                    setMenuNodeList={
                                                      setMenuNodeList
                                                    }
                                                    wrapperId={wrapperId}
                                                    menuId={menuId}
                                                    categoryId={categoryId}
                                                    firstWrapperId={
                                                      firstWrapperId
                                                    }
                                                    isRoot={false}
                                                  />
                                                )}
                                              </>
                                            ),
                                          )}
                                      </>
                                    ) : (
                                      <ProductDirectory
                                        item={data1}
                                        wrapper={wrapper}
                                        handleOpenProductEditModal={
                                          handleOpenProductEditModal
                                        }
                                        handleOpenMappingModal={
                                          handleOpenMappingModal
                                        }
                                        category={category}
                                        isLoadProduct={isLoadProduct}
                                        getProductWrapperInfo={
                                          getProductWrapperInfo
                                        }
                                        menuNode={menuNode}
                                        categoryNode={categoryNode}
                                        setSuccess={setSuccess}
                                        productVersion={productVersion}
                                        removedLinkedIdLists={
                                          removedLinkedIdLists
                                        }
                                        data={data2}
                                        menuNodeList={menuNodeList}
                                        setMenuNodeList={setMenuNodeList}
                                        wrapperId={wrapperId}
                                        menuId={menuId}
                                        categoryId={categoryId}
                                        firstWrapperId={firstWrapperId}
                                        isRoot={false}
                                      />
                                    )}
                                  </>
                                ),
                              )}
                          </>
                        ) : (
                          <ProductDirectory
                            item={wrapper}
                            wrapper={wrapper}
                            handleOpenProductEditModal={
                              handleOpenProductEditModal
                            }
                            handleOpenMappingModal={handleOpenMappingModal}
                            category={category}
                            isLoadProduct={isLoadProduct}
                            getProductWrapperInfo={getProductWrapperInfo}
                            menuNode={menuNode}
                            categoryNode={categoryNode}
                            setSuccess={setSuccess}
                            productVersion={productVersion}
                            removedLinkedIdLists={removedLinkedIdLists}
                            data={data1}
                            menuNodeList={menuNodeList}
                            setMenuNodeList={setMenuNodeList}
                            wrapperId={wrapperId}
                            menuId={menuId}
                            categoryId={categoryId}
                            firstWrapperId={firstWrapperId}
                            isRoot={false}
                          />
                        )}
                      </>
                    ))}

                  {productVersion !== 1 && (
                    <Grid
                      item
                      md={6}
                      xs={12}
                      lg={4}
                      style={{
                        marginBottom: "6px",
                      }}
                    >
                      <CardCommon backgroundColor={"entity_background"}>
                        <Button
                          variant="contained"
                          className={classes.addNodeButton}
                          onClick={() => {
                            setIsProductAdd(true);
                            handleOpenProductOrDirectoryModals(
                              category,
                              wrapper,
                            );
                          }}
                        >
                          <Typography>
                            <AddIcon fontSize="large" />
                          </Typography>
                          <Typography style={{ marginLeft: "12px" }}>
                            Add New
                          </Typography>
                        </Button>
                      </CardCommon>
                    </Grid>
                  )}
                </Grid>
              </CardCommon>
            </Grid>
          )
        : productVersion !== 1 &&
          removedLinkedIdLists[wrapper?.id] && (
            <Grid
              container
              spacing={2}
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                marginBottom: "8px",
                marginTop: "8px",
              }}
            >
              <DirectoryEditAndVisibility
                item={wrapper}
                wrapper={wrapper}
                category={category}
                isOpenWrapperEditModal={isOpenWrapperEditModal}
                isRoot={false}
                setError={setError}
                menuNodeList={menuNodeList}
                menuId={menuId}
                setMenuNodeList={setMenuNodeList}
                setSuccess={setSuccess}
                handleOpenDirectoryEditModal={handleOpenDirectoryEditModal}
              />
              <CardCommon
                backgroundColor={"entity_highlight_directory_background"}
                style={{
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    paddingLeft: "14px",
                    paddingRight: "14px",
                    marginBottom: "6px",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Button onClick={() => handleNavigate(wrapper, 1, wrapper)}>
                      <Typography variant="h6">{wrapper.name}</Typography>
                    </Button>
                    <Button onClick={() => handleClearStrip(wrapper)}>
                      <HighlightOffIcon />
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    lg={4}
                    style={{
                      marginBottom: "6px",
                    }}
                  >
                    <CardCommon backgroundColor={"entity_background"}>
                      <Button
                        variant="contained"
                        className={classes.addNodeButton}
                        onClick={() => {
                          setIsProductAdd(true);
                          handleOpenProductOrDirectoryModals(category, wrapper);
                        }}
                      >
                        <Typography>
                          <AddIcon fontSize="large" />
                        </Typography>
                        <Typography style={{ marginLeft: "12px" }}>
                          Add New
                        </Typography>
                      </Button>
                    </CardCommon>
                  </Grid>
                </Grid>
              </CardCommon>
            </Grid>
          )}
    </>
  );
};

export default WrapperNode;
