import { jsPDF } from "jspdf";
import "jspdf-autotable";
import _ from "lodash";
import moment from "moment";
import { ExportPdfColor } from "../../../../../root/theme/exportColor";
import eatprestoLogo from "../../../../../assets/images/logos/logo.png";

/* Create a pdf file */
export const handleDownloadPdf = (
  scapeChange: string,
  columns: any,
  nodes: any,
  filterDetails: any,
) => {
  const doc: any = new jsPDF(scapeChange === "p" ? "p" : "l");
  const totalPagesExp = "{total_pages_count_string}";
  const locationFilter = filterDetails.split("*")[0];
  const pageWidth = doc.internal.pageSize.getWidth();
  const today = moment().format("MMM_Do_YY").toString();
  let locationLength = 0;

  if (!locationFilter.includes(",")) {
    locationLength = 10;
  }

  /* If there is a image, insert it into the img variable. */
  const img = new Image();
  img.src = eatprestoLogo;

  /* Create a pdf file. */
  doc.autoTable({
    columns: columns.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    startY: 58 + locationLength,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 8,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: { fontStyle: "bold", halign: "left" },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColor.OddRowColor],
      textColor: ["#ffffff"],
    },
    tableLineWidth: 0.1,
    // Change footer style.
    willDrawCell: function (data: any) {
      const rows = data.table.body;
      if (data.row.index === rows.length - 1) {
        doc.setFillColor(ExportPdfColor.green);
        doc.setTextColor(255, 255, 255);
        // doc.setFont(undefined, 'bold')
      }
    },

    // Add topic and sub topics.
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();
      // Change topic font size
      doc.setFontSize(12);
      doc.text("Presto Express Solutions", data.settings.margin.left, 12);
      // Modify the marginLeft according to the scape.
      doc.text("Item Sales Report", data.settings.margin.left + 148, 12);

      // Change main title font size
      doc.setFontSize(16);
      // Insert a topic for the first page only.
      if (doc.internal.getNumberOfPages() === 1) {
        /* Change the title according to the number of locations.
        If the location size is increased, show presto express. if the location size is the one, show that location. */
        doc.setTextColor("#32363E");
        if (locationFilter.includes(",")) {
        } else {
          doc.text(
            filterDetails
              .split("*")[0]
              .replace("Item Sales report for location/s:", ""),
            pageWidth / 2,
            36,
            {
              align: "center",
            },
          );
        }

        doc.setTextColor(0, 0, 0);
        doc.text(
          "Item Sales Report",
          pageWidth / 2,
          36 + locationLength,
          "center",
        );
        doc.setFontSize(10);

        /* Change the sub title according to the number of locations.
        If the location size is increased, show number of locations. if the location size is the one, show that location. */
        let filterLocationsDetails = "";
        if (locationFilter.split(",").length === 1) {
          filterLocationsDetails =
            "Item Sales Report for " + locationFilter.split(",")[0];
        } else {
          filterLocationsDetails =
            "Item Sales Report for " +
            locationFilter.split(",").length +
            " Locations";
        }

        doc.text(
          doc.splitTextToSize(filterLocationsDetails, 210 - 15 - 15),
          pageWidth / 2,
          44 + locationLength,
          {
            align: "center",
          },
        );

        /* Set the start date and end date */
        doc.text(
          doc.splitTextToSize(
            "Generated for the period of " + filterDetails.split("*")[1],
            210 - 15 - 15,
          ),
          pageWidth / 2,
          50 + locationLength,
          {
            align: "center",
          },
        );
      }

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);
      doc.addImage(
        img,
        "JPEG",
        data.settings.margin.left + 144,
        pageHeight - 24,
        40,
        16,
      );
    },
    /* Change the backgroundcolor in the row. */
    didParseCell: function (data: any, index: any) {
      const rows = data.table.body;

      if (data.section === "head") {
        if (data.column.dataKey === "itemName") {
          data.cell.styles.cellPadding = [0, 0, 0, 2.7];
          data.cell.styles.halign = "left";
        } else if (data.column.dataKey === "vatAmount") {
          data.cell.styles.halign = "right";
        } else {
          data.cell.styles.halign = "center";
        }
      }
      if (data.row.raw.itemName === "Total" && data.column.index === 0) {
        data.cell.styles.cellPadding = [0, 0, 0, 2.7];
      }
      if (data.column.index === 6) {
        data.cell.styles.halign = "right";
      }
      if (data.row.index !== 0) {
        if (data.row.raw.nodes === "main") {
          data.cell.styles.fillColor = [ExportPdfColor.blueLight];
          data.cell.styles.textColor = ["#ffffff"];
        } else {
          if (data.row.index % 2 === 0) {
            data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
            data.cell.styles.textColor = ["#ffffff"];
          } else {
            data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
            data.cell.styles.textColor = ["#ffffff"];
          }
        }
        if (data.row.raw.nodes === "sub" && data.column.index === 0) {
          data.cell.styles.cellPadding = [0, 0, 0, 8];
        }
      } else {
        if (data.row.raw.nodes === "main") {
          data.cell.styles.fillColor = [ExportPdfColor.blueLight];
          data.cell.styles.textColor = ["#ffffff"];
        }
      }
      if (
        (data.row.raw.isDuplicateItemName === false &&
          data.column.index === 0) ||
        (data.row.raw.isDuplicateUnitPrice === false &&
          data.column.index === 1) ||
        (data.row.raw.isDuplicateVatPercent === false &&
          data.column.index === 5)
      ) {
        data.cell.styles.fillColor = [ExportPdfColor.purple];
        data.cell.styles.textColor = ["#ffffff"];
      }
      if (data.row.index + 1 === rows.length) {
        data.cell.styles.fontStyle = "bold";
      }
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });
  // Total page number plugin only available in jspdf v1.0+

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(
    `Item_Sales_Report_${today}_${Math.floor(
      100000 + Math.random() * 900000,
    )}.pdf`,
  );
};
