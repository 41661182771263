import { axiosClient } from "../client";
const queryString = require("query-string");
const API_GET_CATEGORY_MENU = (publishedMenuId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CATEGORY_MENU}/published/menu/${publishedMenuId}`;

const API_UPDATE_CATEGORY_MENU = (publishedMenuId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CATEGORY_MENU}/published/menu/${publishedMenuId}`;

const API_GET_CATEGORY_MENU_LIST = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CATEGORY_MENU}/location/${locationId}/published-menu-map`;

const API_GET_MENU_OPTION = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CATEGORY_MENU}/public/location/${locationId}/published/menu-options/eat_presto`;

const API_UPDATE_MENU_OPTION = (locationId, menuOptionId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CATEGORY_MENU}/location/${locationId}/menu-options/${menuOptionId}`;

  const API_GET_ALL_CATEGORY_MENU = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CATEGORY_MENU}/public/location/${locationId}/published/eat_presto`;

// const fetchCategoryMenu = (locationId) =>
//   axiosClient({
//     method: "GET",
//     url: API_GET_CATEGORY_MENU(locationId),
//     headers: {
//       "X-Role-Location": locationId,
//       "Content-Type": "application/json",
//     },
//   });

export const fetchCategoryMenu = (publishedMenuId, token) =>
  axiosClient({
    method: "GET",
    url: API_GET_CATEGORY_MENU(publishedMenuId),
    headers: {
      "X-Role-Location": publishedMenuId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const updateCategoryMenu = (publishedMenuId, token, data) =>
  axiosClient({
    method: "PUT",
    url: API_UPDATE_CATEGORY_MENU(publishedMenuId),
    data,
    headers: {
      "X-Role-Location": publishedMenuId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

// export const fetchCategoryMenuList = (locationId, token) =>
//   axiosClient({
//     method: "GET",
//     url: API_GET_CATEGORY_MENU_LIST(locationId),
//     headers: {
//       "X-Role-Location": locationId,
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   });

export const fetchCategoryMenuList = (
  locationId,
  token,
  shortingHeaderName,
  shortingMethod,
  
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_CATEGORY_MENU_LIST(locationId),
        query: { sort: [shortingHeaderName, shortingMethod] },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchMenuOption = (locationId) =>
  axiosClient({
    method: "GET",
    url: API_GET_MENU_OPTION(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateMenuOptionInfo = (locationId, menuOptionId, data) =>
  axiosClient({
    method: "PUT",
    url: API_UPDATE_MENU_OPTION(locationId, menuOptionId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export  const fetchAllCategoryMenu = (locationId) =>
  axiosClient({
    method: "GET",
    url: API_GET_ALL_CATEGORY_MENU(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export default fetchCategoryMenu;
