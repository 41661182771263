import {
  Button,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import CardCommon from "../../../../components/card/CardCommon";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import { Icon } from "@iconify/react";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    gridStyle: {
      display: "flex",
      justifyContent: "start",
      textAlign: "left",
      placeItems: "center",
      paddingLeft: "12px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        paddingTop: "16px",
      },
    },
    gridStyleIcons: {
      display: "flex",
      justifyContent: "space-between",
      placeItems: "center",
      paddingLeft: "12px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        paddingBottom: "16px",
      },
    },
  }),
);

export interface MenuInfoPageProps {
  nodeData: any;
  handleGetMenuInfo: any;
}

/* Body page of menu information table. */
const MenuBody: React.SFC<MenuInfoPageProps> = ({
  nodeData,
  handleGetMenuInfo,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      key={nodeData.id}
      style={{ placeItems: "center", marginTop: "4px" }}
    >
      <CardCommon backgroundColor={"entity_background"}>
        <Grid container>
          <Grid item xs={12} md={2} className={classes.gridStyle}>
            {nodeData.publishedMenu.title}
          </Grid>
          <Grid item xs={12} md={3} className={classes.gridStyle}>
            {nodeData.created}
          </Grid>
          <Grid item xs={12} md={3} className={classes.gridStyle}>
            {nodeData.updated}
          </Grid>
          <Grid item xs={12} md={1} className={classes.gridStyle}>
            {nodeData.version}
          </Grid>
          <Grid item xs={12} md={3} className={classes.gridStyleIcons}>
            <FormControlLabel
              style={{ marginLeft: "0px", marginRight: "0px" }}
              value="dinein"
              disabled
              control={<SwitchCommon />}
              label={
                <Typography variant="caption" color="textSecondary">
                  Status
                </Typography>
              }
              checked={nodeData.isActive}
              name="dinein"
              labelPlacement="start"
            />
            <Button onClick={() => handleGetMenuInfo(nodeData.publishedMenuId)}>
              <Icon icon="bx:edit" width="20px" height="20px" />
            </Button>
          </Grid>
        </Grid>
      </CardCommon>
    </Grid>
  );
};

export default withAuthority(MenuBody, Authorities.MENU_READ);
