import {
  Avatar,
  Card,
  createStyles,
  Divider,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import ListIcon from "@material-ui/icons/List";
import { Link } from "react-scroll";

import { getWindowDimensions } from "../../../../utils/windowDimension";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    avatarStyle: {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      width: "54px",
      height: "54px",
      marginLeft: "16px",
    },
    menuStyle: {
      top: "40px",
      marginTop: "128px",
      padding: "20px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.imgBackground,
    },
    cardStyle: {
      backgroundColor: theme.palette.background.default,
      boxShadow: "none",
    },
  }),
);

export interface CatMenuPopUpProps {
  setAnchorEl: any;
  anchorEl: any;
  departmentList: any;
  handleOpenCategoryModal: any;
}

/**
 * CatMenuPopUp Component: Category Menu Popup
 *
 * This component represents a popup menu displaying a list of categories. It allows users to navigate to specific categories
 * within a department. The popup is triggered by clicking on an avatar icon, and it displays a list of categories from the
 * departmentList prop.
 */
const CatMenuPopUp: React.FunctionComponent<CatMenuPopUpProps> = ({
  setAnchorEl,
  anchorEl,
  departmentList,
  handleOpenCategoryModal,
}) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const open = Boolean(anchorEl);
  const hasWindow = typeof window !== "undefined";

  /* Open the UI showing the list of categories. */
  const handleOpenCategoryList = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close the UI showing the list of categories. */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* Get the screen size on the website after screen change. */
  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  return (
    <>
      <Avatar onClick={handleOpenCategoryList} className={classes.avatarStyle}>
        <ListIcon
          style={{
            fontSize: "30px",
            color: theme.palette.custom.orange.contrastText,
          }}
        />
      </Avatar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          className: classes.menuStyle,
        }}
        style={{
          maxHeight: `${windowDimensions.height}px`,
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Card className={classes.cardStyle}>
          {!_.isEmpty(departmentList) &&
            departmentList.map((row: any) => (
              <div style={{ maxHeight: `${windowDimensions.height}px` }}>
                <MenuItem
                  style={{
                    padding: "0px",
                    display: "flex",
                  }}
                >
                  {row.id === "category" ? (
                    <Typography
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        padding: "12px",
                      }}
                      onClick={handleOpenCategoryModal}
                    >
                      {row.name}
                    </Typography>
                  ) : (
                    <Link
                      onClick={handleClose}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        padding: "12px",
                      }}
                      activeClass="active"
                      to={row.name}
                      spy={true}
                      smooth={true}
                      offset={-52}
                      duration={500}
                      delay={50}
                    >
                      {row.name}
                    </Link>
                  )}
                </MenuItem>
                <Divider />
              </div>
            ))}
        </Card>
      </Menu>
    </>
  );
};

export default CatMenuPopUp;
