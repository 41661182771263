import { Grid } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import WithLoading from "../../../../utils/WithLoading";
import DashboardDetailsCard from "./DashboardDetailsCard";
import ordersIcon from "../../../../assets/images/icons/ordersIcon.png";
import averageIcon from "../../../../assets/images/icons/averageIcon.png";
import discount from "../../../../assets/images/icons/discount.png";
import itemSales from "../../../../assets/images/icons/itemSales.png";
import cashPayment from "../../../../assets/images/icons/cashPayment.png";
import cardPayment from "../../../../assets/images/icons/cardPayment.png";
import otherPayment from "../../../../assets/images/icons/otherPayment.png";
import totalPayment from "../../../../assets/images/icons/totalPayment.png";

export interface DashboardDetailsInfoNodeProps {
  isLoading: any;
  currentSalesTotal: any;
  previousSalesTotal: any;
}

/*
 * DashboardDetailsInfoNode is a React functional component responsible for rendering
 * a grid of dashboard details cards. It receives isLoading, currentSalesTotal, and
 * previousSalesTotal as props, and based on these props, it generates a list of sales
 * total details to be displayed on each card. It also utilizes WithLoading HOC to handle
 * loading states. The component maps over the salesTotalNode list and renders a
 * DashboardDetailsCard component for each item in the list.
 */
const DashboardDetailsInfoNode: React.FunctionComponent<
  DashboardDetailsInfoNodeProps
> = ({ isLoading, currentSalesTotal, previousSalesTotal }) => {
  const [salesTotalNode, setSalesTotalNode] = useState<any>([]);

  /* Using the data from the API call, lists the icon, amount name, current amount, 
  and previous amount to be displayed on each card. */
  useEffect(() => {
    if (!_.isEmpty(currentSalesTotal) && !_.isEmpty(previousSalesTotal)) {
      let saleTotalList: any = [];
      saleTotalList.push(
        {
          icon: ordersIcon,
          title: "NO OF ORDERS",
          currentAmount: currentSalesTotal["orderCount"],
          previousAmount: previousSalesTotal["orderCount"],
        },
        {
          icon: averageIcon,
          title: "AVERAGE ORDER",
          currentAmount: parseFloat(currentSalesTotal["avgAmount"]).toFixed(2),
          previousAmount: parseFloat(previousSalesTotal["avgAmount"]).toFixed(
            2,
          ),
        },
        {
          icon: discount,
          title: "DISCOUNT",
          currentAmount: parseFloat(
            currentSalesTotal["discountAmount"],
          ).toFixed(2),
          previousAmount: parseFloat(
            previousSalesTotal["discountAmount"],
          ).toFixed(2),
        },
        {
          icon: itemSales,
          title: "ITEM SALES TOTAL",
          currentAmount: parseFloat(currentSalesTotal["salesTotal"]).toFixed(2),
          previousAmount: parseFloat(previousSalesTotal["salesTotal"]).toFixed(
            2,
          ),
        },
        {
          icon: cashPayment,
          title: "CASH PAYMENTS",
          currentAmount: parseFloat(currentSalesTotal["cashPayment"]).toFixed(
            2,
          ),
          previousAmount: parseFloat(previousSalesTotal["cashPayment"]).toFixed(
            2,
          ),
        },
        {
          icon: cardPayment,
          title: "CARD PAYMENTS",
          currentAmount: parseFloat(currentSalesTotal["cardPayment"]).toFixed(
            2,
          ),
          previousAmount: parseFloat(previousSalesTotal["cardPayment"]).toFixed(
            2,
          ),
        },
        {
          icon: otherPayment,
          title: "OTHER PAYMENTS",
          currentAmount: parseFloat(currentSalesTotal["otherPayment"]).toFixed(
            2,
          ),
          previousAmount: parseFloat(
            previousSalesTotal["otherPayment"],
          ).toFixed(2),
        },
        {
          icon: totalPayment,
          title: "TOTAL PAYMENTS",
          currentAmount: parseFloat(currentSalesTotal["totalPayment"]).toFixed(
            2,
          ),
          previousAmount: parseFloat(
            previousSalesTotal["totalPayment"],
          ).toFixed(2),
        },
      );
      setSalesTotalNode(saleTotalList);
    }
  }, [currentSalesTotal, previousSalesTotal]);
  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "12px" }}>
        {salesTotalNode.map((data: any) => {
          return (
            <Grid item xs={6} md={3}>
              <DashboardDetailsCard salesTotalObject={data} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default WithLoading(DashboardDetailsInfoNode, "text");
