 export const isEqualArrays = (arr1: any, arr2: any) => {
    // If lengths are not equal, arrays are not equal
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Sort both arrays
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    // Compare elements of both arrays
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    // If all elements match, arrays are equal
    return true;
  };

 export const isEqualArrayObject = (arr1: any, arr2: any) => {
    // Sort arrays based on the string representation of their objects
    const results = arr1.filter((value: any) =>
      arr2.some((value1: any) => {
        return (
          value.closeTime !== value1.closeTime ||
          value.day !== value1.day ||
          value.durationMins !== value1.durationMins ||
          value.minuteOfWeek !== value1.minuteOfWeek ||
          value.openTime !== value1.openTime
        );
      }),
    );

    // Check if the lengths of the arrays are the same
    const lengthSame = arr1.length > arr2.length || arr2.length > arr1.length;

    // Return false if any unmatched elements were found or if the lengths are different, otherwise return true
    return results.length > 0 || lengthSame ? false : true;
  };