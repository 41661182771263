import React from "react";
import _ from "lodash";
import TextfieldCommon from "../../textField/TextfieldCommon";

export interface InputBoxDefaultProps {
  label: string;
  handleChange?: any;
  value: any;
  name: string;
  id: string;
  register?: any;
  errors?: any;
  helperText?: any;
  multiline?: any;
  rows?: any;
  disabled?: any;
}

/* Common text field */
const InputBoxDefault: React.FunctionComponent<InputBoxDefaultProps> = ({
  label,
  handleChange,
  value,
  name,
  id,
  errors,
  register,
  helperText,
  multiline,
  rows,
  disabled,
}) => {
  const isError = !_.isEmpty(errors) && !_.isEmpty(errors[name]);
  return (
    <TextfieldCommon
      id={id}
      value={value}
      label={label}
      margin={"normal"}
      type={"text"}
      onChange={handleChange}
      name={name}
      error={isError}
      helperText={isError && helperText}
      inputRef={register}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
    />
  );
};

export default InputBoxDefault;