import {
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { Icon } from "@iconify/react";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: `1px solid ${theme.palette.background.entity_border}`,
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
  }),
);

export interface CategoryMenuDropDownProps {
  categoryMenuList: any;
  selectedCatMenuTitle: any;
  handleChangeCatMenu: any;
  handleOpenMenuEditModal: any;
  productVersion: any;
}

/**
 * CategoryMenuDropDown Component: Category Menu DropDown
 *
 * This component represents a dropdown menu for selecting a category from a list of category menu options. It utilizes
 * Material-UI's Autocomplete component to provide a searchable and selectable dropdown menu.
 */
const CategoryMenuDropDown: React.FunctionComponent<
  CategoryMenuDropDownProps
> = ({
  categoryMenuList,
  selectedCatMenuTitle,
  handleChangeCatMenu,
  handleOpenMenuEditModal,
  productVersion,
}) => {
  const groupList: any = Object.values(categoryMenuList);
  const classes = useStyles();

  return (
    <Autocomplete
      id="locationSelectGlobal"
      color="inherit"
      value={groupList.find((v: any) => v.name === selectedCatMenuTitle)}
      options={groupList}
      getOptionLabel={(option: any) => option.name}
      disableClearable
      style={{ zIndex: 0 }}
      renderOption={(props) => {
        return (
          <Grid
            container
            {...props}
            style={{
              display: "flex",
              justifyContent: "space-between",
              zIndex: 0,
              height: "20px",
              marginBottom: "4px",
            }}
          >
            <Grid
              item
              xs={11}
              onClick={() => {
                handleChangeCatMenu(props);
              }}
            >
              <Typography variant="body2" className={classes.titleColor}>
                {props.name}
              </Typography>
            </Grid>
            {productVersion !== 1 && (
              <Grid item xs={1} style={{ paddingRight: "8px" }}>
                <Icon
                  icon="bx:edit"
                  style={{ cursor: "pointer", zIndex: 50 }}
                  width="24px"
                  height="24px"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenMenuEditModal(props);
                  }}
                />
              </Grid>
            )}
          </Grid>
        );
      }}
      classes={{ root: classes.autoComplete }}
      renderInput={(params: any) => (
        <>
          <TextField
            color="inherit"
            {...params}
            label=""
            style={{
              borderRadius: 4,
              width: "280px",
            }}
            variant="outlined"
            id={categoryMenuList.id}
            name={categoryMenuList.name}
          />
        </>
      )}
    />
  );
};

export default withAuthority(CategoryMenuDropDown, Authorities.MENU_READ);
