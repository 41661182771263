import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Menu,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MenuIcon from "@material-ui/icons/Menu";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import { Skeleton } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import { CustomTheme } from "../../../../../types/customTheme";
import {
  updateStockOrder,
  stockOrderItemDelete,
  updateStockOrderItem,
} from "../../../../../services/inventory/stockOrder";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
} from "../../../../../utils/consts";
import TextfieldCommon from "../../../../../components/textField/TextfieldCommon";
import moment from "moment";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../../utils/enum";
import IncrementDecrementTextboxNewsDefault from "../../../../../components/common/IncrementDecrementTextbox/IncrementDecrementTextboxStartEndAdornment";
import DesktopNav from "./DesktopNav";
import StockOrderItemActiveGroupIdNonZero from "./StockOrderItemActiveGroupNonZero";
import StockOrderItemActiveGroupIdZero from "./StockOrderItemActiveGroupIdZero";
import DepartmentSelectMobileView from "./DepartmentSelectMobileView";
import {
  createWasteItemInfo,
  fetchStockItemInfoGetAll,
} from "../../../../../services/inventory/stockItems";
import IncrementDecrementTextboxEndAdornment from "../../../../../components/common/IncrementDecrementTextbox/IncrementDecrementTextboxEndAdornment";

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    "& .MuiTabs-root": {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100vw - 280px)",
      },
    },
  },
  roots: {
    display: "flex",
    alignItems: "start",
  },
  rowOdd: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_border}`,
    marginTop: "8px",
    [theme.breakpoints.down("md")]: {
      marginTop: "28px",
    },
  },
  rootCenterTabs: {
    "& .MuiTabs-root": {},
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
  departmentStyle: {
    display: "block",
  },
  topStyle: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "26px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  buttonStyle: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      marginTop: "12px",
    },
  },
}));

export interface paymentReportProps {
  isOpenStockItemWasteModal: any;
  setIsOpenStockItemWasteModal: any;
  setSuccess: any;
  setError: any;
  supplierId?: any;
  orderId?: any;
  selectedStockOrderDetails: any;
  setIsLoadingToast: any;
  setToastType: any;
  setToastMessage: any;
  isLoadingToast: any;
  stockLevelNode: any;
}

/**
 * This component is responsible for rendering a modal that allows users to manage
 * stock order items. It fetches data from API endpoints to display a list of items
 * available from suppliers and the corresponding order quantities. Users can adjust
 * the order quantities, remove items from the order, and submit the updated quantities.
 *
 * The component uses Material-UI components for styling and UI elements. It handles
 * various user interactions, updates, and API calls to manage the stock order items.
 */
const StockWastageItemModal: React.FunctionComponent<paymentReportProps> = ({
  isOpenStockItemWasteModal,
  setIsOpenStockItemWasteModal,
  setSuccess,
  setError,
  supplierId,
  orderId,
  selectedStockOrderDetails,
  setIsLoadingToast,
  setToastType,
  setToastMessage,
  isLoadingToast,
  stockLevelNode,
}) => {
  const [
    allStockOrderItemTransformedNode,
    setAllStockOrderItemTransformedNode,
  ] = useState<any>([]);
  const [
    allStockOrderItemTransformedNodeInitial,
    setAllStockOrderItemTransformedNodeInitial,
  ] = useState<any>([]);
  const [allStockOrderItemNodeInitial, setAllStockOrderItemNodeInitial] =
    useState<any>([]);
  const [allStockOrderItemResponseNode, setAllStockOrderItemResponseNode] =
    useState<any>([]);
  const [selectedStockOrderIdList, setSelectedStockOrderIdList] = useState<any>(
    [],
  );
  const [stockOrderList, setStockOrderList] = useState<any>([]);
  const [selectedStockItemObject, setSelectedStockItemObject] = useState<any>(
    {},
  );
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedStockOrderItemIdList, setSelectedStockOrderItemIdList] =
    useState<any>([]);
  const [selectedStockOrderItemObject, setSelectedStockOrderItemObject] =
    useState<any>({});
  const [
    selectedStockOrderItemObjectInitial,
    setSelectedStockOrderItemObjectInitial,
  ] = useState<any>({});
  const [initialOrderQty, setInitialOrderQty] = useState("1.0");
  const [isCompleteLoad, setIsCompleteLoad] = useState<any>({});
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [selectedStockOrderItemsNode, setSelectedStockOrderItemsNode] =
    useState<any>([]);
  const [activeGroupId, setActiveGroupId] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  const [selectedValue, setSelectedValue] = useState([
    "warningStockLevel",
    "criticalStockLevel",
    "adequateStockLevel",
  ]);
  const [isEdit, setIsEdit] = useState(false);
  const [isError, setIsError] = useState<any>([]);

  const match: any = useRouteMatch();
  const theme: CustomTheme = useTheme();
  const drawerRef: any = useRef(null);
  const open = Boolean(anchorEl);

  /**
   * This useEffect hook is responsible for filtering and updating a list of selected stock order items
   * based on the selectedStockOrderItemObject and allStockOrderItemTransformedNode data. It filters out items
   * that match specific criteria and updates the state with the filtered results.
   */
  useEffect(() => {
    // Get a list of selected item IDs from the selectedStockOrderItemObject.
    const selectedOrderItemIdIdList = Object.keys(selectedStockOrderItemObject);

    // Filter and update the selected stock order item nodes based on selected item IDs.
    const filterSelectedOrderItem = allStockOrderItemTransformedNode
      .map((entry: any) => {
        // Filter and update departments within each entry.
        const filteredDepartments = entry.stockDepartmentId
          .map((department: any) => {
            // Filter and update items within each department based on selected and checked IDs.
            const filteredItems = department.item.filter(
              (item: any) =>
                selectedOrderItemIdIdList.includes(item.id) &&
                selectedStockOrderItemIdList.includes(item.id),
            );
            // Return the updated department with filtered items.
            return { ...department, item: filteredItems };
          })
          // Remove departments with no filtered items.
          .filter((department: any) => department.item.length > 0);
        // Return the updated entry with filtered departments.
        return { ...entry, stockDepartmentId: filteredDepartments };
      })
      // Remove entries with no filtered departments.
      .filter((entry: any) => entry.stockDepartmentId.length > 0);

    // Update the state with the filtered and updated selected stock order item nodes.
    setSelectedStockOrderItemsNode(filterSelectedOrderItem);
  }, [selectedStockOrderItemObject, allStockOrderItemTransformedNode]);

  /**
   * Transforms the response data into a structured stock order item format.
   * @param {Array} response - The response data containing stock order items. format (item->department->group)
   * @returns {Array} - Transformed stock order item structure. format (group->department->item)
   */
  const handleStockOrderItemStructure = (response: any) => {
    // Initialize an empty array to store the transformed structure
    const transformedStructure = response.reduce(
      (accumulator: any, item: any) => {
        // Extract the stock group ID from the response item
        const stockGroupId = item.stockDepartmentId.stockGroupId.id;

        // Check if the stock group already exists in the accumulator
        const existingGroup = accumulator.find(
          (group: any) => group.id === stockGroupId,
        );

        if (existingGroup) {
          // Check if the department within the stock group exists
          const existingDepartment = existingGroup.stockDepartmentId.find(
            (dept: any) => dept.id === item.stockDepartmentId.id,
          );

          if (existingDepartment) {
            // If department exists, push the item details to its item array
            existingDepartment.item.push({
              id: item.id,
              name: item.name,
              barcode: item.barcode,
              active: item.active,
              created: item.created,
              criticalThreshold: item.criticalThreshold,
              displaySuffix: item.displaySuffix,
              inputMultiplier: item.inputMultiplier,
              inputSuffix: item.inputSuffix,
              primaryLocationId: item.primaryLocationId,
              sku: item.sku,
              updated: item.updated,
              version: item.version,
              warningThreshold: item.warningThreshold,
              autoUnitConvert: item.autoUnitConvert,
              showRemainderSep: item.showRemainderSep,
            });
          } else {
            // If department doesn't exist, create a new department and item
            existingGroup.stockDepartmentId.push({
              id: item.stockDepartmentId.id,
              name: item.stockDepartmentId.name,
              locationId: item.stockDepartmentId.locationId,
              created: item.stockDepartmentId.created,
              updated: item.stockDepartmentId.updated,
              version: item.stockDepartmentId.version,
              item: [
                {
                  id: item.id,
                  name: item.name,
                  barcode: item.barcode,
                  active: item.active,
                  created: item.created,
                  criticalThreshold: item.criticalThreshold,
                  displaySuffix: item.displaySuffix,
                  inputMultiplier: item.inputMultiplier,
                  inputSuffix: item.inputSuffix,
                  primaryLocationId: item.primaryLocationId,
                  sku: item.sku,
                  updated: item.updated,
                  version: item.version,
                  warningThreshold: item.warningThreshold,
                  autoUnitConvert: item.autoUnitConvert,
                  showRemainderSep: item.showRemainderSep,
                },
              ],
            });
          }
        } else {
          // If stock group doesn't exist, create a new stock group, department, and item
          accumulator.push({
            id: item.stockDepartmentId.stockGroupId.id,
            name: item.stockDepartmentId.stockGroupId.name,
            version: item.stockDepartmentId.stockGroupId.version,
            updated: item.stockDepartmentId.stockGroupId.updated,
            created: item.stockDepartmentId.stockGroupId.created,
            locationId: item.stockDepartmentId.stockGroupId.locationId,
            stockDepartmentId: [
              {
                id: item.stockDepartmentId.id,
                name: item.stockDepartmentId.name,
                locationId: item.stockDepartmentId.locationId,
                created: item.stockDepartmentId.created,
                updated: item.stockDepartmentId.updated,
                version: item.stockDepartmentId.version,
                item: [
                  {
                    id: item.id,
                    name: item.name,
                    barcode: item.barcode,
                    active: item.active,
                    created: item.created,
                    criticalThreshold: item.criticalThreshold,
                    displaySuffix: item.displaySuffix,
                    inputMultiplier: item.inputMultiplier,
                    inputSuffix: item.inputSuffix,
                    primaryLocationId: item.primaryLocationId,
                    sku: item.sku,
                    updated: item.updated,
                    version: item.version,
                    warningThreshold: item.warningThreshold,
                    autoUnitConvert: item.autoUnitConvert,
                    showRemainderSep: item.showRemainderSep,
                  },
                ],
              },
            ],
          });
        }

        return accumulator;
      },
      [],
    );

    // Return the transformed structure
    return transformedStructure;
  };

  /**
   * Updates the selected stock order items and their related quantities based on response data.
   */
  const updateSelectedStockOrderItems = () => {
    const selectedStockOrderItems: any = [];
    const selectedStockOrderItemQuantities: any = {};

    // Clone the 'selectedStockOrderItemObject' to modify safely
    const updatedOrderQtyObj = _.cloneDeep(selectedStockOrderItemObject);

    // Check if the response data and stock order list are not empty
    if (
      !_.isEmpty(allStockOrderItemResponseNode) &&
      !_.isEmpty(stockOrderList)
    ) {
      // Loop through each stock order item in the response
      allStockOrderItemResponseNode.forEach((stockOrderItem: any) => {
        // Find the corresponding stock order in 'stockOrderList'
        const matchingStockOrder = stockOrderList.find(
          (order: any) => order.stockItemId === stockOrderItem.id,
        );

        if (matchingStockOrder) {
          // Collect the IDs of selected stock order items
          selectedStockOrderItems.push(stockOrderItem.id);

          // Build the object to store stock order item IDs and their quantities
          selectedStockOrderItemQuantities[stockOrderItem.id] = {
            productId: matchingStockOrder.id,
            orderQty: matchingStockOrder.orderQty,
            name: stockOrderItem.name,
            barcode: stockOrderItem.barcode,
            sku: stockOrderItem.sku,
          };

          // Update the quantity in the 'updatedOrderQtyObj'
          updatedOrderQtyObj[stockOrderItem.id] = {
            productId: stockOrderItem.id,
            orderQty: matchingStockOrder.orderQty,
            name: stockOrderItem.name,
            barcode: stockOrderItem.barcode,
            sku: stockOrderItem.sku,
          };
        }
      });
    }

    // Update relevant state variables with the collected data
    setSelectedStockItemObject(selectedStockOrderItemQuantities);
    setSelectedStockOrderItemObject(updatedOrderQtyObj);
    if (_.isEmpty(selectedStockOrderItemObjectInitial)) {
      setSelectedStockOrderItemObjectInitial(updatedOrderQtyObj);
    }

    setSelectedStockOrderItemIdList(selectedStockOrderItems);
  };
  /**
   * Use the 'updateSelectedStockOrderItems' function in a useEffect
   */
  useEffect(() => {
    // Call the function to update selected stock order items based on data
    updateSelectedStockOrderItems();
  }, [selectedStockOrderIdList, allStockOrderItemResponseNode]);

  /**
   * This useEffect handles the active group ID based on initial data.
   */
  useEffect(() => {
    // Determine the active group ID based on the presence of selected stock order items
    setActiveGroupId(_.isEmpty(selectedStockOrderItemObjectInitial) ? 1 : 0);
  }, [allStockOrderItemNodeInitial, selectedStockOrderItemObjectInitial]);

  useEffect(() => {
    handleGetProductDepartmentGroup();
  }, []);

  /**
   * Handles the increment action for the output multiplier of a product.
   * @param {string} productId - The ID of the product to increment.
   */
  const handleIncrement = (productId: string) => {
    // Clone the outputMultiplierObj to avoid mutating the state directly
    const updatedOutputMultiplierObj = { ...selectedStockOrderItemObject };

    const outputMultiplierValue =
      updatedOutputMultiplierObj[productId].orderQty;
    // Check if the orderQty has a decimal part
    if (!_.isEmpty(outputMultiplierValue.toString().split(".")[1])) {
      // If it has a decimal part, round up to the nearest integer
      if (outputMultiplierValue.toString().split(".")[1] === "0") {
        // If it's an integer, increment it by 1 and convert to string
        updatedOutputMultiplierObj[productId].orderQty = (
          Number(outputMultiplierValue) + 1
        ).toString();
      } else {
        updatedOutputMultiplierObj[productId].orderQty = Math.ceil(
          outputMultiplierValue,
        );
      }
    } else {
      // If it's an integer, increment it by 1 and convert to string
      updatedOutputMultiplierObj[productId].orderQty = (
        Number(outputMultiplierValue) + 1
      ).toString();
    }

    // Update the state with the updated outputMultiplierObj
    setSelectedStockOrderItemObject(updatedOutputMultiplierObj);
    setIsEdit(true);
    if (isError) {
      const error = isError.filter((data: any) => data !== productId);
      setIsError(error);
    }
  };

  /**
   * Handles the decrement action for the output multiplier of a product.
   * @param {string} productId - The ID of the product to decrement.
   */
  const handleDecrement = (productId: string) => {
    // Clone the outputMultiplierObj to avoid mutating the state directly
    const updatedOutputMultiplierObj = { ...selectedStockOrderItemObject };

    // Retrieve the current output multiplier value for the specified product
    const outputMultiplierValue =
      updatedOutputMultiplierObj[productId].orderQty;

    // Check if the orderQty has a decimal part
    if (!_.isEmpty(outputMultiplierValue.toString().split(".")[1])) {
      // If it has a decimal part, and the value is greater than or equal to 1, decrement it
      if (Math.floor(outputMultiplierValue) - 1 > 0) {
        updatedOutputMultiplierObj[productId].orderQty =
          Math.floor(outputMultiplierValue) - 1;
      }
    } else {
      // If it's an integer and greater than or equal to 1, decrement it
      if (Number(outputMultiplierValue) - 1 > 0) {
        updatedOutputMultiplierObj[productId].orderQty = (
          Number(outputMultiplierValue) - 1
        ).toString();
      }
    }

    // Update the state with the updated outputMultiplierObj
    setSelectedStockOrderItemObject(updatedOutputMultiplierObj);
    setIsEdit(true);
    if (isError) {
      const error = isError.filter((data: any) => data !== productId);
      setIsError(error);
    }
  };

  /**
   * Handle the removal of a stock order item
   */
  const handleRemoveStockOrderItem = async (
    id: string,
    selectedStockOrderItemsNodeBeforeUpdate: any,
    selectedStockOrderItemIdListBeforeUpdate: any,
  ) => {
    setIsDisable(true);
    try {
      // Call the API to delete the stock order item
      await stockOrderItemDelete(
        match.params.locationId,
        selectedStockItemObject[id].id,
      );

      // Remove the deleted stock order item from state objects
      delete selectedStockOrderItemObject[id];
      delete selectedStockOrderItemObjectInitial[id];
      delete selectedStockOrderItemsNode[id];
      setIsDisable(false);

      setIsLoadingToast(false);
      setToastMessage(SUCCESSFULLY_DELETED);
      setToastType("success");
    } catch (error) {
      setSelectedStockOrderItemsNode(selectedStockOrderItemsNodeBeforeUpdate);
      setSelectedStockOrderItemIdList(selectedStockOrderItemIdListBeforeUpdate);
      setIsDisable(false);

      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Handles the change in checkbox state for a stock order item.
   *
   * This function is responsible for managing the state changes when a checkbox
   * associated with a stock order item is toggled. It updates the selected stock
   * order items list and their corresponding quantities based on the checkbox state.
   *
   * @param {Event} event - The checkbox change event.
   * @param {string} id - The ID of the stock order item.
   */
  const handleChangeCheckBox = (event: any, id: any, stockOrder: any) => {
    setIsCompleteLoad({});
    if (!selectedStockOrderItemIdList.includes(id)) {
      // Checkbox is checked, add the item to the selected list

      // Clone the list of selected stock order item IDs
      const updatedSelectedStockOrderItemIdList = [
        ...selectedStockOrderItemIdList,
      ];

      // Clone the selected stock order item object
      const updatedSelectedStockOrderItemObject = _.cloneDeep(
        selectedStockOrderItemObject,
      );
      // Check if the item already exists in the selected items
      if (!_.isEmpty(updatedSelectedStockOrderItemObject[id])) {
        // Item exists, update its order quantity
        updatedSelectedStockOrderItemObject[id] = {
          productId: id,
          orderQty: Number(updatedSelectedStockOrderItemObject[id]?.orderQty),
          sku: stockOrder.sku,
          barcode: stockOrder.barcode,
          name: stockOrder.name,
        };
      } else {
        // Item doesn't exist, add it with initial order quantity
        updatedSelectedStockOrderItemObject[id] = {
          productId: id,
          orderQty: Number(initialOrderQty),
          sku: stockOrder.sku,
          barcode: stockOrder.barcode,
          name: stockOrder.name,
        };
      }

      // Update the selected stock order item object and list
      setSelectedStockOrderItemObject(updatedSelectedStockOrderItemObject);
      updatedSelectedStockOrderItemIdList.push(id);
      setSelectedStockOrderItemIdList(updatedSelectedStockOrderItemIdList);
    } else {
      // Checkbox is unchecked, remove the item from the selected list

      // Clone the selected stock order item object
      const updatedSelectedStockOrderItemObject = _.cloneDeep(
        selectedStockOrderItemObject,
      );

      // Remove the item from the list of selected stock order item IDs
      const filteredStockOrderItemIdList = selectedStockOrderItemIdList.filter(
        (itemId: number) => itemId !== id,
      );

      // Clone the selected stock order items node
      const updatedSelectedStockOrderItemsNode = _.cloneDeep(
        selectedStockOrderItemsNode,
      );

      // Get the ID of the stock item associated with the unchecked stock order item
      const unselectedStockItemId =
        handleChangeSearchUsingId(id)[0]?.stockDepartmentId[0]?.item[0]?.id;

      // Modify the selected stock order items node to remove the unselected item
      const modifiedSelectedStockOrderItemsNode =
        updatedSelectedStockOrderItemsNode
          .map((entry: any) => {
            entry.stockDepartmentId = entry.stockDepartmentId.filter(
              (dept: any) => {
                dept.item = dept.item.filter(
                  (item: any) => item.id !== unselectedStockItemId,
                );
                return dept.item.length > 0;
              },
            );
            return entry;
          })
          .filter((entry: any) => entry.stockDepartmentId.length > 0);

      // Update the selected stock order items node and list
      setSelectedStockOrderItemsNode(modifiedSelectedStockOrderItemsNode);
      setSelectedStockOrderItemIdList(filteredStockOrderItemIdList);
      delete updatedSelectedStockOrderItemObject[id];
      setSelectedStockOrderItemObject(updatedSelectedStockOrderItemObject);
      // If the unchecked item had a non-zero order quantity, remove it from the backend
      if (selectedStockItemObject[id]) {
        setIsLoadingToast(true);
        setToastMessage("Loading...");
        setToastType("info");

        handleRemoveStockOrderItem(
          id,
          selectedStockOrderItemsNode,
          selectedStockOrderItemIdList,
        );
      }
    }
  };

  const handleOrderQtyEmpty = (id: any, value: any) => {
    const cloneSelectedStockOrderItemObject = _.cloneDeep(
      selectedStockOrderItemObject,
    );

    // Update the order quantity of the selected stock order item
    cloneSelectedStockOrderItemObject[id] = {
      productId: id,
      orderQty: value,
      sku: selectedStockOrderItemObject[id]?.sku,
      barcode: selectedStockOrderItemObject[id]?.barcode,
      name: selectedStockOrderItemObject[id]?.name,
    };

    const filteredStockOrderItemIdList = _.cloneDeep(
      selectedStockOrderItemIdList,
    );
    filteredStockOrderItemIdList.push(id);
    setSelectedStockOrderItemIdList(filteredStockOrderItemIdList);

    // Update the selected stock order item object with the modified value
    setSelectedStockOrderItemObject(cloneSelectedStockOrderItemObject);
  };

  const handleOrderQty = (
    productId: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      Number.isNaN(parseFloat(event.target.value)) ||
      parseFloat(event.target.value) >= 0
    ) {
      // Clone the outputMultiplierObj to avoid mutating the state directly
      const updatedOutputMultiplierObj = { ...selectedStockOrderItemObject };

      // Update the output multiplier for the specified product
      updatedOutputMultiplierObj[productId] = {
        productId: productId,
        orderQty: event.target.value,
        sku: selectedStockOrderItemObject[productId]?.sku,
        barcode: selectedStockOrderItemObject[productId]?.barcode,
        name: selectedStockOrderItemObject[productId]?.name,
      };

      // Update the state with the new outputMultiplierObj
      setSelectedStockOrderItemObject(updatedOutputMultiplierObj);
      setIsEdit(true);
      if (isError) {
        const error = isError.filter((data: any) => data !== productId);
        setIsError(error);
      }
    }
  };

  /**
   * Handle update stock order item
   *
   * @param {string} id - The ID associated with the submission.
   */
  const handleSubmit = async (id: any, isLoad: any) => {
    setIsDisable(true);
    // Set loading state before making the API call
    // setIsCompleteLoad({ id: id, label: "loading", data: "" });

    // Prepare data for the API call
    // const formData = {
    //   stockItemId: id,
    //   orderQty: Number(selectedStockOrderItemObject[id]["orderQty"]),
    // };
    try {
      // Make the API call to update stock order item
      const res = await updateStockOrderItem(
        match.params.locationId,
        orderId,
        id,
      );

      // Close the create stock order modal
      setIsOpenStockItemWasteModal(false);

      if (isLoad) {
        setIsLoadingToast(false);
        setToastMessage(SUCCESSFULLY_CREATED);
        setToastType("success");
      }

      // Set loading state to success
      // setIsCompleteLoad({ id: id, label: "success", data: "" });
      setIsDisable(false);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
      setIsDisable(false);
    }
  };

  /**
   * Determine if a button should be disabled based on the provided data and ID.
   *
   * @param {any} orderQty - The orderQty to check against.
   * @param {string} id - The ID associated with the data.
   * @returns {boolean} - Returns true if the button should be disabled, otherwise false.
   */
  const handleDisabled = (orderQty: any, id: string) => {
    const initialOrderQty =
      selectedStockOrderItemObjectInitial[id]?.orderQty.toString();

    // Check if the order quantity is zero or if it matches the initial order quantity
    return Number(orderQty) === 0 || initialOrderQty === orderQty?.toString();
  };

  /**
   * Handle the increment of the main order quantity.
   * This function calculates the updated order quantity based on the initial order quantity.
   */
  const handleIncrementMain = () => {
    // Clone the initial order quantity to avoid direct mutation
    const cloneInitialOrderQty = _.cloneDeep(initialOrderQty);
    // Calculate the updated order quantity
    let newOrderQty: any = "";

    if (cloneInitialOrderQty.toString().includes(".")) {
      if (cloneInitialOrderQty.toString().split(".")[1] === "0") {
        newOrderQty = (parseFloat(cloneInitialOrderQty) + 1).toString();
      } else {
        newOrderQty = Math.ceil(parseFloat(cloneInitialOrderQty));
      }
    } else {
      newOrderQty = (parseFloat(cloneInitialOrderQty) + 1).toString();
    }

    // Update the initial order quantity with the new value
    setInitialOrderQty(newOrderQty);
  };

  /**
   * Handle the decrement of the main order quantity.
   * This function calculates the updated order quantity based on the initial order quantity.
   */
  const handleDecrementMain = () => {
    // Clone the initial order quantity to avoid direct mutation
    const cloneInitialOrderQty = initialOrderQty;

    // Calculate the updated order quantity
    let newOrderQty: any;

    if (
      cloneInitialOrderQty.toString().includes(".") &&
      Math.floor(parseFloat(cloneInitialOrderQty)) - 1 >= 0
    ) {
      newOrderQty = Math.floor(parseFloat(cloneInitialOrderQty)) - 1;
    } else if (Number(cloneInitialOrderQty) - 1 >= 0) {
      newOrderQty = (Number(cloneInitialOrderQty) - 1).toString();
    }

    if (newOrderQty !== undefined) {
      // Update the initial order quantity with the new value
      setInitialOrderQty(newOrderQty);
    }
  };

  /**
   * Handle the closing of the create stock order modal.
   * This function sets the 'isOpenStockItemWasteModal' state to false.
   */
  const handleClose = () => {
    // Set the 'isOpenStockItemWasteModal' state to false
    setIsOpenStockItemWasteModal(false);
  };

  /**
   * Handle the submission of output multiplier data.
   * This function clones the output multiplier object, prepares the data, and sends it for creation.
   */
  const handleExitPage = async () => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");

    // Clone the output multiplier object to avoid mutating the original data
    const cloneOutputMultiplierObj = _.cloneDeep(selectedStockOrderItemObject);
    // Prepare the output data for submission

    const outputData: any = {
      // Filter and map the cloned output multiplier data to the required format
      waste: Object.values(cloneOutputMultiplierObj).map((item: any) => ({
        stockItemId: item.productId,
        qty: item.orderQty,
      })),
    };

    // Prepare the output data for submission
    const outputDatas: any = [];
    Object.values(cloneOutputMultiplierObj)
      .filter(
        (item: any) =>
          Number.isNaN(parseFloat(item.orderQty)) ||
          parseFloat(item.orderQty) <= 0,
      )
      .map((item: any) => outputDatas.push(item.productId));
    if (outputDatas.length > 0) {
      setError("Please correct all the errors.");
      setIsError(outputDatas);
      setIsLoadingToast(false);
      setToastMessage("Please correct all the errors.");
      setToastType("error");
    } else {
      setIsError([]);

      // Add the stock_item_id to the output data
      outputData["locationId"] = match.params.locationId;
      outputData["lastUpdateType"] = "waste";

      try {
        // Send the output data for creation
        await createWasteItemInfo(match.params.locationId, outputData);

        // Reset form and display success message
        setIsOpenStockItemWasteModal(false);
        setIsLoadingToast(false);
        setToastMessage(SUCCESSFULLY_CREATED);
        setToastType("success");
      } catch (error) {
        // If an error occurs during the API call, set the 'error' state with an unexpected error message
        // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
        setIsLoadingToast(false);
        setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
        setToastType("error");
      }
    }
  };

  /**
   * Handle the closing of the drawer.
   * This function sets the 'isOpenDrawer' state to false.
   */
  const handleCloseDrawer = () => {};

  /**
   * Handle the click on the overlay.
   * This function checks if the click occurred outside the Drawer and closes it.
   *
   * @param {any} event - The click event object.
   */
  const handleOverlayClick = (event: any) => {
    // Check if the click occurred outside the Drawer
    if (event.target.id === "drawer-overlay") {
      // If the click occurred on the overlay, close the Drawer
      handleCloseDrawer();
    }
  };

  /* Switch the tab to be displayed. */
  const handleChange = (val: any, catMenuIdx: any) => {
    setActiveGroupId(catMenuIdx);
  };

  /**
   * Filter selected stock order items using the provided ID.
   *
   * @param {any} id - The ID to filter by.
   * @returns {Array} - An array of filtered stock order items.
   */
  const handleChangeSearchUsingId = (id: any) => {
    // Check if the provided ID is not empty
    if (!_.isEmpty(id)) {
      // Filter the stock order items based on the provided ID
      const filteredOutput = allStockOrderItemTransformedNode
        .map((group: any) => ({
          ...group,
          stockDepartmentId: group.stockDepartmentId
            .map((dept: any) => ({
              ...dept,
              // Filter department items by matching ID
              item: dept.item.filter((item: any) => item.id.includes(id)),
            }))
            // Filter out departments without matching items
            .filter((dept: any) => dept.item.length > 0),
        }))
        // Filter out groups without matching departments
        .filter((group: any) => group.stockDepartmentId.length > 0);
      // Return the filtered stock order items
      return filteredOutput;
    }
  };

  /**
   * Function to fetch stock item information for a specific location, organize it into a structured format,
   * and set it in the component's state.
   */
  const handleGetProductDepartmentGroup = async () => {
    try {
      // Fetch stock item information for the specified location
      const res = await fetchStockItemInfoGetAll(match.params.locationId);

      if (res.data.data) {
        // Transform the stock item data into a structured format
        const transformedStructure = handleStockOrderItemStructure(
          res.data.data,
        );

        const allSelectedItem = [
          {
            id: "",
            name: "All Items",
            stockDepartmentId: [],
          },
        ];
        // Combine the "All Selected Items" object with the transformed data
        const combinedItems = [...allSelectedItem, ...transformedStructure];
        // Update state variables with the fetched and transformed data
        setAllStockOrderItemResponseNode(res.data.data);
        setAllStockOrderItemTransformedNode(combinedItems);
        setAllStockOrderItemTransformedNodeInitial(combinedItems);

        // Set the selected department ID based on the active group
        combinedItems.forEach((data: any, index: number) => {
          if (index === activeGroupId) {
            return setSelectedDepartmentId(data["stockDepartmentId"]["id"]);
          }
        });

        // Set the initial state of stock items data with the combined items
        setAllStockOrderItemNodeInitial(combinedItems);
      }
      // Handle errors if they occur during the API call
      setIsLoading(false);
      setIsDisable(false);
    } catch (err) {
      // Handle errors if they occur during the API call
      setIsLoading(false);
      setIsDisable(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Handle changing the search value using the search textbox.
   *
   * @param {object} e - The event object containing the input value.
   */
  const handleChangeSearchUsingSearchTextbox = (e: any) => {
    const inputValue = e.target.value;

    const filterItemsByName = (item: any) =>
      item.name
        .toString()
        .toLowerCase()
        .includes(inputValue.toString().toLowerCase());

    setIsLoading(true);
    // Update the search value state
    setSearchValue(inputValue);
    if (!_.isEmpty(inputValue)) {
      const ids: any = [];
      // Filter the stock order items based on the search value
      const filteredOutput = allStockOrderItemNodeInitial
        .map(({ stockDepartmentId, ...group }: any) => ({
          ...group, // Spread the group properties
          stockDepartmentId: stockDepartmentId
            .map(({ item, ...dept }: any) => ({
              ...dept, // Spread the department properties
              item: item.filter(filterItemsByName), // Filter items within the department
            }))
            .filter((dept: any) => {
              if (!ids.includes(dept.id) && dept.item.length > 0) {
                ids.push(dept.id);
              }
              return dept.item.length > 0;
            }), // Remove departments with no items
        }))
        .filter((group: any) => group.stockDepartmentId.length > 0); // Remove groups with no departments with items

      filteredOutput.unshift({
        id: "",
        name: "All Items",
        stockDepartmentId: [],
      });
      // Update the transformed stock order items state
      setAllStockOrderItemTransformedNode(filteredOutput);
    } else {
      // Reset the transformed stock order items to the initial state
      setAllStockOrderItemTransformedNode(allStockOrderItemNodeInitial);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const elementsByTitle: any = {}; // Map to store elements by title

  /**
   * Register an element reference with a corresponding title.
   *
   * @param {any} title - The title associated with the element.
   * @param {any} elementRef - The reference to the element to be registered.
   */
  const registerElementWithTitle = (title: any, elementRef: any) => {
    // Add the element reference to the 'elementsByTitle' object using the provided title as the key
    elementsByTitle[title] = elementRef;
  };

  /**
   * Handle the button click associated with a specific title.
   *
   * @param {string} title - The title associated with the button.
   */
  const handleButtonClick = (title: string) => {
    // Extract the department ID from the title and set it as the selected department ID
    const splitDepartmentId = title.split(" ");
    setSelectedDepartmentId(splitDepartmentId[1]);

    // Scroll to the target element when the button is clicked
    const targetElement = elementsByTitle[title];
    if (targetElement) {
      setTimeout(() => {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          top: -50,
        });
      }, 100); // Adjust the delay as needed
    }

    // Close the drawer after handling the button click
    handleCloseMenu();
  };

  /**
   * Handle clearing the search results.
   * This function resets the transformed stock order items to their initial state.
   */
  const handleClearSearch = () => {
    setIsLoading(true);
    // Update the search value state
    setSearchValue("");

    // Reset the transformed stock order items to the initial state
    setAllStockOrderItemTransformedNode(allStockOrderItemNodeInitial);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const handlePlaceOrder = () => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");

    // Set pdf headers
    const pdfHeaders = [
      { title: "ID", field: "barcode" },
      { title: "Item", field: "name" },
      { title: "SKU", field: "sku" },
      { title: "Order Qty", field: "orderQuantity" },
    ];
    const formData: any = [];
    const merge: any = [];
    let iSEdit = false;
    // Iterate through each key-value pair in the 'selectedStockOrderItemObject'
    Object.entries(selectedStockOrderItemObject).forEach(([key]: any) => {
      // Get the initial order quantity for the current key
      const initialOrderQty =
        selectedStockOrderItemObjectInitial[key]?.orderQty;

      // Get the current order quantity for the current key
      const currentOrderQty = selectedStockOrderItemObject[key]?.orderQty;

      // Check if the current order quantity has changed and is greater than 0
      if (currentOrderQty && currentOrderQty > 0) {
        merge.push({
          name: selectedStockOrderItemObject[key]?.name,
          barcode: selectedStockOrderItemObject[key]?.barcode,
          // locationName: locationData.businessDisplayName,
          // address: locationData.addressFormatted,
          // supplierName: selectedStockOrderDetails.supplierId.name,
          // email: selectedStockOrderDetails.supplierId.email,
          // contactNo: selectedStockOrderDetails.supplierId.contactNo,
          orderQuantity: selectedStockOrderItemObject[key]?.orderQty,
          sku: selectedStockOrderItemObject[key]?.sku,
        });
      }

      // Check if the current order quantity has changed and is greater than 0
      if (
        parseFloat(initialOrderQty) !== parseFloat(currentOrderQty) &&
        parseFloat(currentOrderQty) > 0
      ) {
        iSEdit = true;
        // If conditions are met, submit the form data for the current key
        formData.push({
          stockItemId: key,
          orderQty: Number(selectedStockOrderItemObject[key]["orderQty"]),
        });
      }
    });

    if (iSEdit) {
      handleSubmit(formData, false);
    }

    // handleDownloadPdf(
    //   "p",
    //   pdfHeaders,
    //   merge,
    //   locationData,
    //   selectedStockOrderDetails,
    // );
    if (!_.isEmpty(merge)) {
      handleUpdateOrder();
    }
  };

  /**
   * This function handles the creation of a new stock order. It constructs the necessary
   * form data with the selected supplier's ID and other relevant information, then makes
   * an API call to create the stock order. If the creation is successful, it triggers
   * additional actions like opening the modal for adding stock items, removing sorting
   * of stock items list, updating state variables, and displaying success/error messages.
   */
  const handleUpdateOrder = async () => {
    const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm:ss");
    try {
      // Construct the form data for creating a stock order
      const formData = {
        id: orderId,
        locationId: match.params.locationId,
        supplierId: supplierId,
        status: "ordered",
        orderedOn: currentDateFormatted,
        deliveredOn: null,
        version: selectedStockOrderDetails.version,
      };

      // Make an API call to create the stock order
      const res = await updateStockOrder(match.params.locationId, formData);

      // handleDownloadPdf(
      //   "p",
      //   pdfHeaders,
      //   merge,
      //   locationData,
      //   selectedStockOrderDetails,
      // );

      // Close the create stock order modal
      setIsOpenStockItemWasteModal(false);

      setIsLoadingToast(false);
      setToastMessage(SUCCESSFULLY_CREATED);
      setToastType("success");
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Get the status message for a given stock order.
   *
   * @param {any} stockOrder - The stock order object to get the status message for.
   * @returns {JSX.Element|string} - The status message JSX or string.
   */
  const getStatusMessage = (stockOrder: any) => {
    // Get the order quantity from the selectedStockOrderItemObject using the stockOrder's ID
    const orderQty = selectedStockOrderItemObject[stockOrder?.id]?.orderQty;

    // Determine if the status is loading, error, or none
    const isLoading =
      isCompleteLoad["label"] === "loading" &&
      stockOrder?.id === isCompleteLoad["id"];
    const isError =
      isCompleteLoad["label"] === "error" &&
      stockOrder?.id === isCompleteLoad["id"];

    // Check the conditions and return the appropriate status message
    if (handleDisabled(orderQty, stockOrder?.id) && orderQty > 0) {
      // Return a green check circle icon for a completed order
      return (
        <CheckCircleIcon style={{ color: theme.palette.custom.green.main }} />
      );
    } else if (isLoading) {
      // Return a blue circular progress indicator during loading
      return (
        <CircularProgress
          size={18}
          style={{ color: theme.palette.custom.blue.main }}
        />
      );
    } else if (isError) {
      // Return a red "Retry" Typography element if there's an error
      return (
        <Typography style={{ color: theme.palette.custom.red.main }}>
          Retry
        </Typography>
      );
    } else {
      // Default case: Return "Done" as a string when there's no specific status
      return "Done";
    }
  };

  /**
   * Generate a set of Skeleton components for displaying loading placeholders.
   *
   * @returns {JSX.Element} - The JSX containing a set of Skeleton components.
   */
  const handleSkeleton = () => {
    return (
      <Grid container style={{ marginTop: "170px" }}>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>{" "}
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
      </Grid>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleChangeSelector = (label: any, selected: any) => {
    // Filter and update the selected stock order item nodes based on selected item IDs.
    const cloneAllStockOrderItemTransformedNodeInitial = _.cloneDeep(
      allStockOrderItemTransformedNodeInitial,
    );
    const filterSelectedOrderItem = cloneAllStockOrderItemTransformedNodeInitial
      .map((entry: any) => {
        // Filter and update departments within each entry.
        const filteredDepartments = entry.stockDepartmentId
          .map((department: any) => {
            // Filter and update items within each department based on selected and checked IDs.
            const filteredItems = department.item.filter((item: any) => {
              const stockLevel = stockLevelNode[item.id]
                ? stockLevelNode[item.id]?.stockLevel
                : 0;

              let returned = false;

              if (selected.includes("criticalStockLevel")) {
                if (stockLevel < item.criticalThreshold) {
                  returned = true;
                }
              }

              if (selected.includes("warningStockLevel")) {
                if (
                  stockLevel >= item.criticalThreshold &&
                  stockLevel < item.warningThreshold
                ) {
                  returned = true;
                }
              }

              if (selected.includes("adequateStockLevel")) {
                if (
                  stockLevel >= item.criticalThreshold &&
                  stockLevel >= item.warningThreshold
                ) {
                  returned = true;
                }
              }

              if (returned) {
                return true;
              }
              return false;
            });
            // Return the updated department with filtered items.
            return { ...department, item: filteredItems };
          })
          // Remove departments with no filtered items.
          .filter((department: any) => department.item.length > 0);
        // Return the updated entry with filtered departments.
        return { ...entry, stockDepartmentId: filteredDepartments };
      })
      // Remove entries with no filtered departments.
      .filter((entry: any) => entry.stockDepartmentId.length > 0);

    const cloneSelectedStockOrderItemsNode = _.cloneDeep(
      selectedStockOrderItemsNode,
    );
    const filterSelectedOrderItemZero = cloneSelectedStockOrderItemsNode
      .map((entry: any) => {
        // Filter and update departments within each entry.
        const filteredDepartments = entry.stockDepartmentId
          .map((department: any) => {
            // Filter and update items within each department based on selected and checked IDs.
            const filteredItems = department.item.filter((item: any) => {
              const stockLevel = stockLevelNode[item.id]
                ? stockLevelNode[item.id]?.stockLevel
                : 0;

              let returned = false;

              if (selected.includes("criticalStockLevel")) {
                if (stockLevel < item.criticalThreshold) {
                  returned = true;
                }
              }

              if (selected.includes("warningStockLevel")) {
                if (
                  stockLevel >= item.criticalThreshold &&
                  stockLevel < item.warningThreshold
                ) {
                  returned = true;
                }
              }

              if (selected.includes("adequateStockLevel")) {
                if (
                  stockLevel >= item.criticalThreshold &&
                  stockLevel >= item.warningThreshold
                ) {
                  returned = true;
                }
              }

              if (returned) {
                return true;
              }
              return false;
            });
            // Return the updated department with filtered items.
            return { ...department, item: filteredItems };
          })
          // Remove departments with no filtered items.
          .filter((department: any) => department.item.length > 0);
        // Return the updated entry with filtered departments.
        return { ...entry, stockDepartmentId: filteredDepartments };
      })
      // Remove entries with no filtered departments.
      .filter((entry: any) => entry.stockDepartmentId.length > 0);

    filterSelectedOrderItem.unshift({
      id: "",
      name: "All Items",
      stockDepartmentId: [],
    });
    setAllStockOrderItemTransformedNode(filterSelectedOrderItem);
    setSelectedValue(selected);
    setSelectedStockOrderItemsNode(filterSelectedOrderItemZero);
    // selectedStockOrderItemsNode
  };

  const classes = useStyles();
  return (
    <>
      <DialogCommonDefault
        open={isOpenStockItemWasteModal}
        setOpen={setIsOpenStockItemWasteModal}
        isNeedFixedHeight={true}
        backgroundColor={theme.palette.background.entity_background}
        maxWidth={"xl"}
      >
        {/* <DialogTitle>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" style={{ width: "280px" }}>
                Add Stock Item
              </Typography>
            </div>
          </DialogTitle> */}
        <DialogContent>
          <div
            style={{
              position: "fixed",
              top: "32px",
              paddingTop: "20px",
              zIndex: 1000,
              right: "50px",
              left: "57px",
              backgroundColor: theme.palette.background.entity_background,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "12px",
              }}
            >
              <Typography variant="h6">Stock Wastage</Typography>
            </div>
            <div className={classes.topStyle}>
              <DesktopNav
                allStockOrderItemNodeInitial={allStockOrderItemNodeInitial}
                activeGroupId={activeGroupId}
                handleChange={handleChange}
                searchValue={searchValue}
                handleChangeSearchUsingSearchTextbox={
                  handleChangeSearchUsingSearchTextbox
                }
                handleClearSearch={handleClearSearch}
                isDisable={isDisable}
              />
              <div className={classes.buttonStyle}>
                <ButtonCommon
                  disabled={isDisable || isLoadingToast}
                  variant="contained"
                  style={{
                    fontSize: 11,
                    width: "120px",
                    marginRight: "20px",
                  }}
                  color={buttonColors.CANCEL_BUTTON_COLOR}
                  onClick={() => setIsOpenStockItemWasteModal(false)}
                >
                  Cancel
                </ButtonCommon>
                <ButtonCommon
                  disabled={
                    isDisable ||
                    isLoadingToast ||
                    _.isEmpty(selectedStockOrderItemIdList)
                  }
                  variant="contained"
                  style={{ fontSize: 11, width: "120px" }}
                  color={buttonColors.UPDATE_BUTTON_COLOR}
                  onClick={handleExitPage}
                >
                  Save
                </ButtonCommon>
              </div>
            </div>

            <Hidden smDown>
              <div
                style={{
                  margin: "12px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginRight: "28px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "12px",
                  }}
                >
                  <IconButton
                    id="basic-button"
                    style={{
                      fontSize: 11,
                      marginRight: "12px",
                      marginTop: "6px",
                      backgroundColor:
                        theme.palette.background.entity_highlight_background,
                    }}
                    onClick={handleClick}
                  >
                    {open ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      elevation: 0,
                      style: {
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.background.entity_border}`,
                        marginTop: "10px",
                        marginLeft: "40px",
                        top: "100px",
                        left: "70x",
                        bottom: "34px",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {!_.isEmpty(allStockOrderItemTransformedNode) && (
                      <DepartmentSelectMobileView
                        handleButtonClick={handleButtonClick}
                        activeGroupId={activeGroupId}
                        selectedStockOrderItemsNode={
                          activeGroupId === 0
                            ? selectedStockOrderItemsNode
                            : allStockOrderItemTransformedNode
                        }
                        selectedDepartmentId={selectedDepartmentId}
                        isDisable={isDisable}
                        handleClose={handleCloseMenu}
                        searchValue={searchValue}
                      />
                    )}
                  </Menu>
                  <TextfieldCommon
                    id="search"
                    name="search"
                    label="Search"
                    type="text"
                    style={{ width: "240px" }}
                    value={searchValue}
                    disabled={isLoadingToast}
                    onChange={handleChangeSearchUsingSearchTextbox}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchValue ? (
                            <CloseIcon onClick={handleClearSearch} />
                          ) : (
                            <SearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div>
                  <IncrementDecrementTextboxEndAdornment
                    handleDecrement={handleDecrementMain}
                    handleIncrement={handleIncrementMain}
                    initialOrderQty={initialOrderQty}
                    setInitialOrderQty={setInitialOrderQty}
                    displaySuffix={""}
                    isWaste={true}
                  />
                </div>
              </div>
            </Hidden>

            <Hidden mdUp xsDown>
              <>
                <div
                  style={{
                    margin: "12px 0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "28px",
                  }}
                >
                  <IconButton
                    id="basic-button"
                    style={{
                      fontSize: 11,
                      marginRight: "12px",
                      marginTop: "6px",
                      backgroundColor:
                        theme.palette.background.entity_highlight_background,
                    }}
                    onClick={handleClick}
                  >
                    {open ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      elevation: 0,
                      style: {
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.background.entity_border}`,
                        marginTop: "10px",
                        marginLeft: "40px",
                        top: "100px",
                        left: "70x",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {!_.isEmpty(allStockOrderItemTransformedNode) && (
                      <DepartmentSelectMobileView
                        handleButtonClick={handleButtonClick}
                        activeGroupId={activeGroupId}
                        // selectedStockOrderItemsNode={
                        //   activeGroupId === 0
                        //     ? selectedStockOrderItemsNode
                        //     : allStockOrderItemTransformedNode
                        // }
                        selectedStockOrderItemsNode={
                          allStockOrderItemTransformedNode
                        }
                        selectedDepartmentId={selectedDepartmentId}
                        isDisable={isDisable}
                        handleClose={handleCloseMenu}
                        searchValue={searchValue}
                      />
                    )}
                  </Menu>
                  <TextfieldCommon
                    id="search"
                    name="search"
                    label="Search"
                    type="text"
                    style={{ width: "240px" }}
                    value={searchValue}
                    disabled={isLoadingToast}
                    onChange={handleChangeSearchUsingSearchTextbox}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchValue ? (
                            <CloseIcon onClick={handleClearSearch} />
                          ) : (
                            <SearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div
                  style={{
                    margin: "12px 0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "28px",
                  }}
                >
                  <IncrementDecrementTextboxNewsDefault
                    handleDecrement={handleDecrementMain}
                    handleIncrement={handleIncrementMain}
                    initialOrderQty={initialOrderQty}
                    setInitialOrderQty={setInitialOrderQty}
                    displaySuffix={""}
                    isWaste={true}
                  />
                </div>
              </>
            </Hidden>

            <Hidden smUp>
              <>
                <div
                  style={{
                    margin: "12px 0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "28px",
                  }}
                >
                  <IconButton
                    id="basic-button"
                    style={{
                      fontSize: 11,
                      marginRight: "12px",
                      marginTop: "6px",
                      backgroundColor:
                        theme.palette.background.entity_highlight_background,
                    }}
                    onClick={handleClick}
                  >
                    {open ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      elevation: 0,
                      style: {
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.background.entity_border}`,
                        marginTop: "10px",
                        marginLeft: "40px",
                        top: "100px",
                        left: "70x",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {!_.isEmpty(allStockOrderItemTransformedNode) && (
                      <DepartmentSelectMobileView
                        handleButtonClick={handleButtonClick}
                        activeGroupId={activeGroupId}
                        selectedStockOrderItemsNode={
                          activeGroupId === 0
                            ? selectedStockOrderItemsNode
                            : allStockOrderItemTransformedNode
                        }
                        selectedDepartmentId={selectedDepartmentId}
                        isDisable={isDisable}
                        handleClose={handleCloseMenu}
                        searchValue={searchValue}
                      />
                    )}
                  </Menu>
                  <TextfieldCommon
                    id="search"
                    name="search"
                    label="Search"
                    type="text"
                    style={{ width: "240px" }}
                    value={searchValue}
                    disabled={isLoadingToast}
                    onChange={handleChangeSearchUsingSearchTextbox}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchValue ? (
                            <CloseIcon onClick={handleClearSearch} />
                          ) : (
                            <SearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div
                  style={{
                    margin: "12px 0px",
                    marginRight: "24px",
                    display: "block",
                  }}
                >
                  <IncrementDecrementTextboxNewsDefault
                    handleDecrement={handleDecrementMain}
                    handleIncrement={handleIncrementMain}
                    initialOrderQty={initialOrderQty}
                    setInitialOrderQty={setInitialOrderQty}
                    displaySuffix={""}
                    isWaste={true}
                  />
                </div>
              </>
            </Hidden>
          </div>
          {!isLoading ? (
            <div>
              <div className={classes.roots}>
                <Grid container>
                  {/* Department selection for desktop */}
                  <Grid item xs={12}>
                    {!_.isEmpty(allStockOrderItemTransformedNode) ? (
                      activeGroupId === 0 || searchValue ? (
                        allStockOrderItemTransformedNode.map(
                          (group: any, index: any) => (
                            <StockOrderItemActiveGroupIdZero
                              item={group}
                              registerElementWithTitle={
                                registerElementWithTitle
                              }
                              selectedStockOrderItemIdList={
                                selectedStockOrderItemIdList
                              }
                              handleDecrement={handleDecrement}
                              selectedStockOrderItemObject={
                                selectedStockOrderItemObject
                              }
                              handleOrderQty={handleOrderQty}
                              handleIncrement={handleIncrement}
                              handleSubmit={handleSubmit}
                              handleChangeCheckBox={handleChangeCheckBox}
                              handleDisabled={handleDisabled}
                              getStatusMessage={getStatusMessage}
                              isDisable={isDisable}
                              handleOrderQtyEmpty={handleOrderQtyEmpty}
                              initialOrderQty={initialOrderQty}
                              stockLevelNode={stockLevelNode}
                              isError={isError}
                              groupIndex={index}
                            />
                          ),
                        )
                      ) : (
                        allStockOrderItemTransformedNode.map(
                          (group: any, index: any) => {
                            return (
                              <div>
                                {index === activeGroupId && (
                                  <StockOrderItemActiveGroupIdNonZero
                                    group={group}
                                    registerElementWithTitle={
                                      registerElementWithTitle
                                    }
                                    selectedStockOrderItemIdList={
                                      selectedStockOrderItemIdList
                                    }
                                    handleDecrement={handleDecrement}
                                    selectedStockOrderItemObject={
                                      selectedStockOrderItemObject
                                    }
                                    handleOrderQty={handleOrderQty}
                                    handleIncrement={handleIncrement}
                                    handleSubmit={handleSubmit}
                                    handleChangeCheckBox={handleChangeCheckBox}
                                    handleDisabled={handleDisabled}
                                    getStatusMessage={getStatusMessage}
                                    isDisable={isDisable}
                                    handleOrderQtyEmpty={handleOrderQtyEmpty}
                                    initialOrderQty={initialOrderQty}
                                    stockLevelNode={stockLevelNode}
                                    isError={isError}
                                  />
                                )}
                              </div>
                            );
                          },
                        )
                      )
                    ) : (
                      // Render for no stock items available
                      <div
                        style={{
                          marginTop: "30px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h4">
                          No Stock Item Available.
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            <div style={{ margin: "4px 12px 4px 4px" }}>{handleSkeleton()}</div>
          )}
        </DialogContent>
      </DialogCommonDefault>
    </>
  );
};

export default StockWastageItemModal;
