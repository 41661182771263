import * as React from "react";
import googleIcon from "../../../../../assets/images/google.svg";
import SocialSignInButton from "../../../../../components/buttons/SocialSignInButton";
import { verifyAuthTokenWithServer } from "../../../../../services/accountApp/authService";
import firebase from "../../../../../utils/firebase";

export interface GoogleSignInProps {}

/* Google sign in page */
const GoogleSignIn: React.FunctionComponent<GoogleSignInProps> = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClick = () => {
    setIsLoading(true);
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result: any) => {
        if (result && result.user) {
          firebase.auth().onAuthStateChanged((firebaseUser) => {
            if (firebaseUser) {
              firebaseUser.getIdToken(true).then(function (idToken) {
                // Get the login details
                verifyAuthTokenWithServer(
                  idToken,
                  firebaseUser,
                  firebaseUser.refreshToken,
                );
                setTimeout(() => {
                  setIsLoading(false);
                  window.location.href = "/accounts/apps";
                }, 2000);
              });
            }
          });
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const { email } = error;
        // The firebase.auth.AuthCredential type that was used.
        const { credential } = error;
        setIsLoading(false);
      });
  };
  return (
    <SocialSignInButton
      handleClick={handleClick}
      logoSrc={googleIcon}
      labelText="Sign in with Google"
      alt="google sign in"
      isLoading={isLoading}
    />
  );
};

export default GoogleSignIn;
