import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      height: 12,
      width: "75%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      height: 4,
      width: "80%",
      color: "white",
      borderRadius: 4,
    },
  }),
);

export interface ShapeButtonProps {
  length?: number;
  color?: string;
  height?: number;
  textColor?: string;
}

/* Changing the basket 'button color' preview UI. */
const ShapeButton: React.FunctionComponent<ShapeButtonProps> = ({
  length,
  height,
  color,
  textColor,
}) => {
  const classes = useStyles();

  let width = "50%";
  let buttonHeight = "48px";

  // Changing the preview width
  switch (length) {
    case 1:
      width = "25%";
      break;
    case 2:
      width = "50%";
      break;
    case 3:
      width = "75%";
      break;
    case 4:
      width = "100%";
      break;
    default:
      break;
  }

  // Changing the preview height
  switch (height) {
    case 1:
      buttonHeight = "24px";
      break;
    case 2:
      buttonHeight = "48px";
      break;
    default:
      break;
  }

  return (
    <div
      className={classes.root}
      style={{
        width: width,
        backgroundColor: color || "#000",
        height: buttonHeight,
      }}
    >
      <div
        className={classes.text}
        style={{ backgroundColor: textColor || "#fff" }}
      ></div>
    </div>
  );
};

export default ShapeButton;
