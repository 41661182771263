import {
  Button,
  makeStyles,
  createStyles,
  Typography,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { CustomTheme } from "../../../types/customTheme";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import DatePicker from "../datePicker/DatePicker";
import _ from "lodash";
import CardCommon from "../../card/CardCommon";
import MobileDatePicker from "../mobileDatePicker/MobileDatePicker";

export interface DateRangePickerProps {
  open: any;
  setOpen: any;
  startDate: any;
  endDate: any;
  handleChangeDateRange: any;
  dateSelectionRange: any;
  horizontalMaxWidth: any;
}

const useStyles = makeStyles<any>((theme: CustomTheme) =>
  createStyles({
    datePickerStyle: {
      zIndex: 6,
      marginTop: "6px",
    },
    startDateStyle: {
      [theme.breakpoints.down("xl")]: {
        paddingTop: "8px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        fontSize: "14px",
      },
    },
    arrowIconStyle: {
      [theme.breakpoints.down("xl")]: {
        paddingTop: "9px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
      },
    },
    endDateStyle: {
      [theme.breakpoints.down("xl")]: {
        paddingTop: "8px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        fontSize: "14px",
      },
    },
    arrowIconMarginStyle: {
      [theme.breakpoints.down("xl")]: {
        marginLeft: "8px",
        marginRight: "8px",
        fontSize: "24px",
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: "4px",
        marginRight: "4px",
        fontSize: "18px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        marginLeft: "2px",
        marginRight: "2px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "8px",
        marginRight: "8px",
        fontSize: "20px",
      },
    },
    dateRangeIcon: {
      [theme.breakpoints.down("xl")]: {
        marginTop: "6px",
        fontSize: "24px",
      },
      [theme.breakpoints.down("lg")]: {
        marginTop: "8px",
        fontSize: "18px",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: "6px",
        fontSize: "18px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "8px",
        fontSize: "24px",
      },
    },
    closeIcon: {
      [theme.breakpoints.down("xl")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    buttonStyle: {
      width: "100%",
      justifyContent: "space-between",
      display: "flex",
      height: "60px",
      marginTop: "6px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.entity_highlight_background,
      "&:hover": {
        backgroundColor: theme.palette.background.entity_highlight_background,
      },
      [theme.breakpoints.down("xl")]: {
        padding: "6px 12px",
      },
      [theme.breakpoints.down("lg")]: {
        padding: "6px 6px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "6px 6px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "6px 12px",
      },
    },
  }),
);

/**
 * This component represents a Date Range Picker component that allows users to select date ranges.
 * It includes a button to trigger the date picker, and the date picker itself appears as a modal
 * or a card depending on the screen size.
 */
const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = ({
  open,
  setOpen,
  startDate,
  endDate,
  handleChangeDateRange,
  dateSelectionRange,
  horizontalMaxWidth,
}) => {
  const maxWidth = useMediaQuery("(max-width: 618px)");
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.buttonStyle}
        size={"large"}
        variant="outlined"
        onClick={() => !open && setOpen(true)}
      >
        <div>
          {!_.isEmpty(startDate) && !_.isEmpty(endDate) && (
            <div style={{ display: "flex" }}>
              <Typography className={classes.startDateStyle}>
                {startDate}
              </Typography>
              <Typography className={classes.arrowIconStyle}>
                <ArrowRightAltIcon className={classes.arrowIconMarginStyle} />
              </Typography>

              <Typography className={classes.endDateStyle}>
                {endDate}
              </Typography>
            </div>
          )}
        </div>
        <span>
          {open ? (
            <Typography style={{ fontWeight: "bold" }}>
              <CloseIcon
                className={classes.closeIcon}
                style={{ marginTop: "8px" }}
                onClick={() => setOpen(false)}
              />
            </Typography>
          ) : (
            <Typography>
              <DateRangeIcon className={classes.dateRangeIcon} />
            </Typography>
          )}
        </span>
      </Button>
      <div className={classes.datePickerStyle}>
        {open &&
          (!maxWidth ? (
            <Box
              // boxShadow={3}
              borderRadius={30}
              style={{
                borderRadius: 30,
                // border: `1px solid ${theme.palette.background.entity_border}`,
              }}
            >
              <DatePicker
                handleChangeDateRange={handleChangeDateRange}
                dateSelectionRange={dateSelectionRange}
                horizontalMaxWidth={horizontalMaxWidth}
                setOpen={setOpen}
              />{" "}
            </Box>
          ) : (
            <CardCommon backgroundColor={"entity_highlight_background"}>
              <MobileDatePicker
                handleChangeDateRange={handleChangeDateRange}
                dateSelectionRange={dateSelectionRange}
                setOpen={setOpen}
              />
            </CardCommon>
          ))}
      </div>
    </>
  );
};

export default DateRangePicker;
