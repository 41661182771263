import React, { useContext, useEffect } from "react";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import {
  createStyles,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { getLocalStore, setLocalStore } from "../../utils/store/localStore";
import { GlobalContext } from "../../root/App";
import { CustomTheme } from "../../types/customTheme";

export interface DarkLightModeSwitchProps {}

const useStyles = makeStyles(() =>
  createStyles({
    iconStyle: {
      "&:hover": {
        background: "none",
      },
    },
  }),
);

const DarkLightModeSwitch: React.FunctionComponent<
  DarkLightModeSwitchProps
> = () => {
  const { globalState, setGlobalState } = useContext(GlobalContext);

  /* Update the local storage after change the dark/ light mode switch. */
  const handleThemeSwitch = () => {
    setGlobalState({ isDarkMode: !globalState.isDarkMode });
    setLocalStore("isDarkMode", !globalState.isDarkMode);
  };

  useEffect(() => {
    // Get the dark mode value using local storage.
    const isDarkMode = getLocalStore("isDarkMode");

    if (isDarkMode === null) {
      setGlobalState({ isDarkMode: false });
      setLocalStore("isDarkMode", false);
    } else if (isDarkMode === "true") {
      setGlobalState({ isDarkMode: true });
    } else {
      setGlobalState({ isDarkMode: false });
    }
  }, [setGlobalState]);

  const theme: CustomTheme = useTheme();
  const matchesLg = useMediaQuery("(max-width: 960px)");
  const classes = useStyles();
  return (
    <>
      {matchesLg ? (
        <IconButton
          className={classes.iconStyle}
          style={{
            color: theme.palette.custom.orange.contrastText,
            width: "220px",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            paddingLeft: "28px",
          }}
          onClick={handleThemeSwitch}
        >
          {globalState.isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          <span style={{ margin: 4 }}></span>
          <Typography>
            {globalState.isDarkMode ? "Light Mode" : "Dark Mode"}
          </Typography>
        </IconButton>
      ) : (
        <IconButton
          style={{
            color: theme.palette.custom.orange.contrastText,
          }}
          onClick={handleThemeSwitch}
        >
          {globalState.isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      )}
    </>
  );
};

export default DarkLightModeSwitch;
