import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import DaySelect from "./DaySelect";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import {
  constructMinuteOfWeekArray,
  getStartMinuteOfWeekByDay,
} from "../../../utils/time-conversions";
import _ from "lodash";
import CardCommon from "../../card/CardCommon";
import ButtonCommon from "../../buttons/ButtonCommon";
import { CustomTheme } from "../../../types/customTheme";

export interface AvailableTimeRowProps {
  availabilitySelectionList: any;
  availSelection: any;
  setAvailabilitySelectionList: Function;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    borderRadius: 10,
    border: `1px solid ${theme.palette.background.entity_border}`,
    padding: 16,
    margin: 4,
  },
  deleteButtonStyle: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "end",
    },
  },
  firstDatePickerStyle: {
    marginLeft: "8px",
    paddingRight: "8px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0px",
    },
    "& .MuiPickersClockPointer-pointer": {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    "& .MuiPickersClockNumber-clockNumberSelected": {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    [`& fieldset`]: {
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
  },
  secondDatePickerStyle: {
    paddingLeft: "8px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
    "& .MuiPickersClockPointer-pointer": {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    "& .MuiPickersClockNumber-clockNumberSelected": {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    [`& fieldset`]: {
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
  },
  datePickerStyle: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

/* This component show the availability time selection modal.
   It shows the data coming from the backend and processes the updated and inserted data to be sent to the backend. */
const AvailableTimeRow: React.FunctionComponent<AvailableTimeRowProps> = ({
  availabilitySelectionList,
  availSelection,
  setAvailabilitySelectionList,
}) => {
  const [openTime, setOpenTime] = useState<moment.Moment>(moment());
  const [closeTime, setCloseTime] = useState<moment.Moment>(moment());
  const [selectedDays, setSelectedDays] = useState([]);

  const classes = useStyles();

  /* The state of openTime, closeTime, and selectedDays is changed 
  when the initial loading and availSelection changes. */
  useEffect(() => {
    if (!_.isEmpty(availSelection) && !_.isEmpty(availSelection.nodeList)) {
      const selectedDays = availSelection.nodeList.map((node: any) =>
        getStartMinuteOfWeekByDay(node.day),
      );
      setSelectedDays(selectedDays);
      setOpenTime(moment(availSelection.openTime, "HH:mm"));
      setCloseTime(moment(availSelection.closeTime, "HH:mm"));
    }
  }, [availSelection]);

  /* When the minuteOfWeekArray, openTime, CloseTime are entered, 
  add the openTime, CloseTime to a list and insert the 
  {closeTime: "", day: "", durationMins: minuteOfWeek: openTime: ""} into a nodeList in the same list. */
  const updateOpeningTimeSelectionList = (
    selectedDays: any,
    openTime: any,
    closeTime: any,
  ) => {
    let mowArray = constructMinuteOfWeekArray(
      selectedDays,
      openTime,
      closeTime,
    );
    mowArray = mowArray.filter((node: any) => node.day);
    let updatedOpeningTimesSelectionList = _.cloneDeep(
      availabilitySelectionList,
    );

    /* Get the index of changed availability. */
    const nodeIdx = updatedOpeningTimesSelectionList.findIndex(
      (node: any) => node.id === availSelection.id,
    );

    // Updating 'availability details' to send to the backend.
    updatedOpeningTimesSelectionList[nodeIdx].nodeList = mowArray;
    updatedOpeningTimesSelectionList[nodeIdx].openTime =
      openTime.format("HH:mm");
    updatedOpeningTimesSelectionList[nodeIdx].closeTime =
      closeTime.format("HH:mm");
    setAvailabilitySelectionList(updatedOpeningTimesSelectionList);
  };

  // Update the open time after select time.
  const handleOpenTime = async (time: Date | null) => {
    await setOpenTime(moment(time));
    updateOpeningTimeSelectionList(selectedDays, moment(time), closeTime);
  };

  // Update the close time after select time.
  const handleCloseTime = async (time: Date | null) => {
    await setCloseTime(moment(time));
    updateOpeningTimeSelectionList(selectedDays, openTime, moment(time));
  };

  // Update the availability date after select date.
  const handleSelectDays = async (e: any, selections: any) => {
    await setSelectedDays(selections);
    updateOpeningTimeSelectionList(selections, openTime, closeTime);
  };

  // Remove the availability
  const handleDelete = () => {
    const filtered = _.cloneDeep(availabilitySelectionList).filter(
      (node: any) => node.id !== availSelection.id,
    );
    setAvailabilitySelectionList(filtered);
  };

  const theme: CustomTheme = useTheme();

  return (
    <CardCommon backgroundColor={"entity_background"}>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={5}
            lg={4}
            style={{ display: "flex", alignItems: "center", marginTop: "8px" }}
          >
            <DaySelect
              handleSelectDays={handleSelectDays}
              selectedDays={selectedDays}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={7}
            className={classes.datePickerStyle}
          >
            <Grid container>
              <Grid item xs={12} sm={6}>
                <KeyboardTimePicker
                  margin="normal"
                  ampm={false}
                  id="time-picker"
                  label="From time"
                  value={openTime}
                  onChange={handleOpenTime}
                  inputVariant={"outlined"}
                  className={classes.firstDatePickerStyle}
                  DialogProps={{ className: classes.firstDatePickerStyle }}
                  okLabel={<Typography color="secondary">Ok</Typography>}
                  cancelLabel={
                    <Typography color="secondary">Cancel</Typography>
                  }
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 10,
                      border: `1px solid ${theme.palette.background.entity_border}`,
                      height: 56,
                      backgroundColor:
                        theme.palette.background.entity_highlight_background,
                    },
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <KeyboardTimePicker
                  margin="normal"
                  ampm={false}
                  id="time-picker"
                  label="To time"
                  value={closeTime}
                  onChange={handleCloseTime}
                  inputVariant={"outlined"}
                  className={classes.secondDatePickerStyle}
                  DialogProps={{ className: classes.secondDatePickerStyle }}
                  fullWidth
                  okLabel={<Typography color="secondary">Ok</Typography>}
                  cancelLabel={
                    <Typography color="secondary">Cancel</Typography>
                  }
                  InputProps={{
                    style: {
                      borderRadius: 10,
                      border: `1px solid ${theme.palette.background.entity_border}`,
                      height: 56,
                      backgroundColor:
                        theme.palette.background.entity_highlight_background,
                    },
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={1}
            className={classes.deleteButtonStyle}
          >
            <ButtonCommon
              style={{ height: "40px", marginTop: "2px" }}
              color="red"
              onClick={handleDelete}
            >
              <DeleteIcon />
            </ButtonCommon>
          </Grid>
        </Grid>
      </div>
    </CardCommon>
  );
};

export default AvailableTimeRow;
