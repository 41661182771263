import { Box, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import {
  fetchCategoryMenuList,
} from "../../../../services/menuApp/categoryMenuService";
import { CustomTheme } from "../../../../types/customTheme";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { getCookie } from "../../../../utils/cookies";
import Loading from "./Loading";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";

export interface MenuProps {}

/* Get all menu information using API call at initial load. */
const Menu: React.FunctionComponent<MenuProps> = () => {
  const [menuNodeList, setMenuNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /* Get all menu information using API call and response data set to menuNodeList state. */
  const getMenuInfoList = async (headerName: string, sortingMethod: string) => {
    try {
      const res = await fetchCategoryMenuList(
        match.params.locationId,
        idToken,
        headerName,
        sortingMethod,
      );
      setMenuNodeList(res.data.data);
      setIsLoading(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Menu - Menu Information";
    setIsLoading(true);
    getMenuInfoList("menuMapChannel", "ASC");
  }, []);

  const handleChangeOrderListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    getMenuInfoList(headerName, sortingMethod);
  };

  const handleRemoveOrderListSorting = () => {
    getMenuInfoList("menuMapChannel", "ASC");
  };

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box>
        <Loading
          getMenuInfoList={getMenuInfoList}
          menuNodeList={menuNodeList}
          handleRemoveOrderListSorting={handleRemoveOrderListSorting}
          handleChangeOrderListSorting={handleChangeOrderListSorting}
          isLoading={isLoading}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(Menu, Authorities.MENU_READ);
