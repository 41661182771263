import React, { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import ValidationMessage from "../../../../../components/validation/ValidationMessage";
import { Autocomplete } from "@material-ui/lab";
import { CustomTheme } from "../../../../../types/customTheme";
import { useRouteMatch } from "react-router-dom";
import { createProductWrapper } from "../../../../../services/menuApp/menuService";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../../utils/consts";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    labelStyle: {
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
  }),
);

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  setProductName: any;
  productName: any;
  isCategoryNameEmpty: any;
  setIsCategoryNameEmpty: any;
  handleCloseProductAddModal: any;
  isLoadingButton: any;
  errors: any;
  register: any;
  handleOpenDepartmentAddModal: any;
  stockDepartmentSelectedNode: any;
  selectedDepartmentObj: any;
  setSelectedDepartmentObj: any;
  setDepartmentName: any;
  isDepartmentEmpty: any;
  setIsDepartmentEmpty: any;
  reset: any;
  wrapperId: any;
  handleSubmit: any;
  setIsLoadingButton: any;
  categoryId: any;
  directoryId: any;
  directoryLevel: any;
  setCategoryId: any;
  menuNodeList: any;
  selectedMenuId: any;
  firstWrapperId: any;
  setSuccess: any;
  setMenuNodeList: any;
  setError: any;
  isRoot: any;
  menuId: any;
  setIsOpenDirectoryOrProductSelectionModal: any;
}

const ProductAddModal: React.FunctionComponent<StrategyAddEditModalProps> = ({
  isOpen,
  setIsOpen,
  setProductName,
  productName,
  isCategoryNameEmpty,
  setIsCategoryNameEmpty,
  handleCloseProductAddModal,
  isLoadingButton,
  errors,
  register,
  handleOpenDepartmentAddModal,
  stockDepartmentSelectedNode,
  selectedDepartmentObj,
  setSelectedDepartmentObj,
  setDepartmentName,
  isDepartmentEmpty,
  setIsDepartmentEmpty,
  reset,
  wrapperId,
  handleSubmit,
  setIsLoadingButton,
  categoryId,
  directoryId,
  directoryLevel,
  setCategoryId,
  menuNodeList,
  selectedMenuId,
  firstWrapperId,
  setSuccess,
  setMenuNodeList,
  setError,
  isRoot,
  menuId,
  setIsOpenDirectoryOrProductSelectionModal,
}) => {
  const handleDisable = () => {};
  const match: any = useRouteMatch();

  useEffect(() => {
    if (isOpen) {
      reset({ name: "" });
    }
  }, [isOpen]);

  /**
   * This function handles the change of the selected department.
   * It updates the selected department object based on user selection.
   * @param {Object} departmentData - The selected department data.
   */
  const handleDepartmentChange = (e: any, departmentData: any) => {
    setIsDepartmentEmpty(false);
    setSelectedDepartmentObj(departmentData);
    setDepartmentName(departmentData.name);
  };

  const handleSubmitProductInfo = handleSubmit(async (data: any) => {
    if (Object.values(errors).length === 0 && selectedDepartmentObj?.id) {
      setIsLoadingButton(true);
      handleSubmitProductWrapperInfo(data);
    } else {
      setIsLoadingButton(false);
      setIsDepartmentEmpty(true);
    }
  });

  const handleSubmitProductWrapperInfo = async (data: any) => {
    const { plu, barcode, upcBarcode } = data;

    let body = {};

    if (isRoot) {
      body = {
        name: data.name,
        locationId: match.params.locationId,
        nameShort: data.nameShort,
        categoryId: categoryId,
        valid: true,
        plu: plu,
        barcode: barcode,
        upcBarcode: upcBarcode,
        departmentId: selectedDepartmentObj.id,
        extId: "",
      };
    } else {
      body = {
        name: data.name,
        locationId: match.params.locationId,
        nameShort: data.nameShort,
        categoryId: categoryId,
        valid: true,
        plu: plu,
        barcode: barcode,
        upcBarcode: upcBarcode,
        departmentId: selectedDepartmentObj.id,
        extId: "",
        directoryId: directoryId,
        directoryLevel: directoryLevel,
      };
    }

    try {
      const summaryString = `${data.name} Product wrapper is added`;

      const res = await createProductWrapper(
        match.params.locationId,
        body,
        "Menus Changed",
        summaryString,
        "menu",
      );
      setCategoryId("");
      const cloneMenuNodeList = _.cloneDeep(menuNodeList);

      // Find and update the product name
      if (isRoot) {
        cloneMenuNodeList[selectedMenuId].category[categoryId].wrapper[
          res.data.data.itemWrapperId.id
        ] = res.data.data;
      } else {
        const updateProductName = (node: any) => {
          // Check if the current node is a product and has the specified ID
          if (node.id === wrapperId) {
            let cloneNode = _.cloneDeep(node);
            const wrapper = cloneNode?.wrapper || {};
            if(!node?.wrapper) {
              node.wrapper = {}
            }
            node.wrapper[res.data.data.itemWrapperId.id] = res.data.data.itemWrapperId;
            return true; // Indicates the product was found and updated
          }

          // Recursively traverse nested wrappers
          if (node.wrapper) {
            for (const key in node.wrapper) {
              if (Object.hasOwnProperty.call(node.wrapper, key)) {
                const child = node.wrapper[key];
                if (updateProductName(child)) {
                  return true; // Exit early if the product was found and updated
                }
              }
            }
          }

          return false; // Indicates the product was not found in this branch
        };

        // Find and update the product name
        if (
          cloneMenuNodeList[menuId] &&
          cloneMenuNodeList[menuId].category[categoryId]
        ) {
          updateProductName(
            cloneMenuNodeList[menuId].category[categoryId].wrapper[
              firstWrapperId
            ],
          );
        }
      }

      setIsOpen(false);
      setIsLoadingButton(false);
      setMenuNodeList(cloneMenuNodeList);
      setIsOpenDirectoryOrProductSelectionModal(false)
      setSelectedDepartmentObj({})
      setSuccess(SUCCESSFULLY_CREATED);
    } catch (err) {
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const [open, setOpen] = useState(false);

  const classes = useStyles();
  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>Add New Product</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>Name</Typography>
              <TextfieldDefaultNew
                id="name"
                name="name"
                type="text"
                inputRef={register({
                  required: "Name is required",
                })}
              />
              {errors.name ? (
                <ValidationMessage message={errors.name.message} />
              ) : (
                ""
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "12px", marginBottom: "12px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Select Department</Typography>
                <Button
                  style={{ textTransform: "none" }}
                  color="secondary"
                  autoFocus
                  onClick={handleOpenDepartmentAddModal}
                >
                  {"Add new department"}
                </Button>
              </div>
              <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                size={"small"}
                id="locationSelectGlobal"
                color="inherit"
                options={stockDepartmentSelectedNode}
                value={selectedDepartmentObj}
                getOptionLabel={(option: any) => option.name || ""}
                style={{ width: 300 }}
                disableClearable
                onChange={handleDepartmentChange}
                classes={{ root: classes.autoComplete }}
                popupIcon={<ArrowDropDownIcon color={"inherit"} />}
                renderOption={(props: any) => {
                  return (
                    <Grid
                      container
                      {...props}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        zIndex: 0,
                        height: "20px",
                        marginBottom: "4px",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          className={classes.titleColor}
                        >
                          {props.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
                renderInput={(params: any) => (
                  <TextField
                    color="inherit"
                    {...params}
                    label=""
                    placeholder="Select Department"
                    variant="outlined"
                  />
                )}
              />
              {isDepartmentEmpty ? (
                <ValidationMessage message={"Department is required"} />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>PLU</Typography>
              <TextfieldDefaultNew
                id="plu"
                name="plu"
                type="text"
                inputRef={register({
                  required: false,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>Barcode</Typography>
              <TextfieldDefaultNew
                id="barcode"
                name="barcode"
                type="text"
                inputRef={register({
                  required: false,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>
                UPC Barcode
              </Typography>
              <TextfieldDefaultNew
                id="upcBarcode"
                name="upcBarcode"
                type="text"
                inputRef={register({
                  required: false,
                })}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>
                Global Product Id
              </Typography>
              <TextfieldDefaultNew
                id="globalProductId"
                name="globalProductId"
                type="text"
                inputRef={register({
                  required: "Global Product Id is required",
                  pattern: {
                    value: /^[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}$/i,
                    message: "Please enter a valid Global Product Id",
                  },
                })}
              />
              {errors.globalProductId ? (
                <ValidationMessage message={errors.globalProductId.message} />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>
                Classification Id
              </Typography>
              <TextfieldDefaultNew
                id="classificationId"
                name="classificationId"
                type="text"
                inputRef={register({
                  required: "Classification Id is required",
                  pattern: {
                    value: /^[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}$/i,
                    message: "Please enter a valid Classification Id",
                  },
                })}
              />
              {errors.classificationId ? (
                <ValidationMessage message={errors.classificationId.message} />
              ) : (
                ""
              )}
            </Grid> */}

            {_.isEmpty(wrapperId) && (
              <Grid item xs={12}>
                <Typography style={{ paddingLeft: "4px" }}>
                  Name Short
                </Typography>
                <TextfieldDefaultNew
                  id="nameShort"
                  name="nameShort"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>
            )}
            {/* <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>
                Item Wrapper Id
              </Typography>
              <TextfieldDefaultNew
                id="itemWrapperId"
                name="itemWrapperId"
                type="number"
                inputRef={register({
                  required: "Item Wrapper Id is required",
                  pattern: INTEGER_NUMBER_PATTERN,
                })}
              />
              {errors.itemWrapperId ? (
                <ValidationMessage
                  message={
                    errors.itemWrapperId.type === "required"
                      ? "Item Wrapper Id is required"
                      : "Please enter integer number."
                  }
                />
              ) : (
                ""
              )}
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleCloseProductAddModal}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={handleDisable()}
            isLoadingPage={isLoadingButton}
            color={"green"}
            onClick={() => {
              selectedDepartmentObj?.id
                ? setIsDepartmentEmpty(false)
                : setIsDepartmentEmpty(true);
              handleSubmitProductInfo();
            }}
          >
            {"Create"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default ProductAddModal;
