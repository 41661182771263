import {
  Button,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import CardCommon from "../../../../components/card/CardCommon";
import {
  URL_EATPRESTO,
  URL_PRESTO_LOCATION,
  URL_PRESTO_MENUS,
  URL_PRESTO_TASK,
} from "../../../../utils/consts";
import { useLocation } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import InsertPhotoOutlinedIcon from "@material-ui/icons/InsertPhotoOutlined";
import WithLoading from "../../../../utils/WithLoading";
import { createLocationsInfo } from "../../../../services/locationApp/locationService";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { Icon } from "@iconify/react";
import { buttonColors } from "../../../../utils/enum";
import LocationCreateModal from "../../../../components/common/LocationCreateModal";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { checkIncludedValue } from "../../../../utils/commonArrayMap";
import { CustomTheme } from "../../../../types/customTheme";
import { handleChangeRestaurant } from "../../../../utils";
import { getIsAuthorized } from "../../../../utils/authorities";
import Authorities from "../../../../auth/authorities";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    buttonStyle: {
      display: "flex",
      justifyContent: "start",
      [theme.breakpoints.up("sm")]: {
        justifyContent: "end",
      },
    },
    searchBarStyle: {
      display: "flex",
      justifyContent: "start",
      marginTop: "0px",
      [theme.breakpoints.up("sm")]: {
        marginTop: "8px",
      },
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: "none",
      width: "300px",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface CreateNewNodeProps {
  locationNodeList: any;
  isLoading: any;
  getLocationInfo: any;
}

// Location initial data
const locationDataInitial = {
  businessDisplayName: "",
  businessRegName: "",
  businessTradingName: "",
  locationIdentifier: "",
  contactNo: "",
  email: "",
  orderUrl: "",
  description: "",
  terms: "",
  web: "",
  lat: 0,
  lon: 0,
  addressFormatted: "",
  buildingNoOrName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  county: "",
  country: "",
  postcode: "",
};

/**
 * LocationDetails Component:
 * This component represents the location details page, allowing users to view, search, and create locations.
 * It includes functionality for searching locations, creating new locations, and handling location selection.
 */

const LocationDetails: React.SFC<CreateNewNodeProps> = ({
  locationNodeList,
  isLoading,
  getLocationInfo,
}) => {
  const [searched, setSearched] = useState(false);
  const [locationList, setLocationList] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [locationData, setLocationData] = useState(locationDataInitial);
  const [error, setError] = useState("");
  const [address, setAddress] = useState<any>({});
  const [containerRef, setContainerRef] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const classes = useStyles();
  const search = useLocation().search;

  /* Update the 'locationList' state using the data from the API call. */
  useEffect(() => {
    setLocationList(locationNodeList);
  }, [locationNodeList]);

  /* When creating a location, provide the data to be sent to the backend. */
  const getNodeDataByState = () => {
    const updatedData = _.cloneDeep(locationDataInitial);
    updatedData.lat = address.lat;
    updatedData.lon = address.lon;
    updatedData.addressFormatted = address.addressFormatted;
    updatedData.buildingNoOrName = address.buildingNoOrName;
    updatedData.addressLine1 = address.addressLine1;
    updatedData.addressLine2 = address.addressLine2;
    updatedData.city = address.city;
    updatedData.county = address.county;
    updatedData.country = address.country;
    updatedData.postcode = address.postcode;
    updatedData.businessDisplayName = locationData.businessDisplayName;
    updatedData.businessRegName = locationData.businessRegName;
    updatedData.businessTradingName = locationData.businessTradingName;
    updatedData.locationIdentifier = locationData.locationIdentifier;
    updatedData.description = locationData.description;
    updatedData.contactNo = locationData.contactNo;
    updatedData.email = locationData.email;
    updatedData.web = locationData.web;
    updatedData.orderUrl = locationData.orderUrl;
    updatedData.terms = locationData.terms;
    return updatedData;
  };

  /* After the user selects a location, the website redirects to the corresponding app based on the query parameter. */
  const handleLocation = (location: any) => {
    const appName = new URLSearchParams(search).get("app");
    handleChangeRestaurant(location);
    // If the query parameter is 'eatpresto', Redirect to the eatpresto application.
    if (appName === "eatpresto") {
      window.location.href = `${process.env.REACT_APP_PRESTO_EATPRESTO_DOMAIN}/${location.id}/${URL_EATPRESTO}`;
    } else if (appName === "location") {
      // If the app name is 'location', Redirect to the Locations application.
      if (getIsAuthorized(Authorities.OPENING_TIME_READ)) {
        window.location.href = `${process.env.REACT_APP_PRESTO_LOCATION_DOMAIN}/${location.id}/${URL_PRESTO_LOCATION}`;
      } else {
        window.location.href = `${process.env.REACT_APP_PRESTO_LOCATION_DOMAIN}/${location.id}/${URL_PRESTO_LOCATION}/basic-info`;
      }
    } else if (appName === "sale") {
      // If the query parameter is 'sale', Redirect to the sale application.
      window.location.href = `${process.env.REACT_APP_PRESTO_SALES_DOMAIN}/${location.id}/dashboard`;
    } else if (appName === "task") {
      // If the query parameter is 'task', Redirect to the task application.
      window.location.href = `${process.env.REACT_APP_PRESTO_TASK_DOMAIN}/${location.id}/${URL_PRESTO_TASK}`;
    } else if (appName === "menu") {
      // If the query parameter is 'menu', Redirect to the menu application.
      window.location.href = `${process.env.REACT_APP_PRESTO_MENU_DOMAIN}/${location.id}/${URL_PRESTO_MENUS}`;
    } else if (appName === "inventory") {
      // If the query parameter is 'menu', Redirect to the menu application.
      if (getIsAuthorized(Authorities.INVENTORY_READ)) {
        window.location.href = `${process.env.REACT_APP_PRESTO_INVENTORY_DOMAIN}/${location.id}/stockTake`;
      } else {
        window.location.href = `${process.env.REACT_APP_PRESTO_INVENTORY_DOMAIN}/${location.id}/stockMovements`;
      }
    } else if (appName === "customer") {
      // If the query parameter is 'menu', Redirect to the menu application.
      window.location.href = `${process.env.REACT_APP_PRESTO_CUSTOMER_DOMAIN}/${location.id}/presto-customer/customerList`;
    }
  };

  /* Search the location using search bar */
  const handleSearchLocation = (searchedVal: string) => {
    setSelectedIndex(-1);
    // Locations with names matching the name of the location entered by the user will be returned.
    const filteredRows = locationNodeList.filter((locationDetails: any) =>
      checkIncludedValue(locationDetails.businessDisplayName, searchedVal),
    );
    // Returns 'true' if the user entered location matches the name.
    if (!_.isEmpty(filteredRows)) {
      setSearched(true);
    } else {
      // Returns 'false' if the user entered location not matches the name.
      setSearched(false);
    }
    setSearchValue(searchedVal);
    setLocationList(filteredRows);
  };

  /* Remove the search data */
  const handleResetSearch = () => {
    setSearched(false);
    setSearchValue("");
    handleSearchLocation("");
  };

  /* Entering the state of changes made in a text file. */
  const handleChangeLocationData = (e: any) => {
    const { name, value } = e.target;
    setLocationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  /* Send an API call to create location. */
  const handleCreateLocation = async () => {
    setError("");
    const createData = getNodeDataByState();
    try {
      await createLocationsInfo(createData);
      getLocationInfo();
      setSuccess("Location successfully created");
    } catch (error) {
      setError("Error creating option! please check your connection");
    }
  };

  /**
   * handleKeyPress Function:
   * Handles the key press event, specifically the "Enter" key, for navigating and selecting locations.
   *
   * @param {Object} event - The key press event object.
   */
  const handleKeyPress = (event: any) => {
    // Check if the pressed key is "Enter"
    if (event.key === "Enter") {
      // Prevent the default behavior of the "Enter" key (e.g., form submission)
      event.preventDefault();

      // Check if there is exactly one location in the filtered list
      if (locationList.length === 1) {
        // Call the handleLocation function with the first (and only) location in the list
        handleLocation(locationList[0]);
      }
    }
  };

  /**
   * handleArrowNavigation Function:
   * Handles the arrow key (up and down) navigation within the location list.
   *
   * @param {Object} event - The key press event object.
   */
  const handleArrowNavigation = (event: any) => {
    // Check if the pressed key is either "ArrowUp" or "ArrowDown" and if there is a search value
    if ((event.key === "ArrowUp" || event.key === "ArrowDown") && searchValue) {
      // Prevent the default behavior of arrow keys
      event.preventDefault();

      // Calculate the new index based on the pressed arrow key
      const newIndex =
        event.key === "ArrowUp" ? selectedIndex - 1 : selectedIndex + 1;

      // Clamp the new index to ensure it stays within the valid range [0, locationList.length - 1]
      const clampedIndex = Math.max(
        0,
        Math.min(newIndex, locationList.length - 1),
      );

      // Set the selected index to the clamped index
      setSelectedIndex(clampedIndex);

      // Scroll down if the selected index is at the end of the visible cards
      if (event.key === "ArrowDown" && clampedIndex >= 3 && searchValue) {
        window.scrollBy({ top: 120 });
      }

      // Scroll up if the selected index is at the beginning of the visible cards
      if (event.key === "ArrowUp" && searchValue) {
        window.scrollBy({ top: -120 });
      }
    }
  };

  /**
   * useEffect Hook:
   * Attaches and detaches the event listener for keyboard navigation using arrow keys.
   * Listens for keydown events and calls the handleArrowNavigation function if there is a search value.
   * Cleans up by removing the event listener when the component unmounts or when dependencies change.
   */
  useEffect(() => {
    // Define the handleKeyDown function to be called on keydown events
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if there is a search value
      if (searchValue) {
        // Call the handleArrowNavigation function to handle arrow key navigation
        handleArrowNavigation(event);
      }
    };

    // Attach the event listener for keydown events to the window
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: remove the event listener when the component is unmounted or when dependencies change
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchValue, selectedIndex, locationList]); // Dependencies: searchValue, selectedIndex, locationList

  /**
   * useEffect Hook:
   * Attaches and detaches the event listener for handling 'Enter' keypress.
   * Listens for keydown events and calls the handleKeyPress function when 'Enter' is pressed with a valid selectedIndex.
   * Cleans up by removing the event listener when the component unmounts or when dependencies change.
   */
  useEffect(() => {
    // Define the handleKeyPress function to be called on keydown events
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if there is a valid selectedIndex and the 'Enter' key is pressed
      if (selectedIndex >= 0 && event.key === "Enter") {
        // Check if there are items in the locationList
        if (locationList.length > 0) {
          // Call the handleLocation function with the selected location from locationList
          handleLocation(locationList[selectedIndex]);
        }
      }
    };

    // Attach the event listener for keydown events to the window
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup: remove the event listener when the component is unmounted or when dependencies change
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedIndex, locationList]); // Dependencies: selectedIndex, locationList

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: "16px",
          marginTop: "60px",
          paddingRight: "8px",
        }}
        ref={(ref) => setContainerRef(ref)}
      >
        <Grid item sm={6} xs={12}>
          <Typography
            variant="h5"
            component="div"
            style={{ display: "flex", justifyContent: "start" }}
          >
            All Locations
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12} className={classes.buttonStyle}>
          <ButtonCommon
            style={{
              fontSize: 13,
              width: "240px",
              padding: "12px",
            }}
            startIcon={
              <Icon icon="ic:baseline-add-business" height="26" width="26" />
            }
            variant="contained"
            color={buttonColors.CREATE_BUTTON_COLOR}
            onClick={() => setOpen(true)}
          >
            <Typography
              variant="body2"
              style={{ fontWeight: "inherit", color: "white" }}
            >
              CREATE NEW LOCATION
            </Typography>
          </ButtonCommon>
          {/* </div> */}
        </Grid>
        <Grid item xs={12} className={classes.searchBarStyle}>
          <TextField
            id="filled-hidden-label-small"
            autoFocus
            value={searchValue}
            onChange={(searchVal: any) =>
              handleSearchLocation(searchVal.target.value)
            }
            onKeyDown={(event) => handleKeyPress(event)}
            className={classes.textField}
            variant="outlined"
            size="medium"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!searched ? (
                    <SearchIcon />
                  ) : (
                    <CloseIcon
                      onClick={() => handleResetSearch()}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {!_.isEmpty(locationNodeList) &&
          locationList.map((locations: any, index: any) => {
            return (
              <Grid item xs={12}>
                <CardCommon
                  backgroundColor={
                    selectedIndex === index
                      ? "entity_highlight_background"
                      : "entity_background"
                  }
                >
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => handleLocation(locations)}
                    disableRipple
                  >
                    <Grid
                      container
                      style={{ margin: "8px", placeItems: "center" }}
                    >
                      <Grid item xs={8}>
                        <Typography variant="h6" component="div" align="left">
                          {locations.businessDisplayName}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="div"
                          align="left"
                        >
                          {locations.locationIdentifier}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        xs={4}
                        justify="flex-end"
                        style={{ placeItems: "center" }}
                      >
                        <InsertPhotoOutlinedIcon
                          style={{ width: "75px", height: "75px" }}
                        />
                      </Grid>
                    </Grid>
                  </Button>
                </CardCommon>
              </Grid>
            );
          })}
      </Grid>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <LocationCreateModal
        handleCreateLocation={handleCreateLocation}
        open={open}
        setOpen={setOpen}
        address={address}
        setAddress={setAddress}
        handleChangeLocationData={handleChangeLocationData}
        locationData={locationData}
        setLocationData={setLocationData}
      />
    </>
  );
};

export default WithLoading(LocationDetails);
