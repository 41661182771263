import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { useLocation, useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import Pagination from "../../../../components/common/Pagination";
import InventoryStockOrder from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";
import {
  fetchAllStockItemsGroupsInfoSorting,
  fetchSingleStockGroup,
  updateStockGroups,
} from "../../../../services/inventory/stockItems";
import StockGroupHeader from "./StockGroupHeader";
import AddStockGroupModal from "./StockGroupAddModal";
import Toast from "../../../../components/alerts/Toast";
import { HttpStatus } from "../../../../utils/enum";
import { fetchAllMasterLocations } from "../../../../services/inventory/masterLocations";
import { getIsAuthorized } from "../../../../utils/authorities";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * This component manages the display of stock group information and related UI components.
 * It fetches data from APIs, handles user interactions, and renders various UI elements.
 * The component includes features such as sorting, pagination, and filtering of stock group.
 * Additionally, it provides the ability to create and edit stock group through modal dialogs.
 * Access control is enforced using the 'withAuthority' higher-order component.
 */
const StockGroup: React.FunctionComponent = () => {
  const [stockGroupNodeList, setStockGroupNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("");
  const [sortingMethod, setSortingMethod] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [version, setVersion] = useState("");
  const [success, setSuccess] = useState("");
  const [groupId, setGroupId] = useState("");
  const [isOpenStockGroupModal, setIsOpenStockGroupModal] = useState(false);
  const [isOpenStockGroupEditModal, setIsOpenStockGroupEditModal] =
    useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupNameInitial, setGroupNameInitial] = useState("");
  const [isSort, setIsSort] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [isLoadingToast, setIsLoadingToast] = useState(false);
  const [isActiveLocationId, setIsActiveLocationId] = useState(true);
  const [totalResult, setTotalResult] = useState(0);

  const match: any = useRouteMatch();

  /**
   * Function to fetch stock group information using API call and update component states.
   * @param {number} pageSize - Number of items to display per page.
   * @param {number} currentPage - Current page number.
   * @param {string} sortingMethod - Sorting method for the items.
   * @param {string} headerName - Name of the header to sort by.
   * @param {any} filterList - List of filters for querying items.
   */
  const getStockGroupInfo = async (
    pageSize: number,
    currentPage: number,
    sortingMethod: string,
    headerName: string,
  ) => {
    // Set the pageSize and currentPage states for pagination
    setPageSize(pageSize);
    setCurrentPage(currentPage);

    try {
      // Attempt to fetch stock groups information using the 'fetchAllStockItemsGroupsInfoSorting' API
      const res = await fetchAllStockItemsGroupsInfoSorting(
        match.params.locationId,
        pageSize,
        currentPage,
        sortingMethod,
        headerName,
      );
      // Update component states with the received data from the API response
      // Set loading state to false
      setIsLoading(false);
      // Set stock groups data
      setStockGroupNodeList(res.data.data);
      // Set total number of pages
      setTotalPages(res.data.totalPages);
      setTotalResult(res.data.totalResults)
      // Disable skeleton loading
      setIsOpenSkeletonLoading(false);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    }
  };

  const handleGetMasterLocations = async () => {
    try {
      const res = await fetchAllMasterLocations(match.params.locationId);
      if (
        (res.data && res.data.data && res.data.data.active) ||
        getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ)
      ) {
        getAllFilterLocation("");
        setIsActiveLocationId(true);
      } else {
        setIsActiveLocationId(false);
        setIsLoading(false);
        setIsOpenSkeletonLoading(false);
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Effect to set the document title and fetch location information on component mount.
   */
  useEffect(() => {
    // Set the document title to "Eat Presto - Stock Item"
    document.title = "Inventory - Stock Groups";

    // Fetch location information with an empty searchName
    handleGetMasterLocations();
  }, []);

  /**
   * Function to fetch location information and initialize stock groups loading.
   * @param {string} searchName - Name to search for locations.
   */
  const getAllFilterLocation = async (searchName: string) => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations(searchName);

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);

      // Trigger the function to fetch stock groups information with appropriate parameters
      getStockGroupInfo(pageSize, 1, "", "");
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to handle pagination change using selector.
   * @param {any} pageSize - Number of items to display per page.
   */
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);

    // Check if a headerName is provided for sorting
    if (headerName) {
      // If headerName is provided, fetch stock groups information with sorting and filtering
      getStockGroupInfo(pageSize, 1, sortingMethod, headerName);
    } else {
      // If headerName is not provided, fetch stock groups information without sorting
      getStockGroupInfo(pageSize, 1, sortingMethod, "");
    }
  };

  /**
   * Function to handle pagination change using page number.
   * @param {any} currentPage - The selected page number.
   */
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);

    // Fetch stock groups information with the updated pagination parameters
    getStockGroupInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to handle sorting of stock groups list.
   * @param {any} headerName - The selected header name for sorting.
   * @param {any} sortingMethod - The selected sorting method.
   */
  const handleChangeStockItemsListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    // Set the 'disableButton' state to true to disable the sorting button temporarily
    setDisableButton(true);

    setIsSort(true);

    // Set the 'headerName' state with the selected header name
    setHeaderName(headerName);

    // Set the 'sortingMethod' state with the selected sorting method
    setSortingMethod(sortingMethod);

    // Fetch stock groups information with the updated sorting parameters
    getStockGroupInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to remove sorting from the stock groups list.
   */
  const handleRemoveStockItemsListSorting = () => {
    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    setIsSort(false);

    // Clear the 'headerName' state to remove sorting by header
    setHeaderName("");

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch stock groups information without sorting
    getStockGroupInfo(pageSize, currentPage, "", "");
  };

  /**
   * Function to get stock group details.
   */
  const handleGetStockGroupList = () => {
    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    // Clear the 'headerName' state to remove sorting by header
    setHeaderName("");

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch stock groups information without sorting
    getStockGroupInfo(pageSize, 1, "", "");
  };

  const getSingleGroupGivenId = async () => {
    const res = await fetchSingleStockGroup(match.params.locationId, groupId);

    setVersion(res.data.data?.version);
    handleUpdateGroup(res.data.data?.version);
  };

  /**
   * Handler for updating a group.
   * This function submits the updated group details to the API,
   * handles success and error cases, and updates relevant state.
   */
  const handleUpdateGroup = async (updatedVersion?: any) => {
    const formData = {
      id: groupId,
      name: groupName,
      locationId: match.params.locationId,
      version: updatedVersion || version,
    };

    try {
      // Call the API to update the stock department
      const res = await updateStockGroups(match.params.locationId, formData);

      if (res.data.status === HttpStatus.CONFLICT_409) {
        getSingleGroupGivenId();
      } else {
        // Update the stock group list
        handleGetStockGroupList();

        setIsLoadingToast(false);
        setToastMessage(SUCCESSFULLY_UPDATED);
        setToastType("success");
        setIsOpenStockGroupModal(false);
      }
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Function to handle opening the create stock groups modal.
   */
  const handleOpenCreateStockGroupModal = () => {
    // Open the 'isOpenCreateStockOrderModal'
    setIsOpenStockGroupModal(true);
    setGroupName("");
    setVersion("");
    setIsOpenStockGroupEditModal(false);
  };

  /**
   * Function to handle opening the edit stock group modal.
   * @param {string} groupId - ID of the group.
   * @param {string} name - Name of the group.
   * @param {string} version - Version of the group.
   * @param {React.MouseEvent<HTMLLIElement>} event - The click event triggered.
   */
  const handleOpenEditStockGroupModal = (
    groupId: string,
    name: string,
    version: string,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // If an event is provided, stop its propagation (prevent further bubbling)
    if (event) {
      event.stopPropagation();
    }

    // Set the stock group ID in the state
    setGroupId(groupId);

    // Set the version in the state
    setVersion(version);

    // Open the create stock group modal
    setIsOpenStockGroupModal(true);

    // Set the name in the state
    setGroupName(name);
    setGroupNameInitial(name);

    // Open the edit stock group modal
    setIsOpenStockGroupEditModal(true);
  };

  return (
    <>
      {/* Main container */}
      <Box>
        <Toast
          message={toastMessage}
          type={toastType}
          loading={isLoadingToast}
        />

        {/* InventoryDetails component */}
        <InventoryStockOrder
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          nodeList={stockGroupNodeList}
          topic="Stock Groups"
          handleOpenCreateModal={handleOpenCreateStockGroupModal}
          buttonName="Add Stock Group"
          isNeedAddButton={true}
          isActiveLocationId={isActiveLocationId}
        >
          {/* StockGroup table component */}
          <StockGroupHeader
            handleChangeStockGroupListSorting={
              handleChangeStockItemsListSorting
            }
            handleRemoveStockGroupListSorting={
              handleRemoveStockItemsListSorting
            }
            nodeData={stockGroupNodeList}
            disableButton={disableButton}
            isLoading={isLoading}
            locationSelectorList={locationSelectorList}
            handleOpenEditStockGroupModal={handleOpenEditStockGroupModal}
            isSort={isSort}
            headerName={headerName}
            sortingMethod={sortingMethod}
          />
          {/* Render Pagination if stockGroupNodeList is not empty */}
          {!_.isEmpty(stockGroupNodeList) && (
            <Pagination
              handleChangePaginationUsingSelector={
                handleChangePaginationUsingSelector
              }
              handleChangePaginationUsingPageNumber={
                handleChangePaginationUsingPageNumber
              }
              totalPages={totalPages}
              currentPage={currentPage}
              pageSize={pageSize}
              totalResult={totalResult}
            />
          )}
        </InventoryStockOrder>

        <AddStockGroupModal
          isOpenStockGroupModal={isOpenStockGroupModal}
          setIsOpenStockGroupModal={setIsOpenStockGroupModal}
          setSuccess={setSuccess}
          setError={setError}
          isOpenStockGroupEditModal={isOpenStockGroupEditModal}
          setGroupName={setGroupName}
          groupName={groupName}
          version={version}
          groupId={groupId}
          groupNameInitial={groupNameInitial}
          handleGetStockGroupList={handleGetStockGroupList}
          handleUpdateGroup={handleUpdateGroup}
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
          toastType={toastType}
          setToastType={setToastType}
          isLoadingToast={isLoadingToast}
          setIsLoadingToast={setIsLoadingToast}
        />

        {/* DefaultAlert components for success and error messages */}
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(StockGroup, Authorities.INVENTORY_ADMIN_READ);
