import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  deconstructMinuteOfWeekArray,
  getOpenCloseTimesList,
} from "../../../utils/time-conversions";
import AvailabilitySelectionModal from "../AvailabilitySelectionModal";
import _ from "lodash";
import CardCommon from "../../card/CardCommon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  header: {},
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "self-start",
    flex: 1,
  },
  radioBtn: {
    display: "flex",
  },
  daysSection: {
    display: "flex",
  },
  days: {
    marginRight: 16,
  },
}));

export interface AvailabilitySelectionProps {
  nodeData: any;
  disabled?: boolean;
  handleSubmit: any;
  setAvailabilityErrorMessage?: any;
  backgroundColor?: any;
}

const AvailabilitySelection: React.FunctionComponent<
  AvailabilitySelectionProps
> = ({
  nodeData,
  disabled,
  handleSubmit,
  setAvailabilityErrorMessage,
  backgroundColor = "entity_highlight_background",
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const openCloseTimes = getOpenCloseTimesList(nodeData.availability);
  const deconstructedMinuteOfWeekArray: any = deconstructMinuteOfWeekArray(
    nodeData.availability,
    openCloseTimes,
  );

  return (
    <CardCommon backgroundColor={backgroundColor} style={{ margin: "8px 0" }}>
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant={"subtitle1"} component="div" gutterBottom>
            {/* {option.title} */}
          </Typography>
          <div className={classes.daysSection}>
            {!_.isEmpty(deconstructedMinuteOfWeekArray) ? (
              deconstructedMinuteOfWeekArray.map((node: any) => (
                <div className={classes.days} key={node.toString()}>
                  <Typography variant={"subtitle2"}>
                    {node.nodeList
                      .map((openTimeNode: any) => _.startCase(openTimeNode.day))
                      .join(", ")}
                  </Typography>
                  <Typography variant={"body2"}>
                    {node.openTime} - {node.closeTime}
                  </Typography>
                </div>
              ))
            ) : (
              <Typography>Available for all times</Typography>
            )}
          </div>
        </div>
        <div className={classes.radioBtn}>
          <AvailabilitySelectionModal
            open={open}
            setOpen={setOpen}
            nodeData={nodeData}
            disabled={disabled}
            handleSubmit={handleSubmit}
            setAvailabilityErrorMessage={setAvailabilityErrorMessage}
          />
        </div>
      </div>
    </CardCommon>
  );
};

export default AvailabilitySelection;
