import {
  Button,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import firebase from "firebase";
import _ from "lodash";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import AlertDialog from "../../../../../components/alerts/AlertDialog";
import DefaultAlert from "../../../../../components/alerts/DefaultAlert";
import CardCommon from "../../../../../components/card/CardCommon";
import { fetchAllLocations } from "../../../../../services/locationApp/locationService";
import { handleChangeRestaurant } from "../../../../../utils";
import {
  APP_PRESTO_EATPRESTO,
  APP_PRESTO_INVENTORY,
  APP_PRESTO_LOCATION,
  APP_PRESTO_SALES,
  ERROR_MESSAGE_UNEXPECTED_ERROR,
} from "../../../../../utils/consts";
import { removeAllCookies } from "../../../../../utils/cookies";
import commonThemeDark from "../../../../../root/theme/dark";
import StorefrontIcon from "@material-ui/icons/Storefront";
import SignalCellularAltIcon from "@material-ui/icons/SignalCellularAlt";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import eatprestoLogo from "../../../../../assets/images/eatPrestoLogo.png";
import { handleAppVisible } from "../../../../../utils/appVisibility";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconButton: {
    width: "100%",
    height: "88px",
  },
}));

/**
 * Array of Presto Applications (APPS):
 * This array contains objects representing different Presto applications
 * with details such as title, URL parameter, icon, background color, and app name.
 *
 * Each object represents an application that may be displayed in the ManageApps component.
 * Commented-out entries are optional and can be uncommented based on the application's availability.
 */
const APPS = [
  {
    title: "MANAGE LOCATIONS",
    appUrlParam: "presto-locations",
    icon: <StorefrontIcon fontSize="large" />,
    backgroundColor: commonThemeDark.palette.custom.yellow.main,
    appName: APP_PRESTO_LOCATION,
  },
  {
    title: "ONLINE ORDERING",
    appUrlParam: "eatpresto",
    icon: (
      <img
        src={eatprestoLogo}
        width="60px"
        height="38px"
        alt="eatpresto logo"
      />
    ),
    backgroundColor: commonThemeDark.palette.custom.red.light,
    appName: APP_PRESTO_EATPRESTO,
  },
  {
    title: "SALES AND REPORTING",
    appUrlParam: "presto-sales",
    icon: <SignalCellularAltIcon fontSize="large" />,
    backgroundColor: "#25ACE3",
    appName: APP_PRESTO_SALES,
  },
  // {
  //   title: "PRESTO TASKS",
  //   appUrlParam: "presto-task",
  //   icon: <PlaylistAddCheckIcon style={{ fontSize: "60px" }} />,
  //   backgroundColor: commonThemeDark.palette.custom.green.light,
  //   appName: APP_PRESTO_TASK,
  // },
  // {
  //   title: "Menus",
  //   appUrlParam: "presto-menus",
  //   icon: <MenuBookIcon style={{ fontSize: "60px" }} />,
  //   backgroundColor: commonThemeDark.palette.custom.purple.main,
  //   appName: APP_PRESTO_MENU,
  // },
  {
    title: "Inventory",
    appUrlParam: "presto-inventory",
    icon: <BusinessCenterIcon style={{ fontSize: "36px" }} />,
    backgroundColor: commonThemeDark.palette.custom.green.light,
    appName: APP_PRESTO_INVENTORY,
  },
];

interface PrestoAppObject {
  title: string;
  appUrlParam: string;
}

export interface ManageAppsProps {}

/**
 * ManageApps Component:
 * This component renders a grid of Presto applications based on user permissions.
 * Users can click on each application to navigate to the respective app.
 * It also handles user authentication, fetching app data, and logging out.
 *
 * Features:
 * - Display Presto applications with icons and titles in a responsive grid.
 * - Authenticate users and handle user details using Firebase authentication.
 * - Redirect users to different Presto applications based on their selection.
 * - Display error alerts for unexpected errors and permission issues.
 * - Provide a logout option, clearing authentication and redirecting to the login page.
 */
const ManageApps: React.FunctionComponent<ManageAppsProps> = () => {
  const [appList, setAppList] = useState<Array<PrestoAppObject>>([]);
  const [firstLocation, setFirstLocation] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [authUser, setAuthUser] = useState<{
    isAuthenticated: boolean;
    name: string | "";
    email: string | "";
    uId: String | "";
  }>({
    isAuthenticated: false,
    name: "",
    email: "",
    uId: "",
  });

  /**
   * Asynchronous function to fetch Presto applications.
   * This function sets the application list using the predefined APPS array.
   * In case of an error, it sets the error message to ERROR_MESSAGE_UNEXPECTED_ERROR.
   */
  const fetchPrestoApps = useCallback(async () => {
    try {
      // Set the application list using the predefined APPS array.
      setAppList(APPS);
    } catch (err) {
      // If an error occurs during the fetching process, set the error message.
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  }, []);

  /**
   * Asynchronous function to fetch all locations using the fetchAllLocations service.
   * This function sets the first location using the response data.
   * In case of an error, it sets the error message to ERROR_MESSAGE_UNEXPECTED_ERROR.
   */
  const fetchLocations = useCallback(async () => {
    try {
      // Fetch all locations using the fetchAllLocations service.
      const res = await fetchAllLocations();

      // Set the first location using the response data.
      const firstLocation = res.data.data[0];
      setFirstLocation(firstLocation);
    } catch (err) {
      // If an error occurs during the fetching process, set the error message.
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  }, []);

  /**
   * useEffect hook that runs after the component mounts.
   * It invokes the fetchLocations and fetchPrestoApps functions.
   * These functions fetch location data and Presto app details, respectively.
   * The useEffect has dependencies on fetchLocations and fetchPrestoApps.
   */
  useEffect(() => {
    // Invoke the fetchLocations function to fetch location data.
    fetchLocations();

    // Invoke the fetchPrestoApps function to fetch Presto app details.
    fetchPrestoApps();
  }, [fetchLocations, fetchPrestoApps]);

  /**
   * useEffect hook that runs after the component mounts.
   * It checks if the user is in the account application based on the current domain.
   * If the user is in the account app, it sets up a listener for Firebase authentication state changes.
   * When the authentication state changes, it updates the authUser state with user details.
   * If there's an error during the process, it sets isAuthenticated to false and clears user details.
   */
  useEffect(() => {
    // Get the current domain from environment variables or use "localhost" as the default.
    const CURRENT_DOMAIN =
      process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "localhost";

    // Checking if the user is now in the account application.
    const isAccountApp = window.location.href.includes(CURRENT_DOMAIN);

    if (isAccountApp) {
      // Set up a listener for Firebase authentication state changes.
      firebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          // If the user is authenticated, get the user's ID token to fetch additional details.
          firebaseUser
            .getIdToken(true)
            .then(function (idToken) {
              // Get the user details and update the authUser state.
              setAuthUser({
                isAuthenticated: true,
                email: firebaseUser?.email || "",
                name: firebaseUser?.displayName || "",
                uId: firebaseUser?.uid || "",
              });
            })
            .catch(function (error) {
              // If there's an error during ID token retrieval, set isAuthenticated to false and clear user details.
              alert("error");
              setAuthUser({
                isAuthenticated: false,
                email: "",
                name: "",
                uId: "",
              });
            });
        } else {
          // If there's no authenticated user, set isAuthenticated to false and clear user details.
          setAuthUser({ isAuthenticated: false, email: "", name: "", uId: "" });
        }
      });
    }
  }, []);

  /**
   * Handles the click event for the app icons.
   * If the firstLocation state is empty, it opens an error dialog.
   * Otherwise, it stores the location data in local storage and navigates to the selected app's URL.
   */
  const handleClick = (appUrlParam: any) => {
    // Check if the firstLocation state is empty.
    if (_.isEmpty(firstLocation)) {
      // If empty, open an error dialog and return.
      return setOpen(true);
    }

    // Close the error dialog.
    setOpen(false);

    // Store location data in local storage.
    handleChangeRestaurant(firstLocation);

    // Navigate to the selected app's URL based on appUrlParam.
    if (appUrlParam === "eatpresto") {
      window.location.href = `${process.env.REACT_APP_PRESTO_EATPRESTO_DOMAIN}`;
    } else if (appUrlParam === "presto-locations") {
      window.location.href = `${process.env.REACT_APP_PRESTO_LOCATION_DOMAIN}`;
    } else if (appUrlParam === "presto-sales") {
      window.location.href = `${process.env.REACT_APP_PRESTO_SALES_DOMAIN}`;
    } else if (appUrlParam === "presto-task") {
      window.location.href = `${process.env.REACT_APP_PRESTO_TASK_DOMAIN}`;
    } else if (appUrlParam === "presto-menus") {
      window.location.href = `${process.env.REACT_APP_PRESTO_MENU_DOMAIN}`;
    } else if (appUrlParam === "presto-inventory") {
      window.location.href = `${process.env.REACT_APP_PRESTO_INVENTORY_DOMAIN}`;
    }
  };

  /**
   * Handles the logout action.
   * Closes the error dialog, signs the user out using Firebase authentication,
   * and then redirects the user to the sign-in page with clearing cookies.
   */
  const handleLogout = () => {
    // Close the error dialog.
    setOpen(false);

    // Sign out the user using Firebase authentication.
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Redirect to the sign-in page after successful sign-out.
        window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/sign-in`;

        // Remove all cookies.
        removeAllCookies();
      })
      .catch(() => {
        // If an error occurs during sign-out, set an unexpected error message.
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  const classes = useStyles();

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Grid container spacing={1}>
          {appList.map(
            (app: any) =>
              handleAppVisible(app.appName, authUser.uId) && (
                <Grid item xs={6} md={4}>
                  <CardCommon backgroundColor={"entity_highlight_background"}>
                    <Button
                      onClick={() => handleClick(app.appUrlParam)}
                      className={classes.iconButton}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            style={{ color: app.backgroundColor }}
                          >
                            {app.icon}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">{app.title}</Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </CardCommon>
                </Grid>
              ),
          )}
        </Grid>
      </div>
      <AlertDialog
        open={open}
        confirmAction={handleLogout}
        title="Error"
        desc="You don't have permission."
        severity="error"
        confirmLabel="Log out"
        disableBackdropClick
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default ManageApps;
