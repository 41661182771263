import { Hidden } from "@material-ui/core";
import * as React from "react";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";

export interface HomeProps {}

const Home: React.FunctionComponent<HomeProps> = () => {
  React.useEffect(() => {
    document.title = "Task - Dashboard ";
  }, []);

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      Dashboard
    </>
  );
};

export default Home;
