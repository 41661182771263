import React from "react";
import WithLoading from "../../../../utils/WithLoading";
import MenuInfoPage from "./MenuInfoPage";

export interface LoadingProps {
  isLoading: Boolean;
  menuNodeList: any;
  linkedIdList: any
}

/* Loading the page until data is retrieved from the API call. */
const Loading: React.FunctionComponent<LoadingProps> = ({
  isLoading,
  menuNodeList,
  linkedIdList
}) => {
  return (
    <MenuInfoPage
      menuNodeList={menuNodeList}
      linkedIdList={linkedIdList}
    />
  );
};

export default WithLoading(Loading);
