import {
  Box,
  Button,
  Card,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Slide,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PrintIcon from "@material-ui/icons/Print";

import { CustomTheme } from "../../../../types/customTheme";
import { handleImageUrl } from "../../../../utils/ChangePixelImgUrl";
import { useRouteMatch } from "react-router-dom";
import {
  fetchProductGetByWrapperInfo,
  fetchSingleProductInfo,
  fetchSingleProductWrapper,
  updateProductInfo,
  updateProductWrapperInfo,
  updateWrapperInfo,
} from "../../../../services/menuApp/menuService";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { AxiosError } from "axios";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    button: {
      position: "relative",
      overflow: "hidden",
      borderBottomRightRadius: "10px",
    },
    imgBoxStyle: {
      margin: "7px",
      marginLeft: "10px",
      borderRadius: "10px",
      height: "120px",
      [theme.breakpoints.down("sm")]: {
        height: "92px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
    colorStripStyle: {
      width: "4px",
      height: "112px",
      borderRadius: "10px",
      marginRight: "12px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
      },
    },
    titleStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",

      [theme.breakpoints.down("sm")]: {
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    imgBoxNotAvailableStyle: {
      marginRight: "7px",
      marginTop: "10px",
      borderRadius: "10px",
      marginBottom: "10px",
      height: "114px",
      width: "114px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
        width: "80px",
      },
      backgroundColor: theme.palette.background.imgBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      objectFit: "cover",
      width: "114px",
      height: "114px",
      [theme.breakpoints.down("sm")]: {
        width: "88px",
        height: "84px",
      },
    },
    boxStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    },
    priceStyle: {
      marginBottom: "4px",
    },
  }),
);

export interface CategoryMenuListProps {
  item: any;
  handleOpenProductEditModal: any;
  handleOpenMappingModal: any;
  category: any;
  wrapper: any;
  isLoadProduct: any;
  getProductWrapperInfo: any;
  menuNode: any;
  categoryNode: any;
  setSuccess: any;
  isOpenWrapperEditModal: any;
  productVersion: any;
  data1?: any;
  menuNodeList: any;
  setMenuNodeList: any;
  wrapperId: any;
  menuId: any;
  categoryId: any;
  firstWrapperId: any;
  isRoot: any;
}

/**
 * CategoryMenuList Component: Category Menu List
 *
 * This component represents a list item within a category menu. It displays information about an item, including its name,
 * image (if available), and description. Users can interact with the item to view more details or perform actions.
 */
const CategoryMenuList: React.FunctionComponent<CategoryMenuListProps> = ({
  item,
  handleOpenProductEditModal,
  handleOpenMappingModal,
  category,
  wrapper,
  isLoadProduct,
  getProductWrapperInfo,
  menuNode,
  categoryNode,
  setSuccess,
  isOpenWrapperEditModal,
  productVersion,
  data1,
  menuNodeList,
  setMenuNodeList,
  wrapperId,
  menuId,
  categoryId,
  firstWrapperId,
  isRoot,
}) => {
  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const match: any = useRouteMatch();

  const handleGetSingleProductWrapperValidChange = async (product: any) => {
    try {
      const res = await fetchSingleProductWrapper(
        match.params.locationId,
        product.id,
      );
      handleChangeValidInWrapper(product, res.data.data.version);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  const handleChangeValidInWrapper = async (
    item: any,
    productVersion?: any,
  ) => {
    setError("");
    setIsLoading(true);
    const cloneLoad = _.cloneDeep(isLoadProduct);
    await cloneLoad.push(item.id);
    const body = {
      locationId: match.params.locationId,
      valid: !item.valid,
      id: item.id,
      version: productVersion || item.version,
    };

    try {
      let summary = [];

      if (!item.valid !== item.valid) {
        summary.push(
          `Product id ${item.id} valid changed from ${
            item.valid
          } to ${!item.valid}`,
        );
      }

      const summaryString = summary.join("\\n");

      const res = await updateWrapperInfo(
        match.params.locationId,
        body,
        "Menus Changed",
        summaryString,
        "menu",
      );
      if (res.data.status === 409) {
        handleGetSingleProductWrapperValidChange(item);
      } else {
        const cloneMenuNode = _.cloneDeep(menuNodeList);
        if (isRoot) {
          cloneMenuNode[menuId].category[category.id].wrapper[
            res.data.data.id
          ] = res.data.data;
        } else {
          const updateProductName = (node: any) => {
            // Check if the current node is a product and has the specified ID
            if (node.id === item.id) {
              node.valid = res.data.data.valid;
              node.version = res.data.data.version;
              return true; // Indicates the product was found and updated
            }

            // Recursively traverse nested wrappers
            if (node.wrapper) {
              for (const key in node.wrapper) {
                if (Object.hasOwnProperty.call(node.wrapper, key)) {
                  const child = node.wrapper[key];
                  if (updateProductName(child)) {
                    return true; // Exit early if the product was found and updated
                  }
                }
              }
            }

            return false; // Indicates the product was not found in this branch
          };

          // Find and update the product name
          if (
            cloneMenuNode[menuId] &&
            cloneMenuNode[menuId].category[category.id]
          ) {
            updateProductName(
              cloneMenuNode[menuId].category[category.id].wrapper[wrapper.id],
            );
          }
        }

        setMenuNodeList(cloneMenuNode);
        setIsLoading(false);
        setSuccess(SUCCESSFULLY_UPDATED);
      }
    } catch (error) {
      const err: any = error as AxiosError;

      const errorMessage = err.response ? err.response.data : err.message;

      setError(errorMessage.error);
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      const timer: any = setTimeout(() => {
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <Slide in={true} direction="right" mountOnEnter unmountOnExit timeout={0}>
      <div style={{ overflow: "hidden", zIndex: 0, position: "relative" }}>
        <Card
          style={{
            overflowY: "hidden",
            cursor: "pointer",
            borderRadius: "10px",
            boxShadow: "none",
            backgroundColor: theme.palette.background.entity_background,
            border: `1px solid ${theme.palette.background.entity_border}`,
            position: "relative",
            zIndex: 0,
          }}
        >
          {error && (
            <div
              className="warning"
              style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                left: "0px",
                backgroundColor: "red",
                color: "white",
                padding: "5px",
                borderRadius: "10px",
              }}
            >
              {error}
            </div>
          )}
          <Grid container>
            <Grid item xs={12} style={{ display: "flex" }}>
              {item?.img ? (
                <Box className={classes.imgBoxStyle}>
                  <div
                    style={{
                      paddingTop: "12px",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: wrapper.colour,
                      }}
                      className={classes.colorStripStyle}
                    ></Box>
                  </div>
                  <img
                    className={classes.img}
                    src={handleImageUrl(item.img)}
                    alt="not available images"
                    style={{
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    }}
                  />
                </Box>
              ) : (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      paddingTop: "12px",
                      marginLeft: "10px",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: wrapper.colour,
                      }}
                      className={classes.colorStripStyle}
                    ></Box>
                  </div>
                  <Box className={classes.imgBoxNotAvailableStyle}>
                    <ImageOutlinedIcon style={{ fontSize: "52px" }} />
                  </Box>
                </div>
              )}
              <Grid
                container
                direction="row"
                style={{ paddingLeft: "8px", paddingTop: "12px" }}
              >
                <Grid item xs={12}>
                  <Typography
                    style={
                      item?.valid === false
                        ? { fontWeight: "bold", color: "white" }
                        : { fontWeight: "bold" }
                    }
                    align="left"
                    className={classes.titleStyle}
                  >
                    {item?.name}
                  </Typography>
                  <Typography
                    className={classes.boxStyle}
                    style={
                      item?.valid === false
                        ? { textTransform: "lowercase", color: "white" }
                        : { textTransform: "lowercase" }
                    }
                    variant="body2"
                    align="left"
                  >
                    {item?.desc}
                  </Typography>
                </Grid>
                {/* {productVersion !== 1 && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      position: "absolute",
                      bottom: 0,
                      zIndex: 5,
                      right: -5
                    }}
                  >
                    
                  </Grid>
                )} */}
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <div style={{ position: "absolute", bottom: 0, zIndex: 5, right: 0 }}>
          <Box
            style={{
              padding: "5px 0px 0px 5px",
              backgroundColor: theme.palette.background.default,
              borderTop: `1px solid ${theme.palette.background.entity_border}`,
              borderLeft: `1px solid ${theme.palette.background.entity_border}`,
            }}
          >
            <Button
              size={"small"}
              onClick={() =>
                handleOpenProductEditModal(
                  item,
                  wrapper,
                  category,
                  isOpenWrapperEditModal,
                  isRoot,
                )
              }
              className={classes.button}
              style={{
                minWidth: "32px",
                height: "36px",
                backgroundColor: theme.palette.custom.yellow.main,
                marginRight: "5px",
              }}
            >
              <EditIcon
                style={{
                  fontSize: "16px",
                  color: theme.palette.custom.yellow.contrastText,
                }}
              />
            </Button>

            <Button
              size={"small"}
              className={classes.button}
              style={{
                minWidth: "32px",
                height: "36px",
                backgroundColor: theme.palette.custom.orange.main,
                marginRight: "5px",
              }}
              onClick={(event: any) =>
                handleOpenMappingModal(event, category, item, "item")
              }
            >
              <PrintIcon
                style={{
                  fontSize: "16px",
                  color: theme.palette.custom.orange.contrastText,
                }}
              />
            </Button>

            <Button
              size={"small"}
              className={classes.button}
              style={{
                minWidth: "32px",
                height: "36px",
                backgroundColor: theme.palette.custom.blue.main,
                marginRight: "5px",
              }}
            >
              <FileCopyIcon
                style={{
                  fontSize: "16px",
                  color: theme.palette.custom.blue.contrastText,
                }}
              />
            </Button>

            <Button
              size={"small"}
              className={classes.button}
              style={{
                minWidth: "32px",
                height: "36px",
                backgroundColor: item?.valid
                  ? theme.palette.custom.green.main
                  : theme.palette.custom.red.main,
              }}
              onClick={() => handleChangeValidInWrapper(item)}
            >
              {/* VisibilityIcon */}
              {isLoading ? (
                <CircularProgress color="inherit" size={16} />
              ) : item?.valid ? (
                <VisibilityIcon
                  style={{
                    fontSize: "16px",
                    color: theme.palette.custom.red.contrastText,
                  }}
                />
              ) : (
                <VisibilityOffIcon
                  style={{
                    fontSize: "16px",
                    color: theme.palette.custom.red.contrastText,
                  }}
                />
              )}
            </Button>
          </Box>
        </div>
      </div>
    </Slide>
  );
};

export default CategoryMenuList;
