import React, { useEffect, useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import firebase from "firebase";
import { AxiosError } from "axios";

import { getCookie } from "../../../../utils/cookies";
import CardCommon from "../../../../components/card/CardCommon";
import PasswordResetModal from "../../../../components/common/PasswordResetModal";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import AlertDialog from "../../../../components/alerts/AlertDialog";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import ManageApps from "./ManageApps";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContainer: {
    margin: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
  },
  image: {
    width: "100px",
    height: "100px",
    borderRadius: theme.shape.borderRadius,
  },
}));

/**
 * UserAccountDetails Component:
 *
 * This React component represents the user account details page. It displays user information,
 * including the profile picture, username, and email. Users can initiate a password reset,
 * log out, and manage connected apps. The component utilizes Material-UI for styling and
 * incorporates custom components for cards, buttons, and modals.
 *
 * Component Structure:
 * - State variables manage component state, including user details, modal visibility, loading states, and errors.
 * - useEffect retrieves user details from cookies on component mount.
 * - Functions handle password reset, modal actions, and closure of success or error alerts.
 * - JSX structure includes a responsive layout with user information, buttons, and nested components.
 *
 * Note: The component relies on external services like Firebase for authentication,
 * and it utilizes custom utility functions and components for various functionalities.
 */
const UserAccountDetails: React.FunctionComponent = () => {
  const [firebaseUserDetails, setFirebaseUserDetails] = useState<any>();
  const [isOpenPasswordResetModal, setIsOpenPasswordResetModal] =
    useState(false);
  const [email, setEmail] = useState("");
  const [isLoadingPasswordReset, setIsLoadingPasswordReset] = useState(false);
  const [error, setError] = useState("");
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);

  /**
   * useEffect is a React hook used for handling side effects in functional components.
   * In this case, it is used to fetch and set Firebase user details on component mount.
   */
  useEffect(() => {
    // Get the 'firebaseUser' cookie, which should contain serialized user details.
    const firebaseUser: any = getCookie("firebaseUser");
    // Attempt to parse the cookie content into a JavaScript object.
    setFirebaseUserDetails(JSON.parse(firebaseUser));
  }, []);

  /**
   * The handleResetPassword function is responsible for initiating the password reset process.
   */
  const handleResetPassword = () => {
    // Check if the 'email' property exists in 'firebaseUserDetails'.
    if (firebaseUserDetails?.email) {
      // If it exists, set the 'email' state to the user's email address.
      setEmail(firebaseUserDetails.email);

      // Open the password reset modal by setting the state to true.
      setIsOpenPasswordResetModal(true);
    } else {
      // If 'email' property does not exist, set an error message.
      setError("Can't reset your password. User email not available.");
    }
  };

  /**
   * handleConfirmPasswordReset Function:
   * This asynchronous function is responsible for initiating the password reset process
   * by calling the 'sendPasswordResetEmail' method from the Firebase authentication service.
   * It sets the loading state to true during the process and handles success and error scenarios
   * by updating the state variables accordingly. If successful, it closes the password reset modal,
   * opens the success modal, and sets the loading state to false. In case of an error, it sets the
   * error state with the error message and ensures the loading state is set to false.
   */
  const handleConfirmPasswordReset = async () => {
    // Initiate the password reset process, and set loading state to true.
    setIsLoadingPasswordReset(true);
    try {
      const res = await firebase.auth().sendPasswordResetEmail(email);

      // If successful, close the password reset modal, open the success modal, and set loading state to false.
      setIsOpenPasswordResetModal(false);
      setIsOpenSuccessModal(true);
      setIsLoadingPasswordReset(false);
    } catch (error) {
      // If an error occurs, handle it by setting the error state with the error message.
      const err: any = error as AxiosError;
      setError(err.message);

      // Also, set loading state to false in case of an error.
      setIsLoadingPasswordReset(false);
    }
  };

  /**
   * handleCloseSuccessModal Function:
   * This function is responsible for handling the closure of the success modal
   * that appears after a successful password reset. It sets the state variable
   * 'isOpenSuccessModal' to false, effectively hiding the modal from the UI.
   */
  const handleCloseSuccessModal = () => {
    // Set 'isOpenSuccessModal' state to false to hide the success modal.
    setIsOpenSuccessModal(false);
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={2} style={{ maxWidth: "600px" }}>
          <Grid item xs={12}>
            <CardCommon backgroundColor={"entity_background"}>
              <Grid className={classes.cardContainer}>
                <Grid item xs={12}>
                  {firebaseUserDetails?.profilePic && (
                    <img
                      src={firebaseUserDetails?.profilePic}
                      alt="Profile"
                      className={classes.image}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ fontWeight: "bold", marginRight: "16px" }}
                  >
                    User Name:
                  </Typography>
                  <div>
                    <Typography variant="body1">
                      {firebaseUserDetails?.name}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", marginRight: "58px" }}
                    >
                      Email:
                    </Typography>
                    <Typography
                      variant="body1"
                      noWrap
                      style={{ wordWrap: "break-word" }}
                    >
                      {firebaseUserDetails?.email}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <ManageApps />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: "20px", display: "flex" }}
                >
                  <ButtonCommon
                    onClick={handleResetPassword}
                    variant="contained"
                    style={{
                      fontSize: 11,
                      width: "160px",
                      marginRight: "14px",
                    }}
                    color={buttonColors.CANCEL_BUTTON_COLOR}
                  >
                    {"Log Out"}
                  </ButtonCommon>
                  <ButtonCommon
                    onClick={handleResetPassword}
                    variant="contained"
                    style={{
                      fontSize: 11,
                      width: "160px",
                      marginRight: "14px",
                    }}
                    color={buttonColors.UPDATE_BUTTON_COLOR}
                  >
                    {"Password Reset"}
                  </ButtonCommon>
                </Grid>
              </Grid>
            </CardCommon>
          </Grid>
        </Grid>
      </div>

      <PasswordResetModal
        open={isOpenPasswordResetModal}
        email={email}
        setOpen={setIsOpenPasswordResetModal}
        isLoading={isLoadingPasswordReset}
        handleConfirmPasswordReset={handleConfirmPasswordReset}
      />
      <AlertDialog
        open={isOpenSuccessModal}
        confirmAction={handleCloseSuccessModal}
        title="Success"
        desc={"A password reset link has been sent to your email address."}
        severity="success"
        confirmLabel="Ok"
        disableBackdropClick
        color={"secondary"}
      />
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default UserAccountDetails;
