import * as React from "react";
import { getGrantedAuthorities } from "../../utils/authorities";

const getIsWritePermissionGranted = (
  permissionComponent: string,
  grantedAuthorities: Array<string>,
) => {
  const grantedAuthoritiesForComponent = grantedAuthorities.filter(
    (authority) => authority.includes(permissionComponent),
  );
  const isWritePermission = grantedAuthoritiesForComponent.some(
    (authority) =>
      authority.includes("CREATE") ||
      authority.includes("UPDATE") ||
      authority.includes("DELETE"),
  );

  return isWritePermission;
};

/* Checking if the user has authority. Load that page only if there is an authority. */
const withAuthority = (Component: any, permission: string) => (props: any) => {
  const grantedAuthorities = getGrantedAuthorities();
  // console.log(`grantedAuthorities`, grantedAuthorities);
  // console.log(`permission`, permission);

  if (grantedAuthorities.includes(permission)) {
    const [permissionComponent, _] = permission.split(":");
    const isWritePermissionGranted = getIsWritePermissionGranted(
      permissionComponent,
      grantedAuthorities,
    );
    if (isWritePermissionGranted) {
      return <Component {...props} isAuthorized />;
    }
    return <Component {...props} />;
  }
  return null;
};

export default withAuthority;
