import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import _ from "lodash";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { useRouteMatch } from "react-router-dom";
import { createMenuInfo } from "../../../../../services/menuApp/menuService";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../../utils/consts";

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  setMenuName: any;
  menuName: any;
  isMenuNameEmpty: any;
  setIsMenuNameEmpty: any;
  handleCloseMenuAddModal: any;
  isLoadingButton: any;
  setIsLoadingButton: any;
  setSelectedMenuId: any;
  setError: any;
  menuNodeList: any;
  setMenuNodeList: any;
}

const MenuAddModal: React.FunctionComponent<StrategyAddEditModalProps> = ({
  isOpen,
  setIsOpen,
  setMenuName,
  menuName,
  isMenuNameEmpty,
  setIsMenuNameEmpty,
  handleCloseMenuAddModal,
  isLoadingButton,
  setIsLoadingButton,
  setSelectedMenuId,
  setError,
  menuNodeList,
  setMenuNodeList,
}) => {
  const handleDisable = () => {};

  const match: any = useRouteMatch();

  const handleSubmitMenuInfo = async () => {
    setIsLoadingButton(true);

    const body = {
      name: menuName,
      locationId: match.params.locationId,
    };
    if (_.isEmpty(menuName)) {
      setIsMenuNameEmpty(true);
    } else {
      const summaryString = `${menuName} Menu is added`;
      try {
        const res = await createMenuInfo(
          match.params.locationId,
          body,
          "Menus Changed",
          summaryString,
          "menu",
        );
        setSelectedMenuId(res.data.data.id);
        setIsMenuNameEmpty(false);
        const cloneMenuNodeList = _.cloneDeep(menuNodeList);
        cloneMenuNodeList[res.data.data.id] = res.data.data;
        setMenuNodeList(cloneMenuNodeList);
        setIsMenuNameEmpty(false);
        setIsLoadingButton(false);
        setIsOpen(false);
        // getMenuInfo(productVersion);
      } catch (err) {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
        setIsLoadingButton(false);
      }
    }
  };

  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>Add New Menu</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>Name</Typography>
              <TextfieldDefaultNew
                id="sign-password-input"
                name="sign-password-input"
                onChange={(e: any) => {
                  setIsMenuNameEmpty("");
                  setMenuName(e.target.value);
                }}
                value={menuName}
                type="text"
              />
              {isMenuNameEmpty && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please enter name.
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleCloseMenuAddModal}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={handleDisable()}
            isLoadingPage={isLoadingButton}
            color={"green"}
            onClick={handleSubmitMenuInfo}
          >
            {"Create"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default MenuAddModal;
