import { jsPDF } from "jspdf";
import "jspdf-autotable";
import _ from "lodash";
import moment from "moment";
import { ExportPdfColor } from "../../../../../root/theme/exportColor";
import eatprestoLogo from "../../../../../assets/images/logos/logo.png";

/* Create a pdf file */
export const handleDownloadPdf = (
  scapeChange: string,
  columns: any,
  nodes: any,
  filterDetails: any,
) => {
  const doc: any = new jsPDF(scapeChange === "p" ? "p" : "l");
  const totalPagesExp = "{total_pages_count_string}";
  const locationFilter = filterDetails.split("*")[0];
  const pageWidth = doc.internal.pageSize.getWidth();
  const today = moment().format("MMM_Do_YY").toString();

  /* If there is a chart, insert it into the canvasImg variable. */
  let canvasImg;
  const canvas: any = document.getElementById("chart1");
  if (!_.isEmpty(document.getElementById("chart1"))) {
    canvasImg = canvas.toDataURL("image/jpeg", 1.0);
  }

  /* If there is a image, insert it into the img variable. */
  const img = new Image();
  img.src = eatprestoLogo;

  /* Create a pdf file. */
  doc.autoTable({
    columns: columns.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    startY: 54,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 7,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: { fontStyle: "bold", halign: "left" },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColor.OddRowColor],
      textColor: ["#ffffff"],
    },
    tableLineWidth: 0.1,
    // Change footer style.
    willDrawCell: function (data: any) {
      const rows = data.table.body;
      if (data.row.index === rows.length - 1) {
        doc.setFillColor(ExportPdfColor.green);
        doc.setTextColor(255, 255, 255);
      }
    },
    // Add topic and sub topics.
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();
      // Change topic font size
      doc.setFontSize(12);
      doc.text("Presto Express Solutions", data.settings.margin.left, 12);
      // Modify the marginLeft according to the scape.
      if (scapeChange === "l") {
        doc.text("VAT Report", data.settings.margin.left + 244, 12); // title, marginLeft, top
      } else {
        doc.text("VAT Report", data.settings.margin.left + 160, 12);
      }
      // Change main title font size
      doc.setFontSize(16);
      // Insert a topic for the first page only.
      if (doc.internal.getNumberOfPages() === 1) {
        doc.setTextColor(255, 0, 0);
        /* Change the title according to the number of locations.
        If the location size is increased, show presto express. if the location size is the one, show that location. */
        doc.setTextColor("#32363E");
        if (locationFilter.includes(",")) {
          doc.text("Presto Express", pageWidth / 2, 22, {
            align: "center",
          });
        } else {
          doc.text(
            filterDetails
              .split("*")[0]
              .replace("VAT summary report for location/s:", ""),
            pageWidth / 2,
            22,
            {
              align: "center",
            },
          );
        }

        doc.setTextColor(0, 0, 0);
        doc.text("VAT Report", pageWidth / 2, 32, "center");
        doc.setFontSize(10);

        /* Change the sub title according to the number of locations.
        If the location size is increased, show number of locations. if the location size is the one, show that location. */
        let filterLocationsDetails = "";
        if (locationFilter.split(",").length === 1) {
          filterLocationsDetails =
            "VAT Summary Report for " + locationFilter.split(",")[0];
        } else {
          filterLocationsDetails =
            "VAT Summary Report for " +
            locationFilter.split(",").length +
            " Locations";
        }

        doc.text(
          doc.splitTextToSize(filterLocationsDetails, 210 - 15 - 15),
          pageWidth / 2,
          42,
          {
            align: "center",
          },
        );

        /* Set the start date and end date */
        doc.text(
          doc.splitTextToSize(
            "Generated for the period of " + filterDetails.split("*")[1],
            210 - 15 - 15,
          ),
          pageWidth / 2,
          48,
          {
            align: "center",
          },
        );
      }

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }

      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);
      doc.addImage(
        img,
        "JPEG",
        data.settings.margin.left + 144,
        pageHeight - 24,
        40,
        16,
      );
    },
    /* Change the backgroundcolor in the row. */
    didParseCell: function (data: any) {
      const rows = data.table.body;
      if (data.section === "head") {
        if (data.column.dataKey === "Location") {
          data.cell.styles.halign = "left";
        }
      }
      if (data.row.index !== 0) {
        if (data.row.index % 2 === 0) {
          data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
          data.cell.styles.textColor = ["#ffffff"];
        } else {
          data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
          data.cell.styles.textColor = ["#ffffff"];
        }
      }
      if (data.row.index + 1 === rows.length) {
        data.cell.styles.fontStyle = "bold";
      }
    },
    margin: {
      top: 30,
    },
  });
  let finalY = doc.lastAutoTable.finalY + 8;
  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  /* Insert a Chart */
  if (!_.isEmpty(canvasImg)) {
    if (scapeChange === "l") {
      if (finalY > 110) {
        const pageSize = doc.internal.pageSize;
        let pageNumber = "Page " + doc.internal.getNumberOfPages();
        doc.addPage();
        doc.text("Presto Express Solutions", 14, 12);
        doc.text("VAT Report", 250, 12);

        doc.addImage(canvasImg, "JPEG", 14, 20, 266, 84);
        doc.setFontSize(10);
        pageNumber =
          "Page" +
          doc.internal.getNumberOfPages() +
          " of " +
          doc.internal.getNumberOfPages();
        doc.text(pageNumber, 14, pageSize.height - 10);
        if (typeof doc.putTotalPages === "function") {
          doc.putTotalPages(totalPagesExp);
        }
      } else {
        doc.addImage(canvasImg, "JPEG", 14, finalY, 266, 84);
      }
    } else {
      if (finalY > 204) {
        const pageSize = doc.internal.pageSize;
        let pageNumber = "Page " + doc.internal.getNumberOfPages();
        doc.addPage();
        doc.text("Presto Express Solutions", 14, 12);
        doc.text("VAT Report", 166, 12);

        doc.addImage(canvasImg, "JPEG", 14, 20, 179, 84);
        doc.setFontSize(10);
        pageNumber =
          "Page" +
          doc.internal.getNumberOfPages() +
          " of " +
          doc.internal.getNumberOfPages();
        doc.text(pageNumber, 14, pageSize.height - 10);
        if (typeof doc.putTotalPages === "function") {
          doc.putTotalPages(totalPagesExp);
        }
      } else {
        doc.addImage(canvasImg, "JPEG", 14, finalY, 179, 84);
      }
    }
  }
  doc.save(
    `VAT_Transaction_${today}_${Math.floor(
      100000 + Math.random() * 900000,
    )}.pdf`,
  );
};
