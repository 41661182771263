import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import {
  CheckCircle,
  CloseOutlined,
  Error,
  Info,
  Warning,
} from "@material-ui/icons";
import { CustomTheme } from "../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) => ({
  "@global": {
    ".Toastify__toast--success": {
      backgroundColor: theme.palette.custom.green.main, // Use Material-UI's success color
      color: "white", // Use Material-UI's success text color
    },
    ".Toastify__toast--error": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    ".Toastify__toast--info": {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
  },
}));

const Toast: React.FC<{
  message: string;
  type: any;
  loading: boolean;
}> = ({ message, type, loading }) => {
  const classes = useStyles(); // Initialize the custom styles
  // Add a type annotation for classes to specify it's a Record with specific keys
  const classesWithType: Record<string, string> = classes;

  const notify = () => {
    let dismissDelay = 3000; // Auto-dismiss after 3 seconds (adjust as needed)
    if (loading) {
      dismissDelay = 1000000;
    }
    const toastId: any = toast(
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex" }}>
          {type === "success" && <CheckCircle />}
          {type === "error" && <Error />}
          {type === "warning" && <Warning />}
          {loading && (
            <CircularProgress color="inherit" size={16} thickness={4} />
          )}
          <span style={{ marginLeft: "12px" }}>{message}</span>
        </div>
        <div>
          <CloseOutlined onClick={() => toast.dismiss(toastId)} />
        </div>
      </div>,
      {
        autoClose: dismissDelay,
        closeButton: false,
        hideProgressBar: false,
        type: type,
      },
    );

    const progressBar: any = document.querySelector(".progress-bar");
    const timerInterval = 10; // Interval to update the progress bar

    const updateProgressBar = () => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - toastId.createdAt;
      const progress = (elapsedTime / dismissDelay) * 100;
      if (progressBar) {
        progressBar.style.width = `${progress}%`;
      }

      if (progress >= 100) {
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(updateProgressBar, timerInterval);
  };

  useEffect(() => {
    if (type) {
      toast.dismiss();

      notify();
    }
  }, [type]);

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Toast;
