import {
  Box,
  Button,
  Card,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Slide,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PrintIcon from "@material-ui/icons/Print";

import { CustomTheme } from "../../../../types/customTheme";
import { handleImageUrl } from "../../../../utils/ChangePixelImgUrl";
import { useRouteMatch } from "react-router-dom";
import {
  fetchSingleProductInfo,
  updateProductInfo,
} from "../../../../services/menuApp/menuService";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { AxiosError } from "axios";
import { getLocalStore } from "../../../../utils/store/localStore";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    button: {
      position: "relative",
      overflow: "hidden",
      borderBottomRightRadius: "10px",
      // "&:before": {
      //   content: '""',
      //   position: "absolute",
      //   bottom: 0,
      //   right: 0,
      //   borderLeft: "8px solid transparent", // Adjust the border size as needed
      //   borderBottom: `8px solid ${theme.palette.background.default}`, // Adjust the color as needed
      //   width: 0,
      //   borderBottomRightRadius: "8px",
      // },
    },
    imgBoxStyle: {
      margin: "7px",
      marginLeft: "10px",
      borderRadius: "10px",
      height: "120px",
      [theme.breakpoints.down("sm")]: {
        height: "92px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
    colorStripStyle: {
      width: "4px",
      height: "112px",
      borderRadius: "10px",
      marginRight: "12px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
      },
    },
    titleStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",

      [theme.breakpoints.down("sm")]: {
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    imgBoxNotAvailableStyle: {
      marginRight: "7px",
      marginTop: "10px",
      borderRadius: "10px",
      marginBottom: "10px",
      height: "114px",
      width: "114px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
        width: "80px",
      },
      backgroundColor: theme.palette.background.imgBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      objectFit: "cover",
      width: "114px",
      height: "114px",
      [theme.breakpoints.down("sm")]: {
        width: "88px",
        height: "84px",
      },
    },
    boxStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    },
    priceStyle: {
      marginBottom: "4px",
    },
  }),
);

export interface CategoryMenuListProps {
  item: any;
  category: any;
  handleClickLinkedId: any;
  directoryLevel?: any;
  categoryId?: any;
}

/**
 * CategoryMenuListWrapper Component: Category Menu List
 *
 * This component represents a list item within a category menu. It displays information about an item, including its name,
 * image (if available), and description. Users can interact with the item to view more details or perform actions.
 */
const CategoryMenuListWrapper: React.FunctionComponent<
  CategoryMenuListProps
> = ({ item, category, handleClickLinkedId, directoryLevel, categoryId }) => {
  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const match: any = useRouteMatch();

  //   const handleGetSingleProductWrapperValidChange = async (product: any) => {
  //     try {
  //       const res = await fetchSingleProductInfo(
  //         match.params.locationId,
  //         product.id,
  //       );
  //       handleChangeValidInWrapper(product, res.data.data.version);
  //     } catch (err) {
  //       setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
  //       setIsLoading(false);
  //     }
  //   };

  //   const handleChangeValidInWrapper = async (
  //     product: any,
  //     productVersion?: any,
  //   ) => {
  //     setError("");
  //     setIsLoading(true);
  //     const cloneLoad = _.cloneDeep(isLoadProduct);
  //     await cloneLoad.push(product.id);
  //     const body = {
  //       locationId: match.params.locationId,
  //       valid: !product.valid,
  //       id: product.id,
  //       version: productVersion || product.version,
  //     };
  //     try {
  //       let summary = [];

  //       if (!product.valid !== product.valid) {
  //         summary.push(
  //           `Product id ${product.id} valid changed from ${
  //             product.valid
  //           } to ${!product.valid}`,
  //         );
  //       }

  //       const summaryString = summary.join("\\n");

  //       const res = await updateProductInfo(
  //         match.params.locationId,
  //         body,
  //         "Menus Changed",
  //         summaryString,
  //         "menu",
  //       );
  //       if (res.data.status === 409) {
  //         handleGetSingleProductWrapperValidChange(product);
  //       } else {
  //         const res = await getProductWrapperInfo(
  //           menuNode,
  //           categoryNode,
  //           productVersion,
  //           product.id,
  //           cloneLoad,
  //         );
  //         setIsLoading(false);
  //         setSuccess(SUCCESSFULLY_UPDATED);
  //       }
  //     } catch (error) {
  //       const err: any = error as AxiosError;

  //       const errorMessage = err.response ? err.response.data : err.message;

  //       setError(errorMessage.error);
  //       // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
  //       setIsLoading(false);
  //     }
  //   };

  //   useEffect(() => {
  //     if (error) {
  //       const timer: any = setTimeout(() => {
  //         setError("");
  //       }, 3000);
  //       return () => clearTimeout(timer);
  //     }
  //   }, [error]);
  const isDarkMode = getLocalStore("isDarkMode");
  return (
    <>
      <div
        style={{
          position: "relative",
          height: "149px",
          width: "100%",
          zIndex: 0,
          cursor: "pointer",
        }}
        onClick={() =>
          handleClickLinkedId(item, category, directoryLevel, categoryId)
        }
      >
        {/* {Object.values(item).length === 1 && ( */}
        <>
          <Slide
            in={true}
            direction="left"
            mountOnEnter
            unmountOnExit
            timeout={0}
          >
            <Box
              boxShadow={isDarkMode === "true" ? 4 : 0}
              style={{
                position: "absolute",
                left: 0,
                width: "100%",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
                backgroundColor: theme.palette.background.entity_background,
              }}
            >
              <Box
                style={{
                  height: "134px",
                  width: "100%",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_border_menu_entity_background}`,

                  backgroundColor:
                    theme.palette.background.menu_entity_background,
                  transform: "rotate(3deg)",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={9}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    style={{ display: "flex" }}
                  >
                    {item?.img ? (
                      <Box className={classes.imgBoxStyle}>
                        <Box
                          style={{
                            backgroundColor: item.colour,
                          }}
                          className={classes.colorStripStyle}
                        ></Box>
                        <img
                          className={classes.img}
                          src={handleImageUrl(item.img)}
                          alt="not available images"
                          style={{
                            borderRadius: "10px",
                            border: `1px solid ${theme.palette.background.entity_border}`,
                          }}
                        />
                      </Box>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <Box
                          style={{
                            backgroundColor: item.colour,
                            marginLeft: "10px",
                            marginTop: "13px",
                          }}
                          className={classes.colorStripStyle}
                        ></Box>
                        <Box className={classes.imgBoxNotAvailableStyle}>
                          <ImageOutlinedIcon style={{ fontSize: "52px" }} />
                        </Box>
                      </div>
                    )}
                    <Grid
                      item
                      xs
                      container
                      direction="column"
                      style={{ paddingLeft: "8px" }}
                    >
                      <Grid item xs>
                        <Typography
                          style={
                            item?.valid === false
                              ? { fontWeight: "bold", color: "white" }
                              : { fontWeight: "bold" }
                          }
                          align="left"
                          className={classes.titleStyle}
                        >
                          {item?.name}
                        </Typography>
                        <Typography
                          className={classes.boxStyle}
                          style={
                            item?.valid === false
                              ? {
                                  color: "white",
                                  textTransform: "lowercase",
                                }
                              : {
                                  textTransform: "lowercase",
                                }
                          }
                          variant="body2"
                          align="left"
                        >
                          {item?.desc}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Slide>
          <Slide
            in={true}
            direction="left"
            mountOnEnter
            unmountOnExit
            timeout={0}
          >
            <Box
              boxShadow={isDarkMode === "true" ? 4 : 0}
              style={{
                position: "absolute",
                left: 0,
                width: "100%",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
                backgroundColor: theme.palette.background.entity_background,
              }}
            >
              <div
                style={{
                  backgroundColor: theme.palette.background.entity_background,
                  height: "134px",
                  borderRadius: "10px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={9}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    style={{ display: "flex" }}
                  >
                    {item?.img ? (
                      <Box className={classes.imgBoxStyle}>
                        <Box
                          style={{
                            backgroundColor: item.colour,
                          }}
                          className={classes.colorStripStyle}
                        ></Box>
                        <img
                          className={classes.img}
                          src={handleImageUrl(item.img)}
                          alt="not available images"
                          style={{
                            borderRadius: "10px",
                            border: `1px solid ${theme.palette.background.entity_border}`,
                          }}
                        />
                      </Box>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <Box
                          style={{
                            backgroundColor: item.colour,
                            marginLeft: "10px",
                            marginTop: "13px",
                          }}
                          className={classes.colorStripStyle}
                        ></Box>
                        <Box className={classes.imgBoxNotAvailableStyle}>
                          <ImageOutlinedIcon style={{ fontSize: "52px" }} />
                        </Box>
                      </div>
                    )}
                    <Grid
                      item
                      xs
                      container
                      direction="column"
                      style={{ paddingLeft: "8px", paddingTop: "12px" }}
                    >
                      <Grid item xs>
                        <Typography
                          style={
                            item?.valid === false
                              ? { fontWeight: "bold", color: "white" }
                              : { fontWeight: "bold" }
                          }
                          align="left"
                          className={classes.titleStyle}
                        >
                          {item?.name}
                        </Typography>
                        <Typography
                          className={classes.boxStyle}
                          style={
                            item?.valid === false
                              ? {
                                  color: "white",
                                  textTransform: "lowercase",
                                }
                              : {
                                  textTransform: "lowercase",
                                }
                          }
                          variant="body2"
                          align="left"
                        >
                          {item?.desc}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Slide>
        </>
        {/* )} */}
        {/* {item?.wrapper.length >1 && (
              <>
                <Slide
                  in={true}
                  direction="left"
                  mountOnEnter
                  unmountOnExit
                  timeout={0}
                >
                  <Box
                    boxShadow={isDarkMode === "true" ? 4 : 0}
                    style={{
                      position: "absolute",
                      left: 0,
                      width: "100%",
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                      backgroundColor: !item.valid
                        ? theme.palette.custom.red.main
                        : theme.palette.background.entity_background,
                    }}
                  >
                    <Box
                      style={{
                        height: "134px",
                        width: "100%",
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.background.entity_border_menu_entity_background}`,
    
                        backgroundColor: !item.valid
                          ? theme.palette.custom.red.main
                          : isColorChange === item.directoryId
                          ? hexToRgbOpacity(
                              theme.palette.background.menu_entity_background,
                            )
                          : theme.palette.background.menu_entity_background,
                        transform: item.directoryId
                          ? `rotate(${rotation > 3 ? 3 : rotation}deg)`
                          : "rotate(3deg)",
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={9}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          style={{ display: "flex" }}
                        >
                          {item?.img ? (
                            <Box
                              style={{
                                marginLeft: "10px",
                                borderRadius: "10px",
                                height: `${110}px`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                marginTop: "12px",
                              }}
                            >
                              <img
                                className={classes.img}
                                src={handleImageUrl(item.img)}
                                alt="not available images"
                                style={{
                                  borderRadius: "9px",
                                  border: `1px solid ${theme.palette.background.entity_border}`,
                                  height: `${110}px`,
                                  width: "100px",
                                }}
                              />
                            </Box>
                          ) : (
                            <Box
                              className={classes.imgBoxNotAvailableStyle}
                              style={{ height: `${110}px`, marginTop: "10px" }}
                            >
                              <ImageOutlinedIcon style={{ fontSize: `${52}px` }} />
                            </Box>
                          )}
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            style={{ paddingLeft: "8px" }}
                          >
                            <Grid item xs>
                              <Typography
                                style={
                                  item.valid === false
                                    ? { fontWeight: "bold", color: "white" }
                                    : { fontWeight: "bold" }
                                }
                                align="left"
                                className={classes.titleStyle}
                              >
                                {item?.name}
                              </Typography>
                              <Typography
                                className={classes.boxStyle}
                                style={
                                  item.valid === false
                                    ? {
                                        color: "white",
                                        textTransform: "lowercase",
                                      }
                                    : {
                                        textTransform: "lowercase",
                                      }
                                }
                                variant="body2"
                                align="left"
                              >
                                {item?.desc}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "end",
                            paddingRight: "12px",
                          }}
                        ></Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Slide>
                <Slide
                  in={true}
                  direction="left"
                  mountOnEnter
                  unmountOnExit
                  timeout={0}
                >
                  <Box
                    boxShadow={isDarkMode === "true" ? 4 : 0}
                    style={{
                      position: "absolute",
                      left: 0,
                      width: "100%",
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                      backgroundColor: !item.valid
                        ? theme.palette.custom.red.main
                        : theme.palette.background.entity_background,
                    }}
                  >
                    <Box
                      style={{
                        height: "134px",
                        width: "100%",
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.background.entity_border_menu_entity_background}`,
    
                        backgroundColor: !item.wrapper[1].valid
                          ? theme.palette.custom.red.main
                          : isColorChange === item.wrapper[1].directoryId
                          ? hexToRgbOpacity(
                              theme.palette.background.menu_entity_background,
                            )
                          : theme.palette.background.menu_entity_background,
                        transform:
                          isColorChange === item.wrapper[1].directoryId
                            ? `rotate(${rotation > 3 ? 177 : 180 - rotation}deg)`
                            : "rotate(177deg)",
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={9}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          style={{ display: "flex" }}
                        >
                          {item.wrapper[1]?.img ? (
                            <Box
                              style={{
                                marginLeft: "10px",
                                borderRadius: "10px",
                                height: `${110}px`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                marginTop: "12px",
                              }}
                            >
                              <img
                                className={classes.img}
                                src={handleImageUrl(item.wrapper[1].img)}
                                alt="not available images"
                                style={{
                                  borderRadius: "9px",
                                  border: `1px solid ${theme.palette.background.entity_border}`,
                                  height: `${110}px`,
                                  width: "100px",
                                }}
                              />
                            </Box>
                          ) : (
                            <Box
                              className={classes.imgBoxNotAvailableStyle}
                              style={{ height: `${110}px`, marginTop: "10px" }}
                            >
                              <ImageOutlinedIcon style={{ fontSize: `${52}px` }} />
                            </Box>
                          )}
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            style={{ paddingLeft: "8px" }}
                          >
                            <Grid item xs>
                              <Typography
                                style={
                                  item.wrapper[1].valid === false
                                    ? { fontWeight: "bold", color: "white" }
                                    : { fontWeight: "bold" }
                                }
                                align="left"
                                className={classes.titleStyle}
                              >
                                {item.wrapper[1]?.name}
                              </Typography>
                              <Typography
                                className={classes.boxStyle}
                                style={
                                  item.wrapper[1].valid === false
                                    ? {
                                        color: "white",
                                        textTransform: "lowercase",
                                      }
                                    : {
                                        textTransform: "lowercase",
                                      }
                                }
                                variant="body2"
                                align="left"
                              >
                                {item.wrapper[1]?.desc}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "end",
                            paddingRight: "12px",
                          }}
                        ></Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Slide>
                <Slide
                  in={true}
                  direction="left"
                  mountOnEnter
                  unmountOnExit
                  timeout={0}
                >
                  <Box
                    boxShadow={isDarkMode === "true" ? 4 : 0}
                    style={{
                      position: "absolute",
                      left: 0,
                      width: "100%",
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                      backgroundColor: !item.valid
                        ? theme.palette.custom.red.main
                        : theme.palette.background.entity_background,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: !item.valid
                          ? theme.palette.custom.red.main
                          : theme.palette.background.entity_background,
                        height: "134px",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={9}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          style={{ display: "flex" }}
                        >
                          {item?.img ? (
                            <Box
                              style={{
                                marginLeft: "10px",
                                borderRadius: "10px",
                                height: `${114}px`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                marginTop: "10px",
                              }}
                            >
                              <img
                                className={classes.img}
                                src={handleImageUrl(item.img)}
                                alt="not available images"
                                style={{
                                  borderRadius: "9px",
                                  border: `1px solid ${theme.palette.background.entity_border}`,
                                  height: `${114}px`,
                                  width: "114px",
                                }}
                              />
                            </Box>
                          ) : (
                            <Box
                              className={classes.imgBoxNotAvailableStyle}
                              style={{ height: `${114}px`, marginTop: "10px" }}
                            >
                              <ImageOutlinedIcon style={{ fontSize: `${52}px` }} />
                            </Box>
                          )}
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            style={{ paddingLeft: "8px" }}
                          >
                            <Grid item xs>
                              <Typography
                                style={
                                  item.valid === false
                                    ? { fontWeight: "bold", color: "white" }
                                    : { fontWeight: "bold" }
                                }
                                align="left"
                                className={classes.titleStyle}
                              >
                                {item?.name}
                              </Typography>
                              <Typography
                                className={classes.boxStyle}
                                style={
                                  item.valid === false
                                    ? {
                                        color: "white",
                                        textTransform: "lowercase",
                                      }
                                    : {
                                        textTransform: "lowercase",
                                      }
                                }
                                variant="body2"
                                align="left"
                              >
                                {item?.desc}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </Slide>
              </>
            )} */}
      </div>
    </>
  );
};

export default CategoryMenuListWrapper;
