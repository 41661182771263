export const getBrowserName = () => {
    const userAgent = navigator.userAgent;

    const isChrome = /Chrome/.test(userAgent) && !/Edge/.test(userAgent);
    const isFirefox = /Firefox/.test(userAgent);
    const isEdge = /Edge/.test(userAgent);
    const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
    const isOpera = /Opera/.test(userAgent) || /OPR/.test(userAgent);
  
    let browserName = "Unknown Browser";
    if (isChrome) {
      browserName = "Google Chrome";
    } else if (isFirefox) {
      browserName = "Mozilla Firefox";
    } else if (isEdge) {
      browserName = "Microsoft Edge";
    } else if (isSafari) {
      browserName = "Apple Safari";
    } else if (isOpera) {
      browserName = "Opera";
    }

    return browserName
}