import React, { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";
import { Skeleton } from "@material-ui/lab";

import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { CustomTheme } from "../../../../../types/customTheme";
import { createStockOrder } from "../../../../../services/inventory/stockOrder";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../../utils/consts";
import { fetchAllSuppliersInfo } from "../../../../../services/inventory/supplier";
import CardCommon from "../../../../../components/card/CardCommon";
import { CapitalizeFirstLetter } from "../../../../../utils/ReplaceIcon";
import { buttonColors } from "../../../../../utils/enum";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import DialogCommonOverflow from "../../../../../components/dialogs/DialogOverflowContainer";

const useStyles = makeStyles((theme: CustomTheme) => ({
  rowOdd: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_border}`,
  },
  textField: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    borderRadius: "10px",
    border: "none",
    maxWidth: "220px",
    marginRight: "12px",
    backgroundColor: theme.palette.background.entity_highlight_background,
    [`& fieldset`]: {
      borderRadius: "10px",
      border: "none",
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  dialogTitleStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  title: {
   width: "300px",
   marginBottom: "0px",
   [theme.breakpoints.down("xs")]: {
    marginBottom: "8px",
  },
  }
}));

export interface AddStockOrderModalProps {
  isOpenCreateStockOrderModal: any;
  setIsOpenCreateStockOrderModal: any;
  setError: any;
  handleGetStockOrdersListSorting: any;
  handleOpenAddStockOrderItemModal: any;
  setIsLoadingToast: any;
  setToastType: any;
  setToastMessage: any;
  isLoadingToast: any;
}

/**
 * This component, `AddStockOrderModal`, is responsible for rendering a dialog
 * that allows users to create a new stock order by selecting a supplier. It fetches
 * a list of suppliers from the API, displays their information in cards, and lets
 * users select a supplier. Once a supplier is selected, users can proceed to create
 * the stock order. The component utilizes Material-UI components, custom styles,
 * and API calls to achieve its functionality.
 */
const AddStockOrderModal: React.FunctionComponent<AddStockOrderModalProps> = ({
  isOpenCreateStockOrderModal,
  setIsOpenCreateStockOrderModal,
  setError,
  handleGetStockOrdersListSorting,
  handleOpenAddStockOrderItemModal,
  setIsLoadingToast,
  setToastType,
  setToastMessage,
  isLoadingToast,
}) => {
  const [supplierList, setSupplierList] = useState<any>([]);
  const [supplierListInitial, setSupplierListInitial] = useState<any>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [supplierId, setSupplierId] = useState("");

  const match: any = useRouteMatch();

  /**
   * This function handles the creation of a new stock order. It constructs the necessary
   * form data with the selected supplier's ID and other relevant information, then makes
   * an API call to create the stock order. If the creation is successful, it triggers
   * additional actions like opening the modal for adding stock items, removing sorting
   * of stock items list, updating state variables, and displaying success/error messages.
   */
  const handleCreateOrder = async () => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");

    try {
      // Construct the form data for creating a stock order
      const formData = {
        locationId: match.params.locationId,
        supplierId: supplierId,
        status: "draft",
        orderedOn: null,
        deliveredOn: null,
      };

      // Make an API call to create the stock order
      const res = await createStockOrder(match.params.locationId, formData);

      // Trigger actions for successful stock order creation
      // Open modal for adding stock items
      handleOpenAddStockOrderItemModal(
        res.data.data.id, // Pass the created stock order ID
        res.data.data.supplierId.id, // Pass the ID of the supplier associated with the stock order
        res.data.data,
        "",
      );

      // Get stock order list
      handleGetStockOrdersListSorting();
      // Close the create stock order modal
      setIsOpenCreateStockOrderModal(false);

      setIsLoadingToast(false);
      setToastMessage(SUCCESSFULLY_CREATED);
      setToastType("success");
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * This function fetches information about all suppliers for a given location.
   * It makes an API call to retrieve the list of suppliers and updates the component
   * states accordingly. If the API call is successful, the loading state is set to
   * false and the supplier data is updated. If there is an error, the loading state
   * is still set to false, but an error message is displayed.
   */
  const geAllSuppliers = async () => {
    try {
      // Make an API call to fetch information about all suppliers
      const res = await fetchAllSuppliersInfo(match.params.locationId);

      // Update component states with the received data from the API response
      // Set loading state to false
      setIsLoading(false);
      // Set supplier data
      setSupplierList(res.data.data);
      setSupplierListInitial(res.data.data);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * This function handles the selection of a supplier by updating the 'supplierId'
   * state with the chosen supplier's ID. It takes a 'supplierId' as a parameter
   * and updates the state accordingly.
   *
   * @param {string} supplierId - The ID of the selected supplier.
   */
  const handleSelectSupplier = (supplierId: string) => {
    // Update the 'supplierId' state with the chosen supplier's ID
    setSupplierId(supplierId);
  };

  /**
   * This useEffect hook is responsible for fetching all supplier information
   */
  useEffect(() => {
    // Call the 'geAllSuppliers' function to fetch supplier information
    geAllSuppliers();
  }, []);

  const classes = useStyles();

  /**
   * This function generates a grid with placeholder Skeleton components,
   * which are used to display a loading animation when the supplier list is being fetched.
   *
   * @returns JSX elements containing Skeleton components to show loading animation.
   */
  const handleSkeleton = () => {
    return (
      <Grid container>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
      </Grid>
    );
  };

  /**
   * This function generates a grid with a single row containing a Typography component.
   * This is used to display a message when no suppliers are available for the given location.
   *
   * @returns JSX elements containing a Typography component with a message.
   */

  const handleEmptySupplier = () => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            marginTop: "40px",
            marginBottom: "40px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography variant="h5">
            No suppliers exists for given location
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const handleChangeSearch = (value: any) => {
    setSearchName(value);
    if (value) {
      setIsSearch(true);
      if (!_.isEmpty(supplierListInitial)) {
        const filterSupplierList = supplierListInitial.filter((supplier: any) =>
          supplier.name.toLowerCase().includes(value.toLowerCase()),
        );
        setSupplierList(filterSupplierList);
      }
    } else {
      setIsSearch(false);
      setSupplierList(supplierListInitial);
    }
  };

  const handleClearSearch = () => {
    setSupplierList(supplierListInitial);
    setIsSearch(false);
    setSearchName("");
  };

  const theme: CustomTheme = useTheme();

  return (
    <>
      <DialogCommonOverflow
        open={isOpenCreateStockOrderModal}
        setOpen={setIsOpenCreateStockOrderModal}
      >
        <DialogTitle style={{ marginTop: "4px" }}>
          <div className={classes.dialogTitleStyle}>
            <Typography variant="h6" className={classes.title}>
              Create a new order
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <TextField
                classes={{ root: classes.textField }}
                id="outlined-inline-static"
                placeholder="Search Supplier"
                variant="outlined"
                onChange={(e: any) => handleChangeSearch(e.target.value)}
                value={searchName}
                style={{
                  height: "40px",
                  marginTop: "4px",
                  marginBottom: "4px",
                  width: "!00%",
                }}
                fullWidth
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={handleClearSearch}
                      >
                        {isSearch ? <ClearIcon /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ButtonCommon
                disabled={isLoadingToast}
                variant="contained"
                style={{
                  fontSize: 11,
                  width: "120px",
                  marginRight: "12px",
                  height: "40px",
                }}
                color={buttonColors.CANCEL_BUTTON_COLOR}
                onClick={() => setIsOpenCreateStockOrderModal(false)}
              >
                Close
              </ButtonCommon>
              <ButtonCommon
                disabled={_.isEmpty(supplierId) || isLoadingToast}
                variant="contained"
                style={{
                  fontSize: 11,
                  width: "120px",
                  height: "40px",
                }}
                color={buttonColors.CREATE_BUTTON_COLOR}
                onClick={handleCreateOrder}
              >
                Next
              </ButtonCommon>
            </div>
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
          {/* Check if loading is complete */}
          {!isLoading ? (
            <Grid container spacing={2}>
              {!_.isEmpty(supplierList)
                ? // Map through supplier list and render cards
                  supplierList.map((supplier: any) => (
                    <Grid item xs={6} sm={4}>
                      <CardCommon
                        color={supplier.id === supplierId && "green"}
                        backgroundColor={"entity_highlight_background"}
                      >
                        <Button
                          fullWidth
                          style={{
                            display: "block",
                            textTransform: "none",
                            backgroundColor:
                              supplier.id === supplierId
                                ? theme.palette.custom.green.main
                                : theme.palette.background
                                    .entity_highlight_background,
                          }}
                          onClick={() => handleSelectSupplier(supplier.id)}
                        >
                          <Typography
                            align="left"
                            variant="body2"
                            style={{
                              padding: "2px 12px 2px 12px",
                              whiteSpace: "normal",
                              overflowWrap: "break-word",
                              color:
                                supplier.id === supplierId
                                  ? "white"
                                  : "inherit",
                            }}
                          >
                            {CapitalizeFirstLetter(supplier.name)}
                          </Typography>
                          <Typography
                            align="left"
                            variant="body2"
                            style={{
                              padding: "2px 12px 2px 12px",
                              whiteSpace: "normal",
                              overflowWrap: "break-word",
                              color:
                                supplier.id === supplierId
                                  ? "white"
                                  : "inherit",
                            }}
                          >
                            {CapitalizeFirstLetter(supplier.address)}
                          </Typography>
                          <Typography
                            align="left"
                            variant="body2"
                            style={{
                              padding: "2px 12px 2px 12px",
                              whiteSpace: "normal",
                              overflowWrap: "break-word",
                              color:
                                supplier.id === supplierId
                                  ? "white"
                                  : "inherit",
                            }}
                          >
                            {supplier.contactNo}
                          </Typography>
                          <Typography
                            align="left"
                            variant="body2"
                            style={{
                              padding: "2px 12px 2px 12px",
                              whiteSpace: "normal",
                              overflowWrap: "break-word",
                              color:
                                supplier.id === supplierId
                                  ? "white"
                                  : "inherit",
                            }}
                          >
                            {supplier.email}
                          </Typography>
                        </Button>
                      </CardCommon>
                    </Grid>
                  ))
                : // Render message for no suppliers
                  handleEmptySupplier()}
            </Grid>
          ) : (
            // Render skeleton loading UI
            handleSkeleton()
          )}
        </DialogContent>
        <DialogActions style={{ marginBottom: "12px" }}>
          <div style={{ display: "block", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "18px",
              }}
            >
              <ButtonCommon
                disabled={isLoadingToast}
                variant="contained"
                style={{
                  fontSize: 11,
                  width: "120px",
                  marginRight: "12px",
                }}
                color={buttonColors.CANCEL_BUTTON_COLOR}
                onClick={() => setIsOpenCreateStockOrderModal(false)}
              >
                Close
              </ButtonCommon>
              <ButtonCommon
                disabled={_.isEmpty(supplierId) || isLoadingToast}
                variant="contained"
                style={{
                  fontSize: 11,
                  width: "120px",
                }}
                color={buttonColors.CREATE_BUTTON_COLOR}
                onClick={handleCreateOrder}
              >
                Next
              </ButtonCommon>
            </div>
          </div>
        </DialogActions>
      </DialogCommonOverflow>
    </>
  );
};

export default AddStockOrderModal;
