import { axiosClient } from "../client";

const queryString = require("query-string");

const ORDER_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/orders`;



export const fetchAllOrderInfo = (locationId, size, currentPage, shortingMethod, shortingHeaderName, filterData) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
    url: ORDER_INFO_GET_ALL(locationId),
    query: { size: size, page: currentPage, sort: [shortingHeaderName, shortingMethod], where: filterData }}, {sort: false, arrayFormat: 'comma', skipNull: true, skipEmptyString: true},
    
  ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
