import { DialogActions, DialogContent, Grid } from "@material-ui/core";
import React, { useState } from "react";
import _ from "lodash";

import ValidationMessage from "../../../../components/validation/ValidationMessage";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefault from "../../../../components/textField/TextfieldDefault";
import moment from "moment";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";

export interface BusinessInfoProps {
  isOpenInsertPinModal: any;
  setIsOpenInsertPinModal: any;
  setIsValidPin: any;
}

/**
 * InsertPinModal Component:
 * This React component represents a modal dialog for inserting a PIN.
 * It allows users to input a PIN and validates it against today's date appended with "55".
 * If the entered PIN matches the expected format, it triggers a callback to close the modal and set a flag indicating a valid PIN.
 *
 * Components:
 * - DialogCommonDefault: A reusable dialog component for consistent styling and functionality.
 * - TextfieldDefault: A text field component for inputting the PIN.
 * - ValidationMessage: Displays an error message if the entered PIN is invalid.
 * - ButtonCommon: A common button component for triggering the validation process.
 *
 * Props:
 * - isOpenInsertPinModal: Boolean flag indicating whether the modal is open.
 * - setIsOpenInsertPinModal: Callback function to set the state of modal open/close.
 * - setIsValidPin: Callback function to set the flag indicating a valid PIN.
 *
 * State:
 * - pin: State variable to store the entered PIN.
 * - error: State variable to store the error message if the entered PIN is invalid.
 *
 * Functions:
 * - handleValidatePin: Function to validate the entered PIN against today's date.
 */

const InsertPinModal: React.FunctionComponent<BusinessInfoProps> = ({
  isOpenInsertPinModal,
  setIsOpenInsertPinModal,
  setIsValidPin,
}) => {
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");

  /**
   * handleValidatePin Function:
   * This function validates the entered PIN against today's date appended with "55".
   * If the entered PIN matches the expected format, it triggers a callback to close the modal and set a flag indicating a valid PIN.
   * If the entered PIN is invalid, it sets an error message.
   */
  const handleValidatePin = () => {
    // Get the current date in the format "YYYYMMDD"
    const today = moment().format("YYYYMMDD");
    // Create the valid PIN format by appending "55" to the current date
    const validPin = today + "55";
    // Check if the entered PIN matches the valid PIN format
    if (pin === validPin) {
      // If the PIN is valid, close the modal
      setIsOpenInsertPinModal(false);
      // Set the flag indicating a valid PIN
      setIsValidPin(true);
    } else {
      // If the PIN is invalid, set an error message
      setError("Invalid PIN");
    }
  };

  return (
    <DialogCommonDefault
      open={isOpenInsertPinModal}
      setOpen={setIsOpenInsertPinModal}
      isNeedFixedHeight={false}
      maxWidth={"xs"}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: "12px" }}>
            <TextfieldDefaultNew
              id="sign-email-input"
              name="sign-email-input"
              style={{ width: "100%" }}
              label="PIN"
              onChange={(e: any) => {
                setPin(e.target.value);
                setError("");
              }}
              value={pin}
              type="password"
            />
            {error && <ValidationMessage message="Invalid PIN." />}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={handleValidatePin}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "14px",
          }}
          color={buttonColors.CREATE_BUTTON_COLOR}
        >
          Continue
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default InsertPinModal;
