import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
import _ from "lodash";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import CardCommon from "../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../types/customTheme";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import RewardsDetailsNode from "./RewardsDetailsNode";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import ValidationMessage from "../../../../components/validation/ValidationMessage";

const useStyles = makeStyles((theme: any) => createStyles({}));

export interface CategoryMenuListProps {
  isSelectCash: any;
  register: any;
  setIsOpen: any;
  isPointsReset: any;
  setIsPointsReset: any;
  errors: any;
  isLoadingPage: any;
  handleSubmitData: any;
  isEdit: any;
  isOwner: any;
  selectedStrategyNode: any;
}

/**
 * StepLevelThree Component: Category Menu List
 *
 * This component represents a list item within a category menu. It displays information about an item, including its name,
 * image (if available), and description. Users can interact with the item to view more details or perform actions.
 */
const StepLevelThree: React.FunctionComponent<CategoryMenuListProps> = ({
  isSelectCash,
  register,
  setIsOpen,
  isPointsReset,
  setIsPointsReset,
  errors,
  isLoadingPage,
  handleSubmitData,
  isEdit,
  isOwner,
  selectedStrategyNode,
}) => {
  return (
    <>
      <DialogTitle>{isEdit ? "Edit Rewards" : "Add New Rewards"}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: isSelectCash ? "100%" : "80%" }}>
                <Typography style={{fontWeight: "bold"}}>Reward Name</Typography>
                <Typography variant="caption">
                  Provide a name for this reward as it will appear to the
                  customer.
                </Typography>
                <TextfieldDefaultNew
                  disabled={!isOwner}
                  id="title"
                  name="title"
                  type="text"
                  inputRef={register({
                    required: "Reward Name is required",
                  })}
                />
              </div>
              <div>
                {!isSelectCash && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "12px",
                      marginRight: "12px",
                    }}
                  >
                    <FormControlLabel
                      style={{ marginLeft: "0px", marginRight: "0px" }}
                      value="dinein"
                      control={<SwitchCommon />}
                      disabled={!isOwner}
                      label={
                        <Typography variant="caption" color="textSecondary">
                          Points Reset
                        </Typography>
                      }
                      name="dinein"
                      checked={isPointsReset}
                      labelPlacement="bottom"
                      onChange={() => setIsPointsReset(!isPointsReset)}
                    />
                  </div>
                )}
              </div>
            </div>
            {errors.title ? (
              <ValidationMessage message={errors.title.message} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <Typography style={{fontWeight: "bold"}}>Reward Description</Typography>
            <Typography variant="caption">
              Provide a description for this reward as it will appear to the
              customer.
            </Typography>
            <TextfieldDefaultNew
              id="description"
              name="description"
              disabled={!isOwner}
              type="text"
              inputRef={register({
                required: false,
              })}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <Typography style={{fontWeight: "bold"}}>Reward Image</Typography>
            <Typography variant="caption">
              Provide an image for this reward.
            </Typography>
            <TextfieldDefaultNew
              id="img"
              name="img"
              type="text"
              disabled={!isOwner}
              inputRef={register({
                required: false,
              })}
            />
          </Grid>
          {isSelectCash && (
            <Grid item xs={12} style={{ marginTop: "8px" }}>
              {selectedStrategyNode.allocate_type === "points" ? (
                <>
                  <Typography style={{fontWeight: "bold"}}>Points Required for £1 Reward</Typography>
                  <Typography style={{fontWeight: "bold"}} variant="caption">
                    Specify the number of points the customer needs to earn a £1
                    discount. The discount will be calculated based on the total
                    points and this value.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography style={{fontWeight: "bold"}}>Stamps Required for £1 Reward</Typography>
                  <Typography style={{fontWeight: "bold"}} variant="caption">
                    Specify the number of stamps the customer needs to earn a £1
                    discount. The discount will be calculated based on the total
                    stamps and this value.
                  </Typography>
                </>
              )}
              <TextfieldDefaultNew
                id="units_per_cash"
                name="units_per_cash"
                type="number"
                disabled={!isOwner}
                inputRef={register({
                  required: "Units Per Cash is required",
                  min: {
                    value: 0,
                    message: "Units Per Cash cannot be negative",
                  },
                })}
              />
              {errors.units_per_cash ? (
                <ValidationMessage message={errors.units_per_cash.message} />
              ) : (
                ""
              )}
            </Grid>
          )}
          {!isSelectCash && (
            <Grid item xs={12} style={{ marginTop: "8px" }}>
              {selectedStrategyNode.allocate_type === "points" ? (
                <>
                  <Typography style={{fontWeight: "bold"}}>Points Required to Redeem this Offer</Typography>
                  <Typography style={{fontWeight: "bold"}} variant="caption">
                    Specify the number of points required to redeem this offer.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography style={{fontWeight: "bold"}}>Stamps Required to Redeem this Offer</Typography>
                  <Typography style={{fontWeight: "bold"}} variant="caption">
                    Specify the number of stamps required to redeem this offer.
                  </Typography>
                </>
              )}
              <TextfieldDefaultNew
                id="units_to_redeem"
                name="units_to_redeem"
                type="number"
                disabled={!isOwner}
                inputRef={register({
                  min: {
                    value: 0,
                    message: "Units To Redeem cannot be negative",
                  },
                })}
              />
              {errors.units_to_redeem ? (
                <ValidationMessage message={errors.units_to_redeem.message} />
              ) : (
                ""
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: "8px" }}>
        <ButtonCommon
          variant="contained"
          style={{ fontSize: 11, width: "100px", marginRight: 8 }}
          color="yellow"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </ButtonCommon>
        <ButtonCommon
          isLoadingPage={isLoadingPage}
          variant="contained"
          style={{
            fontSize: 11,
            marginLeft: 4,
            width: "100px",
          }}
          color={isSelectCash ? "orange" : "green"}
          onClick={handleSubmitData}
        >
          {isSelectCash ? "Save" : "Next"}
        </ButtonCommon>
      </DialogActions>
    </>
  );
};

export default StepLevelThree;
