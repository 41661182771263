export enum DeliveryTpe {
  DINEIN = "dinein",
  DELIVERY = "delivery",
  TAKEOUT = "takeout",
}

export enum HttpStatus {
  CONFLICT_409 = 409,
}

export enum buttonColors {
  CREATE_BUTTON_COLOR = "green",
  UPDATE_BUTTON_COLOR = "yellow",
  CANCEL_BUTTON_COLOR = "orange",
  DELETE_BUTTON_COLOR = "red",
}

export enum submitButtonName {
  CREATE_SUBMIT_BUTTON = "Create new",
  UPDATE_SUBMIT_BUTTON = "Save changes",
}
