import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, useTheme } from "@material-ui/core";
import _ from "lodash";
import { useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import { paymentFilterObject } from "../../../../utils/consts/list";
import { CustomTheme } from "../../../../types/customTheme";
import SaleReport from "../../../../components/common/SaleReport";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import DailySaleReportInfoNode from "./DailySaleReportInfoNode";
import { fetchAllDailySaleReportInfo } from "../../../../services/salesApp/dailySaleReportService";
import {
  convertDateFormat,
  convertDateToMoment,
  getEndDateDateMonthYear,
  getStartDateDateMonthYear,
} from "../../../../utils/ConvertDateTimeFormat";

export interface DailySaleReportProps {}

type DailySaleReport = {
  orders: number;
  subTotal: number;
  discount: number;
  total: number;
  cash: number;
  card: number;
  otherPayment: number;
  average: number;
  min: number;
  max: number;
};

type DailySaleObject = {
  date: string;
  orders: number;
  subTotal: number;
  discount: number;
  total: number;
  cash: number;
  card: number;
  otherPayment: number;
  average: number;
  min: number;
  max: number;
};

/**
 * Component for rendering the Daily Sale Report.
 * This component fetches and displays daily sale report data based on the selected location and date range.
 */
const DailySaleReport: React.FunctionComponent<DailySaleReportProps> = () => {
  const [dailySaleReportNodeList, setDailySaleReportNodeList] = useState<any>(
    [],
  );
  const [previousDailySaleReportNodeList, setPreviousDailySaleReportNodeList] =
    useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [diffDate, setDiffDate] = useState(0);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [previousStartDate, setPreviousStartDate] = React.useState<any>(
    moment().subtract(13, "d").format("YYYY-MM-DD"),
  );
  const [previousEndDate, setPreviousEndDate] = React.useState<any>(
    moment().subtract(7, "d").format("YYYY-MM-DD"),
  );
  const [currentStartDate, setCurrentStartDate] = React.useState<any>(
    moment().subtract(6, "d").format("YYYY-MM-DD"),
  );
  const [currentEndDate, setCurrentEndDate] = React.useState<any>(
    moment().format("YYYY-MM-DD"),
  );

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /**
   * This function takes in a date filter and a daily sale report object and returns an array
   * of daily sale objects within the date range of the filter.
   * @param {string} filterData - A date filter string in the format of "date>=start_date,date<=end_date".
   * @param {object} dailySaleReportObject - An object containing daily sale reports.
   * @returns {array} An array of daily sale objects.
   */
  const handleDailyReportNotIncludeDate = (
    filterData: string,
    dailySaleReportObject: { [key: string]: DailySaleReport },
  ): DailySaleObject[] => {
    // Check if the filter data matches the expected format, if not, return an empty array.
    const matchResult = filterData.match(/date>=([^,]*),date<=([^,]*)/);
    if (!matchResult) {
      return [];
    }

    // Extract start and end date strings from the filter data match result.
    const [startDateString, endDateString] = matchResult.slice(1);

    // Create start and end date objects from the date strings.
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Create an array to store the updated daily sale objects.
    const updatedDailySale: DailySaleObject[] = [];
    let min = 10000000;
    let max = 0;

    // Iterate over the date range from end date to start date, and create daily sale objects for each date.
    for (
      let date = endDate;
      date >= startDate;
      date.setDate(date.getDate() - 1)
    ) {
      const dateString = moment(date).format("YYYY-MM-DD");

      // Get the daily sale report for the current date.
      const dailySaleReport = dailySaleReportObject[dateString];
      if (dailySaleReport) {
        if (min > dailySaleReport.min) {
          min = dailySaleReport.min;
        }
        if (max < dailySaleReport.max) {
          max = dailySaleReport.max;
        }
      }
      // Create a daily sale object with the necessary fields for the current date.
      const dailySaleObject: DailySaleObject = {
        date: convertDateFormat(dateString),
        orders: dailySaleReport ? dailySaleReport.orders : 0,
        subTotal: dailySaleReport ? dailySaleReport.subTotal : 0,
        discount: dailySaleReport ? dailySaleReport.discount : 0,
        total: dailySaleReport ? dailySaleReport.total : 0,
        cash: dailySaleReport ? dailySaleReport.cash : 0,
        card: dailySaleReport ? dailySaleReport.card : 0,
        otherPayment: dailySaleReport ? dailySaleReport.otherPayment : 0,
        average: dailySaleReport ? dailySaleReport.average : 0,
        min: dailySaleReport ? dailySaleReport.min : 0,
        max: dailySaleReport ? dailySaleReport.max : 0,
      };

      // Add the daily sale object to the updated daily sale array.
      updatedDailySale.push(dailySaleObject);
    }
    setMin(min);
    setMax(max);
    // Return the updated daily sale array.
    return updatedDailySale;
  };

  /**
   * This function calculates the difference in days between the current date range and
   * he previous date range, when the previous date range does not include the current end date.
   */
  const handlePreviousDailyReportNotIncludeDate = () => {
    // Create new date objects from the current and previous start and end dates
    const currentStartDateMoment = new Date(currentStartDate);
    const currentEndDateMoment = new Date(currentEndDate);
    const previousEndDateMoment = new Date(previousEndDate);
    const previousStartDateMoment = new Date(previousStartDate);

    // Calculate the difference in days between the current start and end dates
    const differenceInTimeCurrentStartDateAndCurrentEndDate =
      currentEndDateMoment.getTime() - currentStartDateMoment.getTime();
    const differenceInCurrentStartDateAndCurrentEndDate =
      differenceInTimeCurrentStartDateAndCurrentEndDate / (1000 * 3600 * 24);

    // Calculate the difference in days between the previous start and end dates
    const differenceInTimePreviousStartDateAndPreviousEndDate =
      previousEndDateMoment.getTime() - previousStartDateMoment.getTime();
    const differenceInPreviousStartDateAndPreviousEndDate =
      differenceInTimePreviousStartDateAndPreviousEndDate / (1000 * 3600 * 24);

    // Calculate the difference in days between the current start date and previous end date
    const differenceInTimeCurrentStartDateAndPreviousEndDate =
      currentStartDateMoment.getTime() - previousEndDateMoment.getTime();
    const differenceInCurrentStartDateAndPreviousEndDate =
      differenceInTimeCurrentStartDateAndPreviousEndDate / (1000 * 3600 * 24);

    // Initialize a variable to hold the final difference in dates
    let differenceDates = 0;

    // Check if the current start and end dates fall within the current month
    if (
      convertDateToMoment(currentStartDate) ===
        getStartDateDateMonthYear(
          new Date(
            moment(currentStartDate).startOf("month").format("YYYY-MM-DD"),
          ),
          "month",
        ) &&
      convertDateToMoment(currentEndDate) ===
        getEndDateDateMonthYear(
          new Date(
            moment(currentStartDate).startOf("month").format("YYYY-MM-DD"),
          ),
          "month",
        )
    ) {
      // If the previous range is longer than the current range, add the additional days to the difference
      if (
        differenceInPreviousStartDateAndPreviousEndDate >
        differenceInCurrentStartDateAndCurrentEndDate
      ) {
        differenceDates =
          differenceInCurrentStartDateAndCurrentEndDate +
          differenceInCurrentStartDateAndPreviousEndDate +
          (differenceInPreviousStartDateAndPreviousEndDate -
            differenceInCurrentStartDateAndCurrentEndDate);
      } else if (
        differenceInPreviousStartDateAndPreviousEndDate <
        differenceInCurrentStartDateAndCurrentEndDate
      ) {
        // If the previous range is shorter than the current range, subtract the overlapping days from the difference
        differenceDates =
          differenceInCurrentStartDateAndCurrentEndDate +
          differenceInCurrentStartDateAndPreviousEndDate -
          (differenceInCurrentStartDateAndCurrentEndDate -
            differenceInPreviousStartDateAndPreviousEndDate);
      } else {
        // If the current range and previous range have the same number of days, add 1 day to the difference
        differenceDates = differenceInCurrentStartDateAndCurrentEndDate + 1;
      }
    } else {
      // If the current start and end dates do not fall within the current month
      if (
        differenceInPreviousStartDateAndPreviousEndDate >
        differenceInCurrentStartDateAndCurrentEndDate
      ) {
        // If the previous range is longer than the current range, add 1 day to the difference
        differenceDates =
          differenceInCurrentStartDateAndCurrentEndDate +
          differenceInCurrentStartDateAndPreviousEndDate +
          1;
      } else if (
        differenceInCurrentStartDateAndCurrentEndDate <
        differenceInCurrentStartDateAndPreviousEndDate
      ) {
        // If the previous range is shorter than the current range, do not add any additional days to the difference
        differenceDates =
          differenceInCurrentStartDateAndCurrentEndDate +
          differenceInCurrentStartDateAndPreviousEndDate;
      } else {
        // If the previous date range is the same length, add 1 day to the difference
        differenceDates = differenceInCurrentStartDateAndCurrentEndDate + 1;
      }
    }

    setDiffDate(differenceDates);
  };

  /** This function retrieves the daily sales report data and
   * updates the state of the component with the updated report
   * The function accepts two parameters: filter and
   * basedOnShift which are used as query parameters when fetching the data
   */
  const getDailySaleReportInfo = async (filter: any, basedOnShift: boolean) => {
    try {
      // This function calls the fetchAllDailySaleReportInfo API to retrieve the data
      const res = await fetchAllDailySaleReportInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );

      // If the response data is not empty, the function calls the handleDailyReportNotIncludeDate function to process the data
      if (!_.isEmpty(res.data)) {
        const updatedDailySale = handleDailyReportNotIncludeDate(
          filter,
          res.data,
        );

        // The updated daily sale data is set to the dailySaleReportNodeList state
        setDailySaleReportNodeList(updatedDailySale);
      } else {
        // If the response data is empty, the dailySaleReportNodeList state is set to an empty array
        setDailySaleReportNodeList([]);
      }

      // The setIsLoading state is set to false to stop the loading spinner
      setIsLoading(false);
    } catch (err) {
      // If an error occurs, the setIsLoading state is set to false and the setError state is set to an error message
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /** This function retrieves the daily sales report data and
   * updates the state of the component with the updated report
   * The function accepts two parameters: filter and
   * basedOnShift which are used as query parameters when fetching the data
   */
  const getDailySaleReportPreviousInfo = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      // This function calls the fetchAllDailySaleReportInfo API to retrieve the data
      const res = await fetchAllDailySaleReportInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );

      // If the response data is not empty, the function calls the handleDailyReportNotIncludeDate function to process the data
      handlePreviousDailyReportNotIncludeDate();
      setPreviousDailySaleReportNodeList(res.data);
      // The setIsLoading state is set to false to stop the loading spinner
    } catch (err) {
      // If an error occurs, the setIsLoading state is set to false and the setError state is set to an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /** A function that fetches all filter locations based on searchName and updates the state
   * of locationList, locationData, locationSelectorList and isGetLocationInfo based on the response
   * */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) => {
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            });
          });
          const locationName = getFilterListFromArrayObject(
            locationList,
            match.params.locationId,
          );
        }
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    document.title = "Sale - Daily Sales Report";
    getAllFilterLocation("");
  }, []);

  // Get filter data
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    setIsLoading(true);
    getDailySaleReportInfo(filterData, basedOnShift);

    setFilterData(filterData);
    setBasedOnShift(basedOnShift);
  };

  const handlePreviousFilterData = (filterData: any, basedOnShift: boolean) => {
    getDailySaleReportPreviousInfo(filterData, basedOnShift);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  const theme: CustomTheme = useTheme();

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={paymentFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={dailySaleReportNodeList}
          filterDetails={filterDetails}
          title={""}
          isChart={true}
          filterTitle="Daily Sale summary report for"
          topic="Daily Sales Report"
          isNeedFilterDetails={true}
          getPreviousFilterInfo={handlePreviousFilterData}
          setPreviousStartDate={setPreviousStartDate}
          setPreviousEndDate={setPreviousEndDate}
          setCurrentStartDate={setCurrentStartDate}
          setCurrentEndDate={setCurrentEndDate}
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          isDailySale={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
          isWeekDateRange={true}
        >
          <DailySaleReportInfoNode
            dailySaleReportNodeList={dailySaleReportNodeList}
            filterDetails={filterDetails}
            min={min}
            max={max}
            previousDailySaleReportNodeList={previousDailySaleReportNodeList}
            diffDate={diffDate}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
          />
        </SaleReport>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(DailySaleReport, Authorities.SALE_READ);
