import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      height: 48,
      width: "100%",
      backgroundColor: "#000",
      opacity: 0.2,
    },
    button: {
        borderRadius: "4px 0 0 4px",
        backgroundColor: "#202020",
        opacity: 0.6,
        width: "50%",
        height: 48
    },
  }),
);

export interface ShapeToggleButtonProps {

}

/* Preview box */
const ShapeToggleButton: React.FunctionComponent<ShapeToggleButtonProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.button}></div>
    </div>
  );
};

export default ShapeToggleButton;
