import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import Authorities from "../../../../../auth/authorities";
import withAuthority from "../../../../../components/Auth/withAuthority";
import ButtonContained from "../../../../../components/buttons/ButtonContained";
import InputBoxDefault from "../../../../../components/common/InputBoxDefault";
import ValidationMessage from "../../../../../components/validation/ValidationMessage";
import { buttonColors, submitButtonName } from "../../../../../utils/enum";

export interface BusinessInfoProps {
  locationData: any;
  handleChange: any;
  register: any;
  errors: any;
  isAuthorized?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginTop: "10px",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0px",
      },
    },
  }),
);

/* Show the business information. */
const BusinessInfo: React.FunctionComponent<BusinessInfoProps> = ({
  locationData,
  handleChange,
  register,
  errors,
  isAuthorized,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Grid
        container
        spacing={2}
        style={{
          paddingTop: 8,
        }}
      >
        {" "}
        <Grid item xs={12} sm={6}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <InputBoxDefault
                label={"Business display name"}
                handleChange={handleChange}
                value={locationData.businessDisplayName}
                name={"businessDisplayName"}
                id={"businessDisplayName"}
                register={register({ required: true })}
                errors={errors.businessDisplayName}
                disabled={!isAuthorized}
              />
              <ValidationMessage
                message={
                  errors.businessDisplayName &&
                  "Please enter business display name"
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputBoxDefault
                label={"Business registration name"}
                handleChange={handleChange}
                value={locationData.businessRegName}
                name={"businessRegName"}
                id={"businessRegName"}
                disabled={!isAuthorized}
                register={register({
                  required: false,
                })}
                errors={errors.businessRegName}
              />
              <ValidationMessage
                message={
                  errors.businessRegName &&
                  "Please enter a valid business registration name"
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputBoxDefault
                disabled={!isAuthorized}
                label={"Business business trading name"}
                handleChange={handleChange}
                value={locationData.businessTradingName}
                name={"businessTradingName"}
                id={"businessTradingName"}
                register={register({ required: false })}
                errors={errors.businessTradingName}
              />
              <ValidationMessage
                message={
                  errors.businessTradingName &&
                  "Please enter business trading name"
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputBoxDefault
                label={"Business registration name"}
                handleChange={handleChange}
                value={locationData.businessRegName}
                name={"businessRegName"}
                id={"businessRegName"}
                disabled={!isAuthorized}
                register={register({
                  required: false,
                })}
                errors={errors.businessRegName}
              />
              <ValidationMessage
                message={
                  errors.businessRegName &&
                  "Please enter a valid business registration name"
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputBoxDefault
                disabled={!isAuthorized}
                label={"Location identifier"}
                handleChange={handleChange}
                value={locationData.locationIdentifier}
                name={"locationIdentifier"}
                id={"locationIdentifier"}
                register={register({ required: true })}
                errors={errors.locationIdentifier}
              />
              <ValidationMessage
                message={
                  errors.locationIdentifier &&
                  "Please enter location identifier"
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <InputBoxDefault
                disabled={!isAuthorized}
                label={"Description"}
                handleChange={handleChange}
                value={locationData.description}
                name={"description"}
                id={"description"}
                register={register({ required: false })}
                errors={errors.description}
                multiline={true}
                rows={7}
              />
              <ValidationMessage
                message={errors.description && "Please enter description"}
              />
            </Grid>
            <Grid item xs={12} className={classes.margin}>
              <InputBoxDefault
                disabled={!isAuthorized}
                label={"Terms"}
                handleChange={handleChange}
                value={locationData.terms}
                name={"terms"}
                id={"terms"}
                register={register({ required: false })}
                errors={errors.terms}
                multiline={true}
                rows={7}
              />
              <ValidationMessage
                message={errors.terms && "Please enter terms"}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={6}>
          <MyEditor />
        </Grid> */}
      </Grid>

      {isAuthorized && (
        <div style={{ marginTop: 24, display: "flex" }}>
          <ButtonContained
            color={buttonColors.UPDATE_BUTTON_COLOR}
            label={submitButtonName.UPDATE_SUBMIT_BUTTON}
            type={"submit"}
          />
        </div>
      )}
    </div>
  );
};

export default withAuthority(BusinessInfo, Authorities.BASIC_INFO_READ);
