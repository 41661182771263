import { CircularProgress, CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { GlobalContext } from "../../root/App";
import MenuAppRoutes, { getSidebarRoutes } from "./routes/MenuAppRoutes";
import queryString from "query-string";
import { initTheme } from "./theme/primary";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getCookie } from "../../utils/cookies";
import { fetchAllLocations } from "../../services/locationApp/locationService";
import { getFilterListFromArrayObject } from "../../utils/commonArrayMap";
import { handleChangeRestaurant } from "../../utils";
import { MENU_QUERY_PARAMS } from "../../utils/consts";
import firebase from "firebase";
import { createCustomToken } from "../../services/globalService";
import { verifyAuthTokenWithServer } from "../../services/accountApp/authService";

export interface PrestoExpressMenuAppProps {}

const PrestoExpressMenuApp: React.FunctionComponent<
  PrestoExpressMenuAppProps
> = () => {
  const [sidebarRoutes, setSidebarRoutes] = React.useState<any>([]);
  const { globalState } = useContext(GlobalContext);
  const theme = initTheme({ isDark: globalState.isDarkMode });
  const match: any = useRouteMatch();

  /**
   * Asynchronous function to fetch location information based on the locationId from the server.
   * It utilizes cookies, API calls, and utility functions to update relevant parts of the application state.
   */
  const getLocationInfo = async () => {
    try {
      // Retrieve the idToken from cookies
      const idToken: any = getCookie("idToken");

      // Fetch all locations using the idToken
      const res = await fetchAllLocations(idToken);

      // Filter the location data based on the provided locationId
      const location = getFilterListFromArrayObject(
        res.data.data,
        match.params.locationId,
      );

      // Update the restaurant information based on the fetched location
      handleChangeRestaurant(location[0]);

      // Update the sidebar based on the new location information
      handleChangeSidebar();
    } catch (error) {}
  };

  /**
   * Function to handle changes in the sidebar. It fetches the sidebar routes
   * using the utility function getSidebarRoutes and updates the state with the new routes.
   */
  const handleChangeSidebar = () => {
    // Fetch the sidebar routes using the utility function
    const sidebar = getSidebarRoutes();

    // Update the state with the new sidebar routes
    setSidebarRoutes(sidebar);
  };

  const tokens = queryString.parse(window.location.search).t;
  /**
   * useEffect hook to fetch location information when the component mounts.
   * It calls the getLocationInfo function, which retrieves location data
   * based on the locationId parameter from the URL, and updates the restaurant
   * and sidebar accordingly.
   */
  React.useEffect(() => {
    // Call the function to fetch location information
    if (!tokens) {
      getLocationInfo();
    }
  }, []);

  /**
   * Extracts the 'app' parameter from the query string in the window's location.
   * Uses the queryString library to parse the query string and retrieve the 'app' value.
   *
   * @constant {string} selectedApp - The value of the 'app' parameter from the query string.
   */
  const selectedApp = queryString.parse(window.location.search).app;
  const isAppEmbedded = queryString.parse(window.location.search).isAppEmbedded;
  const [isLoad, setIsLoad] = useState(false);

  const history = useHistory();

  function removeQueryParam(url: any, param: any) {
    var urlParts = url.split("?");
    if (urlParts.length >= 2) {
      var prefix = encodeURIComponent(param) + "=";
      var queryParams = urlParts[1].split(/[&;]/g);

      // Iterate through query parameters
      for (var i = queryParams.length; i-- > 0; ) {
        // Remove param if found
        if (queryParams[i].lastIndexOf(prefix, 0) !== -1) {
          queryParams.splice(i, 1);
        }
      }

      // Reconstruct the URL
      url =
        urlParts[0] +
        (queryParams.length > 0 ? "?" + queryParams.join("&") : "");
    }
    return url;
  }

  const handleRedirect = async () => {
    let params = new URLSearchParams(window.location.search);

    // Delete the foo parameter.
    params.delete("t");

    // now join the query param and host
    var outputUrl = removeQueryParam(window.location.href, "t");

    const interval = setInterval(() => {
      const idToken = getCookie("idToken");
      if (idToken) {
        setIsLoad(true);
        window.location.href = outputUrl;
      }
    }, 500);

    return () => clearInterval(interval);
  };

  const handleLogin = (firebaseUser: any) => {
    firebaseUser.getIdToken(true).then((idToken: any) => {
      // Get the login details
      verifyAuthTokenWithServer(
        idToken,
        firebaseUser,
        firebaseUser.refreshToken,
      );
      handleRedirect();
    });
  };

  const handleAuthentication = async () => {
    try {
      const authFirebase = firebase.auth();

      const token = queryString.parse(window.location.search).t;
      const res = await createCustomToken(token);
      const customToken = res.data.customToken;
      const userCredential = await authFirebase.signInWithCustomToken(
        customToken,
      );
      if (userCredential) {
        handleLogin(userCredential);
      } else {
      }
    } catch {}
  };
  const token = queryString.parse(window.location.search).t;

  useEffect(() => {
    if (isAppEmbedded === "true") {
      if (token) {
        const onAuthStateChange = async () => {
          const authFirebase = firebase.auth();
          authFirebase.onAuthStateChanged((firebaseUser: any) => {
            if (firebaseUser) {
              handleLogin(firebaseUser);
            } else {
              handleAuthentication();
            }
          });
        };
        onAuthStateChange();
      } else {
        setIsLoad(true);
      }
    } else {
      setIsLoad(true);
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PrimaryLayout
        sidebarRoutes={sidebarRoutes}
        handleChangeSidebar={handleChangeSidebar}
      >
        {!isLoad ? (
          <div style={{ marginTop: "16%" }}>
            <CircularProgress color="secondary" size={50} />
          </div>
        ) : (
          (sidebarRoutes.length > 0 ||
            selectedApp === MENU_QUERY_PARAMS ||
            token) &&
          (isAppEmbedded === "true" ? (
            <div style={{ margin: "0px 20px" }}>
              <MenuAppRoutes />
            </div>
          ) : (
            <MenuAppRoutes />
          ))
        )}
      </PrimaryLayout>
    </ThemeProvider>
  );
};

export default PrestoExpressMenuApp;
