import React, { useEffect, useState } from "react";
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import { DISCOUNT_TYPE_AMOUNT } from "../../../../utils/consts";
import WithLoading from "../../../../utils/WithLoading";
import CreateNewNode from "./CreateNewNode";
import ClosePeriodInfoNode from "./ClosePeriodInfoNode";
import { useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    discountTypeSection: {
      borderRadius: 8,
    },
  }),
);

export interface ClosePeriodLoadingProps {
  nodes: Array<any>;
  getClosePeriodInfo: any;
  isLoading: boolean;
}

/**
 * ClosePeriodLoading Component:
 *
 * This component serves as a loading wrapper for managing and displaying close period information.
 * It renders a list of close period nodes along with the option to create new close periods.
 */
const ClosePeriodLoading: React.FunctionComponent<ClosePeriodLoadingProps> = ({
  nodes,
  getClosePeriodInfo,
  isLoading,
}) => {
  const classes = useStyles();
  const [closePeriodNode, setClosePeriodNode] = useState<Array<any>>([]);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  /* Data allocation for 2 tabs. (percentage, amount) */
  useEffect(() => {
    /* Objects with similar types, combine all those similar types into a single object list. */
    setClosePeriodNode(nodes);
  }, [nodes]);

  /* When creating new discount information, 
  the basic information required for that is entered into the state. */
  const createNode = (nodes: any) => {
    setClosePeriodNode([...closePeriodNode, { ...nodes }]);
  };
  const match: any = useRouteMatch();
  /* Used to create a new close period */
  const handleCreateNode = () => {
    // Close period initial information
    const nodeData = {
      newId: uuidv4(),
      status: "create",
      locationId: match.params.locationId,
      title: "",
      fromDate:
        moment(new Date()).startOf("day").format("YYYY-MM-DD HH:mm:ss") +
        ".000",
      toDate:
        moment(new Date()).endOf("day").format("YYYY-MM-DD HH:mm:ss") + ".000",
      isActive: true,
      version: 0,
    };
    createNode(nodeData);
  };

  /* Remove discount information before updating the backend. The status is used for that. 
  If the status is "create", it will be removed from the state. */
  const handleRemoveNode = (type: string, uuid: string) => {
    setClosePeriodNode(
      closePeriodNode.filter((node: any) => node.newId !== uuid),
    );
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.discountTypeSection}>
          <Typography
            variant="h4"
            component="div"
            align="left"
            style={{ margin: 4 }}
          />
          <Grid
            container
            spacing={2}
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {!_.isEmpty(closePeriodNode) &&
              closePeriodNode.map((nodeData: any) => (
                <Grid
                  item
                  sm={12}
                  md={6}
                  xs={12}
                  lg={4}
                  key={nodeData.id}
                  style={{ display: "flex" }}
                >
                  <ClosePeriodInfoNode
                    nodeData={nodeData}
                    getClosePeriodInfo={getClosePeriodInfo}
                    handleRemoveNode={handleRemoveNode}
                    type={DISCOUNT_TYPE_AMOUNT}
                    openDeleteConfirm={openDeleteConfirm}
                    setOpenDeleteConfirm={setOpenDeleteConfirm}
                  />
                </Grid>
              ))}
            <CreateNewNode handleCreateNode={handleCreateNode} />
          </Grid>
        </div>
      </div>
    </>
  );
};

export default WithLoading(ClosePeriodLoading);
