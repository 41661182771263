import React, { useCallback, useEffect, useState } from "react";
import ColorSelection from "../ColorSelection";
import PreviewBox from "../SectionBox/PreviewBox";
import SectionBoxWrapper from "../SectionBox/SectionBoxWrapper";
import PreviewBasket from "./PreviewBasket";

export interface SectionBoxBasketProps {
  themeConfig: any;
  onSubmit: any;
}

/* Update Basket information and users can view all basket information using card. */
const SectionBoxBasket: React.FunctionComponent<SectionBoxBasketProps> = ({
  themeConfig,
  onSubmit,
}) => {
  const [basketBackgroundColor, setBasketBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [buttonColor, setButtonColor] = useState("");
  const [buttonTextColor, setButtonTextColor] = useState("");

  /* Update states initial load and after update basket information. */
  const setInitialData = useCallback(() => {
    if(basketBackgroundColor) return;
    const {
      primaryColor,
      primaryTextColor,
      secondaryColor,
      secondaryTextColor,
    } = themeConfig;
    setBasketBackgroundColor(primaryColor);
    setTextColor(primaryTextColor);
    setButtonColor(secondaryColor);
    setButtonTextColor(secondaryTextColor);
  }, [basketBackgroundColor, themeConfig]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  /* Send an API call to update basket information after click save button. */
  const handleSave = () => {
    onSubmit({
      primaryColor: basketBackgroundColor,
      primaryTextColor: textColor,
      secondaryColor: buttonColor,
      secondaryTextColor: buttonTextColor,
    });
  };

  return (
    <>
      <SectionBoxWrapper
        sectionTitle={"Basket"}
        onSubmit={handleSave}
        onCancel={setInitialData}
        colorChangeSection={
          <>
            <ColorSelection
              title={"Background Color"}
              colorCode={basketBackgroundColor}
              setColor={(color: any) => setBasketBackgroundColor(color.hex)}
              setInitialData={() =>
                setBasketBackgroundColor(themeConfig.primaryColor)
              }
            />
            <ColorSelection
              title={"Text Color"}
              colorCode={textColor}
              setColor={(color: any) => setTextColor(color.hex)}
              setInitialData={() => setTextColor(themeConfig.primaryTextColor)}
            />
            <ColorSelection
              title={"Button Color"}
              colorCode={buttonColor}
              setColor={(color: any) => setButtonColor(color.hex)}
              setInitialData={() => setButtonColor(themeConfig.secondaryColor)}
            />
            <ColorSelection
              title={"Button Text Color"}
              colorCode={buttonTextColor}
              setColor={(color: any) => setButtonTextColor(color.hex)}
              setInitialData={() => setButtonTextColor(themeConfig.secondaryTextColor)}
            />
          </>
        }
        previewSection={
          <PreviewBox bgColor={basketBackgroundColor}>
            <PreviewBasket
              textColor={textColor}
              buttonColor={buttonColor}
              buttonTextColor={buttonTextColor}
            />
          </PreviewBox>
        }
      />
    </>
  );
};

export default SectionBoxBasket;
