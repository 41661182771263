import { axiosClient } from "../client";

const API_GET_OPENING_TIMES = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}/location/${locationId}/opening-time`;

const API_UPDATE_OPENING_TIMES = (locationId, scheduleId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}/location/${locationId}/opening-time/${scheduleId}`;

export const getOpeningTimes = (locationId) => {
  return axiosClient({
    method: "GET",
    url: API_GET_OPENING_TIMES(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
};

export const updateOpeningTimeSchedule = (locationId, scheduleId, data) => {
  return axiosClient({
    method: "PUT",
    url: API_UPDATE_OPENING_TIMES(locationId, scheduleId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    data,
  });
};

export const updateSelectedOpeningTimeSchedule = (
  locationId,
  selectedOpeningSchedule,
) => {
  let data = Object.assign({}, selectedOpeningSchedule);
  data.isActive = true;
  return axiosClient({
    method: "PUT",
    url: API_UPDATE_OPENING_TIMES(locationId, selectedOpeningSchedule.id),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    data,
  });
};
