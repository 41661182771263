import React from "react";
import { makeStyles, createStyles, useTheme } from "@material-ui/core";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import "./textEditor.css";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      margin: "16px",
    },
  }),
);

export interface TextEditorProps {
  editorState: any;
  handleChangeEditorState: any;
}

/* Editor UI */
const TextEditor: React.FunctionComponent<TextEditorProps> = ({
  editorState,
  handleChangeEditorState,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  
  const editorStyle = {
    minHeight: "200px",
    border: "1px solid",
    padding: "4px",
  };
  
  const styleMap = {
    backgroundColor: theme.palette.background.paper,
    border: "0px",
  };

  return (
    <div className={classes.root}>
      <Editor
        editorState={editorState}
        editorStyle={editorStyle}
        toolbarStyle={styleMap}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        toolbar={{
          options: ["inline", "fontSize", "link", "list"],
        }}
        onEditorStateChange={handleChangeEditorState}
      />
    </div>
  );
};

export default TextEditor;
