import Axios from "axios";
import { axiosClient } from "../client";

const queryString = require("query-string");

const DAILY_SALE_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/orders/report/daily`;

export const fetchAllDailySaleReportInfoInEatpresto = (
  token,
  locationId,
  filterData,
) =>
axiosClient({
  method: "GET",
  url: queryString.stringifyUrl(
    {
      url: DAILY_SALE_INFO_GET_ALL(locationId),
      query: filterData,
    },
    { arrayFormat: "comma" },
  ),
  headers: {
    "X-Role-Location": locationId,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});
