import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getCookie } from "../../../utils/cookies";
import { handleCreateQueryParam } from "../../../utils/locationQueryParams";

export interface ProtectedRouteProps {
  path?: any;
  component?: any;
  children?: any;
  render?: any;
  exact?: any;
}

/* This function is used to protect routing. */
const ProtectedRoute: React.SFC<ProtectedRouteProps> = ({
  path,
  component,
  children,
  render,
  exact
}) => {
  const idToken = getCookie("idToken");

  /* If there is no token, the login page will be redirected. */
  if (!idToken) {
    return <Redirect from="/" to="/redirect-authentication" exact />;
  }

  /* Get the appName using query param */
  const appName = handleCreateQueryParam();
  
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set("app", appName);
  /* If the token is available, it redirects to the location list page. */
  return (
    <Route
      path="/"
      component={() => {
        window.location.href = `${process.env.REACT_APP_PRESTO_LOCATION_DOMAIN}`;
        window.location.search = searchParams.toString();
        return null;
      }}
    />
  );
};

export default ProtectedRoute;
