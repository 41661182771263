import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import { CustomTheme } from "../../../../../types/customTheme";
import StepZero from "./StepZero";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { HttpStatus, buttonColors } from "../../../../../utils/enum";
import StepOne from "./StepOne";
import StockDepartmentAddModal from "./StockDepartmentAddModal";
import {
  createStockDepartments,
  createStockGroups,
  createStockItemsInfo,
  createSupplier,
  createSupplierForStockItem,
  fetchGetAllStockDepartments,
  fetchStockItemInfoGetSingle,
  supplierMap,
  updateStockItemsInfo,
} from "../../../../../services/inventory/stockItems";
import { deleteStockSupplierMap } from "../../../../../services/inventory/supplier";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../../utils/consts";
import StockGroupAddModal from "./StockGroupAddModal";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import AddSupplierModal from "./AddSupplierModal";
import StepFour from "./StapFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import StepSeven from "./StepSeven";
import StepEight from "./StepEight";
import StockItemSummery from "./StockItemSummery";
import AlertDialog from "../../../../../components/alerts/AlertDialog";
import DefaultAlert from "../../../../../components/alerts/DefaultAlert";

export interface WizardModalProps {
  isOpenWizard: boolean;
  setIsOpenWizard: any;
  setSearchName: any;
  setSearchNameTab: any;
  setIsSearch: any;
  getAllStockGroup: any;
  handleGetSupplierInfo: any;
  stockGroupSelectedNode: any;
  setStockGroupSelectedNode: any;
  selectedGroupObj: any;
  setSelectedGroupObj: any;
  supplierDetailsNode: any;
  id: any;
  handleChangeSearchSupplier: any;
  handleClearSearchSupplier: any;
  searchName: any;
  isSearch: any;
  handleGetSelectedSupplier: any;
  getAllStockDepartmentForGroupNotLoad: any;
  handleOpenEditMapProductsItemsModal: any;
  supplierDetailsNodeObj: any;
}

/**
 * WizardModal Component:
 *
 * This component represents a wizard dialog for adding a new stock item. It guides the user through a series of steps
 * to collect necessary information about the stock item, such as department, suppliers, thresholds, etc.
 */
const WizardModal: React.FunctionComponent<WizardModalProps> = ({
  isOpenWizard,
  setIsOpenWizard,
  setSearchName,
  setSearchNameTab,
  setIsSearch,
  getAllStockGroup,
  handleGetSupplierInfo,
  stockGroupSelectedNode,
  selectedGroupObj,
  setSelectedGroupObj,
  supplierDetailsNode,
  id,
  handleChangeSearchSupplier,
  handleClearSearchSupplier,
  searchName,
  isSearch,
  handleGetSelectedSupplier,
  getAllStockDepartmentForGroupNotLoad,
  handleOpenEditMapProductsItemsModal,
  supplierDetailsNodeObj,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isOpenDepartmentAddModal, setIsOpenDepartmentAddModal] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [groupName, setGroupName] = useState("");
  const [isOpenGroupAddModal, setIsOpenGroupAddModal] = useState(false);
  const match: any = useRouteMatch();
  const [error, setError] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [selectedSupplierList, setSelectedSupplierList] = useState<any>([]);
  const [isOpenSupplierAddModals, setIsOpenSupplierAddModals] = useState(false);
  const [selectedDepartmentObj, setSelectedDepartmentObj] = useState<any>([]);
  const [stockDepartmentSelectedNode, setStockDepartmentSelectedNode] =
    useState<any>([]);
  const [openDepartmentDropdown, setOpenDepartmentDropdown] = useState(false);
  const [selectedSupplierListInitial, setSelectedSupplierListInitial] =
    useState<any>([]);
  const [purchaseUnitObj, setPurchaseUnitObj] = useState<any>();
  const [saleUnitObj, setSaleUnitObj] = useState<any>();
  const [inputMultiplier, setInputMultiplier] = useState("");
  const [
    isOPenSelectedPurchaseUnitDropDown,
    setIsOPenSelectedPurchaseUnitDropDown,
  ] = useState(false);
  const [purchaseUnit, setPurchaseUnit] = useState("");
  const [baseUnit, setBaseUnit] = useState("");
  const [isAutoConversion, setIsAutoConversion] = useState(true);
  const [isShowSeparate, setIsShowSeparate] = useState(false);
  const [stockItemId, setStockItemId] = useState("");
  const [warningThreshold, setWarningThreshold] = useState("");
  const [criticalThreshold, setCriticalThreshold] = useState("");
  const [name, setName] = useState("");
  const [barcode, setBarcode] = useState("");
  const [sku, setSku] = useState("");
  const [isEdit, setIsEdit] = useState(0);
  const [isOpenConfirmationExitButton, setIsOpenConfirmationExitButton] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClickCustom, setIsClickCustom] = useState(false);
  const [isClickCustomBaseUnit, setIsClickCustomBaseUnit] = useState(false);

  /**
   * handleNext Function
   *
   * This function is responsible for determining the behavior when the "Next" button
   * is clicked in the wizard modal. It advances to the next step based on certain conditions
   * and updates the active step accordingly. Additionally, it handles special cases such as
   * when the wizard is in edit mode or when custom purchase units are selected.
   */
  const handleNext = () => {
    // Check if in edit mode and at specific steps or if custom purchase unit is selected at step 3
    if (
      (isEdit !== 0 && activeStep === isEdit) ||
      (isEdit && activeStep === 4) ||
      (isEdit &&
        purchaseUnitObj &&
        purchaseUnitObj.value === "PurchaseUnitCustom" &&
        activeStep === 3)
    ) {
      // If at step 3 and base unit is empty, skip to the next step; otherwise, proceed to step 9
      if (activeStep === 3 && _.isEmpty(baseUnit)) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setActiveStep(9);
        setIsEdit(0);
      }
      // If at step 9, revert to step 7
    } else if (activeStep === 9) {
      setActiveStep(7);
    } else {
      // Advance to the next step based on purchase unit and active step
      if (
        purchaseUnitObj &&
        purchaseUnitObj.value === "PurchaseUnitCustom" &&
        activeStep === 3
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  /**
   * handleReset Function
   *
   * This function resets all the states used in the wizard modal to their initial values.
   */
  const handleReset = () => {
    setActiveStep(0);
    setSelectedDepartmentObj({});
    setPurchaseUnitObj({});
    setWarningThreshold("");
    setCriticalThreshold("");
    setName("");
    setBarcode("");
    setSku("");
    setPurchaseUnit("");
    setBaseUnit("");
    setInputMultiplier("");
    setIsAutoConversion(true);
    setIsShowSeparate(false);
    setSelectedSupplierList([]);
    setIsSearch(false);
    setSearchName("");
    setSaleUnitObj({});
    setStockItemId("");
  };

  /**
   * handleExistWizard Function
   *
   * This function is called when the user confirms exiting the wizard.
   */

  const handleExistWizard = () => {
    // Close the wizard modal by setting isOpenWizard to false
    setIsOpenWizard(false);

    // Reset all wizard states to their initial values
    handleReset();

    // Fetch all stock departments for a specified group using getAllStockDepartmentForGroupNotLoad
    getAllStockDepartmentForGroupNotLoad(groupId, "", "");
  };

  /**
   * handleConfirmExist Function
   *
   * This function is called when the user wants to confirm exiting the wizard.
   */

  const handleConfirmExist = () => {
    // Set isOpenConfirmationExitButton state to true to show the confirmation dialog
    setIsOpenConfirmationExitButton(true);
  };

  /**
   * handleAddSimilarStockItem Function
   *
   * This function is called when the user wants to add another similar stock item.
   */
  const handleAddSimilarStockItem = () => {
    // Clear name, barcode, and SKU fields
    setName("");
    setBarcode("");
    setSku("");

    // Increment the active step by 1 to proceed to the next step in the wizard
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /**
   * handleBackWizard Function
   *
   * This function is called when the user wants to navigate back in the wizard.
   */
  const handleBackWizard = () => {
    if (
      purchaseUnitObj &&
      purchaseUnitObj.value === "PurchaseUnitCustom" &&
      activeStep === 5
    ) {
      // Decrement the active step by 2
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    } else {
      // Decrement the active step by 1
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  /**
   * Handle department name change.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
   */
  const handleChangeDepartmentName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Set the department name based on the input value
    const newDepartmentName: string = event.target.value;
    setDepartmentName(newDepartmentName);
  };

  /**
   * Handle group name change.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
   */
  const handleChangeGroupName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Set the group name based on the input value
    const newGroupName: string = event.target.value;
    setGroupName(newGroupName);
  };

  /**
   * Handle the opening of the department add modal.
   */
  const handleOpenDepartmentAddModal = () => {
    // Clear the department name input and set the modal state to open
    setDepartmentName("");
    setIsOpenDepartmentAddModal(true);
  };

  /**
   * Handle the opening of the supplier add modal.
   */
  const handleOpenSupplierAddModal = () => {
    setSearchName("");
    setIsSearch(false);
    setIsOpenSupplierAddModals(true);
  };

  /**
   * Handle the opening of the group add modal.
   */
  const handleOpenGroupAddModal = () => {
    setGroupName("");
    setIsOpenGroupAddModal(true);
  };

  /**
   * This function, 'getAllStockDepartments', is responsible for fetching a list of stock departments
   * from an API endpoint and updating the component's state based on the response.
   *
   * @param {any} id - An optional parameter representing the ID of a specific department to retrieve.
   * @returns {Promise<void>} - This function is asynchronous and does not return a value.
   */
  const getAllStockDepartments = async (id?: any): Promise<void> => {
    try {
      // Attempt to fetch stock department information using the 'fetchGetAllStockDepartments' API function
      const res = await fetchGetAllStockDepartments(match.params.locationId);
      // Iterate through the department data to find the one with the matching 'id' (if provided)

      // Check if the response contains data and update the component's state accordingly
      if (res?.data?.data) {
        setStockDepartmentSelectedNode(res.data.data);
      } else {
        // If no data is available, set an empty array for 'stockDepartmentSelectedNode'
        setStockDepartmentSelectedNode([]);
      }
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };
  /**
   * Handles the creation of a new department by making an API call to create the department
   * with the provided data. If successful, it sets the group ID and fetches all stock departments
   * for the newly created department. Closes the department add modal upon completion.
   */
  const handleCreateNewDepartment = async () => {
    const departmentData = {
      name: departmentName,
      stockGroupId: selectedGroupObj.id,
      locationId: match.params.locationId,
    };

    try {
      // Attempt to create a new department via an API call
      const response = await createStockDepartments(
        match.params.locationId,
        departmentData,
      );
      // Set the group ID based on the API response
      setGroupId(response.data.data.stockGroupId.id);

      // Fetch all stock departments for the newly created department
      getAllStockDepartments(response.data.data.id);

      // Close the department add modal upon successful creation
      setIsOpenDepartmentAddModal(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Handles the creation of a new group by making an API call to create the group
   * with the provided data. If successful, it sets the group ID and fetches all stock groups
   * for the newly created group. Closes the group add modal upon completion.
   */
  const handleCreateNewGroup = async () => {
    // Prepare the data for creating a new group
    const groupData = {
      name: groupName,
      locationId: match.params.locationId,
    };
    try {
      // Attempt to create a new group via an API call
      const response = await createStockGroups(
        match.params.locationId,
        groupData,
      );
      // Set the group ID based on the API response
      setGroupId(response.data.data.id);

      // Fetch all stock groups for the newly created group
      getAllStockGroup(response.data.data.id);
      // Close the group add modal upon successful creation
      setIsOpenGroupAddModal(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Handles the change of selected group and updates the selected group object and ID.
   *
   * @param {Object} e - The event object from the change event.
   * @param {Object} groupData - The data of the selected group.
   */
  const handleGroupChange = (e: any, groupData: any) => {
    // Update the selected group object
    setSelectedGroupObj(groupData);

    // Set the selected group ID
    setGroupId(groupData.id);
  };

  /**
   * Handles the mapping of a supplier to the location.
   *
   * @param {any} supplierId - The ID of the supplier to be mapped.
   */
  const handleSupplierMapping = async (supplierId: any) => {
    const data = {
      locationId: match.params.locationId,
      supplierId: supplierId,
    };

    try {
      await supplierMap(match.params.locationId, data);

      // Update supplier information and set success message
      handleGetSupplierInfo();
      setIsOpenSupplierAddModals(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Handles the creation of a new supplier and maps it to the location.
   *
   * @param {any} supplierData - The data for creating the new supplier.
   */
  const handleCreateNewSupplier = async (supplierData: any) => {
    try {
      // Attempt to create a new supplier using the 'createSupplier' API
      const response = await createSupplier(
        match.params.locationId,
        supplierData,
      );

      // Map the newly created supplier to the location
      handleSupplierMapping(response.data.data.id);
    } catch (error) {}
  };

  /**
   * Delete supplier details. This function is responsible for deleting a supplier's details
   * associated with a specific stock item.
   *
   * @param {string} supplierId - The ID of the supplier to be deleted.
   * @param {string} stockItemId - The ID of the stock item associated with the supplier.
   */
  const deleteSupplierDetails = async (supplierId: any) => {
    // Create a form with the required data
    const deleteFormData = {
      locationId: match.params.locationId,
      supplierId: supplierId,
      stockItemId: id,
    };
    try {
      // Make an API call to delete the supplier details
      await deleteStockSupplierMap(match.params.locationId, deleteFormData);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Toggles the selection of a supplier by adding or removing its ID from the selectedSupplierList.
   * @param {string} id - The ID of the supplier to toggle.
   */
  const handleSelectedSupplier = (id: string) => {
    // Clone the selectedSupplierList to avoid mutating the state directly.
    const updatedSelectedSupplierList = _.cloneDeep(selectedSupplierList);

    if (updatedSelectedSupplierList.includes(id)) {
      // If the supplier ID is already in the list, remove it to deselect the supplier.
      const filterSelectedSupplier = updatedSelectedSupplierList.filter(
        (data: any) => data !== id,
      );
      if (selectedSupplierListInitial.includes(id)) {
        deleteSupplierDetails(id);
      }
      setSelectedSupplierList(filterSelectedSupplier);
    } else {
      // If the supplier ID is not in the list, add it to select the supplier.
      updatedSelectedSupplierList.push(id);
      setSelectedSupplierList(updatedSelectedSupplierList);
    }
  };

  /**
   * handleChangePurchaseUnit Function
   *
   * This function is called when the purchase unit selection is changed.
   */
  const handleChangePurchaseUnit = (purchaseUnit: any) => {
    // Set the purchase unit state to the label of the selected unit, unless it's "Custom"
    setPurchaseUnit(purchaseUnit.label !== "Custom" ? purchaseUnit.label : "");
    // Set the purchase unit object state to the selected unit
    setPurchaseUnitObj(purchaseUnit);

    // Reset states if active step is 3
    if (activeStep === 3) {
      setBaseUnit("");
      setSaleUnitObj({});
      setInputMultiplier("");
      setIsAutoConversion(true);
      setIsShowSeparate(false);
    }

    // Handle custom purchase unit
    if (purchaseUnit.label === "Custom") {
      setPurchaseUnit("");
      setBaseUnit("");
      setIsClickCustom(true);
      setInputMultiplier("");
      setIsAutoConversion(true);
      setIsShowSeparate(false);
    } else {
      setIsClickCustom(false);
    }
  };

  /**
   * handleChangeSaleUnit Function
   *
   * This function is called when the sale unit selection is changed.
   */
  const handleChangeSaleUnit = (saleUnit: any) => {
    // Set the base unit state to the base unit of the selected sale unit, unless it's "Custom"
    setBaseUnit(saleUnit.baseUnit !== "Custom" ? saleUnit.baseUnit : "");
    // Set the sale unit object state to the selected sale unit
    setSaleUnitObj(saleUnit);
    // Set the input multiplier state to the multiplier of the selected sale unit
    setInputMultiplier(saleUnit.multiplier);
    // Set the isAutoConversion state to the auto conversion flag of the selected sale unit
    setIsAutoConversion(saleUnit.auto);
    // Set the isShowSeparate state to the separate flag of the selected sale unit
    setIsShowSeparate(saleUnit.separate);

    // Handle custom sale unit type
    if (saleUnit.type === "Custom") {
      setInputMultiplier("");
    }

    // Handle custom base unit
    if (saleUnit.baseUnit === "Custom") {
      setBaseUnit("");
      setInputMultiplier("");
      setIsAutoConversion(true);
      setIsShowSeparate(false);
      setIsClickCustomBaseUnit(true);
    } else {
      setIsClickCustomBaseUnit(false);
    }
  };

  /**
   * handleChangeAutoConversion Function
   *
   * This function is called when the auto conversion setting is changed.
   */

  const handleChangeAutoConversion = () => {
    // If the isAutoConversion state is true, sets the isShowSeparate state to false
    if (isAutoConversion) {
      setIsShowSeparate(false);
    }

    // Toggles the isAutoConversion state by negating its current value
    setIsAutoConversion(!isAutoConversion);
  };

  /**
   * handleChangeShowSeparate Function
   *
   * This function is called when the show separate setting is changed.
   */

  const handleChangeShowSeparate = () => {
    // Toggles the isShowSeparate state by negating its current value
    setIsShowSeparate(!isShowSeparate);
  };

  /**
   * handleDisabled Function
   *
   * This function determines whether the Next button should be disabled based on the current active step and form input validation.
   */
  const handleDisabled = () => {
    // Check if activeStep is 1 and selectedDepartmentObj.id is empty
    if (activeStep === 1 && _.isEmpty(selectedDepartmentObj.id)) {
      return true;
    }
    // Check if activeStep is 2 and selectedSupplierList is empty
    else if (activeStep === 2 && _.isEmpty(selectedSupplierList)) {
      return true;
    }
    // Check if activeStep is 3
    else if (activeStep === 3) {
      // Check if purchaseUnitObj is empty
      if (_.isEmpty(purchaseUnitObj)) {
        return true;
      }
      // Check if purchaseUnitObj is "PurchaseUnitCustom" and any of purchaseUnit, baseUnit, or inputMultiplier is empty
      else if (
        purchaseUnitObj.value === "PurchaseUnitCustom" &&
        (_.isEmpty(purchaseUnit) ||
          _.isEmpty(baseUnit) ||
          _.isEmpty(inputMultiplier))
      ) {
        return true;
      }
    }
    // Check if activeStep is 4 and saleUnitObj is empty
    else if (activeStep === 4 && _.isEmpty(saleUnitObj)) {
      return true;
    }
    // Check if activeStep is 4
    else if (activeStep === 4) {
      // Check if saleUnitObj is "Custom" and baseUnit is "Custom", and either inputMultiplier or baseUnit is empty
      if (saleUnitObj.type === "Custom" && saleUnitObj.baseUnit === "Custom") {
        if (!inputMultiplier || !baseUnit) {
          return true;
        }
      }
      // Check if saleUnitObj is "Custom" and baseUnit is not "Custom", and inputMultiplier is empty
      else if (
        saleUnitObj.type === "Custom" &&
        saleUnitObj.baseUnit !== "Custom"
      ) {
        if (_.isEmpty(inputMultiplier)) {
          return true;
        }
      }
    }
    // Check if activeStep is 5 and warningThreshold is empty
    else if (activeStep === 5 && _.isEmpty(warningThreshold)) {
      return true;
    }
    // Check if activeStep is 6 and criticalThreshold is empty
    else if (activeStep === 6 && _.isEmpty(criticalThreshold)) {
      return true;
    }
    // Check if activeStep is 7 and name is empty
    else if (activeStep === 7 && _.isEmpty(name)) {
      return true;
    }
  };

  /**
   * Handles the creation of suppliers for a stock item, and optionally updates
   * selected suppliers if it's a delete operation.
   *
   * @param {any} stockItemId - The ID of the stock item.
   * @param {boolean} isDelete - Indicates whether this is a delete operation.
   */
  const handleCreateSuppliersForStockItem = async (stockItemId: any) => {
    setError("");
    // Prepare the data for the supplier creation
    const data = {
      stockItemId: stockItemId,
      suppliers: selectedSupplierList,
    };

    try {
      // Make an API call to create suppliers for the stock item
      await createSupplierForStockItem(match.params.locationId, data);
      handleGetSelectedSupplier().then(() => {
        setSearchName("");
        setSearchNameTab("");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * handleCreateStockItems Function
   *
   * This function is responsible for creating stock items by making an API call.
   * It sets an error message if an error occurs during the API call.
   */

  const handleCreateStockItems = async (updatedStockItem: any) => {
    // Clear any existing error message
    setError("");
    try {
      // Make an API call to create the stock item
      const res = await createStockItemsInfo(
        match.params.locationId,
        updatedStockItem,
      );
      // If the API call is successful and returns data
      if (res.data.data) {
        // Perform additional actions after successfully creating the stock item
        handleCreateSuppliersForStockItem(res.data.data.id);
        setStockItemId(res.data.data.id);
        setGroupId(res.data.data.stockDepartmentId.stockGroupId.id);
      }
    } catch (error) {
      // If an error occurs during the API call, handle the error
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * getSingleStockItemGivenId Function
   *
   * This function retrieves a single stock item's information based on its ID by making an API call.
   * It then updates the stock item's version and triggers an update operation.
   */
  const getSingleStockItemGivenId = async (updatedStockItem: any) => {
    // Make an API call to fetch the stock item information
    const res = await fetchStockItemInfoGetSingle(
      match.params.locationId,
      updatedStockItem.id,
    );

    // Update the version of the stock item with the version obtained from the API response
    updatedStockItem.version = res.data.data.version;

    // Trigger an update operation for the stock item
    handleUpdateStockItems(updatedStockItem);
  };

  /**
   * Handle the update of stock items. This function is responsible for updating stock item information.
   *
   * @param {object} updatedStockItem - The updated stock item data.
   */
  const handleUpdateStockItems = async (updatedStockItem: any) => {
    // Clear any previous errors.
    setError("");

    try {
      // Send a request to update stock item information.
      const res = await updateStockItemsInfo(
        match.params.locationId,
        updatedStockItem,
      );

      if (res.data.status === HttpStatus.CONFLICT_409) {
        getSingleStockItemGivenId(updatedStockItem);
      } else {
        // Handle creating suppliers for the created stock item
        handleCreateSuppliersForStockItem(res.data.data.id);

        // Set the name and ID of the updated stock item.
        setName(res.data.data.name);
        setStockItemId(res.data.data.id);

        setSelectedGroupObj({});

        setGroupId(res.data.data.stockDepartmentId.stockGroupId.id);
      }
    } catch (error) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * handleCompleteStockItem Function
   *
   * This function handles the completion of creating a new stock item.
   * It sets the loading state to true, constructs the updated stock item object with necessary data,
   * and triggers the creation of the stock item by calling handleCreateStockItems function.
   */
  const handleCompleteStockItem = () => {
    // Set loading state to true
    setIsLoading(true);

    // Initialize an object to hold the updated stock item data
    const updatedStockItem: any = {};

    // Set properties of the updated stock item object with relevant data
    updatedStockItem.active = true;
    updatedStockItem.primaryLocationId = match.params.locationId;
    updatedStockItem.criticalThreshold = parseFloat(criticalThreshold);
    updatedStockItem.inputMultiplier = parseFloat(inputMultiplier);
    updatedStockItem.warningThreshold = parseFloat(warningThreshold);
    updatedStockItem.stockDepartmentId = selectedDepartmentObj.id;
    updatedStockItem.name = name;
    updatedStockItem.barcode = barcode;
    updatedStockItem.displaySuffix = baseUnit;
    updatedStockItem.inputSuffix = purchaseUnit;
    updatedStockItem.sku = sku;

    updatedStockItem.autoUnitConvert = isAutoConversion;
    updatedStockItem.showRemainderSep = isAutoConversion
      ? isShowSeparate
      : false;
    updatedStockItem.version = 0;

    // Trigger the creation of the stock item by calling handleCreateStockItems function
    handleCreateStockItems(updatedStockItem);
  };

  /**
   * handleOpenMapProduct Function
   *
   * This function handles the opening of the modal to map products to the current stock item.
   * It triggers the opening of the modal with necessary parameters by calling handleOpenEditMapProductsItemsModal function.
   */
  const handleOpenMapProduct = () => {
    // Trigger the opening of the modal to map products to the current stock item
    // by calling handleOpenEditMapProductsItemsModal function with required parameters
    handleOpenEditMapProductsItemsModal({
      id: stockItemId, // Pass the stock item ID
      displaySuffix: baseUnit, // Pass the base unit of the stock item
    });
  };

  const theme: CustomTheme = useTheme();

  return (
    <>
      <DialogCommonDefault
        open={isOpenWizard}
        setOpen={setIsOpenWizard}
        isNeedFixedHeight={false}
        backgroundColor={theme.palette.background.entity_background}
        maxWidth={"sm"}
      >
        <DialogContent>
          {activeStep === 0 && <StepZero />}
          {activeStep === 1 && (
            <StepOne
              open={open}
              setOpen={setOpen}
              handleOpenDepartmentAddModal={handleOpenDepartmentAddModal}
              stockDepartmentSelectedNode={stockDepartmentSelectedNode}
              selectedDepartmentObj={selectedDepartmentObj}
              setSelectedDepartmentObj={setSelectedDepartmentObj}
              getAllStockDepartments={getAllStockDepartments}
              getAllStockGroup={getAllStockGroup}
              handleGetSupplierInfo={handleGetSupplierInfo}
              setDepartmentName={setDepartmentName}
            />
          )}
          {activeStep === 2 && (
            <StepTwo
              supplierDetailsNode={supplierDetailsNode}
              selectedSupplierList={selectedSupplierList}
              handleChangeSearchSupplier={handleChangeSearchSupplier}
              searchName={searchName}
              isSearch={isSearch}
              handleSelectedSupplier={handleSelectedSupplier}
              handleClearSearchSupplier={handleClearSearchSupplier}
              handleOpenSupplierAddModal={handleOpenSupplierAddModal}
            />
          )}
          {activeStep === 3 && (
            <StepThree
              handleChangePurchaseUnit={handleChangePurchaseUnit}
              purchaseUnitObj={purchaseUnitObj}
              purchaseUnit={purchaseUnit}
              setPurchaseUnit={setPurchaseUnit}
              baseUnit={baseUnit}
              setBaseUnit={setBaseUnit}
              inputMultiplier={inputMultiplier}
              setInputMultiplier={setInputMultiplier}
              handleChangeAutoConversion={handleChangeAutoConversion}
              isShowSeparate={isShowSeparate}
              isAutoConversion={isAutoConversion}
              handleChangeShowSeparate={handleChangeShowSeparate}
              isClickCustom={isClickCustom}
            />
          )}
          {activeStep === 4 && (
            <StepFour
              purchaseUnitObj={purchaseUnitObj}
              handleChangeSaleUnit={handleChangeSaleUnit}
              saleUnitObj={saleUnitObj}
              inputMultiplier={inputMultiplier}
              setInputMultiplier={setInputMultiplier}
              baseUnit={baseUnit}
              setBaseUnit={setBaseUnit}
              purchaseUnit={purchaseUnit}
              handleChangeAutoConversion={handleChangeAutoConversion}
              isShowSeparate={isShowSeparate}
              isAutoConversion={isAutoConversion}
              handleChangeShowSeparate={handleChangeShowSeparate}
              isClickCustomBaseUnit={isClickCustomBaseUnit}
            />
          )}
          {activeStep === 5 && (
            <StepFive
              warningThreshold={warningThreshold}
              setWarningThreshold={setWarningThreshold}
              baseUnit={baseUnit}
            />
          )}
          {activeStep === 6 && (
            <StepSix
              criticalThreshold={criticalThreshold}
              setCriticalThreshold={setCriticalThreshold}
              baseUnit={baseUnit}
            />
          )}
          {activeStep === 7 && (
            <StepSeven
              name={name}
              setName={setName}
              barcode={barcode}
              setBarcode={setBarcode}
              sku={sku}
              setSku={setSku}
            />
          )}
          {activeStep === 9 && (
            <StockItemSummery
              setActiveStep={setActiveStep}
              setIsEdit={setIsEdit}
              departmentName={departmentName}
              selectedSupplierList={selectedSupplierList}
              purchaseUnit={purchaseUnit}
              baseUnit={baseUnit}
              warningThreshold={warningThreshold}
              criticalThreshold={criticalThreshold}
              supplierDetailsNodeObj={supplierDetailsNodeObj}
              purchaseUnitObj={purchaseUnitObj}
            />
          )}
          {activeStep === 8 && <StepEight />}
        </DialogContent>
        {activeStep !== 8 ? (
          <DialogActions style={{ margin: "12px 0px" }}>
            <ButtonCommon
              style={{
                marginRight: 4,
                width: "180px",
              }}
              variant="contained"
              color={buttonColors.DELETE_BUTTON_COLOR}
              onClick={handleConfirmExist}
              disabled={isLoading}
            >
              <Typography style={{ textTransform: "none", color: "white" }}>
                Exit Wizard
              </Typography>
            </ButtonCommon>
            {activeStep > 0 && activeStep !== 9 && (
              <ButtonCommon
                style={{
                  marginRight: 4,
                  width: "180px",
                }}
                variant="contained"
                color={buttonColors.UPDATE_BUTTON_COLOR}
                onClick={handleBackWizard}
                disabled={isLoading}
              >
                <Typography style={{ textTransform: "none", color: "black" }}>
                  Back
                </Typography>
              </ButtonCommon>
            )}
            <ButtonCommon
              style={{
                marginRight: 4,
                width: "180px",
              }}
              variant="contained"
              color={buttonColors.CREATE_BUTTON_COLOR}
              onClick={activeStep === 7 ? handleCompleteStockItem : handleNext}
              disabled={handleDisabled() || isLoading}
              isLoadingPage={isLoading}
            >
              <Typography style={{ textTransform: "none", color: "white" }}>
                {activeStep === 7 ? "Create" : "Next"}
              </Typography>
            </ButtonCommon>
          </DialogActions>
        ) : (
          <DialogActions style={{ margin: "12px 0px" }}>
            <ButtonCommon
              style={{
                marginRight: 4,
                width: "180px",
                minHeight: "66px",
              }}
              variant="contained"
              color={buttonColors.DELETE_BUTTON_COLOR}
              onClick={handleConfirmExist}
              disabled={isLoading}
            >
              <Typography style={{ textTransform: "none", color: "white" }}>
                Exit Wizard
              </Typography>
            </ButtonCommon>
            <ButtonCommon
              style={{
                marginRight: 4,
                width: "288px",
              }}
              variant="contained"
              color={buttonColors.CANCEL_BUTTON_COLOR}
              onClick={handleOpenMapProduct}
              disabled={isLoading}
            >
              <Typography style={{ textTransform: "none", color: "white" }}>
                {`Map products to ${name}`}
              </Typography>
            </ButtonCommon>
            <ButtonCommon
              style={{
                marginRight: 4,
                width: "260px",
              }}
              variant="contained"
              color={buttonColors.CREATE_BUTTON_COLOR}
              onClick={handleAddSimilarStockItem}
              disabled={isLoading}
              isLoadingPage={isLoading}
            >
              <Typography style={{ textTransform: "none", color: "white" }}>
                Add another similar stock item
              </Typography>
            </ButtonCommon>
          </DialogActions>
        )}
      </DialogCommonDefault>

      {isOpenDepartmentAddModal && (
        <StockDepartmentAddModal
          isOpenDepartmentAddModal={isOpenDepartmentAddModal}
          setIsOpenDepartmentAddModal={setIsOpenDepartmentAddModal}
          setOpen={setOpenDepartmentDropdown}
          open={openDepartmentDropdown}
          stockGroupSelectedNode={stockGroupSelectedNode}
          selectedGroupObj={selectedGroupObj}
          isLoadingToast={false}
          handleGroupChange={handleGroupChange}
          handleOpenGroupAddModal={handleOpenGroupAddModal}
          departmentName={departmentName}
          handleChangeDepartmentName={handleChangeDepartmentName}
          handleCreateNewDepartment={handleCreateNewDepartment}
        />
      )}
      {isOpenGroupAddModal && (
        <StockGroupAddModal
          isOpenGroupAddModal={isOpenGroupAddModal}
          setIsOpenGroupAddModal={setIsOpenGroupAddModal}
          isLoadingToast={false}
          groupName={groupName}
          handleChangeGroupName={handleChangeGroupName}
          handleCreateNewGroup={handleCreateNewGroup}
        />
      )}

      <AddSupplierModal
        isOpenSupplierAddModals={isOpenSupplierAddModals}
        setIsOpenSupplierAddModals={setIsOpenSupplierAddModals}
        handleCreateNewSupplier={handleCreateNewSupplier}
        isLoadingToast={false}
      />

      <AlertDialog
        open={isOpenConfirmationExitButton}
        confirmAction={handleExistWizard}
        title="Warning"
        desc="Are you sure you want to exit the wizard? All unsaved data will be lost."
        severity="secondary"
        confirmLabel="Confirm"
        disableBackdropClick
        color="secondary"
        cancelLabel={"Cancel"}
        cancelAction={() => setIsOpenConfirmationExitButton(false)}
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default WizardModal;
