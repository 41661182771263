import React, { useEffect, useState } from "react";
import { Box, Hidden } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";

import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import {
  fetchAllDashboardInfo,
  updateDashboardInfo,
} from "../../../../services/eatprestoApp/dashboardServices";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import DashboardDetails from "./DashboardDetails";
import CardLoading from "../../../../components/common/Loading/CardLoading";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";

/**
 * Dashboard Component:
 * 
 * This component represents the dashboard page for managing dashboard information.
 * It fetches dashboard information using API calls, allows updates to the dashboard,
 * and displays the dashboard details.
 */
const Dashboard: React.FunctionComponent<{}> = () => {
  const [dashboardNodeList, setDashboardNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const match: any = useRouteMatch();

  /* Get all dashboard information using API call and response data set to dashboardNodeList state. */
  const getDashboardInfo = async () => {
    setIsLoading(true);
    try {
      const res = await fetchAllDashboardInfo(match.params.locationId);
      setDashboardNodeList(res.data.data);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
    setIsLoading(false);
  };
  /* Send an API call to update dashboard information after change any switch status. */
  const handleUpdateDashboardInfo = async (nodeList: any) => {
    setIsLoading(true);
    try {
      await updateDashboardInfo(match.params.locationId, nodeList);
      getDashboardInfo();
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  useEffect(() => {
    document.title = "Eat Presto - Dashboard";
    getDashboardInfo();
  }, []);

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box style={{ marginTop: "18px" }}>
        {!isLoading ? (
          <DashboardDetails
            dashboardNodeList={dashboardNodeList}
            handleUpdateDashboardInfo={handleUpdateDashboardInfo}
          />
        ) : (
          <CardLoading />
        )}
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(Dashboard, Authorities.DASHBOARD_READ);
