import { Grid } from "@material-ui/core";
import * as React from "react";
import Authorities from "../../../../../auth/authorities";
import withAuthority from "../../../../../components/Auth/withAuthority";
import ButtonContained from "../../../../../components/buttons/ButtonContained";
import InputBoxDefault from "../../../../../components/common/InputBoxDefault";
import ValidationMessage from "../../../../../components/validation/ValidationMessage";
import { EMAIL_PATTERN } from "../../../../../utils/consts";
import { buttonColors, submitButtonName } from "../../../../../utils/enum";

export interface ContactInfoProps {
  locationData: any;
  handleChange: any;
  register: any;
  errors: any;
  onSubmit?: any;
  isAuthorized?: boolean;
}

/* Show the business information. */
const ContactInfo: React.FunctionComponent<ContactInfoProps> = ({
  locationData,
  handleChange,
  register,
  errors,
  isAuthorized,
}) => {
  return (
    <>
      <Grid container spacing={2} style={{ paddingTop: "8px" }}>
        <Grid item xs={12} sm={6} style={{ padding: "0px 8px" }}>
          <InputBoxDefault
            label={"Phone"}
            handleChange={handleChange}
            value={locationData.contactNo}
            name={"contactNo"}
            disabled={!isAuthorized}
            id={"contactNo"}
            register={register({
              required: false,
              // pattern: UK_PHONE_NUMBER_PATTERN,
            })}
            errors={errors}
            helperText={"Please enter a valid contact number"}
          />
          {/* <span style={{ margin: 8 }}></span> */}
        </Grid>

        <Grid item xs={12} sm={6} style={{ padding: "0px 8px" }}>
          <InputBoxDefault
            disabled={!isAuthorized}
            label={"Web URL"}
            handleChange={handleChange}
            value={locationData.web}
            name={"web"}
            id={"web"}
            register={register({
              required: false,
            })}
            errors={errors}
            helperText={"Please enter a web url"}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: "0px 8px" }}>
          <InputBoxDefault
            label={"Email"}
            disabled={!isAuthorized}
            handleChange={handleChange}
            value={locationData.email}
            name={"email"}
            id={"email"}
            register={register({
              required: false,
              pattern: EMAIL_PATTERN,
            })}
            errors={errors.email}
          />
          <ValidationMessage
            message={errors.email && "Please enter a valid email address"}
          />
        </Grid>
        {/* <span style={{ margin: 8 }}></span> */}
        <Grid item xs={12} sm={6} style={{ padding: "0px 8px" }}>
          <InputBoxDefault
            disabled={!isAuthorized}
            label={"Order URL"}
            handleChange={handleChange}
            value={locationData.orderUrl}
            name={"orderUrl"}
            id={"orderUrl"}
            register={register({
              required: false,
            })}
            errors={errors}
            helperText={"Please enter a order url"}
          />
        </Grid>
      </Grid>
      {isAuthorized && (
        <div style={{ marginTop: 24, display: "flex" }}>
          <ButtonContained
            color={buttonColors.UPDATE_BUTTON_COLOR}
            label={submitButtonName.UPDATE_SUBMIT_BUTTON}
            type={"submit"}
          />
        </div>
      )}
    </>
  );
};

export default withAuthority(ContactInfo, Authorities.BASIC_INFO_READ);
