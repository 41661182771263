import React, { ChangeEvent, useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import _ from "lodash";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import { INTEGER_NUMBER_PATTERN } from "../../../../../utils/consts";
import ValidationMessage from "../../../../../components/validation/ValidationMessage";
import { useForm } from "react-hook-form";
import SwitchCommon from "../../../../../components/switches/SwitchCommon";
import TextfieldDefault from "../../../../../components/textField/TextfieldDefault";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../../utils/enum";

export interface DeliveryDetailsModalProps {
  isOpenDeliveryDetailsModal: any;
  setIsOpenDeliveryDetailsModal: any;
  deliveryDetailsNode: any;
  handleUpdateStockOrderItemDelivery: any;
  selectedStockOrderDetails: any;
  isDeliver: any;
  stockItemsDetailsObj: any;
}

interface FormData {
  orderQty: number;
  unitPrice: number;
  totalCost: number;
  deliveredQty: number;
}

/**
 * This component represents a modal for managing delivery details of an order.
 * It allows the user to adjust and confirm delivery quantities, unit prices, and total costs.
 * The component is used within a Material-UI dialog and relies on React Hook Form for form handling.
 */
const DeliveryDetailsModal: React.FunctionComponent<DeliveryDetailsModalProps> = ({
  isOpenDeliveryDetailsModal,
  setIsOpenDeliveryDetailsModal,
  deliveryDetailsNode,
  handleUpdateStockOrderItemDelivery,
  selectedStockOrderDetails,
  isDeliver,
  stockItemsDetailsObj,
}) => {
  const [unitPrice, setUnitPrice] = useState<number | undefined>(undefined);
  const [deliveredQty, setDeliveredQty] = useState(0);
  const [status, setStatus] = useState("");
  const [isAutoAdjust, setIsAutoAdjust] = useState(true);

  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      orderQty: 0,
      unitPrice: 0,
      totalCost: 0,
      deliveredQty: 0,
    },
  });

  /**
   * This useEffect is responsible for synchronizing and initializing
   * the state of a delivery details form based on the 'deliveryDetailsNode'
   * prop and the 'isDeliver' flag.
   *
   * This useEffect is triggered whenever 'deliveryDetailsNode' or 'isDeliver' changes,
   * ensuring that the form state stays in sync with the delivery details.
   */
  useEffect(() => {
    // Destructure properties from 'deliveryDetailsNode'
    const { deliveredQty, orderQty, totalCost, unitPrice, status } =
      deliveryDetailsNode;

    // Set status based on the 'status' property from 'deliveryDetailsNode'
    setStatus(status);

    // if (_.isEmpty(deliveredQty) && deliveredQty === 0) {
    //   initialDeliveredQty = orderQty;
    // } else {
    //   initialDeliveredQty = deliveredQty;
    // }
    // Calculate the initial delivered quantity based on 'isDeliver' and 'deliveredQty'
    const initialDeliveredQty = isDeliver
      ? deliveredQty
      : deliveredQty || orderQty;

    // Reset the form fields with the initial values
    reset({
      orderQty,
      unitPrice,
      totalCost,
      deliveredQty: initialDeliveredQty,
    });

    // Set 'deliveredQty' state to match the form field value
    setDeliveredQty(initialDeliveredQty);

    // Set 'unitPrice' state to match the 'unitPrice' property from 'deliveryDetailsNode'
    setUnitPrice(unitPrice);
  }, [deliveryDetailsNode, isDeliver]);

  /**
   * This function is responsible for toggling the 'isAutoAdjust' state,
   * which controls whether auto-adjustment is enabled or disabled.
   *
   * It performs the following task:
   * - Toggles the 'isAutoAdjust' state by negating its current value.
   *
   * This function is typically used as an event handler for user interactions
   * such as clicking a checkbox or button to enable or disable auto-adjustment.
   */
  const handleChangeAutoAdjust = () => {
    // Toggle the 'isAutoAdjust' state by negating its current value
    setIsAutoAdjust(!isAutoAdjust);
  };

  /**
   * This function serves as the event handler for confirming a delivery within the delivery details modal.
   *
   * It performs the following tasks:
   * - Closes the delivery details modal by setting 'isOpenDeliveryDetailsModal' to 'false'.
   * - Invokes the 'handleUpdateStockOrderItemDelivery ' function with the delivery data and status 'delivered'.
   *
   * This function is typically triggered when the user confirms a delivery action within the modal.
   */
  const handleConfirmDelivery = handleSubmit((data: any) => {
    // Close the delivery details modal
    setIsOpenDeliveryDetailsModal(false);

    // Call the 'handleUpdateStockOrderItemDelivery ' function with the delivery data and status 'delivered'
    handleUpdateStockOrderItemDelivery(data, "delivered");
  });

  /**
   * This function serves as the event handler for indicating that a delivery was not confirmed
   * within the delivery details modal.
   *
   * This function is typically triggered when the user chooses not to confirm a delivery action
   * within the modal, and it handles the associated state changes and data updates accordingly.
   */
  const handleNotConfirmDelivery = () => {
    // Extract 'orderQty' from 'deliveryDetailsNode'
    const { orderQty } = deliveryDetailsNode;

    // Create data object with default values for a 'not-delivered' status
    const data = {
      orderQty,
      unitPrice: 0,
      totalCost: 0,
      deliveredQty: 0,
    };

    // Close the delivery details modal
    setIsOpenDeliveryDetailsModal(false);

    // Call the 'handleUpdateStockOrderItemDelivery ' function with the 'not-delivered' status and reset data
    handleUpdateStockOrderItemDelivery(data, "not-delivered");
  };

  /**
   * This function is responsible for formatting a number as a string with two decimal places,
   * but if the number has no decimal part, it returns it as a whole number string.
   *
   * @param {any} num - The number to be formatted.
   * @returns {string} - The formatted number as a string.
   */
  const formatNumber = (num: any) => {
    // Check if the number has a decimal part
    if (num % 1 !== 0) {
      // If it has a decimal part, format it with two decimal places
      return num.toFixed(2);
    } else {
      // If it's a whole number, return it as a string without decimal places
      return num.toString();
    }
  };

  /**
   * This function handles changes to the delivered quantity input field.
   * It calculates and updates the total cost based on the delivered quantity and unit price.
   *
   * @param {ChangeEvent<HTMLInputElement>} e - The event object representing the input change.
   */
  const handleChangeDeliveredQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    // Parse the input value to obtain the new delivered quantity as a floating-point number
    const newDeliveredQty: number = parseFloat(e.target.value) || 0;

    // Check if auto-adjustment is enabled
    if (isAutoAdjust) {
      // Calculate the new total cost if both 'unitPrice' and 'newDeliveredQty' are valid
      const newTotalCost: number | undefined =
        unitPrice !== undefined && !isNaN(newDeliveredQty)
          ? unitPrice * newDeliveredQty
          : undefined;

      // Update the 'totalCost' field using the 'setValue' function, ensuring it's formatted correctly
      if (newTotalCost === undefined) {
        setValue("totalCost", newTotalCost);
      } else {
        setValue("totalCost", formatNumber(newTotalCost));
      }
    }

    // Set the new delivered quantity
    setDeliveredQty(newDeliveredQty);
  };

  /**
   * This function handles changes to the unit price input field.
   * It calculates and updates the total cost based on the unit price and delivered quantity.
   *
   * @param {ChangeEvent<HTMLInputElement>} e - The event object representing the input change.
   */
  const handleChangeUnitPrice = (e: ChangeEvent<HTMLInputElement>) => {
    // Parse the input value to obtain the new unit price as a floating-point number
    const newUnitPrice: number = parseFloat(e.target.value);

    // Check if auto-adjustment is enabled
    if (isAutoAdjust) {
      // Calculate the new total cost if both 'newUnitPrice' and 'deliveredQty' are valid
      const newTotalCost: number | undefined =
        newUnitPrice !== undefined && !isNaN(deliveredQty)
          ? newUnitPrice * deliveredQty
          : undefined;

      // Update the 'totalCost' field using the 'setValue' function, ensuring it's formatted correctly
      if (newTotalCost === undefined) {
        setValue("totalCost", newTotalCost);
      } else {
        setValue("totalCost", formatNumber(newTotalCost));
      }
    }

    // Set the new unit price
    setUnitPrice(newUnitPrice);
  };

  /**
   * This function handles changes to the total cost input field.
   * It calculates and updates the unit price based on the total cost and delivered quantity.
   *
   * @param {ChangeEvent<HTMLInputElement>} e - The event object representing the input change.
   */
  const handleChangeTotalCost = (e: ChangeEvent<HTMLInputElement>) => {
    // Parse the input value to obtain the new total cost as a floating-point number
    const newTotalCost: number = parseFloat(e.target.value);

    // Check if auto-adjustment is enabled
    if (isAutoAdjust) {
      // Calculate the new unit price if both 'newTotalCost' and 'deliveredQty' are valid
      const newUnitPrice: number | undefined =
        newTotalCost !== undefined && !isNaN(deliveredQty)
          ? newTotalCost / deliveredQty
          : undefined;

      // Set the new unit price
      setUnitPrice(newUnitPrice);

      // Update the 'unitPrice' field using the 'setValue' function, ensuring it's formatted correctly
      if (newUnitPrice === undefined) {
        setValue("unitPrice", newUnitPrice);
      } else {
        setValue("unitPrice", formatNumber(newUnitPrice));
      }
    }
  };

  return (
    <>
      <DialogCommonDefault
        open={isOpenDeliveryDetailsModal}
        setOpen={setIsOpenDeliveryDetailsModal}
        isNeedFixedHeight={false}
      >
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          {stockItemsDetailsObj[deliveryDetailsNode.stockItemId]
            ? stockItemsDetailsObj[deliveryDetailsNode.stockItemId]
            : "Ordered Details"}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            {/* Quantity (Qty) Textfield */}
            <Grid item xs={9} sm={10}>
              <TextfieldDefault
                id="orderQty"
                name="orderQty"
                type="number"
                label="Qty"
                disabled
                inputRef={register({
                  required: "Order QTY is required.",
                })}
              />
            </Grid>

            {/* Auto Adjust Switch */}
            <Grid
              item
              xs={3}
              sm={2}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <FormControlLabel
                style={{ marginLeft: "0px", marginRight: "0px" }}
                value="autoAdjust"
                disabled={
                  selectedStockOrderDetails.status !== "ordered" &&
                  selectedStockOrderDetails.status !== "viewed"
                }
                control={<SwitchCommon />}
                label={
                  <Typography variant="caption" color="textSecondary">
                    Auto Adjust
                  </Typography>
                }
                name="autoAdjust"
                checked={isAutoAdjust}
                labelPlacement="bottom"
                onChange={handleChangeAutoAdjust}
              />
            </Grid>

            {/* Delivered Quantity (Delivered QTY) Textfield */}
            <Grid item xs={12}>
              <TextfieldDefault
                id="deliveredQty"
                name="deliveredQty"
                type="number"
                label="Delivered QTY"
                disabled={
                  selectedStockOrderDetails.status !== "ordered" &&
                  selectedStockOrderDetails.status !== "viewed"
                }
                onChange={(e: any) => handleChangeDeliveredQuantity(e)}
                inputRef={register({
                  required: "Delivered QTY is required.",
                  min: {
                    value: 0,
                    message: "Delivered QTY cannot be negative.",
                  },
                  pattern: {
                    value: INTEGER_NUMBER_PATTERN,
                    message: "Please enter a Delivered QTY.",
                  },
                })}
              />
              {errors.deliveredQty ? (
                <ValidationMessage message={errors.deliveredQty.message} />
              ) : (
                ""
              )}
            </Grid>

            {/* Unit Price Textfield */}
            <Grid item xs={12}>
              <TextfieldDefault
                id="unitPrice"
                name="unitPrice"
                type="number"
                label="Unit Price"
                disabled={
                  selectedStockOrderDetails.status !== "ordered" &&
                  selectedStockOrderDetails.status !== "viewed"
                }
                onChange={(e: any) => handleChangeUnitPrice(e)}
                inputRef={register({
                  required: "Unit Price is required.",
                  min: {
                    value: 0,
                    message: "Unit Price cannot be negative",
                  },
                })}
              />
              {errors.unitPrice ? (
                <ValidationMessage message={errors.unitPrice.message} />
              ) : (
                ""
              )}
            </Grid>

            {/* Total Cost Textfield */}
            <Grid item xs={12}>
              <TextfieldDefault
                id="totalCost"
                name="totalCost"
                type="number"
                label="Total"
                disabled={
                  selectedStockOrderDetails.status !== "ordered" &&
                  selectedStockOrderDetails.status !== "viewed"
                }
                onChange={(e: any) => handleChangeTotalCost(e)}
                inputRef={register({
                  required: "Total is required.",
                  min: {
                    value: 0,
                    message: "Total cannot be negative.",
                  },
                })}
              />
              {errors.totalCost ? (
                <ValidationMessage message={errors.totalCost.message} />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ marginBottom: "12px" }}>
          <div style={{ display: "block", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <ButtonCommon
                onClick={() => setIsOpenDeliveryDetailsModal(false)}
                variant="contained"
                style={{
                  fontSize: 11,
                  width: "80px",
                  marginRight: "16px",
                  marginLeft: "20px"
                }}
                color={buttonColors.CANCEL_BUTTON_COLOR}
              >
                Back
              </ButtonCommon>

              <ButtonCommon
                onClick={handleNotConfirmDelivery}
                disabled={
                  status === "not-delivered" ||
                  (selectedStockOrderDetails.status !== "ordered" &&
                    selectedStockOrderDetails.status !== "viewed")
                }
                variant="contained"
                style={{
                  fontSize: 11,
                  width: "140px",
                  marginRight: "14px",
                }}
                color={buttonColors.DELETE_BUTTON_COLOR}
              >
                Not Delivered
              </ButtonCommon>

              <ButtonCommon
                onClick={handleConfirmDelivery}
                disabled={
                  !isDirty ||
                  (selectedStockOrderDetails.status !== "ordered" &&
                    selectedStockOrderDetails.status !== "viewed")
                }
                variant="contained"
                style={{
                  fontSize: 11,
                  width: "180px",
                  marginRight: "16px",
                }}
                color={buttonColors.CREATE_BUTTON_COLOR}
              >
                Confirm Item Delivery
              </ButtonCommon>
            </div>
          </div>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default DeliveryDetailsModal;
