import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import SaleCategoryReportInfoNode from "./SaleCategoryReportInfoNode";
import { salesItemsFilterObject } from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { fetchAllCategoryReportInfo } from "../../../../services/salesApp/categoryReportService";

/**
 * SaleCategoryReport Component
 *
 * This component is responsible for rendering the sales category report page.
 * It fetches data related to category sales reports based on selected filters and location.
 * The component includes functionality for filtering data, handling location selection,
 * and displaying the sales report information. It also handles error messages and loading states.
 */
const SaleCategoryReport: React.FunctionComponent<{}> = () => {
  const [itemsReportNodeList, setItemsReportNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /**
   * Function: getSaleItemReportInfo
   *
   * This function is responsible for fetching sales category report data based on specified filters and location.
   *
   * @param {any} filter - The filter object containing parameters for the sales report.
   * @param {boolean} basedOnShift - A boolean indicating whether the report data should be based on shift timings.
   */
  const getSaleItemReportInfo = async (filter: any, basedOnShift: boolean) => {
    try {
      // Attempt to fetch category report info using the provided filter and location ID
      const res = await fetchAllCategoryReportInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );

      // Update component state with fetched report data and indicate that loading is complete
      setIsOpenSkeletonLoading(false);
      setItemsReportNodeList(res.data.data);
    } catch (err) {
      // If an error occurs during fetching, set error state and indicate loading is complete
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get location details. */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) => {
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            });
          });
          const locationName = getFilterListFromArrayObject(
            locationList,
            match.params.locationId,
          );
        }
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  /**
   * useEffect Hook
   *
   * This hook sets the document title and fetches location data when the component mounts.
   * It runs only once on component mount due to the empty dependency array.
   */
  useEffect(() => {
    // Set the document title to "Sale - Category Sales"
    document.title = "Sale - Category Sales";

    // Fetch location data with an empty search query
    getAllFilterLocation("");
  }, []);

  // Get filter data
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    setIsOpenSkeletonLoading(true);
    getSaleItemReportInfo(filterData, basedOnShift);
  };

  /**
   * Function: handleOnClickText
   *
   * This function is triggered when a text is clicked.
   * It opens the filter card and scrolls the window to the top with smooth behavior.
   */
  const handleOnClickText = () => {
    // Open the filter card
    setOpenFilterCard(true);

    // Scroll the window to the top with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLocationSelectorTypingList = (searchName: any) => {};

  return (
    <>
      <SaleReport
        handleFilterData={handleFilterData}
        locationSelectorList={locationSelectorList}
        handleLocationSelectorTypingList={handleLocationSelectorTypingList}
        setFilterDetails={setFilterDetails}
        availableFilter={salesItemsFilterObject}
        isOpenSkeletonLoading={isOpenSkeletonLoading}
        nodeList={itemsReportNodeList}
        filterDetails={filterDetails}
        title={""}
        isChart={false}
        isGetLocationInfo={isGetLocationInfo}
        isSaleItem={true}
        locationSelectedList={locationSelectedList}
        setLocationSelectedList={setLocationSelectedList}
        openFilterCard={openFilterCard}
        setOpenFilterCard={setOpenFilterCard}
        handleOnClickText={handleOnClickText}
        topic="Sales Category Report"
        isReport={true}
      >
        <SaleCategoryReportInfoNode
          itemsReportNodeList={itemsReportNodeList}
          filterDetails={filterDetails}
          locationSelectedList={locationSelectedList}
          handleOnClickText={handleOnClickText}
        />
      </SaleReport>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(SaleCategoryReport, Authorities.SALE_READ);
