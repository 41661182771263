import {
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import _ from "lodash";
import * as React from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { CustomTheme } from "../../types/customTheme";
import SubMenu from "./SubMenu";

type Props = {
  drawerWidth: number;
};

const useStyles = makeStyles<Theme, Props>((theme: any) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    drawer: {
      width: ({ drawerWidth }) => drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerRoot: {
      "&.MuiPaper-root": {
        backgroundColor: theme.palette.background.default,
        border: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: {
      padding: 8,
      margin: 8,
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    drawerOpen: {
      zIndex: 0,
      width: ({ drawerWidth }) => drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginTop: 58,
    },
    drawerClose: {
      zIndex: 0,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
      marginTop: 58,
    },
    listItemTab: {
      borderRadius: "0 48px 48px 0",
      border: "none",
    },
  }),
);

export interface SideBarProps {
  navRoutes: any;
  open: boolean;
  drawerWidth: number;
  setOpen: any;
}

/* This component renders pages related to the application. */
const SideBar: React.FunctionComponent<SideBarProps> = ({
  navRoutes,
  open,
  drawerWidth,
  setOpen,
}) => {
  let match: any = useRouteMatch();
  const classes = useStyles({ drawerWidth });
  const { pathname } = useLocation();

  /* If there is a '/' at the end of the URL, removing it. */
  let url = "";
  if (match.url.charAt(match.url.length - 1) === "/") {
    url = match.url.slice(0, -1);
  } else {
    url = match.url;
  }
  const theme: CustomTheme = useTheme();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerRoot, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      anchor="left"
    >
      <div className={classes.toolbar}></div>
      <List component="div" disablePadding>
        {navRoutes.map((route: any, index: Number) =>
          route.childRoutes ? (
            <SubMenu
              key={route.path}
              title={route.title}
              icon={route.icon}
              route={route}
              openSideBar={open}
              setOpenSideBar={setOpen}
            />
          ) : (
            // "rftg"
            <div key={route.path}>
              {match.params.locationId && route.isAuthorized ? (
                !open ? (
                  <Tooltip
                    title={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", color: "white" }}
                      >
                        {route.title}
                      </Typography>
                    }
                  >
                    <ListItem
                      className={classes.listItemTab}
                      button
                      alignItems="center"
                      component={Link}
                      to={url + route.path}
                      selected={_.isEqual(pathname, url + route.path)}
                      style={{ height: 48 }}
                    >
                      <ListItemIcon
                        style={{
                          minWidth: "unset",
                          color: theme.palette.custom.orange.contrastText,
                        }}
                      >
                        {route.icon}
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                ) : (
                  <ListItem
                    className={classes.listItemTab}
                    button
                    alignItems="center"
                    component={Link}
                    to={url + route.path}
                    selected={_.isEqual(pathname, url + route.path)}
                    style={{ height: 48 }}
                  >
                    <ListItemIcon
                      style={{
                        minWidth: "unset",
                        color: theme.palette.custom.orange.contrastText,
                      }}
                    >
                      {route.icon}
                    </ListItemIcon>
                    <span style={{ margin: 4 }}></span>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color: theme.palette.custom.orange.contrastText,
                          }}
                        >
                          {route.title}
                        </Typography>
                      }
                    />
                  </ListItem>
                )
              ) : null}
            </div>
          ),
        )}
      </List>
    </Drawer>
  );
};

export default SideBar;
