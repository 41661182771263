import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import ApplyToConditionSelectionModal from "./ApplyToConditionSelectionModel";
import {
  DISCOUNT_TYPE_ALL,
  DISCOUNT_TYPE_CATEGORY,
  DISCOUNT_TYPE_ITEM,
} from "../../../utils/consts";
import CardCommon from "../../card/CardCommon";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "self-start",
    flex: 1,
  },
}));

export interface AvailabilitySelectionProps {
  disabled?: boolean;
  nodeData: any;
  categoryList: any;
  handleSubmitApplyTo: any;
  setApplyToConditionErrorMessage: any;
}

/* This component shows the applyTo selection modal. */
const ApplyToSelection: React.FunctionComponent<AvailabilitySelectionProps> = ({
  disabled,
  nodeData,
  categoryList,
  handleSubmitApplyTo,
  setApplyToConditionErrorMessage,
}) => {
  const [open, setOpen] = React.useState(false);
  const [applicableList, setApplicableList] = React.useState<any>([]);
  const [applicableInitialList, setApplicableInitialList] = React.useState<any>(
    [],
  );
  const [applicableCategoryList, setApplicableCategoryList] =
    React.useState<any>([]);
  const [applicableCategoryInitialList, setApplicableCategoryInitialList] =
    React.useState<any>([]);
  const [applicableItemsList, setApplicableItemsList] = React.useState<any>([]);
  const [applicableItemsInitialList, setApplicableItemsInitialList] =
    React.useState<any>([]);

  const [applyToCondition, setApplyToCondition] = React.useState("");
  const [applyToConditionInitial, setApplyToConditionInitial] =
    React.useState("");
  const [availableApplyToList, setAvailableApplyToList] = React.useState([]);
  const [error, setError] = React.useState("");
  const classes = useStyles();

  const getNodeData = React.useCallback((nodeValues) => {
    const { applyToCondition, applyTo } = nodeValues;
    if (applyToCondition === "items") {
      setApplicableItemsInitialList(applyTo);
      setApplicableItemsList(applyTo);
      setApplicableCategoryList([]);
      setApplicableCategoryInitialList([]);
      setApplicableList([]);
      setApplicableInitialList([]);
    } else {
      setApplicableItemsInitialList([]);
      setApplicableItemsList([]);
      setApplicableCategoryList([]);
      setApplicableCategoryInitialList([]);
      setApplicableList(applyTo);
      setApplicableInitialList(applyTo);
    }
    setApplyToCondition(applyToCondition);
    setApplyToConditionInitial(applyToCondition);
  }, []);

  React.useEffect(() => {
    getNodeData(nodeData);
  }, [nodeData]);

  React.useEffect(() => {
    if (!_.isEmpty(categoryList)) {
      let updatedCategoryIdSet: any = [];
      Object.values(categoryList).forEach((cateMenu: any) => {
        Object.values(cateMenu.data).forEach((category: any) => {
          const output = Object.values(category.data).filter(
            (item: any) => !nodeData.applyTo.includes(item.menuId),
          );
          if (_.isEmpty(output)) {
            updatedCategoryIdSet.push(category.catId);
          }
        });
      });

      setApplicableCategoryList(updatedCategoryIdSet);
      setApplicableCategoryInitialList(updatedCategoryIdSet);
    }
  }, [categoryList, nodeData]);

  /* handleSubmitApplyTo function call to after click apply button.
  applyToCondition When "all" the applicableList is empty. */
  const handleSubmitData = () => {
    // If 'applyToCondition' is 'all', an empty list is sent to the backend.
    if (applyToCondition === DISCOUNT_TYPE_ALL) {
      handleSubmitApplyTo([], applyToCondition);
      setApplicableList([]);
      setApplicableItemsList([]);
      setApplicableCategoryList([]);
      setOpen(false);
    } else if (applyToCondition === "items") {
      if (!_.isEmpty(applicableItemsList)) {
        setOpen(false);
        handleSubmitApplyTo(applicableItemsList, applyToCondition);
      } else {
        /* When the applyToCondition is not selected 'all' and no category is selected, 
       when trying to add an applyTo, an error message is displayed. */
        setError("Please select Items");
      }
    } else if (applyToCondition === "categories") {
      if (!_.isEmpty(applicableList)) {
        setOpen(false);
        handleSubmitApplyTo(applicableList, applyToCondition);
      } else {
        /* When the applyToCondition is not selected 'all' and no category is selected, 
         when trying to add an applyTo, an error message is displayed. */
        setError("Please select categories");
      }
    }
  };

  // Set selected catMenu id to applicableList.
  const handleApplyLToList = (e: any) => {
    const { value, checked } = e.target;
    // If the catId is selected, add that catId to a list.
    if (checked) {
      const selectedCatIdList: any = new Set([...applicableList, value]);
      setApplicableList([...selectedCatIdList]);
    } else {
      // If the catId is not selected, remove that catId from the list.
      const selectedCatIdList: any = applicableList.filter(
        (catId: String) => value !== catId,
      );
      setApplicableList(selectedCatIdList);
    }
  };

  const handleApplyLToListItems = (
    e: any,
    itemType: any,
    itemsOrCategory: any,
  ) => {
    const { value, checked } = e.target;

    const updateItemsList = (newList: any) => {
      setApplicableItemsList(newList);
    };

    const updateCategoryList = (newList: any) => {
      setApplicableCategoryList(newList);
    };

    if (itemType === "items") {
      if (checked) {
        const updatedItemIdSet = new Set([...applicableItemsList, value]);

        const filteredItems = Object.values(itemsOrCategory.data).filter(
          (item: any) => ![...updatedItemIdSet].includes(item.menuId),
        );

        if (filteredItems.length === 0) {
          const updatedCategoryIdSet = new Set([
            ...applicableCategoryList,
            itemsOrCategory.catId,
          ]);

          updateCategoryList([...updatedCategoryIdSet]);
        }

        updateItemsList([...updatedItemIdSet]);
      } else {
        const updatedCategoryList = applicableCategoryList.filter(
          (catId: any) => value !== catId && itemsOrCategory.catId !== catId,
        );

        const updatedItemList = applicableItemsList.filter(
          (itemId: any) => value !== itemId,
        );

        updateCategoryList(updatedCategoryList);
        updateItemsList(updatedItemList);
      }
    } else {
      const menuIdListSelectedCategory = Object.keys(itemsOrCategory).map(
        (key) => itemsOrCategory[key].menuId,
      );
      if (checked) {
        const updatedCategorySet = new Set([...applicableCategoryList, value]);

        updateCategoryList([...updatedCategorySet]);
        updateItemsList((prev: any) => [
          ...prev,
          ...menuIdListSelectedCategory,
        ]);
      } else {
        const updatedItemsList = applicableItemsList.filter(
          (item: any) => !menuIdListSelectedCategory.includes(item),
        );

        const updatedCategoryList = applicableCategoryList.filter(
          (catId: any) => value !== catId,
        );
        updateCategoryList(updatedCategoryList);
        updateItemsList(updatedItemsList);
      }
    }
  };

  /* Close applyTo condition modal after click cancel button and reset data */
  const handleClose = () => {
    setApplicableList(applicableInitialList);
    setApplicableItemsList(applicableItemsInitialList);
    setApplicableCategoryList(applicableCategoryInitialList);
    setApplyToCondition(applyToConditionInitial);
    setOpen(false);
  };

  // Change available apply list and apply condition
  const handleApplyToCondition = (discountType: string) => {
    setError("");
    setApplicableCategoryList(applicableCategoryInitialList);
    setApplicableList(applicableInitialList);
    setApplicableItemsList(applicableItemsInitialList);
    setApplyToCondition(discountType);
    if (discountType === DISCOUNT_TYPE_ALL || _.isEmpty(discountType)) {
      setAvailableApplyToList([]);
    } else if (discountType === DISCOUNT_TYPE_CATEGORY || DISCOUNT_TYPE_ITEM) {
      setAvailableApplyToList(categoryList);
    } else {
      setAvailableApplyToList([]);
      setError("Discount type not support.");
    }
  };

  return (
    <CardCommon
      backgroundColor={"entity_highlight_background"}
      style={{ margin: "8px 0" }}
    >
      <div className={classes.root}>
        <div className={classes.content}>
          <div>
            {applyToCondition ? (
              <Typography style={{ textTransform: "capitalize" }}>
                {applyToCondition}
              </Typography>
            ) : (
              <Typography>Add apply to condition</Typography>
            )}
          </div>
        </div>
        <ApplyToConditionSelectionModal
          open={open}
          setOpen={setOpen}
          disabled={disabled}
          availableApplyToList={availableApplyToList}
          handleApplyToCondition={handleApplyToCondition}
          handleApplyLToList={handleApplyLToList}
          applicableList={applicableList}
          handleSubmitData={handleSubmitData}
          handleCloseModal={handleClose}
          applyToCondition={applyToCondition}
          error={error}
          setApplyToConditionErrorMessage={setApplyToConditionErrorMessage}
          nodeData={nodeData}
          handleApplyLToListItems={handleApplyLToListItems}
          applicableItemsList={applicableItemsList}
          applicableCategoryList={applicableCategoryList}
        />
      </div>
    </CardCommon>
  );
};

export default ApplyToSelection;
