import { createMuiTheme } from "@material-ui/core/styles";
import commonThemeDark from "../../../root/theme/dark";
import commonThemeLight from "../../../root/theme/light";
import { CustomColors } from "../../../types/customTheme";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    custom: CustomColors;
  }

  interface PaletteOptions {
    custom: CustomColors;
  }
}

export function initTheme({ isDark }: { isDark: boolean }) {
  return isDark
    ? createMuiTheme({
        palette: {
          custom: commonThemeDark.palette.custom,
          background: commonThemeDark.palette.background,
          type: commonThemeDark.palette.type,
          primary: {
            main: commonThemeDark.palette.custom.blue.dark,
          },
          secondary: {
            main: commonThemeDark.palette.custom.blue.secondary,
          },
        },
      })
    : createMuiTheme({
        palette: {
          custom: commonThemeLight.palette.custom,
          background: commonThemeLight.palette.background,
          type: commonThemeLight.palette.type,
          primary: {
            main: commonThemeDark.palette.custom.blue.dark,
          },
          secondary: {
            main: commonThemeDark.palette.custom.blue.secondary,
          },
        },
      });
}
