import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import CardCommon from "../../../../components/card/CardCommon";
import { Skeleton } from "@material-ui/lab";
import {
  convertDateTimeFormat,
  convertDateTimeFormatAndConvertTimeBold,
} from "../../../../utils/ConvertDateTimeFormat";
import { OrderChannelDetailsObject } from "../../../../utils/consts/list";
import StorefrontIcon from "@material-ui/icons/Storefront";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import BlockIcon from "@material-ui/icons/Block";
import EditSalePaymentType from "./EditSalePaymentType";
import PaymentTypeSelector from "./PaymentTypeSelector";
import DiscountSelector from "./DiscountSelector";
import { CustomTheme } from "../../../../types/customTheme";

export interface OrdersDetailsTabProps {
  nodeData: any;
  locationName: any;
  saleId: any;
  handleGetSalesItems: any;
  setIsLoading: any;
  saleItemList: any;
  saleVatList: any;
  isLoading: any;
  handleGetOtherPayment: any;
  otherPayment: any;
  isCancelledSale: any;
  getCancelSaleInfo: any;
  saleCancelItemList: any;
  userList: any;
  getUserInfo: any;
  locationId: any;
  updatePaymentType: any;
  isPaymentTypeLoading: any;
  changedPaymentType: any;
  setOtherPayment: any;
  updateDiscountType: any;
  isDiscountLoading: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    rightSideTopAlign: {
      marginTop: "0px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "12px",
      },
    },
    topAlign: {
      marginTop: "10px",
    },
    gridTopAlignRightSide: {
      marginTop: "12px",
    },
    style: {
      marginBottom: "16px",
      display: "flex",
    },
    gridTopAlign: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
    },
    vatHeaderStyle: {
      marginLeft: "18px",
      marginRight: "18px",
      height: "28px",
      marginBottom: "4px",
      display: "flex",
      placeItems: "center",
      justifyContent: "start",
      backgroundColor: theme.palette.custom.blue.main,
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    vatStyle: {
      color: theme.palette.custom.blue.contrastText,
    },
    vatBodyStyle: {
      marginTop: "4px",
      marginLeft: "18px",
      marginRight: "18px",
      height: "28px",
      marginBottom: "4px",
      display: "flex",
      placeItems: "center",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.imgBackground,
      [theme.breakpoints.down("sm")]: {
        marginLeft: "16px",
        marginRight: "16px",
      },
    },
    locationNameStyle: {
      marginLeft: "4px",
      backgroundColor: theme.palette.custom.blue.main,
    },
    channelNameStyle: {
      marginLeft: "12px",
    },
    textColor: {
      color: theme.palette.custom.blue.contrastText,
    },
    dividerStyle: {
      marginTop: "12px",
      marginBottom: "16px",
      width: "100%",
      height: "2px",
      marginLeft: "20px",
      marginRight: "20px",
      backgroundColor: theme.palette.background.entity_background,
    },
    cancelBoxStyle: {
      backgroundColor: theme.palette.custom.red.main,
      width: "100%",
      display: "flex",
      height: "120px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      marginRight: "12px",
      marginLeft: "2px",
    },
    mealDealStatusIsZeroAndOneStyleInFirstColumn: {
      marginTop: "12px",
    },
    firstSaleItemStyle: {
      marginTop: "4px",
    },
    mealDealStatusIsTwoStyle: {
      marginTop: "2px",
    },
    mealDealStatusIsZeroAndOneFontSizeStyle: {
      fontWeight: "bolder",
      fontSize: "14px",
    },
    mealDealStatusIsFontSizeStyle: {
      fontWeight: "normal",
      fontSize: "10px",
    },
    firstColumnMargin: {
      marginRight: "12px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "20px",
      },
    },
  }),
);

/* This component displays information about the selected sale item.
   This also makes the API call that gets sales and the API call that gets other payments. */
const SalesDetailsTab: React.FunctionComponent<OrdersDetailsTabProps> = ({
  nodeData,
  locationName,
  saleId,
  handleGetSalesItems,
  setIsLoading,
  saleItemList,
  saleVatList,
  isLoading,
  handleGetOtherPayment,
  otherPayment,
  isCancelledSale,
  getCancelSaleInfo,
  saleCancelItemList,
  userList,
  getUserInfo,
  locationId,
  updatePaymentType,
  isPaymentTypeLoading,
  changedPaymentType,
  setOtherPayment,
  updateDiscountType,
  isDiscountLoading,
}) => {
  const [customerDetails, setCustomerDetails] = useState<any>({});
  const [discountPercent, setDiscountPercent] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  /* This useEffect hook to set the isLoading state to true when the component mounts.
    That state indicates loading a skeleton of the component until the data for the component arrives. */
  useEffect(() => {
    setIsLoading(true);
  }, []);

  /* Making API calls about sales details and other payments. */
  useEffect(() => {
    // Check if either userList or userList[nodeData.userId] is empty
    if (_.isEmpty(userList) || _.isEmpty(userList[nodeData.userId])) {
      // If either is empty, call getUserInfo() function with locationId parameter
      getUserInfo(locationId);
    }

    if (isCancelledSale) {
      // Get the sale details
      getCancelSaleInfo(saleId);
    }
  }, [saleId]);

  /* Making API calls about sales details and other payments. */
  useEffect(() => {
    // Get the sale items details
    handleGetSalesItems(saleId);

    /* Only if the other payment value is greater than 0, 
         make the API call that retrieves the other payment information. */
    if (nodeData.otherPayment > 0) {
      // Get the other payment details
      handleGetOtherPayment(saleId);
    } else {
      setOtherPayment([]);
    }
  }, [nodeData]);

  // Use the useEffect hook to run a function after rendering the component
  useEffect(() => {
    setDiscountPercent(nodeData.discountPercent);
    let customerObject: any = {};
    // Check if nodeData.customerObject is not empty
    if (!_.isEmpty(nodeData.customerObject)) {
      // If it's not empty, parse the JSON string and assign it to customerObject
      customerObject = JSON.parse(nodeData.customerObject);
      // Set the customer details state to customerObject
      setCustomerDetails(customerObject);
    }
  }, [otherPayment, nodeData]);

  /* Get vat using percentage */
  const getVat = (percent: any) => {
    let sum = 0;
    // Iterate over each item in the saleItemList array using the map method
    saleItemList.map((item: any) => {
      // Check if the item's vatPercent matches the percent parameter
      if (item.vatPercent === percent) {
        sum = sum + item.vatAmount;
      }
    });
    return sum;
  };

  /* Get incVat using percentage */
  const getIncVat = (percent: any) => {
    let sum = 0;
    // Iterate over each item in the saleItemList array using the map method
    saleItemList.map((item: any) => {
      // Check if the item's vatPercent matches the percent parameter
      if (item.vatPercent === percent) {
        sum = sum + item.amount;
      }
    });
    return sum;
  };

  /* Get user name using user id */
  const handleUserName = (userId: any) => {
    if (!_.isEmpty(userList) && !_.isEmpty(userList[userId])) {
      return userList[userId].label;
    } else {
      return userId;
    }
  };

  /* Create a orderChannel pin. */
  const handleOrderChannelPin = (nodeData: any) => {
    // Destructure the platform and orderChannel properties from the nodeData object
    const { platform, orderChannel } = nodeData;
    // If the platform is "online"
    if (platform === "online") {
      // Return a Chip component with a background color based on the orderChannel and a label with the channel name
      if (orderChannel === "wix") {
        return (
          <Chip
            className={classes.channelNameStyle}
            style={{
              backgroundColor: OrderChannelDetailsObject["eatpresto"].color,
            }}
            label={
              <Typography
                variant="body1"
                className={classes.textColor}
                align="left"
              >
                {OrderChannelDetailsObject["eatpresto"].channelName}
              </Typography>
            }
          />
        );
      } else if (!_.isEmpty(OrderChannelDetailsObject[orderChannel])) {
        return (
          <Chip
            className={classes.channelNameStyle}
            style={{
              backgroundColor: OrderChannelDetailsObject[orderChannel].color,
            }}
            label={
              <Typography
                variant="body1"
                className={classes.textColor}
                align="left"
              >
                {OrderChannelDetailsObject[orderChannel].channelName}
              </Typography>
            }
          />
        );
      } else {
        return (
          <Chip
            className={classes.channelNameStyle}
            style={{
              backgroundColor: OrderChannelDetailsObject["default"].color,
            }}
            label={
              <Typography
                variant="body1"
                className={classes.textColor}
                align="left"
              >
                {orderChannel}
              </Typography>
            }
          />
        );
      }
    } else {
      // If the platform is not "online", return a Chip component with a fixed background color and a label with "EPOS"
      return (
        <Chip
          className={classes.channelNameStyle}
          style={{ backgroundColor: "#F7931E" }}
          label={
            <Typography
              variant="body1"
              className={classes.textColor}
              align="left"
            >
              EPOS
            </Typography>
          }
        />
      );
    }
  };

  const classes = useStyles();

  /* Change the margin top of the sale item details using mealDealStatus. */
  const handleSaleItemTitleStyle = (
    previousObject: any,
    currentObject: any,
    index: number,
  ) => {
    if (
      (previousObject && previousObject.mealDealStatus === 0) ||
      (previousObject &&
        previousObject.mealDealStatus === 2 &&
        currentObject.mealDealStatus === 1) ||
      (previousObject &&
        previousObject.mealDealStatus === 2 &&
        currentObject.mealDealStatus === 0) ||
      (previousObject &&
        previousObject.mealDealStatus === 1 &&
        currentObject.mealDealStatus === 0)
    ) {
      // If any of the conditions are true, apply a class with larger margin top
      return classes.mealDealStatusIsZeroAndOneStyleInFirstColumn;
    } else if (index === 0) {
      // If none of the conditions are true but the index is 0, apply a class with top of the raw
      return classes.firstSaleItemStyle;
    } else {
      // If none of the conditions are true and the index is not 0, apply a class with smaller margin top
      return classes.mealDealStatusIsTwoStyle;
    }
  };

  /* Change the fontsize sale item details using mealDealStatus. */
  const handleSaleItemDataStyle = (mealDealStatus: any) => {
    if (mealDealStatus === 0 || mealDealStatus === 1) {
      // If the meal deal status is 0 or 1, apply a class with larger font size
      return classes.mealDealStatusIsZeroAndOneFontSizeStyle;
    } else {
      // Otherwise, apply a class with a smaller font size
      return classes.mealDealStatusIsFontSizeStyle;
    }
  };

  /* This function takes an address string and returns a JSX element with each line
     of the address separated by a line break */
  const handleCustomerAddress = (address: any) => {
    return address.split("\n").map(
      (line: any, index: number) =>
        line !== "" && (
          // Create a div element with the line of the address and a line break
          <div key={index}>
            {line}
            <br />
          </div>
        ),
    );
  };

  /* This code defines a function that takes a payment type and creates a request body object with its data. 
     Then, it calls an updatePaymentType function with the request body object as an argument. 
     The locationId and saleId variables are assumed to be in scope and defined elsewhere. */
  const handleUpdatePaymentType = (selectedPaymentType: any) => {
    const requestBody = {
      typeBefore: selectedPaymentType.typeBefore,
      otherPayId: selectedPaymentType.otherPayId,
      locationId: locationId,
      saleId: saleId,
      amount: selectedPaymentType.amount,
      typeAfter: selectedPaymentType.typeAfter,
    };
    updatePaymentType(requestBody);
  };

  /* This code defines a function that takes a selected discount value and creates a request body object with it.
    Then, it calls an updateDiscountType function with the request body object as an argument. 
    The locationId and saleId variables are assumed to be in scope and defined elsewhere */
  const handleUpdateDiscount = (selectedDiscount: any) => {
    const requestBody = {
      locationId: locationId,
      saleId: saleId,
      discount: parseFloat(selectedDiscount).toFixed(2),
    };
    updateDiscountType(requestBody);
  };

  const theme: CustomTheme = useTheme();
  const mobileScreen = useMediaQuery("(max-width: 600px)");

  return (
    <Grid container style={{ margin: "10px" }}>
      {!isLoading ? (
        <>
          {mobileScreen ? (
            <>
              <Grid item xs={12} className={classes.style}>
                <Chip
                  className={classes.locationNameStyle}
                  label={
                    <Typography
                      variant="body1"
                      className={classes.textColor}
                      align="left"
                    >
                      {locationName}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={8} style={{display: "flex", justifyContent: "start", marginBottom: "16px"}}>
                {handleOrderChannelPin(nodeData)}
              </Grid>
              {_.isEmpty(saleCancelItemList) && (
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "start",
                    paddingRight: "20px",
                    paddingTop: "6px",
                  }}
                >
                  <EditSalePaymentType setIsEdit={setIsEdit} isEdit={isEdit} />
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={10} className={classes.style}>
                <Chip
                  className={classes.locationNameStyle}
                  label={
                    <Typography
                      variant="body1"
                      className={classes.textColor}
                      align="left"
                    >
                      {locationName}
                    </Typography>
                  }
                />
                {handleOrderChannelPin(nodeData)}
              </Grid>
              {_.isEmpty(saleCancelItemList) && (
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "start",
                    paddingRight: "20px",
                    paddingTop: "6px",
                  }}
                >
                  <EditSalePaymentType setIsEdit={setIsEdit} isEdit={isEdit} />
                </Grid>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Skeleton
              style={{
                marginTop: "4px",
                marginRight: "8px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
              }}
              variant="rect"
              width={160}
              height={28}
            />
            <Skeleton
              style={{
                marginTop: "4px",
                marginRight: "8px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
              }}
              variant="rect"
              width={100}
              height={28}
            />
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Grid container>
          {isCancelledSale &&
            (!isLoading ? (
              !_.isEmpty(saleCancelItemList) && (
                <Grid
                  container
                  style={{ marginTop: "2px", marginBottom: "12px" }}
                >
                  <Box className={classes.cancelBoxStyle}>
                    {saleCancelItemList.map((data: any) => (
                      <Grid container style={{ marginTop: "4px" }}>
                        <Grid
                          item
                          xs={10}
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            paddingLeft: "8px",
                            marginBottom: "4px",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "24px", fontWeight: "normal" }}
                            className={classes.textColor}
                          >
                            CANCELLED SALE
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            paddingRight: "4px",
                            marginTop: "4px",
                          }}
                        >
                          <BlockIcon
                            style={{ color: "white" }}
                            fontSize="large"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            paddingLeft: "8px",
                            marginTop: "4px",
                          }}
                        >
                          <Typography
                            variant={"body2"}
                            className={classes.textColor}
                            style={{ marginTop: "2px" }}
                          >
                            Cancelled by
                          </Typography>
                          {userList && (
                            <Typography
                              variant={"body1"}
                              style={{
                                fontWeight: "bolder",
                                paddingLeft: "8px",
                              }}
                              className={classes.textColor}
                            >
                              {handleUserName(data.userId)}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            paddingLeft: "8px",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              fontSize: "18px",
                              color: "white",
                            }}
                          >
                            {convertDateTimeFormat(data.date)}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Grid>
              )
            ) : (
              <Skeleton
                style={{
                  marginTop: "16px",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_border}`,
                }}
                variant="rect"
                width={"100%"}
                height={120}
              />
            ))}

          {!isLoading ? (
            <Grid item xs={12} className={classes.firstColumnMargin}>
              <CardCommon backgroundColor={"entity_highlight_background"}>
                <Grid container style={{ marginTop: "12px" }}>
                  {!_.isEmpty(saleItemList) && (
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingLeft: "20px",
                        color: theme.palette.custom.orange.contrastText,
                      }}
                    >
                      Quantity
                    </Grid>
                  )}
                  {!_.isEmpty(saleItemList) && (
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingLeft: "24px",
                        color: theme.palette.custom.orange.contrastText,
                      }}
                    >
                      Item
                    </Grid>
                  )}
                  {!_.isEmpty(saleItemList) && (
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "20px",
                        color: theme.palette.custom.orange.contrastText,
                      }}
                    >
                      Price
                    </Grid>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Box className={classes.dividerStyle}></Box>
                  </div>
                  {!_.isEmpty(saleItemList)
                    ? saleItemList.map(
                        (item: any, index: number, array: any) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  paddingLeft: "20px",
                                }}
                                className={handleSaleItemTitleStyle(
                                  array[index - 1],
                                  item,
                                  index,
                                )}
                              >
                                <Typography
                                  className={handleSaleItemDataStyle(
                                    item.mealDealStatus,
                                  )}
                                  style={{
                                    color:
                                      theme.palette.custom.orange.contrastText,
                                  }}
                                >
                                  {item.mealDealStatus !== 2 && item.qty}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                className={handleSaleItemTitleStyle(
                                  array[index - 1],
                                  item,
                                  index,
                                )}
                              >
                                <Typography
                                  align="left"
                                  className={handleSaleItemDataStyle(
                                    item.mealDealStatus,
                                  )}
                                  style={{
                                    color:
                                      theme.palette.custom.orange.contrastText,
                                  }}
                                >
                                  {!_.isEmpty(item.itemName)
                                    ? item.itemName
                                    : "Unknown Item"}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                className={handleSaleItemTitleStyle(
                                  array[index - 1],
                                  item,
                                  index,
                                )}
                              >
                                <Typography
                                  align="right"
                                  className={handleSaleItemDataStyle(
                                    item.mealDealStatus,
                                  )}
                                  style={{
                                    paddingRight: "20px",
                                    color:
                                      theme.palette.custom.orange.contrastText,
                                  }}
                                >
                                  {item.mealDealStatus === 2
                                    ? item.amount > 0 &&
                                      parseFloat(
                                        item.amount + item.discount,
                                      ).toFixed(2)
                                    : parseFloat(
                                        item.amount + item.discount,
                                      ).toFixed(2)}
                                </Typography>
                              </Grid>
                            </>
                          );
                        },
                      )
                    : null}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "24px",
                      marginBottom: "24px",
                    }}
                  >
                    <Box className={classes.dividerStyle}></Box>
                  </div>
                  {!_.isEmpty(saleItemList) && !_.isEmpty(saleVatList) && (
                    <>
                      <Grid container className={classes.vatHeaderStyle}>
                        <Grid
                          item
                          xs={3}
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            paddingLeft: "8px",
                          }}
                        >
                          <Typography
                            variant="caption"
                            className={classes.vatStyle}
                            style={{
                              color: theme.palette.custom.blue.contrastText,
                            }}
                          >
                            VAT Rate
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="caption"
                            className={classes.vatStyle}
                            style={{
                              color: theme.palette.custom.blue.contrastText,
                            }}
                          >
                            Ex VAT
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="caption"
                            className={classes.vatStyle}
                            style={{
                              color: theme.palette.custom.blue.contrastText,
                            }}
                          >
                            VAT
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            paddingRight: "8px",
                          }}
                        >
                          <Typography
                            variant="caption"
                            className={classes.vatStyle}
                            style={{
                              color: theme.palette.custom.blue.contrastText,
                            }}
                          >
                            Inc VAT
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid container>
                    {!_.isEmpty(saleItemList) &&
                      !_.isEmpty(saleVatList) &&
                      saleVatList.map((item: any) => {
                        return (
                          <Grid item xs={12} className={classes.vatBodyStyle}>
                            <Grid container>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  paddingLeft: "12px",
                                }}
                              >
                                <Typography variant="caption">
                                  {item.vatPercent}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="caption">
                                  {parseFloat(
                                    (
                                      getIncVat(item.vatPercent) -
                                      getVat(item.vatPercent)
                                    ).toString(),
                                  ).toFixed(2)}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="caption">
                                  {parseFloat(
                                    getVat(item.vatPercent).toString(),
                                  ).toFixed(2)}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  paddingRight: "8px",
                                }}
                              >
                                <Typography variant="caption">
                                  {parseFloat(
                                    getIncVat(item.vatPercent).toString(),
                                  ).toFixed(2)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ marginTop: "12px", marginBottom: "12px" }}
                >
                  <>
                    <Grid
                      item
                      xs={
                        isEdit &&
                        nodeData.cashPayment > 0 &&
                        nodeData.cardPayment === 0 &&
                        nodeData.otherPayment === 0
                          ? 3
                          : 6
                      }
                      className={classes.topAlign}
                      style={{
                        paddingLeft: "20px",
                        marginTop: "20px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: theme.palette.custom.orange.contrastText,
                        }}
                        align="left"
                      >
                        Discount
                      </Typography>
                    </Grid>
                    {isEdit &&
                      nodeData.cashPayment > 0 &&
                      nodeData.cardPayment === 0 &&
                      nodeData.otherPayment === 0 &&
                      (isDiscountLoading ? (
                        <Grid item xs={6} style={{ marginBottom: "12px" }}>
                          <Skeleton
                            variant="rect"
                            width={"100%"}
                            height={36}
                            style={{
                              borderRadius: "10px",
                              border: `1px solid ${theme.palette.background.entity_border}`,
                              marginTop: "8px",
                            }}
                          />
                        </Grid>
                      ) : (
                        <DiscountSelector
                          selectedPaymentType="discount"
                          discountPercent={discountPercent}
                          setDiscountPercent={setDiscountPercent}
                          handleUpdateDiscount={handleUpdateDiscount}
                          initialDiscount={nodeData.discountPercent}
                        />
                      ))}
                    <Grid
                      item
                      xs={
                        isEdit &&
                        nodeData.cashPayment > 0 &&
                        nodeData.cardPayment === 0 &&
                        nodeData.otherPayment === 0
                          ? 3
                          : 6
                      }
                      className={classes.gridTopAlign}
                      style={
                        isEdit &&
                        nodeData.cashPayment > 0 &&
                        nodeData.cardPayment === 0 &&
                        nodeData.otherPayment === 0
                          ? { paddingRight: "20px", marginTop: "16px" }
                          : { paddingRight: "20px", marginTop: "20px" }
                      }
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: theme.palette.custom.orange.contrastText,
                        }}
                        align="left"
                      >
                        {nodeData.discountValue > 0
                          ? `- ${nodeData.discountValue.toFixed(2)}`
                          : `${nodeData.discountValue.toFixed(2)}`}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      className={classes.topAlign}
                      style={{ paddingLeft: "20px", marginBottom: "10px" }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: theme.palette.custom.orange.contrastText,
                        }}
                        align="left"
                      >
                        Total Amount
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={classes.gridTopAlign}
                      style={{ paddingRight: "20px", marginBottom: "10px" }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: theme.palette.custom.orange.contrastText,
                        }}
                        align="left"
                      >
                        {nodeData.totalAmount.toFixed(2)}
                      </Typography>
                    </Grid>
                    {nodeData.cashPayment > 0 && (
                      <>
                        <Grid
                          item
                          xs={isEdit ? 3 : 6}
                          className={classes.topAlign}
                          style={{ paddingLeft: "20px", display: "flex" }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Cash Payment
                          </Typography>
                        </Grid>
                        {isEdit &&
                          (changedPaymentType === "cash" &&
                          isPaymentTypeLoading ? (
                            <Grid item xs={6} style={{ marginBottom: "12px" }}>
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={40}
                                style={{
                                  borderRadius: "10px",
                                  border: `1px solid ${theme.palette.background.entity_border}`,
                                }}
                              />
                            </Grid>
                          ) : (
                            <PaymentTypeSelector
                              selectedPaymentType="cash"
                              paymentType={{
                                typeBefore: "cash",
                                amount: parseFloat(
                                  nodeData.cashPayment,
                                ).toFixed(2),
                                typeAfter: "cash",
                                otherPayId: "",
                              }}
                              handleUpdatePaymentType={handleUpdatePaymentType}
                            />
                          ))}
                      </>
                    )}

                    {nodeData.cashPayment > 0 && (
                      <Grid
                        item
                        xs={isEdit ? 3 : 6}
                        className={classes.gridTopAlign}
                        style={{ paddingRight: "20px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {nodeData.cashPayment.toFixed(2)}
                        </Typography>
                      </Grid>
                    )}

                    {nodeData.cardPayment > 0 && (
                      <>
                        <Grid
                          item
                          xs={isEdit ? 3 : 6}
                          className={classes.topAlign}
                          style={{ paddingLeft: "20px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Card Payment
                          </Typography>
                        </Grid>
                        {isEdit &&
                          (changedPaymentType === "card" &&
                          isPaymentTypeLoading ? (
                            <Grid item xs={6} style={{ marginBottom: "12px" }}>
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={40}
                                style={{
                                  borderRadius: "10px",
                                  border: `1px solid ${theme.palette.background.entity_border}`,
                                }}
                              />
                            </Grid>
                          ) : (
                            <PaymentTypeSelector
                              selectedPaymentType="card"
                              paymentType={{
                                typeBefore: "card",
                                amount: parseFloat(
                                  nodeData.cardPayment,
                                ).toFixed(2),
                                typeAfter: "card",
                                otherPayId: "",
                              }}
                              handleUpdatePaymentType={handleUpdatePaymentType}
                            />
                          ))}
                      </>
                    )}
                    {nodeData.cardPayment > 0 && (
                      <Grid
                        item
                        xs={isEdit ? 3 : 6}
                        className={classes.gridTopAlign}
                        style={{ paddingRight: "20px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {nodeData.cardPayment.toFixed(2)}
                        </Typography>
                      </Grid>
                    )}

                    {!_.isEmpty(otherPayment) &&
                      otherPayment.map((payment: any) => {
                        return (
                          <Grid item xs={12} style={{ display: "flex" }}>
                            <Grid
                              item
                              xs={isEdit ? 3 : 6}
                              className={classes.topAlign}
                              style={{ paddingLeft: "20px" }}
                            >
                              <Typography
                                align="left"
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "12px",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                              >
                                {payment.type}
                              </Typography>
                            </Grid>
                            {isEdit &&
                              (changedPaymentType === payment.type &&
                              isPaymentTypeLoading ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{ marginBottom: "12px" }}
                                >
                                  <Skeleton
                                    variant="rect"
                                    width={"100%"}
                                    height={40}
                                    style={{
                                      borderRadius: "10px",
                                      border: `1px solid ${theme.palette.background.entity_border}`,
                                    }}
                                  />
                                </Grid>
                              ) : (
                                <PaymentTypeSelector
                                  selectedPaymentType={payment.type}
                                  paymentType={{
                                    typeBefore: `${payment.type}`,
                                    amount: payment.amount.toFixed(2),
                                    typeAfter: `${payment.type}`,
                                    otherPayId: `${payment.id}`,
                                  }}
                                  handleUpdatePaymentType={
                                    handleUpdatePaymentType
                                  }
                                />
                              ))}
                            <Grid
                              item
                              xs={isEdit ? 3 : 6}
                              className={classes.gridTopAlign}
                              style={{ paddingRight: "20px" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                {payment.amount.toFixed(2)}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </>
                </Grid>
              </CardCommon>
            </Grid>
          ) : (
            <Skeleton
              style={{
                marginTop: "16px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
              }}
              variant="rect"
              width={"100%"}
              height={280}
            />
          )}
        </Grid>
      </Grid>
      <Grid item md={6} xs={12} className={classes.rightSideTopAlign}>
        {!isLoading ? (
          <Box
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Grid container>
              <Grid item xs={12} style={{ paddingRight: "20px" }}>
                <CardCommon backgroundColor={"entity_highlight_background"}>
                  <Grid container>
                    <Grid
                      item
                      xs={10}
                      style={{ marginTop: "12px", paddingLeft: "16px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <div>
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Mode
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {nodeData.mode
                              .split("_")
                              .map((word: any) => {
                                return (
                                  word.charAt(0).toUpperCase() + word.slice(1)
                                );
                              })
                              .join(" ")}
                          </Typography>
                        </div>
                        {((!_.isEmpty(nodeData.tableNo) &&
                          nodeData.mode === "dine_in") ||
                          (nodeData.mode === "delivery" &&
                            nodeData.tableNo !== "-3")) && (
                          <div style={{ marginLeft: "20px" }}>
                            <Typography
                              style={{
                                fontSize: "11px",
                                fontWeight: "lighter",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              {nodeData.mode === "delivery" &&
                              nodeData.tableNo !== "-3"
                                ? "Driver"
                                : "Table"}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "17px",
                                fontWeight: "bolder",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              {nodeData.tableNo}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "14px",
                        paddingRight: "12px",
                      }}
                    >
                      <Typography
                        align="left"
                        style={{
                          color: theme.palette.custom.orange.contrastText,
                        }}
                      >
                        <StorefrontIcon fontSize="large" />
                      </Typography>
                    </Grid>
                    <Box className={classes.dividerStyle}></Box>
                    {!_.isEmpty(nodeData.partySize) && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "16px", marginBottom: "12px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Party Size
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {nodeData.partySize}
                        </Typography>
                      </Grid>
                    )}
                    {!_.isEmpty(nodeData.transactionType) && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "16px", marginBottom: "12px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Transaction Type
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {nodeData.transactionType}
                        </Typography>
                      </Grid>
                    )}
                    {!_.isEmpty(nodeData.date) && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "16px", marginBottom: "12px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Date
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {convertDateTimeFormatAndConvertTimeBold(
                            nodeData.date,
                          )}
                        </Typography>
                      </Grid>
                    )}
                    {!_.isEmpty(nodeData.userId) && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "16px", marginBottom: "12px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Cashier
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {handleUserName(nodeData.userId)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </CardCommon>
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.gridTopAlignRightSide}
                style={{ paddingRight: "20px" }}
              >
                <CardCommon backgroundColor={"entity_highlight_background"}>
                  <Grid container>
                    <Grid
                      item
                      xs={10}
                      style={{ marginTop: "12px", paddingLeft: "16px" }}
                    >
                      <Typography
                        style={{
                          fontSize: "11px",
                          fontWeight: "lighter",
                          color: theme.palette.custom.orange.contrastText,
                        }}
                        align="left"
                      >
                        Order Ref
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "17px",
                          fontWeight: "bolder",
                          color: theme.palette.custom.orange.contrastText,
                        }}
                        align="left"
                      >
                        {nodeData.queOrderNo}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "14px",
                        paddingRight: "12px",
                      }}
                    >
                      <Typography
                        align="left"
                        style={{
                          color: theme.palette.custom.orange.contrastText,
                        }}
                      >
                        <ReceiptOutlinedIcon fontSize="large" />
                      </Typography>
                    </Grid>
                    <Box className={classes.dividerStyle}></Box>
                    {!_.isEmpty(nodeData.receiptNo) && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "16px", marginBottom: "12px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Receipt No
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {nodeData.receiptNo}
                        </Typography>
                      </Grid>
                    )}
                    {!_.isEmpty(nodeData.orderNo) && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "16px", marginBottom: "12px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Order No
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {nodeData.orderNo}
                        </Typography>
                      </Grid>
                    )}
                    {!_.isEmpty(nodeData.extQueOrderNo) && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "16px", marginBottom: "12px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "lighter",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          Channel Order No
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: "bolder",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          align="left"
                        >
                          {nodeData.extQueOrderNo}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </CardCommon>
              </Grid>
              {!_.isEmpty(customerDetails) && (
                <Grid
                  item
                  xs={12}
                  className={classes.gridTopAlignRightSide}
                  style={{ paddingRight: "20px" }}
                >
                  <CardCommon backgroundColor={"entity_highlight_background"}>
                    <Grid container>
                      {customerDetails.customerFullName && (
                        <Grid
                          item
                          xs={10}
                          style={{ marginTop: "12px", paddingLeft: "16px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Name
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {customerDetails.customerFullName}
                          </Typography>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={2}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "14px",
                          paddingRight: "12px",
                        }}
                      >
                        <Typography align="left">
                          <span
                            className="material-symbols-outlined"
                            style={{
                              fontSize: "34px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                          >
                            badge
                          </span>
                        </Typography>
                      </Grid>
                      <Box className={classes.dividerStyle}></Box>
                      {!_.isEmpty(customerDetails.tel) && (
                        <Grid
                          item
                          xs={12}
                          style={{ paddingLeft: "16px", marginBottom: "12px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Contact
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {customerDetails.tel}
                          </Typography>
                        </Grid>
                      )}
                      {!_.isEmpty(customerDetails.address) && (
                        <Grid
                          item
                          xs={12}
                          style={{ paddingLeft: "16px", marginBottom: "12px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Address
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {handleCustomerAddress(customerDetails.address)}
                          </Typography>
                        </Grid>
                      )}
                      {!_.isEmpty(customerDetails.postcode) && (
                        <Grid
                          item
                          xs={12}
                          style={{ paddingLeft: "16px", marginBottom: "12px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Postcode
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {customerDetails.postcode}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </CardCommon>
                </Grid>
              )}
            </Grid>
          </Box>
        ) : (
          <div style={{ marginLeft: "20px", marginTop: "60px" }}>
            <Skeleton
              variant="text"
              width={"50%"}
              style={{
                marginTop: "8px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
              }}
            />
            <Skeleton
              variant="text"
              width={"50%"}
              style={{
                marginTop: "8px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
              }}
            />
            <Skeleton
              variant="text"
              width={"50%"}
              style={{
                marginTop: "8px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
              }}
            />
            <Skeleton
              variant="text"
              width={"50%"}
              style={{
                marginTop: "8px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
              }}
            />
            <Skeleton
              variant="text"
              width={"50%"}
              style={{
                marginTop: "8px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
              }}
            />
            <Skeleton
              variant="text"
              width={"50%"}
              style={{
                marginTop: "8px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
              }}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default SalesDetailsTab;
