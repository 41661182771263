import {
  Button,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { CustomColor, CustomTheme } from "../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      borderRadius: "10px",
      backgroundColor: ({ color }: { color: CustomColor }) =>
        theme.palette.custom[color].main,
      "&:hover": {
        backgroundColor: ({ color }: { color: CustomColor }) =>
          theme.palette.custom[color].dark,
      },
      color: ({ color }: { color: CustomColor }) =>
        theme.palette.custom[color].contrastText,
    },
  }),
);

export interface ButtonContainedProps {
  label: string;
  onClick?: any;
  color: any;
  type?: any;
  startIcon?: any;
}

// This component shows the button.
const ButtonContained: React.FunctionComponent<ButtonContainedProps> = ({
  label,
  onClick,
  color,
  type,
  startIcon,
}) => {
  const classes = useStyles({ color });
  return (
    <Button
      className={classes.root}
      variant="contained"
      color={color}
      disableElevation
      onClick={onClick}
      type={type}
      size={"large"}
      startIcon={startIcon}
    >
      {label}
    </Button>
  );
};

export default ButtonContained;
