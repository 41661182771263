import { axiosClient } from "../client";

const PAYMENT_UPDATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/sale/paymentUpdate`;

  const DISCOUNT_UPDATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/sale/discountUpdate`;

export const updateSalePaymentInfo = (locationId, data) => {
  return axiosClient({
    method: "PUT",
    url: PAYMENT_UPDATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
};

export const updateDiscountInfo = (locationId, data) => {
  return axiosClient({
    method: "PUT",
    url: DISCOUNT_UPDATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
};
