import { axiosClient } from "../client";

const DASHBOARD_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}`;

  const DASHBOARD_INFO_UPDATE_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}`;

  export const fetchAllDashboardInfo = (locationId) => {
    return axiosClient({
      method: "GET",
      url: DASHBOARD_INFO_GET_ALL(locationId),
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
      },
    });
  };

  export const updateDashboardInfo = (locationId, data) =>
  axiosClient({
    method: "PUT",
    url: DASHBOARD_INFO_UPDATE_ALL(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
