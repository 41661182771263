import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";

import { CustomTheme } from "../../../../types/customTheme";
import { getLocalStore } from "../../../../utils/store/localStore";
import { handleImageUrl } from "../../../../utils/ChangePixelImgUrl";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    titleStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginTop: "7px",
      [theme.breakpoints.down("sm")]: {
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    imgBoxNotAvailableStyle: {
      marginRight: "7px",
      marginLeft: "10px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      width: "114px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
        width: "80px",
      },
      backgroundColor: theme.palette.background.imgBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      objectFit: "cover",
    },
    boxStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    },
    priceStyle: {
      marginBottom: "4px",
    },
  }),
);

export interface CategoryMenuListStripProps {
  item: any;
  handleClickLinkedId: any;
  isColorChange: any;
  handleUpdateIdList: any;
  category: any;
}

/**
 * Component: CategoryMenuListStrip
 *
 * This component represents a list strip for categories or items, displaying them in a grid layout.
 * Each item includes an image, title, description, and price, with optional animations and styles based on the provided props.
 * The layout and styling of the component adapt based on the screen size.
 */
const CategoryMenuListStrip: React.FunctionComponent<
  CategoryMenuListStripProps
> = ({
  item,
  handleClickLinkedId,
  isColorChange,
  handleUpdateIdList,
  category,
}) => {
  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  const [data, setData] = useState<any>([]);
  const [opacity, setOpacity] = useState(0);
  const [rotation, setRotation] = useState(0);
  const matchesLargeScreen = useMediaQuery("(max-width: 960px)");

  useEffect(() => {
    setData(data);
  }, []);

  /**
   * Function: hexToRgbOpacity
   *
   * This function takes a hexadecimal color code as input and converts it to an rgba color code by adding an opacity value.
   * It removes the hash sign from the input hex code, parses it into individual RGB components, and
   * then returns the RGB value with the provided opacity in the rgba format.
   */
  const hexToRgbOpacity = (hex: any) => {
    // Remove the hash sign if it exists
    hex = hex.replace("#", "");

    // Parse the hex value into individual RGB components
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return the RGB value in the format suitable for rgba() function
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  /**
   * This useEffect hook manages the rotation animation of the category menu items.
   * It calculates the rotation angle step based on the progress of time, and updates the rotation state accordingly.
   * The animation duration is determined by the number of items in the menu list.
   *
   * @param {boolean} isColorChange - Flag indicating whether a color change has occurred, triggering the animation.
   */
  // useEffect(() => {
  //   // Start time of the animation
  //   const startTime = performance.now();

  //   // Function to rotate the element over time
  //   function rotateElement(timestamp: any) {
  //     // Calculate the progress of the animation
  //     const progress = timestamp - startTime;

  //     // Duration of the animation (in milliseconds)
  //     const duration = item.wrapper.length * 100;

  //     // Determine the target rotation based on screen size
  //     const targetRotation = matchesLargeScreen ? 2 : 3;

  //     // Calculate the rotation step based on the progress and target rotation
  //     const rotationStep = (targetRotation / duration) * progress;

  //     // Set the rotation state with the calculated rotation step
  //     setRotation(rotationStep);

  //     // Continue animating if progress is less than duration
  //     if (progress < duration) {
  //       requestAnimationFrame(rotateElement);
  //     }
  //   }

  //   // Start the animation
  //   requestAnimationFrame(rotateElement);

  //   // Cleanup function to reset rotation when component unmounts or isColorChange updates
  //   return () => {
  //     setRotation(0);
  //   };
  // }, [isColorChange]);

  /**
   * This useEffect hook manages the fade-in animation of colors for category menu items.
   * It calculates the opacity value based on the progress of time, and updates the opacity state accordingly.
   * The animation duration is determined by the number of items in the menu list.
   *
   * @param {boolean} isColorChange - Flag indicating whether a color change has occurred, triggering the animation.
   */
  // useEffect(() => {
  //   // Get the start time of the animation
  //   const startTime = performance.now();

  //   // Define a function to handle the fade-in animation of colors
  //   function fadeInColor(timestamp: any) {
  //     // Calculate the progress of time since the animation started
  //     const progress = timestamp - startTime;

  //     // Calculate the duration of the animation based on the number of items in the list
  //     const duration = item.wrapper.length * 400;

  //     // Calculate the opacity value based on the progress and duration
  //     const opacityValue = Math.min(progress / duration, 1);

  //     // Update the opacity state with the calculated value
  //     setOpacity(opacityValue);

  //     // Continue the animation if the progress is less than the duration
  //     if (progress < duration) {
  //       requestAnimationFrame(fadeInColor);
  //     }
  //   }

  //   // Start the animation by requesting the first frame
  //   requestAnimationFrame(fadeInColor);

  //   // Return a cleanup function to reset the opacity state when the component unmounts
  //   return () => {
  //     setOpacity(0);
  //   };
  // }, [isColorChange]);

  const isDarkMode = getLocalStore("isDarkMode");
  return (
    <>
      <div
        style={{
          position: "relative",
          height: "149px",
          width: "100%",
          zIndex: 0,
          cursor: "pointer",
          marginTop: "14px",
        }}
        onClick={() => handleClickLinkedId(item, category)}
      >
        {/* {Object.values(item).length === 1 && ( */}
        <>
          <Slide
            in={true}
            direction="left"
            mountOnEnter
            unmountOnExit
            timeout={0}
          >
            <Box
              boxShadow={isDarkMode === "true" ? 4 : 0}
              style={{
                position: "absolute",
                left: 0,
                width: "100%",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
                backgroundColor: !item?.valid
                  ? theme.palette.custom.red.main
                  : theme.palette.background.entity_background,
              }}
            >
              <Box
                style={{
                  height: "134px",
                  width: "100%",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_border_menu_entity_background}`,

                  backgroundColor: !item?.valid
                    ? theme.palette.custom.red.main
                    : theme.palette.background.menu_entity_background,
                  transform: "rotate(3deg)",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={9}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    style={{ display: "flex" }}
                  >
                    {item?.img ? (
                      <Box
                        style={{
                          marginLeft: "10px",
                          borderRadius: "10px",
                          height: `${110}px`,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          marginTop: "12px",
                        }}
                      >
                        <img
                          className={classes.img}
                          src={handleImageUrl(item?.img)}
                          alt="not available images"
                          style={{
                            borderRadius: "9px",
                            border: `1px solid ${theme.palette.background.entity_border}`,
                            height: `${110}px`,
                            width: "100px",
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        className={classes.imgBoxNotAvailableStyle}
                        style={{ height: `${110}px`, marginTop: "10px" }}
                      >
                        <ImageOutlinedIcon style={{ fontSize: `${52}px` }} />
                      </Box>
                    )}
                    <Grid
                      item
                      xs
                      container
                      direction="column"
                      style={{ paddingLeft: "8px" }}
                    >
                      <Grid item xs>
                        <Typography
                          style={
                            item?.valid === false
                              ? { fontWeight: "bold", color: "white" }
                              : { fontWeight: "bold" }
                          }
                          align="left"
                          className={classes.titleStyle}
                        >
                          {item?.name}
                        </Typography>
                        <Typography
                          className={classes.boxStyle}
                          style={
                            item?.valid === false
                              ? {
                                  color: "white",
                                  textTransform: "lowercase",
                                }
                              : {
                                  textTransform: "lowercase",
                                }
                          }
                          variant="body2"
                          align="left"
                        >
                          {item?.desc}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      paddingRight: "12px",
                    }}
                  ></Grid>
                </Grid>
              </Box>
            </Box>
          </Slide>
          <Slide
            in={true}
            direction="left"
            mountOnEnter
            unmountOnExit
            timeout={0}
          >
            <Box
              boxShadow={isDarkMode === "true" ? 4 : 0}
              style={{
                position: "absolute",
                left: 0,
                width: "100%",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
                backgroundColor: !item?.valid
                  ? theme.palette.custom.red.main
                  : theme.palette.background.entity_background,
              }}
            >
              <div
                style={{
                  backgroundColor: !item?.valid
                    ? theme.palette.custom.red.main
                    : theme.palette.background.entity_background,
                  height: "134px",
                  borderRadius: "10px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={9}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    style={{ display: "flex" }}
                  >
                    {item?.img ? (
                      <Box
                        style={{
                          marginLeft: "10px",
                          borderRadius: "10px",
                          height: `${114}px`,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          marginTop: "10px",
                        }}
                      >
                        <img
                          className={classes.img}
                          src={handleImageUrl(item?.img)}
                          alt="not available images"
                          style={{
                            borderRadius: "9px",
                            border: `1px solid ${theme.palette.background.entity_border}`,
                            height: `${114}px`,
                            width: "114px",
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        className={classes.imgBoxNotAvailableStyle}
                        style={{ height: `${114}px`, marginTop: "10px" }}
                      >
                        <ImageOutlinedIcon style={{ fontSize: `${52}px` }} />
                      </Box>
                    )}
                    <Grid
                      item
                      xs
                      container
                      direction="column"
                      style={{ paddingLeft: "8px" }}
                    >
                      <Grid item xs>
                        <Typography
                          style={
                            item?.valid === false
                              ? { fontWeight: "bold", color: "white" }
                              : { fontWeight: "bold" }
                          }
                          align="left"
                          className={classes.titleStyle}
                        >
                          {item?.name}
                        </Typography>
                        <Typography
                          className={classes.boxStyle}
                          style={
                            item?.valid === false
                              ? {
                                  color: "white",
                                  textTransform: "lowercase",
                                }
                              : {
                                  textTransform: "lowercase",
                                }
                          }
                          variant="body2"
                          align="left"
                        >
                          {item?.desc}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Slide>
        </>
        {/* )} */}
        {/* {item?.wrapper.length >1 && (
          <>
            <Slide
              in={true}
              direction="left"
              mountOnEnter
              unmountOnExit
              timeout={0}
            >
              <Box
                boxShadow={isDarkMode === "true" ? 4 : 0}
                style={{
                  position: "absolute",
                  left: 0,
                  width: "100%",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_border}`,
                  backgroundColor: !item.valid
                    ? theme.palette.custom.red.main
                    : theme.palette.background.entity_background,
                }}
              >
                <Box
                  style={{
                    height: "134px",
                    width: "100%",
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border_menu_entity_background}`,

                    backgroundColor: !item.valid
                      ? theme.palette.custom.red.main
                      : isColorChange === item.directoryId
                      ? hexToRgbOpacity(
                          theme.palette.background.menu_entity_background,
                        )
                      : theme.palette.background.menu_entity_background,
                    transform: item.directoryId
                      ? `rotate(${rotation > 3 ? 3 : rotation}deg)`
                      : "rotate(3deg)",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={9}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      style={{ display: "flex" }}
                    >
                      {item?.img ? (
                        <Box
                          style={{
                            marginLeft: "10px",
                            borderRadius: "10px",
                            height: `${110}px`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            marginTop: "12px",
                          }}
                        >
                          <img
                            className={classes.img}
                            src={handleImageUrl(item.img)}
                            alt="not available images"
                            style={{
                              borderRadius: "9px",
                              border: `1px solid ${theme.palette.background.entity_border}`,
                              height: `${110}px`,
                              width: "100px",
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          className={classes.imgBoxNotAvailableStyle}
                          style={{ height: `${110}px`, marginTop: "10px" }}
                        >
                          <ImageOutlinedIcon style={{ fontSize: `${52}px` }} />
                        </Box>
                      )}
                      <Grid
                        item
                        xs
                        container
                        direction="column"
                        style={{ paddingLeft: "8px" }}
                      >
                        <Grid item xs>
                          <Typography
                            style={
                              item.valid === false
                                ? { fontWeight: "bold", color: "white" }
                                : { fontWeight: "bold" }
                            }
                            align="left"
                            className={classes.titleStyle}
                          >
                            {item?.name}
                          </Typography>
                          <Typography
                            className={classes.boxStyle}
                            style={
                              item.valid === false
                                ? {
                                    color: "white",
                                    textTransform: "lowercase",
                                  }
                                : {
                                    textTransform: "lowercase",
                                  }
                            }
                            variant="body2"
                            align="left"
                          >
                            {item?.desc}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                        paddingRight: "12px",
                      }}
                    ></Grid>
                  </Grid>
                </Box>
              </Box>
            </Slide>
            <Slide
              in={true}
              direction="left"
              mountOnEnter
              unmountOnExit
              timeout={0}
            >
              <Box
                boxShadow={isDarkMode === "true" ? 4 : 0}
                style={{
                  position: "absolute",
                  left: 0,
                  width: "100%",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_border}`,
                  backgroundColor: !item.valid
                    ? theme.palette.custom.red.main
                    : theme.palette.background.entity_background,
                }}
              >
                <Box
                  style={{
                    height: "134px",
                    width: "100%",
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border_menu_entity_background}`,

                    backgroundColor: !item.wrapper[1].valid
                      ? theme.palette.custom.red.main
                      : isColorChange === item.wrapper[1].directoryId
                      ? hexToRgbOpacity(
                          theme.palette.background.menu_entity_background,
                        )
                      : theme.palette.background.menu_entity_background,
                    transform:
                      isColorChange === item.wrapper[1].directoryId
                        ? `rotate(${rotation > 3 ? 177 : 180 - rotation}deg)`
                        : "rotate(177deg)",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={9}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      style={{ display: "flex" }}
                    >
                      {item.wrapper[1]?.img ? (
                        <Box
                          style={{
                            marginLeft: "10px",
                            borderRadius: "10px",
                            height: `${110}px`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            marginTop: "12px",
                          }}
                        >
                          <img
                            className={classes.img}
                            src={handleImageUrl(item.wrapper[1].img)}
                            alt="not available images"
                            style={{
                              borderRadius: "9px",
                              border: `1px solid ${theme.palette.background.entity_border}`,
                              height: `${110}px`,
                              width: "100px",
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          className={classes.imgBoxNotAvailableStyle}
                          style={{ height: `${110}px`, marginTop: "10px" }}
                        >
                          <ImageOutlinedIcon style={{ fontSize: `${52}px` }} />
                        </Box>
                      )}
                      <Grid
                        item
                        xs
                        container
                        direction="column"
                        style={{ paddingLeft: "8px" }}
                      >
                        <Grid item xs>
                          <Typography
                            style={
                              item.wrapper[1].valid === false
                                ? { fontWeight: "bold", color: "white" }
                                : { fontWeight: "bold" }
                            }
                            align="left"
                            className={classes.titleStyle}
                          >
                            {item.wrapper[1]?.name}
                          </Typography>
                          <Typography
                            className={classes.boxStyle}
                            style={
                              item.wrapper[1].valid === false
                                ? {
                                    color: "white",
                                    textTransform: "lowercase",
                                  }
                                : {
                                    textTransform: "lowercase",
                                  }
                            }
                            variant="body2"
                            align="left"
                          >
                            {item.wrapper[1]?.desc}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                        paddingRight: "12px",
                      }}
                    ></Grid>
                  </Grid>
                </Box>
              </Box>
            </Slide>
            <Slide
              in={true}
              direction="left"
              mountOnEnter
              unmountOnExit
              timeout={0}
            >
              <Box
                boxShadow={isDarkMode === "true" ? 4 : 0}
                style={{
                  position: "absolute",
                  left: 0,
                  width: "100%",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_border}`,
                  backgroundColor: !item.valid
                    ? theme.palette.custom.red.main
                    : theme.palette.background.entity_background,
                }}
              >
                <div
                  style={{
                    backgroundColor: !item.valid
                      ? theme.palette.custom.red.main
                      : theme.palette.background.entity_background,
                    height: "134px",
                    borderRadius: "10px",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={9}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      style={{ display: "flex" }}
                    >
                      {item?.img ? (
                        <Box
                          style={{
                            marginLeft: "10px",
                            borderRadius: "10px",
                            height: `${114}px`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            marginTop: "10px",
                          }}
                        >
                          <img
                            className={classes.img}
                            src={handleImageUrl(item.img)}
                            alt="not available images"
                            style={{
                              borderRadius: "9px",
                              border: `1px solid ${theme.palette.background.entity_border}`,
                              height: `${114}px`,
                              width: "114px",
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          className={classes.imgBoxNotAvailableStyle}
                          style={{ height: `${114}px`, marginTop: "10px" }}
                        >
                          <ImageOutlinedIcon style={{ fontSize: `${52}px` }} />
                        </Box>
                      )}
                      <Grid
                        item
                        xs
                        container
                        direction="column"
                        style={{ paddingLeft: "8px" }}
                      >
                        <Grid item xs>
                          <Typography
                            style={
                              item.valid === false
                                ? { fontWeight: "bold", color: "white" }
                                : { fontWeight: "bold" }
                            }
                            align="left"
                            className={classes.titleStyle}
                          >
                            {item?.name}
                          </Typography>
                          <Typography
                            className={classes.boxStyle}
                            style={
                              item.valid === false
                                ? {
                                    color: "white",
                                    textTransform: "lowercase",
                                  }
                                : {
                                    textTransform: "lowercase",
                                  }
                            }
                            variant="body2"
                            align="left"
                          >
                            {item?.desc}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Slide>
          </>
        )} */}
      </div>
    </>
  );
};

export default CategoryMenuListStrip;
