import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { getColorByStatus } from "../../utils/Color";
import Brightness1Icon from "@material-ui/icons/Brightness1";

interface styleProps {
  status: any;
}

const useStyles = makeStyles<any, styleProps>((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      placeItems: "center",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "24px",
      },
    },
    labelStyle: {
      paddingTop: "4px",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("md")]: {
        marginTop: "0px",
      },
    },
    statusBox: {
      fontSize: "40px",
      paddingTop: "0px",
      paddingLeft: "8px",
      color: ({ status }) => getColorByStatus(status),
      [theme.breakpoints.down("lg")]: {
        paddingTop: "3px",
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "3px",
        paddingLeft: "4px",
      },
    },
  }),
);

export interface statusProps {
  label: string;
  status?: any;
}

/* Show the order status circle with the status color. */
const Status: React.FunctionComponent<statusProps> = ({ label, status }) => {
  const classes = useStyles({ status });
  return (
    <div className={classes.root}>
      <div className={classes.labelStyle}>{label}</div>
      <Brightness1Icon className={classes.statusBox} />
    </div>
  );
};

export default Status;
