import React, { useEffect, useState } from "react";
import PageHeader from "../../../../components/common/PageHeader/PageHeader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { useTheme } from "@material-ui/styles";
import { CustomTheme } from "../../../../types/customTheme";
import LocationDetails from "./LocationDetails";
import { getLocationBasicInfo } from "../../../../services/locationApp/storeService";
import { useRouteMatch } from "react-router-dom";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import AddressDetails from "./AddressDetails";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { Hidden } from "@material-ui/core";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";

export interface HomeProps {}

/* Get all location information using API call at initial load. */
const Dashboard: React.FunctionComponent<HomeProps> = () => {
  const [error, setError] = useState("");
  const [locationData, setLocationData] = useState({});
  const match: any = useRouteMatch();

  /* Get all basic information using API call after change locationId. */
  useEffect(() => {
    document.title = "Locations - Dashboard";
    const fetchBasicInfo = async () => {
      try {
        const res = await getLocationBasicInfo(match.params.locationId);
        setLocationData(res.data.data);
      } catch (error) {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      }
    };

    fetchBasicInfo();
  }, [match.params.locationId]);

  const theme: CustomTheme = useTheme();

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <div style={{ marginTop: "18px" }}>
        <LocationDetails locationData={locationData} />
        <AddressDetails locationData={locationData} />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity={"error"}
        />
      </div>
    </>
  );
};

export default Dashboard;
