import React from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import { CustomTheme } from "../../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    dividerStyle: {
      margin: "12px 0px",
      backgroundColor: theme.palette.custom.grey.main,
      height: "2px",
      borderRadius: "10px",
      width: "100%",
    },
  }),
);

export interface StockItemSummeryProps {
  setActiveStep: any;
  setIsEdit: any;
  departmentName: any;
  selectedSupplierList: any;
  purchaseUnit: any;
  baseUnit: any;
  warningThreshold: any;
  criticalThreshold: any;
  supplierDetailsNodeObj: any;
  purchaseUnitObj: any;
}

/**
 * StockItemSummery Component
 *
 * This component displays a summary of the stock item information.
 * Users can view and edit various details such as department, supplier,
 * purchase unit, sale unit, warning threshold, and critical threshold.
 */
const StockItemSummery: React.FunctionComponent<StockItemSummeryProps> = ({
  setActiveStep,
  setIsEdit,
  departmentName,
  selectedSupplierList,
  purchaseUnit,
  baseUnit,
  warningThreshold,
  criticalThreshold,
  supplierDetailsNodeObj,
  purchaseUnitObj,
}) => {
  /**
   * Function to handle the sale unit
   */
  const handleSell = () => {
    // Check if the purchase unit label is "Custom"
    if (purchaseUnitObj.label === "Custom") {
      // If it is custom, set the active step to 3 (Purchase Unit) and set isEdit to 3
      setActiveStep(3);
      setIsEdit(3);
    } else {
      // If not custom, set isEdit to 4 and active step to 4 (Sale Unit)
      setIsEdit(4);
      setActiveStep(4);
    }
  };
  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6">
        Let’s add another stock item that is similar
      </Typography>
      <Typography variant="body1">
        For convenience, all the information has been copied from the last stock
        item added. If anything needs to be changed, please make the edits
        before proceeding
      </Typography>
      <Grid container style={{ marginTop: "20px" }}>
        <Divider className={classes.dividerStyle} />
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            style={{
              width: "100%",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setActiveStep(1);
              setIsEdit(1);
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Department:
              </Typography>
              <Typography variant="body2" style={{ marginLeft: "12px" }}>
                {departmentName}
              </Typography>
            </div>
            <EditIcon
              style={{
                fontSize: "20px",
                color: theme.palette.custom.yellow.main,
              }}
            />
          </Button>
        </Grid>
        <Divider className={classes.dividerStyle} />
        <Grid item xs={12}>
          <Button
            style={{
              width: "100%",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setActiveStep(2);
              setIsEdit(2);
            }}
          >
            <div style={{ display: "flex" }}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Supplier:
              </Typography>
              <div style={{ marginTop: "2px" }}>
                {selectedSupplierList.map((data: any, index: any) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FiberManualRecordIcon
                      style={{
                        marginLeft: "12px",
                        fontSize: "12px",
                        marginRight: "4px",
                      }}
                    />
                    <Typography variant="body2">
                      {supplierDetailsNodeObj[data].name}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
            <EditIcon
              style={{
                fontSize: "20px",
                color: theme.palette.custom.yellow.main,
              }}
            />
          </Button>
        </Grid>
        <Divider className={classes.dividerStyle} />
        <Grid item xs={12}>
          <Button
            style={{
              width: "100%",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setActiveStep(3);
              setIsEdit(4);
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Purchase Unit:
              </Typography>
              <Typography
                variant="body2"
                style={{ marginLeft: "12px", marginTop: "2px" }}
              >
                {purchaseUnit}
              </Typography>
            </div>
            <EditIcon
              style={{
                fontSize: "20px",
                color: theme.palette.custom.yellow.main,
              }}
            />
          </Button>
        </Grid>
        <Divider className={classes.dividerStyle} />
        <Grid item xs={12}>
          <Button
            style={{
              width: "100%",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={handleSell}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Sale Unit:
              </Typography>
              <Typography
                variant="body2"
                style={{ marginLeft: "12px", marginTop: "2px" }}
              >
                {baseUnit}
              </Typography>
            </div>
            <EditIcon
              style={{
                fontSize: "20px",
                color: theme.palette.custom.yellow.main,
              }}
            />
          </Button>
        </Grid>
        <Divider className={classes.dividerStyle} />
        <Grid item xs={12}>
          <Button
            style={{
              width: "100%",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setActiveStep(5);
              setIsEdit(5);
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Warning Threshold:
              </Typography>
              <Typography
                variant="body2"
                style={{ marginLeft: "12px", marginTop: "2px" }}
              >
                {warningThreshold}
              </Typography>
            </div>
            <EditIcon
              style={{
                fontSize: "20px",
                color: theme.palette.custom.yellow.main,
              }}
            />
          </Button>
        </Grid>
        <Divider className={classes.dividerStyle} />
        <Grid item xs={12}>
          <Button
            style={{
              width: "100%",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setActiveStep(6);
              setIsEdit(6);
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Critical Threshold:
              </Typography>
              <Typography
                variant="body2"
                style={{ marginLeft: "12px", marginTop: "2px" }}
              >
                {criticalThreshold}
              </Typography>
            </div>
            <EditIcon
              style={{
                fontSize: "20px",
                color: theme.palette.custom.yellow.main,
              }}
            />
          </Button>
        </Grid>
        <Divider className={classes.dividerStyle} />
        {/* <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex" }}>
            <Typography variant="body1">Name:</Typography>
            <Typography variant="body1">{name}</Typography>
          </div>
          <EditIcon
            style={{ cursor: "pointer", fontSize: "20px", color: theme.palette.custom.yellow.main }}
            onClick={() => {
              setActiveStep(7);
              setIsEdit(7);
            }}
          />
        </Grid>
        {barcode && (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <div style={{ display: "flex" }}>
              <Typography variant="body1">Barcode:</Typography>
              <Typography variant="body1">{barcode}</Typography>
            </div>
            <EditIcon
              style={{ cursor: "pointer", fontSize: "20px", color: theme.palette.custom.yellow.main }}
              onClick={() => {
                setActiveStep(7);
                setIsEdit(7);
              }}
            />
          </Grid>
        )}
        {sku && (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <div style={{ display: "flex" }}>
              <Typography variant="body1">Sku:</Typography>
              <Typography variant="body1">{sku}</Typography>
            </div>
            <EditIcon
              style={{ cursor: "pointer", fontSize: "20px", color: theme.palette.custom.yellow.main }}
              onClick={() => {
                setActiveStep(7);
                setIsEdit(7);
              }}
            />
          </Grid>
        )} */}
      </Grid>
    </>
  );
};

export default StockItemSummery;
