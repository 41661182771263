import {
  Chip,
  makeStyles,
  TextField,
  createStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { CustomTheme } from "../../../types/customTheme";

export interface Props {
  selectorTextFieldName: any;
  selectorList: { label: string; id: string }[];
  handleAllSelectedList: any;
  selectedList: any;
  disabled?: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    alignTextField: {
      paddingLeft: "12px",
      paddingRight: "12px",
      marginTop: "8px",
    },
    textField: {
      cursor: "pointer",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: "10px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
    },
  }),
);

/* This component changes the color of the selected item, it is depending on the selected type and payInOut method. . */
const MultipleSelectorColor: React.FunctionComponent<Props> = ({
  selectorTextFieldName,
  selectorList,
  handleAllSelectedList,
  selectedList,
  disabled,
}) => {
  /* Checking if it is a payInOut or a type. */
  const handleSelectedList = (selectedList: any) => {
    // If it is a 'type', send the 'type' props.
    if (selectorTextFieldName === "type") {
      handleAllSelectedList("type", selectedList);
    } else if (selectorTextFieldName === "payInOut") {
      // If it is a 'payInOut', send the 'payInOut' props.
      handleAllSelectedList("payInOut", selectedList);
    }
  };

  /* Changing the color of the chip from green for 'pay', 'cash_in', 'delivery_in', and 'oil' chips, and red for other chips.  */
  const typeColor = (type: any) => {
    // Change the color after selecting the 'pay', 'cash_in', 'delivery_in', or 'oil'.
    if (
      type === "pay" ||
      type === "cash_in" ||
      type === "delivery_in" ||
      type === "oil"
    ) {
      return {
        backgroundColor: theme.palette.custom.green.main,
        color: theme.palette.custom.red.contrastText,
      };
    } else {
      return {
        backgroundColor: theme.palette.custom.red.main,
        color: theme.palette.custom.red.contrastText,
      };
    }
  };

  /* Changing the color of the chip from green for pay-in and red for pay out. */
  const payInOutColor = (payInOut: any) => {
    // Change the color after selecting the pay-in option.
    if (payInOut === "pay_in") {
      return {
        backgroundColor: theme.palette.custom.green.main,
        color: theme.palette.custom.red.contrastText,
      };
    } else {
      // Change the color after selecting the pay-out option.
      return {
        backgroundColor: theme.palette.custom.red.main,
        color: theme.palette.custom.red.contrastText,
      };
    }
  };

  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <Autocomplete
        className={classes.alignTextField}
        multiple
        disableClearable
        id="multiple_selector_id"
        options={selectorList}
        value={selectedList}
        disabled={disabled}
        getOptionLabel={(option) => option.label}
        onChange={(e, selectedList: any, reason) => {
          handleSelectedList(selectedList);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <>
              <Chip
                variant="outlined"
                label={option.label}
                style={
                  selectorTextFieldName === "type"
                    ? typeColor(option.id)
                    : payInOutColor(option.id)
                }
                {...getTagProps({ index })}
              />
            </>
          ))
        }
        renderInput={(params) => (
          <TextField
            className={classes.textField}
            {...params}
            variant="outlined"
          />
        )}
      />
    </>
  );
};

export default MultipleSelectorColor;
