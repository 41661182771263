import React from "react";
import { Button, createStyles, Grid, makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import AddIcon from "@material-ui/icons/Add";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    addNodeButton: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.entity_background,
      "&:hover": {
        backgroundColor: fade(
          theme.palette.background.paper,
          theme.palette.action.hoverOpacity,
        ),
      },
    },
  }),
);

export interface CreateNewNodeProps {
  handleCreateNode: any;
}

/**
 * CreateNewNode Component:
 *
 * This component represents a button for creating a new node. It renders an "Add" button
 * with an icon, allowing users to add a new node when clicked.
 */
const CreateNewNode: React.SFC<CreateNewNodeProps> = ({ handleCreateNode }) => {
  const classes = useStyles();

  return (
    <Grid item sm={12} md={6} xs={12} lg={4}>
      <Button
        variant="contained"
        className={classes.addNodeButton}
        onClick={() => handleCreateNode()}
      >
        <AddIcon fontSize="large" />
      </Button>
    </Grid>
  );
};

export default withAuthority(CreateNewNode, Authorities.BANNERS_CREATE);
