import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import { fetchAllVoidTransactionInfo } from "../../../../services/salesApp//voidTransactionServices";
import { fetchAllUserInfo } from "../../../../services/salesApp/salesService";
import Pagination from "../../../../components/common/Pagination";
import { voidTransactionFilterObject } from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import VoidTransactionHeader from "./VoidTransactionHeader";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";

/**
 * VoidTransaction Component:
 *
 * This component is responsible for rendering the Void Transaction section of the sales application.
 * It fetches void transaction information, user information, and location information via API calls,
 * and provides functionalities for filtering, sorting, and pagination of void transactions.
 */
const VoidTransaction: React.FunctionComponent<{}> = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("");
  const [sortingMethod, setSortingMethod] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [userList, setUserList] = useState<any>([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [userListFilter, setUserListFilter] = useState<any>({});
  const [voidTransactionNodeList, setVoidTransactionNodeList] = useState<any>(
    [],
  );
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [isSort, setIsSort] = useState(false);
  const [totalResult, setTotalResult] = useState(0);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /* Get all void transaction information using API call and response data set to voidTransactionNodeList state. */
  const getVoidTransactionFilterInfo = async (
    pageSize: any,
    currentPage: any,
    sortingMethod: any,
    headerName: any,
    filter: any,
  ) => {
    setPageSize(pageSize);
    setCurrentPage(currentPage);
    try {
      const res = await fetchAllVoidTransactionInfo(
        idToken,
        match.params.locationId,
        pageSize,
        currentPage,
        sortingMethod,
        headerName,
        filter,
      );
      setVoidTransactionNodeList(res.data.data);
      setTotalPages(res.data.totalPages);
      setTotalResult(res.data.totalResults);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
    setIsLoading(false);
  };

  /* Get all user information using API call and response data set to userList state. */
  const getUserInfo = async (locationId: any) => {
    try {
      const res = await fetchAllUserInfo(idToken, locationId);
      let arr: any = [];
      let userObject: any = {};
      // Response data set to array.
      if (!_.isEmpty(res.data.data)) {
        Object.values(res.data.data).map((data: any) => {
          arr.push({ id: data.id, label: data.name });
          userObject[data.id] = { id: data.id, label: data.name };
        });
      }
      setUserListFilter(userObject);

      // Adding new data to previously obtained user data.
      if (!_.isEmpty(arr)) {
        // setUserList((prevState: any) => [...prevState, ...arr]);
        setUserList(arr);
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get all location information using API call and response data set to locationSelectorList state. */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) =>
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            }),
          );
          const locationName = getFilterListFromArrayObject(
            locationList,
            match.params.locationId,
          );
        }

        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    document.title = "Sale - Void Transaction";
    setIsLoading(true);
    getAllFilterLocation("");
  }, []);

  //Handle Void Transaction information using selector
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    setIsLoading(true);
    getVoidTransactionFilterInfo(
      pageSize,
      1,
      sortingMethod,
      headerName,
      filterData,
    );
  };

  //Handle Void Transaction information using page number
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    setIsLoading(true);
    getVoidTransactionFilterInfo(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      filterData,
    );
  };

  //sorting the Void Transaction information using a header name
  const handleChangeOrderListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    setIsSort(true);
    setDisableButton(true);
    setHeaderName(headerName);
    setSortingMethod(sortingMethod);
    getVoidTransactionFilterInfo(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      filterData,
    );
  };

  //Remove sorting method
  const handleRemoveOrderListSorting = () => {
    setIsSort(false);
    setDisableButton(false);
    setHeaderName("");
    setSortingMethod("");
    getVoidTransactionFilterInfo(pageSize, currentPage, "", "", filterData);
  };

  // Get filter data
  const handleFilterData = (filterData: any) => {
    setIsLoading(true);
    setFilterData(filterData);
    getVoidTransactionFilterInfo(20, 1, sortingMethod, headerName, filterData);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  return (
    <>
      <SaleReport
        handleFilterData={handleFilterData}
        locationSelectorList={locationSelectorList}
        handleLocationSelectorTypingList={handleLocationSelectorTypingList}
        isGetLocationInfo={isGetLocationInfo}
        getUserInfo={getUserInfo}
        userListFilter={userList}
        setFilterDetails={setFilterDetails}
        availableFilter={voidTransactionFilterObject}
        isOpenSkeletonLoading={isLoading}
        nodeList={voidTransactionNodeList}
        filterDetails={filterDetails}
        title={""}
        isChart={false}
        isUserGet={true}
        isSale={false}
        topic="Void Transaction"
        locationSelectedList={locationSelectedList}
        setLocationSelectedList={setLocationSelectedList}
        openFilterCard={openFilterCard}
        setOpenFilterCard={setOpenFilterCard}
        handleOnClickText={handleOnClickText}
        isReport={false}
      >
        <VoidTransactionHeader
          handleChangeOrderListSorting={handleChangeOrderListSorting}
          handleRemoveOrderListSorting={handleRemoveOrderListSorting}
          nodeData={voidTransactionNodeList}
          disableButton={disableButton}
          locationDetailsList={locationSelectorList}
          userList={userListFilter}
          locationSelectedList={locationSelectedList}
          handleOnClickText={handleOnClickText}
          isSort={isSort}
          headerName={headerName}
          sortingMethod={sortingMethod}
        />
        {!_.isEmpty(voidTransactionNodeList) && (
          <Pagination
            handleChangePaginationUsingSelector={
              handleChangePaginationUsingSelector
            }
            handleChangePaginationUsingPageNumber={
              handleChangePaginationUsingPageNumber
            }
            totalPages={totalPages}
            currentPage={currentPage}
            pageSize={pageSize}
            totalResult={totalResult}
          />
        )}
      </SaleReport>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(VoidTransaction, Authorities.SALE_READ);
