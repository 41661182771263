import React, { useCallback, useEffect, useState } from "react";
import ColorSelection from "../ColorSelection";
import PreviewBox from "../SectionBox/PreviewBox";
import SectionBoxWrapper from "../SectionBox/SectionBoxWrapper";
import PreviewCategories from "./PreviewCategories";

export interface SectionBoxCategoriesProps {
  themeConfig: any;
  onSubmit: any;
}

/* Update category information and users can view all category information using card. */
const SectionBoxCategories: React.FunctionComponent<SectionBoxCategoriesProps> =
  ({ themeConfig, onSubmit }) => {
    const [categoryBackgroundColor, setCategoryBackgroundColor] = useState("");
    const [menuTextColor, setMenuTextColor] = React.useState("");
    const [boarderColor, setBorderColor] = useState("");

    /* Update states initial load and after update category information. */
    const setInitialData = useCallback(() => {
      if(categoryBackgroundColor) return;

      const {
        categoryBackgroundColor: catBG,
        menuTextColor,
        boarderColor,
      } = themeConfig;
      setCategoryBackgroundColor(catBG);
      setMenuTextColor(menuTextColor);
      setBorderColor(boarderColor);
    }, [categoryBackgroundColor, themeConfig]);

    useEffect(() => {
      setInitialData();
    }, [setInitialData]);

    /* Send an API call to update category information after click save button. */
    const handleSave = () => {
      onSubmit({
        categoryBackgroundColor,
        menuTextColor,
        boarderColor,
      });
    };

    return (
      <>
        <SectionBoxWrapper
          sectionTitle={"Categories"}
          onSubmit={handleSave}
          onCancel={setInitialData}
          colorChangeSection={
            <>
              <ColorSelection
                title={"Background Color"}
                colorCode={categoryBackgroundColor}
                setColor={(color: any) => setCategoryBackgroundColor(color.hex)}
                setInitialData={() =>
                  setCategoryBackgroundColor(themeConfig.categoryBackgroundColor)
                }
              />
              <ColorSelection
                title={"Text Color"}
                colorCode={menuTextColor}
                setColor={(color: any) => setMenuTextColor(color.hex)}
                setInitialData={() => setMenuTextColor(themeConfig.menuTextColor)}
              />
              <ColorSelection
                title={"Border Color"}
                colorCode={boarderColor}
                setColor={(color: any) => setBorderColor(color.hex)}
                setInitialData={() => setBorderColor(themeConfig.boarderColor)}
              />
            </>
          }
          previewSection={
            <PreviewBox bgColor={categoryBackgroundColor}>
              <PreviewCategories
                titleColor={themeConfig.titleTextColor}
                categoryBackgroundColor={categoryBackgroundColor}
                menuTextColor={menuTextColor}
                boarderColor={boarderColor}
              />
            </PreviewBox>
          }
        />
      </>
    );
  };

export default SectionBoxCategories;
