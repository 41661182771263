export const replaceCommon = (word: string, prev: any, after: any) =>
  word.replace(prev, after);

export const replaceUnderscoreToSpace = (word: string) =>
  replaceCommon(word, "_", " ");

export const CapitalizeFirstLetter = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const ReplaceSpaceAfterCapitalLetter = (word: string) =>
  replaceCommon(word, /([a-z])([A-Z])/g, "$1 $2");

export const convertDecimalPoint = (string: string) =>
  parseFloat(string).toFixed(2);
