import * as React from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      height: 40,
      width: "100%",
    },
  }),
);

export interface ShapeTextFieldProps {
  bgColor: string;
}

/* Changing the checkout background color preview UI. */
const ShapeTextField: React.FunctionComponent<ShapeTextFieldProps> = ({
  bgColor,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: bgColor ?  theme.palette.getContrastText(bgColor) : '#000',
        opacity: 0.2,
      }}
    ></div>
  );
};

export default ShapeTextField;
