import React, { useState } from "react";
import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";

import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import StockItemInfoNode from "./StockItemInfoNode";

export interface StockItemsHeaderProps {
  isLoading: any;
  locationSelectorList: any;
  handleOpenEditStockItemsModal: any;
  handleOpenEditMapProductsItemsModal: any;
  stockItemGroupDepartmentNodeInSelector: any;
  activeGroupId: any;
  searchNameTab: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    headerItemStyle: {
      display: "flex",
      placeItems: "center",
      paddingLeft: "14px",
    },
    headerItemStyleEndColumn: {
      display: "flex",
      placeItems: "center",
      justifyContent: "end",
      paddingRight: "14px",
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      paddingLeft: "14px",
    },
    headerItemStyles: {
      fontSize: "12px",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "9px",
      },
      color: theme.palette.custom["orange"].contrastText,
    },
  }),
);

/**
 * StockItemsHeader Component
 *
 * This React component is responsible for rendering the header and individual stock item details in a grid layout.
 * It displays various information such as group, department, name, SKU, barcode, and actions for each stock item.
 * The component also handles loading state and provides functionality to open modals for editing stock items and mapping products.
 */

const StockItemsHeader: React.FunctionComponent<StockItemsHeaderProps> = ({
  isLoading,
  locationSelectorList,
  handleOpenEditStockItemsModal,
  handleOpenEditMapProductsItemsModal,
  stockItemGroupDepartmentNodeInSelector,
  activeGroupId,
  searchNameTab,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /**
   * Handles opening the department list popover when triggered by a click event.
   */
  const handleOpenDepartmentList = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles closing the department list popover.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  return (
    <>
      <div
        style={matchesLg ? { marginTop: "220px" } : { marginTop: "160px" }}
      ></div>
      <Hidden smDown>
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ minHeight: "36px" }}>
            <Grid item xs={2} className={classes.headerFirstItemStyle}>
              <Typography
                align="left"
                style={{ fontWeight: "bolder", textTransform: "none" }}
                className={classes.headerItemStyles}
              >
                Group
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <Typography
                align="left"
                style={{ fontWeight: "bolder", textTransform: "none" }}
                className={classes.headerItemStyles}
              >
                Department
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <Typography
                align="left"
                style={{ fontWeight: "bolder", textTransform: "none" }}
                className={classes.headerItemStyles}
              >
                Name
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <Typography
                align="left"
                style={{ fontWeight: "bolder", textTransform: "none" }}
                className={classes.headerItemStyles}
              >
                SKU
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <Typography
                align="left"
                style={{ fontWeight: "bolder", textTransform: "none" }}
                className={classes.headerItemStyles}
              >
                Barcode
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyleEndColumn}>
              <Typography
                align="left"
                style={{ fontWeight: "bolder", textTransform: "none" }}
                className={classes.headerItemStyles}
              >
                Actions
              </Typography>
            </Grid>
          </Grid>
        </CardCommon>
      </Hidden>
      <Grid container style={{ marginTop: "1px" }}>
        {!_.isEmpty(stockItemGroupDepartmentNodeInSelector) ? (
          stockItemGroupDepartmentNodeInSelector.map(
            (group: any, index: number) =>
              (activeGroupId === index || searchNameTab) &&
              group.stockDepartmentId.map((department: any) =>
                department.item.map((item: any) => (
                  <Grid item xs={12}>
                    <div
                      id={department.name}
                      style={{ position: "sticky", zIndex: 0 }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ placeItems: "center", marginTop: "4px" }}
                      >
                        <StockItemInfoNode
                          nodeData={item}
                          locationSelectorList={locationSelectorList}
                          handleOpenEditStockItemsModal={
                            handleOpenEditStockItemsModal
                          }
                          handleOpenEditMapProductsItemsModal={
                            handleOpenEditMapProductsItemsModal
                          }
                          handleOpenDepartmentList={handleOpenDepartmentList}
                          anchorEl={anchorEl}
                          handleClose={handleClose}
                          departmentName={department}
                          groupName={group.name}
                        />
                      </Grid>
                    </div>
                  </Grid>
                )),
              ),
          )
        ) : (
          <div style={{ width: "100%", margin: "auto", marginTop: "5%" }}>
            <Typography variant="h4" align="center">
              No Stock Items available.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};

export default WithLoading(StockItemsHeader);
