import { axiosClient } from "../client";

const queryString = require("query-string");

const DISCOUNT_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/discount`;

const DISCOUNT_INFO_UPDATE_ALL = (locationId, discountOptionId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/discount/${discountOptionId}`;

const DISCOUNT_INFO_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/discount`;

const DISCOUNT_INFO_DELETE = (locationId, discountOptionId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/discount/${discountOptionId}`;

export const fetchAllDiscountInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
    url: DISCOUNT_INFO_GET_ALL(locationId),
    query: { sort: ["created","ASC"] }}, {arrayFormat: 'comma'},
  ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateDiscountInfo = (locationId, discountOptionId, data) =>
  axiosClient({
    method: "PUT",
    url: DISCOUNT_INFO_UPDATE_ALL(locationId, discountOptionId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const createDiscountInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: DISCOUNT_INFO_CREATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const deleteDiscountInfo = (locationId, discountOptionId) =>
  axiosClient({
    method: "DELETE",
    url: DISCOUNT_INFO_DELETE(locationId, discountOptionId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
