import Dialog from "@material-ui/core/Dialog";
import * as React from "react";
import { CustomTheme } from "../../types/customTheme";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import DialogCommonDefault from "../dialogs/DialogCommonDefault";
import TextfieldDefault from "../textField/TextfieldDefault";
import ButtonCommon from "../buttons/ButtonCommon";
import { buttonColors } from "../../utils/enum";

export interface StockGroupAddModalProps {
  isOpenGroupAddModal: boolean;
  setIsOpenGroupAddModal: any;
  isLoadingToast: any;
  groupName: any;
  handleChangeGroupName: any;
  handleCreateNewGroup: any;
}

/* Common modal UI.. */
const StockGroupAddModal: React.FunctionComponent<StockGroupAddModalProps> = ({
  isOpenGroupAddModal,
  setIsOpenGroupAddModal,
  isLoadingToast,
  groupName,
  handleChangeGroupName,
  handleCreateNewGroup,
}) => {
  const theme: CustomTheme = useTheme();

  return (
    <DialogCommonDefault
      open={isOpenGroupAddModal}
      setOpen={setIsOpenGroupAddModal}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
    >
      <DialogTitle>Add New Group</DialogTitle>
      <DialogContent>
        <TextfieldDefault
          id="groupName"
          name="groupName"
          type="text"
          label="Group Name"
          disabled={isLoadingToast}
          value={groupName}
          onChange={handleChangeGroupName}
        />
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px", marginRight: "16px" }}>
        <ButtonCommon
          disabled={isLoadingToast}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "12px",
          }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
          onClick={() => {
            setIsOpenGroupAddModal(false);
          }}
        >
          Cancel
        </ButtonCommon>

        <ButtonCommon
          disabled={!groupName || isLoadingToast}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
          }}
          color={buttonColors.CREATE_BUTTON_COLOR}
          onClick={handleCreateNewGroup}
        >
          Create
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default StockGroupAddModal;
