import {
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";

import { CustomTheme } from "../../../../types/customTheme";
import DashboardChart from "./DashboardChart";

const useStyles = makeStyles<any>((theme: CustomTheme) =>
  createStyles({
    tabPanel: {
      backgroundColor: theme.palette.background.entity_background,
    },
    tabStyle: {
      minWidth: "0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
  }),
);

export interface ChartTypeProps {
  previousStartDate: any;
  previousEndDate: any;
  currentStartDate: any;
  currentEndDate: any;
  shortPreviousDateRange: any;
  shortCurrentDateRange: any;
  previousDashboardNodeList: any;
  currentDashboardNodeList: any;
  paymentNodeList: any;
  previousPaymentNodeList: any;
  ordersNodeList: any;
  previousOrdersNodeList: any;
  averageNodeList: any;
  previousAverageNodeList: any;
  platformNodeList: any;
  previousPlatformNodeList: any;
  modeNodeList: any;
  previousModeNodeList: any;
  channelNodeList: any;
  previousChannelNodeList: any;
  isEmpty?: any;
  currentSalesTotal: any;
  previousSalesTotal: any;
}

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`sale-dashboard-chart-type-tab-${index}`}
      aria-labelledby={`sale-dashboard-chart-type-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

/*
 * ChartType is a React functional component responsible for rendering a tabbed interface
 * for displaying different types of charts based on the provided data.
 * It utilizes Material-UI's Tabs and Tab components to switch between different chart types.
 * The component receives various props including data related to sales, orders, average, payments,
 * platform, mode, channel, and date ranges.
 * Each tab corresponds to a specific type of chart (e.g., sales, orders, average, etc.) and renders
 * a DashboardChart component with appropriate data and configurations.
 * The component also handles tab switching and adjusts its appearance based on screen size using
 * Material-UI's useMediaQuery hook.
 */
const ChartType: React.FunctionComponent<ChartTypeProps> = ({
  previousStartDate,
  previousEndDate,
  currentStartDate,
  currentEndDate,
  shortPreviousDateRange,
  shortCurrentDateRange,
  previousDashboardNodeList,
  currentDashboardNodeList,
  paymentNodeList,
  previousPaymentNodeList,
  ordersNodeList,
  previousOrdersNodeList,
  averageNodeList,
  previousAverageNodeList,
  platformNodeList,
  previousPlatformNodeList,
  modeNodeList,
  previousModeNodeList,
  channelNodeList,
  previousChannelNodeList,
  isEmpty,
  currentSalesTotal,
  previousSalesTotal,
}) => {
  const [value, setValue] = React.useState<number>(0);

  /* Switch the tab to be displayed. */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const [checked, setChecked] = React.useState(true);
  const [checkedCurrentPreviousToggle, setCheckedCurrentPreviousToggle] =
    React.useState(true);
  const [valueToggle, setValueToggle] = React.useState("line");
  const maxWidth = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  return (
    <>
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        aria-label="discount-types-tabs"
        // variant="fullWidth"
        variant={maxWidth ? "scrollable" : "fullWidth"}
        scrollButtons={maxWidth ? "on" : "off"}
      >
        <Tab
          label="SALES"
          id="sale-dashboard-chart-type-tab-0"
          aria-controls="sale-dashboard-chart-type-tab-0"
          className={classes.tabStyle}
        />
        <Tab
          label="ORDERS"
          id="sale-dashboard-chart-type-tab-1"
          aria-controls="sale-dashboard-chart-type-tab-1"
          className={classes.tabStyle}
          disabled={isEmpty}
        />
        <Tab
          label="AVERAGE"
          id="sale-dashboard-chart-type-tab-0"
          aria-controls="sale-dashboard-chart-type-tab-0"
          className={classes.tabStyle}
          disabled={isEmpty}
        />
        <Tab
          label="PAYMENTS"
          id="sale-dashboard-chart-type-tab-1"
          aria-controls="sale-dashboard-chart-type-tab-1"
          className={classes.tabStyle}
          disabled={isEmpty}
        />
        <Tab
          label="Platform"
          id="sale-dashboard-chart-type-tab-1"
          aria-controls="sale-dashboard-chart-type-tab-1"
          className={classes.tabStyle}
          disabled={isEmpty}
        />
        <Tab
          label="Mode"
          id="sale-dashboard-chart-type-tab-1"
          aria-controls="sale-dashboard-chart-type-tab-1"
          className={classes.tabStyle}
          disabled={isEmpty}
        />
        <Tab
          label="Channel"
          id="sale-dashboard-chart-type-tab-1"
          aria-controls="sale-dashboard-chart-type-tab-1"
          className={classes.tabStyle}
          disabled={isEmpty}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <DashboardChart
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          shortPreviousDateRange={shortPreviousDateRange}
          shortCurrentDateRange={shortCurrentDateRange}
          previousDashboardNodeList={previousDashboardNodeList}
          dashboardNodeList={currentDashboardNodeList}
          isEmpty={isEmpty}
          order={false}
          setChecked={setChecked}
          checked={checked}
          setCheckedCurrentPreviousToggle={setCheckedCurrentPreviousToggle}
          checkedCurrentPreviousToggle={checkedCurrentPreviousToggle}
          value={valueToggle}
          setValue={setValueToggle}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DashboardChart
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          shortPreviousDateRange={shortPreviousDateRange}
          shortCurrentDateRange={shortCurrentDateRange}
          previousDashboardNodeList={previousOrdersNodeList}
          dashboardNodeList={ordersNodeList}
          order={true}
          setChecked={setChecked}
          checked={checked}
          setCheckedCurrentPreviousToggle={setCheckedCurrentPreviousToggle}
          checkedCurrentPreviousToggle={checkedCurrentPreviousToggle}
          value={valueToggle}
          setValue={setValueToggle}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DashboardChart
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          shortPreviousDateRange={shortPreviousDateRange}
          shortCurrentDateRange={shortCurrentDateRange}
          previousDashboardNodeList={previousAverageNodeList}
          dashboardNodeList={averageNodeList}
          order={false}
          setChecked={setChecked}
          checked={checked}
          setCheckedCurrentPreviousToggle={setCheckedCurrentPreviousToggle}
          checkedCurrentPreviousToggle={checkedCurrentPreviousToggle}
          value={valueToggle}
          setValue={setValueToggle}
          isAverage={true}
          currentSalesTotal={currentSalesTotal}
          previousSalesTotal={previousSalesTotal}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DashboardChart
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          shortPreviousDateRange={shortPreviousDateRange}
          shortCurrentDateRange={shortCurrentDateRange}
          previousDashboardNodeList={previousPaymentNodeList}
          dashboardNodeList={paymentNodeList}
          order={false}
          setChecked={setChecked}
          checked={checked}
          setCheckedCurrentPreviousToggle={setCheckedCurrentPreviousToggle}
          checkedCurrentPreviousToggle={checkedCurrentPreviousToggle}
          value={valueToggle}
          setValue={setValueToggle}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <DashboardChart
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          shortPreviousDateRange={shortPreviousDateRange}
          shortCurrentDateRange={shortCurrentDateRange}
          previousDashboardNodeList={previousPlatformNodeList}
          dashboardNodeList={platformNodeList}
          order={false}
          setChecked={setChecked}
          checked={checked}
          setCheckedCurrentPreviousToggle={setCheckedCurrentPreviousToggle}
          checkedCurrentPreviousToggle={checkedCurrentPreviousToggle}
          value={valueToggle}
          setValue={setValueToggle}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DashboardChart
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          shortPreviousDateRange={shortPreviousDateRange}
          shortCurrentDateRange={shortCurrentDateRange}
          previousDashboardNodeList={previousModeNodeList}
          dashboardNodeList={modeNodeList}
          order={false}
          setChecked={setChecked}
          checked={checked}
          setCheckedCurrentPreviousToggle={setCheckedCurrentPreviousToggle}
          checkedCurrentPreviousToggle={checkedCurrentPreviousToggle}
          value={valueToggle}
          setValue={setValueToggle}
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <DashboardChart
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          shortPreviousDateRange={shortPreviousDateRange}
          shortCurrentDateRange={shortCurrentDateRange}
          previousDashboardNodeList={previousChannelNodeList}
          dashboardNodeList={channelNodeList}
          order={false}
          setChecked={setChecked}
          checked={checked}
          setCheckedCurrentPreviousToggle={setCheckedCurrentPreviousToggle}
          checkedCurrentPreviousToggle={checkedCurrentPreviousToggle}
          value={valueToggle}
          setValue={setValueToggle}
          isChannel={true}
        />
      </TabPanel>
    </>
  );
};

export default ChartType;
