import axios from "axios";
import _ from "lodash";
import React from "react";
import queryString from "query-string";

import { Redirect } from "react-router-dom";
import { getCookie } from "../../utils/cookies";
import { refreshAccessToken } from "../accountApp/authService";
import { saveLogs } from "../../utils/firebase/logs";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const options = {
  baseUrl: baseUrl,
};

export const axiosClient = axios.create(options);

axiosClient.interceptors.response.use(
  (response) => {
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data.status >= 200 &&
      response.data.status <= 300
    ) {
      const config = response.config;
      if (config.method === "post") {
        const { message, summery, logsAppName, locationId } = config;
        if (message && logsAppName && locationId) {
          const body = {
            message: message,
            summary: summery,
            type: "info",
            code: "create",
            metadata: { changedBy: "" },
          };
          saveLogs(body, logsAppName, locationId);
        }
      } else if (config.method === "patch") {
        const { message, summery, logsAppName, locationId } = config;
        if (message && logsAppName && locationId) {
          const body = {
            message: message,
            summary: summery,
            type: "info",
            code: "update",
            metadata: { changedBy: "" },
          };
          if (!_.isEmpty(summery)) {
            saveLogs(body, logsAppName, locationId);
          }
        }
      }
    }
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const isAppEmbedded = queryString.parse(
          window.location.search,
        ).isAppEmbedded;

        try {
          const refreshToken = getCookie("refreshToken");
          const { id_token } = await refreshAccessToken(refreshToken);
          if (!_.isEmpty(id_token)) {
            let updatedConfig = {
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization: `Bearer ${id_token}`,
              },
            };
            return axios(updatedConfig);
          } else {
            if (isAppEmbedded !== "true") {
              return (window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/sign-in`);
            }
          }
        } catch (error) {
          if (isAppEmbedded !== "true") {
            return (
              <Redirect
                to={`${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/sign-in`}
              />
            );
          }
        }
      }
    } else {
      // redirect to login
    }
    return Promise.reject(error);
  },
);

axiosClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (config.url.includes("securetoken.googleapis.com/v1/token")) {
      return config;
    }
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getCookie("idToken")}`,
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);
