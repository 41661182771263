import { FormControlLabel, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Authorities from "../../../../../auth/authorities";
import withAuthority from "../../../../../components/Auth/withAuthority";
import CardCommon from "../../../../../components/card/CardCommon";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import _ from "lodash";
import SwitchCommon from "../../../../../components/switches/SwitchCommon";
import { DeliveryTpe, submitButtonName } from "../../../../../utils/enum";
import AvailabilitySelection from "../../../../../components/time/AvailabilitySelection";

export interface AvailabilityModalProps {
  deliveryTypesList: any;
  handleSelectDeliveryTypes: any;
  isDeliveryTypeEmpty: any;
  availabilityList: any;
  handleSubmitAvailability: any;
  isActive: any;
  handleSwitchActivate: any;
  handleDisable: any;
  handleSubmitData: any;
}

/* This component shows the 'availability' and delivery type of menu information. */
const AvailabilityModal: React.FunctionComponent<AvailabilityModalProps> = ({
  deliveryTypesList,
  handleSelectDeliveryTypes,
  isDeliveryTypeEmpty,
  availabilityList,
  handleSubmitAvailability,
  isActive,
  handleSwitchActivate,
  handleDisable,
  handleSubmitData,
}) => {
  const [availabilityErrorMessage, setAvailabilityErrorMessage] = useState("");
  return (
    <CardCommon backgroundColor={"entity_background"}>
      <Grid
        container
        style={{
          marginTop: "8px",
          marginLeft: "8px",
        }}
      >
        {/* Availability delivery type selection switches */}
        <Grid item xs={12} sm={8} md={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "28px",
            }}
          >
            <FormControlLabel
              style={{ marginLeft: "0px", marginRight: "0px" }}
              value="dinein"
              control={<SwitchCommon />}
              label={
                <Typography variant="caption" color="textSecondary">
                  Dine In
                </Typography>
              }
              name="dinein"
              checked={
                !_.isEmpty(deliveryTypesList)
                  ? deliveryTypesList.includes(DeliveryTpe.DINEIN)
                  : false
              }
              labelPlacement="bottom"
              onChange={handleSelectDeliveryTypes}
            />
            <FormControlLabel
              value="delivery"
              control={<SwitchCommon />}
              label={
                <Typography variant="caption" color="textSecondary">
                  Delivery
                </Typography>
              }
              name="delivery"
              checked={
                !_.isEmpty(deliveryTypesList)
                  ? deliveryTypesList.includes(DeliveryTpe.DELIVERY)
                  : false
              }
              labelPlacement="bottom"
              onChange={handleSelectDeliveryTypes}
            />
            <FormControlLabel
              value="takeout"
              name="takeout"
              control={<SwitchCommon />}
              label={
                <Typography variant="caption" color="textSecondary">
                  Takeout
                </Typography>
              }
              checked={
                !_.isEmpty(deliveryTypesList)
                  ? deliveryTypesList.includes(DeliveryTpe.TAKEOUT)
                  : false
              }
              labelPlacement="bottom"
              onChange={handleSelectDeliveryTypes}
            />
          </div>
          {_.isEqual(isDeliveryTypeEmpty, true) && (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginBottom: "3px",
              }}
            >
              <Typography
                align="left"
                variant="caption"
                style={{ color: "red" }}
              >
                Please select a least one delivery option.
              </Typography>
            </div>
          )}
        </Grid>

        {/* Availability active button */}
        <Grid
          item
          xs={12}
          sm={4}
          md={6}
          style={{
            display: "flex",
            justifyContent: "end",
            paddingRight: "40px",
          }}
        >
          <FormControlLabel
            style={{ marginLeft: "0px", marginRight: "0px" }}
            control={<SwitchCommon />}
            checked={isActive}
            onChange={handleSwitchActivate}
            label={
              <Typography variant="caption" color="textSecondary">
                Active
              </Typography>
            }
            labelPlacement="bottom"
          />
        </Grid>

        {/* Availability selection modal */}
        <Grid item xs={12} style={{ marginRight: "32px", marginTop: "12px" }}>
          <AvailabilitySelection
            nodeData={availabilityList}
            handleSubmit={handleSubmitAvailability}
            setAvailabilityErrorMessage={setAvailabilityErrorMessage}
          />
        </Grid>

        {/* Availability submit button */}
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "16px",
            display: "flex",
            justifyContent: "end",
            marginRight: "28px",
            marginTop: "12px",
          }}
        >
          <div style={{ width: "160px" }}>
            <ButtonCommon
              style={{
                flex: 1,
                fontSize: 11,
                width: "160px",
              }}
              disabled={handleDisable()}
              variant="contained"
              color={"green"}
              onClick={handleSubmitData}
            >
              {submitButtonName.UPDATE_SUBMIT_BUTTON}
            </ButtonCommon>
          </div>
        </Grid>
      </Grid>
    </CardCommon>
  );
};

export default withAuthority(AvailabilityModal, Authorities.MENU_READ);
