import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import ItemsReportInfoNode from "./ItemsReportInfoNode";
import { salesItemsFilterObject } from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { fetchAllItemsReportInfo } from "../../../../services/salesApp/itemsReportService";
import {
  fetchGlobalDepartmentInfo,
  fetchGlobalGroupInfo,
  fetchGlobalProductsInfo,
} from "../../../../services/menuApp/printerMenuService";

export interface ItemSalesByCategoryReportProps {}

/**
 * ItemSalesByCategoryReport Component:
 *
 * This component is responsible for rendering a sales report for items categorized by category.
 * It fetches data from various API endpoints to populate the sales report and provides filtering
 * options for the user to customize the data view. The component is composed of several state variables,
 * useEffect hook for component initialization, and various helper functions to handle API calls
 * nd user interactions. It also renders child components such as SaleReport and ItemsReportInfoNode
 * to display the sales report and additional information.
 */
const ItemSalesByCategoryReport: React.FunctionComponent<
  ItemSalesByCategoryReportProps
> = () => {
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [itemsSalesByCategoryNode, setItemsSalesByCategoryNode] = useState([]);
  const [itemsSalesByCategoryNodeInitial, setItemsSalesByCategoryNodeInitial] =
    useState([]);
  const [selectedCatMenuTitle, setSelectedCatMenuTitle] = useState("");
  const [menuNodeList, setMenuNodeList] = useState<any>([]);
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  useEffect(() => {
    document.title = "Sale - Item Sales";
    getAllFilterLocation("");
  }, []);

  /**
   * Fetches all filter locations based on the provided search name.
   * @param {any} searchName - The search term to filter locations.
   */
  const getAllFilterLocation = async (searchName: any) => {
    // Call the API to fetch all filter locations
    fetchAllFilterLocations(searchName)
      .then((res) => {
        // If the request is successful
        let locationList: any = [];
        // Check if the response data is not empty
        if (!_.isEmpty(res.data.data)) {
          // Iterate over each location in the response data
          res.data.data.map((location: any) => {
            // Push location details to the locationList array
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            });
          });
        }

        // Update the location selector list with fetched locations
        setLocationSelectorList(locationList);
        // Set the flag to indicate that location info is fetched
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        // If an error occurs during fetching, handle it by setting an error message
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  /**
   * Function to fetch group information based on location ID.
   * @param {any} itemSalesReport - Sales report data.
   */
  const getGroupInfo = async (itemSalesReport: any) => {
    try {
      // Attempt to fetch group information using the 'fetchGlobalGroupInfo' API
      const res = await fetchGlobalGroupInfo(match.params.locationId);

      // Once group information is successfully fetched, call 'getDepartmentInfo' with the received data
      getDepartmentInfo(itemSalesReport, res.data.data);
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch department information based on location ID.
   * @param {any} itemSalesReport - Sales report data.
   * @param {any} groupNode - Group information.
   */
  const getDepartmentInfo = async (itemSalesReport: any, groupNode: any) => {
    try {
      // Attempt to fetch department information using the 'fetchGlobalDepartmentInfo' API
      const res = await fetchGlobalDepartmentInfo(match.params.locationId);

      // Once department information is successfully fetched, call 'getProductInfo' with the received data
      getProductInfo(itemSalesReport, groupNode, res.data.data);
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);

      // Additionally, set the 'isOpenSkeletonLoading' state to false to stop skeleton loading
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Function to fetch product information based on location ID and handle menu restructuring.
   * @param {any} itemSalesReport - Sales report data.
   * @param {any} groupNode - Group information.
   * @param {any} departmentNode - Department information.
   */
  const getProductInfo = async (
    itemSalesReport: any,
    groupNode: any,
    departmentNode: any,
  ) => {
    try {
      // Attempt to fetch product information using the 'fetchGlobalProductsInfo' API
      const res = await fetchGlobalProductsInfo(match.params.locationId);

      // Once product information is successfully fetched, call 'handleMenuRestructure' to restructure menu data
      const menu = await handleMenuRestructure(
        groupNode,
        departmentNode,
        res.data.data,
        itemSalesReport,
      );

      // Update states with the restructured menu data
      setSelectedCatMenuTitle("All");
      setItemsSalesByCategoryNode(menu);
      setItemsSalesByCategoryNodeInitial(menu);

      // Set 'isOpenSkeletonLoading' state to false to stop skeleton loading
      setIsOpenSkeletonLoading(false);
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);

      // Set 'isOpenSkeletonLoading' state to false to stop skeleton loading
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Restructures the menu data based on group, department, and product nodes, incorporating sales report data.
   * @param {any} groupNode - Group information.
   * @param {any} departmentNode - Department information.
   * @param {any} productNode - Product information.
   * @param {any} itemSalesReport - Sales report data.
   * @returns {any} - Restructured menu data.
   */
  const handleMenuRestructure = (
    groupNode: any,
    departmentNode: any,
    productNode: any,
    itemSalesReport: any,
  ) => {
    if (
      !_.isEmpty(groupNode) &&
      !_.isEmpty(departmentNode) &&
      !_.isEmpty(productNode) &&
      !_.isEmpty(itemSalesReport)
    ) {
      const filterDepartment = departmentNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const filterProduct = productNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const filterGroup = groupNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );

      const linkedId: any = [];
      const output: any = [];
      const groupList: any = [{ id: "all", name: "All" }];

      filterGroup.forEach((groupItem: any) => {
        const groupObject: any = {
          ...groupItem,
          department: [],
        };

        const matchingDepartments = filterDepartment.filter(
          (departmentItem: any) =>
            departmentItem.groupId === groupItem.id &&
            departmentItem.name !== "Online Hidden" &&
            departmentItem.name !== "Templates",
        );

        // Separate "Hidden Food" departments from others
        const hiddenFoodDepartments: any[] = [];
        const otherDepartments: any[] = [];

        matchingDepartments.forEach((departmentItem: any) => {
          if (
            departmentItem.name === "Hidden Food" ||
            departmentItem.name === "Hidden Drinks"
          ) {
            hiddenFoodDepartments.push(departmentItem);
          } else {
            otherDepartments.push(departmentItem);
          }
        });

        // Concatenate other departments with "Hidden Food" departments at the end
        const allDepartments = [...otherDepartments, ...hiddenFoodDepartments];

        allDepartments.forEach((departmentItem: any) => {
          const departmentObject: any = {
            ...departmentItem,
            product: {},
          };

          const matchingProducts = filterProduct.filter(
            (productItem: any) =>
              productItem.departmentId === departmentItem.id,
          );

          matchingProducts.forEach((productItem: any) => {
            if (!departmentObject.product[productItem.id]) {
              departmentObject.product[productItem.id] = {};
            } else {
              linkedId.push(`${productItem.departmentId}*${productItem.id}`);
            }

            if (itemSalesReport[productItem.id]) {
              itemSalesReport[productItem.id].map((data: any) => {
                data["viewOrder"] = productItem.viewOrder;
              });

              departmentObject.product[productItem.id] =
                itemSalesReport[productItem.id];
            }
          });

          const keys = Object.keys(departmentObject.product);
          const values = Object.values(departmentObject.product);

          const outputs: any = {};
          if (!_.isEmpty(values)) {
            keys.forEach((key, index) => {
              if (!_.isEmpty(values[index])) {
                outputs[key] = values[index];
              }
            });
          }
          if (Object.values(outputs).length > 0) {
            departmentObject.product = outputs;
          }

          if (
            !_.isEmpty(departmentObject) &&
            Object.values(outputs).length > 0
          ) {
            groupObject.department.push(departmentObject);
          }
        });

        if (!_.isEmpty(groupObject) && groupObject.department.length > 0) {
          output.push(groupObject);
        }
      });

      output.filter((data: any) => {
        groupList.push({ id: data.id, name: data.name });
      });

      setMenuNodeList(groupList);

      return output;
    }
  };

  /**
   * Retrieves sale item report information based on the provided filter and shift status.
   * @param {any} filter - The filter object containing parameters for the report.
   * @param {boolean} basedOnShift - Indicates whether the report is based on shift.
   */
  const getSaleItemReportInfo = async (filter: any, basedOnShift: boolean) => {
    try {
      // Call the API to fetch sale item report information
      const res = await fetchAllItemsReportInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );

      // Once the data is fetched successfully, call getGroupInfo to proceed with data processing
      getGroupInfo(res.data.data);
    } catch (err) {
      // If an error occurs during fetching, handle it by setting loading state and displaying error message
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Handles filter data and triggers the sale item report information retrieval.
   * @param {any} filterData - The filter data to be applied.
   * @param {boolean} basedOnShift - Indicates whether the filter is based on shift.
   */
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    // Set loading state to true to indicate data fetching
    setIsOpenSkeletonLoading(true);
    // Trigger the retrieval of sale item report information based on the provided filter data
    getSaleItemReportInfo(filterData, basedOnShift);
  };

  /**
   * Handles the click event on text by opening the filter card and scrolling to the top of the page.
   */
  const handleOnClickText = () => {
    // Open the filter card
    setOpenFilterCard(true);
    // Scroll to the top of the page smoothly
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLocationSelectorTypingList = (searchName: any) => {};

  const handleChangeCatMenu = (event: any) => {
    // Create a new array to store the custom department list
    const customDepartmentList: any = [];

    // Determine the action based on the selected category menu
    if (event.id === "all") {
      // If 'All' is selected, reset the items sales by category node to the initial state
      setItemsSalesByCategoryNode(itemsSalesByCategoryNodeInitial);
    } else {
      // If a specific category menu is selected, filter the items sales by category node
      const filteredNodes = itemsSalesByCategoryNodeInitial.filter(
        (group: any) => group.id === event.id,
      );
      setItemsSalesByCategoryNode(filteredNodes);
    }

    // Populate the custom department list based on the selected category menu
    itemsSalesByCategoryNodeInitial.forEach((group: any) => {
      if (group.id === event.id) {
        group.department.forEach((department: any) => {
          customDepartmentList.push({
            id: department.id,
            name: department.name,
          });
        });
      }
    });

    // Set the selected category menu title and the custom department list
    setSelectedCatMenuTitle(event.name);
    setDepartmentList(customDepartmentList);
  };

  return (
    <>
      <SaleReport
        handleFilterData={handleFilterData}
        locationSelectorList={locationSelectorList}
        handleLocationSelectorTypingList={handleLocationSelectorTypingList}
        setFilterDetails={setFilterDetails}
        availableFilter={salesItemsFilterObject}
        isOpenSkeletonLoading={isOpenSkeletonLoading}
        nodeList={itemsSalesByCategoryNode}
        filterDetails={filterDetails}
        title={""}
        isChart={false}
        isGetLocationInfo={isGetLocationInfo}
        isSaleItem={true}
        locationSelectedList={locationSelectedList}
        setLocationSelectedList={setLocationSelectedList}
        openFilterCard={openFilterCard}
        setOpenFilterCard={setOpenFilterCard}
        handleOnClickText={handleOnClickText}
        topic="Sales Items Report"
        isReport={true}
        isWeekDateRange={true}
        selectedCatMenuTitle={selectedCatMenuTitle}
        menuNodeList={menuNodeList}
        handleChangeCatMenu={handleChangeCatMenu}
        departmentList={departmentList}
        isNeedDropdown={true}
      >
        <ItemsReportInfoNode
          filterDetails={filterDetails}
          locationSelectedList={locationSelectedList}
          handleOnClickText={handleOnClickText}
          itemsSalesByCategoryNode={itemsSalesByCategoryNode}
          setDepartmentList={setDepartmentList}
          nodes={nodes}
          setNodes={setNodes}
        />
      </SaleReport>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(ItemSalesByCategoryReport, Authorities.SALE_READ);
