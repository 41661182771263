import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { fetchAllLocations } from "../../../../services/locationApp/locationService";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { getCookie } from "../../../../utils/cookies";
import LocationDetails from "./LocationDetails";

export interface HomeProps {}

/**
 * Location Component
 *
 * This component fetches and displays information about locations using an API call.
 * It utilizes the fetchAllLocations function from locationService and renders the
 * LocationDetails component to display location data. In case of errors, it utilizes
 * the DefaultAlert component to show an error message. The component manages loading
 * state and handles the initial data fetching using the useEffect hook.
 */

const Location: React.FunctionComponent<HomeProps> = () => {
  const [locationNodeList, setLocationNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const idToken = getCookie("idToken");

  /**
   * Asynchronous function to fetch location information based on the idToken from the server.
   * It utilizes the `fetchAllLocations` function from locationService, updates the state with
   * the received data, and manages loading and error states.
   */
  const getLocationInfo = async () => {
    try {
      // Make an API call to fetch all locations using the idToken
      const res = await fetchAllLocations(idToken);

      // Update loading state to indicate that data has been received
      setIsLoading(false);

      // Set the locationNodeList state with the received data
      setLocationNodeList(res.data.data);
    } catch (error) {
      // Set an error message in case of API call failure
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }

    // Update loading state to indicate completion (whether successful or with an error)
    setIsLoading(false);
  };

  /**
   * Effect hook to initiate the loading state and fetch location information when the component mounts.
   * The `getLocationInfo` function is called once when the component is mounted.
   */
  useEffect(() => {
    // Set loading state to indicate that data is being fetched
    setIsLoading(true);

    // Call the asynchronous function to fetch location information
    getLocationInfo();
  }, []);

  return (
    <>
      <Box>
        <LocationDetails
          isLoading={isLoading}
          locationNodeList={locationNodeList}
          getLocationInfo={getLocationInfo}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default Location;
