import Axios from "axios";
import { axiosClient } from "../client";

const queryString = require("query-string");

const SHIFT_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/shift`;

const SHIFT_REPORT_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/reports/shift/summary`;

  const DEVICE_ID_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS}/core/modules/${locationId}/hardware`;

  export const getAllDeviceIdInfo = (locationId) => {
    const config = {
      method: "GET",
      url: DEVICE_ID_INFO_GET_ALL(locationId),
      headers: {},
    };
  
    return Axios.request(config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
 

export const fetchAllShiftInfo = (
  token,
  locationId,
  size,
  currentPage,
  shortingMethod,
  shortingHeaderName,
  filterData,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: SHIFT_INFO_GET_ALL(locationId),
        query: {
          size: size,
          page: currentPage,
          sort: [shortingHeaderName, shortingMethod],
          where: filterData,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchAllShiftReportInfo = (token, locationId, filterData, data) =>
  axiosClient({
    method: "POST",
    url: queryString.stringifyUrl({
      url: SHIFT_REPORT_INFO_GET_ALL(locationId),
      query: {
        where: filterData,
      },
    }),
    data: data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
