import React from "react";
import withAuthority from "../../Auth/withAuthority";
import Authorities from "../../../auth/authorities";
import { Skeleton } from "@material-ui/lab";
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { CustomTheme } from "../../../types/customTheme";

export interface VoidSummeryProps {}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    buttonStyle: {
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.entity_background,
    },
    rowOdd: {
      backgroundColor: theme.palette.background.paper,
    },
    rowEven: {
      backgroundColor: theme.palette.background.entity_highlight_background,
    },
  }),
);

const ChartSkeletonLoading: React.FunctionComponent<VoidSummeryProps> = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} style={{ marginTop: "16px" }}>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Skeleton
          variant="rect"
          className={classes.buttonStyle}
          width={"100%"}
          height={360}
        />
      </Grid>
    </Grid>
  );
};

export default withAuthority(ChartSkeletonLoading, Authorities.DASHBOARD_READ);
