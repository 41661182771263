import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
  } from "@material-ui/core";
  import React from "react";
  
  export interface Props {
    handleChangeSelector: any;
    label: any;
    selectedValue: any;
    selectorList: any;
    disable: any;
  }
  
  /* Select one item uses this component. */
  const Selector: React.FunctionComponent<Props> = ({
    handleChangeSelector,
    label,
    selectedValue,
    selectorList,
    disable,
  }) => {
  
    /* Providing selected value with selector name. */
    const handleSelectedData = (e: any) => {
      handleChangeSelector(label, e.target.value);
    };
  
    return (
      <>
        <FormControl
          variant="outlined"
          disabled={disable}
          style={{ margin: "1px", width: "100%" }}
        >
          <InputLabel id="selector-label">{label}</InputLabel>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            labelId="selector-label-id"
            id="selector-id"
            value={selectedValue}
            label={label}
            onChange={handleSelectedData}
          >
            {selectorList.map((value: any) => {
              return <MenuItem value={value.id}>{value.label}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </>
    );
  };
  
  export default Selector;
  