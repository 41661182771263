import React, { useEffect } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import ValidationMessage from "../../../../../components/validation/ValidationMessage";
import SwitchCommon from "../../../../../components/switches/SwitchCommon";
import { Autocomplete } from "@material-ui/lab";
import { buttonSizeList } from "../../../../../utils/consts/list";
import { CustomTheme } from "../../../../../types/customTheme";
import ColorSelection from "../../../../PrestoExpressEatprestoApp/pages/ThemeCustomizationPage/ColorSelection";
import {
  fetchSingleCategoryInfo,
  updateCategoryInfo,
} from "../../../../../services/menuApp/menuService";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../../utils/consts";
import { useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme: CustomTheme) => ({
  autoComplete: {
    marginTop: "4px",
    "& .MuiAutocomplete-input": {
      color: theme.palette.custom.orange.contrastText,
    },
    "& .MuiInputBase-root": {
      color: "inherit",
      backgroundColor: theme.palette.background.entity_background,
      borderRadius: 10,
    },
    [`& fieldset`]: {
      borderRadius: 10,
      border: `1px solid ${theme.palette.background.entity_border}`,
      color: theme.palette.custom.orange.contrastText,
    },
  },
  titleColor: {
    color: theme.palette.custom.orange.contrastText,
  },
}));

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  register: any;
  errors: any;
  setValue: any;
  reset: any;
  handleCloseCategoryEditModal: any;
  isLoadingButton: any;
  selectedCategory: any;
  setIsValidCategory: any;
  isValidCategory: any;
  buttonSize: any;
  setButtonSize: any;
  color: any;
  setColor: any;
  kiosk: any;
  setKiosk: any;
  setOpenKioskDropDown: any;
  openKioskDropDown: any;
  isValidKiosk: any;
  setIsValidKiosk: any;
  isDirty: any;
  setError: any;
  setIsLoadingButton: any;
  handleSubmit: any;
  selectedMenuId: any;
  setSuccess: any;
  menuNodeList: any;
  setMenuNodeList: any;
  setIsCategoryNameEmpty: any;
}

const CategoryEditModal: React.FunctionComponent<StrategyAddEditModalProps> = ({
  isOpen,
  setIsOpen,
  register,
  errors,
  setValue,
  reset,
  handleCloseCategoryEditModal,
  isLoadingButton,
  selectedCategory,
  setIsValidCategory,
  isValidCategory,
  buttonSize,
  setButtonSize,
  color,
  setColor,
  kiosk,
  setKiosk,
  isValidKiosk,
  setIsValidKiosk,
  isDirty,
  setError,
  setIsLoadingButton,
  handleSubmit,
  selectedMenuId,
  setSuccess,
  menuNodeList,
  setMenuNodeList,
  setIsCategoryNameEmpty,
}) => {
  const [openPicker, setOpenPicker] = React.useState(false);

  const match: any = useRouteMatch();

  useEffect(() => {
    // Set default values from selectedCategory when it changes
    if (Object.values(selectedCategory).length > 0 && isOpen) {
      const { name, valid, kiosk, buttonSize, desc, colour } = selectedCategory;
      setButtonSize({ id: buttonSize, name: buttonSize?.toString() });
      setColor(colour);
      setIsValidKiosk(kiosk.toString() === "1" ? true : false);
      setIsValidCategory(valid);
      reset({
        name,
        desc,
      });
      setOpenPicker(true);
    }
  }, [selectedCategory, isOpen]);

  /* Active switch status change using switch */
  const handleSwitchActivate = (e: any) => {
    const { checked } = e.target;
    setIsValidCategory(checked);
  };

  const handleKioskActivate = (e: any) => {
    const { checked } = e.target;
    setIsValidKiosk(checked);
  };

  const handleButtonSizeChange = (e: any, buttonSize: any) => {
    setButtonSize(buttonSize);
  };

  /* Close color selection pop up */
  const handleDisable = () => {
    const { valid, kiosk, buttonSize: buttonSizes, colour } = selectedCategory;

    if (
      buttonSizes?.toString() === buttonSize?.id?.toString() &&
      !isDirty &&
      valid === isValidCategory &&
      kiosk?.toString() === (isValidKiosk ? "1" : "0") &&
      colour === color
    ) {
      return true;
    } else {
      return false;
    }
  };

  const classes = useStyles();

  const handleGetSingleCategory = async (categoryId: any, data: any) => {
    try {
      const res = await fetchSingleCategoryInfo(
        match.params.locationId,
        categoryId,
      );
      handleEditCategoryInfo409(data, res.data.data.version);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  };

  const handleEditCategoryInfo = handleSubmit(async (data: any) => {
    setIsLoadingButton(true);

    const { name, desc } = data;
    const body = {
      id: selectedCategory.id,
      name: name,
      locationId: match.params.locationId,
      menuId: selectedMenuId,
      desc: desc,
      valid: isValidCategory,
      buttonSize: buttonSize.id,
      kiosk: isValidKiosk ? 1 : 0,
      colour: color,
      version: selectedCategory.version,
    };

    try {
      let summary = [];
      if (selectedCategory.name !== name) {
        summary.push(
          `Category name changed from ${selectedCategory.name} to ${name}`,
        );
      }
      if (selectedCategory.desc !== desc && !_.isEmpty(desc)) {
        summary.push(
          `Description changed from ${selectedCategory.desc} to ${desc}`,
        );
      }
      if (selectedCategory.valid !== isValidCategory) {
        summary.push(
          `Valid changed from ${selectedCategory.valid} to ${isValidCategory}`,
        );
      }
      if (
        selectedCategory.buttonSize?.toString() !== buttonSize?.id?.toString()
      ) {
        summary.push(
          `Button Size changed from ${selectedCategory.buttonSize} to ${buttonSize.id}`,
        );
      }
      if (selectedCategory.kiosk.toString() !== (isValidKiosk ? "1" : "0")) {
        summary.push(
          `Kiosk changed from ${selectedCategory.kiosk} to ${
            isValidKiosk ? "1" : "0"
          }`,
        );
      }
      if (selectedCategory?.colour !== color) {
        summary.push(
          `Color changed from ${selectedCategory?.colour} to ${color}`,
        );
      }

      const summaryString = summary.join("\\n");

      const res = await updateCategoryInfo(
        match.params.locationId,
        body,
        "Menus Changed",
        summaryString,
        "menu",
      );
      if (res.data.status === 409) {
        handleGetSingleCategory(selectedCategory.id, data);
      } else {
        const cloneMenuNodeList = _.cloneDeep(menuNodeList);

        const wrapper =
          cloneMenuNodeList[selectedMenuId].category[res.data.data.id].wrapper;
        cloneMenuNodeList[selectedMenuId].category[res.data.data.id] =
          res.data.data;
        cloneMenuNodeList[selectedMenuId].category[res.data.data.id][
          "wrapper"
        ] = wrapper;
        setMenuNodeList(cloneMenuNodeList);

        setIsCategoryNameEmpty(false);
        setIsLoadingButton(false);
        setIsOpen(false);
        setSuccess(SUCCESSFULLY_CREATED);
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  });

  const handleEditCategoryInfo409 = async (data: any, version: any) => {
    const { name, desc } = data;
    const body = {
      id: selectedCategory.id,
      name: name,
      locationId: match.params.locationId,
      menuId: selectedMenuId,
      desc: desc,
      valid: isValidCategory,
      buttonSize: buttonSize.id,
      kiosk: isValidKiosk ? 1 : 0,
      colour: color,
      version: version,
    };

    try {
      let summary = [];
      if (selectedCategory.name !== name) {
        summary.push(
          `Category name changed from ${selectedCategory.name} to ${name}`,
        );
      }
      if (selectedCategory.desc !== desc && !_.isEmpty(desc)) {
        summary.push(
          `Description changed from ${selectedCategory.desc} to ${desc}`,
        );
      }
      if (selectedCategory.valid !== isValidCategory) {
        summary.push(
          `Valid changed from ${selectedCategory.valid} to ${isValidCategory}`,
        );
      }
      if (
        selectedCategory.buttonSize?.toString() !== buttonSize?.id?.toString()
      ) {
        summary.push(
          `Button Size changed from ${selectedCategory.buttonSize} to ${buttonSize.id}`,
        );
      }
      if (selectedCategory.kiosk.toString() !== (isValidKiosk ? "1" : "0")) {
        summary.push(
          `Kiosk changed from ${selectedCategory.kiosk} to ${
            isValidKiosk ? "1" : "0"
          }`,
        );
      }
      if (selectedCategory?.colour !== color) {
        summary.push(
          `Color changed from ${selectedCategory?.colour} to ${color}`,
        );
      }
      const summaryString = summary.join("\\n");

      const res = await updateCategoryInfo(
        match.params.locationId,
        body,
        "Menus Changed",
        summaryString,
        "menu",
      );
      if (res.data.status === 409) {
        handleGetSingleCategory(selectedCategory.id, data);
      } else {
        const cloneMenuNodeList = _.cloneDeep(menuNodeList);

        const wrapper =
          cloneMenuNodeList[selectedMenuId].category[res.data.data.id].wrapper;
        cloneMenuNodeList[selectedMenuId].category[res.data.data.id] =
          res.data.data;
        cloneMenuNodeList[selectedMenuId].category[res.data.data.id][
          "wrapper"
        ] = wrapper;
        setMenuNodeList(cloneMenuNodeList);

        setIsCategoryNameEmpty(false);
        setIsLoadingButton(false);
        setIsOpen(false);
        setSuccess(SUCCESSFULLY_CREATED);
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  };

  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>Edt Category</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={8}>
              <Typography style={{ paddingLeft: "4px" }}>Name</Typography>
              <TextfieldDefaultNew
                id="name"
                name="name"
                type="text"
                inputRef={register({
                  required: "Name is required",
                })}
              />
              {errors.name ? (
                <ValidationMessage message={errors.name.message} />
              ) : (
                ""
              )}
            </Grid>

            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                style={{ marginLeft: "0px", marginRight: "0px" }}
                control={<SwitchCommon />}
                checked={isValidCategory}
                onChange={handleSwitchActivate}
                label={
                  <Typography variant="caption" color="textSecondary">
                    Active
                  </Typography>
                }
                labelPlacement="bottom"
              />
            </Grid>

            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                style={{ marginLeft: "0px", marginRight: "0px" }}
                control={<SwitchCommon />}
                checked={isValidKiosk}
                onChange={handleKioskActivate}
                label={
                  <Typography variant="caption" color="textSecondary">
                    Kiosk
                  </Typography>
                }
                labelPlacement="bottom"
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <Typography style={{ paddingLeft: "4px" }}>
                Description
              </Typography>
              <TextfieldDefaultNew
                id="desc"
                name="desc"
                type="text"
                inputRef={register({
                  required: false,
                })}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <Typography style={{ paddingLeft: "4px" }}>
                Button Size
              </Typography>
              <Autocomplete
                size={"small"}
                id="locationSelectGlobal"
                color="inherit"
                options={buttonSizeList}
                value={buttonSize}
                getOptionLabel={(option: any) => option.name || ""}
                disableClearable
                onChange={handleButtonSizeChange}
                classes={{ root: classes.autoComplete }}
                popupIcon={<ArrowDropDownIcon color={"inherit"} />}
                renderOption={(props: any) => {
                  return (
                    <Grid
                      container
                      {...props}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        zIndex: 0,
                        height: "20px",
                        marginBottom: "4px",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          className={classes.titleColor}
                        >
                          {props.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
                renderInput={(params: any) => (
                  <TextField
                    color="inherit"
                    {...params}
                    label=""
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <Typography style={{ paddingLeft: "4px" }}>Color</Typography>
              <ColorSelection
                title={"Color"}
                colorCode={color}
                setColor={(color: any) => setColor(color.hex)}
                setInitialData={() => setColor(selectedCategory.color)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleCloseCategoryEditModal}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={handleDisable()}
            isLoadingPage={isLoadingButton}
            color={"orange"}
            onClick={handleEditCategoryInfo}
          >
            {"Save"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default CategoryEditModal;
