import * as React from "react";
import { Typography } from "@material-ui/core";
import _ from "lodash";

/**
 * StepEight Component
 *
 * This component represents the eighth step of a wizard modal.
 * It informs the user that stock item creation has been completed successfully.
 * It prompts the user to choose the next action.
 */
const StepEight: React.FunctionComponent<{}> = ({}) => {
  return (
    <>
      <Typography variant="h6">
        Stock item creation completed successfully
      </Typography>
      <Typography variant="body1">
        What action would you like to take next?
      </Typography>
    </>
  );
};

export default StepEight;
