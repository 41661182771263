import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import {
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import RewardsAddModal from "./RewardsAddModal";
import {
  createNewRewardsInfo,
  updateRewardsInfo,
} from "../../../../services/customerApp/customerDetails";
import { saveLogs } from "../../../../utils/firebase/logs";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../utils/consts";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";

export interface CustomerNodeProps {
  rewardNode: any;
  isLoading: any;
  isOwner: any;
  programId: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {},
  }),
);

/**
 * CustomerNode Component
 *
 * This component is responsible for rendering the customer node table.
 * It displays customer information in a table format and provides options for sorting and exporting data.
 */
const CustomerNode: React.FunctionComponent<CustomerNodeProps> = ({
  rewardNode,
  isOwner,
  programId,
}) => {
  const [rewardNodeList, setRewardNodeList] = useState<any>([]);
  const [editableRewardDetails, setEditableRewardDetails] = useState<any>([]);
  const [editableRewardId, setEditableRewardId] = useState<any>([]);
  const [isOpenRewardsAddModal, setIsOpenRewardsAddModal] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [rewardDetails, setRewardDetails] = useState<any>([]);
  const [isPointsReset, setIsPointsReset] = useState(false);
  const [error, setError] = useState("");
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [selectedStrategyNode, setSelectedStrategyNode] = useState<any>([]);
  const [success, setSuccess] = useState("");

  const match: any = useRouteMatch();

  useEffect(() => {
    if (rewardNode) {
      setRewardNodeList(rewardNode);
    }
  }, [rewardNode]);

  const handleCreateRewards = async (formData: any) => {
    setIsLoadingButton(true);
    try {
      const res = await createNewRewardsInfo(match.params.locationId, formData);
      let summary = [];
      summary.push(`Created Title is ${formData.title}`);

      formData.description &&
        summary.push(`Created Description is ${formData.description}`);

      formData.img && summary.push(`Created Image is ${formData.img}`);

      formData.units_per_cash &&
        summary.push(`Created Units Per Cash is ${formData.units_per_cash}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;
      const response = data.data;
      const cloneNodes = _.cloneDeep(rewardNodeList);
      cloneNodes[Object.keys(response)[0]] = Object.values(response)[0];
      setRewardNodeList(cloneNodes);

      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleOpenAddRewardsModal = () => {
    setIsOpenRewardsAddModal(true);
    setEditableRewardDetails([]);
    setEditableRewardId("");
  };

  const handleUpdateNewRewards = async (formData: any, rewardId: any) => {
    const rewardsInfo = {
      description: rewardDetails.description,
      img: rewardDetails.img,
      product_id: rewardDetails.product_id,
      strategy_id: rewardDetails.strategy_id,
      title: rewardDetails.title,
      type: "product",
      is_points_reset: isPointsReset,
      units_to_redeem: rewardDetails.units_to_redeem,
      products: formData,
    };

    try {
      const res = await updateRewardsInfo(
        match.params.locationId,
        rewardId,
        rewardsInfo,
      );

      let summary = [];
      summary.push(`Created Title is ${rewardDetails.title}`);

      rewardDetails.description &&
        summary.push(`Created Description is ${rewardDetails.description}`);

      rewardDetails.img &&
        summary.push(`Created Image is ${rewardDetails.img}`);

      rewardDetails.units_per_cash &&
        summary.push(
          `Created Units Per Cash is ${rewardDetails.units_per_cash}`,
        );

      rewardDetails &&
        summary.push(`Created Product details is ${rewardDetails}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(rewardNodeList);

      cloneNodes[rewardId] = response;
      setRewardNodeList(cloneNodes);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  console.log("sdfsdf45sadsadfsdf", isPointsReset);

  const handleCreateNewRewards = async (formData: any) => {
    console.log("sdfsdf45sadsadfsdf 666666666", isPointsReset);
    const rewardsInfo = {
      description: rewardDetails.description,
      img: rewardDetails.img,
      product_id: rewardDetails.product_id,
      strategy_id: rewardDetails.strategy_id,
      title: rewardDetails.title,
      type: "product",
      is_points_reset: isPointsReset,
      units_to_redeem: rewardDetails.units_to_redeem,
      products: formData,
    };
    try {
      const res = await createNewRewardsInfo(
        match.params.locationId,
        rewardsInfo,
      );

      let summary = [];
      summary.push(`Created Title is ${rewardDetails.title}`);

      rewardDetails.description &&
        summary.push(`Created Description is ${rewardDetails.description}`);

      rewardDetails.img &&
        summary.push(`Created Image is ${rewardDetails.img}`);

      rewardDetails.units_per_cash &&
        summary.push(
          `Created Units Per Cash is ${rewardDetails.units_per_cash}`,
        );

      rewardDetails &&
        summary.push(`Created Product details is ${rewardDetails}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(rewardNodeList);
      cloneNodes[Object.keys(response)[0]] = Object.values(response)[0];

      setRewardNodeList(cloneNodes);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleSubmitRewardsTypePoints = async (
    formData: any,
    isEdit: any,
    rewardId?: any,
  ) => {
    setIsLoadingButton(true);
    if (isEdit) {
      handleUpdateNewRewards(formData, rewardId);
    } else {
      handleCreateNewRewards(formData);
    }
  };

  const handleEditRewards = async (formData: any, rewardId: any) => {
    setIsLoadingButton(true);
    try {
      const res = await updateRewardsInfo(
        match.params.locationId,
        rewardId,
        formData,
      );

      let summary = [];
      summary.push(`Created Title is ${formData.title}`);

      formData.description &&
        summary.push(`Created Description is ${formData.description}`);

      formData.img && summary.push(`Created Image is ${formData.img}`);

      formData.units_per_cash &&
        summary.push(`Created Units Per Cash is ${formData.units_per_cash}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(rewardNodeList);

      cloneNodes[rewardId] = response;

      setRewardNodeList(cloneNodes);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleOpenEditRewardModal = (rewardId: any, rewardDetails: any) => {
    setIsOpenRewardsAddModal(true);
    setEditableRewardDetails(rewardDetails);
    setEditableRewardId(rewardId);
  };
  return (
    <>
      <div style={{ marginTop: "24px" }}>
        <Grid container style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs={12}
            // xs={8}
            // lg={9}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <ButtonCommon
              style={{
                fontSize: 11,
                width: "160px",
                height: "40px",
              }}
              disabled={!isOwner || Object.values(rewardNodeList).length >0}
              variant="contained"
              color="green"
              onClick={handleOpenAddRewardsModal}
            >
              Add New Reward
            </ButtonCommon>
          </Grid>
        </Grid>
        {!_.isEmpty(rewardNodeList) ? (
          <>
            <CardCommon backgroundColor={"table_header_background"}>
              <Grid
                container
                style={{ minHeight: "40px", placeItems: "center" }}
              >
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    paddingLeft: "12px",
                  }}
                >
                  <Typography>Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Description</Typography>
                </Grid>
                <Grid item xs={3} style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingRight: "20px",
                  }}>
                  <Typography>Type</Typography>
                </Grid>
              </Grid>
            </CardCommon>
            <Grid container>
              {Object.entries(rewardNodeList).map(([key, value]: any) => (
                <Grid item xs={12} style={{ marginTop: "4px" }}>
                  <CardCommon backgroundColor={"entity_background"}>
                    <Grid
                      container
                      style={{ minHeight: "40px", placeItems: "center" }}
                    >
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          paddingLeft: "12px",
                        }}
                      >
                        <Typography>{value.title}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{value.description}</Typography>
                      </Grid>
                      <Grid item xs={3} style={{
                          display: "flex",
                          justifyContent: "end",
                          paddingRight: "20px",
                        }}>
                        <Typography>{value.type}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          paddingRight: "12px",
                        }}
                      >
                        <ButtonCommon
                          style={{
                            fontSize: 11,
                            marginRight: 12,
                            width: "100px",
                            height: "28px",
                          }}
                          disabled={!isOwner}
                          variant="contained"
                          color="yellow"
                          onClick={() => handleOpenEditRewardModal(key, value)}
                        >
                          Edit
                        </ButtonCommon>
                      </Grid>
                    </Grid>
                  </CardCommon>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Typography variant="h4" style={{ marginTop: "80px" }}>
            Rewards Not Available.
          </Typography>
        )}

        <RewardsAddModal
          isOpen={isOpenRewardsAddModal}
          setIsOpen={setIsOpenRewardsAddModal}
          handleCreateRewards={handleCreateRewards}
          rewardNode={rewardNodeList}
          selectedStrategyNode={selectedStrategyNode}
          isLoadingPage={isLoadingPage}
          handleSubmitRewardsTypePoints={handleSubmitRewardsTypePoints}
          setRewardDetails={setRewardDetails}
          setIsPointsReset={setIsPointsReset}
          isPointsReset={isPointsReset}
          handleEditRewards={handleEditRewards}
          setError={setError}
          isLoadingButton={isLoadingButton}
          isOwner={isOwner}
          programId={programId}
          editableRewardId={editableRewardId}
          editableRewardDetails={editableRewardDetails}
        />
      </div>
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
    </>
  );
};

export default WithLoading(CustomerNode);
