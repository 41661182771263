import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@material-ui/core";
import _ from "lodash";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import VoidSummeryInfoNode from "./VoidSummeryInfoNode";
import { voidSummeryFilterObject } from "../../../../utils/consts/list";
import { CustomTheme } from "../../../../types/customTheme";
import SaleReport from "../../../../components/common/SaleReport";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { fetchAllVoidSummeryInfo } from "../../../../services/salesApp/voidSummeryServices";

/**
 * VoidSummery Component:
 *
 * This component is responsible for displaying the VAT summary report for void transactions.
 * It retrieves void summary information from the backend API based on the selected filters
 * such as location, date range, etc., and presents the data in a tabular format.
 */
const VoidSummery: React.FunctionComponent<{}> = () => {
  const [voidSummeryNodeList, setVoidSummeryNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [vatPercentList, setVatPercentList] = useState<any>([]);
  const [filterDetails, setFilterDetails] = useState("");
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [locationData, setLocationData] = useState("");
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /* Get void summery information */
  const getVoidSummeryInfo = async (filter: any, basedOnShift: boolean) => {
    setVoidSummeryNodeList([]);
    try {
      const res = await fetchAllVoidSummeryInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );

      setVoidSummeryNodeList(res.data.data);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
    setIsLoading(false);
  };

  /* Get location details. */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) =>
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            }),
          );
          const locationName = getFilterListFromArrayObject(
            locationList,
            match.params.locationId,
          );
          setLocationData(locationName[0].label);
        }
        setLocationSelectorList(locationList);
        setVatPercentList([0, 5, 12.5, 20, "total"]);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    document.title = "Sale - VAT Summary";
    getAllFilterLocation("");
  }, []);

  // Get filter data
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    setBasedOnShift(basedOnShift);
    setIsLoading(true);
    getVoidSummeryInfo(filterData, basedOnShift);
    setFilterData(filterData);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  const theme: CustomTheme = useTheme();
  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={voidSummeryFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={voidSummeryNodeList}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          isUserGet={false}
          filterTitle="VAT Summary report for"
          topic="VAT Summary"
          isNeedFilterDetails={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
          payInOut={true}
        >
          <VoidSummeryInfoNode
            voidSummeryNodeList={voidSummeryNodeList}
            locationSelectorList={locationSelectorList}
            filterDetails={filterDetails}
            vatPercentList={vatPercentList}
            filterData={filterData}
            basedOnShift={basedOnShift}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
          />
        </SaleReport>
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(VoidSummery, Authorities.SALE_READ);
