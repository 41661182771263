import * as React from "react";
import { Route, Switch } from "react-router-dom";

import ManageApps from "../pages/ManageApps";
import SignInPage from "../pages/SignInPage";
import UserAccountDetails from "../pages/UserAccountDetails";

export interface AccountsAppRoutesProps {}

/**
 * `AccountsAppRoutes` component handles the routing for different pages in the accounts application.
 * It uses React Router's `Switch` and `Route` components to define routes for the sign-in page,
 * user account details page, and managing apps page.
 */
const AccountsAppRoutes: React.FunctionComponent<
  AccountsAppRoutesProps
> = () => {
  return (
    <Switch>
      {/* Route for the sign-in page */}
      <Route path="/accounts/sign-in" component={SignInPage} />

      {/* Route for the user account details page */}
      <Route path="/accounts/userDetails" component={UserAccountDetails} />

      {/* Route for managing apps */}
      <Route path="/accounts/apps" component={ManageApps} />
    </Switch>
  );
};

export default AccountsAppRoutes;
