
/* Return a length od object */
export const getNumberOfItems = (object: Object) => {
  let numberOfKeyByObject = getNumberOfKeyByObject(object);
  if (numberOfKeyByObject === 1) {
    return numberOfKeyByObject + " item";
  } else {
    return numberOfKeyByObject + " items";
  }
};

/* Calculate length of object */
export const getNumberOfKeyByObject = (object: Object) => {
  return Object.keys(object).length;
};
