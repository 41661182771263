import { FormControlLabel, Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useForm } from "react-hook-form";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import {
  accept_check_jobSubTypeList,
  deliveryTrackingSubTypeList,
  emailPhoneReminder_jobSubTypeList,
  taskTriggerList,
  typeList,
} from "../../../../utils/consts/listArrays";
import {
  EMAIL_PATTERN,
  ERROR_MESSAGE_UPDATING_ERROR,
  ERROR_MESSAGE_VERSION_ERROR,
  INTEGER_NUMBER_PATTERN,
  NODE_STATUS_CREATE,
  REQUIRED_FIELD_PATTERN,
} from "../../../../utils/consts";
import { Theme } from "../../../../types/customTheme";
import ConfirmDeleteDialogCommon from "../../../../components/dialogs/ConfirmDeleteDialogCommon";
import {
  createTaskInfo,
  deleteTaskInfo,
  updateTaskInfo,
} from "../../../../services/taskApp/taskInfoService";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import CardCommon from "../../../../components/card/CardCommon";
import {
  buttonColors,
  HttpStatus,
  submitButtonName,
} from "../../../../utils/enum";
import Selector from "../../../../components/common/selector/Selector";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      width: "90%",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    actionsWrapper: {
      paddingTop: "16px",
      display: "flex",
      justifyContent: "center",
    },
    switchGlobalLocationStyle: {
      display: "flex",
      justifyContent: "end",
      marginTop: "28px",
      marginBottom: "8px",
      marginRight: "-8px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0px",
        marginTop: "6px",
      },
    },
    activeSwitchStyle: {
      display: "flex",
      justifyContent: "end",
      marginTop: "12px",
      marginRight: "10px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0px",
        marginTop: "0px",
      },
    },
    switchStyle: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        justifyContent: "space-evenly",
      },
    },
    someTextField: {
      minHeight: 32,
    },
    base: {
      padding: "16px 16px",
      [theme.breakpoints.up("sm")]: {
        padding: "20px 20px",
      },
    },
  }),
);

interface FormData {
  resource: any;
  resourceTitle: any;
  resourceSubtitle: any;
  resourceBody: any;
}
export interface TaskInfoNodeProps {
  nodeData: any;
  getTaskInfo: any;
  handleRemoveNode: any;
  isAuthorized: boolean;
}

/**
 * TaskInfoNode Component:
 *
 * This component is responsible for displaying and managing the details of a task information node.
 * Users can view, create, update, or delete task information through this component.
 */
const TaskInfoNode: React.FunctionComponent<TaskInfoNodeProps> = ({
  nodeData,
  getTaskInfo,
  handleRemoveNode,
  isAuthorized,
}) => {
  const [error, setError] = useState("");
  const [isCreated, setIsCreated] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [nodeID, setNodeID] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [scopeSwitch, setScopeSwitch] = useState(true);
  const [scopeSwitchInitial, setScopeSwitchInitial] = useState(true);
  const [taskTrigger, setTaskTrigger] = useState("");
  const [type, setType] = useState("");
  const [typeInitial, setTypeInitial] = useState("");
  const [subType, setSubType] = useState("");
  const [resource, setResource] = useState("");
  const [resourceInitial, setResourceInitial] = useState("");
  const [isTaskTigerEmpty, setIsTaskTigerEmpty] = useState(false);
  const [isTypeEmpty, setIsTypeEmpty] = useState(false);
  const [isSubTypeEmpty, setIsSubTypeEmpty] = useState(false);
  const [subTypeList, setSubTypeList] = useState<any>([]);
  const [resourceTextFieldTitle, setResourceTextFieldTitle] = useState("");
  const [originalNodeData, setOriginalNodeData] = useState({
    isActive: false,
    id: "",
    scope: "",
    taskTrigger: "",
    type: "",
    subType: "",
    resource: "",
    resourceTitle: "",
    resourceSubtitle: "",
    resourceBody: "",
  });

  /* Use a react form hooks */
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState,
    clearErrors,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      resource: "",
      resourceTitle: "",
      resourceSubtitle: "",
      resourceBody: "",
    },
  });

  /* Check the form is edited or not */
  const isFormEdited = formState.isDirty;

  const match: any = useRouteMatch();

  const getNodeDataBySelector = () => {
    const updatedData = _.cloneDeep(originalNodeData);
    updatedData.isActive = isActive;
    updatedData.taskTrigger = taskTrigger;
    updatedData.type = type;
    updatedData.subType = subType;
    return updatedData;
  };

  const getNodeDataByState = (data: any) => {
    const updatedData = getNodeDataBySelector();
    if (data.resource) {
      updatedData.resource = data.resource;
    } else if (resource) {
      updatedData.resource = resource;
    } else {
      updatedData.resource = "";
    }
    if (scopeSwitch) {
      updatedData.scope = "location";
    } else {
      updatedData.scope = "global";
    }
    return updatedData;
  };

  const handleChangeTypes = (type: any) => {
    if (type === "accept_check_job") {
      setResourceTextFieldTitle("Resource");
      setSubTypeList(accept_check_jobSubTypeList);
    } else if (type === "delivery_tracking") {
      setResourceTextFieldTitle("Resource");
      setSubTypeList(deliveryTrackingSubTypeList);
    } else {
      setSubTypeList(emailPhoneReminder_jobSubTypeList);
      if (type === "reminder_job") {
        setResourceTextFieldTitle("Minutes");
      } else if (type === "email") {
        setResourceTextFieldTitle("Email");
      }
    }
  };

  const setNodeDataToState = useCallback((nodeValues) => {
    const {
      id,
      isActive,
      scope,
      taskTrigger,
      subType,
      type,
      resource,
      resourceTitle,
      resourceSubtitle,
      resourceBody,
    } = nodeValues;
    setNodeID(id);
    setIsActive(isActive);
    setTaskTrigger(taskTrigger);
    setSubType(subType);
    setType(type);
    setTypeInitial(type);
    setResource(resource);
    setResourceInitial(resource);
    handleChangeTypes(type);
    if (scope === "global") {
      setScopeSwitchInitial(false);
      setScopeSwitch(false);
    } else if (scope === "location") {
      setScopeSwitchInitial(true);
      setScopeSwitch(true);
    }
    reset({
      resource,
      resourceTitle,
      resourceSubtitle,
      resourceBody,
    });
  }, []);

  useEffect(() => {
    if (typeInitial === type) {
      setResource(resourceInitial);
      setValue("resource", resourceInitial);
    } else {
      setResource("");
      setValue("resource", "");
    }
  }, [type]);

  useEffect(() => {
    setOriginalNodeData(nodeData);
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const updatedData = getNodeDataBySelector();
  const isSame = _.isEqual(originalNodeData, updatedData);

  const handleErrorMessage = (error: any) => {
    if (error.response.status === HttpStatus.CONFLICT_409) {
      setError(ERROR_MESSAGE_VERSION_ERROR);
      getTaskInfo();
    } else {
      setError(ERROR_MESSAGE_UPDATING_ERROR);
    }
  };

  const handleUpdateTaskInfo = async (data: any) => {
    setError("");
    const updatedData = getNodeDataByState(data);
    try {
      const res = await updateTaskInfo(
        match.params.locationId,
        nodeID,
        updatedData,
      );
      getTaskInfo();
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  const handleCreateTaskOption = async (data: any) => {
    setError("");
    const createData = getNodeDataByState(data);
    const res = await createTaskInfo(match.params.locationId, createData);
    try {
      setIsCreated(true);
      getTaskInfo();
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  const handleDeleteTaskOption = async () => {
    setError("");
    const res = await deleteTaskInfo(match.params.locationId, nodeID);
    try {
      getTaskInfo();
      setOpenDeleteConfirm(false);
    } catch (error) {
      setOpenDeleteConfirm(false);
      handleErrorMessage(error);
    }
  };

  const resetToOriginalData = () => {
    setScopeSwitch(scopeSwitchInitial);
    setNodeDataToState(originalNodeData);
  };

  const deleteFunc = () => {
    if (isCreated) {
      handleDeleteTaskOption();
    } else if (nodeData.status === NODE_STATUS_CREATE) {
      handleRemoveNode();
    } else {
      setOpenDeleteConfirm(true);
    }
  };

  const handleCheckValidation = () => {
    let isValidated = false;
    if (_.isEmpty(taskTrigger)) {
      setIsTaskTigerEmpty(true);
    } else if (_.isEmpty(type)) {
      setIsTypeEmpty(true);
    } else if (_.isEmpty(subType)) {
      setIsSubTypeEmpty(true);
    } else {
      isValidated = true;
    }
    return isValidated;
  };

  const handleSubmitData = handleSubmit((data) => {
    setError("");
    const isValidated = handleCheckValidation();
    if (nodeData.status === NODE_STATUS_CREATE && isValidated) {
      return handleCreateTaskOption(data);
    } else if (isValidated) {
      return handleUpdateTaskInfo(data);
    }
  });

  /* Active switch status change using switch */
  const handleSwitchActivate = (e: any) => {
    const { checked } = e.target;
    setIsActive(checked);
  };

  const handleChangeSelector = (label: any, selected: any) => {
    clearErrors("resource");
    if (label === "Task Tiger") {
      setIsTaskTigerEmpty(false);
      setTaskTrigger(selected);
    } else if (label === "Type") {
      setIsTypeEmpty(false);
      setSubType("");
      handleChangeTypes(selected);
      setType(selected);
    } else if (label === "Sub Type") {
      setIsSubTypeEmpty(false);
      setSubType(selected);
    }
  };

  const handleChangeScopeSwitch = () => {
    // if (nodeData.status !== NODE_STATUS_CREATE) {
    //   if (scopeSwitch) {
    //     setResource(getValues("resource"));
    //   }
    //   setScopeSwitch(!scopeSwitch);
    // }
  };

  const handleResourceTextField = () => {
    if (!_.isEmpty(type) && type !== "phone") {
      return true;
    } else {
      return false;
    }
  };

  const handelResourceType = () => {
    let resourceType: string = "";
    if (type === "email" || type === "delivery_tracking") {
      resourceType = "text";
    } else {
      resourceType = "number";
    }
    return resourceType;
  };

  const handleValidationPattern = () => {
    let pattern: any = {};
    if (type === "email") {
      pattern = {
        value: EMAIL_PATTERN,
        message: "Please enter a valid email address",
      };
    } else if (type === "delivery_tracking") {
      pattern = {
        value: REQUIRED_FIELD_PATTERN,
        message: "Resource is required",
      };
    } else {
      pattern = {
        value: INTEGER_NUMBER_PATTERN,
        message: "Please enter a Integer value",
      };
    }
    return pattern;
  };

  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <ConfirmDeleteDialogCommon
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        confirmAction={handleDeleteTaskOption}
      />

      <CardCommon>
        <form className={classes.base}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <div style={{ display: "flex", justifyContent: "start" }}>
                <Selector
                  disable={!scopeSwitch}
                  handleChangeSelector={handleChangeSelector}
                  label={"Task Tiger"}
                  selectorList={taskTriggerList}
                  selectedValue={taskTrigger}
                />
              </div>
              {isTaskTigerEmpty && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please select a Task tiger.
                  </Typography>
                </div>
              )}

              <div style={{ marginTop: "16px" }}>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <Selector
                    disable={!scopeSwitch || _.isEmpty(type)}
                    handleChangeSelector={handleChangeSelector}
                    label={"Sub Type"}
                    selectorList={subTypeList}
                    selectedValue={subType}
                  />
                </div>
                {isSubTypeEmpty && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginBottom: "3px",
                    }}
                  >
                    <Typography
                      align="left"
                      variant="caption"
                      style={{ color: "red" }}
                    >
                      Please select a Sub type.
                    </Typography>
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={6} sm={4}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Selector
                  disable={!scopeSwitch}
                  handleChangeSelector={handleChangeSelector}
                  label={"Type"}
                  selectorList={typeList}
                  selectedValue={type}
                />
              </div>
              {isTypeEmpty && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please select a Type.
                  </Typography>
                </div>
              )}

              <div style={{ marginTop: "10px" }}>
                {handleResourceTextField() && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <TextField
                      id="resource"
                      name="resource"
                      style={{ width: "100%" }}
                      label={resourceTextFieldTitle}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type={handelResourceType()}
                      className={classes.textField}
                      variant="outlined"
                      disabled={!isAuthorized}
                      InputProps={{
                        classes: { input: classes.someTextField },
                      }}
                      inputRef={register({
                        required: `${resourceTextFieldTitle} is required`,
                        pattern: handleValidationPattern(),
                      })}
                      helperText={
                        errors.resource ? (
                          <ValidationMessage
                            message={errors.resource.message}
                          />
                        ) : (
                          ""
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={4} className={classes.switchStyle}>
              <div className={classes.switchGlobalLocationStyle}>
                <Typography variant="body2" style={{ paddingTop: "10px" }}>
                  Global
                </Typography>
                <FormControlLabel
                  style={{ paddingLeft: "8px" }}
                  control={<SwitchCommon />}
                  checked={scopeSwitch}
                  onChange={handleChangeScopeSwitch}
                  label={<Typography variant="body2">Location</Typography>}
                />
              </div>
              <div className={classes.activeSwitchStyle}>
                <FormControlLabel
                  control={<SwitchCommon />}
                  checked={isActive}
                  onChange={handleSwitchActivate}
                  label="Active"
                  labelPlacement="start"
                  disabled={!isAuthorized || !scopeSwitch}
                />
              </div>
            </Grid>
          </Grid>
          {isAuthorized && (
            <Grid container spacing={2} className={classes.actionsWrapper}>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "start" }}
              >
                <ButtonCommon
                  disabled={isSame && !isFormEdited}
                  style={{
                    width: "100%",
                    fontSize: "11px",
                  }}
                  variant="contained"
                  color={
                    nodeData.status === NODE_STATUS_CREATE && !isCreated
                      ? buttonColors.CREATE_BUTTON_COLOR
                      : buttonColors.UPDATE_BUTTON_COLOR
                  }
                  onClick={handleSubmitData}
                >
                  {nodeData.status === NODE_STATUS_CREATE
                    ? submitButtonName.CREATE_SUBMIT_BUTTON
                    : submitButtonName.UPDATE_SUBMIT_BUTTON}
                </ButtonCommon>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ButtonCommon
                  disabled={isSame && !isFormEdited}
                  variant="contained"
                  style={{ width: "100%", fontSize: "11px" }}
                  color={buttonColors.CANCEL_BUTTON_COLOR}
                  startIcon={<RotateLeftIcon />}
                  onClick={resetToOriginalData}
                >
                  Cancel
                </ButtonCommon>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <ButtonCommon
                  disabled={!scopeSwitch}
                  variant="contained"
                  style={{
                    width: "100%",
                    fontSize: "11px",
                  }}
                  color={buttonColors.DELETE_BUTTON_COLOR}
                  startIcon={<DeleteIcon />}
                  onClick={deleteFunc}
                >
                  Delete
                </ButtonCommon>
              </Grid>
            </Grid>
          )}
        </form>
      </CardCommon>

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </Grid>
  );
};

export default withAuthority(TaskInfoNode, Authorities.TASKS_READ);
