import { Grid, Typography } from "@material-ui/core";
import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import TextfieldCommon from "../../../components/textField/TextfieldCommon";
import ValidationMessage from "../../../components/validation/ValidationMessage";
import { EMAIL_PATTERN, PHONE_NUMBER_PATTERN } from "../../../utils/consts";
import _ from "lodash";

export interface HomeProps {
  handleCloseModal: any;
  locationData: any;
  isValidated: any;
  handleChangeLocationData: any;
}

/* This component displays text fields used for Business Contact Number, Business Email, 
   Business Website, and Order Url. */
const BusinessContactInformation: React.FunctionComponent<HomeProps> = ({
  handleCloseModal,
  locationData,
  isValidated,
  handleChangeLocationData,
}) => {
  /* Checking the validity of contact number. */
  const handleContactNumberValidate = () => {
    if (isValidated) {
      // Checking if contact number is available.
      if (!locationData.contactNo) {
        return "Contact number is required.";
      } else if (!PHONE_NUMBER_PATTERN.test(locationData.contactNo)) {
        // Checking whether the contact number has 10 digits and only digits.
        return "Please enter a valid contact number";
      }
    }
  };

  /* Checking the validity of email. */
  const handleEmailValidate = () => {
    if (isValidated) {
      // Checking if email is available.
      if (!locationData.email) {
        return "Email is required.";
      } else if (!EMAIL_PATTERN.test(locationData.email)) {
        // Check the email number is valid.
        return "Please enter a valid email address";
      }
    } else {
      return false;
    }
  };
  return (
    <Grid container style={{ padding: "16px" }}>
      <Grid
        item
        xs={12}
        style={{
          marginBottom: "8px",
          marginLeft: "4px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">business Contact Information</Typography>
        <CancelIcon
          style={{ marginRight: "4px", cursor: "pointer" }}
          onClick={handleCloseModal}
        />
      </Grid>
      <Grid item xs={12}>
        <TextfieldCommon
          id="contactNo"
          name="contactNo"
          label="Business Contact No"
          type="number"
          autoComplete="off"
          error={!_.isEmpty(handleContactNumberValidate())}
          value={locationData.contactNo}
          onChange={handleChangeLocationData}
        />
        <div style={{ marginLeft: "2px" }}>
          <ValidationMessage message={handleContactNumberValidate()} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextfieldCommon
          id="email"
          name="email"
          label="Business Email"
          type="text"
          autoComplete="off"
          error={!_.isEmpty(handleEmailValidate())}
          value={locationData.email}
          onChange={handleChangeLocationData}
        />
      </Grid>
      <div style={{ marginLeft: "2px" }}>
        <ValidationMessage message={handleEmailValidate()} />
      </div>
      <Grid item xs={12}>
        <TextfieldCommon
          id="web"
          name="web"
          label="Business Website"
          type="text"
          autoComplete="off"
          value={locationData.web}
          onChange={handleChangeLocationData}
        />
      </Grid>
      <Grid item xs={12}>
        <TextfieldCommon
          id="orderUrl"
          name="orderUrl"
          label="Order Url"
          type="text"
          autoComplete="off"
          value={locationData.orderUrl}
          onChange={handleChangeLocationData}
        />
      </Grid>
    </Grid>
  );
};

export default BusinessContactInformation;
