import React from "react";
import { Route, Switch } from "react-router-dom";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import CustomerListPage from "../pages/CustomerListPage";
import { Icon } from "@iconify/react";
import Location from "../../PrestoExpressLocationApp/pages/Location";
import LoyaltyConfiguration from "../pages/LoyaltyConfiguration";
import StrategyPage from "../pages/StrategyPage";
import RewardListPage from "../pages/RewardListPage";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "CustomerList",
      title: "CustomerList",
      path: "/customerList",
      icon: <span className="material-symbols-outlined">group</span>,
      isAuthorized: getIsAuthorized(Authorities.CUSTOMER_LIST_READ),
    },
    {
      id: "RewardList",
      title: "RewardList",
      path: "/rewardList",
      icon: <span className="material-symbols-outlined">group</span>,
      isAuthorized: getIsAuthorized(Authorities.CUSTOMER_LIST_READ),
    },
    {
      id: "StrategyList",
      title: "StrategyList",
      path: "/strategyList",
      icon: <span className="material-symbols-outlined">group</span>,
      isAuthorized: getIsAuthorized(Authorities.CUSTOMER_LIST_READ),
    },
    {
      id: "internal",
      title: "Internal Configurations",
      path: "/internalConfiguration",
      icon: <Icon icon="ri:admin-line" height="24" width="24" />,
      isAuthorized: getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ),
      childRoutes: [
        {
          id: "loyaltyConfiguration",
          title: "Loyalty Configuration",
          path: "/internalConfiguration/loyaltyConfiguration",
          icon: <Icon icon="dashicons:admin-generic" height="24" width="24" />,
          isAuthorized: getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ),
        },
      ],
    },
  ];
  return sidebarRoute;
};
export interface TasksAppRoutesProps {}

const TasksAppRoutes: React.FunctionComponent<TasksAppRoutesProps> = () => {
  return (
    <Switch>
      <Route
        path="/:locationId/presto-customer/customerList"
        component={CustomerListPage}
        exact
      />
       <Route
        path="/:locationId/presto-customer/internalConfiguration/loyaltyConfiguration"
        component={LoyaltyConfiguration}
        exact
      />
      <Route
        path="/:locationId/presto-customer/strategyList"
        component={StrategyPage}
        exact
      />
      <Route
        path="/:locationId/presto-customer/rewardList"
        component={RewardListPage}
        exact
      />
      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default TasksAppRoutes;
