import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { handleDownloadCsv } from "./convertPdfExcel/convertDataToExcel";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";
import DailyOrderReportInfoNode from "./DailyOrderReportInfoNode";

export interface VoidSummeryInfoNodeProps {
  orderReportNodeListDaily: any;
  filterDetails: any;
  locationSelectedList: any;
  handleOnClickText: any;
  orderReportNodeListWeekly: any;
  orderReportNodeListMonthly: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    discountTypeSection: {
      borderRadius: 8,
    },
    tabPanel: {
      backgroundColor: theme.palette.background.default,
    },
  }),
);

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`discount-type-tab-${index}`}
      aria-labelledby={`discount-type-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ padding: "16px 0" }}>{children}</div>}
    </div>
  );
};

/**
 * This component represents a tabbed view for displaying daily, weekly, and monthly order reports.
 * It allows users to switch between different time intervals and export the data as PDF or CSV.
 * The component fetches data based on the selected time interval and location, and renders it accordingly.
*/
const DailyOrderReportTab: React.FunctionComponent<
  VoidSummeryInfoNodeProps
> = ({
  orderReportNodeListDaily,
  filterDetails,
  locationSelectedList,
  handleOnClickText,
  orderReportNodeListWeekly,
  orderReportNodeListMonthly,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([
    { header: "Date", key: "date", width: 52, height: 68 },
    { header: "Orders", key: "orders", width: 32, height: 68 },
    { header: "Total", key: "total", width: 32, height: 68 },
    { header: "Cash", key: "cash", width: 32, height: 68 },
    { header: "Card", key: "card", width: 32, height: 68 },
    { header: "Collection", key: "takeout", width: 32, height: 68 },
    { header: "Delivery", key: "delivery", width: 32, height: 68 },
  ]);
  const [nodesLength, setNodesLength] = useState(0);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState(0);
  
  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    setNodes({ nodes: [] });
  }, [params.get("startDate")]);

  useEffect(() => {
    if (value === 0) {
      if (!_.isEmpty(orderReportNodeListDaily)) {
        setNodes({ nodes: orderReportNodeListDaily });
        setNodesPdfExcel(orderReportNodeListDaily);
        setNodesLength(orderReportNodeListDaily?.length);
      } else {
        setNodes({ nodes: [] });
      }
    } else if (value === 1) {
      if (!_.isEmpty(orderReportNodeListWeekly)) {
        setNodes({ nodes: orderReportNodeListWeekly });
        setNodesPdfExcel(orderReportNodeListWeekly);
        setNodesLength(orderReportNodeListWeekly?.length);
      } else {
        setNodes({ nodes: [] });
      }
    } else if (value === 2) {
      if (!_.isEmpty(orderReportNodeListMonthly)) {
        setNodes({ nodes: orderReportNodeListMonthly });
        setNodesPdfExcel(orderReportNodeListMonthly);
        setNodesLength(orderReportNodeListMonthly?.length);
      } else {
        setNodes({ nodes: [] });
      }
    }
    setPdfHeaders([
      { title: "Date", field: "date" },
      { title: "Orders", field: "orders" },
      { title: "Total", field: "total" },
      { title: "Cash", field: "cash" },
      { title: "Card", field: "card" },
      { title: "Collection", field: "takeout" },
      { title: "Delivery", field: "delivery" },
    ]);
  }, [
    value,
    orderReportNodeListDaily,
    orderReportNodeListWeekly,
    orderReportNodeListMonthly,
  ]);

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* Switch the tab to be displayed. */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const theme: CustomTheme = useTheme();

  const classes: any = useStyles();

  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
            {!_.isEmpty(nodes.nodes) && (
              <div
                style={
                  maxWidthLocationChips
                    ? {
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "8px",
                      }
                    : { marginLeft: "16px" }
                }
              >
                <MenuButtonCommon
                  id="basic-button"
                  variant="contained"
                  style={{
                    fontSize: 11,
                    height: "38px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  color="blue"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <GetAppIcon />
                  <Typography
                    variant="body1"
                    style={{ paddingLeft: "16px", color: "white" }}
                  >
                    Export
                  </Typography>
                </MenuButtonCommon>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    style: {
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    },
                  }}
                  style={{ top: "56px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadPdf(
                        "p",
                        pdfHeaders,
                        nodesPdfExcel,
                        filterDetails,
                      );
                      handleClose();
                    }}
                  >
                    Export as PDF
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadCsv(
                        excelHeaders,
                        nodesPdfExcel,
                        filterDetails,
                        nodesLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as CSV
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <>
        <CardCommon>
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="discount-types-tabs"
            variant="fullWidth"
          >
            <Tab
              label="DAILY"
              id="discount-type-tab-0"
              aria-controls="discount-type-tab-0"
            />
            <Tab
              label="WEEKLY"
              id="discount-type-tab-1"
              aria-controls="discount-type-tab-1"
            />
            <Tab
              label="MONTHLY"
              id="discount-type-tab-1"
              aria-controls="discount-type-tab-1"
            />
          </Tabs>
        </CardCommon>
        <div className={classes.root}>
          <TabPanel value={value} index={0}>
            <div className={classes.discountTypeSection}>
              <Typography variant="h4" component="div" />
              <DailyOrderReportInfoNode
                nodes={nodes}
                nodesLength={nodesLength}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className={classes.discountTypeSection}>
              <Typography
                variant="h4"
                component="div"
                align="left"
                style={{ margin: 4 }}
              />
              <DailyOrderReportInfoNode
                nodes={nodes}
                nodesLength={nodesLength}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className={classes.discountTypeSection}>
              <Typography
                variant="h4"
                component="div"
                align="left"
                style={{ margin: 4 }}
              />
              <DailyOrderReportInfoNode
                nodes={nodes}
                nodesLength={nodesLength}
              />
            </div>
          </TabPanel>
        </div>
      </>
    </>
  );
};

export default DailyOrderReportTab;
