import * as React from "react";
import ShapeLine from "../../shapes/ShapeLine";
import ShapeButton from "../../shapes/ShapeButton";
import ShapeToggleButton from "../../shapes/ShapeToggleButton";

export interface PreviewBasketProps {
  textColor: string;
  buttonColor: string;
  buttonTextColor: string;
}

/* Show user selected color for color preview box.
(textColor, buttonColor, buttonTextColor) */
const PreviewBasket: React.FunctionComponent<PreviewBasketProps> = ({
  textColor,
  buttonColor,
  buttonTextColor,
}) => {

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <ShapeButton
          length={2}
          height={1}
          color={buttonColor}
          textColor={buttonTextColor}
        />
      </div>
      <div style={{ margin: 8 }}></div>
      <ShapeLine length={5} color={textColor} />
      <div style={{ margin: 8 }}></div>
      <ShapeLine length={5} color={textColor} />
      <div style={{ margin: 8 }}></div>
      <ShapeLine length={5} color={textColor} />
      <div style={{ margin: 8 }}></div>
      <ShapeLine length={5} color={textColor} />
      <div style={{ margin: 30 }}></div>
      <ShapeLine length={5} color={textColor} />
      <div style={{ margin: 8 }}></div>
      <ShapeLine length={5} color={textColor} />
      <div style={{ margin: 30 }}></div>
      <ShapeLine length={5} color={textColor} />
      <div style={{ margin: 8 }}></div>
      <ShapeLine length={5} color={textColor} />
      <div style={{ margin: 20 }}></div>
      <ShapeToggleButton />
      <div style={{ margin: 8 }}></div>
      <ShapeButton
        length={4}
        height={1}
        color={buttonColor}
        textColor={buttonTextColor}
      />
      <div style={{ margin: 8 }}></div>
      <ShapeToggleButton />
      <div style={{ margin: 8 }}></div>
      <ShapeButton
        length={4}
        height={2}
        color={buttonColor}
        textColor={buttonTextColor}
      />
    </>
  );
};

export default PreviewBasket;
