import React from "react";

import WithLoading from "../../../../utils/WithLoading";
import MenuInfoPageNew from "./MenuInfoPageNew";

export interface LoadingProps {
  isLoading: Boolean;
  menuNodeList: any;
  getCategoryInfo: any;
  menuNode: any;
  categoryNode: any;
  getProductWrapperInfo: any;
  getMenuInfo: any;
  productVersion: any;
  selectedGroupObj: any;
  stockGroupSelectedNode: any;
  setSelectedGroupObj: any;
  stockDepartmentSelectedNode: any;
  getAllStockDepartments: any;
  getProductGroupInfo: any;
  isOpenCategoryEditModal: any;
  setIsOpenCategoryEditModal: any;
  isOpenProductEditModal: any;
  setIsOpenProductEditModal: any;
  isOpenMenuEditModal: any;
  setIsOpenMenuEditModal: any;
  isOpenProductModal: any;
  setIsOpenProductModal: any;
  isOpenMenuAddModal: any;
  setIsOpenMenuAddModal: any;
  isOpenCategoryModal: any;
  setIsOpenCategoryModal: any;
  isLoadingButton: any;
  setIsLoadingButton: any;
  selectedDepartmentObj: any;
  setSelectedDepartmentObj: any;
  menuName: any;
  setMenuName: any;
  categoryName: any;
  setCategoryName: any;
  isLoadProduct: any;
  setIsLoadProduct: any;
  isOpenDirectoryOrProductSelectionModal: any;
  setIsOpenDirectoryOrProductSelectionModal: any;
  setIsOpenProductWrapperEditModal: any;
  isOpenProductWrapperEditModal: any;
  setIsOpenDirectoryModal: any;
  isOpenDirectoryModal: any;
  setMenuNodeList: any;
}

/* Loading the page until data is retrieved from the API call. */
const Loading: React.FunctionComponent<LoadingProps> = ({
  isLoading,
  menuNodeList,
  getCategoryInfo,
  menuNode,
  categoryNode,
  getProductWrapperInfo,
  getMenuInfo,
  productVersion,
  selectedGroupObj,
  stockGroupSelectedNode,
  setSelectedGroupObj,
  stockDepartmentSelectedNode,
  getAllStockDepartments,
  getProductGroupInfo,
  isOpenCategoryEditModal,
  setIsOpenCategoryEditModal,
  isOpenProductEditModal,
  setIsOpenProductEditModal,
  isOpenMenuEditModal,
  setIsOpenMenuEditModal,
  isOpenProductModal,
  setIsOpenProductModal,
  isOpenMenuAddModal,
  setIsOpenMenuAddModal,
  isOpenCategoryModal,
  setIsOpenCategoryModal,
  isLoadingButton,
  setIsLoadingButton,
  selectedDepartmentObj,
  setSelectedDepartmentObj,
  menuName,
  setMenuName,
  categoryName,
  setCategoryName,
  isLoadProduct,
  setIsLoadProduct,
  isOpenDirectoryOrProductSelectionModal,
  setIsOpenDirectoryOrProductSelectionModal,
  setIsOpenProductWrapperEditModal,
  isOpenProductWrapperEditModal,
  setIsOpenDirectoryModal,
  isOpenDirectoryModal,
  setMenuNodeList,
}) => {
  return (
    <MenuInfoPageNew
      menuNodeList={menuNodeList}
      getCategoryInfo={getCategoryInfo}
      menuNode={menuNode}
      categoryNode={categoryNode}
      getProductWrapperInfo={getProductWrapperInfo}
      getMenuInfo={getMenuInfo}
      productVersion={productVersion}
      selectedGroupObj={selectedGroupObj}
      stockGroupSelectedNode={stockGroupSelectedNode}
      setSelectedGroupObj={setSelectedGroupObj}
      stockDepartmentSelectedNode={stockDepartmentSelectedNode}
      getAllStockDepartments={getAllStockDepartments}
      getProductGroupInfo={getProductGroupInfo}
      isOpenCategoryEditModal={isOpenCategoryEditModal}
      setIsOpenCategoryEditModal={setIsOpenCategoryEditModal}
      isOpenProductEditModal={isOpenProductEditModal}
      setIsOpenProductEditModal={setIsOpenProductEditModal}
      isOpenMenuEditModal={isOpenMenuEditModal}
      setIsOpenMenuEditModal={setIsOpenMenuEditModal}
      isOpenProductModal={isOpenProductModal}
      setIsOpenProductModal={setIsOpenProductModal}
      isOpenMenuAddModal={isOpenMenuAddModal}
      setIsOpenMenuAddModal={setIsOpenMenuAddModal}
      isOpenCategoryModal={isOpenCategoryModal}
      setIsOpenCategoryModal={setIsOpenCategoryModal}
      isLoadingButton={isLoadingButton}
      setIsLoadingButton={setIsLoadingButton}
      selectedDepartmentObj={selectedDepartmentObj}
      setSelectedDepartmentObj={setSelectedDepartmentObj}
      menuName={menuName}
      setMenuName={setMenuName}
      categoryName={categoryName}
      setCategoryName={setCategoryName}
      isLoadProduct={isLoadProduct}
      setIsLoadProduct={setIsLoadProduct}
      isOpenDirectoryOrProductSelectionModal={
        isOpenDirectoryOrProductSelectionModal
      }
      setIsOpenDirectoryOrProductSelectionModal={
        setIsOpenDirectoryOrProductSelectionModal
      }
      setIsOpenProductWrapperEditModal={setIsOpenProductWrapperEditModal}
      isOpenProductWrapperEditModal={isOpenProductWrapperEditModal}
      setIsOpenDirectoryModal={setIsOpenDirectoryModal}
      isOpenDirectoryModal={isOpenDirectoryModal}
      setMenuNodeList={setMenuNodeList}
    />
  );
};

export default WithLoading(Loading);
