import { Box, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";

import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import Loading from "./Loading";
import {
  fetchGlobalDepartmentInfo,
  fetchGlobalGroupInfo,
  fetchGlobalProductsInfo,
  fetchProductConfigInfo,
  fetchProductWrapperInfo,
} from "../../../../services/menuApp/printerMenuService";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";
import {
  fetchCategoriesInfo,
  fetchMenusInfo,
} from "../../../../services/menuApp/menuService";

export interface MenusNewProps {}

/**
 * MenusNewTest Component
 *
 * This component is responsible for managing the creation and modification of menus
 * in a menu printing application. It fetches menu, category, and product information
 * from server APIs and allows users to add, edit, and delete menus, categories, and products.
 */
const MenusNewTest: React.FunctionComponent<MenusNewProps> = () => {
  const [menuNodeList, setMenuNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [menuNode, setMenuNode] = useState<any>([]);
  const [categoryNode, setCategoryNode] = useState<any>([]);
  const [productVersion, setProductVersion] = useState<any>();
  const [selectedGroupObj, setSelectedGroupObj] = useState<any>({});
  const [stockGroupSelectedNode, setStockGroupSelectedNode] = useState<any>([]);
  const [stockDepartmentSelectedNode, setStockDepartmentSelectedNode] =
    useState<any>([]);
  const [isOpenCategoryEditModal, setIsOpenCategoryEditModal] = useState(false);
  const [isOpenProductEditModal, setIsOpenProductEditModal] = useState(false);
  const [isOpenMenuEditModal, setIsOpenMenuEditModal] = useState(false);
  const [isOpenProductModal, setIsOpenProductModal] = useState(false);
  const [isOpenMenuAddModal, setIsOpenMenuAddModal] = useState(false);
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [selectedDepartmentObj, setSelectedDepartmentObj] = useState<any>([]);
  const [menuName, setMenuName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [isLoadProduct, setIsLoadProduct] = useState<any>([]);
  const [
    isOpenDirectoryOrProductSelectionModal,
    setIsOpenDirectoryOrProductSelectionModal,
  ] = useState(false);
  const [isOpenProductWrapperEditModal, setIsOpenProductWrapperEditModal] =
    useState(false);
  const [isOpenDirectoryModal, setIsOpenDirectoryModal] = useState(false);

  const match: any = useRouteMatch();

  /**
   * getMenuInfo Function
   *
   * This function is responsible for fetching menu information from the server API based on the location ID.
   * It then invokes the getCategoryInfo function to fetch category information associated with the menus.
   * Finally, it updates the component state with the fetched menu data.
   */
  const getMenuInfo = async (productsVersion: any): Promise<void> => {
    try {
      // Fetch menu information from the server API based on the location ID
      const res = await fetchMenusInfo(match.params.locationId);

      // Fetch category information associated with the menus
      getCategoryInfo(res.data.data, productsVersion);

      // Update the component state with the fetched menu data
      setMenuNode(res.data.data);
    } catch (err) {
      // Handle errors by setting an error message in the component state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * getCategoryInfo Function
   *
   * This function is responsible for fetching category information from the server API based on the location ID.
   * It then invokes the getProductWrapperInfo function to fetch product wrapper information associated with the categories.
   * Finally, it updates the component state with the fetched category data.
   */
  const getCategoryInfo = async (
    groupNode: any,
    productsVersion: any,
    productId?: any,
  ): Promise<void> => {
    try {
      // Fetch category information from the server API based on the location ID
      const res = await fetchCategoriesInfo(match.params.locationId);

      // Fetch product wrapper information associated with the categories
      const res1 = getProductWrapperInfo(
        groupNode,
        res.data.data,
        productsVersion,
        productId,
      );

      // Update the component state with the fetched category data
      setCategoryNode(res.data.data);

      return res1;
    } catch (err) {
      // Handle errors by setting an error message in the component state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * getProductGroupInfo Function
   *
   * This function is responsible for fetching product group information from the server API based on the location ID.
   * It optionally takes a new group ID parameter to fetch details of a specific group.
   * If successful, it updates the component state with the fetched group data.
   */
  const getProductGroupInfo = async (newGroupId?: any): Promise<void> => {
    try {
      // Fetch product group information from the server API based on the location ID
      const res = await fetchGlobalGroupInfo(match.params.locationId);

      if (res.data.data) {
        // If data is returned from the API
        if (newGroupId) {
          // If a new group ID is provided, filter the data to get details of the specific group
          const dept = res.data.data.filter(
            (data: any) => data.id === newGroupId,
          );
          setSelectedGroupObj(dept[0]); // Set the selected group object
        } else {
          // If no new group ID is provided, select the first group by default
          setSelectedGroupObj(res.data.data[0]); // Set the selected group object
        }

        // Update the component state with the fetched product group data
        setStockGroupSelectedNode(res.data.data);
      } else {
        // If no data is returned from the API, set loading state to false
        setIsLoading(false);
      }
    } catch (err) {
      // Handle errors by setting an error message in the component state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * getProductWrapperInfo Function
   *
   * This function is responsible for fetching product wrapper information from the server API based on the location ID.
   * It then invokes the getProductInfo function to fetch detailed product information associated with the product wrappers.
   */
  const getProductWrapperInfo = async (
    groupNode: any,
    departmentNode: any,
    productsVersion: any,
    productId?: any,
  ) => {
    try {
      // Fetch product wrapper information from the server API based on the location ID
      const res = await fetchProductWrapperInfo(match.params.locationId);

      // Fetch detailed product information associated with the product wrappers
      const menu = await handleMenuRestructure(
        // menuss,
        // categories,
        // productss,
        // 2,
        // productWrappers,
        groupNode,
        departmentNode,
        productsVersion,
        res.data.data,
      );

      // Update the component state with the restructured menu data
      setMenuNodeList(menu);
      setIsLoading(false);
    } catch (err) {
      // Handle errors by setting an error message in the component state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * handleMenuRestructure Function
   *
   * This function is responsible for restructuring menu data based on the fetched product information.
   * It takes various nodes containing group, department, product, and product wrapper information,
   * along with the version of products being fetched.
   * It filters and sorts the nodes and organizes them into a structured menu hierarchy.
   */
  const handleMenuRestructure = (
    groupNode: any,
    departmentNode: any,
    productsVersion: any,
    productWrapperNode: any,
  ) => {
    // Check if all necessary nodes contain data
    if (!_.isEmpty(groupNode) && !_.isEmpty(departmentNode)) {
      // Sort and filter department, product, group, and product wrapper nodes
      const filterDepartment = departmentNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const filterGroup = groupNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );

      const productWrappers = productWrapperNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      // Map through each group and its associated categories
      // Map through each group and its associated categories
      const buildWrapper = (wrapperId: any) => {
        const wrapperObj: any = {};
        productWrappers.forEach((wrapper: any) => {
          if (wrapper.directoryId === wrapperId) {
            const innerWrapper = buildWrapper(wrapper.id);
            wrapperObj[wrapper.id] = {
              ...wrapper,
              wrapper: innerWrapper,
            };
          }
        });
        return wrapperObj;
      };

      const output = filterGroup.reduce((acc: any, menu: any) => {
        const menuObj: any = {
          ...menu,
          category: {},
        };

        filterDepartment.forEach((category: any) => {
          if (category.menuId === menu.id) {
            const categoryObj: any = {
              ...category,
              wrapper: {},
            };

            productWrappers.forEach((wrapper: any) => {
              if (
                wrapper.categoryId === category.id &&
                wrapper.directoryId === -99
              ) {
                categoryObj.wrapper[wrapper.id] = {
                  ...wrapper,
                  wrapper: buildWrapper(wrapper.id),
                };
              }
            });

            menuObj.category[category.id] = categoryObj;
          }
        });

        acc[menu.id] = menuObj;
        return acc;
      }, {});

      // Add "Add New Menu" option if the version of products is not 1
      if (!_.isEmpty(output) && productsVersion !== 1) {
        output["menu"] = {
          id: "menu",
          name: "Add New Menu",
        };
      }

      return output; // Return the restructured menu data
    }
  };

  /**
   * getProductConfig Function
   *
   * This function is responsible for fetching product configuration information from the server API based on the location ID.
   * It then sets the product version in the component state and invokes the getMenuInfo function to fetch menu information
   * based on the fetched product version.
   */
  const getProductConfig = async (): Promise<void> => {
    try {
      // Fetch product configuration information from the server API based on the location ID
      const res = await fetchProductConfigInfo(match.params.locationId);

      // Set the product version in the component state
      setProductVersion(res.data.data.productsVersion);
      // Fetch menu information based on the fetched product version
      getMenuInfo(res.data.data.productsVersion);
    } catch (err) {
      // Handle errors by setting an error message in the component state and setting loading state to false
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * getAllStockDepartments Function
   *
   * This function is responsible for fetching all stock departments information from the server API based on the location ID.
   * It sets the fetched stock department nodes in the component state.
   *
   * @returns {Promise<void>} - A promise that resolves once the stock department information is fetched and processed.
   */
  const getAllStockDepartments = async (): Promise<void> => {
    try {
      // Fetch all stock departments information from the server API based on the location ID
      const res = await fetchGlobalDepartmentInfo(match.params.locationId);

      // Check if data is returned from the API
      if (res?.data?.data) {
        // Set the fetched stock department nodes in the component state
        setStockDepartmentSelectedNode(res.data.data);
      } else {
        // If no data is returned, set an empty array for stock department nodes in the component state
        setStockDepartmentSelectedNode([]);
      }
    } catch (err) {
      // Handle errors by setting an error message in the component state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * useEffect Hook
   *
   * This useEffect hook is invoked when the component mounts.
   * It sets the document title, fetches product configuration information, product group information,
   * and all stock departments information from the server API based on the location ID.
   * It also sets the loading state to true initially.
   */
  useEffect(() => {
    // Set the document title
    document.title = "Menu - Menu Information";

    // Fetch product configuration information
    getProductConfig();

    // Fetch product group information
    getProductGroupInfo();

    // Fetch all stock departments information
    getAllStockDepartments();

    // Set the loading state to true
    setIsLoading(true);
  }, []);

  const menuId = 18
  const categoryId = 11
  const wrapperId = 65
  const id = 659

  const input1 = {
    18: {
      id: 18,
      name: "test menu 20",
      category: {
        11: {
          id: 11,
          menuId: 18,
          name: "test category 1",
          wrapper: {
            65: {
              categoryId: 11,
              directory: true,
              directoryId: -99,
              directoryLevel: 1,
              id: 65,
              name: "test wrapper 1",
              wrapper: {
                658: {
                  categoryId: 11,
                  directory: false,
                  directoryId: -99,
                  directoryLevel: 1,
                  id: 658,
                  name: "test product 11",
                },
                659: {
                  categoryId: 11,
                  directory: true,
                  directoryId: 65,
                  directoryLevel: 2,
                  id: 659,
                  name: "test wrapper 2",
                  wrapper: {
                    660: {
                      categoryId: 11,
                      directory: true,
                      directoryId: 659,
                      directoryLevel: 3,
                      id: 660,
                      name: "test wrapper 3",
                      wrapper: {
                        661: {
                          categoryId: 11,
                          directory: true,
                          directoryId: 660,
                          directoryLevel: 4,
                          id: 661,
                          name: "test wrapper 4",
                          wrapper: {
                            662: {
                              categoryId: 11,
                              directory: true,
                              directoryId: 661,
                              directoryLevel: 5,
                              id: 662,
                              name: "test wrapper 5",
                              wrapper: {
                                663: {
                                  categoryId: 11,
                                  directory: true,
                                  directoryId: 662,
                                  directoryLevel: 6,
                                  id: 663,
                                  name: "test wrapper 6",
                                  wrapper: {
                                    664: {
                                      categoryId: 11,
                                      directory: true,
                                      directoryId: 663,
                                      directoryLevel: 7,
                                      id: 664,
                                      name: "test wrapper 7",
                                      wrapper: {
                                        665: {
                                          categoryId: 11,
                                          directory: true,
                                          directoryId: 664,
                                          directoryLevel: 8,
                                          id: 665,
                                          name: "test wrapper 8",
                                          wrapper: {
                                            664: {
                                              categoryId: 11,
                                              directory: true,
                                              directoryId: 665,
                                              directoryLevel: 9,
                                              id: 666,
                                              name: "test wrapper 9",
                                              wrapper: {
                                                665: {
                                                  categoryId: 11,
                                                  directory: true,
                                                  directoryId: 666,
                                                  directoryLevel: 10,
                                                  id: 667,
                                                  name: "test wrapper 10",
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };

//   const updateProductName = (node: any) => {
//     // Check if the current node is a product and has the specified ID
//     if (node.id === id) {
//         node.name = "aaaaaaaaaaaaaaaa";
//         return true; // Indicates the product was found and updated
//     }

//     // Recursively traverse nested wrappers
//     if (node.wrapper) {
//         for (const key in node.wrapper) {
//             if (Object.hasOwnProperty.call(node.wrapper, key)) {
//                 const child = node.wrapper[key];
//                 if (updateProductName(child)) {
//                     return true; // Exit early if the product was found and updated
//                 }
//             }
//         }
//     }

//     return false; // Indicates the product was not found in this branch
// };

// // Find and update the product name
// if (input1[menuId] && input1[menuId].category[categoryId]) {
//     updateProductName(input1[menuId].category[categoryId].wrapper[wrapperId]);
// }


  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile isNeedFixed={true} />
      </Hidden>
      <Box>
        <Loading
          isLoading={isLoading}
          menuNodeList={menuNodeList}
          getCategoryInfo={getCategoryInfo}
          menuNode={menuNode}
          categoryNode={categoryNode}
          getProductWrapperInfo={getProductWrapperInfo}
          getMenuInfo={getMenuInfo}
          productVersion={productVersion}
          selectedGroupObj={selectedGroupObj}
          stockGroupSelectedNode={stockGroupSelectedNode}
          setSelectedGroupObj={setSelectedGroupObj}
          getProductGroupInfo={getProductGroupInfo}
          stockDepartmentSelectedNode={stockDepartmentSelectedNode}
          getAllStockDepartments={getAllStockDepartments}
          isOpenCategoryEditModal={isOpenCategoryEditModal}
          setIsOpenCategoryEditModal={setIsOpenCategoryEditModal}
          isOpenProductEditModal={isOpenProductEditModal}
          setIsOpenProductEditModal={setIsOpenProductEditModal}
          isOpenMenuEditModal={isOpenMenuEditModal}
          setIsOpenMenuEditModal={setIsOpenMenuEditModal}
          isOpenProductModal={isOpenProductModal}
          setIsOpenProductModal={setIsOpenProductModal}
          isOpenMenuAddModal={isOpenMenuAddModal}
          setIsOpenMenuAddModal={setIsOpenMenuAddModal}
          isOpenCategoryModal={isOpenCategoryModal}
          setIsOpenCategoryModal={setIsOpenCategoryModal}
          isLoadingButton={isLoadingButton}
          setIsLoadingButton={setIsLoadingButton}
          selectedDepartmentObj={selectedDepartmentObj}
          setSelectedDepartmentObj={setSelectedDepartmentObj}
          menuName={menuName}
          setMenuName={setMenuName}
          categoryName={categoryName}
          setCategoryName={setCategoryName}
          isLoadProduct={isLoadProduct}
          setIsLoadProduct={setIsLoadProduct}
          isOpenDirectoryOrProductSelectionModal={
            isOpenDirectoryOrProductSelectionModal
          }
          setIsOpenDirectoryOrProductSelectionModal={
            setIsOpenDirectoryOrProductSelectionModal
          }
          setIsOpenProductWrapperEditModal={setIsOpenProductWrapperEditModal}
          isOpenProductWrapperEditModal={isOpenProductWrapperEditModal}
          setIsOpenDirectoryModal={setIsOpenDirectoryModal}
          isOpenDirectoryModal={isOpenDirectoryModal}
          setMenuNodeList={setMenuNodeList}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(MenusNewTest, Authorities.MENU_READ);
