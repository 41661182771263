import * as React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../../utils/enum";
import TextfieldDefault from "../../../../../components/textField/TextfieldDefault";
import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import { CustomTheme } from "../../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) => ({
  autoComplete: {
    marginTop: "4px",
    "& .MuiAutocomplete-input": {
      color: theme.palette.custom.orange.contrastText,
    },
    "& .MuiInputBase-root": {
      color: "inherit",
      backgroundColor: theme.palette.background.entity_background,
      borderRadius: 10,
    },
    [`& fieldset`]: {
      borderRadius: 10,
      border: `1px solid ${theme.palette.background.entity_border}`,
      color: theme.palette.custom.orange.contrastText,
    },
  },
  titleColor: {
    color: theme.palette.custom.orange.contrastText,
  },
}));

export interface StockDepartmentAddModalProps {
  isOpenDepartmentAddModal: boolean;
  setIsOpenDepartmentAddModal: any;
  setOpen: any;
  open: any;
  stockGroupSelectedNode: any;
  selectedGroupObj: any;
  isLoadingToast: any;
  handleGroupChange: any;
  handleOpenGroupAddModal: any;
  departmentName: any;
  handleChangeDepartmentName: any;
  handleCreateNewDepartment: any;
}

/**
 * StockDepartmentAddModal Component
 *
 * This component represents a modal for adding a new department to a stock group.
 * Users can select an existing group or create a new one, then enter the department name.
 */
const StockDepartmentAddModal: React.FunctionComponent<
  StockDepartmentAddModalProps
> = ({
  isOpenDepartmentAddModal,
  setIsOpenDepartmentAddModal,
  setOpen,
  open,
  stockGroupSelectedNode,
  selectedGroupObj,
  isLoadingToast,
  handleGroupChange,
  handleOpenGroupAddModal,
  departmentName,
  handleChangeDepartmentName,
  handleCreateNewDepartment,
}) => {
  const classes = useStyles();

  return (
    <DialogCommonDefault
      open={isOpenDepartmentAddModal}
      setOpen={setIsOpenDepartmentAddModal}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
    >
      <DialogTitle>Add New Department</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Autocomplete
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              size={"small"}
              id="locationSelectGlobal"
              color="inherit"
              options={stockGroupSelectedNode}
              value={selectedGroupObj}
              getOptionLabel={(option: any) => option.name || ""}
              style={{ width: 300 }}
              disableClearable
              disabled={isLoadingToast}
              onChange={handleGroupChange}
              classes={{ root: classes.autoComplete }}
              popupIcon={<ArrowDropDownIcon color={"inherit"} />}
              renderOption={(props: any) => {
                return (
                  <Grid
                    container
                    {...props}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      zIndex: 0,
                      height: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.titleColor}
                      >
                        {props.name}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
              renderInput={(params: any) => (
                <TextField
                  color="inherit"
                  {...params}
                  label=""
                  placeholder="Select Group"
                  variant="outlined"
                />
              )}
            />
            <Button
              style={{ textTransform: "none" }}
              color="secondary"
              autoFocus
              disabled={isLoadingToast}
              // disabled={checkedProductIdList.length === 0 || result}
              onClick={handleOpenGroupAddModal}
            >
              {"Add new Group"}
            </Button>
          </Grid>
        </Grid>
        <TextfieldDefault
          id="departmentName"
          name="departmentName"
          type="text"
          disabled={isLoadingToast}
          label="Department Name"
          value={departmentName}
          onChange={handleChangeDepartmentName}
        />
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px", marginRight: "16px" }}>
        <ButtonCommon
          disabled={isLoadingToast}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "12px",
          }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
          onClick={() => {
            setIsOpenDepartmentAddModal(false);
          }}
        >
          Cancel
        </ButtonCommon>

        <ButtonCommon
          disabled={!departmentName || isLoadingToast}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
          }}
          color={buttonColors.CREATE_BUTTON_COLOR}
          onClick={handleCreateNewDepartment}
        >
          Create
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default StockDepartmentAddModal;
