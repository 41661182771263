import _ from "lodash";
import moment from "moment";

interface FilterData {
  locationFilterList: any[];
  startDate: string | null;
  endDate: string | null;
  channelFilterList: any[];
  paymentTypeFilterList: any[];
  deliveryTypeFilterList: any[];
}

const handleChangeFilterInOperator = (data: any[]): string => {
  return data.join(",");
};

const handleFilterDataEatprestoOrder = (
  locationFilterList: any[],
  startDate: string | null,
  endDate: string | null,
  channelFilterList: any[],
  paymentTypeFilterList: any[],
  deliveryTypeFilterList: any[],
) => {
  let location = "";
  let orderTimeStart = "";
  let orderTimeEnd = "";
  let channel = "";
  let deliveryType = "";
  let paymentType = "";

  if (!_.isEmpty(paymentTypeFilterList)) {
    paymentType = handleChangeFilterInOperator(paymentTypeFilterList);
  }

  if (!_.isEmpty(locationFilterList)) {
    location = locationFilterList.join("*");
  }

  if (!_.isEmpty(channelFilterList)) {
    channel = handleChangeFilterInOperator(channelFilterList);
  }

  if (!_.isEmpty(endDate) && !_.isEmpty(startDate)) {
    orderTimeStart = moment(startDate, "YYYY-MM-DD").format(
      "YYYY-MM-DDT00:00:00"
    );
    orderTimeEnd = moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DDT23:59:59");
  }

  if (!_.isEmpty(deliveryTypeFilterList)) {
    deliveryType = deliveryTypeFilterList.join(",");
  }

  const filterQuery: any = {
    orderTimeStart,
    orderTimeEnd,
  };

  if (paymentType.trim() !== "") {
    filterQuery.paymentTypes = paymentType;
  }

  if (deliveryType.trim() !== "") {
    filterQuery.deliveryTypes = deliveryType;
  }

  if (channel.trim() !== "") {
    filterQuery.orderChannels = channel;
  }

  const filterCount = Object.keys(filterQuery).length;

  return { filterCount, filterQuery };
};

export default handleFilterDataEatprestoOrder;
