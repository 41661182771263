import React from "react";
import { Typography } from "@material-ui/core";

export interface StepZeroProps {}

/**
 * StepZero Component
 *
 * This component represents the initial step (step zero) of a wizard modal.
 * It provides an introductory message to the user.
 */
const StepZero: React.FunctionComponent<StepZeroProps> = ({}) => {
  return (
    <Typography variant="h6" style={{ marginBottom: "20px" }}>
      Use this wizard to efficiently add multiple stock items with similar
      parameters
    </Typography>
  );
};

export default StepZero;
