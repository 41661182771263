import { axiosClient } from "../client";

const queryString = require("query-string");

const API_FILTER_LOCATION_GET_ALL = `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}?page=1&size=20000`;

export const fetchAllFilterLocations = (searchLocation) => {
  return axiosClient({
    url: queryString.stringifyUrl(
      {
        url: API_FILTER_LOCATION_GET_ALL,
        query: { sort: ["updated", "DESC"], search: searchLocation },
      },
      { arrayFormat: "comma", skipNull: true, skipEmptyString: true },
    ),
    method: "GET",
  });
};
