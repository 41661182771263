import React, { useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import { Autocomplete } from "@material-ui/lab";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import ValidationMessage from "../validation/ValidationMessage";
import TextfieldCommon from "../textField/TextfieldCommon";
import { CustomTheme } from "../../types/customTheme";
import CardCommon from "../card/CardCommon";
import { CapitalizeFirstLetter } from "../../utils/ReplaceIcon";
import SwitchCommon from "../switches/SwitchCommon";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: `1px solid ${theme.palette.background.entity_border}`,
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: "none",
      marginRight: "8px",
      maxWidth: "200px",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    supplierTitleStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    supplierSearchAddStyle: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      marginTop: "0px",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "8px",
      },
    },
    labelStyle: {
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
  }),
);

export interface AddStockItemsProps {
  register: any;
  errors: any;
  selectedNodeData: any;
  reset: any;
  stockDepartmentSelectedNode: any;
  supplierDetailsNode: any;
  setSelectedSupplierList: any;
  selectedSupplierList: any;
  selectedDepartmentObj: any;
  setSelectedDepartmentObj: any;
  handleOpenDepartmentAddModal: any;
  setSelectedDepartmentIdInitial: any;
  handleOpenSupplierAddModal: any;
  handleChangeSearchSupplier: any;
  handleClearSearchSupplier: any;
  searchName: any;
  isSearch: any;
  deleteSupplierDetails: any;
  selectedSupplierListInitial: any;
  setIsAutoConversion: any;
  isAutoConversion: any;
  setIsShowSeparate: any;
  isShowSeparate: any;
  setIsAutoConversionInitial: any;
  setIsShowSeparateInitial: any;
}

/**
 * This component represents a form for adding stock items, allowing users
 * to input various details, select a department, and choose suppliers.
 */
const AddStockItems: React.FunctionComponent<AddStockItemsProps> = ({
  register,
  errors,
  selectedNodeData,
  reset,
  stockDepartmentSelectedNode,
  supplierDetailsNode,
  setSelectedSupplierList,
  selectedSupplierList,
  selectedDepartmentObj,
  setSelectedDepartmentObj,
  handleOpenDepartmentAddModal,
  setSelectedDepartmentIdInitial,
  handleOpenSupplierAddModal,
  handleChangeSearchSupplier,
  handleClearSearchSupplier,
  searchName,
  isSearch,
  deleteSupplierDetails,
  selectedSupplierListInitial,
  setIsAutoConversion,
  isAutoConversion,
  setIsShowSeparate,
  isShowSeparate,
  setIsAutoConversionInitial,
  setIsShowSeparateInitial,
}) => {
  const [open, setOpen] = useState(false);
  /**
   * This useEffect handles the initialization of form fields when the selectedNodeData changes.
   * It populates the form fields with values from selectedNodeData or resets them to defaults.
   */
  useEffect(() => {
    if (!_.isEmpty(selectedNodeData)) {
      const {
        name,
        barcode,
        sku,
        displaySuffix,
        inputSuffix,
        inputMultiplier,
        criticalThreshold,
        warningThreshold,
        stockDepartmentId,
        autoUnitConvert,
        showRemainderSep,
      } = selectedNodeData;
      // Set the initial and current selected department based on stockDepartmentId
      setSelectedDepartmentIdInitial(stockDepartmentId);
      setSelectedDepartmentObj(stockDepartmentId);
      setIsShowSeparate(showRemainderSep);
      setIsAutoConversion(autoUnitConvert);
      setIsAutoConversionInitial(autoUnitConvert);
      setIsShowSeparateInitial(showRemainderSep);
      // Reset the form fields with values from selectedNodeData
      reset({
        name,
        barcode,
        sku,
        displaySuffix,
        inputSuffix,
        inputMultiplier,
        criticalThreshold,
        warningThreshold,
      });
    } else {
      // Reset form fields to default values if selectedNodeData is empty
      reset({
        name: "",
        barcode: "",
        sku: "",
        displaySuffix: "",
        inputSuffix: "",
        inputMultiplier: "",
        criticalThreshold: "",
        warningThreshold: "",
      });
    }
  }, [selectedNodeData]);

  /**
   * This function handles the change of the selected department.
   * It updates the selected department object based on user selection.
   * @param {Object} departmentData - The selected department data.
   */
  const handleDepartmentChange = (e: any, departmentData: any) => {
    setSelectedDepartmentObj(departmentData);
  };

  /**
   * Determines the color for a supplier based on its ID.
   * If the supplier is selected (in the selectedSupplierList), it returns "green".
   * @param {string} id - The ID of the supplier.
   * @returns {string} - The color string, "green" if selected, undefined otherwise.
   */
  const determineSupplierColor = (id: string) => {
    if (selectedSupplierList.includes(id)) {
      return "green"; // Selected supplier is shown in green.
    }
    // Return undefined for non-selected suppliers.
  };

  /**
   * Toggles the selection of a supplier by adding or removing its ID from the selectedSupplierList.
   * @param {string} id - The ID of the supplier to toggle.
   */
  const handleSelectedSupplier = (id: string) => {
    // Clone the selectedSupplierList to avoid mutating the state directly.
    const updatedSelectedSupplierList = _.cloneDeep(selectedSupplierList);

    if (updatedSelectedSupplierList.includes(id)) {
      // If the supplier ID is already in the list, remove it to deselect the supplier.
      const filterSelectedSupplier = updatedSelectedSupplierList.filter(
        (data: any) => data !== id,
      );
      if (selectedSupplierListInitial.includes(id)) {
        deleteSupplierDetails(id);
      }
      setSelectedSupplierList(filterSelectedSupplier);
    } else {
      // If the supplier ID is not in the list, add it to select the supplier.
      updatedSelectedSupplierList.push(id);
      setSelectedSupplierList(updatedSelectedSupplierList);
    }
  };

  const handleChangeAutoConversion = () => {
    // Toggle the 'isAutoConversion' state by negating its current value
    if(isAutoConversion) {
      setIsShowSeparate(false)
    }

    setIsAutoConversion(!isAutoConversion);
  };

  const handleChangeShowSeparate = () => {
    // Toggle the 'isAutoConversion' state by negating its current value
    setIsShowSeparate(!isShowSeparate);
  };

  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextfieldCommon
          id="name"
          name="name"
          type="text"
          label={<Typography className={classes.labelStyle}>Name</Typography>}
          disabled={false}
          inputRef={register({
            required: "Name is required.",
          })}
        />
        {errors.name ? <ValidationMessage message={errors.name.message} /> : ""}
      </Grid>
      <Grid item xs={6}>
        <TextfieldCommon
          id="barcode"
          name="barcode"
          type="text"
          label={
            <Typography className={classes.labelStyle}>Barcode</Typography>
          }
          disabled={false}
          inputRef={register({
            required: false,
          })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextfieldCommon
          id="sku"
          name="sku"
          type="text"
          label={<Typography className={classes.labelStyle}>Sku</Typography>}
          disabled={false}
          inputRef={register({
            required: false,
          })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextfieldCommon
          id="displaySuffix"
          name="displaySuffix"
          type="text"
          label={
            <Typography className={classes.labelStyle}>Base Unit</Typography>
          }
          disabled={false}
          inputRef={register({
            required: false,
          })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextfieldCommon
          id="inputSuffix"
          name="inputSuffix"
          type="text"
          label={
            <Typography className={classes.labelStyle}>
              Purchase Unit
            </Typography>
          }
          disabled={false}
          inputRef={register({
            required: false,
          })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextfieldCommon
          id="inputMultiplier"
          name="inputMultiplier"
          type="number"
          label={
            <Typography className={classes.labelStyle}>
              Based on the Purchase Unit Conversion
            </Typography>
          }
          disabled={false}
          inputRef={register({
            required: "Based on the Purchase Unit Conversion is required.",
            min: {
              value: 1,
              message:
                "Based on the Purchase Unit Conversion must be greater than to 0.",
            },
          })}
        />
        {errors.inputMultiplier ? (
          <ValidationMessage message={errors.inputMultiplier.message} />
        ) : (
          ""
        )}
      </Grid>

      <Grid item xs={6}>
        <TextfieldCommon
          id="criticalThreshold"
          name="criticalThreshold"
          type="number"
          label={
            <Typography className={classes.labelStyle}>
              Critical Threshold
            </Typography>
          }
          disabled={false}
          inputRef={register({
            required: "Critical Threshold is required.",
            min: {
              value: 1,
              message: "Critical Threshold must be greater than to 0.",
            },
          })}
        />
        {errors.criticalThreshold ? (
          <ValidationMessage message={errors.criticalThreshold.message} />
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={6}>
        <TextfieldCommon
          id="warningThreshold"
          name="warningThreshold"
          type="number"
          label={
            <Typography className={classes.labelStyle}>
              Warning threshold
            </Typography>
          }
          disabled={false}
          inputRef={register({
            required: "Warning threshold is required.",
            min: {
              value: 1,
              message: "Warning threshold must be greater than to 0.",
            },
          })}
        />
        {errors.warningThreshold ? (
          <ValidationMessage message={errors.warningThreshold.message} />
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          style={{ marginLeft: "0px", marginRight: "0px" }}
          value="autoConversion"
          control={<SwitchCommon />}
          label={
            <Typography variant="body2" color="textSecondary">
              Auto Conversion
            </Typography>
          }
          name="autoConversion"
          checked={isAutoConversion}
          labelPlacement="start"
          onChange={handleChangeAutoConversion}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          style={{ marginLeft: "0px", marginRight: "0px" }}
          value="showSeparate"
          control={<SwitchCommon />}
          disabled={!isAutoConversion}
          label={
            <Typography variant="body2" color="textSecondary">
              Show Separate
            </Typography>
          }
          name="showSeparate"
          checked={isShowSeparate}
          labelPlacement="start"
          onChange={handleChangeShowSeparate}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: "12px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography>Select Department</Typography>
          <Button
            style={{ textTransform: "none" }}
            color="secondary"
            autoFocus
            // disabled={checkedProductIdList.length === 0 || result}
            onClick={handleOpenDepartmentAddModal}
          >
            {"Add new department"}
          </Button>
        </div>
        <Autocomplete
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          size={"small"}
          id="locationSelectGlobal"
          color="inherit"
          options={stockDepartmentSelectedNode}
          value={selectedDepartmentObj}
          getOptionLabel={(option: any) => option.name || ""}
          style={{ width: 300 }}
          disableClearable
          onChange={handleDepartmentChange}
          classes={{ root: classes.autoComplete }}
          popupIcon={<ArrowDropDownIcon color={"inherit"} />}
          renderOption={(props: any) => {
            return (
              <Grid
                container
                {...props}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  zIndex: 0,
                  height: "20px",
                  marginBottom: "4px",
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.titleColor}>
                    {props.name}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
          renderInput={(params: any) => (
            <TextField
              color="inherit"
              {...params}
              label=""
              placeholder="Select Department"
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            marginTop: "12px",
          }}
        >
          <Grid container style={{ marginBottom: "12px" }}>
            <Grid item xs={12} className={classes.supplierTitleStyle}>
              <Typography>Select Suppliers</Typography>
              <div className={classes.supplierSearchAddStyle}>
                <TextField
                  classes={{ root: classes.textField }}
                  id="outlined-inline-static"
                  placeholder="Search Supplier"
                  variant="outlined"
                  onChange={(e: any) =>
                    handleChangeSearchSupplier(e.target.value)
                  }
                  value={searchName}
                  style={{
                    height: "40px",
                    marginTop: "4px",
                    marginBottom: "4px",
                    width: "!00%",
                  }}
                  fullWidth
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClearSearchSupplier}
                        >
                          {isSearch ? <ClearIcon /> : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  style={{ textTransform: "none" }}
                  color="secondary"
                  autoFocus
                  // disabled={checkedProductIdList.length === 0 || result}
                  onClick={handleOpenSupplierAddModal}
                >
                  {"Add new supplier"}
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            {!_.isEmpty(supplierDetailsNode) ? (
              supplierDetailsNode.map((supplier: any) => (
                <Grid item xs={6} sm={4} style={{ padding: "4px" }}>
                  <CardCommon
                    color={determineSupplierColor(supplier.id)}
                    backgroundColor={"entity_highlight_background"}
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      fullWidth
                      onClick={() => handleSelectedSupplier(supplier.id)}
                      style={{
                        textTransform: "none",
                        display: "flex",
                        alignItems: "center",
                        padding: "4px",
                        minHeight: "80px",
                        backgroundColor: determineSupplierColor(supplier.id)
                          ? theme.palette.custom.green.main
                          : theme.palette.background
                              .entity_highlight_background,
                      }}
                    >
                      <Typography
                        style={
                          determineSupplierColor(supplier.id)
                            ? {
                                margin: "4px",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                              }
                            : {
                                margin: "4px",
                                display: "flex",
                                alignItems: "center",
                              }
                        }
                      >
                        {CapitalizeFirstLetter(supplier.name)}
                      </Typography>
                    </Button>
                  </CardCommon>{" "}
                </Grid>
              ))
            ) : (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "40px",
                    marginBottom: "40px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography variant="h5">No supplier available.</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default AddStockItems;
