import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import ValidationMessage from "../../../../../components/validation/ValidationMessage";
import { CustomTheme } from "../../../../../types/customTheme";
import SwitchCommon from "../../../../../components/switches/SwitchCommon";
import ColorSelection from "../../../../PrestoExpressEatprestoApp/pages/ThemeCustomizationPage/ColorSelection";
import { inOutTax } from "../../../../../utils/consts/list";
import {
  fetchProductGetByWrapperInfo,
  fetchSingleProductInfo,
  fetchSingleProductWrapper,
  updateProductWrapperInfo,
} from "../../../../../services/menuApp/menuService";
import { useRouteMatch } from "react-router-dom";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../../utils/consts";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    labelStyle: {
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
  }),
);

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  handleCloseProductEditModal: any;
  isLoadingButton: any;
  errors: any;
  register: any;
  handleOpenDepartmentAddModal: any;
  stockDepartmentSelectedNode: any;
  selectedDepartmentObj: any;
  setSelectedDepartmentObj: any;
  setDepartmentName: any;
  isDepartmentEmpty: any;
  setIsDepartmentEmpty: any;
  reset: any;
  isValidProduct: any;
  setIsValidProduct: any;
  inTax: any;
  setInTax: any;
  isByo: any;
  setIsByo: any;
  isCustomisationPriority: any;
  setIsCustomisationPriority: any;
  setIsMirrored: any;
  isSpicySelectionEnabled: any;
  setIsSpicySelectionEnabled: any;
  outTax: any;
  setOutTax: any;
  allYouCanEat: any;
  setAllYouCanEat: any;
  setColor: any;
  color: any;
  setIsValidKiosk: any;
  isValidKiosk: any;
  isDirty: any;
  selectedWrapper: any;
  setError: any;
  productId: any;
  menuNodeList: any;
  selectedMenuId: any;
  categoryId: any;
  wrapperId: any;
  setMenuNodeList: any;
  setIsOpenProductEditModal: any;
  setIsLoadingButton: any;
  handleSubmit: any;
  menuId: any;
  firstWrapperId: any;
  isRoot: any;
}

const ProductEditModal: React.FunctionComponent<StrategyAddEditModalProps> = ({
  isOpen,
  setIsOpen,
  handleCloseProductEditModal,
  isLoadingButton,
  errors,
  register,
  handleOpenDepartmentAddModal,
  stockDepartmentSelectedNode,
  selectedDepartmentObj,
  setSelectedDepartmentObj,
  setDepartmentName,
  isDepartmentEmpty,
  setIsDepartmentEmpty,
  reset,
  isValidProduct,
  setIsValidProduct,
  inTax,
  setInTax,
  isByo,
  setIsByo,
  isCustomisationPriority,
  setIsCustomisationPriority,
  setIsMirrored,
  isSpicySelectionEnabled,
  setIsSpicySelectionEnabled,
  outTax,
  setOutTax,
  allYouCanEat,
  setAllYouCanEat,
  setColor,
  color,
  setIsValidKiosk,
  isValidKiosk,
  isDirty,
  selectedWrapper,
  setError,
  productId,
  menuNodeList,
  selectedMenuId,
  categoryId,
  wrapperId,
  setMenuNodeList,
  setIsOpenProductEditModal,
  setIsLoadingButton,
  handleSubmit,
  menuId,
  firstWrapperId,
  isRoot,
}) => {
  const match: any = useRouteMatch();
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const handleDisable = () => {
    if (selectedProduct.length > 0) {
      const {
        valid,
        isByo: isByoInitial,
        isCustomisationPriority: isCustomisationPriorityInitial,
        isSpicySelectionEnabled: isSpicySelectionEnabledInitial,
        inTax: inTaxInitial,
        outTax: outTaxInitial,
        departmentId,
      } = selectedProduct[0];

      const {
        kiosk,
        colour,
        allYouCanEat: allYouCanEatInitial,
      } = selectedWrapper;

      if (
        !isDirty &&
        valid === isValidProduct &&
        kiosk?.toString() === (isValidKiosk ? "1" : "0") &&
        colour === color &&
        isByoInitial === isByo &&
        isCustomisationPriorityInitial === isCustomisationPriority &&
        isSpicySelectionEnabledInitial === isSpicySelectionEnabled &&
        allYouCanEatInitial === allYouCanEat &&
        inTaxInitial?.toString() === inTax?.id?.toString() &&
        outTaxInitial?.toString() === outTax?.id?.toString() &&
        selectedDepartmentObj.id === departmentId
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleGetSingleProduct = async () => {
    try {
      const res = await fetchProductGetByWrapperInfo(
        match.params.locationId,
        selectedWrapper.id,
      );
      return res?.data?.data;
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  useEffect(() => {
    if (
      selectedProduct.length > 0 &&
      Object.values(selectedWrapper).length > 0
    ) {
      const {
        name,
        barcode,
        valid,
        plu,
        departmentId,
        upcBarcode,
        version,
        cost,
        desc,
        inTax,
        isByo,
        isCustomisationPriority,
        img,
        isSpicySelectionEnabled,
        outTax,
        price,
        printName,
        size,
        slavePrintType,
      } = selectedProduct[0];

      const { allYouCanEat, colour, cover, itemType, kiosk, nameShort } =
        selectedWrapper;
      setIsValidKiosk(kiosk.toString() === "1" ? true : false);
      setColor(colour);
      setAllYouCanEat(allYouCanEat);
      setInTax({ id: inTax, name: inTax });
      setIsByo(isByo);
      setIsCustomisationPriority(isCustomisationPriority);
      setIsSpicySelectionEnabled(isSpicySelectionEnabled);
      setOutTax({ id: outTax, name: outTax });
      const selectedDepartment = stockDepartmentSelectedNode.filter(
        (department: any) => department.id === departmentId,
      );
      setSelectedDepartmentObj(selectedDepartment[0]);
      setIsValidProduct(valid);
      reset({
        name,
        barcode,
        valid,
        plu,
        upcBarcode,
        version,
        cost,
        desc,
        price,
        printName,
        size,
        slavePrintType,
        cover,
        itemType,
        nameShort,
        img,
      });
    }
  }, [selectedProduct, selectedWrapper]);

  const handleGetProduct = async () => {
    const selectedProducts: any = await handleGetSingleProduct();
    if (selectedProducts) {
      setSelectedProduct(selectedProducts);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      handleGetProduct();
      // Set default values from selectedCategory when it changes
    }
  }, [isOpen]);

  /**
   * This function handles the change of the selected department.
   * It updates the selected department object based on user selection.
   * @param {Object} departmentData - The selected department data.
   */
  const handleDepartmentChange = (e: any, departmentData: any) => {
    setIsDepartmentEmpty(false);
    setSelectedDepartmentObj(departmentData);
    setDepartmentName(departmentData.name);
  };

  const handleOutTaxChange = (e: any, outTaxData: any) => {
    setOutTax(outTaxData);
  };

  const handleInTaxChange = (e: any, inTaxData: any) => {
    setInTax(inTaxData);
  };

  const [open, setOpen] = useState(false);
  const [openInTaxDropDown, setOpenInTaxDropDown] = useState(false);
  const [openOutTaxDropDown, setOpenOutTaxDropDown] = useState(false);

  const handleSwitchByo = (e: any) => {
    const { checked } = e.target;
    setIsByo(checked);
  };

  const handleSwitchCustomisationPriority = (e: any) => {
    const { checked } = e.target;
    setIsCustomisationPriority(checked);
  };

  const handleSwitchSpicySelectionEnabled = (e: any) => {
    const { checked } = e.target;
    setIsSpicySelectionEnabled(checked);
  };

  const handleAllYouCanEat = (e: any) => {
    const { checked } = e.target;
    setAllYouCanEat(checked);
  };

  const handleKioskActivate = (e: any) => {
    const { checked } = e.target;
    setIsValidKiosk(checked);
  };

  const handleEditProductWrapperAndProduct = async (
    data: any,
    version?: any,
    productVersion?: any,
  ) => {
    const {
      cover,
      itemType,
      nameShort,
      name,
      plu,
      barcode,
      upcBarcode,
      cost,
      desc,
      price,
      printName,
      size,
      slavePrintType,
      img,
    } = data;

    const body = {
      allYouCanEat: allYouCanEat,
      colour: color,
      cover: cover,
      itemType: itemType,
      kiosk: isValidKiosk ? "1" : "0",
      nameShort: nameShort,
      wrapperVersion: productVersion || selectedWrapper.version,
      wrapperId: wrapperId,
      locationId: match.params.locationId,
      name: name,
      valid: isValidProduct,
      plu: plu,
      barcode: barcode,
      upcBarcode: upcBarcode,
      departmentId: selectedDepartmentObj.id,
      productId: selectedProduct[0].id,
      extId: selectedProduct[0].extId,
      productVersion: version || selectedProduct[0].version,
      cost: cost,
      desc: desc,
      inTax: inTax?.id >= 0 ? inTax?.id.toString() : "",
      isByo: isByo,
      isCustomisationPriority: isCustomisationPriority,
      isSpicySelectionEnabled: isSpicySelectionEnabled,
      outTax: outTax?.id >= 0 ? outTax?.id : "",
      price: price,
      printName,
      size,
      slavePrintType,
      img,
    };

    try {
      let summary = [];

      if (selectedProduct[0].name !== name) {
        summary.push(
          `Product name changed from ${selectedProduct[0].name} to ${name}`,
        );
      }
      if (selectedProduct[0].plu !== plu) {
        summary.push(`PLU changed from ${selectedProduct[0].plu} to ${plu}`);
      }
      if (selectedProduct[0].valid !== isValidProduct) {
        summary.push(
          `Valid changed from ${selectedProduct[0].valid} to ${isValidProduct}`,
        );
      }
      if (selectedProduct[0].barcode !== barcode) {
        summary.push(
          `Barcode changed from ${selectedProduct[0].barcode} to ${barcode}`,
        );
      }
      if (selectedProduct[0].upcBarcode !== upcBarcode) {
        summary.push(
          `UPC Barcode changed from ${selectedProduct[0].upcBarcode} to ${upcBarcode}`,
        );
      }

      if (selectedProduct[0].departmentId !== selectedDepartmentObj.id) {
        summary.push(
          `Department Id changed from ${selectedProduct[0].departmentId} to ${selectedDepartmentObj.id}`,
        );
      }

      if (selectedProduct[0].cost?.toString() !== cost?.toString()) {
        summary.push(`Cost changed from ${selectedProduct[0].cost} to ${cost}`);
      }

      if (selectedProduct[0].desc !== desc) {
        summary.push(
          `Description changed from ${selectedProduct[0].desc} to ${desc}`,
        );
      }

      if (selectedProduct[0].inTax !== inTax?.id) {
        summary.push(
          `In Tax changed from ${selectedProduct[0].inTax} to ${inTax?.id}`,
        );
      }

      if (selectedProduct[0].outTax !== outTax?.id) {
        summary.push(
          `Out Tax changed from ${selectedProduct[0].outTax} to ${outTax?.id}`,
        );
      }

      if (selectedProduct[0].isByo !== isByo) {
        summary.push(
          `BYO changed from ${selectedProduct[0].isByo} to ${isByo}`,
        );
      }

      if (
        selectedProduct[0].isCustomisationPriority !== isCustomisationPriority
      ) {
        summary.push(
          `Customisation Priority changed from ${selectedProduct[0].isCustomisationPriority} to ${isCustomisationPriority}`,
        );
      }

      if (
        selectedProduct[0].isSpicySelectionEnabled !== isSpicySelectionEnabled
      ) {
        summary.push(
          `Spicy Selection Enabled changed from ${selectedProduct[0].isSpicySelectionEnabled} to ${isSpicySelectionEnabled}`,
        );
      }

      if (selectedProduct[0].price?.toString() !== price?.toString()) {
        summary.push(
          `Price changed from ${selectedProduct[0].price} to ${price}`,
        );
      }

      if (selectedProduct[0].printName !== printName) {
        summary.push(
          `Print Name changed from ${selectedProduct[0].printName} to ${printName}`,
        );
      }

      if (selectedProduct[0].size !== size) {
        summary.push(
          `Size Name changed from ${selectedProduct[0].size} to ${size}`,
        );
      }

      if (selectedProduct[0].slavePrintType !== slavePrintType) {
        summary.push(
          `Slave Print Type Name changed from ${selectedProduct[0].slavePrintType} to ${slavePrintType}`,
        );
      }

      if (selectedWrapper.cover !== cover && !_.isEmpty(cover)) {
        summary.push(`Cover changed from ${selectedWrapper.cover} to ${cover}`);
      }

      if (selectedWrapper.itemType !== itemType) {
        summary.push(
          `Item Type changed from ${selectedWrapper.itemType} to ${itemType}`,
        );
      }

      if (selectedWrapper?.kiosk?.toString() !== (isValidKiosk ? "1" : "0")) {
        summary.push(
          `Kiosk changed from ${selectedWrapper.kiosk} to ${
            isValidKiosk ? "1" : "0"
          }`,
        );
      }

      if (selectedWrapper.nameShort !== nameShort) {
        summary.push(
          `Name Short changed from ${selectedWrapper.nameShort} to ${nameShort}`,
        );
      }

      if (selectedWrapper.colour !== color && !_.isEmpty(color)) {
        summary.push(
          `Color changed from ${selectedWrapper.colour} to ${color}`,
        );
      }

      if (selectedWrapper.allYouCanEat !== allYouCanEat) {
        summary.push(
          `All You Can Eat changed from ${selectedWrapper.allYouCanEat} to ${allYouCanEat}`,
        );
      }

      if (selectedProduct[0].img !== img) {
        summary.push(
          `All You Can Eat changed from ${selectedProduct[0].img} to ${img}`,
        );
      }

      const summaryString = summary.join("\\n");

      const res = await updateProductWrapperInfo(
        match.params.locationId,
        body,
        "Menus Changed",
        summaryString,
        "menu",
      );
      if (res.data.status === 409) {
        handleGetSingleProductWrapper(selectedProduct[0], data);
      } else {
        const cloneMenuNode = _.cloneDeep(menuNodeList);
        if (isRoot) {
          cloneMenuNode[menuId].category[categoryId].wrapper[
            res.data.data.itemWrapperId.id
          ] = res.data.data.itemWrapperId;
        } else {
          const updateProductName = (node: any) => {
            // Check if the current node is a product and has the specified ID
            if (node.id === wrapperId) {
              const productWrapper = res.data.data.itemWrapperId;
              node.name = productWrapper.name;
              node.allYouCanEat = productWrapper.allYouCanEat;
              node.colour = productWrapper.colour;
              node.cover = productWrapper.cover;
              node.desc = productWrapper.desc;
              node.img = productWrapper.img;
              node.itemType = productWrapper.itemType;
              node.kiosk = productWrapper.kiosk;
              node.nameShort = productWrapper.nameShort;
              node.valid = productWrapper.valid;
              node.version = productWrapper.version;
              node.viewOrder = productWrapper.viewOrder;
              node.weekFrom = productWrapper.weekFrom;
              node.weekTo = productWrapper.weekTo;

              return true; // Indicates the product was found and updated
            }

            // Recursively traverse nested wrappers
            if (node.wrapper) {
              for (const key in node.wrapper) {
                if (Object.hasOwnProperty.call(node.wrapper, key)) {
                  const child = node.wrapper[key];
                  if (updateProductName(child)) {
                    return true; // Exit early if the product was found and updated
                  }
                }
              }
            }

            return false; // Indicates the product was not found in this branch
          };

          // Find and update the product name
          if (
            cloneMenuNode[menuId] &&
            cloneMenuNode[menuId].category[categoryId]
          ) {
            updateProductName(
              cloneMenuNode[menuId].category[categoryId].wrapper[
                firstWrapperId
              ],
            );
          }
        }
        setMenuNodeList(cloneMenuNode);
        setSelectedProduct([]);
        setSelectedDepartmentObj({});
        setIsOpenProductEditModal(false);
        setIsLoadingButton(false);
        setIsOpen(false);

        // getProductWrapperInfo(menuNode, categoryNode, productVersion);
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  };

  const handleSubmitProductEditInfo = handleSubmit(async (data: any) => {
    setIsLoadingButton(true);

    handleEditProductWrapperAndProduct(data);
  });

  const handleGetSingleProduct409 = async (
    data: any,
    productId: any,
    version: any,
  ) => {
    try {
      const res = await fetchSingleProductInfo(
        match.params.locationId,
        productId,
      );
      handleEditProductWrapperAndProduct(data, res.data.data.version, version);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  };

  const handleGetSingleProductWrapper = async (product: any, data: any) => {
    try {
      const res = await fetchSingleProductWrapper(
        match.params.locationId,
        selectedWrapper.id,
      );
      handleGetSingleProduct409(data, product.id, res.data.data.version);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  };

  const handleGetSingleProductWrapperDetails = async () => {
    try {
      const res = await fetchSingleProductWrapper(
        match.params.locationId,
        selectedWrapper.id,
      );
      return res.data.data;
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  };

  const classes = useStyles();
  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>Edit Product of {selectedProduct[0]?.name}</DialogTitle>
        <DialogContent>
          {!isLoading ? (
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{ paddingLeft: "4px" }}>Name</Typography>
                <TextfieldDefaultNew
                  id="name"
                  name="name"
                  type="text"
                  inputRef={register({
                    required: "Name is required",
                  })}
                />
                {errors.name ? (
                  <ValidationMessage message={errors.name.message} />
                ) : (
                  ""
                )}
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginTop: "4px",
                }}
              >
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  control={<SwitchCommon />}
                  checked={isByo}
                  onChange={handleSwitchByo}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Byo
                    </Typography>
                  }
                  labelPlacement="bottom"
                />
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "4px",
                }}
              >
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  control={<SwitchCommon />}
                  checked={isCustomisationPriority}
                  onChange={handleSwitchCustomisationPriority}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Customisation Priority
                    </Typography>
                  }
                  labelPlacement="bottom"
                />
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  marginTop: "4px",
                }}
              >
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  control={<SwitchCommon />}
                  checked={allYouCanEat}
                  onChange={handleAllYouCanEat}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      All You Can Eat
                    </Typography>
                  }
                  labelPlacement="bottom"
                />
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  control={<SwitchCommon />}
                  checked={isValidKiosk}
                  onChange={handleKioskActivate}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Kiosk
                    </Typography>
                  }
                  labelPlacement="bottom"
                />
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "4px",
                }}
              >
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  control={<SwitchCommon />}
                  checked={isSpicySelectionEnabled}
                  onChange={handleSwitchSpicySelectionEnabled}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Spicy Selection Enabled
                    </Typography>
                  }
                  labelPlacement="bottom"
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginTop: "12px", marginBottom: "12px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Select Department</Typography>
                  <Button
                    style={{ textTransform: "none" }}
                    color="secondary"
                    onClick={handleOpenDepartmentAddModal}
                  >
                    {"Add new department"}
                  </Button>
                </div>
                <Autocomplete
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  size={"small"}
                  id="locationSelectGlobal"
                  color="inherit"
                  options={stockDepartmentSelectedNode}
                  value={selectedDepartmentObj}
                  getOptionLabel={(option: any) => option.name || ""}
                  disableClearable
                  onChange={handleDepartmentChange}
                  classes={{ root: classes.autoComplete }}
                  popupIcon={<ArrowDropDownIcon color={"inherit"} />}
                  renderOption={(props: any) => {
                    return (
                      <Grid
                        container
                        {...props}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          zIndex: 0,
                          height: "20px",
                          marginBottom: "4px",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            className={classes.titleColor}
                          >
                            {props.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      color="inherit"
                      {...params}
                      label=""
                      placeholder="Select Department"
                      variant="outlined"
                    />
                  )}
                />
                {isDepartmentEmpty ? (
                  <ValidationMessage message={"Department is required"} />
                ) : (
                  ""
                )}
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginTop: "12px", marginBottom: "12px" }}
              >
                <Typography>Select In Tax</Typography>
                <Autocomplete
                  open={openInTaxDropDown}
                  onOpen={() => setOpenInTaxDropDown(true)}
                  onClose={() => setOpenInTaxDropDown(false)}
                  size={"small"}
                  id="locationSelectGlobal"
                  color="inherit"
                  options={inOutTax}
                  value={inTax}
                  getOptionLabel={(option: any) => option.name || ""}
                  disableClearable
                  onChange={handleInTaxChange}
                  classes={{ root: classes.autoComplete }}
                  popupIcon={<ArrowDropDownIcon color={"inherit"} />}
                  renderOption={(props: any) => {
                    return (
                      <Grid
                        container
                        {...props}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          zIndex: 0,
                          height: "20px",
                          marginBottom: "4px",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            className={classes.titleColor}
                          >
                            {props.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      color="inherit"
                      {...params}
                      label=""
                      placeholder="Select Department"
                      variant="outlined"
                    />
                  )}
                />
                {isDepartmentEmpty ? (
                  <ValidationMessage message={"Department is required"} />
                ) : (
                  ""
                )}
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginTop: "12px", marginBottom: "12px" }}
              >
                <Typography>Select Out Tax</Typography>
                <Autocomplete
                  open={openOutTaxDropDown}
                  onOpen={() => setOpenOutTaxDropDown(true)}
                  onClose={() => setOpenOutTaxDropDown(false)}
                  size={"small"}
                  id="locationSelectGlobal"
                  color="inherit"
                  options={inOutTax}
                  value={outTax}
                  getOptionLabel={(option: any) => option.name || ""}
                  disableClearable
                  onChange={handleOutTaxChange}
                  classes={{ root: classes.autoComplete }}
                  popupIcon={<ArrowDropDownIcon color={"inherit"} />}
                  renderOption={(props: any) => {
                    return (
                      <Grid
                        container
                        {...props}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          zIndex: 0,
                          height: "20px",
                          marginBottom: "4px",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            className={classes.titleColor}
                          >
                            {props.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      color="inherit"
                      {...params}
                      label=""
                      placeholder="Select Department"
                      variant="outlined"
                    />
                  )}
                />
                {isDepartmentEmpty ? (
                  <ValidationMessage message={"Department is required"} />
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>PLU</Typography>
                <TextfieldDefaultNew
                  id="plu"
                  name="plu"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>Barcode</Typography>
                <TextfieldDefaultNew
                  id="barcode"
                  name="barcode"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>
                  UPC Barcode
                </Typography>
                <TextfieldDefaultNew
                  id="upcBarcode"
                  name="upcBarcode"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>Cost</Typography>
                <TextfieldDefaultNew
                  id="cost"
                  name="cost"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>
                  Description
                </Typography>
                <TextfieldDefaultNew
                  id="desc"
                  name="desc"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>Price</Typography>
                <TextfieldDefaultNew
                  id="price"
                  name="price"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>
                  Print Name
                </Typography>
                <TextfieldDefaultNew
                  id="printName"
                  name="printName"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>Size</Typography>
                <TextfieldDefaultNew
                  id="size"
                  name="size"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>
                  Slave Print Type
                </Typography>
                <TextfieldDefaultNew
                  id="slavePrintType"
                  name="slavePrintType"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>Cover</Typography>
                <TextfieldDefaultNew
                  id="cover"
                  name="cover"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>
                  Item Type
                </Typography>
                <TextfieldDefaultNew
                  id="itemType"
                  name="itemType"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>
                  Name Short
                </Typography>
                <TextfieldDefaultNew
                  id="nameShort"
                  name="nameShort"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>Img</Typography>
                <TextfieldDefaultNew
                  id="img"
                  name="img"
                  type="text"
                  inputRef={register({
                    required: false,
                  })}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography style={{ paddingLeft: "4px" }}>Color</Typography>
                <ColorSelection
                  title={"Color"}
                  colorCode={color}
                  setColor={(color: any) => setColor(color.hex)}
                  setInitialData={() => setColor(selectedWrapper.color)}
                />
              </Grid>
            </Grid>
          ) : (
            <div
              style={{
                marginTop: "10%",
                marginBottom: "10%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="secondary" disableShrink />
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleCloseProductEditModal}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={handleDisable()}
            isLoadingPage={isLoadingButton}
            color={"orange"}
            onClick={handleSubmitProductEditInfo}
          >
            {"Save"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default ProductEditModal;
