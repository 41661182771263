import {
  GET_LOCATION_LIST_ERROR,
  GET_LOCATION_LIST_PENDING,
  GET_LOCATION_LIST_SUCCESS,
  SET_RESTAURANT_INFO,
} from "../actions/restaurant";

export const RestaurantInitialState = {
  isFetching: false,
  error: "",
  storeList: [],
  restaurantInfo: {
    businessDisplayName: "",
    authorities: "",
    coverUrl: "",
    description: "",
    extId: "",
    id: "",
    locationIdentifier: "",
    profileUrl: "",
  },
};

interface Action {
  type: string;
  payload: any;
}

export const RestaurantReducer = (
  state = RestaurantInitialState,
  action: Action,
) => {
  switch (action.type) {
    case GET_LOCATION_LIST_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        storeList: action.payload,
        isFetching: false,
      };
    case GET_LOCATION_LIST_ERROR:
      return {
        ...state,
        storeList: [],
        isFetching: false,
      };
    case SET_RESTAURANT_INFO:
      return {
        ...state,
        restaurantInfo: action.payload,
      };
    default:
      return state;
  }
};
