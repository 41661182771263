import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useState } from "react";
import { NODE_STATUS_CREATE } from "../../../utils/consts";
import {
  deconstructMinuteOfWeekArray,
  getOpenCloseTimesList,
} from "../../../utils/time-conversions";
import CardCommon from "../../card/CardCommon";
import AvailableTimeRow from "./AvailableTimeRow";
import { CustomTheme } from "../../../types/customTheme";
import { useTheme } from "@material-ui/core";

export interface AvailabilitySelectionModalProps {
  open: boolean;
  setOpen: Function;
  nodeData: any;
  disabled?: boolean;
  handleSubmit: (availabilitySelectionList: Array<any>) => void;
  setAvailabilityErrorMessage?: any;
}

/* This component show the availability selection modal and show the selected availability. */
const AvailabilitySelectionModal: React.FunctionComponent<
  AvailabilitySelectionModalProps
> = ({
  open,
  setOpen,
  nodeData,
  disabled,
  handleSubmit,
  setAvailabilityErrorMessage,
}) => {
  const [availabilitySelectionList, setAvailabilitySelectionList] =
    useState<any>([]);

  /* Update the 'availabilitySelectionList' state using backend data. */
  useEffect(() => {
    if (!open) return;
    const openCloseTimes = getOpenCloseTimesList(nodeData.availability);
    const deconstructedMinuteOfWeekArray = deconstructMinuteOfWeekArray(
      nodeData.availability,
      openCloseTimes,
    );
    setAvailabilitySelectionList(deconstructedMinuteOfWeekArray);
  }, [nodeData.availability, open]);

  /* Open the availability selection modal. */
  const handleClickOpen = () => {
    // An availability modal is open only if the status is 'create'.
    if (nodeData.status !== NODE_STATUS_CREATE) {
      setOpen(true);
      setAvailabilityErrorMessage(false);
    } else {
      setAvailabilityErrorMessage(true);
    }
  };

  /* Close the availability selection modal. */
  const handleClose = () => {
    setOpen(false);
  };

  /* Create new time slot UI using initial data. */
  const handleAdd = () => {
    setAvailabilitySelectionList([
      ...availabilitySelectionList,
      {
        closeTime: "00:00",
        id: Math.random(),
        nodeList: [],
        openTime: "00:00",
      },
    ]);
  };

  const handleSubmitSelections = () => {
    handleSubmit(availabilitySelectionList);
    setOpen(false);
  };
  const theme: CustomTheme = useTheme();
  return (
    <div>
      {!disabled && (
        <Button variant={"text"} color="secondary" onClick={handleClickOpen}>
          Edit
        </Button>
      )}
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "10px",
            border: `1px solid ${theme.palette.background.entity_border}`,
          },
        }}
      >
        <CardCommon backgroundColor={"entity_background"}>
          <div>
            <DialogTitle id="alert-dialog-title">
              {"Set opening hours"}
            </DialogTitle>
            <div style={{ padding: 16 }}>
              {availabilitySelectionList.map(
                (availSelection: any, i: number) => (
                  <AvailableTimeRow
                    availabilitySelectionList={availabilitySelectionList}
                    availSelection={availSelection}
                    setAvailabilitySelectionList={setAvailabilitySelectionList}
                  />
                ),
              )}
              <Button onClick={handleAdd} color="secondary">
                Add +
              </Button>
            </div>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleSubmitSelections}
                color="secondary"
                autoFocus
              >
                Apply
              </Button>
            </DialogActions>
          </div>
        </CardCommon>
      </Dialog>
    </div>
  );
};

export default AvailabilitySelectionModal;
