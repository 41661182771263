import { axiosClient } from "../client";

const queryString = require("query-string");

const SALES_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/pay-in-out`;

const SALES_INFO_GET_ITEM = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/sale-item`;

const SALES_CANCEL_INFO_GET_ITEM = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/cancelled-receipt`;

const USER_INFO_GET = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/users`;

const TABLE_INFO_GET = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/tables`;

const OTHER_PAYMENT_INFO_GET = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/other-payment?`;

const SALES_INFO_CANCEL_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/sale`;

const SALES_INFO_Filter_Sales_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/sale/filter`;

  export const fetchAllFilterSalesInfo = (
    token,
    locationId,
    size,
    currentPage,
    shortingMethod,
    shortingHeaderName,
    filterData,
    basedOnShift, 
    selectedPaymentMethod
  ) =>
    axiosClient({
      method: "GET",
      url: queryString.stringifyUrl(
        {
          url: SALES_INFO_Filter_Sales_GET_ALL(locationId),
          query: {
            size: size,
            page: currentPage,
            sort: [shortingHeaderName, shortingMethod],
            where: filterData,
            based_on_shift: basedOnShift,
            payment_type: selectedPaymentMethod,
          },
        },
        {
          sort: false,
          arrayFormat: "comma",
          skipNull: true,
          skipEmptyString: true,
        },
      ),
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

export const fetchAllCancelSalesInfo = (
  token,
  locationId,
  size,
  currentPage,
  shortingMethod,
  shortingHeaderName,
  filterData,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: SALES_INFO_CANCEL_GET_ALL(locationId),
        query: {
          size: size,
          page: currentPage,
          sort: [shortingHeaderName, shortingMethod],
          where: filterData,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchAllSalesInfo = (
  token,
  locationId,
  size,
  currentPage,
  filterData,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: SALES_INFO_GET_ALL(locationId),
        query: { size: size, page: currentPage, where: filterData },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchAllSalesItemInfo = (token, locationId, filterData) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: SALES_INFO_GET_ITEM(locationId),
        query: { where: filterData, sort: ["createdTime", "ASC"] },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchAllSalesCancelItemInfo = (token, locationId, filterData) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: SALES_CANCEL_INFO_GET_ITEM(locationId),
        query: { where: filterData },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchAllUserInfo = (token, locationId) => {
  return axiosClient({
    method: "GET",
    url: USER_INFO_GET(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchAllTableInfo = (token, locationId) => {
  return axiosClient({
    method: "GET",
    url: TABLE_INFO_GET(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchOtherPaymentInfo = (token, locationId, filterData) => {
  return axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: OTHER_PAYMENT_INFO_GET(locationId),
        query: { where: filterData },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
