import moment from "moment";
import { defaultStaticRanges } from "react-date-range";
import {
    addYears,
    endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  isSameDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";

/* If today, yesterday, this week, last week, this month, last month, this year, 
and last year are selected in the date range, a value is returned to close the date range. */
export const checkDateRangeFromTwoDates = (ranges: any) => {
  const beginDate = moment(ranges.selection.startDate).format("YYYY-MM-DD");
  const endDate = moment(ranges.selection.endDate).format("YYYY-MM-DD");
  const startWeek = moment(startOfWeek(new Date())).format("YYYY-MM-DD");
  const endWeek = moment(endOfWeek(new Date())).format("YYYY-MM-DD");
  const lastWeekStartDate = moment(startOfWeek(new Date()))
    .subtract(7, "days")
    .format("YYYY-MM-DD");
  const lastWeekEndDate = moment(endOfWeek(new Date()))
    .subtract(7, "days")
    .format("YYYY-MM-DD");
  const yesterDay = moment(endOfYesterday()).format("YYYY-MM-DD");
  const today = moment().format("YYYY-MM-DD");
  const monthStartDate = moment(startOfMonth(new Date())).format("YYYY-MM-DD");
  const monthEndDate = moment(endOfMonth(new Date())).format("YYYY-MM-DD");
  const lastMonthStartDate = moment()
    .subtract(1, "months")
    .startOf("month")
    .format("YYYY-MM-DD");
  const lastMonthEndDate = moment()
    .subtract(1, "months")
    .endOf("month")
    .format("YYYY-MM-DD");
  const lastYearStartDate = moment()
    .subtract(1, "year")
    .startOf("year")
    .format("YYYY-MM-DD");
  const lastYearEndDate = moment()
    .subtract(1, "year")
    .endOf("year")
    .format("YYYY-MM-DD");
  const thisYearStartDate = moment(startOfYear(new Date())).format(
    "YYYY-MM-DD",
  );
  
  let specificTimeSpan = false;

  if (startWeek === beginDate && endWeek === endDate) {
    specificTimeSpan = true;
  } else if (lastWeekStartDate === beginDate && lastWeekEndDate === endDate) {
    specificTimeSpan = true;
  } else if (yesterDay === beginDate && yesterDay === endDate) {
    specificTimeSpan = true;
  } else if (today === beginDate && today === endDate) {
    specificTimeSpan = true;
  } else if (monthStartDate === beginDate && monthEndDate === endDate) {
    specificTimeSpan = true;
  } else if (lastMonthStartDate === beginDate && lastMonthEndDate === endDate) {
    specificTimeSpan = true;
  } else if (lastYearStartDate === beginDate && lastYearEndDate === endDate) {
    specificTimeSpan = true;
  } else if (thisYearStartDate === beginDate && today === endDate) {
    specificTimeSpan = true;
  }

  return {
    specificTimeSpan,
  };
};

/* Add this year and last year to the date range picker.. */
export const handleStaticRanges = () => {
  return [
    ...defaultStaticRanges,
    {
      label: "This Year",
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: endOfDay(new Date()),
      }),
      isSelected(range: any) {
        const definedRange: any = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      },
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: startOfYear(addYears(new Date(), -1)),
        endDate: endOfYear(addYears(new Date(), -1)),
      }),
      isSelected(range: any) {
        const definedRange: any = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      },
    },
  ];
};
