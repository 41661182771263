import * as React from "react";
import ShapeCircle from "../../shapes/ShapeCircle";
import ShapeLine from "../../shapes/ShapeLine";

export interface PreviewCategoriesProps {
  titleColor: string;
  categoryBackgroundColor: string;
  menuTextColor: string;
  boarderColor: string;
}

/* Show user selected color for color preview box.
(titleColor, menuTextColor) */
const PreviewCategories: React.FunctionComponent<PreviewCategoriesProps> = ({
  titleColor,
  menuTextColor,
}) => {

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ShapeCircle radius={4} color={titleColor} />
        <div style={{ margin: 8 }}></div>
        <ShapeLine length={3} color={titleColor} />
      </div>
      <div style={{ margin: 32 }}></div>
      <ShapeLine length={3} color={menuTextColor} />
      <div style={{ margin: 32 }}></div>
      <ShapeLine length={5} color={menuTextColor} />
      <div style={{ margin: 32 }}></div>
      <ShapeLine length={4} color={menuTextColor} />
      <div style={{ margin: 32 }}></div>
      <ShapeLine length={2} color={menuTextColor} />
      <div style={{ margin: 32 }}></div>
      <ShapeLine length={3} color={menuTextColor} />
      <div style={{ margin: 32 }}></div>
      <ShapeLine length={4} color={menuTextColor} />
      <div style={{ margin: 32 }}></div>
      <ShapeLine length={3} color={menuTextColor} />
      <div style={{ margin: 32 }}></div>
      <ShapeLine length={2} color={menuTextColor} />
      <div style={{ margin: 32 }}></div>
      {/* <ShapeLine length={2} color={menuTextColor} />
      <div style={{ margin: 32 }}></div> */}
    </>
  );
};

export default PreviewCategories;
