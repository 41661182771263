import { axiosClient } from "../client";

const queryString = require("query-string");

const DELIVERY_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/delivery-info`;

const DELIVERY_INFO_GET = (locationId, deliveryOptionId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/delivery-info/${deliveryOptionId}`;

const DELIVERY_INFO_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/delivery-info`;

const DELIVERY_INFO_DELETE = (locationId, deliveryOptionId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/delivery-info/${deliveryOptionId}`;

export const fetchAllDeliveryInfo = (locationId) => {
  return axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
      url: DELIVERY_INFO_GET_ALL(locationId),
      query: { sort: ["created","ASC"] }}, {arrayFormat: 'comma'},
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
};

export const updateDeliveryInfo = (locationId, deliveryOptionId, data) => {
  return axiosClient({
    method: "PUT",
    url: DELIVERY_INFO_GET(locationId, deliveryOptionId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
};

export const createDeliveryInfo = (locationId, data) => {
  return axiosClient({
    method: "POST",
    url: DELIVERY_INFO_CREATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
};

export const deleteDeliveryInfo = (locationId, deliveryOptionId) => {
  return axiosClient({
    method: "DELETE",
    url: DELIVERY_INFO_DELETE(locationId, deliveryOptionId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
};
