import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";

import WithLoading from "../../../../utils/WithLoading";
import CreateNewNode from "./CreateNewNode";
import BannersInfoNode from "./BannersInfoNode";
import { NODE_STATUS_CREATE } from "../../../../utils/consts";

export interface BannerTypesProps {
  nodes: any;
  getThemeInfo: any;
  isLoading: boolean;
}

/**
 * Banner information contained in the theme information is entered into a state.
 * The data in that state is entered separately into the Grid using a map.
 * In addition, Until the required data for the banner
 * information is received from the API call, this will show the loading of the skeleton.
 */
const BannersType: React.FunctionComponent<BannerTypesProps> = ({
  nodes,
  getThemeInfo,
  isLoading,
}) => {
  const [bannerNodes, setBannerNodes] = useState<any>({});
  const [themeInitialNodes, setThemeInitialNodes] = useState<any>({});

  useEffect(() => {
    // Set banner information
    setBannerNodes(nodes.banners);
    setThemeInitialNodes(nodes);
  }, [nodes]);

  /* When creating a new banner, enter the initial information 
  required for the banner into the state. */
  const createNode = (nodeData: any) => {
    setBannerNodes([...bannerNodes, { ...nodeData }]);
  };

  /* Used to create a new banner create */
  const handleCreateNode = () => {
    // Banner initial information
    const nodeData = {
      status: "create",
      availability: [],
      isActive: true,
      title: "",
      backgroundColor: "",
      deliveryTypes: [],
      subtitle: "",
      textColor: "",
    };
    createNode(nodeData);
  };

  /* Remove banner information before updating the backend. The status is used for that. 
  If the status is "create", it will be removed from the state.  */
  const handleRemoveNode = () => {
    setBannerNodes(
      bannerNodes.filter((node: any) => node.status !== NODE_STATUS_CREATE),
    );
  };

  return (
    <div>
      <Typography
        variant="h4"
        component="div"
        align="left"
        style={{ margin: 4 }}
      />
      <Grid
        container
        spacing={2}
        style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
      >
        {!_.isEmpty(bannerNodes) &&
          bannerNodes.map((nodeData: any, index: any) => (
            <Grid
              item
              sm={12}
              md={6}
              xs={12}
              key={nodeData.id}
              lg={4}
              style={{ display: "flex" }}
            >
              <BannersInfoNode
                nodeData={nodeData}
                index={index}
                themeInitialNodes={themeInitialNodes}
                getThemeInfo={getThemeInfo}
                handleRemoveNode={handleRemoveNode}
                setThemeInitialNodes={setThemeInitialNodes}
              />
            </Grid>
          ))}
        <CreateNewNode handleCreateNode={handleCreateNode} />
      </Grid>
    </div>
  );
};

export default WithLoading(BannersType);
