import { authenticateTokenWithServer } from "../../services/accountApp/authService";
import { setCookie } from "../../utils/cookies";

export const SET_AUTH_USER = "SET_AUTH_USER";
export const AUTHENTICATION_PENDING = "AUTHENTICATION_PENDING";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";

interface AuthUser {
  email: string | null;
  // idToken: string | null;
  // test comment
}

export const setAuthUser = (payload: AuthUser) => ({
  type: SET_AUTH_USER,
  payload,
});

function authenticationPending() {
  return {
    type: AUTHENTICATION_PENDING,
  };
}

function authenticationSuccess(firebaseUser: any) {
  return {
    type: AUTHENTICATION_SUCCESS,
    payload: firebaseUser,
  };
}

function authenticationError(error: any) {
  return {
    type: AUTHENTICATION_ERROR,
    error,
  };
}
