import React, { useState } from "react";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  HeaderCellSort,
  useSort,
} from "@table-library/react-table-library/sort";
import "jspdf-autotable";
import { Icon } from "@iconify/react";

import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import { sortNumbers, sortStrings } from "../../../../utils/commonArrayMap";

export interface VoidSummeryInfoNodeProps {
  nodes: any;
  nodesLength: any;
}

const useStyles = (nodesLength: number) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        paddingLeft: "16px",
        fontSize: "12px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: "12px",
      },
      endTextStyle: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontSize: "12px",
        paddingRight: "20px",
      },
      textStyle: {
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.table_header_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      headerRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      baseCellRow: {
        placeItems: "center",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: `${100 / 7}%`,
          width: `${100 / 7}%`,
        },
        [theme.breakpoints.down("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${6})`]: {
            minWidth: `${100 / 7}%`,
            width: `${100 / 7}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${7})`]: {
            minWidth: `${100 / 7}%`,
            width: `${100 / 7}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${6})`]: {
            minWidth: "20%",
            width: "20%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${7})`]: {
            minWidth: "20%",
            width: "20%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "20%",
            width: "20%",
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${6})`]: {
            minWidth: "32%",
            width: "32%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${7})`]: {
            minWidth: "32%",
            width: "32%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "32%",
            width: "32%",
          },
        },
      },
    }),
  );

const DailyOrderReportInfoNode: React.FunctionComponent<
  VoidSummeryInfoNodeProps
> = ({ nodes, nodesLength }) => {
  const headersList = [
    "Date",
    "Orders",
    "Total",
    "Cash",
    "Card",
    "Collection",
    "Delivery",
  ];

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const maxWidthCustom = useMediaQuery("(max-width: 1600px)");

  // Create a pay In Out sorting function
  const sort = useSort(
    nodes,
    {},
    {
      sortFns: {
        Date: (array) => sortStrings(array, "date"),
        Orders: (array) => sortNumbers(array, "orders"),
        Total: (array) => sortNumbers(array, "total"),
        Cash: (array) => sortNumbers(array, "cash"),
        Card: (array) => sortNumbers(array, "card"),
        Collection: (array) => sortNumbers(array, "takeout"),
        Delivery: (array) => sortNumbers(array, "delivery"),
      },
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  const classes: any = useStyles(nodesLength)();
  return (
    <>
      <CardCommon>
        <Table
          data={nodes}
          sort={sort}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
        >
          {(tableList) => (
            <>
              <Header style={{ zIndex: 0 }}>
                <HeaderRow className={classes.headerStyle}>
                  {headersList.map((data: any, index: any) => (
                    <HeaderCellSort
                      sortKey={data}
                      pinLeft={index === 0}
                      className={classes.baseCellRow}
                      style={{ fontWeight: "bold" }}
                    >
                      <Typography
                        variant="caption"
                        className={
                          index === 0
                            ? classes.startTextStyle
                            : classes.textStyleHeader
                        }
                      >
                        {data}
                      </Typography>
                    </HeaderCellSort>
                  ))}
                </HeaderRow>
              </Header>

              <Body className={classes.bodyStyle}>
                {tableList.map((item) => (
                  <>
                    <Row
                      key={item.gross20}
                      item={item}
                      className={classes.headerRow}
                    >
                      {Object.keys(item).map(
                        (data: any, index: any) =>
                          data !== "methodKey" &&
                          data !== "locationId" && (
                            <>
                              <Cell
                                pinLeft={index === 0 ? true : false}
                                className={classes.baseCellRow}
                              >
                                <Typography
                                  variant="caption"
                                  align="left"
                                  className={
                                    index === 0
                                      ? classes.startTextStyle
                                      : index === 6
                                      ? classes.endTextStyle
                                      : classes.textStyle
                                  }
                                >
                                  {item[data]}
                                </Typography>
                              </Cell>
                            </>
                          ),
                      )}
                    </Row>
                  </>
                ))}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>
    </>
  );
};

export default DailyOrderReportInfoNode;
