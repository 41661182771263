/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { Divider, FormControlLabel, Grid, IconButton } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { Icon } from "@iconify/react";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import AvailabilitySelection from "../../../../components/time/AvailabilitySelection";
import ConfirmDeleteDialog from "../../components/dialogs/ConfirmDeleteDialog";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import {
  createDiscountInfo,
  deleteDiscountInfo,
  updateDiscountInfo,
} from "../../../../services/eatprestoApp/discountInfoService";
import {
  combineMinuteOfWeekArrays,
  normalizeTimeSelections,
} from "../../../../utils/time-conversions";
import {
  DISCOUNT_TYPE_AMOUNT,
  ERROR_MESSAGE_CREATING_ERROR,
  ERROR_MESSAGE_DELETING_ERROR,
  ERROR_MESSAGE_NOT_AVAILABLE_DISCOUNT_ERROR,
  ERROR_MESSAGE_UPDATING_ERROR,
  ERROR_MESSAGE_VERSION_ERROR,
  INTEGER_NUMBER_PATTERN,
  NODE_STATUS_CREATE,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { Theme } from "../../../../types/customTheme";
import ApplyToSelection from "../../../../components/common/ApplyToSelection";
import { DISCOUNT_TYPE_PERCENT } from "../../../../utils/consts";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import {
  DeliveryTpe,
  HttpStatus,
  submitButtonName,
} from "../../../../utils/enum";
import ConfirmDeleteDialogDefault from "../../components/dialogs/ConfirmDeleteDialogDefault";
import CardCommon from "../../../../components/card/CardCommon";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { convertDecimalPoint } from "../../../../utils/ReplaceIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
    },
    base: {
      padding: "12px 12px 0 12px",
      [theme.breakpoints.up("sm")]: {
        padding: "16px 16px 0 16px",
      },
      borderRadius: 4,
    },
    error: {
      backgroundColor: theme.palette.error.dark,
      borderRadius: "8px 8px 0 0",
      border: `1px solid ${theme.palette.background.entity_border}`,
      padding: 8,
    },
    actionsWrapper: {
      padding: "16px 16px",
      display: "flex",
      justifyContent: "center",
    },
  }),
);

interface FormData {
  title: string;
  usageCount?: number;
  usageDiscountValue: string;
  usageLimit: number;
  discountGroup: string;
  isAutomaticDiscount: boolean;
  discountCode: string;
  deliveryTypes: [];
  isActive: boolean;
  value: string;
  minOrderPrice: string;
  applyToCondition: any;
  type: string;
  description: string;
  id?: any;
  locationId?: any;
}
export interface DiscountInfoNodeProps {
  nodeData: any;
  type: string;
  getDiscountInfo: any;
  isAuthorized: boolean;
  handleCopyNode: any;
  handleRemoveNode: any;
  categoryList: any;
  isLoadingButton: any;
}

/* Create, delete, and update discount information and users can view all discount information. */
const DiscountInfoNode: React.FunctionComponent<DiscountInfoNodeProps> = ({
  nodeData,
  getDiscountInfo,
  type,
  handleRemoveNode,
  handleCopyNode,
  isAuthorized,
  categoryList,
  isLoadingButton,
}) => {
  const [deliveryTypesList, setDeliveryTypesList] = useState<any>([]);
  const [deliveryTypesListInitial, setDeliveryTypesListInitial] = useState<any>(
    [],
  );
  const [availabilityList, setAvailabilityList] = useState([]);
  const [error, setError] = useState("");
  const [isCreated, setIsCreated] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [isAutomaticDiscount, setIsAutomaticDiscount] = useState(true);
  const [isAutomaticDiscountInitial, setIsAutomaticDiscountInitial] =
    useState(true);
  const [nodeID, setNodeID] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isActiveInitial, setIsActiveInitial] = useState(true);
  const [applyToCondition, setApplyToCondition] = useState<any>();
  const [title, setTitle] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [usageCount, setUsageCount] = useState(0);
  const [isDeliveryTypeEmpty, setIsDeliveryTypeEmpty] = useState(false);
  const [usageDiscountValue, setUsageDiscountValue] = useState("0.00");
  const [discountType, setDiscountType] = useState("");
  const [applyToConditionErrorMessage, setApplyToConditionErrorMessage] =
    useState(false);
  const [availabilityErrorMessage, setAvailabilityErrorMessage] =
    useState(false);
  const [success, setSuccess] = useState("");
  const [isOpenDiscountResetModal, setIsOpenDiscountResetModal] =
    useState(false);
  const [originalNodeData, setOriginalNodeData] = useState({
    type: "",
    availability: [],
    isActive: false,
    minOrderPrice: "0",
    title: "",
    usageCount: 0,
    usageDiscountValue: "",
    value: "",
    usageLimit: 0,
    discountGroup: "",
    isAutomaticDiscount: true,
    discountCode: "",
    deliveryTypes: [],
    applyToCondition: "",
    description: "",
    id: "",
    locationId: "",
    applyTo: [],
  });

  /* Use a react form hooks */
  const { register, handleSubmit, errors, reset, formState, clearErrors } =
    useForm<FormData>({
      defaultValues: {
        title: "",
        value: "",
        minOrderPrice: "",
        usageLimit: 0,
        discountGroup: "",
        deliveryTypes: [],
        discountCode: "",
      },
    });

  // Check the form edit or not using react form hook.
  const isFormEdited = formState.isDirty;

  const match: any = useRouteMatch();

  /* Get updated data after changes. */
  const getNodeDataByState = (data: any) => {
    const updatedData = _.cloneDeep(originalNodeData);
    updatedData.availability = availabilityList;
    updatedData.isActive = isActive;
    updatedData.minOrderPrice = convertDecimalPoint(
      data.minOrderPrice,
    ).toString();
    updatedData.title = data.title;
    updatedData.usageCount = usageCount;
    updatedData.usageDiscountValue = usageDiscountValue;
    updatedData.value = data.value;
    updatedData.usageLimit = data.usageLimit;
    updatedData.discountGroup = data.discountGroup;
    updatedData.isAutomaticDiscount = isAutomaticDiscount;
    updatedData.discountCode = data.discountCode ? data.discountCode : "";
    updatedData.deliveryTypes = deliveryTypesList;
    updatedData.applyToCondition = !_.isEmpty(applyToCondition)
      ? applyToCondition
      : null;
    return updatedData;
  };

  /* Update states after creating delete or update banner information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const {
      id,
      isActive,
      minOrderPrice,
      title,
      value,
      usageLimit,
      discountGroup,
      isAutomaticDiscount,
      discountCode,
      deliveryTypes,
      applyToCondition,
      usageDiscountValue,
      usageCount,
      availability,
    } = nodeValues;
    setNodeID(id);
    setTitle(title);
    setUsageCount(usageCount);
    setUsageDiscountValue(usageDiscountValue);
    setDeliveryTypesList(deliveryTypes);
    setDeliveryTypesListInitial(deliveryTypes);
    setAvailabilityList(availability);
    setIsAutomaticDiscount(isAutomaticDiscount);
    setIsAutomaticDiscountInitial(isAutomaticDiscount);
    setApplyToCondition(applyToCondition);
    setIsActive(isActive);
    setIsActiveInitial(isActive);
    // If the selected tab is 'percent', the discount text field will be renamed to 'Discount Percentage'.
    if (type === DISCOUNT_TYPE_PERCENT) {
      setDiscountType("Discount Percentage");
    } else if (type === DISCOUNT_TYPE_AMOUNT) {
      // If the selected tab is 'amount', the discount text field will be renamed to 'Discount Amount'.
      setDiscountType("Discount Amount");
    }

    // Changing the value of the text field according to the response.
    reset({
      title,
      value,
      minOrderPrice,
      usageLimit: usageLimit.toString(),
      discountGroup,
      deliveryTypes,
      discountCode,
    });
  }, []);

  useEffect(() => {
    setOriginalNodeData(nodeData);
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  /* Enable or disable Create/Edit button. */
  useEffect(() => {
    // Enables the create/edit button if any changes are not made to the form.
    if (
      !isFormEdited &&
      isActive === isActiveInitial &&
      isAutomaticDiscount === isAutomaticDiscountInitial &&
      listsAreEqual(deliveryTypesList, deliveryTypesListInitial)
    ) {
      setButtonDisabled(true);
    } else {
      // Enables the create/edit button if any changes are made to the form.
      setButtonDisabled(false);
    }
  }, [
    isFormEdited,
    isActive,
    isActiveInitial,
    isAutomaticDiscount,
    isAutomaticDiscountInitial,
    deliveryTypesList,
    deliveryTypesListInitial,
  ]);

  function listsAreEqual(list1: any, list2: any) {
    if (list1.length !== list2.length) {
      return false;
    }
    let count = 0;
    for (let i = 0; i < list1.length; i++) {
      for (let t = 0; t < list2.length; t++) {
        if (list1[i] === list2[t]) {
          count = count + 1;
        }
      }
    }
    if (list1.length !== count) {
      return false;
    } else {
      return true;
    }
  }

  /* Update the deliveryTypeList after selecting the delivery options using switches (dine-in, takeout, and delivery). */
  const handleSelectDeliveryTypes = (e: any) => {
    const { value, name, checked } = e.target;
    setIsDeliveryTypeEmpty(false);
    // When a delivery type option is selected, that delivery type option is entered into a list.
    if (checked) {
      const selectedList: any = new Set([...deliveryTypesList, value]);
      setDeliveryTypesList([...selectedList]);
    } else {
      // When a delivery type option is not selected, that delivery type option is removed into a list.
      const selectedList: any = deliveryTypesList.filter(
        (amountType: String) => name !== amountType,
      );
      setDeliveryTypesList(selectedList);
    }
  };

  /* Send an API call to update discount information after click save changes button. */
  const handleUpdateDiscountInfo = async (data: any) => {
    setError("");
    const updatedData = getNodeDataByState(data);
    await updateDiscountInfo(match.params.locationId, nodeID, updatedData)
      .then((res) => {
        getDiscountInfo();
        setSuccess(SUCCESSFULLY_UPDATED);
      })
      .catch((err) => {
        if (err.response.status === HttpStatus.CONFLICT_409) {
          setError(ERROR_MESSAGE_VERSION_ERROR);
          getDiscountInfo();
        } else {
          setError(ERROR_MESSAGE_UPDATING_ERROR);
        }
      });
  };

  /* Send an API call to crete discount information after click create button. */
  const handleCreateDiscountOption = async (data: any) => {
    setError("");
    const createData = getNodeDataByState(data);
    await createDiscountInfo(match.params.locationId, createData)
      .then((res) => {
        getDiscountInfo();
        setIsCreated(true);
        setSuccess(SUCCESSFULLY_CREATED);
      })
      .catch((err) => {
        if (err.response.status === HttpStatus.CONFLICT_409) {
          setError(ERROR_MESSAGE_VERSION_ERROR);
          getDiscountInfo();
        } else {
          setError(ERROR_MESSAGE_CREATING_ERROR);
        }
      });
  };

  /* Send an API call to delete discount information after click delete button. */
  const handleDeleteDiscountOption = async () => {
    setError("");
    await deleteDiscountInfo(match.params.locationId, nodeID)
      .then(() => {
        getDiscountInfo();
        setOpenDeleteConfirm(false);
        setSuccess(SUCCESSFULLY_DELETED);
      })
      .catch((err) => {
        setOpenDeleteConfirm(false);
        if (err.response.status === HttpStatus.CONFLICT_409) {
          setError(ERROR_MESSAGE_VERSION_ERROR);
          getDiscountInfo();
        } else {
          setError(ERROR_MESSAGE_DELETING_ERROR);
        }
      });
  };

  /* Reset states after clicking the cancel button. */
  const resetToOriginalData = () => {
    setNodeDataToState(originalNodeData);
  };

  /* Remove discount information using status. 
  If the status is "create", it will be removed not using an API call
  and if the status is not "create", it will be removed using an API call.  */
  const deleteFunc = () => {
    if (isCreated) {
      handleDeleteDiscountOption();
    } else if (nodeData.status === NODE_STATUS_CREATE) {
      handleRemoveNode(type, newId);
    } else {
      setOpenDeleteConfirm(true);
    }
  };

  /* Check for a create or update and 
  call the handleCreateDiscountOption function if it is a create and 
  handleUpdateDiscountInfo function if it is a update. */
  const handleSubmitData = handleSubmit((data) => {
    setError("");
    // When no option is selected in the delivery type option, the state is set to 'true' to display the error message.
    if (_.isEmpty(deliveryTypesList)) {
      setIsDeliveryTypeEmpty(true);
    } else if (nodeData.status === NODE_STATUS_CREATE && !isCreated) {
      // Call the handleCreateDiscountOption function if it is a create
      setIsDeliveryTypeEmpty(false);
      setApplyToConditionErrorMessage(false);
      setAvailabilityErrorMessage(false);
      return handleCreateDiscountOption(data);
    } else {
      // Call handleUpdateDiscountInfo function if it is a update.
      setIsDeliveryTypeEmpty(false);
      setApplyToConditionErrorMessage(false);
      setAvailabilityErrorMessage(false);
      return handleUpdateDiscountInfo(data);
    }
  });

  /* Send an API call to after click availability apply button. 
  An API call is sent only if the status is not "create". */
  const handleSubmitAvailability = async (
    availabilitySelectionList: Array<any>,
  ) => {
    setError("");
    setApplyToConditionErrorMessage(false);
    setAvailabilityErrorMessage(false);
    /* availabilitySelectionList = [{openTime: '11:49', closeTime: '11:49', id: 2, 
                                         nodeList: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                                    {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]}]
           combinedMowArrays = {tue: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                thu:  {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]} */
    const combinedMowArrays: any = combineMinuteOfWeekArrays(
      availabilitySelectionList,
    );
    const normalizedArray = _.cloneDeep(combinedMowArrays);

    // Remove select same date range in same same date.
    Object.keys(combinedMowArrays).forEach((day) => {
      normalizedArray[day] = normalizeTimeSelections(normalizedArray[day]);
    });

    // Convert array to minute of week array
    const mowArray: any = Object.values(normalizedArray).flat();
    const data = _.cloneDeep(originalNodeData);

    // Update data
    data.availability = mowArray;

    // If the discount has not been created, an error message will be displayed when adding a new 'availability'.
    if (nodeData.status === NODE_STATUS_CREATE && !isCreated) {
      setError(ERROR_MESSAGE_NOT_AVAILABLE_DISCOUNT_ERROR);
    } else {
      // If the discount has been created, add new 'availability'.
      await updateDiscountInfo(match.params.locationId, nodeID, data)
        .then((res) => {
          getDiscountInfo();
          setSuccess(SUCCESSFULLY_UPDATED);
        })
        .catch((err) => {
          if (err.response.status === HttpStatus.CONFLICT_409) {
            setError(ERROR_MESSAGE_VERSION_ERROR);
            getDiscountInfo();
          } else {
            setError(ERROR_MESSAGE_UPDATING_ERROR);
          }
        });
    }
  };

  /* Active switch status change using switch */
  const handleSwitchActivate = (e: any) => {
    const { checked } = e.target;
    setIsActive(checked);
  };

  /* Automatic discount status change. */
  const handleSwitchAutomaticDiscount = (e: any) => {
    const { checked } = e.target;
    setIsAutomaticDiscount(checked);
    // If isAutomaticDiscount is true, then discountCode error will be removed.
    if (checked === true) {
      clearErrors("discountCode");
    }
  };

  /* Send an API call to after click 'applyTo' apply button. 
  An API call is sent only if the status is not "create". */
  const handleSubmitApplyTo = async (applyTo: any, applyToCondition: any) => {
    setError("");
    setApplyToConditionErrorMessage(false);
    setAvailabilityErrorMessage(false);
    const data = _.cloneDeep(originalNodeData);
    data.applyTo = applyTo;
    data.applyToCondition = applyToCondition;

    // If the discount has not been created, an error message will be displayed when adding a new 'applyTo'.
    if (nodeData.status === NODE_STATUS_CREATE && !isCreated) {
      setError(ERROR_MESSAGE_NOT_AVAILABLE_DISCOUNT_ERROR);
    } else {
      // If the discount has been created, add new 'applyTo'.
      await updateDiscountInfo(match.params.locationId, nodeID, data)
        .then((res) => {
          getDiscountInfo();
          setSuccess(SUCCESSFULLY_UPDATED);
        })
        .catch((err) => {
          if (err.response.status === HttpStatus.CONFLICT_409) {
            setError(ERROR_MESSAGE_VERSION_ERROR);
            getDiscountInfo();
          } else {
            setError(ERROR_MESSAGE_UPDATING_ERROR);
          }
        });
    }
  };

  /* Send an API call to after click reset icon. */
  const handleResetDiscount = async () => {
    setIsOpenDiscountResetModal(false);
    setApplyToConditionErrorMessage(false);
    setAvailabilityErrorMessage(false);
    const updatedData = _.cloneDeep(originalNodeData);
    updatedData.usageCount = 0;
    updatedData.usageDiscountValue = "0.00";

    // After clicking the reset icon, usageCount value changes to 0 and usageDiscountValue value changes to 0.00.
    await updateDiscountInfo(match.params.locationId, nodeID, updatedData)
      .then((res) => {
        getDiscountInfo();
        setSuccess(SUCCESSFULLY_UPDATED);
      })
      .catch((err) => {
        if (err.response.status === HttpStatus.CONFLICT_409) {
          setError(ERROR_MESSAGE_VERSION_ERROR);
          getDiscountInfo();
        } else {
          setError(ERROR_MESSAGE_UPDATING_ERROR);
        }
      });
  };

  const { newId } = nodeData;

  const classes = useStyles();

  return (
    <Grid>
      <ConfirmDeleteDialogDefault
        open={isOpenDiscountResetModal}
        setOpen={setIsOpenDiscountResetModal}
        confirmAction={handleResetDiscount}
      />
      <ConfirmDeleteDialog
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        confirmAction={handleDeleteDiscountOption}
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <CardCommon backgroundColor={"entity_background"}>
        {error && (
          <Typography component="div" className={classes.error} variant="body1">
            {error}
          </Typography>
        )}
        <form className={classes.base}>
          <Grid container spacing={2}>
            <Grid item lg={10} xs={9}>
              <Typography variant="h5" align="left">
                {title && title}
              </Typography>
              <Typography variant="caption" align="left">
                <div
                  style={{
                    marginTop: "4px",
                    marginRight: "30px",
                  }}
                >
                  £{usageDiscountValue} DISCOUNTED OVER {usageCount} ORDERS{" "}
                  <IconButton
                    disabled={usageCount === 0 && usageDiscountValue === "0.00"}
                    onClick={() => setIsOpenDiscountResetModal(true)}
                    style={{ paddingTop: "8px" }}
                  >
                    <Icon icon="ic:round-restart-alt" height="20" width="20" />
                  </IconButton>
                </div>
              </Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              lg={2}
              xs={3}
              justify="flex-end"
              style={{ placeItems: "center" }}
            >
              <FormControlLabel
                style={{ marginLeft: "0px", marginRight: "0px" }}
                control={<SwitchCommon />}
                checked={isActive}
                onChange={handleSwitchActivate}
                label="Active"
                labelPlacement="start"
                disabled={!isAuthorized}
              />
            </Grid>
          </Grid>
          <Divider variant="fullWidth" style={{ margin: "4px 0 8px 0" }} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextfieldCommon
                id="title"
                name="title"
                type="text"
                label="Discount title"
                disabled={!isAuthorized}
                inputRef={register({
                  required: "Title is required",
                  minLength: { value: 2, message: "Minimum Character is 2" },
                  maxLength: {
                    value: 30,
                    message: "Maximum Character is 30",
                  },
                })}
              />
              {errors.title ? (
                <ValidationMessage message={errors.title.message} />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={6}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  value="dinein"
                  disabled={!isAuthorized}
                  control={<SwitchCommon />}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Dine In
                    </Typography>
                  }
                  name="dinein"
                  checked={
                    !_.isEmpty(deliveryTypesList)
                      ? deliveryTypesList.includes(DeliveryTpe.DINEIN)
                      : false
                  }
                  labelPlacement="bottom"
                  onChange={handleSelectDeliveryTypes}
                />
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  value="delivery"
                  disabled={!isAuthorized}
                  control={<SwitchCommon />}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Delivery
                    </Typography>
                  }
                  name="delivery"
                  checked={
                    !_.isEmpty(deliveryTypesList)
                      ? deliveryTypesList.includes(DeliveryTpe.DELIVERY)
                      : false
                  }
                  labelPlacement="bottom"
                  onChange={handleSelectDeliveryTypes}
                />
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  value="takeout"
                  disabled={!isAuthorized}
                  name="takeout"
                  control={<SwitchCommon />}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Takeout
                    </Typography>
                  }
                  checked={
                    !_.isEmpty(deliveryTypesList)
                      ? deliveryTypesList.includes(DeliveryTpe.TAKEOUT)
                      : false
                  }
                  labelPlacement="bottom"
                  onChange={handleSelectDeliveryTypes}
                />
              </div>
              {_.isEqual(isDeliveryTypeEmpty, true) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please select a least one delivery option.
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
              <TextfieldCommon
                id="value"
                name="value"
                label={discountType}
                type="number"
                disabled={!isAuthorized}
                inputRef={register({
                  required: "Discount Amount is required",
                  min: {
                    value: 0.01,
                    message:
                      "Please enter a valid value. The two nearest valid values are 0 and 1.",
                  },
                  max: { value: 100, message: "Maximum value is 100" },
                })}
              />
              {errors.value ? (
                <ValidationMessage message={errors.value.message} />
              ) : (
                ""
              )}
            </Grid>
            <Grid item md={6} xs={6}>
              <TextfieldCommon
                id="minOrderPrice"
                name="minOrderPrice"
                type="number"
                disabled={!isAuthorized}
                label="Minimum order price"
                inputRef={register({
                  required: "Min Order Price is required",
                })}
              />
              {errors.minOrderPrice ? (
                <ValidationMessage message={errors.minOrderPrice.message} />
              ) : (
                ""
              )}
            </Grid>
            <Grid item md={6} xs={6}>
              <TextfieldCommon
                id="usageLimit"
                name="usageLimit"
                type="number"
                label="Usage limit"
                inputRef={register({
                  required: "Usage Limit is required.",
                  pattern: {
                    value: INTEGER_NUMBER_PATTERN,
                    message: "Invalid limit entered",
                  },
                })}
              />
              {errors.usageLimit ? (
                <ValidationMessage message={errors.usageLimit.message} />
              ) : (
                ""
              )}
            </Grid>
            <Grid item md={6} xs={6}>
              <TextfieldCommon
                id="discountGroup"
                name="discountGroup"
                type="text"
                label="Discount Group"
                disabled={!isAuthorized}
                inputRef={register}
              />
            </Grid>
          </Grid>

          <Typography
            align="left"
            variant="body2"
            style={{ marginTop: "8px", marginLeft: "4px" }}
          >
            Apply To
          </Typography>
          <ApplyToSelection
            nodeData={originalNodeData}
            categoryList={categoryList}
            disabled={!isAuthorized}
            handleSubmitApplyTo={handleSubmitApplyTo}
            setApplyToConditionErrorMessage={setApplyToConditionErrorMessage}
          />
          {applyToConditionErrorMessage && (
            <ValidationMessage
              message={"Please create a discount before updating."}
            />
          )}
          <AvailabilitySelection
            nodeData={originalNodeData}
            disabled={!isAuthorized}
            handleSubmit={handleSubmitAvailability}
            setAvailabilityErrorMessage={setAvailabilityErrorMessage}
          />
          {availabilityErrorMessage && (
            <ValidationMessage
              message={"Please create a discount before updating."}
            />
          )}
          <Grid
            container
            justify="flex-start"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <FormControlLabel
              style={{ marginLeft: "20px", marginRight: "0px" }}
              control={<SwitchCommon />}
              checked={isAutomaticDiscount}
              disabled={!isAuthorized}
              onChange={handleSwitchAutomaticDiscount}
              label={
                <Typography variant="caption" color="textSecondary">
                  Automatic Discount
                </Typography>
              }
              labelPlacement="start"
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextfieldCommon
                id="discountCode"
                name="discountCode"
                label="Discount Code"
                type="text"
                disabled={!isAuthorized || isAutomaticDiscount}
                inputRef={register({
                  required: {
                    value: !isAutomaticDiscount ? true : false,
                    message: "Discount code is required.",
                  },
                })}
              />
              {errors.discountCode && (
                <ValidationMessage message={errors.discountCode.message} />
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              xs={6}
              justify="flex-end"
              style={{ placeItems: "center" }}
            >
              <ButtonCommon
                style={{ marginTop: "3px", marginRight: "3px" }}
                variant="contained"
                color="blue"
                onClick={() => handleCopyNode(originalNodeData)}
              >
                <FileCopyIcon />
              </ButtonCommon>
            </Grid>
          </Grid>
        </form>

        {isAuthorized && (
          <div className={classes.actionsWrapper}>
            <ButtonCommon
              disabled={buttonDisabled || isDeliveryTypeEmpty}
              isLoadingPage={isLoadingButton}
              style={{
                flex: 1,
                fontSize: 11,
                marginRight: 4,
              }}
              variant="contained"
              color={
                nodeData.status === NODE_STATUS_CREATE && !isCreated
                  ? "green"
                  : "yellow"
              }
              onClick={handleSubmitData}
            >
              {nodeData.status === NODE_STATUS_CREATE
                ? submitButtonName.CREATE_SUBMIT_BUTTON
                : submitButtonName.UPDATE_SUBMIT_BUTTON}
            </ButtonCommon>
            <ButtonCommon
              disabled={buttonDisabled}
              variant="contained"
              style={{ fontSize: 11, flex: 1 }}
              color="orange"
              startIcon={<RotateLeftIcon />}
              onClick={resetToOriginalData}
            >
              Cancel
            </ButtonCommon>
            {isAuthorized && (
              <ButtonCommon
                variant="contained"
                style={{
                  fontSize: 11,
                  flex: 1,
                  marginLeft: 4,
                }}
                color="red"
                startIcon={<DeleteIcon />}
                onClick={deleteFunc}
              >
                Delete
              </ButtonCommon>
            )}
          </div>
        )}
      </CardCommon>
    </Grid>
  );
};

export default withAuthority(DiscountInfoNode, Authorities.DISCOUNT_READ);
