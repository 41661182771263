import { Button, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { Theme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridField: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "11px",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "36px",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        minHeight: "0px",
        justifyContent: "center",
        marginTop: "6px",
        paddingLeft: "0px",
      },
    },
    gridFieldLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9px",
      marginTop: "2px",
      marginBottom: "8px",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "36px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        minHeight: "0px",
        fontSize: "14px",
        justifyContent: "center",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      paddingLeft: "16px",
      textTransform: "none",
      minHeight: "36px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        fontSize: "14px",
        minHeight: "0px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
  }),
);

export interface StockDepartmentInfoNodeProps {
  nodeData: any;
  locationSelectorList: any;
  handleOpenEditStockDepartmentModal: any;
}

/* This component creates the data for the table using the data received from the API call.  */
const StockDepartmentInfoNode: React.FunctionComponent<
  StockDepartmentInfoNodeProps
> = ({
  nodeData,
  locationSelectorList,
  handleOpenEditStockDepartmentModal,
}) => {
  const [updateTime, setUpdateTime] = useState("");
  const [version, setVersion] = useState("");
  const [locationName, setLocationName] = useState("");
  const [name, setName] = useState("");
  const [createdTime, setCreatedTime] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [stockGroupId, setStockGroupId] = useState("");

  /* Update states after getting the order information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const { created, version, updated, locationId, name, id, stockGroupId } =
      nodeValues;
    setDepartmentId(id);
    setStockGroupId(stockGroupId.id);
    setName(name);
    setCreatedTime(moment(created).format("MMM D, YYYY HH:mm"));
    setUpdateTime(moment(updated).format("MMM D, YYYY HH:mm"));
    setVersion(version);
    // Get the location name using locationId
    const locationDetails = getFilterListFromArrayObject(
      locationSelectorList,
      locationId,
    );
    // Only if there is a detail of the location, A location name entered into a state.
    if (!_.isEmpty(locationDetails)) {
      setLocationName(locationDetails[0].label);
    } else {
      // If there is no description of the location, the state will be entered as 'Unknown location'.
      setLocationName("Unknown location");
    }
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const classes = useStyles();

  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Button
          style={{ width: "100%", margin: "0px", padding: "0px" }}
          onClick={(event: any) =>
            handleOpenEditStockDepartmentModal(
              departmentId,
              stockGroupId,
              name,
              version,
              event,
            )
          }
        >
          <Grid container>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={3}
              className={classes.gridFieldFirstColumn}
            >
              {locationName}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={3}
              className={classes.gridField}
            >
              {name}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={3}
              className={classes.gridField}
            >
              {createdTime}
            </Grid>

            <Grid item xs={12} md={3} className={classes.gridFieldLastColumn}>
              {updateTime}
            </Grid>
          </Grid>
        </Button>
      </CardCommon>
    </>
  );
};

export default withAuthority(StockDepartmentInfoNode, Authorities.INVENTORY_ADMIN_READ);
