import { axiosClient } from "../client";


const queryString = require("query-string");

const PAYMENT_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/reports/payments/summary`;

// const PAYMENT_INFO_GET_ALL = (locationId) =>
//   `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/reports/payments/summary`;

export const fetchAllPaymentReportInfo = (token, locationId, filterData, basedOnShift) => 
axiosClient({
  method: "GET",
  url: queryString.stringifyUrl({
    url: PAYMENT_INFO_GET_ALL(locationId),
    query: {  where: filterData, based_on_shift: basedOnShift }}, {sort: false, arrayFormat: 'comma', skipNull: true, skipEmptyString: true},
    
  ),
  headers: {
    "X-Role-Location": locationId,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});
