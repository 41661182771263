import React, { useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { CustomTheme } from "../../../../../types/customTheme";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: `1px solid ${theme.palette.background.entity_border}`,
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    labelStyle: {
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
  }),
);

export interface StepOneProps {
  open: any;
  setOpen: any;
  handleOpenDepartmentAddModal: any;
  stockDepartmentSelectedNode: any;
  selectedDepartmentObj: any;
  setSelectedDepartmentObj: any;
  getAllStockDepartments: any;
  getAllStockGroup: any;
  handleGetSupplierInfo: any;
  setDepartmentName: any;
}

/**
 * StepOne Component
 *
 * This component represents the first step of a wizard modal.
 * It prompts the user to choose a department for adding stock items.
 */
const StepOne: React.FunctionComponent<StepOneProps> = ({
  open,
  setOpen,
  handleOpenDepartmentAddModal,
  stockDepartmentSelectedNode,
  selectedDepartmentObj,
  setSelectedDepartmentObj,
  getAllStockDepartments,
  getAllStockGroup,
  handleGetSupplierInfo,
  setDepartmentName,
}) => {
  /**
   * This function handles the change of the selected department.
   * It updates the selected department object based on user selection.
   * @param {Object} departmentData - The selected department data.
   */
  const handleDepartmentChange = (e: any, departmentData: any) => {
    setSelectedDepartmentObj(departmentData);
    setDepartmentName(departmentData.name);
  };

  /**
   * useEffect hook is used to perform side effects in function components.
   * In this case, it's used to fetch stock departments, stock groups, and supplier information when the component mounts.
   * The empty dependency array [] ensures that this effect runs only once when the component mounts.
   */
  useEffect(() => {
    // Call the function to fetch all stock departments
    getAllStockDepartments();
    // Call the function to fetch all stock groups
    getAllStockGroup();
    // Call the function to fetch supplier information
    handleGetSupplierInfo();
  }, []);

  const classes = useStyles();

  return (
    <>
      <Typography variant="h6">
        Please choose a department for adding stock items
      </Typography>
      <Typography variant="body1">
        Assigning stock items to departments helps organise and categories your
        inventory for future use and reporting
      </Typography>
      <Grid container>
        <Grid item xs={12} style={{ marginTop: "12px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>Select Department</Typography>
            <Button
              style={{ textTransform: "none" }}
              color="secondary"
              autoFocus
              onClick={handleOpenDepartmentAddModal}
            >
              {"Add new department"}
            </Button>
          </div>
          <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            size={"small"}
            id="locationSelectGlobal"
            color="inherit"
            options={stockDepartmentSelectedNode}
            value={selectedDepartmentObj}
            getOptionLabel={(option: any) => option.name || ""}
            style={{ width: 300 }}
            disableClearable
            onChange={handleDepartmentChange}
            classes={{ root: classes.autoComplete }}
            popupIcon={<ArrowDropDownIcon color={"inherit"} />}
            renderOption={(props: any) => {
              return (
                <Grid
                  container
                  {...props}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    zIndex: 0,
                    height: "20px",
                    marginBottom: "4px",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.titleColor}>
                      {props.name}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
            renderInput={(params: any) => (
              <TextField
                color="inherit"
                {...params}
                label=""
                placeholder="Select Department"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StepOne;
