import React from "react";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import _ from "lodash";

import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import StockGroupInfoNode from "./StockGroupInfoNode";

export interface StockGroupHeaderProps {
  nodeData: any;
  handleChangeStockGroupListSorting: any;
  handleRemoveStockGroupListSorting: any;
  disableButton: any;
  isLoading: any;
  locationSelectorList: any;
  handleOpenEditStockGroupModal: any;
  orderId: any;
  setOrderId: any;
  isSort: any;
  headerName: any;
  sortingMethod: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    headerContainerStyle: {
      marginTop: "16px",
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    headerItemStyle: {
      display: "flex",
      placeItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
        paddingLeft: "0px",
      },
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      paddingLeft: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
    headerItemStyleLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      placeItems: "center",
      paddingRight: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
      },
    },
  }),
);

/* This is stock group Table header page. In addition, Until the required data for the stock group 
   table is received from the API call, this will show the loading of the skeleton. */
const StockGroupHeader: React.FunctionComponent<StockGroupHeaderProps> = ({
  nodeData,
  handleChangeStockGroupListSorting,
  handleRemoveStockGroupListSorting,
  disableButton,
  isLoading,
  locationSelectorList,
  handleOpenEditStockGroupModal,
  isSort,
  headerName,
  sortingMethod,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.headerContainerStyle}>
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ minHeight: "36px" }}>
            <Grid item xs={3} className={classes.headerFirstItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeStockGroupListSorting}
                handleRemoveOrderListSorting={handleRemoveStockGroupListSorting}
                headerName={"Location"}
                headerId={"locationId"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "locationId" ? isSort : false}
                sortingMethod={headerName === "locationId" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={3} className={classes.headerItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeStockGroupListSorting}
                handleRemoveOrderListSorting={handleRemoveStockGroupListSorting}
                headerName={"Name"}
                headerId={"name"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "name" ? isSort : false}
                sortingMethod={headerName === "name" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={3} className={classes.headerItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeStockGroupListSorting}
                handleRemoveOrderListSorting={handleRemoveStockGroupListSorting}
                headerName={"Created"}
                headerId={"created"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "created" ? isSort : false}
                sortingMethod={headerName === "created" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={3} className={classes.headerItemStyleLastColumn}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeStockGroupListSorting}
                handleRemoveOrderListSorting={handleRemoveStockGroupListSorting}
                headerName={"Updated"}
                headerId={"updated"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "updated" ? isSort : false}
                sortingMethod={headerName === "updated" ? sortingMethod : ""}
              />
            </Grid>
          </Grid>
        </CardCommon>
      </div>
      <Grid container style={{ marginTop: "1px" }}>
        {!_.isEmpty(nodeData) ? (
          nodeData.map((nodeData: any) => (
            <Grid
              item
              xs={12}
              key={nodeData.id}
              style={{ placeItems: "center", marginTop: "4px" }}
            >
              <StockGroupInfoNode
                nodeData={nodeData}
                locationSelectorList={locationSelectorList}
                handleOpenEditStockGroupModal={handleOpenEditStockGroupModal}
              />
            </Grid>
          ))
        ) : (
          <div style={{ width: "100%", margin: "auto", marginTop: "5%" }}>
            <Typography variant="h4" align="center">
              No Stock Group available.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};

export default WithLoading(StockGroupHeader);
