import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import _ from "lodash";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { useRouteMatch } from "react-router-dom";
import { createWrapper } from "../../../../../services/menuApp/menuService";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../../utils/consts";
import ColorSelection from "../../../../PrestoExpressEatprestoApp/pages/ThemeCustomizationPage/ColorSelection";

export interface DirectoryAddModalProps {
  isOpen: any;
  setIsOpen: any;
  setDirectoryName: any;
  directoryName: any;
  isDirectoryNameEmpty: any;
  setIsDepartmentEmpty: any;
  handleCloseDirectoryAddModal: any;
  setUrl: any;
  isLoadingButton: any;
  url: any;
  setIsLoadingButton: any;
  categoryId: any;
  directoryId: any;
  directoryLevel: any;
  setSuccess: any;
  setError: any;
  setIsDirectoryNameEmpty: any;
  menuNodeList: any;
  wrapperId: any;
  menuId: any;
  firstWrapperId: any;
  setMenuNodeList: any;
  isRoot: any;
  setIsOpenDirectoryOrProductSelectionModal: any;
  color: any;
  setColor: any
}

const DirectoryAddModal: React.FunctionComponent<DirectoryAddModalProps> = ({
  isOpen,
  setIsOpen,
  setDirectoryName,
  directoryName,
  isDirectoryNameEmpty,
  setIsDepartmentEmpty,
  handleCloseDirectoryAddModal,
  setUrl,
  isLoadingButton,
  url,
  setIsLoadingButton,
  categoryId,
  directoryId,
  directoryLevel,
  setSuccess,
  setError,
  setIsDirectoryNameEmpty,
  menuNodeList,
  wrapperId,
  menuId,
  firstWrapperId,
  setMenuNodeList,
  isRoot,
  setIsOpenDirectoryOrProductSelectionModal,
  color,
  setColor
}) => {
  const match: any = useRouteMatch();

  const handleDirectoryAddInfo = async () => {
    setIsLoadingButton(true);
    if (directoryName) {
      const body = {
        name: directoryName,
        locationId: match.params.locationId,
        categoryId: categoryId,
        nameShort: "",
        directory: true,
        img: url,
        colour: color,
        directoryId: directoryId || "",
        directoryLevel: directoryLevel || 1,
      };
      try {
        const summaryString = `${directoryName} Directory is added`;

        const res = await createWrapper(
          match.params.locationId,
          body,
          "Menus Changed",
          summaryString,
          "menu",
        );
        const cloneMenuNode = _.cloneDeep(menuNodeList);

        if (isRoot) {
          cloneMenuNode[menuId].category[categoryId].wrapper[res.data.data.id] =
            res.data.data;
        } else {
          const updateProductName = (node: any) => {
            // Check if the current node is a product and has the specified ID
            if (node.id === wrapperId) {
              if (_.isEmpty(node["wrapper"])) {
                node["wrapper"] = {};
              }
              node["wrapper"][res.data.data.id] = res.data.data;
              return true; // Indicates the product was found and updated
            }

            // Recursively traverse nested wrappers
            if (node.wrapper) {
              for (const key in node.wrapper) {
                if (Object.hasOwnProperty.call(node.wrapper, key)) {
                  const child = node.wrapper[key];
                  if (updateProductName(child)) {
                    return true; // Exit early if the product was found and updated
                  }
                }
              }
            }

            return false; // Indicates the product was not found in this branch
          };
          // Find and update the product name
          if (
            cloneMenuNode[menuId] &&
            cloneMenuNode[menuId].category[categoryId]
          ) {
            updateProductName(
              cloneMenuNode[menuId].category[categoryId].wrapper[
                firstWrapperId
              ],
            );
          }
        }
        setMenuNodeList(cloneMenuNode);
        setIsDirectoryNameEmpty(false);
        setIsLoadingButton(false);
        setIsOpen(false);
        setIsOpenDirectoryOrProductSelectionModal(false);
        setSuccess(SUCCESSFULLY_CREATED);
      } catch (error) {
        setIsLoadingButton(false);
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      }
    } else {
      setIsLoadingButton(false);
      setIsDirectoryNameEmpty(true);
    }
  };

  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={true}
    >
      <DialogTitle>Add Directory</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography style={{ paddingLeft: "4px" }}>
              Directory Name
            </Typography>
            <TextfieldDefaultNew
              id="sign-password-input"
              name="sign-password-input"
              onChange={(e: any) => {
                setIsDepartmentEmpty(false);
                setDirectoryName(e.target.value);
              }}
              value={directoryName}
              type="text"
            />
            {isDirectoryNameEmpty && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "3px",
                }}
              >
                <Typography
                  align="left"
                  variant="caption"
                  style={{ color: "red" }}
                >
                  Please enter directory name.
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ paddingLeft: "4px" }}>Image</Typography>
            <TextfieldDefaultNew
              id="sign-password-input"
              name="sign-password-input"
              onChange={(e: any) => {
                setUrl(e.target.value);
              }}
              value={url}
              type="text"
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "4px" }}>
            <Typography style={{ paddingLeft: "4px" }}>Color</Typography>
            <ColorSelection
              title={"Color"}
              colorCode={color}
              setColor={(color: any) => setColor(color.hex)}
              setInitialData={() => setColor(color)}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ marginBottom: "8px" }}>
        <ButtonCommon
          variant="contained"
          style={{ fontSize: 11, width: "100px", marginRight: 8 }}
          color="yellow"
          onClick={handleCloseDirectoryAddModal}
        >
          Cancel
        </ButtonCommon>
        <ButtonCommon
          variant="contained"
          style={{
            fontSize: 11,
            marginLeft: 4,
            width: "100px",
          }}
          isLoadingPage={isLoadingButton}
          color={"orange"}
          onClick={handleDirectoryAddInfo}
        >
          {"Save"}
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default DirectoryAddModal;
