import React from "react";
import withAuthority from "../../Auth/withAuthority";
import Authorities from "../../../auth/authorities";
import { Skeleton } from "@material-ui/lab";
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { CustomTheme } from "../../../types/customTheme";
import CardCommon from "../../card/CardCommon";

export interface VoidSummeryProps {
  isSaleModal?: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    buttonStyle: {
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    rowEven: {
      backgroundColor: theme.palette.background.entity_highlight_background,
    },
  }),
);

const ModalTableLoading: React.FunctionComponent<VoidSummeryProps> = ({
  isSaleModal = true,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      style={isSaleModal ? { padding: "20px" } : { padding: "0px" }}
    >
      {isSaleModal && (
        <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
          <Skeleton
            variant="text"
            className={classes.buttonStyle}
            width={130}
            height={64}
          />
        </Grid>
      )}
      <Grid item xs={12} style={{ marginTop: "8px" }}>
        <CardCommon>
          <Skeleton variant="rect" width={"100%"} height={40} />
          <Skeleton
            variant="rect"
            className={classes.rowEven}
            width={"100%"}
            height={40}
          />
          <Skeleton variant="rect" width={"100%"} height={40} />
          <Skeleton
            variant="rect"
            className={classes.rowEven}
            width={"100%"}
            height={40}
          />
          <Skeleton variant="rect" width={"100%"} height={40} />
          <Skeleton
            variant="rect"
            className={classes.rowEven}
            width={"100%"}
            height={40}
          />
          <Skeleton variant="rect" width={"100%"} height={40} />
        </CardCommon>
      </Grid>
    </Grid>
  );
};

export default withAuthority(ModalTableLoading, Authorities.DASHBOARD_READ);
