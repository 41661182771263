import { createStyles, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import CardCommon from "../../../../components/card/CardCommon";
import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    headerContainerStyle: {
      marginTop: "16px",
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    headerStyle: {
      display: "flex",
      justifyContent: "start",
      placeItems: "center",
      paddingLeft: "4px",
    },
  }),
);

export interface MenuHeaderProps {
  handleChangeOrderListSorting: any;
  handleRemoveOrderListSortingApi: any;
  disableButton: any;
}

/* Header page of menu information table. */
const MenuHeader: React.SFC<MenuHeaderProps> = ({
  handleChangeOrderListSorting,
  handleRemoveOrderListSortingApi,
  disableButton,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.headerContainerStyle}>
      <CardCommon backgroundColor={"table_header_background"}>
        <Grid container style={{ minHeight: "40px" }}>
          <Grid item xs={2} className={classes.headerStyle}>
            <SortingMethod
              handleChangeOrderListSorting={handleChangeOrderListSorting}
              handleRemoveOrderListSorting={handleRemoveOrderListSortingApi}
              headerName={"Name"}
              headerId={"menuMapChannel"}
              disableButton={disableButton}
              color={"orange"}
            />
          </Grid>
          <Grid item xs={3} className={classes.headerStyle}>
            <SortingMethod
              handleChangeOrderListSorting={handleChangeOrderListSorting}
              handleRemoveOrderListSorting={handleRemoveOrderListSortingApi}
              headerName={"Created"}
              headerId={"created"}
              disableButton={disableButton}
              color={"orange"}
            />
          </Grid>
          <Grid item xs={3} className={classes.headerStyle}>
            <SortingMethod
              handleChangeOrderListSorting={handleChangeOrderListSorting}
              handleRemoveOrderListSorting={handleRemoveOrderListSortingApi}
              headerName={"Updated"}
              headerId={"updated"}
              disableButton={disableButton}
              color={"orange"}
            />
          </Grid>
          <Grid item xs={3} className={classes.headerStyle}>
            <SortingMethod
              handleChangeOrderListSorting={handleChangeOrderListSorting}
              handleRemoveOrderListSorting={handleRemoveOrderListSortingApi}
              headerName={"Version"}
              headerId={"version"}
              disableButton={disableButton}
              color={"orange"}
            />
          </Grid>
        </Grid>
      </CardCommon>
    </div>
  );
};

export default withAuthority(MenuHeader, Authorities.MENU_READ);
