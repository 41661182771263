import { Button, Grid, Tooltip } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { Theme } from "../../../../types/customTheme";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CardCommon from "../../../../components/card/CardCommon";
import deliverect from "../../../../assets/images/logos/Channel/deliverect.png";
import deliveroo from "../../../../assets/images/logos/Channel/deliveroo.png";
import eatpresto from "../../../../assets/images/logos/Channel/eatpresto.png";
import justeat from "../../../../assets/images/logos/Channel/justeat.png";
import ubereats from "../../../../assets/images/logos/Channel/ubereats.png";
import presto from "../../../../assets/images/logos/Channel/presto.png";
import kiosk from "../../../../assets/images/logos/Platform/kiosk.png";
import online from "../../../../assets/images/logos/Platform/online.png";
import other from "../../../../assets/images/logos/Platform/other.png";
import phone from "../../../../assets/images/logos/Platform/phone.png";
import walkin from "../../../../assets/images/logos/Platform/walkin.png";
import SalesDetailsTab from "./SalesDetailsTab";
import {
  fetchAllSalesCancelItemInfo,
  fetchAllSalesItemInfo,
  fetchOtherPaymentInfo,
} from "../../../../services/salesApp/salesService";
import { getCookie } from "../../../../utils/cookies";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { convertDateTimeFormatAndConvertTimeBold } from "../../../../utils/ConvertDateTimeFormat";
import {
  updateDiscountInfo,
  updateSalePaymentInfo,
} from "../../../../services/salesApp/salePaymentUpdate";
import {
  ERROR_MESSAGE_UPDATING_ERROR,
  ERROR_MESSAGE_VERSION_ERROR,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { AxiosError } from "axios";
import DefaultAlert from "../../../alerts/DefaultAlert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailsTabRoot: {
      flexGrow: 1,
      marginTop: "5px",
      marginBottom: "5px",
    },
    imgStyle: {
      width: "35px",
      height: "35px",
      [theme.breakpoints.down("sm")]: {
        width: "35px",
        height: "35px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "30px",
        height: "30px",
      },
    },
    gridField: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      fontSize: "12px",
      color: theme.palette.custom.green.contrastText,
      [theme.breakpoints.down("sm")]: {
        marginTop: "6px",
      },
    },
    gridFieldPaper: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      fontSize: "12px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "6px",
      },
    },
    gridFieldLastColumn: {
      display: "flex",
      justifyContent: "end",
      placeItems: "center",
      fontSize: "12px",
      paddingRight: "16px",
      color: theme.palette.custom.green.contrastText,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginTop: "6px",
        paddingRight: "0px",
        marginBottom: "12px",
      },
    },
    gridFieldLastColumnPaper: {
      display: "flex",
      justifyContent: "end",
      placeItems: "center",
      fontSize: "12px",
      paddingRight: "16px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginTop: "6px",
        paddingRight: "0px",
        marginBottom: "12px",
      },
    },
    dropDownStyle: {
      paddingRight: "14px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center",
        paddingRight: "0px",
      },
    },
    gridFieldFirstColumnPaper: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "12px",
      paddingLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      color: theme.palette.custom.green.contrastText,
      fontSize: "12px",
      paddingLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
    detailsDropDownIcon: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
        visibility: "hidden",
      },
    },
    buttonClick: {
      minHeight: "48px",
      width: "100%",
      margin: "0px",
      padding: "0px",
      color: "white",
    },
    buttonNotClick: {
      minHeight: "48px",
      width: "100%",
      margin: "0px",
      padding: "0px",
    },
  }),
);

export interface DiscountInfoNodeProps {
  nodeData: any;
  locationSelectorList: any;
  userList: any;
  numberOfLocations: any;
  getUserInfo: any;
  getSaleFilterInfo: any;
  isPaymentTypeLoading: any;
  setIsPaymentTypeLoading: any;
  setIsDiscountLoading: any;
  isDiscountLoading: any;
}

/* This shows the sale details. It have queOrderNo, location name, type or tableNo, date, channel, and platform.
   In addition, this component also retrieves sales details, sales item details, and other payment details through API calls. */
const SalesInfoNode: React.FunctionComponent<DiscountInfoNodeProps> = ({
  nodeData,
  locationSelectorList,
  userList,
  numberOfLocations,
  getUserInfo,
  getSaleFilterInfo,
  isPaymentTypeLoading,
  setIsPaymentTypeLoading,
  setIsDiscountLoading,
  isDiscountLoading,
}) => {
  const [locationName, setLocationName] = useState("");
  const [orderChannelImage, setOrderChannelImage] = useState("");
  const [orderChannelHoverText, setOrderChannelHoverText] = useState("");
  const [platformImage, setPlatformImage] = useState("");
  const [platformHoverText, setPlatformHoverText] = useState("");
  const [openOrderDetailsCard, setOpenOrderDetailsCard] = useState(false);
  const [saleId, setSaleId] = useState("");
  const [isCancelledSale, setIsCancelledSale] = useState(false);
  const [saleVatList, setSaleVatList] = useState<any>([]);
  const [saleItemList, setSaleItemList] = useState<any>([]);
  const [saleCancelItemList, setSaleCancelItemList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [otherPayment, setOtherPayment] = useState<any>([]);
  const [locationId, setLocationId] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [changedPaymentType, setChangedPaymentType] = useState("");

  const idToken = getCookie("idToken");

  const setNodeDataToState = useCallback((nodeValues) => {
    const { locationId, platform, orderChannel } = nodeValues;

    setLocationId(locationId);
    /* Find the location name using locationId. */
    const locationDetails = getFilterListFromArrayObject(
      locationSelectorList,
      locationId,
    );

    // Only if there is a detail of the location, A location name entered into a state.
    if (!_.isEmpty(locationDetails)) {
      setLocationName(locationDetails[0].label);
    } else {
      // If there is no description of the location, the state will be entered as 'Unknown location'.
      setLocationName("Unknown location");
    }

    /* The channel will be included only if the platform is online. Otherwise, the presto logo will be entered for the channel. */
    if (platform === "online") {
      if (orderChannel === "eatpresto") {
        setOrderChannelImage(eatpresto);
        setOrderChannelHoverText("Eatpresto");
      } else if (orderChannel === "deliverect") {
        setOrderChannelImage(deliverect);
        setOrderChannelHoverText("Deliverect");
      } else if (orderChannel === "justeat") {
        setOrderChannelImage(justeat);
        setOrderChannelHoverText("Justeat");
      } else if (orderChannel === "ubereats") {
        setOrderChannelImage(ubereats);
        setOrderChannelHoverText("Ubereats");
      } else if (orderChannel === "deliveroo") {
        setOrderChannelImage(deliveroo);
        setOrderChannelHoverText("Deliveroo");
      } else if (orderChannel === "presto") {
        setOrderChannelImage(presto);
        setOrderChannelHoverText("Presto");
      } else {
        setOrderChannelImage(presto);
        setOrderChannelHoverText("Presto");
      }
    } else {
      setOrderChannelImage(presto);
      setOrderChannelHoverText("Presto");
    }

    /* Set the platform image. */
    if (platform === "kiosk") {
      setPlatformImage(kiosk);
      setPlatformHoverText("Kiosk");
    } else if (platform === "online") {
      setPlatformImage(online);
      setPlatformHoverText("Online");
    } else if (platform === "phone") {
      setPlatformImage(phone);
      setPlatformHoverText("Phone");
    } else if (platform === "walk in") {
      setPlatformImage(walkin);
      setPlatformHoverText("Walk in");
    } else {
      setPlatformImage(other);
      setPlatformHoverText("Unknown");
    }
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  /* Open sale details card. If it is a cancellation of the sale, 
  it is stored in a state as true otherwise it is stored in a state as false and the sale id is also stored in the state. */
  const handleOpenSaleDetails = (nodeData: any) => {
    const { id, cancelled } = nodeData;
    if (!openOrderDetailsCard) {
      setSaleId(id);
      setIsCancelledSale(cancelled);
    } else {
      setSaleId("");
      setIsCancelledSale(false);
      setSaleCancelItemList([]);
    }
    setOpenOrderDetailsCard(!openOrderDetailsCard);
  };

  /* vatPercent When there are equal objects, only one object is returned. */
  const handleSaleVatList = (data: any) => {
    const vatList = data.reduce((previousValue: any, currentValue: any) => {
      if (
        !previousValue.some(
          (prevValue: any) => prevValue.vatPercent === currentValue.vatPercent,
        )
      ) {
        previousValue.push(currentValue);
      }
      return previousValue;
    }, []);

    return vatList;
  };

  /* Get sale items information */
  const handleGetSalesItems = async (saleId: any) => {
    try {
      const res = await fetchAllSalesItemInfo(
        idToken,
        nodeData.locationId,
        `saleId!(${saleId})`,
      );
      const vatList = handleSaleVatList(res.data.data);
      setSaleVatList(vatList);
      setSaleItemList(res.data.data);
    } catch (err) {}
    setIsLoading(false);
  };

  /* Get other payments information */
  const handleGetOtherPayment = async (saleId: any) => {
    try {
      const res = await fetchOtherPaymentInfo(
        idToken,
        nodeData.locationId,
        `saleId!(${saleId})`,
      );
      setOtherPayment(res.data.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  /* Get cancel sale information. */
  const getCancelSaleInfo = async (saleId: any) => {
    try {
      const res = await fetchAllSalesCancelItemInfo(
        idToken,
        nodeData.locationId,
        `saleId!(${saleId})`,
      );
      setSaleCancelItemList(res.data.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  /* Change the sale details row background color. */
  const handleChangeSaleRowColor = (sale: any) => {
    const { id, cancelled } = sale;
    if (saleId === id) {
      return "green";
    } else if (cancelled) {
      return "red";
    }
  };

  /* Updating sales information payment type. */
  const updatePaymentType = async (requestBody: any) => {
    setIsPaymentTypeLoading(true);
    setChangedPaymentType(requestBody.typeBefore);
    try {
      const res = await updateSalePaymentInfo(locationId, requestBody);
      getSaleFilterInfo();
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      const err: any = error as AxiosError;
      if (err.response.status === 409) {
        setError(ERROR_MESSAGE_VERSION_ERROR);
      } else {
        setError(ERROR_MESSAGE_UPDATING_ERROR);
      }
    }
  };

  /* Updating sales information discount percentage. */
  const updateDiscountType = async (requestBody: any) => {
    setIsDiscountLoading(true);
    try {
      const res = await updateDiscountInfo(locationId, requestBody);
      getSaleFilterInfo();
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      const err: any = error as AxiosError;
      if (err.response.status === 409) {
        setError(ERROR_MESSAGE_VERSION_ERROR);
      } else {
        setError(ERROR_MESSAGE_UPDATING_ERROR);
      }
    }
  };

  const classes = useStyles();

  return (
    <>
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity={"error"}
      />
      <CardCommon
        color={handleChangeSaleRowColor(nodeData)}
        backgroundColor={saleId !== nodeData.id && "entity_background"}
      >
        <Button
          className={
            saleId === nodeData.id
              ? classes.buttonClick
              : classes.buttonNotClick
          }
          onClick={() => handleOpenSaleDetails(nodeData)}
        >
          <Grid container>
            {numberOfLocations > 1 && (
              <Grid
                style={{ textAlign: "left", textTransform: "capitalize" }}
                item
                xs={12}
                md={3}
                className={
                  handleChangeSaleRowColor(nodeData) === "green" ||
                  handleChangeSaleRowColor(nodeData) === "red"
                    ? classes.gridFieldFirstColumn
                    : classes.gridFieldFirstColumnPaper
                }
              >
                {locationName}
              </Grid>
            )}
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={1}
              className={
                handleChangeSaleRowColor(nodeData) === "green" ||
                handleChangeSaleRowColor(nodeData) === "red"
                  ? classes.gridFieldFirstColumn
                  : classes.gridFieldFirstColumnPaper
              }
            >
              <Tooltip title={orderChannelHoverText}>
                <img
                  src={orderChannelImage}
                  className={classes.imgStyle}
                  alt="Order channel image"
                />
              </Tooltip>
            </Grid>
            <Grid
              style={{ textAlign: "left", textTransform: "capitalize" }}
              item
              xs={12}
              md={1}
              className={
                handleChangeSaleRowColor(nodeData) === "green" ||
                handleChangeSaleRowColor(nodeData) === "red"
                  ? classes.gridFieldFirstColumn
                  : classes.gridFieldFirstColumnPaper
              }
            >
              <Tooltip title={platformHoverText}>
                <img
                  src={platformImage}
                  className={classes.imgStyle}
                  alt="Platform image"
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              className={
                handleChangeSaleRowColor(nodeData) === "green" ||
                handleChangeSaleRowColor(nodeData) === "red"
                  ? classes.gridFieldFirstColumn
                  : classes.gridFieldFirstColumnPaper
              }
              style={{ textTransform: "capitalize", textAlign: "left" }}
            >
              {nodeData.mode
                .split("_")
                .map((word: any) => {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(" ")}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={1}
              className={
                handleChangeSaleRowColor(nodeData) === "green" ||
                handleChangeSaleRowColor(nodeData) === "red"
                  ? classes.gridFieldFirstColumn
                  : classes.gridFieldFirstColumnPaper
              }
            >
              {nodeData.queOrderNo}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={1}
              className={
                handleChangeSaleRowColor(nodeData) === "green" ||
                handleChangeSaleRowColor(nodeData) === "red"
                  ? classes.gridFieldFirstColumn
                  : classes.gridFieldFirstColumnPaper
              }
            >
              {nodeData.orderNo}
            </Grid>
            <Grid
              style={{ textAlign: "left", fontWeight: "bold" }}
              item
              xs={12}
              md={numberOfLocations > 1 ? 1 : 2}
              className={
                handleChangeSaleRowColor(nodeData) === "green" ||
                handleChangeSaleRowColor(nodeData) === "red"
                  ? classes.gridFieldFirstColumn
                  : classes.gridFieldFirstColumnPaper
              }
            >
              {nodeData.extQueOrderNo}
            </Grid>
            <Grid
              style={{ textAlign: "left", textTransform: "none" }}
              item
              xs={12}
              md={numberOfLocations > 1 ? 2 : 3}
              className={
                handleChangeSaleRowColor(nodeData) === "green" ||
                handleChangeSaleRowColor(nodeData) === "red"
                  ? classes.gridField
                  : classes.gridFieldPaper
              }
            >
              {convertDateTimeFormatAndConvertTimeBold(nodeData.date)}
            </Grid>

            <Grid
              item
              xs={12}
              md={numberOfLocations > 1 ? 1 : 2}
              className={
                handleChangeSaleRowColor(nodeData) === "green" ||
                handleChangeSaleRowColor(nodeData) === "red"
                  ? classes.gridFieldLastColumn
                  : classes.gridFieldLastColumnPaper
              }
            >
              <div className={classes.dropDownStyle}>
                {parseFloat(nodeData.totalAmount).toFixed(2)}
              </div>
              {openOrderDetailsCard ? (
                <ArrowDropUpIcon className={classes.detailsDropDownIcon} />
              ) : (
                <ArrowDropDownIcon className={classes.detailsDropDownIcon} />
              )}
            </Grid>
          </Grid>
        </Button>
      </CardCommon>
      {openOrderDetailsCard && (
        <div className={classes.detailsTabRoot}>
          <CardCommon backgroundColor={"entity_background"}>
            <SalesDetailsTab
              nodeData={nodeData}
              locationName={locationName}
              saleId={saleId}
              handleGetSalesItems={handleGetSalesItems}
              setIsLoading={setIsLoading}
              saleItemList={saleItemList}
              saleVatList={saleVatList}
              isLoading={isLoading}
              handleGetOtherPayment={handleGetOtherPayment}
              otherPayment={otherPayment}
              isCancelledSale={isCancelledSale}
              getCancelSaleInfo={getCancelSaleInfo}
              saleCancelItemList={saleCancelItemList}
              userList={userList}
              getUserInfo={getUserInfo}
              locationId={locationId}
              updatePaymentType={updatePaymentType}
              isPaymentTypeLoading={isPaymentTypeLoading}
              changedPaymentType={changedPaymentType}
              setOtherPayment={setOtherPayment}
              updateDiscountType={updateDiscountType}
              isDiscountLoading={isDiscountLoading}
            />
          </CardCommon>
        </div>
      )}
    </>
  );
};

export default withAuthority(SalesInfoNode, Authorities.SALE_READ);
