import { Grid } from "@material-ui/core";
import * as React from "react";
import Authorities from "../../../../../auth/authorities";
import withAuthority from "../../../../../components/Auth/withAuthority";
import ButtonContained from "../../../../../components/buttons/ButtonContained";
import InputBoxDefault from "../../../../../components/common/InputBoxDefault";
import TextfieldCommon from "../../../../../components/textField/TextfieldCommon";
import { buttonColors, submitButtonName } from "../../../../../utils/enum";

export interface AddressInfoProps {
  locationData: any;
  register: any;
  errors: any;
  isAuthorized?: boolean;
}

/* Show the business information. */
const AddressInfo: React.SFC<AddressInfoProps> = ({
  locationData,
  register,
  errors,
  isAuthorized,
}) => {

  return (
    <>
      <Grid container spacing={2} style={{ paddingTop: "8px" }}>
        <Grid item xs={12}>
          <TextfieldCommon
            id="googleMap"
            name="googleMap"
            type="text"
            margin="normal"
            style={{ width: "100%", borderRadius: "10Ppx" }}
            value={locationData.addressFormatted}
          />
        </Grid>
        <Grid item xs={12}>
          <InputBoxDefault
            disabled
            label={"Building Number or Name"}
            value={locationData.buildingNoOrName}
            name={"buildingNoOrName"}
            id={"buildingNoOrName"}
            register={register({
              required: false,
            })}
            errors={errors}
            helperText={"Please enter a building number Or name"}
          />
        </Grid>
        <Grid item xs={12}>
          <InputBoxDefault
            disabled
            label={"Address Line 1"}
            value={locationData.addressLine1}
            name={"addressLine1"}
            id={"addressLine1"}
            register={register({
              required: false,
            })}
            errors={errors}
            helperText={"Please enter a address line 1"}
          />
        </Grid>
        <Grid item xs={12}>
          <InputBoxDefault
            disabled
            label={"Address Line 2"}
            value={locationData.addressLine2}
            name={"addressLine2"}
            id={"addressLine2"}
            register={register({
              required: false,
            })}
            errors={errors}
            helperText={""}
          />
        </Grid>
        <Grid item xs={12}>
          <InputBoxDefault
            disabled
            label={"City"}
            value={locationData.city}
            name={"city"}
            id={"city"}
            register={register({
              required: false,
            })}
            errors={errors}
            helperText={"Please enter city"}
          />
        </Grid>
        <Grid item xs={12}>
          <InputBoxDefault
            disabled
            label={"county"}
            value={locationData.county}
            name={"county"}
            id={"county"}
            register={register({
              required: false,
            })}
            errors={errors}
            helperText={"Please enter county"}
          />
        </Grid>
        <Grid item xs={12}>
          <InputBoxDefault
            disabled
            label={"country"}
            value={locationData.country}
            name={"country"}
            id={"country"}
            register={register({
              required: false,
            })}
            errors={errors}
            helperText={"Please enter country"}
          />
        </Grid>
        <Grid item xs={12}>
          <InputBoxDefault
            disabled
            label={"Postcode"}
            value={locationData.postcode}
            name={"postcode"}
            id={"postcode"}
            register={register({
              required: false,
            })}
            errors={errors}
            helperText={"Please enter postcode"}
          />
        </Grid>
      </Grid>
      {isAuthorized && (
        <div style={{ marginTop: 24, display: "flex" }}>
          <ButtonContained
            color={buttonColors.UPDATE_BUTTON_COLOR}
            label={submitButtonName.UPDATE_SUBMIT_BUTTON}
            type={"submit"}
          />
        </div>
      )}
    </>
  );
};

export default withAuthority(AddressInfo, Authorities.BASIC_INFO_READ);
