import {
  Button,
  makeStyles,
  createStyles,
  Typography,
  Box,
  useMediaQuery,
  Tabs,
  Tab,
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { CustomTheme } from "../../../types/customTheme";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import _ from "lodash";
import CardCommon from "../../card/CardCommon";
import MobileDatePicker from "../mobileDatePicker/MobileDatePicker";
import DatePicker from "../datePicker/DatePicker";

export interface SortingMethodProps {
  open: any;
  setOpen: any;
  startDate: any;
  endDate: any;
  handleChangeDateRange: any;
  dateSelectionRange: any;
  horizontalMaxWidth: any;
  handleChangePreviousDateRange?: any;
  previousDateSelectionRange?: any;
  previousStartDate?: any;
  previousEndDate?: any;
}

const useStyles = makeStyles<any>((theme: CustomTheme) =>
  createStyles({
    datePickerStyle: {
      zIndex: 6,
      width: "890px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "490px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "328px",
      },
    },
    startDateStyle: {
      [theme.breakpoints.down("xl")]: {
        paddingTop: "8px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        fontSize: "14px",
      },
    },
    arrowIconStyle: {
      [theme.breakpoints.down("xl")]: {
        paddingTop: "9px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
      },
    },
    endDateStyle: {
      [theme.breakpoints.down("xl")]: {
        paddingTop: "8px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        fontSize: "14px",
      },
    },
    arrowIconMarginStyle: {
      [theme.breakpoints.down("xl")]: {
        marginLeft: "8px",
        marginRight: "8px",
        fontSize: "24px",
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: "4px",
        marginRight: "4px",
        fontSize: "18px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        marginLeft: "2px",
        marginRight: "2px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "8px",
        marginRight: "8px",
        fontSize: "20px",
      },
    },
    dateRangeIcon: {
      [theme.breakpoints.down("xl")]: {
        marginTop: "6px",
        fontSize: "24px",
      },
      [theme.breakpoints.down("lg")]: {
        marginTop: "8px",
        fontSize: "18px",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: "6px",
        fontSize: "18px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "8px",
        fontSize: "24px",
      },
    },
    closeIcon: {
      [theme.breakpoints.down("xl")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    buttonStyle: {
      width: "100%",
      justifyContent: "space-between",
      display: "flex",
      height: "60px",
      marginTop: "6px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.entity_highlight_background,
      "&:hover": {
        backgroundColor: theme.palette.background.entity_highlight_background,
      },
      [theme.breakpoints.down("xl")]: {
        padding: "6px 12px",
      },
      [theme.breakpoints.down("lg")]: {
        padding: "6px 6px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "6px 6px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "6px 12px",
      },
    },
    tabPanel: {
      // backgroundColor: theme.palette.background.default,
    },
  }),
);

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`discount-type-tab-${index}`}
      aria-labelledby={`discount-type-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

/* This component is used to display the date selection in the filter. 
   Two date pickers are used to select the current time and previous time for the dashboard page. */
const MultipleDateRangePicker: React.FunctionComponent<SortingMethodProps> = ({
  open,
  setOpen,
  startDate,
  endDate,
  handleChangeDateRange,
  dateSelectionRange,
  horizontalMaxWidth,
  handleChangePreviousDateRange,
  previousDateSelectionRange,
  previousStartDate,
  previousEndDate,
}) => {
  const [value, setValue] = React.useState<number>(0);
  /* Switch the tab to be displayed. */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const maxWidth = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  return (
    <>
      <Button
        className={classes.buttonStyle}
        size={"large"}
        variant="outlined"
        onClick={() => {
          !open && setOpen(true);
          setValue(0);
        }}
      >
        <div>
          {!_.isEmpty(startDate) && !_.isEmpty(endDate) && (
            <div style={{ display: "flex" }}>
              <Typography className={classes.startDateStyle}>
                {value === 0 ? startDate : previousStartDate}
              </Typography>
              <Typography className={classes.arrowIconStyle}>
                <ArrowRightAltIcon className={classes.arrowIconMarginStyle} />
              </Typography>

              <Typography className={classes.endDateStyle}>
                {value === 0 ? endDate : previousEndDate}
              </Typography>
            </div>
          )}
        </div>

        <span>
          {open ? (
            <Typography style={{ fontWeight: "bold" }}>
              <CloseIcon
                className={classes.closeIcon}
                style={{ marginTop: "8px" }}
                onClick={() => setOpen(false)}
              />
            </Typography>
          ) : (
            <Typography>
              <DateRangeIcon className={classes.dateRangeIcon} />
            </Typography>
          )}
        </span>
      </Button>
      <div className={classes.datePickerStyle}>
        {open && (
          <>
            <CardCommon>
              <Tabs
                centered
                value={value}
                onChange={handleChange}
                aria-label="discount-types-tabs"
                variant="fullWidth"
              >
                <Tab
                  label="Current Period"
                  id="discount-type-tab-0"
                  aria-controls="discount-type-tab-0"
                />
                <Tab
                  label="Previous Period"
                  id="discount-type-tab-1"
                  aria-controls="discount-type-tab-1"
                />
              </Tabs>
            </CardCommon>
            <div className={classes.root}>
              <TabPanel value={value} index={0}>
                {!maxWidth ? (
                  <Box borderRadius={30} style={{ borderRadius: 30 }}>
                    <DatePicker
                      handleChangeDateRange={handleChangeDateRange}
                      dateSelectionRange={dateSelectionRange}
                      horizontalMaxWidth={horizontalMaxWidth}
                      setOpen={setOpen}
                    />{" "}
                  </Box>
                ) : (
                  <CardCommon backgroundColor={"entity_highlight_background"}>
                    <MobileDatePicker
                      handleChangeDateRange={handleChangeDateRange}
                      dateSelectionRange={dateSelectionRange}
                      setOpen={setOpen}
                    />
                  </CardCommon>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {!maxWidth ? (
                  <Box
                    boxShadow={3}
                    borderRadius={30}
                    style={{ borderRadius: 30 }}
                  >
                    <DatePicker
                      handleChangeDateRange={handleChangePreviousDateRange}
                      dateSelectionRange={previousDateSelectionRange}
                      horizontalMaxWidth={horizontalMaxWidth}
                      setOpen={setOpen}
                    />{" "}
                  </Box>
                ) : (
                  <CardCommon backgroundColor={"entity_highlight_background"}>
                    <MobileDatePicker
                      handleChangeDateRange={handleChangeDateRange}
                      dateSelectionRange={previousDateSelectionRange}
                      setOpen={setOpen}
                    />
                  </CardCommon>
                )}
              </TabPanel>
            </div>
          </>
        )}
        {/* </CardCommon> */}
      </div>
    </>
  );
};

export default MultipleDateRangePicker;
