export const GET_LOCATION_LIST_PENDING = "GET_LOCATION_LIST_PENDING";
export const GET_LOCATION_LIST_SUCCESS = "GET_LOCATION_LIST_SUCCESS";
export const GET_LOCATION_LIST_ERROR = "GET_LOCATION_LIST_ERROR";

export const SET_RESTAURANT_INFO = "SET_RESTAURANT_INFO";

// fetch all locations
export const getLocationListPending = () => ({
  type: GET_LOCATION_LIST_PENDING,
});

export const getLocationListSuccess = (payload: any) => ({
  type: GET_LOCATION_LIST_SUCCESS,
  payload,
});

export const getLocationListError = (error: any) => ({
  type: GET_LOCATION_LIST_ERROR,
  error,
});

export const fetchLocationList = () => async (dispatch: any) => {
  dispatch(getLocationListPending());
  try {
    // const res = await getAllLocations();
    // dispatch(getLocationListSuccess(locationList));
  } catch (error) {
    dispatch(getLocationListError("Error fetching location list"));
  }
};

interface RestaurantInfo {
  businessDisplayName: string | "";
  authorities: string | "";
  coverUrl: string | "";
  description: string | "";
  extId: string | "";
  id: string | "";
  locationIdentifier: string | "";
  profileUrl: string | "";
}

export const setRestaurantInfo = (payload: RestaurantInfo) => ({
  type: SET_RESTAURANT_INFO,
  payload,
});
