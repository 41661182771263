import { axiosClient } from "../client";
const queryString = require("query-string");

const API_GET_PRODUCT_MENU_LIST = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/product`;

const API_GET_PRODUCT_WRAPPER_LIST = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/productWrapper`;

const API_GET_PRODUCT_BY_WRAPPED_ID = (locationId, wrappedId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/product/${wrappedId}`;

const API_GET_GROUP_MENU_LIST = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/productGroup`;

const API_CREATE_PRODUCT_GROUP = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/productGroup`;

const API_GET_DEPARTMENT_MENU_LIST = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/productDepartment`;

const API_CREATE_DEPARTMENT_MENU = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/productDepartment`;

const API_GET_HARDWARE_LIST = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/hardware`;

const API_GET_STRATEGY_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/printerMappingStrategy`;

const API_GET_PRINTER_MAP_INFO = (locationId, id, type) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/printerMap/${id}/${type}`;

const API_GET_SINGLE_PRINTER_MAP_INFO = (locationId, id, type) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/printerMap/id/${id}`;

const API_GET_PRODUCT_CONFIG_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/productConfig`;

const API_UPDATE_PRODUCT_CONFIG_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/productConfig`;

const API_CREATE_PRODUCT_CONFIG_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/productConfig`;

const API_STRATEGY_INFO_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/printerMappingStrategy`;

const API_DUPLICATE_STRATEGY_INFO_CREATE = (locationId, id) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/printerMappingStrategy/duplicate/${id}`;

const API_STRATEGY_INFO_UPDATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/printerMappingStrategy`;

const API_STRATEGY_INFO_GET_SINGLE = (locationId, id) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/printerMappingStrategy/id/${id}`;

const API_MAP_PRODUCT_INFO_UPDATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/printerMap`;

const API_MAP_PRODUCT_INFO_DELETE = (locationId, id) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/printerMap/${id}`;

const API_MAP_PRODUCT_INFO_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/printerMap`;

export const fetchGlobalProductsInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_PRODUCT_MENU_LIST(locationId),
        query: {
          size: 100000,
          page: 1,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
  });

export const fetchProductWrapperInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_PRODUCT_WRAPPER_LIST(locationId),
        query: {
          size: 100000,
          page: 1,
        },
      },
      
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
  });

export const fetchProductGetByWrappedId = (locationId, wrappedId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_PRODUCT_BY_WRAPPED_ID(locationId, wrappedId),
        query: {
          size: 100000,
          page: 1,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
  });

export const fetchGlobalGroupInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_GROUP_MENU_LIST(locationId),
        query: {
          size: 100000,
          page: 1,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
  });

export const fetchGlobalDepartmentInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_DEPARTMENT_MENU_LIST(locationId),
        query: {
          size: 100000,
          page: 1,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
  });

export const createProductDepartment = (
  locationId,
  data,
  message,
  summery,
  logsAppName,
) =>
  axiosClient({
    method: "POST",
    data,
    url: API_CREATE_DEPARTMENT_MENU(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    message,
    summery,
    logsAppName,
    locationId,
  });

export const createProductGroup = (
  locationId,
  data,
  message,
  summery,
  logsAppName,
) =>
  axiosClient({
    method: "POST",
    data,
    url: API_CREATE_PRODUCT_GROUP(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    message,
    summery,
    logsAppName,
    locationId,
  });

export const fetchHardwareInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_HARDWARE_LIST(locationId),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
  });

export const fetchStrategyInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_STRATEGY_INFO(locationId),
        query: { sort: ["created", "ASC"] },
      },
      { arrayFormat: "comma" },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchPrinterMappingInfo = (locationId, id, type) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_PRINTER_MAP_INFO(locationId, id, type),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
  });

export const fetchSinglePrinterMappingInfo = (locationId, id) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_SINGLE_PRINTER_MAP_INFO(locationId, id),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
  });

export const fetchProductConfigInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_PRODUCT_CONFIG_INFO(locationId),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
  });

export const createProductConfiguration = (locationId, data) =>
  axiosClient({
    method: "POST",
    data,
    url: API_CREATE_PRODUCT_CONFIG_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateProductConfiguration = (
  locationId,
  data,
  logsBody,
  logsAppName,
) =>
  axiosClient({
    method: "PATCH",
    data,
    url: API_UPDATE_PRODUCT_CONFIG_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    logsBody,
    logsAppName,
    locationId,
  });

export const createMapProductInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: queryString.stringifyUrl(
      {
        url: API_MAP_PRODUCT_INFO_CREATE(locationId),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    data,
  });

export const updateMapProductInfo = (locationId, data) =>
  axiosClient({
    method: "PATCH",
    url: queryString.stringifyUrl(
      {
        url: API_MAP_PRODUCT_INFO_UPDATE(locationId),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    data,
  });

export const deleteMapProductInfo = (locationId, id) =>
  axiosClient({
    method: "DELETE",
    url: queryString.stringifyUrl(
      {
        url: API_MAP_PRODUCT_INFO_DELETE(locationId, id),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
  });

export const createDuplicateStrategyInfo = (locationId, data, id) =>
  axiosClient({
    method: "POST",
    url: queryString.stringifyUrl(
      {
        url: API_DUPLICATE_STRATEGY_INFO_CREATE(locationId, id),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    data,
  });

export const createStrategyInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: queryString.stringifyUrl(
      {
        url: API_STRATEGY_INFO_CREATE(locationId),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    data,
  });

export const updateStrategyInfo = (locationId, data) =>
  axiosClient({
    method: "PATCH",
    url: queryString.stringifyUrl(
      {
        url: API_STRATEGY_INFO_UPDATE(locationId),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    data,
  });

export const fetchSingleStrategyInfo = (locationId, id) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_STRATEGY_INFO_GET_SINGLE(locationId, id),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
  });
