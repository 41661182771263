import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { Theme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import { CapitalizeFirstLetter } from "../../../../utils/ReplaceIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridField: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "11px",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "36px",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        minHeight: "0px",
        justifyContent: "center",
        marginTop: "6px",
        paddingLeft: "0px",
      },
    },
    gridFieldLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9px",
      marginTop: "2px",
      marginBottom: "8px",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "36px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        minHeight: "0px",
        fontSize: "14px",
        justifyContent: "center",
      },
    },
    gridFieldLastColumnDisplayView: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      paddingLeft: "16px",
      textTransform: "none",
      minHeight: "36px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        fontSize: "14px",
        minHeight: "0px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
  }),
);

export interface SupplierInfoNodeProps {
  nodeData: any;
  handleOpenEditSupplierModal: any;
}

/* This component creates the data for the table using the data received from the API call.  */
const SupplierInfoNode: React.FunctionComponent<SupplierInfoNodeProps> = ({
  nodeData,
  handleOpenEditSupplierModal,
}) => {
  const [contactNo, setContactNo] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  /* Update states after getting the suplier information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const { contactNo, email, address, name } = nodeValues;
    setContactNo(contactNo);
    setName(name);
    setEmail(email);
    setAddress(address);
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const classes = useStyles();

  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Button
          style={{ width: "100%", margin: "0px", padding: "0px" }}
          onClick={(event: any) => handleOpenEditSupplierModal(nodeData)}
        >
          <Grid container>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={3}
              className={classes.gridFieldFirstColumn}
            >
              {CapitalizeFirstLetter(name)}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={3}
              className={classes.gridField}
            >
              {address}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={3}
              className={classes.gridField}
            >
              {email}
            </Grid>

            <Grid item xs={12} md={3} className={classes.gridFieldLastColumn}>
              {contactNo}
            </Grid>
          </Grid>
        </Button>
      </CardCommon>
    </>
  );
};

export default withAuthority(SupplierInfoNode, Authorities.INVENTORY_ADMIN_READ);
