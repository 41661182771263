import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import CardCommon from "../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = (dimensions: any) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      imgStyle: {
        borderRadius: "10px",
        border: `1px solid ${theme.palette.background.entity_border}`,
        width: "100%",
        height: "300px",
      },
      mapStyle: {
        paddingLeft: "12px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0px",
          marginTop: "8px",
        },
      },
      gridStyle: {
        paddingRight: "12px",
        [theme.breakpoints.down("xs")]: {
          paddingRight: "0px",
        },
      },
    }),
  );

export interface LocationDetailsProps {
  locationData: any;
}

/* This component displays 'location id', 'display name', 'business registration name', 
   and 'business trading name', and the 'location id' can be copied to the user. */
const AddressDetails: React.FunctionComponent<LocationDetailsProps> = ({
  locationData,
}) => {
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [web, setWeb] = useState("");
  const [addressFormatted, setAddressFormatted] = useState("");
  const targetRef: any = useRef();
  const [dimensions, setDimensions] = useState(0);

  /* Update the state using 'locationData' */
  useEffect(() => {
    const { email, contactNo, web, addressFormatted } = locationData;
    // If there is an e-mail, the e-mail value will be entered into the state.
    if (!_.isEmpty(email)) {
      setEmail(email);
    } else {
      // If there is no email, the state will be entered as 'N/A'.
      setEmail("N/A");
    }

    // If there is an contactNo, the contactNo value will be entered into the state.
    if (!_.isEmpty(contactNo)) {
      setContactNo(contactNo);
    } else {
      // If there is no contactNo, the state will be entered as 'N/A'.
      setContactNo("N/A");
    }

    // If there is an web, the web value will be entered into the state.
    if (!_.isEmpty(web)) {
      setWeb(web);
    } else {
      // If there is no web, the state will be entered as 'N/A'.
      setWeb("N/A");
    }

    // If there is an addressFormatted, the addressFormatted value will be entered into the state.
    if (!_.isEmpty(addressFormatted)) {
      setAddressFormatted(addressFormatted);
    } else {
      // If there is no addressFormatted, the state will be entered as 'N/A'.
      setAddressFormatted("N/A");
    }
  }, [locationData]);

  /* Getting the width of the component. */
  useLayoutEffect(() => {
    if (targetRef.current) {
      const width: number = targetRef.current.offsetWidth;
      setDimensions(width - 18);
    }
  }, []);

  const matches = useMediaQuery("(min-width:960px)");

  const classes = useStyles(dimensions)();

  return (
    <div style={{ marginTop: "24px" }}>
      <Grid container style={{ placeItems: "center" }}>
        <Grid item xs={12} sm={6} md={7} className={classes.gridStyle}>
          <CardCommon backgroundColor={"entity_background"}>
            <div style={{ margin: "16px 28px 16px 40px" }}>
              <div>
                <Typography align="left" variant="h6">
                  Public Email
                </Typography>
                <Typography
                  style={{
                    fontWeight: "lighter",
                    display: "flex",
                    justifyContent: "Start",
                  }}
                  align="left"
                  variant="body1"
                >
                  {email}
                </Typography>
              </div>
              <div style={{ marginTop: "16px" }}>
                <Typography align="left" variant="h6">
                  Public Contact No
                </Typography>
                <Typography
                  style={{
                    fontWeight: "lighter",
                    display: "flex",
                    justifyContent: "Start",
                  }}
                  align="left"
                  variant="body1"
                >
                  {contactNo}
                </Typography>
              </div>
              <div style={{ marginTop: "16px" }}>
                <Typography align="left" variant="h6">
                  Website
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  style={{
                    fontWeight: "lighter",
                    display: "flex",
                    justifyContent: "Start",
                  }}
                >
                  {web}
                </Typography>
              </div>
              <div style={{ marginTop: "16px" }}>
                <Typography align="left" variant="h6">
                  Address
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  style={{
                    fontWeight: "lighter",
                    display: "flex",
                    justifyContent: "Start",
                  }}
                >
                  {addressFormatted}
                </Typography>
              </div>
            </div>
          </CardCommon>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          className={classes.mapStyle}
          ref={targetRef}
        >
          <img
            className={classes.imgStyle}
            src={`https://maps.googleapis.com/maps/api/staticmap?zoom=12&size=${
              matches ? `${dimensions}x300` : `${dimensions + 18}x300`
            }&maptype=roadmap\
  &markers=size:mid%7Ccolor:red%7C${addressFormatted}CA&key=AIzaSyBV1dOjGuUbY-dNKDRl3KGA8mAWDZWcHig`}
            alt="Google Map of Albany, NY"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withAuthority(AddressDetails, Authorities.DASHBOARD_READ);
