import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation, useRouteMatch } from "react-router";
import { Box, useTheme } from "@material-ui/core";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllOrderInfo } from "../../../../services/eatprestoApp/orderInfoService";
import OrdersHeader from "./OrdersHeader";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { orderFilterObject } from "../../../../utils/consts/list";
import { CustomTheme } from "../../../../types/customTheme";
import SaleReport from "../../../../components/common/SaleReport";
import Pagination from "../../../../components/common/Pagination";
 
/**
 * Orders Component:
 * 
 * This component represents the page for managing orders. It fetches order information and location details from the server.
 */
const Orders: React.FunctionComponent<{}> = () => {
  const [orderNodeList, setOrderNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("");
  const [sortingMethod, setSortingMethod] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [pageSize, setPageSize] = useState("20");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [numberOfLocations, setNumberOfLocations] = useState(0);
  const [isSort, setIsSort] = useState(false);
  const [totalResult, setTotalResult] = useState(0);

  const match: any = useRouteMatch();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    const locationId: any = params.get("locationId");
    if (!_.isEmpty(locationId)) {
      const numUniqueLocations = locationId.split(",");
      setNumberOfLocations(numUniqueLocations.length);
    }
  }, [params.get("locationId")]);

  useEffect(() => {
    document.title = "Eat Presto - Order";
    getAllFilterLocation("");
  }, []);

  /* Get all order information using API call and response data set to orderNodeList state. */
  const getOrderInfo = async (
    pageSize: any,
    currentPage: any,
    sortingMethod: any,
    headerName: any,
    filterList: any,
  ) => {
    setPageSize(pageSize);
    setCurrentPage(currentPage);
    fetchAllOrderInfo(
      match.params.locationId,
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      filterList,
    )
      .then((res) => {
        setIsLoading(false);
        setOrderNodeList(res.data.data);
        setTotalPages(res.data.totalPages);
        setIsOpenSkeletonLoading(false);
        setTotalResult(res.data.totalResults)
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
        setIsLoading(false);
        setIsOpenSkeletonLoading(false);
      });
  };

  /* Get location information */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res: any) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) =>
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            }),
          );
        }
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  // Handle order information using selector
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);
    if (headerName) {
      getOrderInfo(pageSize, 1, sortingMethod, headerName, filterData);
    } else {
      getOrderInfo(pageSize, 1, sortingMethod, headerName, filterData);
    }
  };

  // Handle order information using page number
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    setIsOpenSkeletonLoading(true);
    getOrderInfo(pageSize, currentPage, sortingMethod, headerName, filterData);
  };

  // Sorting the order information using a header name
  const handleChangeOrderListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    setIsSort(true);
    setDisableButton(true);
    setHeaderName(headerName);
    setSortingMethod(sortingMethod);
    getOrderInfo(pageSize, currentPage, sortingMethod, headerName, filterData);
  };

  // Remove sorting method
  const handleRemoveOrderListSorting = () => {
    setIsSort(false);
    setDisableButton(false);
    setHeaderName("");
    setSortingMethod("");
    getOrderInfo(pageSize, currentPage, "", "", filterData);
  };

  // Get filter data
  const handleFilterData = (filterData: any) => {
    setIsOpenSkeletonLoading(true);
    setFilterData(filterData);
    getOrderInfo(20, 1, "", "", filterData);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {
    // setIsOpenSkeletonLoading(true);
    getAllFilterLocation(searchName);
  };

  const theme: CustomTheme = useTheme();

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          availableFilter={orderFilterObject}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          setFilterDetails={setFilterDetails}
          filterDetails={filterDetails}
          nodeList={orderNodeList}
          title={""}
          isChart={false}
          isGetLocationInfo={isGetLocationInfo}
          isOrder={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          topic="Orders"
          isReport={false}
          handleOnClickText={handleOnClickText}
        >
          <OrdersHeader
            handleChangeOrderListSorting={handleChangeOrderListSorting}
            handleRemoveOrderListSorting={handleRemoveOrderListSorting}
            nodeData={orderNodeList}
            disableButton={disableButton}
            isLoading={isLoading}
            locationSelectorList={locationSelectorList}
            handleOnClickText={handleOnClickText}
            locationSelectedList={locationSelectedList}
            numberOfLocations={numberOfLocations}
            isSort={isSort}
            headerName={headerName}
            sortingMethod={sortingMethod}
          />
          {!_.isEmpty(orderNodeList) && (
            <Pagination
              handleChangePaginationUsingSelector={
                handleChangePaginationUsingSelector
              }
              handleChangePaginationUsingPageNumber={
                handleChangePaginationUsingPageNumber
              }
              totalPages={totalPages}
              currentPage={currentPage}
              pageSize={pageSize}
              totalResult={totalResult}
            />
          )}
        </SaleReport>
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(Orders, Authorities.ORDERS_READ);
