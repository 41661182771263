import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSort } from "@table-library/react-table-library/sort";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Icon } from "@iconify/react";
import BreakDownOfShiftGenerateTable from "./BreakDownOfShiftGenerateTable";

export interface AvailabilitySelectionModalProps {
  shiftReportNode: any;
  handleBreakdownShift: any;
  isShowBreakdownShift: any;
}

/* This component will enter the required data for the Breakdown Shift table. */
const BreakDownOfShiftTableInfo: React.FunctionComponent<
  AvailabilitySelectionModalProps
> = ({ shiftReportNode, handleBreakdownShift, isShowBreakdownShift }) => {
  const [reportName, setReportName] = useState("");
  const [headersList, setHeadersList] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [nodesLength, setNodeLength] = useState(0);
  const [nodesHeadersLength, setNodesHeadersLength] = useState(0);
  const [firstColumnWidth, setFirstColumnWidth] = useState("0%");
  const [columnWidth, setColumnWidth] = useState("0%");
  const [sortFns, setSortFns] = useState<any>({});

  useEffect(() => {
    if (!_.isEmpty(shiftReportNode)) {
      const { header, data, tableName } = shiftReportNode;
      setReportName(tableName);
      setNodes({ nodes: data });
      setHeadersList(header);
      if (header.length > 4) {
        setFirstColumnWidth("28%");
        setColumnWidth(`${72 / (header.length - 1)}%`);
      } else {
        setFirstColumnWidth(`${100 / header.length}%`);
        setColumnWidth(`${100 / header.length}%`);
      }
      setNodesHeadersLength(header.length);
      setNodeLength(data.length - 1);

      let sortFns: any = {};
      header.map((data: any) => {
        sortFns[data] = (array: any) =>
          array.sort((a: any, b: any) =>
            typeof a[data] === "string" &&
            data !== "Vat" &&
            data !== "Total" &&
            data !== "VAT Rate"
              ? a[data].localeCompare(b[data])
              : a[data] - b[data],
          );
      });
      setSortFns(sortFns);
    } else {
      setNodes({ nodes: [] });
      setHeadersList([]);
    }
  }, [shiftReportNode.data]);

  const sort = useSort(
    nodes,
    {},
    {
      sortFns: sortFns,
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  return (
    <>
      <BreakDownOfShiftGenerateTable
        nodesLength={nodesLength}
        nodesHeadersLength={nodesHeadersLength}
        firstColumnWidth={firstColumnWidth}
        columnWidth={columnWidth}
        reportName={reportName}
        nodes={nodes}
        sort={sort}
        headersList={headersList}
        handleBreakdownShift={handleBreakdownShift}
        isShowBreakdownShift={isShowBreakdownShift}
      />
    </>
  );
};

export default BreakDownOfShiftTableInfo;
