import React, { useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";

import {
  EMAIL_PATTERN,
  EMAIL_PATTERN_INVENTORY,
  PHONE_NUMBER_PATTERN,
} from "../../../../utils/consts";
import TextfieldDefault from "../../../../components/textField/TextfieldDefault";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";

interface FormData {
  name: string;
  address: string;
  contactNo: string;
  email: string;
}

export interface supplierModalProps {
  isOpenSupplierModal: any;
  setIsOpenSupplierModal: any;
  isOpenSupplierEditModal: any;
  handleCreateNewSupplier: any;
  selectedSupplierNode: any;
  handleUpdateSupplier: any;
  isLoadingToast: boolean;
}

/**
 * This component provides a modal dialog for adding or editing supplier information.
 * It uses the react-hook-form library for form handling and validation.
 * The modal allows users to input and edit details such as name, address, contact number, and email.
 * When creating a new supplier, the "Save" button is used, and when editing, the "Update" button is used.
 * The component receives props that control the modal's visibility and behavior for adding or updating suppliers.
 */
const SupplierAddModal: React.FunctionComponent<supplierModalProps> = ({
  isOpenSupplierModal,
  setIsOpenSupplierModal,
  isOpenSupplierEditModal,
  handleCreateNewSupplier,
  selectedSupplierNode,
  handleUpdateSupplier,
  isLoadingToast,
}) => {
  const match: any = useRouteMatch();

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      address: "",
      contactNo: "",
      email: "",
    },
  });

  // useEffect to pre-fill form fields with selected supplier data when in edit mode
  useEffect(() => {
    // Check if the modal is in edit mode
    if (isOpenSupplierEditModal) {
      // Destructure the selected supplier data
      const { address, email, name, contactNo } = selectedSupplierNode;

      // Use the 'reset' function from react-hook-form to pre-fill form fields
      reset({
        address,
        email,
        name,
        contactNo,
      });
    }
  }, [isOpenSupplierModal]);

  // Define a submit handler function for the supplier form
  const handleSubmitSupplier = handleSubmit((data: any) => {
    // Clone the form data to ensure the original data is not modified
    const cloneData = _.cloneDeep(data);

    // Add the locationId to the cloned data using the 'match' object from react-router
    cloneData.locationId = match.params.locationId;

    // Check if the modal is in edit mode
    if (isOpenSupplierEditModal) {
      // Destructure the 'id' and 'version' from the selected supplier node
      const { id, version } = selectedSupplierNode;

      // Assign the 'id' and 'version' to the cloned data
      cloneData.id = id;
      cloneData.version = version;

      // Call the 'handleUpdateSupplier' function to update the supplier with the edited data
      handleUpdateSupplier(cloneData);
    } else {
      // If not in edit mode, call the 'handleCreateNewSupplier' function to create a new supplier
      handleCreateNewSupplier(cloneData);
    }
  });

  return (
    <DialogCommonDefault
      open={isOpenSupplierModal}
      setOpen={setIsOpenSupplierModal}
      isNeedFixedHeight={false}
    >
      <DialogTitle>Add New Supplier</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextfieldDefault
              id="name"
              name="name"
              type="text"
              label="Supplier Name"
              disabled={false}
              inputRef={register({
                required: "Name is required.",
              })}
            />
            {errors.name ? (
              <ValidationMessage message={errors.name.message} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <TextfieldDefault
              id="address"
              name="address"
              type="text"
              label="Address"
              inputRef={register({
                required: "Address is required.",
              })}
            />
            {errors.address ? (
              <ValidationMessage message={errors.address.message} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <TextfieldDefault
              id="contactNo"
              name="contactNo"
              type="number"
              label="Contact No"
              inputRef={register({
                required: true,
                pattern: PHONE_NUMBER_PATTERN,
              })}
            />
            {errors.contactNo ? (
              <ValidationMessage
                message={
                  errors.contactNo.type === "required"
                    ? "Contact No is required"
                    : "Please enter a valid Contact No"
                }
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <TextfieldDefault
              id="email"
              name="email"
              type="email"
              label="Email"
              inputRef={register({
                required: true,
                pattern: EMAIL_PATTERN_INVENTORY,
              })}
            />
            {errors.email ? (
              <ValidationMessage
                message={errors.email && "Please enter a valid email address"}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={() => {
            setIsOpenSupplierModal(false);
          }}
          disabled={isLoadingToast}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "14px",
          }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
        >
          Cancel
        </ButtonCommon>

        <ButtonCommon
          disabled={!isDirty || isLoadingToast}
          onClick={handleSubmitSupplier}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "14px",
          }}
          color={
            isOpenSupplierEditModal
              ? buttonColors.UPDATE_BUTTON_COLOR
              : buttonColors.CREATE_BUTTON_COLOR
          }
        >
          {isOpenSupplierEditModal ? "Update" : "Create"}
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default SupplierAddModal;
