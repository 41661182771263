import {
  Box,
  Button,
  Grid,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import ReplayIcon from "@material-ui/icons/Replay";
import Authorities from "../../../../../auth/authorities";
import withAuthority from "../../../../../components/Auth/withAuthority";
import CardCommon from "../../../../../components/card/CardCommon";
import TextfieldCommon from "../../../../../components/textField/TextfieldCommon";
import ValidationMessage from "../../../../../components/validation/ValidationMessage";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import _ from "lodash";

export interface MenuBasicInfoModalProps {
  register: any;
  errors: any;
  replayIconStyle: any;
  category: any;
  handleOpenBasicInfoRevertModal: any;
  isDirty: any;
  handleError: any;
  isLoadingPage: any;
  handleSubmitMenuBasicInfo: any;
}

/* This component shows the menu basic information. It show the menu title, description, size and price. */
const MenuBasicInfoModal: React.FunctionComponent<MenuBasicInfoModalProps> = ({
  register,
  errors,
  replayIconStyle,
  category,
  handleOpenBasicInfoRevertModal,
  isDirty,
  handleError,
  isLoadingPage,
  handleSubmitMenuBasicInfo,
}) => {
  return (
    <CardCommon backgroundColor={"entity_background"}>
      <Box>
        <form>
          <Grid container style={{ padding: "20px" }}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", placeItems: "center" }}
            >
              <TextfieldCommon
                id="title"
                name="title"
                label="Title"
                defaultValue={category.title}
                disabled={false}
                type="text"
                inputRef={register({
                  required: "Title is required",
                })}
                helperText={
                  errors.title ? (
                    <ValidationMessage message={errors.title.message} />
                  ) : (
                    ""
                  )
                }
              />
              <Tooltip title="Click to revert back to original value">
                <Button
                  disabled={category.originTitle === null}
                  style={{ display: "flex ", justifyContent: "end" }}
                >
                  <ReplayIcon
                    className={replayIconStyle(category.originTitle)}
                    onClick={() => {
                      handleOpenBasicInfoRevertModal("title", 0);
                    }}
                  />
                </Button>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", placeItems: "center", marginTop: "12px" }}
            >
              <TextfieldCommon
                id="desc"
                name="desc"
                label="Description"
                disabled={false}
                defaultValue={category.desc}
                type="text"
                inputRef={register({
                  required: false,
                })}
                rows={4}
                multiline={true}
              />
              <Tooltip title="Click to revert back to original value">
                <Button
                  disabled={category.originDesc === null}
                  style={{ display: "flex ", justifyContent: "end" }}
                >
                  <ReplayIcon
                    className={replayIconStyle(category.originDesc)}
                    onClick={() => {
                      handleOpenBasicInfoRevertModal("desc", 0);
                    }}
                  />
                </Button>
              </Tooltip>
            </Grid>

            {!_.isEmpty(category.data) &&
              category.data.map((multi: any, index: any) => {
                return (
                  <>
                    {category.itemType === "direct" && (
                      <Grid
                        item
                        xs={category.data.length > 1 ? 6 : 12}
                        style={{
                          display: "flex",
                          placeItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <TextfieldCommon
                          id={`size${index}`}
                          name={`size${index}`}
                          label="Size"
                          disabled={false}
                          type="text"
                          defaultValue={multi.size}
                          inputRef={register({
                            required: false,
                          })}
                        />
                        <Tooltip title="Click to revert back to original value">
                          <Button
                            disabled={_.isEmpty(
                              category.data[index].originSize,
                            )}
                            style={{
                              display: "flex ",
                              justifyContent: "end",
                            }}
                          >
                            <ReplayIcon
                              className={replayIconStyle(
                                category.data[index].originSize,
                              )}
                              onClick={() => {
                                handleOpenBasicInfoRevertModal("size", index);
                              }}
                            />
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={category.data.length > 1 ? 6 : 12}
                      style={{ display: "flex", marginTop: "4px" }}
                    >
                      <TextfieldCommon
                        id={`price${index}`}
                        name={`price${index}`}
                        label="Price"
                        disabled={false}
                        type="number"
                        defaultValue={multi.price}
                        inputRef={register({
                          required: "Price is required.",
                          min: {
                            value: 1,
                            message: "Minimum value is 1.",
                          },
                        })}
                        helperText={handleError()}
                      />
                      <Tooltip title="Click to revert back to original value">
                        <Button
                          disabled={category.data[index].originPrice === null}
                          style={{
                            display: "flex ",
                            justifyContent: "end",
                          }}
                        >
                          <ReplayIcon
                            className={replayIconStyle(
                              category.data[index].originPrice,
                            )}
                            onClick={() => {
                              handleOpenBasicInfoRevertModal("price", index);
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </>
                );
              })}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "16px",
              paddingRight: "28px",
            }}
          >
            <div style={{ width: "160px" }}>
              <ButtonCommon
                style={{
                  flex: 1,
                  fontSize: 11,
                  width: "160px",
                }}
                variant="contained"
                color={"green"}
                isLoadingPage={isLoadingPage}
                disabled={!isDirty}
                onClick={handleSubmitMenuBasicInfo}
              >
                {"Save changes"}
              </ButtonCommon>
            </div>
          </Grid>
        </form>
      </Box>
    </CardCommon>
  );
};

export default withAuthority(MenuBasicInfoModal, Authorities.MENU_READ);
