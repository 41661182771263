import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Tab,
  Tabs,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ListIcon from "@material-ui/icons/List";
import { Link } from "react-scroll";
import queryString from "query-string";

import SkeleTonLoading from "../Loading/SkeleTonLoading";
import PageHeaderMobile from "../PageHeader/PageHeaderMobile";
import ButtonCommon from "../../buttons/ButtonCommon";
import { buttonColors } from "../../../utils/enum";
import { CustomTheme } from "../../../types/customTheme";
import { getWindowDimensions } from "../../../utils/windowDimension";
import NoInventoryDataFound from "../NoDataFound/NoInventoryDataFound";
import AlertDialog from "../../alerts/AlertDialog";

const isAppEmbedded = queryString.parse(window.location.search).isAppEmbedded;

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    maxWidth:
      isAppEmbedded === "true" ? "calc(100vw - 380px)" : "calc(100vw - 360px)",
    [theme.breakpoints.down("xs")]: {
      maxWidth:
        isAppEmbedded === "true"
          ? "calc(100vw - 160px)"
          : "calc(100vw - 150px)",
    },
  },
  rootDesktop: {
    maxWidth: "calc(100vw - 628px)",
  },
  rootMobile: {
    maxWidth: "calc(100vw - 448px)",
  },
  textFieldOpen: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    borderRadius: "10px",
    border: "none",
    minWidth: "calc(100vw - 108px)",
    backgroundColor: theme.palette.background.entity_highlight_background,
    [`& fieldset`]: {
      borderRadius: "10px",
      border: "none",
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  textField: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    borderRadius: "10px",
    border: "none",
    minWidth: "200px",
    marginRight: "4px",
    backgroundColor: theme.palette.background.entity_highlight_background,
    [`& fieldset`]: {
      borderRadius: "10px",
      border: "none",
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  rootCenterTabs: {
    "& .MuiTabs-root": {},
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
  avatarStyle: {
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "54px",
    height: "54px",
    marginLeft: "4px",
  },
  menuStyleLg: {
    padding: "20px",
    borderRadius: "10px",
    marginRight: "14px",
    border: `1px solid ${theme.palette.background.entity_border}`,
    backgroundColor: theme.palette.background.imgBackground,
  },
  menuStyle: {
    top: "200px",
    padding: "20px",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_border}`,
    backgroundColor: theme.palette.background.imgBackground,
  },
  cardStyle: {
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
  },
  tabletScreen: {
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    zIndex: 5,
    padding: "0px 6px 16px 8px",
    marginTop: "0px",
  },
  mobileScreen: {
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    zIndex: 5,
    padding: "8px 26px 16px 12px",
    marginTop: "8px",
  },
  desktopModalOpen: {
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    zIndex: 5,
    padding: "2px 296px 16px 12px",
    marginTop: "6px",
  },
  desktopModalClose: {
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    zIndex: 5,
    padding: "2px 108px 16px 12px",
    marginTop: "6px",
  },
}));

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function evaluateArray(arr: any) {
  // Check if the array contains any false or empty objects
  return arr.every(
    (item: any) =>
      item === false ||
      (typeof item === "object" && Object.keys(item).length === 0),
  );
}

export interface InventoryDetailsProps {
  locationSelectorList?: any;
  isOpenSkeletonLoading: any;
  isGetLocationInfo?: any;
  topic?: any;
  children: any;
  handleOpenCreateModal?: any;
  buttonName?: any;
  handleChangeSearch?: any;
  searchNameTab?: any;
  setSearchNameTab?: any;
  isOpen?: any;
  setIsOpen?: any;
  activeGroupId?: any;
  setActiveGroupId?: any;
  selectedDepartmentId?: any;
  stockItemGroupDepartmentNodeInSelector?: any;
  isOpenDesktopModal?: any;
  setSelectedDepartmentId?: any;
  stockItemGroupDepartmentNodeInSelectorInitial?: any;
  createdId?: any;
  setCreatedId?: any;
  isStockTake?: any;
  searchedDepartmentList?: any;
  isActiveLocationId?: any;
  isWizard?: any;
  handleOpenWizardModal?: any;
}

/**
 * This React component represents a UI element for displaying inventory details.
 * It provides functionality for filtering and searching inventory items, as well as
 * selecting different inventory categories and departments. It also includes a mobile-
 * friendly interface and various responsive design features.
 */
const InventoryDetails: React.FunctionComponent<InventoryDetailsProps> = ({
  isOpenSkeletonLoading,
  topic,
  children,
  handleOpenCreateModal,
  buttonName,
  handleChangeSearch,
  searchNameTab,
  setSearchNameTab,
  isOpen,
  setIsOpen,
  activeGroupId,
  setActiveGroupId,
  selectedDepartmentId,
  stockItemGroupDepartmentNodeInSelector,
  isOpenDesktopModal,
  setSelectedDepartmentId,
  stockItemGroupDepartmentNodeInSelectorInitial,
  createdId,
  setCreatedId,
  isStockTake,
  searchedDepartmentList,
  isActiveLocationId,
  isWizard,
  handleOpenWizardModal,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const theme: CustomTheme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const tabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const open = Boolean(anchorEl);
  const classes = useStyles();

  /**
   * useEffect to handle setting active group and selected department.
   * This effect runs when 'createdId', 'activeGroupId', or 'stockItemGroupDepartmentNodeInSelector' change.
   */
  useEffect(() => {
    // Check if 'createdId' is not empty
    if (!_.isEmpty(createdId)) {
      // Loop through stock groups in 'stockItemGroupDepartmentNodeInSelector'
      stockItemGroupDepartmentNodeInSelector.map((group: any, index: any) => {
        // Check if the group ID matches the created ID's stock group ID
        if (group.id === createdId.stockDepartmentId.stockGroupId.id) {
          // Loop through departments within the group
          group.stockDepartmentId.map((department: any) => {
            // Check if the department ID matches the created ID's department ID
            if (department.id === createdId.stockDepartmentId.id) {
              // Loop through items within the department
              department.item.map((item: any) => {
                // Check if the item ID matches the created ID
                if (item.id === createdId.id) {
                  // Set the active group ID to the current index
                  setActiveGroupId(index);
                }
              });
            }
          });
        }
      });
    }

    // Set the selected department ID to the first department of the active group
    setSelectedDepartmentId(
      stockItemGroupDepartmentNodeInSelector[activeGroupId]
        ?.stockDepartmentId[0]?.id,
    );
  }, [activeGroupId, stockItemGroupDepartmentNodeInSelector]);

  /**
   * Handle change of the selected category menu.
   * Clears the created ID and sets the active group ID.
   * @param {any} val - The new value selected.
   * @param {any} catMenuIdx - The index of the selected category menu.
   */
  const handleChange = (val: any, catMenuIdx: any) => {
    // Clear the created ID array
    setCreatedId([]);
    // Set the active group ID based on the selected category menu index
    setActiveGroupId(catMenuIdx);
  };

  /**
   * Close the department selection popover and set the selected department ID.
   * @param {any} departmentData - The selected department data.
   */
  const handleCloseDepartmentPopover = (departmentData?: any) => {
    // Set the selected department ID
    setSelectedDepartmentId(departmentData?.id);
    // Close the popover
    setAnchorEl(null);
  };

  /**
   * Handle the click event for closing the department selection popover.
   * @param {any} event - The click event.
   */
  const handleClick = (e: any) => {
    // Close the popover
    setAnchorEl(null);
  };

  /**
   * Handle the click event for clearing the search input and closing the search.
   */
  const handleClearSearch = () => {
    if (searchNameTab || isOpen) {
      // Clear the search input
      handleChangeSearch("");
      // Reset the searchNameTab state
      setSearchNameTab("");
      // Close the search
      setIsOpen(false);
    }
  };

  /**
   * Opens the search input by setting the 'isOpen' state to true.
   */
  const handleOpenSearchInput = () => {
    setIsOpen(true);
  };

  /**
   * Determines the appropriate style for the root container based on screen size and modal state.
   * @returns {string} The CSS class name for the root container style.
   */
  const determineRootStyle = (): string => {
    if (tabletScreen) {
      if (mobileScreen) {
        return classes.tabletScreen;
      } else {
        return classes.mobileScreen;
      }
    } else if (isOpenDesktopModal) {
      return classes.desktopModalOpen;
    } else {
      return classes.desktopModalClose;
    }
  };

  /**
   * Determines the appropriate tab style based on screen size and modal state.
   * @returns {object} An object containing the CSS class for the tab style.
   */
  const determineTabStyle = (): object => {
    let rootStyle;

    if (stockItemGroupDepartmentNodeInSelectorInitial.length === 1) {
      // Use 'rootCenterTabs' class if there's only one item.
      rootStyle = classes.rootCenterTabs;
    } else if (tabletScreen) {
      // Use 'root' class for tablet screens.
      rootStyle = classes.root;
    } else if (isOpenDesktopModal) {
      // Use 'rootDesktop' class when the desktop modal is open.
      rootStyle = classes.rootDesktop;
    } else {
      // Use 'rootMobile' class for other cases (default)
      rootStyle = classes.rootMobile;
    }

    // Then use rootStyle in your component
    return {
      root: rootStyle,
    };
  };

  /**
   * Renders the tab view component for category menu items.
   * @returns {JSX.Element} The rendered tab view.
   */
  const TabView = (): JSX.Element => {
    return (
      <Tabs
        classes={determineTabStyle()}
        value={activeGroupId}
        onChange={handleChange}
        variant="scrollable"
        selectionFollowsFocus
        scrollButtons="on"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="scrollable category menu items list"
      >
        {!_.isEmpty(stockItemGroupDepartmentNodeInSelectorInitial) &&
          stockItemGroupDepartmentNodeInSelectorInitial.map(
            (group: any, idx: number) => (
              <Tab
                key={String(idx)}
                label={
                  <Typography style={{ fontSize: "14px" }}>
                    {group.name}
                  </Typography>
                }
                {...a11yProps(group)}
              />
            ),
          )}
      </Tabs>
    );
  };

  /**
   * Renders the view for the stock department pop-up.
   * @returns {JSX.Element[]} An array of JSX elements representing the pop-up view.
   */
  const renderStockDepartmentPopUpView = () => {
    if (!_.isEmpty(stockItemGroupDepartmentNodeInSelectorInitial)) {
      return stockItemGroupDepartmentNodeInSelectorInitial.map(
        (department: any, index: number) =>
          (index === activeGroupId || searchNameTab) &&
          department.stockDepartmentId.map(
            (departmentData: any) =>
              (!searchNameTab ||
                (searchNameTab &&
                  searchedDepartmentList.includes(departmentData.id))) && (
                <div
                  style={{
                    maxHeight: `${windowDimensions.height}px`,
                  }}
                  onClick={handleClick}
                >
                  <MenuItem
                    style={{
                      padding: "0px",
                      display: "flex",
                    }}
                  >
                    <Link
                      onClick={() =>
                        handleCloseDepartmentPopover(departmentData)
                      }
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        padding: "12px",
                      }}
                      activeClass="active"
                      to={departmentData.name}
                      spy={true}
                      smooth={true}
                      offset={tabletScreen ? -284 : -274}
                      duration={500}
                      delay={50}
                    >
                      <Typography
                        style={
                          selectedDepartmentId === departmentData.id
                            ? { fontWeight: "bold" }
                            : { fontWeight: "normal" }
                        }
                      >
                        {departmentData.name}
                      </Typography>
                    </Link>
                  </MenuItem>
                  <Divider />
                </div>
              ),
          ),
      );
    }
    return null; // Return null when there are no stock departments to display.
  };

  /**
   * Opens the department list popover when triggered by a click event.
   * @param {React.MouseEvent<HTMLElement>} event - The click event.
   */
  const handleOpenDepartmentListPopover = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    if (
      renderStockDepartmentPopUpView() &&
      !evaluateArray(renderStockDepartmentPopUpView().flat())
    ) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleRedirect = () => {
    window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/apps`;
  };

  const isAppEmbedded = queryString.parse(window.location.search).isAppEmbedded;

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={determineRootStyle()}>
              <Grid item xs={12}>
                <Hidden lgUp>
                  <PageHeaderMobile />
                </Hidden>
                {!isOpenSkeletonLoading && (
                  <Grid
                    container
                    style={{
                      marginTop: "36px",
                    }}
                  >
                    <Grid
                      item
                      xs={mobileScreen && isWizard ? 12 : isWizard?4:6}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <Typography
                        variant="h4"
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        color="secondary"
                      >
                        {isStockTake ? "Stock Take" : topic}
                      </Typography>
                    </Grid>
                    {!isStockTake &&
                      (isWizard ? (
                        mobileScreen ? (
                          <Grid
                            container
                            style={{
                              paddingRight: isAppEmbedded ==="true" ? "74px" : "0px",
                              marginTop: "12px",
                            }}
                          >
                            <Grid
                              item
                              xs={6}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              <ButtonCommon
                                style={{
                                  marginRight: 20,
                                  width: "210px",
                                }}
                                variant="contained"
                                color={buttonColors.CREATE_BUTTON_COLOR}
                                onClick={handleOpenWizardModal}
                              >
                                <Typography
                                  style={{
                                    textTransform: "none",
                                    color: "white",
                                  }}
                                >
                                  {"Stock Creation Wizard"}
                                </Typography>
                              </ButtonCommon>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <ButtonCommon
                                style={{
                                  marginRight: 4,
                                  width: "180px",
                                }}
                                variant="contained"
                                color={buttonColors.CREATE_BUTTON_COLOR}
                                onClick={handleOpenCreateModal}
                              >
                                <Typography
                                  style={{
                                    textTransform: "none",
                                    color: "white",
                                  }}
                                >
                                  {buttonName}
                                </Typography>
                              </ButtonCommon>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={8}
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: isAppEmbedded==="true"  ? "50px" : "0px",
                            }}
                          >
                            <ButtonCommon
                              style={{
                                marginRight: 20,
                                width: "210px",
                              }}
                              variant="contained"
                              color={buttonColors.CREATE_BUTTON_COLOR}
                              onClick={handleOpenWizardModal}
                            >
                              <Typography
                                style={{
                                  textTransform: "none",
                                  color: "white",
                                }}
                              >
                                {"Stock Creation Wizard"}
                              </Typography>
                            </ButtonCommon>
                            <ButtonCommon
                              style={{
                                marginRight: 4,
                                width: "180px",
                              }}
                              variant="contained"
                              color={buttonColors.CREATE_BUTTON_COLOR}
                              onClick={handleOpenCreateModal}
                            >
                              <Typography
                                style={{
                                  textTransform: "none",
                                  color: "white",
                                }}
                              >
                                {buttonName}
                              </Typography>
                            </ButtonCommon>
                          </Grid>
                        )
                      ) : (
                        <Grid
                          item
                          xs={isWizard ? 12 : 6}
                          style={
                            mobileScreen
                              ? {
                                  display: "flex",
                                  justifyContent: "end",
                                  paddingRight: "17px",
                                  marginRight: isWizard ? "20px" : "0px",
                                }
                              : {
                                  display: "flex",
                                  justifyContent: "end",
                                }
                          }
                        >
                          <ButtonCommon
                            style={{
                              marginRight: 4,
                              width: "180px",
                            }}
                            variant="contained"
                            color={buttonColors.CREATE_BUTTON_COLOR}
                            onClick={handleOpenCreateModal}
                          >
                            <Typography
                              style={{ textTransform: "none", color: "white" }}
                            >
                              {buttonName}
                            </Typography>
                          </ButtonCommon>
                        </Grid>
                      ))}
                  </Grid>
                )}
                {!isOpenSkeletonLoading && (
                  <Grid
                    container
                    style={{
                      marginTop: "12px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginRight: isAppEmbedded==="true" ? "20px" : "0px",
                      }}
                    >
                      {mobileScreen && !isOpen && TabView()}
                      <Hidden xsDown>
                        <div>{TabView()}</div>
                      </Hidden>
                      {mobileScreen && !isOpen && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            marginRight: isOpen ? "0px" : "19px",
                          }}
                        >
                          <SearchIcon
                            onClick={handleOpenSearchInput}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                          <Avatar
                            onClick={handleOpenDepartmentListPopover}
                            className={classes.avatarStyle}
                          >
                            <ListIcon
                              style={{
                                fontSize: "30px",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                            />
                          </Avatar>
                          <Popover
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleCloseDepartmentPopover}
                            onClick={handleCloseDepartmentPopover}
                            PaperProps={{
                              elevation: 0,
                              className: tabletScreen
                                ? classes.menuStyleLg
                                : classes.menuStyle,
                            }}
                            style={{
                              maxHeight: `${windowDimensions.height}px`,
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Card className={classes.cardStyle}>
                              {renderStockDepartmentPopUpView()}
                            </Card>
                          </Popover>
                        </div>
                      )}
                      {mobileScreen && isOpen && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            classes={{ root: classes.textFieldOpen }}
                            id="outlined-inline-static"
                            placeholder="Search for a item"
                            variant="outlined"
                            onChange={(e: any) =>
                              handleChangeSearch(e.target.value)
                            }
                            value={searchNameTab}
                            style={{
                              height: "40px",
                              marginTop: "4px",
                              marginBottom: "4px",
                              width: "!00%",
                            }}
                            fullWidth
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={handleClearSearch}
                                  >
                                    {isOpen ? <ClearIcon /> : <SearchIcon />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Avatar
                            onClick={handleOpenDepartmentListPopover}
                            className={classes.avatarStyle}
                          >
                            <ListIcon
                              style={{
                                fontSize: "30px",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                            />
                          </Avatar>
                          <Popover
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleCloseDepartmentPopover}
                            onClick={handleCloseDepartmentPopover}
                            PaperProps={{
                              elevation: 0,
                              className: tabletScreen
                                ? classes.menuStyleLg
                                : classes.menuStyle,
                            }}
                            style={{
                              maxHeight: `${windowDimensions.height}px`,
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Card className={classes.cardStyle}>
                              {renderStockDepartmentPopUpView()}
                            </Card>
                          </Popover>
                        </div>
                      )}
                      <Hidden xsDown>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            classes={{ root: classes.textField }}
                            id="outlined-inline-static"
                            placeholder="Search for a item"
                            variant="outlined"
                            onChange={(e: any) =>
                              handleChangeSearch(e.target.value)
                            }
                            value={searchNameTab}
                            style={{ width: "240px", height: "40px" }}
                            fullWidth
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={handleClearSearch}
                                  >
                                    {searchNameTab ? (
                                      <ClearIcon />
                                    ) : (
                                      <SearchIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Avatar
                            onClick={handleOpenDepartmentListPopover}
                            className={classes.avatarStyle}
                          >
                            <ListIcon
                              style={{
                                fontSize: "30px",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                            />
                          </Avatar>
                          <Popover
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleCloseDepartmentPopover}
                            onClick={handleCloseDepartmentPopover}
                            PaperProps={{
                              elevation: 0,
                              className: tabletScreen
                                ? classes.menuStyleLg
                                : classes.menuStyle,
                            }}
                            style={{
                              maxHeight: `${windowDimensions.height}px`,
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Card className={classes.cardStyle}>
                              {renderStockDepartmentPopUpView()}
                            </Card>
                          </Popover>
                        </div>
                      </Hidden>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!isOpenSkeletonLoading ? (
          <>
            {!_.isEmpty(stockItemGroupDepartmentNodeInSelectorInitial) ? (
              <div
                style={
                  tabletScreen
                    ? { padding: "8px 8px 8px 8px" }
                    : { padding: "8px 16px 8px 8px" }
                }
              >
                {children}
              </div>
            ) : (
              <div style={{ marginTop: "160px" }}>
                <NoInventoryDataFound title={topic} />
              </div>
            )}
          </>
        ) : (
          <div
            style={
              tabletScreen
                ? { marginTop: "20px", padding: "8px 4px 8px 8px" }
                : { marginTop: "20px", padding: "8px 16px 8px 8px" }
            }
          >
            <SkeleTonLoading isReport={false} />
          </div>
        )}
      </Box>
      {!isActiveLocationId && (
        <AlertDialog
          open={!isActiveLocationId}
          title="Activation Required"
          desc="Stock & inventory management is disabled on your account. Please contact your Presto Express account manager to request access."
          severity="error"
          confirmLabel="Back to Apps"
          disableBackdropClick
          confirmAction={handleRedirect}
          color="secondary"
        />
      )}
    </>
  );
};

export default InventoryDetails;
