import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import ReplayIcon from "@material-ui/icons/Replay";
import Authorities from "../../../../../auth/authorities";
import withAuthority from "../../../../../components/Auth/withAuthority";
import CardCommon from "../../../../../components/card/CardCommon";
import TextfieldCommon from "../../../../../components/textField/TextfieldCommon";
import ValidationMessage from "../../../../../components/validation/ValidationMessage";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    boxStyle: {
      backgroundColor: theme.palette.primary.main,
      minHeight: "60px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    replayButtonStyle: {
      display: "flex ",
      justifyContent: "end",
      "&:hover": {
        background: "none",
      },
    },
  }),
);

export interface CatMenuBasicInfoProps {
  register: any;
  errors: any;
  replayIconStyle: any;
  category: any;
  setIsOpenCatMenuRevertModal: any;
  isDirty: any;
  isLoadingPageCatMenu: any;
  handleSubmitCatMenu: any;
}

/* This component shows the cat menu basic information. It show the catMenu title */
const CatMenuBasicInfo: React.FunctionComponent<CatMenuBasicInfoProps> = ({
  register,
  errors,
  replayIconStyle,
  category,
  setIsOpenCatMenuRevertModal,
  isDirty,
  isLoadingPageCatMenu,
  handleSubmitCatMenu,
}) => {
  const classes = useStyles();
  return (
    <CardCommon backgroundColor={"entity_background"}>
      <Box>
        <form>
          <Grid container style={{ padding: "20px" }}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", placeItems: "center" }}
            >
              <TextfieldCommon
                id="title"
                name="title"
                label="Title"
                disabled={false}
                type="text"
                inputRef={register({
                  required: "Title is required",
                })}
                helperText={
                  errors.title ? (
                    <ValidationMessage message={errors.title.message} />
                  ) : (
                    ""
                  )
                }
              />
              <Tooltip title="Click to revert back to original value">
                <Button
                  disabled={category.originTitle === null}
                  className={classes.replayButtonStyle}
                >
                  <ReplayIcon
                    className={replayIconStyle(category.originTitle)}
                    onClick={() => setIsOpenCatMenuRevertModal(true)}
                  />
                </Button>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "16px",
                paddingRight: "18px",
                marginTop: "16px",
              }}
            >
              <div style={{ width: "160px" }}>
                <ButtonCommon
                  style={{
                    flex: 1,
                    fontSize: 11,
                    width: "160px",
                  }}
                  variant="contained"
                  defaultValue={category.title}
                  color={"green"}
                  disabled={!isDirty}
                  isLoadingPage={isLoadingPageCatMenu}
                  onClick={handleSubmitCatMenu}
                >
                  {"Rename"}
                </ButtonCommon>
              </div>
            </Grid>
          </Grid>
        </form>
      </Box>
    </CardCommon>
  );
};

export default withAuthority(CatMenuBasicInfo, Authorities.MENU_READ);
