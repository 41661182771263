import { Grid } from "@material-ui/core";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import React, { useState } from "react";

import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import WithLoading from "../../../../utils/WithLoading";
import StrategyInfoNode from "./StrategyInfoNode";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import StrategyAddEditModal from "./StrategyAddEditModal";
import {
  createDuplicateStrategyInfo,
  createStrategyInfo,
  fetchSingleStrategyInfo,
  updateStrategyInfo,
} from "../../../../services/menuApp/printerMenuService";
import {
  combineMinuteOfWeekArrays,
  normalizeTimeSelections,
} from "../../../../utils/time-conversions";
import { HttpStatus } from "../../../../utils/enum";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import AlertDialogDefault from "../../../../components/alerts/AlertDialogDefault";
import FilterCustom from "../../../../components/common/FilterCustom";
import { saveLogs } from "../../../../utils/firebase/logs";
import {
  isEqualArrayObject,
  isEqualArrays,
} from "../../../../utils/checkArrayEqual";

export interface StrategyPageLoadingProps {
  nodes: Array<any>;
  isLoading: boolean;
  setError: any;
  getStrategyInfo: any;
}

/**
 * Component: StrategyPageLoading
 *
 * This component represents the loading state of the menu strategies page.
 * It handles the rendering of menu strategy nodes, including their creation, editing, duplication, activation, and deactivation.
 */
const StrategyPageLoading: React.FunctionComponent<
  StrategyPageLoadingProps
> = ({ nodes, isLoading, setError, getStrategyInfo }) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [strategyName, setStrategyName] = useState("");
  const [strategyNameInitial, setStrategyNameInitial] = useState("");
  const [deliveryTypesList, setDeliveryTypesList] = useState<any>([]);
  const [deliveryTypesListInitial, setDeliveryTypesListInitial] = useState<any>(
    [],
  );
  const [availability, setAvailability] = useState<any>({});
  const [availabilityInitial, setAvailabilityInitial] = useState<any>({});
  const [availabilityErrorMessage, setAvailabilityErrorMessage] = useState("");
  const [isDeliveryTypeEmpty, setIsDeliveryTypeEmpty] = useState(false);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isAvailabilityEmpty, setIsAvailabilityEmpty] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingButtonDeactivate, setIsLoadingButtonDeactivate] =
    useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [id, setId] = useState("");
  const [version, setVersion] = useState(0);
  const [nodeData, setNodeData] = useState<any>([]);
  const [success, setSuccess] = useState("");
  const [openFilterCard, setOpenFilterCard] = useState();
  const [activeInactiveSelectedList, setActiveInactiveSelectedList] =
    useState<any>([]);

  const match: any = useRouteMatch();

  /**
   * Function: handleReset
   *
   * This function resets all state variables related to discount strategy editing and creation to their initial values.
   */
  const handleReset = () => {
    setIsEdit(false);
    setIsDuplicate(false);
    setIsOpenEditModal(false);
    setStrategyName("");
    setDeliveryTypesList([]);
    setDeliveryTypesListInitial([]);
    setAvailability({});
    setAvailabilityInitial({});
    setIsLoadingButtonDeactivate([]);
    setIsLoadingButton(false);
    setIsNameEmpty(false);
    setIsDeliveryTypeEmpty(false);
    setNodeData([]);
    setId("");
    setVersion(0);
    setStrategyNameInitial("");
    setIsAvailabilityEmpty(false);
  };

  /**
   * Function: handleOpenAddStrategyModal
   * This function is called to open the modal for adding a new strategy.
   * It initializes the state variables related to the strategy editing modal and sets the availability to an empty array.
   */
  const handleOpenAddStrategyModal = () => {
    setIsEdit(false);
    setIsDuplicate(false);
    setIsOpenEditModal(true);
    setAvailability({ availability: [] });
    setAvailabilityInitial({ availability: [] });
  };

  /**
   * Function: handleOpenEditModal
   * This function is called to open the modal for editing an existing strategy.
   * It initializes the state variables related to the strategy editing modal with the values from the provided nodeData object.
   */
  const handleOpenEditModal = (nodeData: any) => {
    setIsEdit(true);
    setIsDuplicate(false);
    setAvailability({ availability: nodeData.availability });
    setAvailabilityInitial({ availability: nodeData.availability });
    setStrategyName(nodeData.name);
    setStrategyNameInitial(nodeData.name);
    setDeliveryTypesList(nodeData.deliveryTypes);
    setDeliveryTypesListInitial(nodeData.deliveryTypes);
    setIsOpenEditModal(true);
    setId(nodeData.id);
    setVersion(nodeData.version);
  };

  /**
   * Function: handleOpenDuplicateEditModal
   * This function is called to open the modal for duplicating and editing an existing strategy.
   * It initializes the state variables related to the strategy editing modal with the values from the provided nodeData object,
   * with modifications to indicate that it's a duplication operation.
   */
  const handleOpenDuplicateEditModal = (nodeData: any) => {
    setAvailability({ availability: nodeData.availability });
    setAvailabilityInitial({ availability: nodeData.availability });
    setStrategyName(`Copy of ${nodeData.name}`);
    setStrategyNameInitial(nodeData.name);
    setDeliveryTypesList(nodeData.deliveryTypes);
    setDeliveryTypesListInitial(nodeData.deliveryTypes);
    setIsOpenEditModal(true);
    setId(nodeData.id);
    setVersion(nodeData.version);
  };

  /**
   * Function: handleOpenConfirmationModal
   *
   * This function is called to open the confirmation modal for duplicating a strategy.
   * It sets the state variables to indicate that it's a duplication operation and sets the nodeData object for further processing.
   */
  const handleOpenConfirmationModal = (nodeData: any) => {
    setIsEdit(false);
    setIsDuplicate(true);
    setNodeData(nodeData);
  };

  /**
   * Function: handleCreateNewStrategy
   *
   * This function is called to create a new strategy by making an asynchronous API call to createStrategyInfo.
   * Upon successful creation, it updates the strategy information, resets the form, and displays a success message.
   * If there's an error during the process, it handles the error by setting an error message and resetting the loading state.
   */
  const handleCreateNewStrategy = async (strategyInfo: any) => {
    try {
      await createStrategyInfo(match.params.locationId, strategyInfo);
      // Upon successful creation, update the strategy information displayed on the page by calling getStrategyInfo.
      getStrategyInfo();

      // Reset the form fields and state variables to their initial values.
      handleReset();

      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);

      let summary = [];
        summary.push(
          `Created strategy name is ${strategyInfo.name}`,
        );

        summary.push(
          `Created delivery type is ${deliveryTypesList}`,
        );

        const currentAvailabilityString = JSON.stringify(
          availability.availability,
        );

        summary.push(
          `Created availability is ${currentAvailabilityString}`,
        );
      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Mapping Strategy Information Changed",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "menu",
        match.params.locationId,
      );
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function: handleDuplicateStrategy
   *
   * This function is responsible for duplicating an existing strategy.
   * It makes an asynchronous API call to createDuplicateStrategyInfo to duplicate the strategy information on the server.
   * Upon successful duplication, it updates the strategy information displayed on the page, resets the form fields, and
   * displays a success message. If an error occurs during the process, it handles the error by setting an error message and
   * resetting the loading state.
   */
  const handleDuplicateStrategy = async (strategyInfo: any, id: any) => {
    try {
      await createDuplicateStrategyInfo(
        match.params.locationId,
        strategyInfo,
        id,
      );
      // Upon successful duplication, update the strategy information displayed on the page by calling getStrategyInfo.
      getStrategyInfo();

      // Reset the form fields and state variables to their initial values.
      handleReset();

      // Set the success message to indicate that the strategy was duplicated successfully.
      setSuccess(SUCCESSFULLY_CREATED);

      let summary = [];
      if (strategyName !== strategyNameInitial) {
        summary.push(
          `Strategy duplicated by ${strategyName}`,
        );
      }

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Mapping Strategy Information Changed",
          summary: summaryString,
          type: "info",
          code: "duplicate",
          metadata: { changedBy: "" },
        },
        "menu",
        match.params.locationId,
      );
    } catch (error) {
      // If an error occurs during the duplication process, handle the error by setting isLoadingButton to false and displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);

      // Set isLoadingButton to false again in case it was set to true before catching the error.
      setIsLoadingButton(false);
    }
  };

  /**
   * Function: getSingleStrategyInfo
   *
   * This function retrieves the latest version of a single strategy information from the server.
   * It makes an asynchronous API call to fetchSingleStrategyInfo with the locationId and strategy ID parameters.
   * Upon receiving the response, it updates the version of the strategy information and
   * calls handleUpdateNewStrategy to handle the updated strategy information. If an error occurs during the process,
   * it sets an error message.
   */
  const getSingleStrategyInfo = async (
    strategyInfo: any,
    editOrDeactivate: any,
  ) => {
    try {
      const res = await fetchSingleStrategyInfo(
        match.params.locationId,
        id || strategyInfo.id,
      );
      // Update the version of the strategy information with the latest version received from the server response.
      setVersion(res.data.data.version);

      // Update the version property of the strategyInfo object to match the latest version.
      strategyInfo.version = res.data.data.version;

      // Call handleUpdateNewStrategy to handle the updated strategy information.
      handleUpdateNewStrategy(strategyInfo, editOrDeactivate);
    } catch (error) {
      // If an error occurs during the process, set the error message to indicate the unexpected error.
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function: handleUpdateNewStrategy
   *
   * This function handles the update of a new strategy information. It makes an asynchronous API call to updateStrategyInfo with
   * the locationId and strategyInfo parameters. Upon receiving the response, it checks if the status is HttpStatus.CONFLICT_409,
   * indicating a conflict in the update process. If a conflict occurs, it retrieves the latest version of the
   * strategy information using getSingleStrategyInfo and retries the update. If no conflict occurs,
   * it triggers the getStrategyInfo function to refresh the strategy information list, resets the form using handleReset,
   * and sets a success message indicating the successful update. If an error occurs during the process, it sets an error message.
   */
  const handleUpdateNewStrategy = async (
    strategyInfo: any,
    editOrDeactivate: any,
  ) => {
    try {
      const res = await updateStrategyInfo(
        match.params.locationId,
        strategyInfo,
      );

      // Check if the status of the response indicates a conflict (HTTP 409).
      if (res.data.status === HttpStatus.CONFLICT_409) {
        // If a conflict occurs, retrieve the latest version of the strategy information and retry the update.
        getSingleStrategyInfo(strategyInfo, editOrDeactivate);
      } else {
        // If no conflict occurs, trigger the getStrategyInfo function to refresh the strategy information list.
        getStrategyInfo();

        // Reset the form.
        handleReset();

        // Set a success message indicating the successful update.
        setSuccess(SUCCESSFULLY_UPDATED);
        if (editOrDeactivate === "deactivate") {
          const summaryString = `${
            strategyInfo.name
          } Valid changed from ${!strategyInfo.valid} to ${strategyInfo.valid}`;
          saveLogs(
            {
              message: "Mapping Strategy Information Changed",
              summary: summaryString,
              type: "warning",
              code: "deactivate",
              metadata: { changedBy: "" },
            },
            "menu",
            match.params.locationId,
          );
        }

        if (editOrDeactivate === "edit") {
          let summary = [];
          if (strategyName !== strategyNameInitial) {
            summary.push(
              `${strategyNameInitial} strategy name changed from ${strategyNameInitial} to ${strategyName}`,
            );
          }

          if (!isEqualArrays(deliveryTypesList, deliveryTypesListInitial)) {
            summary.push(
              `${strategyNameInitial} Delivery type changed from ${deliveryTypesListInitial.join(
                ", ",
              )} to ${deliveryTypesList.join(", ")}`,
            );
          }

          if (
            !isEqualArrayObject(
              availability.availability,
              availabilityInitial.availability,
            )
          ) {
            const initialAvailabilityString = JSON.stringify(
              availabilityInitial.availability,
            );
            const currentAvailabilityString = JSON.stringify(
              availability.availability,
            );

            summary.push(
              `${strategyNameInitial} Availability changed from ${initialAvailabilityString} to ${currentAvailabilityString}`,
            );
          }
          const summaryString = summary.join("\\n");
          saveLogs(
            {
              message: "Mapping Strategy Information Changed",
              summary: summaryString,
              type: "info",
              code: "update",
              metadata: { changedBy: "" },
            },
            "menu",
            match.params.locationId,
          );
        }
      }
    } catch (error) {
      // If an error occurs during the process, set the error message to indicate the unexpected error.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function: handleSubmit
   *
   * This function handles the submission of strategy information. It constructs a strategyInfo object containing the name,
   * locationId, deliveryTypes, and availability based on the current form data. It validates the form fields and
   * sets corresponding state variables to indicate any empty fields. If the form is valid,
   * it triggers the appropriate action based on the form mode (Edit, Duplicate, or Create New). If in Edit mode,
   * it updates the existing strategy using handleUpdateNewStrategy. If in Duplicate mode,
   * it creates a duplicate strategy using handleDuplicateStrategy. If in Create New mode,
   * it creates a new strategy using handleCreateNewStrategy.
   */
  const handleSubmit = () => {
    // Construct the strategy information object.
    const createStrategyInfo: any = {
      name: strategyName,
      locationId: match.params.locationId,
      deliveryTypes: deliveryTypesList,
      availability: availability.availability,
    };

    // Validate form fields and set corresponding state variables if empty.
    if (_.isEmpty(strategyName) || _.isEmpty(deliveryTypesList)) {
      if (_.isEmpty(strategyName)) {
        setIsNameEmpty(true);
      }
      if (_.isEmpty(deliveryTypesList)) {
        setIsDeliveryTypeEmpty(true);
      }
    }
    // If form is valid, trigger appropriate action based on form mode.
    else {
      setIsLoadingButton(true);

      // If in Edit mode, update the existing strategy.
      if (isEdit) {
        createStrategyInfo.id = id;
        createStrategyInfo.version = version;
        handleUpdateNewStrategy(createStrategyInfo, "edit");
      }
      // If in Duplicate mode, create a duplicate strategy.
      else if (isDuplicate) {
        handleDuplicateStrategy(createStrategyInfo, id);
      }
      // If in Create New mode, create a new strategy.
      else {
        handleCreateNewStrategy(createStrategyInfo);
      }
    }
  };

  /**
   * Function: handleDeactivateStrategy
   *
   * This function handles the deactivation of a strategy.
   * It updates the validity status of the strategy by toggling the 'valid' property.
   * It sets the loading state for the deactivate button associated with the strategy to indicate the deactivation process.
   */
  const handleDeactivateStrategy = (nodeData: any, valid: any) => {
    // Set loading state for the deactivate button associated with the strategy.
    setIsLoadingButtonDeactivate((prevState: any) => [
      ...prevState,
      nodeData.id,
    ]);

    // Create a deep clone of the nodeData object to prevent mutation.
    const cloneData = _.cloneDeep(nodeData);

    // Toggle the validity status of the strategy.
    cloneData.valid = !valid;

    // Trigger the update of the strategy with the modified validity status.
    handleUpdateNewStrategy(cloneData, "deactivate");
  };

  /* Send an API call to after click availability apply button. 
  An API call is sent only if the status is not "create". */
  const handleSubmitAvailability = async (
    availabilitySelectionList: Array<any>,
  ) => {
    setIsAvailabilityEmpty(false);
    setError("");
    /* availabilitySelectionList = [{openTime: '11:49', closeTime: '11:49', id: 2, 
                                         nodeList: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                                    {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]}]
           combinedMowArrays = {tue: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                thu:  {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]} */
    const combinedMowArrays: any = combineMinuteOfWeekArrays(
      availabilitySelectionList,
    );
    const normalizedArray = _.cloneDeep(combinedMowArrays);

    // Remove select same date range in same same date.
    Object.keys(combinedMowArrays).forEach((day) => {
      normalizedArray[day] = normalizeTimeSelections(normalizedArray[day]);
    });

    // Convert array to minute of week array
    const mowArray: any = Object.values(normalizedArray).flat();
    setAvailability({ availability: mowArray });
  };

  return (
    <>
      <Grid container spacing={2} style={{ placeItems: "center" }}>
        <Grid item xs={4} lg={3}>
          <FilterCustom
            openFilterCard={openFilterCard}
            setOpenFilterCard={setOpenFilterCard}
            activeInactiveSelectedList={activeInactiveSelectedList}
            setActiveInactiveSelectedList={setActiveInactiveSelectedList}
          />
        </Grid>
        <Grid
          item
          xs={8}
          lg={9}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <ButtonCommon
            style={{
              fontSize: 11,
              width: "160px",
              height: "40px",
            }}
            variant="contained"
            color="green"
            onClick={handleOpenAddStrategyModal}
          >
            Add New Strategy
          </ButtonCommon>
        </Grid>
        {!_.isEmpty(nodes) &&
          nodes.map((nodeData: any) =>
            _.isEmpty(activeInactiveSelectedList) ||
            activeInactiveSelectedList.length === 2 ? (
              <Grid item xs={12} key={nodeData.id} style={{ display: "flex" }}>
                <StrategyInfoNode
                  nodeData={nodeData}
                  handleOpenEditModal={handleOpenEditModal}
                  handleDeactivateStrategy={handleDeactivateStrategy}
                  isLoadingButtonDeactivate={isLoadingButtonDeactivate}
                  handleOpenConfirmationModal={handleOpenConfirmationModal}
                />
              </Grid>
            ) : activeInactiveSelectedList[0]?.id === "active" ? (
              nodeData.valid && (
                <Grid
                  item
                  xs={12}
                  key={nodeData.id}
                  style={{ display: "flex" }}
                >
                  <StrategyInfoNode
                    nodeData={nodeData}
                    handleOpenEditModal={handleOpenEditModal}
                    handleDeactivateStrategy={handleDeactivateStrategy}
                    isLoadingButtonDeactivate={isLoadingButtonDeactivate}
                    handleOpenConfirmationModal={handleOpenConfirmationModal}
                  />
                </Grid>
              )
            ) : (
              !nodeData.valid && (
                <Grid
                  item
                  xs={12}
                  key={nodeData.id}
                  style={{ display: "flex" }}
                >
                  <StrategyInfoNode
                    nodeData={nodeData}
                    handleOpenEditModal={handleOpenEditModal}
                    handleDeactivateStrategy={handleDeactivateStrategy}
                    isLoadingButtonDeactivate={isLoadingButtonDeactivate}
                    handleOpenConfirmationModal={handleOpenConfirmationModal}
                  />
                </Grid>
              )
            ),
          )}
      </Grid>
      {isOpenEditModal && (
        <StrategyAddEditModal
          isOpen={isOpenEditModal}
          setIsOpen={setIsOpenEditModal}
          setStrategyName={setStrategyName}
          strategyName={strategyName}
          deliveryTypesList={deliveryTypesList}
          setDeliveryTypesList={setDeliveryTypesList}
          handleSubmit={handleSubmit}
          availability={availability}
          handleSubmitAvailability={handleSubmitAvailability}
          setAvailabilityErrorMessage={setAvailabilityErrorMessage}
          isDeliveryTypeEmpty={isDeliveryTypeEmpty}
          setIsNameEmpty={setIsNameEmpty}
          setIsDeliveryTypeEmpty={setIsDeliveryTypeEmpty}
          isNameEmpty={isNameEmpty}
          isAvailabilityEmpty={isAvailabilityEmpty}
          isLoadingButton={isLoadingButton}
          isEdit={isEdit}
          isDuplicate={isDuplicate}
          handleReset={handleReset}
          deliveryTypesListInitial={deliveryTypesListInitial}
          availabilityInitial={availabilityInitial}
          strategyNameInitial={strategyNameInitial}
        />
      )}
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <AlertDialogDefault
        open={isDuplicate}
        confirmAction={() => handleOpenDuplicateEditModal(nodeData)}
        cancelAction={() => setIsDuplicate(false)}
        title="Confirm Printer Mapping Duplication"
        desc="Are you sure you want to duplicate this printer mapping strategy? All associated category and item printer mappings will also be duplicated."
        severity="warning"
        confirmLabel="Confirm Duplicate"
        cancelLabel="cancel"
        color="secondary"
        disableBackdropClick
        isLoading={isLoadingButton}
      />
    </>
  );
};

export default WithLoading(StrategyPageLoading);
