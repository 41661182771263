import {
  createStyles,
  Grid,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import _ from "lodash";
import ValidationMessage from "../../validation/ValidationMessage";
import TextfieldCommon from "../../textField/TextfieldCommon";
import { CustomTheme } from "../../../types/customTheme";

export interface GoogleMapProps {
  address: any;
  isValidated: any;
  handleChangeLocationAddress?: any;
  isNeedTextBox?: any;
  height?: any;
}

const useStyles = (height: any) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      imgStyle: {
        borderRadius: "10px",
        border: `1px solid ${theme.palette.background.entity_border}`,
        width: "100%",
        height: `${height}px`,
        [theme.breakpoints.down("xs")]: {
          height: "250px",
        },
      },
    }),
  );

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const mapApiJs = process.env.REACT_APP_GOOGLE_MAP_URL;

// load google map api js
function loadAsyncScript(src: any) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

/* Setting the address given by Google map according to our needs. */
const extractAddress = (place: any) => {
  const address = {
    postcode: "",
    country: "",
    county: "",
    buildingNoOrName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    addressFormatted: "",
    lat: "",
    lon: "",
  };

  /* Returns the initial address object if 'address_components' does not exist. */
  if (!Array.isArray(place?.address_components)) {
    return address;
  } else {
    /* If the 'address_components' is there, it iterates. */
    place.address_components.forEach((component: any) => {
      const types = component.types;
      const value = component.long_name;
      // If the type is 'premise', 'subpremise', or 'street_number', that value is entered for 'buildingNoOrName'.
      if (
        types.includes("premise") ||
        types.includes("subpremise") ||
        types.includes("street_number")
      ) {
        address.buildingNoOrName = value;
      }

      // If the type is 'route', that value is entered for 'addressLine1'.
      if (types.includes("route")) {
        address.addressLine1 = value;
      }
      // If the type is route and street_address'.
      if (types.includes("route") && types.includes("street_address")) {
        // If the type is 'route', that value is entered for 'addressLine1'.
        if (types.includes("route")) {
          address.addressLine1 = value;
        }
        // If the type is 'street_address', that value is entered for 'addressLine2'.
        if (types.includes("street_address")) {
          address.addressLine2 = value;
        }
      } else if (types.includes("route") && !types.includes("street_address")) {
        // If the type is 'route' and is not 'street_address', that value is entered for 'addressLine1'.
        address.addressLine1 = value;
      } else if (!types.includes("route") && types.includes("street_address")) {
        // If the type is not 'route' and is 'street_address', that value is entered for 'addressLine1'.
        address.addressLine1 = value;
      }

      // If the type is not 'administrative_area_level_2', that value is entered for 'county'.
      if (types.includes("administrative_area_level_2")) {
        address.county = value;
      }

      // If the type is not 'locality' or 'postal_town', that value is entered for 'city'.
      if (types.includes("locality") || types.includes("postal_town")) {
        address.city = value;
      }

      // If the type is not 'postal_code', that value is entered for 'postcode'.
      if (types.includes("postal_code")) {
        address.postcode = value;
      }

      // If the type is not 'country', that value is entered for 'country'.
      if (types.includes("country")) {
        address.country = value;
      }
    });

    address.addressFormatted = place.formatted_address;
    address.lat = place.geometry.location.lat();
    address.lon = place.geometry.location.lng();
  }

  return address;
};

/* To get the input location from Google Maps, the API call is sent 
   and the data obtained from the API call is processed by this component.  */
const GoogleMap: React.FunctionComponent<GoogleMapProps> = ({
  address,
  isValidated,
  handleChangeLocationAddress,
  isNeedTextBox,
  height,
}) => {
  const searchInput = useRef<any>();
  const [isSearchLocation, setIsSearchLocation] = useState(false);
  const [width, setWidth] = useState(0);
  const targetRef: any = useRef();

  /* Get the component width. */
  useLayoutEffect(() => {
    if (targetRef.current) {
      const width = targetRef.current.offsetWidth;
      setWidth(width);
    }
  }, []);

  // init map script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete: any) => {
    const place = autocomplete.getPlace();
    setIsSearchLocation(true);
    handleChangeLocationAddress(extractAddress(place));
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current,
    );

    autocomplete.setFields([
      "address_component",
      "formatted_address",
      "geometry",
    ]);

    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete),
    );
  };

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, [initAutocomplete]);

  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles(height)();

  return (
    <Grid container>
      {isNeedTextBox && (
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <TextfieldCommon
            inputRef={searchInput}
            id="googleMap"
            name="googleMap"
            type="text"
            margin="normal"
            style={{ width: "100%", borderRadius: "10Ppx" }}
            onChange={() => setIsSearchLocation(true)}
            value={
              !_.isEmpty(address) && !isSearchLocation
                ? address.addressFormatted
                : null
            }
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "start", marginLeft: "28px" }}
      >
        {_.isEmpty(address) && isValidated && (
          <ValidationMessage message={"This field is required."} />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        style={
          isNeedTextBox
            ? {
                marginTop: "8px",
                display: "flex",
                justifyContent: "center",
              }
            : {
                display: "flex",
                justifyContent: "center",
              }
        }
        ref={targetRef}
      >
        {!_.isEmpty(address) && (
          <img
            className={classes.imgStyle}
            src={`https://maps.googleapis.com/maps/api/staticmap?zoom=12&size=${
              matches ? `${width}x${height}` : `${width}x250`
            }&maptype=roadmap\
  &markers=size:mid%7Ccolor:red%7C${
    address.addressFormatted
  }CA&key=AIzaSyBV1dOjGuUbY-dNKDRl3KGA8mAWDZWcHig`}
            alt="Google Map of Albany, NY"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default GoogleMap;
