import React from "react";
import withAuthority from "../../../Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import { Icon } from "@iconify/react";
import CancelIcon from "@material-ui/icons/Cancel";

export interface InputBoxDefaultProps {
  setIsEdit: any;
  isEdit: any;
}

/* This is a component renders an edit icon or a cancel icon based on the isEdit prop. 
  This component is wrapped with the withAuthority HOC which checks if the user has the 
  authority to edit a sale payment type using the Authorities.SALE_PAYMENT_TYPE_UPDATE_UPDATE constant. */
const EditSalePaymentType: React.FunctionComponent<InputBoxDefaultProps> = ({
  setIsEdit,
  isEdit,
}) => {
  return isEdit ? (
    <CancelIcon
      style={{ cursor: "pointer" }}
      onClick={() => setIsEdit(false)}
    />
  ) : (
    <Icon
      icon="bx:edit"
      style={{ cursor: "pointer" }}
      width="24px"
      height="24px"
      onClick={() => {
        setIsEdit(true);
      }}
    />
  );
};

export default withAuthority(
  EditSalePaymentType,
  Authorities.SALE_PAYMENT_TYPE_UPDATE_UPDATE,
);
