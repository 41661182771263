import { Box, createStyles, Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";
import React from "react";

import { CustomTheme } from "../../../../../types/customTheme";
import CardCommon from "../../../../../components/card/CardCommon";

const useStyles = makeStyles<any>((theme: CustomTheme) =>
  createStyles({
    root: {
      minWidth: "150px",
      [theme.breakpoints.down("xs")]: {
        minWidth: "112px",
      },
    },
    modeTitleStyle: {
      backgroundColor:
        theme.palette.background.entity_highlight_background_mode_title,
      color: theme.palette.custom.orange.contrastText,
      height: "60px",
      display: "flex",
      justifyContent: "start",
      paddingLeft: "12px",
      fontSize: "14px",
      paddingTop: "33px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        paddingTop: "36px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px",
      },
    },
    prevTitleStyle: {
      backgroundColor:
        theme.palette.background.entity_highlight_background_mode_prev,
      color: theme.palette.custom.orange.contrastText,
      height: "60px",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        paddingTop: "4px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px",
      },
    },
    currentTitleStyle: {
      backgroundColor:
        theme.palette.background.entity_highlight_background_mode_current,
      color: theme.palette.custom.orange.contrastText,
      height: "60px",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        paddingTop: "4px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px",
      },
    },
    modeBodyStyle: {
      backgroundColor:
        theme.palette.background.entity_highlight_background_body_mode,
      color: theme.palette.custom.orange.contrastText,
      height: "40px",
      alignItems: "center",
      paddingTop: "8px",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        paddingTop: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px",
      },
    },
    prevBodyStyle: {
      backgroundColor: theme.palette.background.entity_highlight_background,
      color: theme.palette.custom.orange.contrastText,
      paddingTop: "10px",
      fontSize: "14px",
      height: "40px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        paddingTop: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px",
      },
    },
    currentBodyStyle: {
      backgroundColor:
        theme.palette.background.entity_highlight_background_body_current,
      color: theme.palette.custom.orange.contrastText,
      paddingTop: "10px",
      fontSize: "14px",
      height: "40px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        paddingTop: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px",
      },
    },
    modeFooterStyle: {
      backgroundColor:
        theme.palette.background.entity_highlight_background_mode_footer,
      color: theme.palette.custom.orange.contrastText,
      height: "40px",
      paddingTop: "8px",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        paddingTop: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px",
      },
    },
    prevFooterStyle: {
      backgroundColor:
        theme.palette.background.entity_highlight_background_prev_footer,
      color: theme.palette.custom.orange.contrastText,
      height: "40px",
      paddingTop: "10px",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        paddingTop: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px",
      },
    },
    currentFooterStyle: {
      backgroundColor:
        theme.palette.background.entity_highlight_background_current_footer,
      color: theme.palette.custom.orange.contrastText,
      height: "40px",
      paddingTop: "10px",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        paddingTop: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px",
      },
    },
    boxStyle: {
      height: "2px",
      width: "100%",
      marginTop: "8px",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        marginTop: "10px",
      },
      [theme.breakpoints.down("xs")]: {},
    },
    subTitle: {
      color: theme.palette.custom.orange.contrastText,
      marginTop: "6px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "12px",
      },
    },
  }),
);

export interface ModeTableProps {
  modeList: any;
}

/*
 * This component, ModeTable, is a React functional component responsible for rendering a table
 * displaying mode data. It receives a prop modeList, an array containing mode data objects.
 * The component utilizes Material-UI's Grid, Box, and makeStyles to structure and style the table.
 * It also utilizes a custom CardCommon component for styling.
 * The component is divided into sections: root, mode title, mode body, mode footer,
 * previous period title, previous period body, previous period footer,
 * current period title, and current period body, current period footer.
 * Each section has its corresponding styles defined using makeStyles.
 * The component maps over the modeList array to render mode data in the table.
 * It calculates and displays previous and current period data such as orders, discount, and sales.
 * The styles are responsive and adjust based on the screen size using breakpoints.
 */
const ModeTable: React.FunctionComponent<ModeTableProps> = ({ modeList }) => {
  const classes = useStyles();
  return (
    <CardCommon backgroundColor="entity_background">
      <div style={{ display: "flex" }}>
        <div className={classes.root}>
          <div className={classes.modeTitleStyle}>MODE</div>
          <div>
            {modeList.map((data: any, index: number) => {
              return (
                <div
                  className={
                    index + 1 === modeList.length
                      ? classes.modeFooterStyle
                      : classes.modeBodyStyle
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      paddingLeft: "12px",
                    }}
                  >
                    {data.mode.id.replace("_", " ").toUpperCase()}
                  </div>
                  <Box
                    className={classes.boxStyle}
                    style={{
                      backgroundColor: data.mode.color,
                    }}
                  ></Box>
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
          <Grid container style={{ minWidth: "440px" }}>
            <Grid item xs={6} className={classes.prevTitleStyle}>
              <Grid item xs={12} style={{ marginTop: "6px" }}>
                PREVIOUS PERIOD
              </Grid>
              <Grid container>
                <Grid item xs={4} className={classes.subTitle}>
                  ORDERS
                </Grid>
                <Grid item xs={4} className={classes.subTitle}>
                  DISCOUNT
                </Grid>
                <Grid item xs={4} className={classes.subTitle}>
                  SALES
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.currentTitleStyle}>
              <Grid item xs={12} style={{ marginTop: "6px" }}>
                CURRENT PERIOD
              </Grid>
              <Grid container>
                <Grid item xs={4} className={classes.subTitle}>
                  ORDERS
                </Grid>
                <Grid item xs={4} className={classes.subTitle}>
                  DISCOUNT
                </Grid>
                <Grid item xs={4} className={classes.subTitle}>
                  SALES
                </Grid>
              </Grid>
            </Grid>
            {modeList.map((data: any, index: number) => {
              return (
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    className={
                      index + 1 === modeList.length
                        ? classes.prevFooterStyle
                        : classes.prevBodyStyle
                    }
                  >
                    <Grid container>
                      <Grid item xs={4}>
                        {data.prevOrder}
                      </Grid>
                      <Grid item xs={4}>
                        {parseFloat(data.prevDiscount).toFixed(2)}
                      </Grid>
                      <Grid item xs={4}>
                        {parseFloat(data.prevSales).toFixed(2)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={
                      index + 1 === modeList.length
                        ? classes.currentFooterStyle
                        : classes.currentBodyStyle
                    }
                  >
                    <Grid container>
                      <Grid item xs={4}>
                        {data.currentOrder}
                      </Grid>
                      <Grid item xs={4}>
                        {parseFloat(data.currentDiscount).toFixed(2)}
                      </Grid>
                      <Grid item xs={4}>
                        {parseFloat(data.currentSales).toFixed(2)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </CardCommon>
  );
};

export default ModeTable;
