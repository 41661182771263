import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import {
  deconstructMinuteOfWeekArray,
  getOpenCloseTimesList,
} from "../../../utils/time-conversions";
import EditOpeningTimesModal from "../EditOpeningTimesDialog";
import _ from "lodash";
import Switch from "@material-ui/core/Switch";
import CardCommon from "../../card/CardCommon";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 12,
    padding: 16,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "self-start",
    flex: 1,
  },
  radioBtn: {
    display: "flex",
  },
  daysSection: {
    display: "flex",
  },
  days: {
    marginRight: 16,
  },
}));

export interface OpeningTimeProps {
  handleSelectOpeningSchedule: any;
  option: any;
  locationId: any;
  fetchOpeningTimes: any;
}

/* Handle opening time UI */
const OpeningTime: React.FunctionComponent<OpeningTimeProps> = ({
  handleSelectOpeningSchedule,
  option,
  locationId,
  fetchOpeningTimes,
}) => {
  const classes = useStyles();

  const openCloseTimes = getOpenCloseTimesList(option.weekly);
  const deconstructedMinuteOfWeekArray = deconstructMinuteOfWeekArray(
    option.weekly,
    openCloseTimes,
  );
  return (
    <CardCommon
      backgroundColor={"entity_highlight_background"}
      style={{ margin: "8px 0" }}
    >
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.daysSection}>
            {deconstructedMinuteOfWeekArray.map((node) => (
              <div className={classes.days} key={node.toString()}>
                <Typography variant={"subtitle2"}>
                  {node.nodeList
                    .map((openTimeNode: any) => _.startCase(openTimeNode.day))
                    .join(", ")}
                </Typography>
                <Typography variant={"body2"}>
                  {node.openTime} - {node.closeTime}
                </Typography>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.radioBtn}>
          <EditOpeningTimesModal
            openingTimesSchedule={option}
            locationId={locationId}
            fetchOpeningTimes={fetchOpeningTimes}
          />
          <Switch
            checked={option.isActive}
            onChange={() => handleSelectOpeningSchedule(option)}
            name={option.id}
            value={option.id}
            inputProps={{ "aria-label": "toggle" }}
          />
        </div>
      </div>
    </CardCommon>
  );
};

export default OpeningTime;
