import React from "react";
import { Grid, Typography } from "@material-ui/core";

export interface VoidSummeryProps {
  filterLocation: any;
  filterDate: any
}

const SaleSubTitle: React.FunctionComponent<VoidSummeryProps> = ({
  filterLocation,
  filterDate
}) => {
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item>
          <Typography variant="caption" component="div">
            Item Sales report for:{" "}
            <span style={{ fontWeight: "bold", paddingLeft: "4px" }}>
              {filterLocation}
            </span>
            <div>
              {"Generated for the period of " + filterDate}
            </div>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default SaleSubTitle;
