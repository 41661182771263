import { axiosClient } from "../client";

const queryString = require("query-string");

const SALE_DISCOUNT_SUMMARY_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/reports/sales/discount`;

export const fetchAllDiscountSummaryReportInfo = (
  token,
  locationId,
  filterData,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: SALE_DISCOUNT_SUMMARY_INFO_GET_ALL(locationId),
        query: { where: filterData },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
