import { createStyles, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import _ from "lodash";

import CategoryMenuListWrapper from "./CategoryMenuListWrapper";
import CardCommon from "../../../../components/card/CardCommon";
import CategoryMenuList from "./CategoryMenuList";

export interface CategoryMenuListProps {
  removedLinkedIdLists: any;
  wrapper: any;
  handleOpenProductEditModal: any;
  handleOpenMappingModal: any;
  category: any;
  isLoadProduct: any;
  getProductWrapperInfo: any;
  menuNode: any;
  categoryNode: any;
  setSuccess: any;
  productVersion: any;
  item: any;
  data: any;
  menuNodeList: any;
  setMenuNodeList: any;
  wrapperId: any;
  menuId: any;
  categoryId: any;
  firstWrapperId: any;
  isRoot: any;
}

/**
 * ProductDirectory Component: Category Menu List
 *
 * This component represents a list item within a category menu. It displays information about an item, including its name,
 * image (if available), and description. Users can interact with the item to view more details or perform actions.
 */
const ProductDirectory: React.FunctionComponent<CategoryMenuListProps> = ({
  removedLinkedIdLists,
  wrapper,
  handleOpenProductEditModal,
  handleOpenMappingModal,
  category,
  isLoadProduct,
  getProductWrapperInfo,
  menuNode,
  categoryNode,
  setSuccess,
  productVersion,
  item,
  data,
  menuNodeList,
  setMenuNodeList,
  wrapperId,
  menuId,
  categoryId,
  firstWrapperId,
  isRoot,
}) => {
  return (
    <>
      {removedLinkedIdLists[wrapper.id.toString()]?.directoryLevel ===
        item.directoryLevel &&
        removedLinkedIdLists[wrapper.id.toString()]?.id === item.id && (
          <Grid item md={6} xs={12} lg={4}>
            <CardCommon
              backgroundColor={"default"}
              style={{
                borderRadius: "10px",
              }}
            >
              <CategoryMenuList
                item={data}
                handleOpenProductEditModal={handleOpenProductEditModal}
                handleOpenMappingModal={handleOpenMappingModal}
                category={category}
                wrapper={wrapper}
                isLoadProduct={isLoadProduct}
                getProductWrapperInfo={getProductWrapperInfo}
                menuNode={menuNode}
                categoryNode={categoryNode}
                setSuccess={setSuccess}
                isOpenWrapperEditModal={false}
                productVersion={productVersion}
                menuNodeList={menuNodeList}
                setMenuNodeList={setMenuNodeList}
                wrapperId={wrapperId}
                menuId={menuId}
                categoryId={categoryId}
                firstWrapperId={firstWrapperId}
                isRoot={isRoot}
              />
            </CardCommon>
          </Grid>
        )}
    </>
  );
};

export default ProductDirectory;
