import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import CardCommon from "../../../../components/card/CardCommon";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  PASSWORD_ERROR_CODE,
  USER_ERROR_CODE,
} from "../../../../utils/consts";
import firebase from "../../../../utils/firebase";
import GoogleSignIn from "./GoogleSignIn";
import allBrands from "../../../../assets/images/allBrands.webp";
import commonThemeDark from "../../../../root/theme/dark";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import backgroundImage from "../../../../assets/images/background.webp";
import FullScreenLoading from "../../../../components/common/Loading/FullScreenLoading";
import { verifyAuthTokenWithServer } from "../../../../services/accountApp/authService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    root: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      alignItems: "center",
    },
    form: {
      padding: "16px 20px 20px 20px",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("sm")]: {
        padding: "16px 60px 40px 60px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "16px 80px 60px 80px",
      },
    },
  }),
);

export interface SignInPageProps {}

/* This component show the sign in page.
   When the user provides the username and password, if the data is correct, it redirects to the app details page. */
const SignInPage: React.FunctionComponent<SignInPageProps> = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  
  const history = useHistory();
  const classes = useStyles();

  /* Return the login error message. */
  const errorHandle = (err: string) => {
    switch (err) {
      // Password incorrect
      case PASSWORD_ERROR_CODE:
        setError("Password is incorrect. Please try again.");
        break;
      // Username incorrect
      case USER_ERROR_CODE:
        setError("User not fount. Please enter correct user.");
        break;
      default:
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
        break;
    }
  };

  /* Check whether the username and password are correct or not. */
  const handleSubmit = () => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        setIsLoading(true);
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(function (result) {
            // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
            firebase.auth().onAuthStateChanged((firebaseUser) => {
              if (firebaseUser) {
                
                firebaseUser
                  .getIdToken(true)
                  .then(function (idToken) {
                    // Get the login details
                    verifyAuthTokenWithServer(
                      idToken,
                      firebaseUser,
                      firebaseUser.refreshToken,
                    );
                    setTimeout(() => {
                      setIsLoading(false);
                      history.push("/accounts/apps");
                    }, 2000);
                  })
                  
              }
            });
        
          })
          .catch(function (error) {
            setIsLoading(false);
            try {
              errorHandle(error.code);
            } catch (error) {
              setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
            }
          });
      })
      .catch((error) => {
        // var errorCode = error.code;
        // var errorMessage = error.message;
        setIsLoading(false);
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  return (
    <>
      <div
        className={classes.root}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "48px",
              letterSpacing: "5.44px",
              color: "white",
            }}
          >
            PRESTO PORTAL
          </Typography>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Grid item xs={12} sm={9} md={8}>
              <CardCommon backgroundColor={"entity_background"}>
                <FullScreenLoading open={isLoading} />

                <div className={classes.form}>
                  <Typography
                    variant="h5"
                    style={{ marginBottom: "8px", fontWeight: "bold" }}
                  >
                    SIGN-IN
                  </Typography>
                  <TextfieldCommon
                    id="sign-email-input"
                    name="sign-email-input"
                    style={{ width: "100%" }}
                    label="Email"
                    onChange={(e: any) => setEmail(e.target.value)}
                    value={email}
                    type="text"
                  />
                  <span style={{ height: 16 }} />
                  <TextfieldCommon
                    id="sign-password-input"
                    name="sign-password-input"
                    label="Password"
                    onChange={(e: any) => setPassword(e.target.value)}
                    value={password}
                    type="password"
                  />
                  <span style={{ height: 16 }} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "8px",
                    }}
                  >
                    <ButtonCommon
                      variant="contained"
                      style={{
                        flex: 1,
                        fontSize: 16,
                        backgroundColor:
                          commonThemeDark.palette.custom.blue.dark,
                        color: "white",
                      }}
                      size={"large"}
                      onClick={handleSubmit}
                    >
                      Sign In
                    </ButtonCommon>
                  </div>
                </div>
              </CardCommon>
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "12px",
            }}
          >
            <Grid item xs={12} sm={9} md={8}>
              <CardCommon backgroundColor={"entity_background"}>
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <GoogleSignIn />
                </div>
              </CardCommon>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "32px",
            }}
          >
            <Grid item xs={12} sm={9} md={8}>
              <img
                width="100%"
                src={allBrands}
                alt="not available brand images"
                onError={({ currentTarget }) => {
                  currentTarget.src =
                    "https://via.placeholder.com/295x295?text=Image+Not+Available";
                }}
              />
            </Grid>
          </Grid>
        </div>
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </div>
    </>
  );
};

export default SignInPage;
