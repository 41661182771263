import { jsPDF } from "jspdf";
import "jspdf-autotable";
import _ from "lodash";
import moment from "moment";
import eatprestoLogo from "../../../../../assets/images/logos/logo.png";
import { ExportPdfColor } from "../../../../../root/theme/exportColor";

const handleNodeAndFooterLength = (key: any, nodes: any) => {
  let nodeLength = 0;
  if (key === "cancelled-receipts") {
    nodeLength = nodes["cancelledSaleNodesLength"];
  } else if (key === "void-transaction") {
    nodeLength = nodes["voidTransactionNodesLength"];
  } else if (key === "paid-in-out") {
    nodeLength = nodes["paidInOutNodesLength"];
  } else {
    nodeLength = 0;
  }
  return { nodeLength };
};

const handleConvertPdf = (
  doc: any,
  filterDetails: any,
  totalPagesExp: any,
  eatprestoLogoImg: any,
) => {
  const locationFilter = filterDetails.split("*")[0];
  const pageWidth = doc.internal.pageSize.getWidth();
  /* Create a pdf file. */
  doc.autoTable({
    // Add topic and sub topics.
    // Add topic and sub topics.
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();
      // Change topic font size
      doc.setFontSize(12);
      doc.text("Presto Express Solutions", data.settings.margin.left, 12);
      // Modify the marginLeft according to the scape.
      doc.text("Stock Movements Reports", data.settings.margin.left + 131, 12);

      // Change main title font size
      doc.setFontSize(16);
      // Insert a topic for the first page only.
      if (doc.internal.getNumberOfPages() === 1) {
        /* Change the title according to the number of locations.
        If the location size is increased, show presto express. if the location size is the one, show that location. */
        doc.setTextColor("#32363E");
        if (locationFilter.includes(",")) {
        } else {
          doc.text(
            filterDetails
              .split("*")[0]
              .replace("Stock Movements summary report for location/s:", ""),
            pageWidth / 2,
            36,
            {
              align: "center",
            },
          );
        }

        doc.setTextColor(0, 0, 0);
        doc.text(
          "Stock Movements Report",
          pageWidth / 2,
          36 + 10,
          "center",
        );
        doc.setFontSize(10);

        /* Change the sub title according to the number of locations.
        If the location size is increased, show number of locations. if the location size is the one, show that location. */
        let filterLocationsDetails = "";
        if (locationFilter.split(",").length === 1) {
          filterLocationsDetails =
            "Stock Movements Summary Report for " + locationFilter.split(",")[0];
        } else {
          filterLocationsDetails =
            "Stock Movements Summary Report for " +
            locationFilter.split(",").length +
            " Locations";
        }

        doc.text(
          doc.splitTextToSize(filterLocationsDetails, 210 - 15 - 15),
          pageWidth / 2,
          44 + 10,
          {
            align: "center",
          },
        );

        /* Set the start date and end date */
        doc.text(
          doc.splitTextToSize(
            "Generated for the period of " + filterDetails.split("*")[1],
            210 - 15 - 15,
          ),
          pageWidth / 2,
          50 + 10,
          {
            align: "center",
          },
        );
      }

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);
      doc.addImage(
        eatprestoLogoImg,
        "JPEG",
        data.settings.margin.left + 144,
        pageHeight - 24,
        40,
        16,
      );
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });
};

const handleTable = (
  pdfHeader: any,
  doc: any,
  nodes: any,
  startY: any,
  lastIndex: any,
  nodeLength: any,
) => {
  const columnLength: number = 9;
  const pageWidth = doc.internal.pageSize.getWidth();
  /* Create a new table. */
  doc.autoTable({
    columns: pdfHeader.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    showHead: "never",
    startY: startY,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 7,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: { fontStyle: "bold", halign: "left" },
      [columnLength]: { halign: "right" },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColor.OddRowColor],
      textColor: ["#ffffff"],
    },
    tableLineWidth: 0.1,

     /* Change the backgroundcolor in the row. */
     didParseCell: function (data: any, index: any) {
      if (data.column.index === 10) {
        data.cell.styles.halign = "right";
      }
      if (data.row.raw.isGroup) {
        data.cell.styles.fillColor = [ExportPdfColor.green];
        data.cell.styles.textColor = ["#ffffff"];
        data.cell.styles.fontStyle = "bold";
      } else if (data.row.raw.isDepartment) {
        data.cell.styles.fillColor = [ExportPdfColor.yellow];
        data.cell.styles.textColor = ["#000000"];
        data.cell.styles.fontStyle = "bold";
      } else {
        if (data.row.index % 2 === 0) {
          data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
          data.cell.styles.textColor = ["#ffffff"];
        } else {
          data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
          data.cell.styles.textColor = ["#ffffff"];
        }
      }
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });
};

/* Create a pdf file */
export const handleDownloadPdf = (filterDetails: any, pdfHeader: any, nodes: any) => {
  const doc: any = new jsPDF("p");
  const totalPagesExp = "{total_pages_count_string}";

  const today = moment().format("MMM_Do_YY").toString();

  /* If there is a image, insert it into the eatprestoLogoImg variable. */
  const eatprestoLogoImg = new Image();
  eatprestoLogoImg.src = eatprestoLogo;

  let startY = 65;

  /* Iterating table data */
  nodes.forEach((value: any) => {
    if (!_.isEmpty(value)) {
      // If the height of the previous table is greater than 240, a new page is added.
      if (startY > 240) {
        doc.addPage();
        startY = 30;
      }

      handleConvertPdf(doc, filterDetails, totalPagesExp, eatprestoLogoImg);
      let data: any = []
      if(value.isGroup || value.isDepartment) {
        data.push(value)
      } else {
        data = value.pdfData
      }

      handleTable(
        pdfHeader,
        doc,
        data,
        startY + 1,
        100,
        data.length,
      );
      startY = doc.lastAutoTable.finalY + 1;
    }
  });

  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(`stock_movements_report_${today}_${Math.floor(100000 + Math.random() * 900000)}.pdf`);
};
