import * as React from "react";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 34,
      height: 20,
      padding: 0,
      margin: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(1),
        width: 36,
        height: 22,
      },
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: theme.palette.custom.green.main,
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 17,
      height: 17,
      [theme.breakpoints.up("sm")]: {
        width: 19,
        height: 19,
      },
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }),
);

export interface SwitchCommonProps {
  props?: any;
}

const SwitchButtonResponsive: React.FunctionComponent<SwitchCommonProps> = (
  props?: any,
) => {
  const classes = useStyles();

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export default SwitchButtonResponsive;
