import React, { useCallback, useEffect, useState } from "react";
import { Box, Hidden } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";

import authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { replaceWENwithWEDdeliveryOptions_DEPRECATED } from "../../../../utils/time-conversions";
import DeliveryTypes from "./DeliveryTypes";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllDeliveryInfo } from "../../../../services/eatprestoApp/deliveryInfoService";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";

/**
 * DeliveryInfo Component:
 * 
 * This component represents the delivery information page, displaying delivery options
 * and providing functionality to update them.
 */
const DeliveryInfo: React.FunctionComponent<{}> = () => {
  const [deliveryNodeList, setDeliveryNodeList] = useState<any>([]);
  const [isLoadingButton, setIsLoadingButton] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const match: any = useRouteMatch();

  /* Get all delivery information using API call and response data set to deliveryNodeList state. */
  const getDeliveryInfo = useCallback(async () => {
    try {
      const res = await fetchAllDeliveryInfo(match.params.locationId);
      const deliveryOptions = replaceWENwithWEDdeliveryOptions_DEPRECATED(
        res.data.data,
      );
      setDeliveryNodeList(deliveryOptions);
      setIsLoadingButton("");
      setIsLoading(false);
    } catch (err) {
      setIsLoadingButton("");
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  }, [match.params.locationId]);

  useEffect(() => {
    document.title = "Eat Presto - Delivery Info";
    getDeliveryInfo();
  }, [getDeliveryInfo]);


  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box style={{ marginTop: "18px" }}>
        <DeliveryTypes
          nodes={deliveryNodeList}
          getDeliveryInfo={getDeliveryInfo}
          setIsLoadingButton={setIsLoadingButton}
          isLoadingButton={isLoadingButton}
          isLoading={isLoading}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(DeliveryInfo, authorities.DELIVERY_INFO_READ);
