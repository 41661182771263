import Axios from "axios";
import { axiosClient } from "../client";
const queryString = require("query-string");

const API_LOCATIONS_GET_ALL = `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}?page=1&size=20000`;

const LOCATIONS_INFO_CREATE = `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}`;

const API_ROLLS_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}/location/${locationId}/role`;

  const USER_DETAILS_GET_ALL = () =>
  `${process.env.REACT_APP_USER_CREATION_AND_INFO_RETRIEVAL}`;

  const GET_SINGLE_USER_DETAIL = (accountId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_ACCOUNTS}/account/${accountId}`;

const PUBLIC_LOCATIONS_INFO_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}/public/location/${locationId}`;

export const fetchAllRoles = (locationId, pageSize, currentPage) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_ROLLS_GET_ALL(locationId),
        query: { size: pageSize, page: currentPage },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const getUserDetailsUsingAccountId = (accountId) => {
    return axiosClient({
      method: "GET",
      url: GET_SINGLE_USER_DETAIL(accountId),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  export const getUserDetails = (data) => {
    return axiosClient({
      method: "POST",
      url: USER_DETAILS_GET_ALL(),
      data,
    });
  };

export const createRoles = (locationId, data) => {
  return axiosClient({
    method: "POST",
    url: API_ROLLS_GET_ALL(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    data,
  });
};

export const fetchAllLocations = (token) =>
  axiosClient({
    url: API_LOCATIONS_GET_ALL,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const createLocationsInfo = (data) =>
  axiosClient({
    url: LOCATIONS_INFO_CREATE,
    method: "POST",
    data,
  });

export const getPublicLocationInfo = (locationId) =>
  Axios({
    url: PUBLIC_LOCATIONS_INFO_CREATE(locationId),
    method: "GET",
  });
