import React, { useState } from "react";
import _ from "lodash";
import LocationCreate from "./LocationCreate";
import { EMAIL_PATTERN, PHONE_NUMBER_PATTERN } from "../../../utils/consts";

export interface CreateNewNodeProps {
  handleCreateLocation: any;
  open: any;
  setOpen: any;
  address: any;
  setAddress: any;
  handleChangeLocationData: any;
  locationData: any;
  setLocationData: any;
}

// Location initial data
const locationDataInitial = {
  businessDisplayName: "",
  businessRegName: "",
  businessTradingName: "",
  locationIdentifier: "",
  contactNo: "",
  email: "",
  orderUrl: "",
  description: "",
  terms: "",
  web: "",
  lat: 0,
  lon: 0,
  addressFormatted: "",
  buildingNoOrName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  county: "",
  country: "",
  postcode: "",
};

/* This component show the location create modal UI. This modal has three pages. 
   It is business information, contact information and location information. */
const LocationCreateModal: React.SFC<CreateNewNodeProps> = ({
  handleCreateLocation,
  open,
  setOpen,
  address,
  setAddress,
  handleChangeLocationData,
  locationData,
  setLocationData,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isValidated, setIsValidated] = useState(false);
  const [
    isOpenModelCloseConfirmationModel,
    setIsOpenModelCloseConfirmationModel,
  ] = useState(false);

  /* Go to previous page after click back button. */
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

   /* Change the address state after select location using google map. */
  const handleChangeLocationAddress = (address: any) => {
    setAddress(address);
  };

  /* close location create modal */
  const handleCloseModal = () => {
    // If no changes have been made to the model, the model is closed.
    if (_.isEqual(locationData, locationDataInitial)) {
      setOpen(false);
      setIsValidated(false);
    } else {
      /* If a model change has been made, the state returns true to issue
          a warning message to the user that the changed data will be removed. */
      setIsOpenModelCloseConfirmationModel(true);
    }
  };

  /* Remove all changes and add the initial data of the state. */
  const handleResetModelData = () => {
    setOpen(false);
    setIsOpenModelCloseConfirmationModel(false);
    setLocationData(locationDataInitial);
    setActiveStep(0);
    setIsValidated(false);
    setAddress({});
  };

  /* Validate email and phone number */
  const emailAndPhoneNumberVerification = () => {
    if (
      locationData.contactNo &&
      locationData.email &&
      EMAIL_PATTERN.test(locationData.email) &&
      PHONE_NUMBER_PATTERN.test(locationData.contactNo)
    ) {
      return true;
    } else {
      return false;
    }
  };

  /* In the Modal, The process that should happen after clicking the next button on each page. */
  const handleSubmitData = () => {
    setIsValidated(true);
    // Check the email and phone number valid or not
    let secondPageVerification: boolean = emailAndPhoneNumberVerification();
    // Go from the first page to the second page.
    if (activeStep === 0) {
      if (locationData.businessDisplayName) {
        setActiveStep(activeStep + 1);
        setIsValidated(false);
      }
    } else if (activeStep === 1) {
      // Go from the second page to the third page.
      if (secondPageVerification) {
        setActiveStep(activeStep + 1);
        setIsValidated(false);
      }
    } else if (activeStep === 2) {
      // Sending an API call to create a new location or modify an existing location.
      if (!_.isEmpty(address)) {
        handleCreateLocation();
        handleResetModelData();
      }
    }
  };

  return (
    <>
      <LocationCreate
        handleCloseModal={handleCloseModal}
        isValidated={isValidated}
        locationData={locationData}
        handleChangeLocationData={handleChangeLocationData}
        handleChangeLocationAddress={handleChangeLocationAddress}
        address={address}
        isOpenModelCloseConfirmationModel={isOpenModelCloseConfirmationModel}
        activeStep={activeStep}
        handleSubmitData={handleSubmitData}
        handleBack={handleBack}
        setIsOpenModelCloseConfirmationModel={
          setIsOpenModelCloseConfirmationModel
        }
        handleResetModelData={handleResetModelData}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default LocationCreateModal;
