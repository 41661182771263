import { axiosClient } from "../client";

const queryString = require("query-string");

const CLOSE_PERIOD_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/closed-period`;

const CLOSE_PERIOD_INFO_UPDATE_ALL = (locationId, closeOptionId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/closed-period/${closeOptionId}`;

const CLOSE_PERIOD_INFO_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/closed-period`;

const CLOSE_PERIOD_INFO_DELETE = (locationId, closeOptionId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/closed-period/${closeOptionId}`;

export const fetchAllClosePeriodInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
      url: CLOSE_PERIOD_INFO_GET_ALL(locationId),
      query: { sort: ["fromDate","ASC"] }}, {arrayFormat: 'comma'},
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateClosePeriodInfo = (locationId, closeOptionId, data) =>
  axiosClient({
    method: "PUT",
    url: CLOSE_PERIOD_INFO_UPDATE_ALL(locationId, closeOptionId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const createClosePeriodInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: CLOSE_PERIOD_INFO_CREATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const deleteClosePeriodInfo = (locationId, closeOptionId) =>
  axiosClient({
    method: "DELETE",
    url: CLOSE_PERIOD_INFO_DELETE(locationId, closeOptionId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
