import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import DaySelect from "./DaySelect";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import {
  constructMinuteOfWeekArray,
  getStartMinuteOfWeekByDay,
} from "../../../utils/time-conversions";
import _ from "lodash";
import ButtonCommon from "../../buttons/ButtonCommon";
import { CustomTheme } from "../../../types/customTheme";

export interface OpeningTimeSlotProps {
  openingTimesSelectionList: any;
  openingTimeSelection: any;
  setOpeningTimesSelectionList: Function;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    borderRadius: 10,
    border: `1px solid ${theme.palette.background.entity_border}`,
    padding: 16,
    margin: 4,
  },
  deleteButtonStyle: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "end",
    },
  },
  firstDatePickerStyle: {
    marginLeft: "8px",
    paddingRight: "8px",
    "& .MuiPickersClockPointer-pointer": {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    "& .MuiPickersClockNumber-clockNumberSelected": {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    // "& .MuiPickersClock-container": {
    //   backgroundColor: "red !important",
    // },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0px",
    },
    [`& fieldset`]: {
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
  },
  secondDatePickerStyle: {
    paddingLeft: "8px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
    "& .MuiPickersClockPointer-pointer": {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    "& .MuiPickersClockNumber-clockNumberSelected": {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    [`& fieldset`]: {
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
  },
  datePickerStyle: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

/* This component show the opening time selection modal.
   It shows the data coming from the backend and processes the updated and inserted data to be sent to the backend. */
const OpeningTimeSlot: React.FunctionComponent<OpeningTimeSlotProps> = ({
  openingTimesSelectionList,
  openingTimeSelection,
  setOpeningTimesSelectionList,
}) => {
  const classes = useStyles();

  const [openTime, setOpenTime] = useState<moment.Moment>(moment());
  const [closeTime, setCloseTime] = useState<moment.Moment>(moment());
  const [selectedDays, setSelectedDays] = useState([]);

  /* The state of openTime, closeTime, and selectedDays is changed 
  when the initial loading or openingTimeSelection changes. */
  useEffect(() => {
    if (
      !_.isEmpty(openingTimeSelection) &&
      !_.isEmpty(openingTimeSelection.nodeList)
    ) {
      const selectedDays = openingTimeSelection.nodeList.map((node: any) =>
        getStartMinuteOfWeekByDay(node.day),
      );
      setSelectedDays(selectedDays);
      setOpenTime(moment(openingTimeSelection.openTime, "HH:mm"));
      setCloseTime(moment(openingTimeSelection.closeTime, "HH:mm"));
    }

    if (
      !_.isEmpty(openingTimeSelection) &&
      _.isEmpty(openingTimeSelection.nodeList)
    ) {
      setOpenTime(moment(openingTimeSelection.openTime, "HH:mm"));
      setCloseTime(moment(openingTimeSelection.closeTime, "HH:mm"));
    }
  }, [openingTimeSelection]);

  /* When the minuteOfWeekArray, openTime, CloseTime are entered, 
  add the openTime, CloseTime to a list and insert the 
  {closeTime: "", day: "", durationMins: minuteOfWeek: openTime: ""} into a nodeList in the same list. */
  const updateOpeningTimeSelectionList = (
    selectedDays: any,
    openTime: any,
    closeTime: any,
  ) => {
    const mowArray = constructMinuteOfWeekArray(
      selectedDays,
      openTime,
      closeTime,
    );
    let updatedOpeningTimesSelectionList = _.cloneDeep(
      openingTimesSelectionList,
    );

    /* Get the index of changed opening time. */
    const nodeIdx = updatedOpeningTimesSelectionList.findIndex(
      (node: any) => node.id === openingTimeSelection.id,
    );

    // Updating 'opening time details' to send to the backend.
    updatedOpeningTimesSelectionList[nodeIdx].nodeList = mowArray;
    updatedOpeningTimesSelectionList[nodeIdx].openTime =
      openTime.format("HH:mm");
    updatedOpeningTimesSelectionList[nodeIdx].closeTime =
      closeTime.format("HH:mm");
    setOpeningTimesSelectionList(updatedOpeningTimesSelectionList);
  };

  /* Handle close time selection modal */
  const handleOpenTime = async (time: Date | null) => {
    await setOpenTime(moment(time));
    updateOpeningTimeSelectionList(selectedDays, moment(time), closeTime);
  };

  /* Handle close time selection modal */
  const handleCloseTime = async (time: Date | null) => {
    await setCloseTime(moment(time));
    updateOpeningTimeSelectionList(selectedDays, openTime, moment(time));
  };

  /* Handle date selection */
  const handleSelectDays = async (e: any, selections: any) => {
    await setSelectedDays(selections);
    updateOpeningTimeSelectionList(selections, openTime, closeTime);
  };

  /* Remove time slot */
  const handleDelete = () => {
    const filtered = _.cloneDeep(openingTimesSelectionList).filter(
      (node: any) => node.id !== openingTimeSelection.id,
    );
    setOpeningTimesSelectionList(filtered);
  };

  const theme: CustomTheme = useTheme();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          style={{ display: "flex", alignItems: "center", marginTop: "8px" }}
        >
          <DaySelect
            handleSelectDays={handleSelectDays}
            selectedDays={selectedDays}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={6}
          lg={7}
          className={classes.datePickerStyle}
        >
          <Grid container>
            <Grid item xs={12} sm={6}>
              <KeyboardTimePicker
                margin="normal"
                ampm={false}
                id="time-picker"
                label="From time"
                value={openTime}
                onChange={handleOpenTime}
                inputVariant={"outlined"}
                className={classes.firstDatePickerStyle}
                fullWidth
                DialogProps={{ className: classes.firstDatePickerStyle }}
                okLabel={<Typography color="secondary">Ok</Typography>}
                cancelLabel={<Typography color="secondary">Cancel</Typography>}
                InputProps={{
                  style: {
                    borderRadius: 10,
                    border: `1px solid ${theme.palette.background.entity_border}`,
                    height: 56,
                    backgroundColor:
                      theme.palette.background.entity_highlight_background,
                  },
                }}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyboardTimePicker
                margin="normal"
                ampm={false}
                id="time-picker"
                label="To time"
                value={closeTime}
                onChange={handleCloseTime}
                inputVariant={"outlined"}
                className={classes.secondDatePickerStyle}
                DialogProps={{ className: classes.secondDatePickerStyle }}
                fullWidth
                okLabel={<Typography color="secondary">Ok</Typography>}
                cancelLabel={<Typography color="secondary">Cancel</Typography>}
                InputProps={{
                  style: {
                    borderRadius: 10,
                    border: `1px solid ${theme.palette.background.entity_border}`,
                    height: 56,
                    backgroundColor:
                      theme.palette.background.entity_highlight_background,
                  },
                }}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2} md={1} className={classes.deleteButtonStyle}>
          <ButtonCommon
            style={{ height: "40px", marginTop: "2px" }}
            color="red"
            onClick={handleDelete}
          >
            <DeleteIcon />
          </ButtonCommon>
        </Grid>
      </Grid>
    </div>
  );
};

export default OpeningTimeSlot;
