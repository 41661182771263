import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { CustomTheme } from "../../../types/customTheme";
import { useWindowDimensions } from "../../../utils/windowDimensions";
import bullet_list from "../../../assets/images/bullet_list.png";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    iconStyle: {
      height: "140px",
      width: "140px",
      marginTop: "40px",
      marginBottom: "16px",
    },
  }),
);

export interface InputBoxDefaultProps {
  title?: any;
  subTitle?: any;
}

/* This component is displayed if the sale information is empty. */
const NoSalesFound: React.FunctionComponent<InputBoxDefaultProps> = ({
  title = "Sales",
  subTitle = "Try adjusting the filters, to view your report",
}) => {
  // Get the height of the screen.
  const { height } = useWindowDimensions();
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      style={{
        minHeight: `${height - 350}px`,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid item sm={1} md={2} lg={3}></Grid>
      <Grid item xs={12} sm={10} md={8} lg={6}>
        <img
          src={bullet_list}
          className={classes.iconStyle}
          alt="No title data found"
        />
        <Typography
          variant="h4"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          No {title} data found
        </Typography>
        <Typography
          variant="h6"
          style={{ marginBottom: "52px", marginTop: "4px" }}
        >
          {subTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoSalesFound;
