import { createStyles, Grid, makeStyles } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import CardCommon from "../../../../components/card/CardCommon";
import {
  DISCOUNT_TYPE_PERCENT,
  DISCOUNT_TYPE_AMOUNT,
} from "../../../../utils/consts";
import WithLoading from "../../../../utils/WithLoading";
import CreateNewNode from "./CreateNewNode";
import DiscountInfoNode from "./DiscountInfoNode";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    discountTypeSection: {
      borderRadius: 8,
    },
    tabPanel: {
      backgroundColor: theme.palette.background.default,
    },
  }),
);

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`discount-type-tab-${index}`}
      aria-labelledby={`discount-type-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ padding: "16px 0" }}>{children}</div>}
    </div>
  );
};

export interface DiscountTypesProps {
  nodes: Array<any>;
  getDiscountInfo: any;
  categoryList: any;
  isLoading: boolean;
  isLoadingButton: any;
}

/* This page has 2 tab panels. They are percentage, and amount. 
Information related to those panels is designed to be visible to the user using cards */
const DiscountTypes: React.FunctionComponent<DiscountTypesProps> = ({
  nodes,
  getDiscountInfo,
  categoryList,
  isLoading,
  isLoadingButton,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [percentNodes, setPercentNodes] = useState<Array<any>>([]);
  const [amountNodes, setAmountNodes] = useState<Array<any>>([]);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  /* Data allocation for 2 tabs. (percentage, amount) */
  useEffect(() => {
    /* Objects with similar types, combine all those similar types into a single object list. */
    const groupedNodes: any = _.groupBy(nodes, (node) => node.type);
    setPercentNodes(groupedNodes[DISCOUNT_TYPE_PERCENT] || []);
    setAmountNodes(groupedNodes[DISCOUNT_TYPE_AMOUNT] || []);
  }, [nodes]);

  /* Switch the tab to be displayed. */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  /* When creating new discount information, 
  the basic information required for that is entered into the state. */
  const createNode = (nodeData: any, type: string) => {
    switch (type) {
      // If the selected tab is 'percent', the percentNodes state set to percent information'.
      case DISCOUNT_TYPE_PERCENT:
        setPercentNodes([...percentNodes, { ...nodeData }]);
        break;
      // If the selected tab is 'amount', the amountNodes state set to amount information'.
      case DISCOUNT_TYPE_AMOUNT:
        setAmountNodes([...amountNodes, { ...nodeData }]);
        break;
      default:
        break;
    }
  };

  /* Used to obtain a copy of one discount information. */
  const handleCopyNode = (nodeData: any) => {
    const nodeDataCopy = {
      newId: uuidv4(),
      status: "create",
      availability: nodeData.availability,
      isActive: nodeData.isActive,
      minOrderPrice: nodeData.minOrderPrice,
      title: nodeData.title,
      value: nodeData.value,
      usageCount: nodeData.usageCount,
      usageDiscountValue: nodeData.usageDiscountValue,
      usageLimit: nodeData.usageLimit,
      discountGroup: nodeData.discountGroup,
      isAutomaticDiscount: nodeData.isAutomaticDiscount,
      discountCode: nodeData.discountCode,
      deliveryTypes: nodeData.deliveryTypes,
      applyToCondition: nodeData.applyToCondition,
      applyTo: nodeData.applyTo,
      description: nodeData.description,
      id: nodeData.id,
      locationId: nodeData.locationId,
      type: nodeData.type,
    };
    createNode(nodeDataCopy, nodeData.type);
  };

  /* Used to create a new discount */
  const handleCreateNode = (type: string) => {
    const nodeData = {
      newId: uuidv4(),
      status: "create",
      availability: [],
      isActive: true,
      minOrderPrice: 0,
      usageLimit: 0,
      isAutomaticDiscount: true,
      deliveryTypes: [],
      applyTo: [],
      applyToCondition: "all",
      type,
    };
    // Discount initial information
    createNode(nodeData, type);
  };

  /* Remove discount information before updating the backend. The status is used for that. 
  If the status is "create", it will be removed from the state. */
  const handleRemoveNode = (type: string, uuid: string) => {
    switch (type) {
      // If the selected tab is 'percent', remove the selected percent details using nodeId.
      case DISCOUNT_TYPE_PERCENT:
        setPercentNodes(
          percentNodes.filter((node: any) => node.newId !== uuid),
        );
        break;
      case DISCOUNT_TYPE_AMOUNT:
        setAmountNodes(amountNodes.filter((node: any) => node.newId !== uuid));
        break;
      default:
        break;
    }
  };
  return (
    <>
      <CardCommon>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          aria-label="discount-types-tabs"
          variant="fullWidth"
        >
          <Tab
            label="PERCENTAGE"
            id="discount-type-tab-0"
            aria-controls="discount-type-tab-0"
          />
          <Tab
            label="AMOUNT"
            id="discount-type-tab-1"
            aria-controls="discount-type-tab-1"
          />
        </Tabs>
      </CardCommon>
      <div className={classes.root}>
        <TabPanel value={value} index={0}>
          <div className={classes.discountTypeSection}>
            <Typography variant="h4" component="div" />
            <Grid
              container
              spacing={2}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {!_.isEmpty(percentNodes) &&
                percentNodes.map((nodeData: any) => (
                  <Grid
                    item
                    sm={12}
                    md={6}
                    xs={12}
                    key={nodeData.id}
                    lg={4}
                    style={{ display: "flex" }}
                  >
                    <DiscountInfoNode
                      nodeData={nodeData}
                      getDiscountInfo={getDiscountInfo}
                      handleRemoveNode={handleRemoveNode}
                      type={DISCOUNT_TYPE_PERCENT}
                      handleCopyNode={handleCopyNode}
                      categoryList={categoryList}
                      openDeleteConfirm={openDeleteConfirm}
                      setOpenDeleteConfirm={setOpenDeleteConfirm}
                      isLoadingButton={isLoadingButton}
                    />
                  </Grid>
                ))}
              <CreateNewNode
                handleCreateNode={handleCreateNode}
                type={DISCOUNT_TYPE_PERCENT}
              />
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={classes.discountTypeSection}>
            <Typography
              variant="h4"
              component="div"
              align="left"
              style={{ margin: 4 }}
            />
            <Grid
              container
              spacing={2}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {!_.isEmpty(amountNodes) &&
                amountNodes.map((nodeData: any) => (
                  <Grid
                    item
                    sm={12}
                    md={6}
                    xs={12}
                    lg={4}
                    key={nodeData.id}
                    style={{ display: "flex" }}
                  >
                    <DiscountInfoNode
                      nodeData={nodeData}
                      getDiscountInfo={getDiscountInfo}
                      categoryList={categoryList}
                      handleRemoveNode={handleRemoveNode}
                      type={DISCOUNT_TYPE_AMOUNT}
                      handleCopyNode={handleCopyNode}
                      openDeleteConfirm={openDeleteConfirm}
                      setOpenDeleteConfirm={setOpenDeleteConfirm}
                      isLoadingButton={isLoadingButton}
                    />
                  </Grid>
                ))}
              <CreateNewNode
                handleCreateNode={handleCreateNode}
                type={DISCOUNT_TYPE_AMOUNT}
              />
            </Grid>
          </div>
        </TabPanel>
      </div>
    </>
  );
};

export default WithLoading(DiscountTypes);
