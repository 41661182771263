import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
    },
  }),
);

export interface DialogFullScreenLoadingProps {
  open: boolean;
}

/* This component use to full screen loading modal */
const FullScreenLoading: React.FunctionComponent<DialogFullScreenLoadingProps> =
  ({ open }) => {
    const classes = useStyles();

    return (
      <Backdrop className={classes.root} open={open}>
        <CircularProgress />
      </Backdrop>
    );
  };

export default FullScreenLoading;
