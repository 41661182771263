import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import _ from "lodash";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { useRouteMatch } from "react-router-dom";
import {
  createWrapper,
  fetchSingleCategoryInfo,
  fetchSingleProductWrapper,
  updateProductWrapperInfo,
  updateWrapperInfo,
} from "../../../../../services/menuApp/menuService";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../../utils/consts";
import ColorSelection from "../../../../PrestoExpressEatprestoApp/pages/ThemeCustomizationPage/ColorSelection";

export interface DirectoryAddModalProps {
  isOpen: any;
  setIsOpen: any;
  setDirectoryName: any;
  directoryName: any;
  isDirectoryNameEmpty: any;
  setIsDepartmentEmpty: any;
  handleCloseDirectoryEditModal: any;
  setUrl: any;
  isLoadingButton: any;
  url: any;
  setIsLoadingButton: any;
  categoryId: any;
  directoryId: any;
  directoryLevel: any;
  setSuccess: any;
  setError: any;
  setIsDirectoryNameEmpty: any;
  menuNodeList: any;
  wrapperId: any;
  menuId: any;
  firstWrapperId: any;
  setMenuNodeList: any;
  isRoot: any;
  setIsOpenDirectoryOrProductSelectionModal: any;
  selectedWrapper: any;
  setColor: any;
  color: any;
}

const DirectoryEditModal: React.FunctionComponent<DirectoryAddModalProps> = ({
  isOpen,
  setIsOpen,
  setDirectoryName,
  directoryName,
  isDirectoryNameEmpty,
  setIsDepartmentEmpty,
  handleCloseDirectoryEditModal,
  setUrl,
  isLoadingButton,
  url,
  setIsLoadingButton,
  categoryId,
  directoryId,
  directoryLevel,
  setSuccess,
  setError,
  setIsDirectoryNameEmpty,
  menuNodeList,
  wrapperId,
  menuId,
  firstWrapperId,
  setMenuNodeList,
  isRoot,
  setIsOpenDirectoryOrProductSelectionModal,
  selectedWrapper,
  setColor,
  color,
}) => {
  const match: any = useRouteMatch();

  const handleDisable = () => {
    if (
      _.isEqual(url, urlInitial) &&
      color === colorInitial &&
      directoryName === directoryNameInitial
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleGetSingleWrapper = async () => {
    try {
      const res = await fetchSingleProductWrapper(
        match.params.locationId,
        selectedWrapper.id,
      );
      handleDirectoryEditInfo(res.data.data.version);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  };

  const handleDirectoryEditInfo = async (version?: any) => {
    setIsLoadingButton(true);
    if (directoryName) {
      const body = {
        id: selectedWrapper.id,
        name: directoryName,
        locationId: match.params.locationId,
        categoryId: categoryId,
        nameShort: selectedWrapper.nameShort,
        img: url,
        colour: color,
        version: version || selectedWrapper.version,
      };
      try {
        let summary = [];
        if (selectedWrapper?.name !== directoryName) {
          summary.push(
            `Directory name changed from ${selectedWrapper?.name} to ${directoryName}`,
          );
        }
        if (selectedWrapper?.valid !== color) {
          summary.push(
            `Color changed from ${selectedWrapper?.valid} to ${color}`,
          );
        }
        if (selectedWrapper?.img !== url) {
          summary.push(`Image changed from ${selectedWrapper?.img} to ${url}`);
        }

        const summaryString = summary.join("\\n");

        const res = await updateWrapperInfo(
          match.params.locationId,
          body,
          "Menus Changed",
          summaryString,
          "menu",
        );
        if (res.data.status === 409) {
          handleGetSingleWrapper();
        } else {
          const cloneMenuNode = _.cloneDeep(menuNodeList);
          if (isRoot) {
            cloneMenuNode[menuId].category[categoryId].wrapper[
              res.data.data.id
            ] = res.data.data;
          } else {
            const updateProductName = (node: any) => {
              // Check if the current node is a product and has the specified ID
              if (node.id === selectedWrapper.id) {
                const productWrapper = res.data.data;
                node.name = productWrapper.name;
                node.allYouCanEat = productWrapper.allYouCanEat;
                node.colour = productWrapper.colour;
                node.cover = productWrapper.cover;
                node.desc = productWrapper.desc;
                node.img = productWrapper.img;
                node.itemType = productWrapper.itemType;
                node.kiosk = productWrapper.kiosk;
                node.nameShort = productWrapper.nameShort;
                node.valid = productWrapper.valid;
                node.version = productWrapper.version;
                node.viewOrder = productWrapper.viewOrder;
                node.weekFrom = productWrapper.weekFrom;
                node.weekTo = productWrapper.weekTo;
                return true; // Indicates the product was found and updated
              }

              // Recursively traverse nested wrappers
              if (node.wrapper) {
                for (const key in node.wrapper) {
                  if (Object.hasOwnProperty.call(node.wrapper, key)) {
                    const child = node.wrapper[key];
                    if (updateProductName(child)) {
                      return true; // Exit early if the product was found and updated
                    }
                  }
                }
              }

              return false; // Indicates the product was not found in this branch
            };

            // Find and update the product name
            if (
              cloneMenuNode[menuId] &&
              cloneMenuNode[menuId].category[categoryId]
            ) {
              updateProductName(
                cloneMenuNode[menuId].category[categoryId].wrapper[
                  firstWrapperId
                ],
              );
            }
          }
          setMenuNodeList(cloneMenuNode);
          setIsDirectoryNameEmpty(false);
          setIsLoadingButton(false);
          setIsOpen(false);
          setIsOpenDirectoryOrProductSelectionModal(false);
          setSuccess(SUCCESSFULLY_CREATED);
        }
      } catch (error) {
        setIsLoadingButton(false);
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      }
    } else {
      setIsLoadingButton(false);
      setIsDirectoryNameEmpty(true);
    }
  };

  const [directoryNameInitial, setDirectoryNameInitial] = useState("");
  const [urlInitial, setUrlInitial] = useState("");
  const [colorInitial, setColorInitial] = useState("");

  useEffect(() => {
    if (isOpen && Object.values(selectedWrapper).length > 0) {
      const { name, img, colour } = selectedWrapper;
      setDirectoryName(name);
      setDirectoryNameInitial(name);
      setUrl(img || "");
      setUrlInitial(img || "");
      setColor(colour);
      setColorInitial(colour);
    }
  }, [selectedWrapper]);

  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={true}
    >
      <DialogTitle>Edit Directory</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography style={{ paddingLeft: "4px" }}>
              Directory Name
            </Typography>
            <TextfieldDefaultNew
              id="sign-password-input"
              name="sign-password-input"
              onChange={(e: any) => {
                setIsDepartmentEmpty(false);
                setDirectoryName(e.target.value);
              }}
              value={directoryName}
              type="text"
            />
            {isDirectoryNameEmpty && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "3px",
                }}
              >
                <Typography
                  align="left"
                  variant="caption"
                  style={{ color: "red" }}
                >
                  Please enter directory name.
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ paddingLeft: "4px" }}>Image</Typography>
            <TextfieldDefaultNew
              id="sign-password-input"
              name="sign-password-input"
              onChange={(e: any) => {
                setUrl(e.target.value);
              }}
              value={url}
              type="text"
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "4px" }}>
            <Typography style={{ paddingLeft: "4px" }}>Color</Typography>
            <ColorSelection
              title={"Color"}
              colorCode={color}
              setColor={(color: any) => setColor(color.hex)}
              setInitialData={() => setColor(selectedWrapper.color)}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ marginBottom: "8px" }}>
        <ButtonCommon
          variant="contained"
          style={{ fontSize: 11, width: "100px", marginRight: 8 }}
          color="yellow"
          onClick={handleCloseDirectoryEditModal}
        >
          Cancel
        </ButtonCommon>
        <ButtonCommon
          variant="contained"
          style={{
            fontSize: 11,
            marginLeft: 4,
            width: "100px",
          }}
          isLoadingPage={isLoadingButton}
          disabled={handleDisable()}
          color={"orange"}
          onClick={handleDirectoryEditInfo}
        >
          {"Save"}
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default DirectoryEditModal;
