import React from "react";

import {
  Button,
  CircularProgress,
  makeStyles,
  SvgIcon,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    textTransform: "none",
    minWidth: 200,
    display: "flex",
    justifyContent: "space-between",
  },
}));

export interface SocialSignInButtonProps {
  handleClick?: (e: any) => void;
  logoSrc?: string;
  labelText?: string;
  isLoading?: boolean;
  alt?: string;
}

const SocialSignInButton: React.SFC<SocialSignInButtonProps> = ({
  handleClick,
  logoSrc,
  labelText,
  isLoading,
  alt = "alt",
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      size="large"
      onClick={handleClick}
      classes={{ root: classes.root }}
      disabled={isLoading}
    >
      {typeof logoSrc === "string" ? (
        <img
          src={logoSrc}
          alt={alt}
          style={{ width: 24, height: 24, marginRight: 8 }}
        />
      ) : (
        <SvgIcon>{logoSrc}</SvgIcon>
      )}
      {isLoading ? (
        <CircularProgress size={20} color="secondary" />
      ) : (
        <Typography variant="subtitle2">{labelText}</Typography>
      )}
    </Button>
  );
};

export default SocialSignInButton;
