import moment from "moment";
import saveAs from "file-saver";
import _ from "lodash";
import { ExportExcelColor } from "../../../../../root/theme/exportColor";

export const handleDownloadCsv = (
  columnsExcel: any,
  nodes: any,
  filterDetails: any,
) => {
  const ExcelJS = require("exceljs");
  const workbook = new ExcelJS.Workbook();

  let canvasImg;
  const canvas: any = document.getElementById("chart1");
  if (!_.isEmpty(document.getElementById("chart1"))) {
    canvasImg = canvas.toDataURL("image/jpeg", 1.0);
  }

  const worksheet = workbook.addWorksheet("VAT Transaction");

  worksheet.columns = columnsExcel;
  const len = columnsExcel.length + 64;
  let char = String.fromCharCode(len);
  let charHeader = String.fromCharCode(len - 1);
  worksheet.getCell("A1").value = "VAT report";
  worksheet.mergeCells(`B1:${char}1`);
  let filter = "";

  if (filterDetails.split("*")[0].split(",").length === 1) {
    filter =
      "VAT Summary Report for " +
      filterDetails.split("*")[0].split(",")[0] +
      " " +
      "Generated for the period of " +
      filterDetails.split("*")[1];
  } else {
    filter =
      "VAT Summary Report for " +
      filterDetails.split("*")[0].split(",").length +
      " Locations " +
      "Generated for the period of " +
      filterDetails.split("*")[1];
  }
  let filterList: any = [filter];
  filterList.push(filterDetails.split("*")[1]);
  worksheet.getCell("B1").value = filter;
  worksheet.addRow({});
  let excelHeader: any = {};
  let excelData: any = {};
  columnsExcel.map((data: any) => {
    excelHeader[data.key] = data.header;
  });

  worksheet.addRow(excelHeader);
  worksheet.getRow(1).height = 28;
  worksheet.getRow(2).height = 28;

  nodes.map((data: any, index: number) => {
    Object.keys(excelHeader).map((header: any) => {
      excelData[header] = data[header];
    });
    worksheet.addRow(excelData);

    const row = worksheet.getRow(index + 3);
    row.eachCell({ includeEmpty: true }, function (cell: any, colNumber: any) {
      let char = String.fromCharCode(64 + colNumber);
      if (colNumber !== 1) {
        worksheet.getCell(`${char}${index + 3})`).alignment = {
          vertical: "bottom",
          horizontal: "right",
        };
      } else {
        worksheet.getCell(`${char}${index + 3})`).font = { bold: true };
      }
    });

    worksheet.getRow(index + 3).height = 28;
  });

  const row = worksheet.getRow(nodes.length + 3);
  row.eachCell({ includeEmpty: true }, function (cell: any, colNumber: any) {
    let char = String.fromCharCode(64 + colNumber);
    if (colNumber !== 1) {
      worksheet.getCell(`${char}${nodes.length + 3})`).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
    } else {
      worksheet.getCell(`${char}${nodes.length + 3})`).font = { bold: true };
    }
  });

  worksheet.addConditionalFormatting({
    ref: `A4:${char}${nodes.length + 2}`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { color: { argb: "FFFFFF" } },
        },
      },
    ],
  });

  worksheet.getRow(nodes.length + 3).height = 28;
  worksheet.addConditionalFormatting({
    ref: "A1",
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { bold: true, size: 10 },
        },
      },
    ],
  });

  worksheet.addConditionalFormatting({
    ref: `B1:${char}1`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: {
            bold: true,
            size: 10,
          },
        },
      },
    ],
  });

  nodes.map((data: any, index: any) => {
    if (index + 1 !== nodes.length) {
      worksheet.addConditionalFormatting({
        ref: `A${index + 4}:${char}${index + 4}`,
        rules: [
          {
            type: "expression",
            formulae: ["MOD(2,2)=0"],
            style: {
              fill: {
                type: "pattern",
                pattern: "solid",
                bgColor: {
                  argb:
                    index % 2 === 0
                      ? ExportExcelColor.OddRowColor
                      : ExportExcelColor.EvenRowColor,
                },
              },
              font: { color: { argb: "000000" } },
            },
          },
        ],
      });
    }
  });

  const column = worksheet.getColumn(1);
  column.style = { font: { bold: true } };

  worksheet.addConditionalFormatting({
    ref: `A3:${char}3`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "E5BB4F" },
          },
          font: {
            bold: true,
          },
        },
      },
    ],
  });

  worksheet.addConditionalFormatting({
    ref: `A${nodes.length + 3}:${char}${nodes.length + 3}`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "3A9D76" },
          },
          font: {
            bold: true,
            color: { argb: "ffffff" },
          },
          alignment: "right",
          horizontal: "right",
        },
      },
    ],
  });

  if (!_.isEmpty(canvasImg)) {
    var imageId = workbook.addImage({
      base64: canvasImg,
      extension: "jpeg",
    });

    worksheet.addImage(imageId, {
      tl: { col: 1, row: nodes.length + 6 },
      ext: { width: 500, height: 200 },
    });
  }
  const today = moment().format("MMM_Do_YY").toString();
  workbook.xlsx.writeBuffer().then(function (buffer: any) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `Vat_Transaction${today}_${Math.floor(
        100000 + Math.random() * 900000,
      )}.xlsx`,
    );
  });
};
