import React from "react";
import { Route, Switch } from "react-router-dom";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import Location from "../../PrestoExpressLocationApp/pages/Location";
import StockItems from "../pages/StockItems";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import StockOrders from "../pages/StockOrders";
import StockGroup from "../pages/StockGroup";
import StockDepartment from "../pages/StockDepartment";
import StockTake from "../pages/StockTake";
import StockMovements from "../pages/StockMovements";
import Supplier from "../pages/Supplier";
import StockWaste from "../pages/StockWaste";
import { Icon } from "@iconify/react";
import StockConfiguration from "../pages/StockConfiguration";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "StockTake",
      title: "Stock Take",
      path: "/stockTake",
      icon: <span className="material-symbols-outlined">inventory</span>,
      isAuthorized: getIsAuthorized(Authorities.INVENTORY_READ),
    },
    {
      id: "StockOrders",
      title: "Stock Orders",
      path: "/stockOrders",
      icon: <span className="material-symbols-outlined">draft_orders</span>,
      isAuthorized: getIsAuthorized(Authorities.INVENTORY_READ),
    },
    {
      id: "StockMovement",
      title: "Stock Movements Report",
      path: "/stockMovements",
      icon: <span className="material-symbols-outlined">swap_horiz</span>,
      isAuthorized: getIsAuthorized(Authorities.INVENTORY_MOMENT_READ),
    },
    {
      id: "Waste",
      title: "Waste",
      path: "/waste",
      icon: <Icon icon="ri:filter-fill" width={20} height={20} />,
      isAuthorized: getIsAuthorized(Authorities.INVENTORY_READ),
    },
    {
      id: "admin",
      title: "Admin Configurations",
      path: "/admin",
      icon: <SupervisorAccountIcon />,
      isAuthorized: getIsAuthorized(Authorities.INVENTORY_ADMIN_READ),
      childRoutes: [
        {
          id: "stockItems",
          title: "Stock Items",
          path: "/admin/stockItems",
          icon: <span className="material-symbols-outlined">inventory_2</span>,
          isAuthorized: getIsAuthorized(Authorities.INVENTORY_ADMIN_READ),
        },
        {
          id: "stockDepartment",
          title: "Departments",
          path: "/admin/department",
          icon: <span className="material-symbols-outlined">token</span>,
          isAuthorized: getIsAuthorized(Authorities.INVENTORY_ADMIN_READ),
        },
        {
          id: "stockGroup",
          title: "Groups",
          path: "/admin/group",
          icon: <span className="material-symbols-outlined">hive</span>,
          isAuthorized: getIsAuthorized(Authorities.INVENTORY_ADMIN_READ),
        },
        {
          id: "supplier",
          title: "Suppliers",
          path: "/admin/supplier",
          icon: (
            <span className="material-symbols-outlined">local_shipping</span>
          ),
          isAuthorized: getIsAuthorized(Authorities.INVENTORY_ADMIN_READ),
        },
      ],
    },
    {
      id: "internal",
      title: "Internal Configurations",
      path: "/internalConfiguration",
      icon: <Icon icon="ri:admin-line" height="24" width="24" />,
      isAuthorized: getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ),
      childRoutes: [
        {
          id: "internalConfiguration",
          title: "Stock Configuration",
          path: "/internalConfiguration/stockConfiguration",
          icon: <Icon icon="dashicons:admin-generic" height="24" width="24" />,
          isAuthorized: getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ),
        },
      ],
    },
  ];
  return sidebarRoute;
};

export interface InventoryAppRoutesProps {
  isOpenDesktopSideBar: any;
}

const InventoryAppRoutes: React.FunctionComponent<InventoryAppRoutesProps> = ({
  isOpenDesktopSideBar,
}) => {
  return (
    <Switch>
      {/* <Route path="/:locationId" component={Home} exact /> */}
      <Route path="/:locationId/stockOrders" component={StockOrders} exact />
      {/* <Route
        path="/:locationId/admin/stockItems"
        component={StockItems}
        exact
      /> */}
      <Route
        path="/:locationId/admin/stockItems"
        render={(props) => (
          <StockItems {...props} additionalProp={isOpenDesktopSideBar} />
        )}
        exact
      />
      <Route path="/:locationId/admin/group" component={StockGroup} exact />
      <Route
        path="/:locationId/admin/department"
        component={StockDepartment}
        exact
      />
      <Route path="/:locationId/admin/supplier" component={Supplier} exact />
      <Route
        path="/:locationId/stockTake"
        render={(props) => (
          <StockTake {...props} additionalProp={isOpenDesktopSideBar} />
        )}
        exact
      />
      <Route
        path="/:locationId/stockMovements"
        component={StockMovements}
        exact
      />
      <Route
        path="/:locationId/waste"
        component={StockWaste}
        exact
      />
      <Route
        path="/:locationId/internalConfiguration/stockConfiguration"
        component={StockConfiguration}
        exact
      />
      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default InventoryAppRoutes;
