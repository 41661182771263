import React from "react";
import DialogCommon from "../dialogs/DialogCommon";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import _ from "lodash";
import { useForm } from "react-hook-form";

import ValidationMessage from "../validation/ValidationMessage";
import TextfieldCommon from "../textField/TextfieldCommon";
import { EMAIL_PATTERN, PHONE_NUMBER_PATTERN } from "../../utils/consts";
import DialogCommonDefault from "../dialogs/DialogCommonDefault";
import ButtonCommon from "../buttons/ButtonCommon";
import { buttonColors } from "../../utils/enum";
import TextfieldDefault from "../textField/TextfieldDefault";

interface FormData {
  name: string;
  address: string;
  contactNo: string;
  email: string;
}

export interface AddSupplierModalProps {
  isOpenSupplierAddModals: any;
  setIsOpenSupplierAddModals: any;
  handleCreateNewSupplier: any;
  isLoadingToast: any;
}

/**
 * AddSupplierModal Component
 *
 * This component represents a modal dialog for adding a new supplier. It allows users
 * to input supplier details such as name, address, contact number, and email.
 *
 * @param {object} props - The component's props.
 * @param {boolean} props.isOpenSupplierAddModals - Flag to control the visibility of the modal.
 * @param {function} props.setIsOpenSupplierAddModals - Function to set the modal's visibility.
 * @param {function} props.handleCreateNewSupplier - Function to handle the creation of a new supplier.
 * @returns {JSX.Element} - The rendered JSX element for the AddSupplierModal.
 */

const AddSupplierModal: React.FunctionComponent<AddSupplierModalProps> = ({
  isOpenSupplierAddModals,
  setIsOpenSupplierAddModals,
  handleCreateNewSupplier,
  isLoadingToast,
}) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    defaultValues: {
      name: "",
      address: "",
      contactNo: "",
      email: "",
    },
  });

  // Define a form submission handler using react-hook-form's handleSubmit
  const handleSubmitSupplier = handleSubmit((data) => {
    // When the form is submitted, call the provided 'handleCreateNewSupplier' function
    handleCreateNewSupplier(data);
  });

  return (
    <DialogCommonDefault
      open={isOpenSupplierAddModals}
      setOpen={setIsOpenSupplierAddModals}
      isNeedFixedHeight={false}
    >
      <DialogTitle>Add New Supplier</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextfieldDefault
              id="name"
              name="name"
              type="text"
              label="Supplier Name"
              disabled={false}
              inputRef={register({
                required: "Name is required.",
              })}
            />
            {errors.name ? (
              <ValidationMessage message={errors.name.message} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <TextfieldDefault
              id="address"
              name="address"
              type="text"
              label="Address"
              inputRef={register({
                required: "Address is required.",
              })}
            />
            {errors.address ? (
              <ValidationMessage message={errors.address.message} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <TextfieldDefault
              id="contactNo"
              name="contactNo"
              type="text"
              label="Contact No"
              inputRef={register({
                required: true,
                pattern: PHONE_NUMBER_PATTERN,
              })}
            />
            {errors.contactNo ? (
              <ValidationMessage
                message={
                  errors.contactNo.type === "required"
                    ? "Contact No is required"
                    : "Please enter a valid Contact No"
                }
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <TextfieldDefault
              id="email"
              name="email"
              type="email"
              label="Email"
              inputRef={register({
                required: true,
                pattern: EMAIL_PATTERN,
              })}
            />
            {errors.email ? (
              <ValidationMessage
                message={errors.email && "Please enter a valid email address"}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px", marginRight: "3px" }}>
        <ButtonCommon
          onClick={() => {
            setIsOpenSupplierAddModals(false);
          }}
          disabled={isLoadingToast}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "12px",
          }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
        >
          Cancel
        </ButtonCommon>

        <ButtonCommon
          disabled={!errors || isLoadingToast}
          onClick={handleSubmitSupplier}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "14px",
          }}
          color={buttonColors.CREATE_BUTTON_COLOR}
        >
          Create
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default AddSupplierModal;
