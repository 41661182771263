import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
} from "../../../../utils/consts";
import { Button, Grid, Typography } from "@material-ui/core";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import {
  fetchAllStockGroups,
  fetchAllStockItemsDepartmentsInfo,
  fetchAllStockItemsGroupsInfo,
  fetchAllStockItemsProductInfo,
  fetchStockItemInfoGetAll,
} from "../../../../services/inventory/stockItems";
import {
  fetchStockLevelInfoGetAll,
} from "../../../../services/inventory/stockLevel";
import Toast from "../../../../components/alerts/Toast";
import InventoryDetailsDefault from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";
import CardCommon from "../../../../components/card/CardCommon";
import StockItemMapProductModal from "../../../../components/common/StockItemMapProductModal";
import StockWastageItemModal from "./component";
import { fetchAllMasterLocations } from "../../../../services/inventory/masterLocations";
import { getIsAuthorized } from "../../../../utils/authorities";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * This component represents the Stock Take page, where stock levels and items are managed.
 * It allows users to view, update, and save stock levels for different stock items at various locations.
 */
const StockWaste: React.FunctionComponent = () => {
  const [error, setError] = useState("");
  const [mapProductSkeletonLoading, setMapProductSkeletonLoading] =
    useState(false);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [stockMappingDetailsNode, setStockMappingDetailsNode] = useState([]);
  const [isOpenStockItemWasteModal, setIsOpenStockItemWasteModal] =
    useState(false);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [isOpenEditStockItemsModal, setIsOpenEditStockItemsModal] =
    useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [success, setSuccess] = useState("");
  const [menuGroups, setMenuGroups] = useState([]);
  const [menuDepartments, setMenuDepartments] = useState<any>([]);
  const [menuProducts, setMenuProducts] = useState([]);
  const [groupId, setGroupId] = useState("");
  const [isOpenMapProductsEditModal, setIsOpenMapProductsEditModal] =
    useState(false);
  const [id, setId] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchNameTab, setSearchNameTab] = useState("");
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [isLoadingToast, setIsLoadingToast] = useState(false);
  const [isGetApi, setIsGetApi] = useState(false);
  const [isOpenNewModal, setIsOpenNewModal] = useState(false);
  const [isActiveLocationId, setIsActiveLocationId] = useState(true);
  const [selectedStockOrderDetails, setSelectedStockOrderDetails] =
    useState<any>([]);
  const [isOpenWasteStockMapProductModal, setIsOpenStockProductWasteModal] =
    useState(false);
  const [stockLevelNode, setStockLevelNode] = useState<any>({});

  const match: any = useRouteMatch();

  /**
   * Function to fetch stock level information for a specific location and set it in the component's state.
   */
  const getAllStockLevel = async () => {
    try {
      // Fetch stock level information for the specified location
      const res = await fetchStockLevelInfoGetAll(match.params.locationId);

      const output: any = {};

      if (res.data.data) {
        res.data.data.forEach((item: any) => {
          const { id, stockLevel, stockItemId } = item;
          const stockItemIdKey = stockItemId.id;

          output[stockItemIdKey] = {
            stockLevel,
            criticalThreshold: stockItemId.criticalThreshold,
            warningThreshold: stockItemId.warningThreshold,
          };
        });
        // Set the fetched stock level data in the component's state
        setStockLevelNode(output);
      } else {
        setStockLevelNode({});
      }
    } catch (err) {
      // Handle errors if they occur during the API call
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleGetMasterLocations = async () => {
    try {
      const res = await fetchAllMasterLocations(match.params.locationId);
      if (
        (res.data && res.data.data && res.data.data.active) ||
        getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ)
      ) {
        getAllFilterLocation("");
        setIsActiveLocationId(true);
      } else {
        setIsActiveLocationId(false);
        setIsOpenSkeletonLoading(false);
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Effect to set the document title and fetch location information on component mount.
   */
  useEffect(() => {
    // Set the document title to "Eat Presto - Stock Item"
    document.title = "Inventory - Stock Items";

    // Fetch location information with an empty searchName
    handleGetMasterLocations();
  }, []);

  /**
   * Function to fetch stock items groups information and update the 'menuGroups' state.
   */
  const handleGetStockItemsGroupsInfo = async (
    headerName: any,
    shortingMethod: any,
  ) => {
    try {
      // Attempt to fetch stock items groups information using the 'fetchAllStockItemsGroupsInfo' API
      const res = await fetchAllStockItemsGroupsInfo(
        match.params.locationId,
        headerName,
        shortingMethod,
      );

      // Set the 'menuGroups' state with the retrieved data from the response
      setMenuGroups(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch stock items departments information and update the 'menuDepartments' state.
   */
  const handleGetStockItemsDepartmentsInfo = async () => {
    try {
      // Attempt to fetch stock items departments information using the 'fetchAllStockItemsDepartmentsInfo' API
      const res = await fetchAllStockItemsDepartmentsInfo(
        match.params.locationId,
      );

      if (res.data && res.data.data && Object.keys(res.data.data).length > 0) {
        const newData = Object.fromEntries(
          Object.entries(res.data.data).filter(
            ([key, value]: any) =>
              value.name !== "Online Hidden" && value.name !== "Templates",
          ),
        );
        setMenuDepartments(newData);
      }
      // Set the 'menuDepartments' state with the retrieved data from the response
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch stock items products information and update the 'menuProducts' state.
   */
  const handleGetStockItemsProductsInfo = async () => {
    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await fetchAllStockItemsProductInfo(match.params.locationId);

      // Set the 'menuProducts' state with the retrieved data from the response
      setMenuProducts(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch stock items groups, departments, and products information collectively.
   * Calls individual functions to retrieve and update corresponding states.
   */
  const handleGetProductDepartmentGroup = (
    headerName: any,
    shortingMethod: any,
  ) => {
    // Call function to fetch stock items groups information and update 'menuGroups' state
    handleGetStockItemsGroupsInfo(headerName, shortingMethod);

    // Call function to fetch stock items departments information and update 'menuDepartments' state
    handleGetStockItemsDepartmentsInfo();

    // Call function to fetch stock items products information and update 'menuProducts' state
    handleGetStockItemsProductsInfo();

    // Call function to fetch all stock departments for a specific group and update relevant states
    getAllStockDepartmentForGroupNotLoad(groupId);
  };

  /**
   * Transforms the response data into a structured stock order item format.
   * @param {Array} response - The response data containing stock order items. format (item->department->group)
   * @returns {Array} - Transformed stock order item structure. format (group->department->item)
   */
  const handleStockOrderItemStructure = (response: any) => {
    // Initialize an empty array to store the transformed structure
    const transformedStructure = response.reduce(
      (accumulator: any, item: any) => {
        if (item?.stockDepartmentId?.stockGroupId?.id) {
          const stockGroupId = item.stockDepartmentId.stockGroupId.id;
          const existingGroup = accumulator?.find(
            (group: any) => group.id === stockGroupId,
          );

          if (existingGroup) {
            const existingDept = existingGroup.stockDepartmentId.find(
              (dept: any) => dept.id === item.stockDepartmentId.id,
            );

            if (existingDept) {
              existingDept.item.push({
                id: item.id,
                name: item.name,
                barcode: item.barcode,
                active: item.active,
                created: item.created,
                criticalThreshold: item.criticalThreshold,
                displaySuffix: item.displaySuffix,
                inputMultiplier: item.inputMultiplier,
                inputSuffix: item.inputSuffix,
                primaryLocationId: item.primaryLocationId,
                sku: item.sku,
                updated: item.updated,
                version: item.version,
                warningThreshold: item.warningThreshold,
                stockDepartmentId: item.stockDepartmentId.id,
                autoUnitConvert: item.autoUnitConvert,
                showRemainderSep: item.showRemainderSep,
              });
            } else {
              existingGroup.stockDepartmentId.push({
                id: item.stockDepartmentId.id,
                name: item.stockDepartmentId.name,
                locationId: item.stockDepartmentId.locationId,
                created: item.stockDepartmentId.created,
                updated: item.stockDepartmentId.updated,
                version: item.stockDepartmentId.version,
                item: [
                  {
                    id: item.id,
                    name: item.name,
                    barcode: item.barcode,
                    active: item.active,
                    created: item.created,
                    criticalThreshold: item.criticalThreshold,
                    displaySuffix: item.displaySuffix,
                    inputMultiplier: item.inputMultiplier,
                    inputSuffix: item.inputSuffix,
                    primaryLocationId: item.primaryLocationId,
                    sku: item.sku,
                    updated: item.updated,
                    version: item.version,
                    warningThreshold: item.warningThreshold,
                    stockDepartmentId: item.stockDepartmentId.id,
                    autoUnitConvert: item.autoUnitConvert,
                    showRemainderSep: item.showRemainderSep,
                  },
                ],
              });
            }
          } else {
            accumulator.push({
              id: item.stockDepartmentId.stockGroupId.id,
              name: item.stockDepartmentId.stockGroupId.name,
              version: item.stockDepartmentId.stockGroupId.version,
              updated: item.stockDepartmentId.stockGroupId.updated,
              created: item.stockDepartmentId.stockGroupId.created,
              locationId: item.stockDepartmentId.stockGroupId.locationId,
              stockDepartmentId: [
                {
                  id: item.stockDepartmentId.id,
                  name: item.stockDepartmentId.name,
                  locationId: item.stockDepartmentId.locationId,
                  created: item.stockDepartmentId.created,
                  updated: item.stockDepartmentId.updated,
                  version: item.stockDepartmentId.version,
                  item: [
                    {
                      id: item.id,
                      name: item.name,
                      barcode: item.barcode,
                      active: item.active,
                      created: item.created,
                      criticalThreshold: item.criticalThreshold,
                      displaySuffix: item.displaySuffix,
                      inputMultiplier: item.inputMultiplier,
                      inputSuffix: item.inputSuffix,
                      primaryLocationId: item.primaryLocationId,
                      sku: item.sku,
                      updated: item.updated,
                      version: item.version,
                      warningThreshold: item.warningThreshold,
                      stockDepartmentId: item.stockDepartmentId.id,
                      autoUnitConvert: item.autoUnitConvert,
                      showRemainderSep: item.showRemainderSep,
                    },
                  ],
                },
              ],
            });
          }
        }

        return accumulator;
      },
      [],
    );

    // Return the transformed structure
    return transformedStructure;
  };

  /**
   * Fetch All Stock Departments for a Group (Without Loading Data)
   *
   * This function is responsible for fetching all stock departments for a specific group without loading data into
   * various states. It performs API requests to obtain the necessary information.
   *
   * @param {any} groupId - The identifier of the group for which departments are fetched.
   */
  const getAllStockDepartmentForGroupNotLoad = async (groupId: any) => {
    setSelectedDepartmentId("");
    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await fetchStockItemInfoGetAll(match.params.locationId);

      // Check if the API response contains data
      if (res.data.data) {
        // Set the group identifier in state
        setGroupId(groupId);
        // Transform the stock item data into a structured format
        const transformedStructure = handleStockOrderItemStructure(
          res.data.data,
        );

        if (!_.isEmpty(searchName)) {
          handleChangeSearchUsingResponse(res.data.data);
        }
      }

      // Set loading states to false
      setIsOpenSkeletonLoading(false);
    } catch (err) {
      // Set loading states to false in case of an error
      setIsOpenSkeletonLoading(false);
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * This function is responsible for fetching all stock groups, typically used for populating a menu or selector with
   * available groups. It performs an API request to obtain the necessary information.
   *
   * @param {any} newGroupId - Optional: The identifier of a specific group to select.
   * @param {any} isLoad - Optional: Indicates whether to load data or not.
   */
  const getAllStockGroups = async (newGroupId?: any, isLoad?: any) => {
    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await fetchAllStockGroups(match.params.locationId);

      // Set the 'menuProducts' state with the retrieved data from the response
      if (res.data.data) {
      

      } else {
        // Set loading states to false in case of an error
        setIsOpenSkeletonLoading(false);
      }
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch location information and initialize stock items loading.
   * @param {string} searchName - Name to search for locations.
   */
  const getAllFilterLocation = async (searchName: string) => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations(searchName);

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);

      // Trigger the function to fetch stock items information with appropriate parameters
      handleGetProductDepartmentGroup("", "");
      getAllStockGroups();
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Handles the search functionality for filtering stock item groups, departments, and items.
   *
   * @param {any} response - The search query entered by the user.
   */
  const handleChangeSearchUsingResponse = (response: any) => {
    // Filter and update the data based on the search response
    const filteredOutput = response
      .map((group: any) => ({
        ...group,
        stockDepartmentId: group.stockDepartmentId
          .map((dept: any) => ({
            ...dept,
            // Filter items within each department by name
            item: dept.item.filter((item: any) =>
              item.name.includes(searchName),
            ),
          }))
          // Remove departments with no matching items
          .filter((dept: any) => dept.item.length > 0),
      }))
      // Remove groups with no matching departments
      .filter((group: any) => group.stockDepartmentId.length > 0);

  };

  const handleOpenStockItemWasteModal = () => {
    setIsOpenStockItemWasteModal(true);
    setIsOpenStockItemWasteModal(true);
    getAllStockLevel();
  };

  const handleOpenStockProductWasteModal = () => {
    setIsGetApi(false);
    getAllStockLevel();

    // Close the 'isOpenEditStockItemsModal' if it was open.
    setIsOpenEditStockItemsModal(false);

    // Set the 'id' state to the provided 'stockId'.

    // Close the 'isOpenWasteStockMapProductModal' if it was open.
    setIsOpenStockProductWasteModal(true);

    // Open the 'isOpenMapProductsEditModal'.
    setIsOpenMapProductsEditModal(true);
    setIsOpenStockProductWasteModal(true);
  };
  return (
    <>
      <Toast message={toastMessage} type={toastType} loading={isLoadingToast} />
      {(isOpenStockItemWasteModal || isOpenNewModal) && (
        <StockWastageItemModal
          isOpenStockItemWasteModal={isOpenStockItemWasteModal}
          setIsOpenStockItemWasteModal={setIsOpenStockItemWasteModal}
          setSuccess={setSuccess}
          setError={setError}
          selectedStockOrderDetails={selectedStockOrderDetails}
          setIsLoadingToast={setIsLoadingToast}
          setToastType={setToastType}
          setToastMessage={setToastMessage}
          isLoadingToast={isLoadingToast}
          stockLevelNode={stockLevelNode}
        />
      )}
      {(isOpenWasteStockMapProductModal || isOpenNewModal) && (
        <StockItemMapProductModal
          isOpenWasteStockMapProductModal={isOpenWasteStockMapProductModal}
          setIsOpenStockProductWasteModal={setIsOpenStockProductWasteModal}
          setError={setError}
          setIsCreated={setIsCreated}
          isCreated={isCreated}
          menuGroups={menuGroups}
          menuDepartments={menuDepartments}
          menuProducts={menuProducts}
          isOpenEditStockItemsModal={isOpenEditStockItemsModal}
          isOpenMapProductsEditModal={isOpenMapProductsEditModal}
          id={id}
          stockMappingDetailsNode={stockMappingDetailsNode}
          mapProductSkeletonLoading={mapProductSkeletonLoading}
          setStockMappingDetailsNode={setStockMappingDetailsNode}
          groupId={groupId}
          selectedDepartmentIdInMenu={selectedDepartmentId}
          isLoadingToast={isLoadingToast}
          setIsLoadingToast={setIsLoadingToast}
          setToastType={setToastType}
          setToastMessage={setToastMessage}
          setSearchName={setSearchName}
          setSearchNameTab={setSearchNameTab}
          displaySuffix={""}
          isGetApi={isGetApi}
          setMapProductSkeletonLoading={setMapProductSkeletonLoading}
        />
      )}
      <InventoryDetailsDefault
        locationSelectorList={locationSelectorList}
        isOpenSkeletonLoading={isOpenSkeletonLoading}
        topic="Stock Waste"
        isNeedAddButton={false}
        nodeList={[1]}
        isActiveLocationId={isActiveLocationId}
      >
        {/* Render AddStockItemsModal if isOpenWasteStockMapProductModal is true */}
        {/* StockWaste table component */}
        <Grid container style={{ marginTop: "16px" }} spacing={2}>
          <Grid item xs={6} md={3}>
            <CardCommon backgroundColor={"entity_highlight_background"}>
              <Button
                onClick={handleOpenStockItemWasteModal}
                style={{ width: "100%" }}
              >
                <Typography style={{ margin: "32px 0px" }}>
                  Stock Waste
                </Typography>
              </Button>
            </CardCommon>
          </Grid>
          <Grid item xs={6} md={3}>
            <CardCommon backgroundColor={"entity_highlight_background"}>
              <Button
                onClick={handleOpenStockProductWasteModal}
                style={{ width: "100%" }}
              >
                <Typography style={{ margin: "32px 0px" }}>
                  Product Waste
                </Typography>
              </Button>
            </CardCommon>
          </Grid>
        </Grid>
      </InventoryDetailsDefault>
      {/* DefaultAlert components for success and error messages */}
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(StockWaste, Authorities.INVENTORY_READ);
