/**
 * Function: hexToRgb
 *
 * This function takes a hexadecimal color code as input and converts it into an array of RGB components.
 */
export const hexToRgb = (hex: any) => {
  // Remove the hash sign if it exists
  hex = hex.replace("#", "");

  // Parse the hex value into individual RGB components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the RGB value in the format suitable for rgba() function
  return [r, g, b];
};
