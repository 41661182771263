import React, { useState } from "react";
import { DialogActions, DialogContent } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";

import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import UserDetails from "./UserDetails";
import PasswordAdd from "./PasswordAdd";
import RoleSelection from "./RoleSelection";
import {
  createRoles,
  getUserDetails,
} from "../../../../services/locationApp/locationService";
import DialogCommonFullWidth from "../../../../components/dialogs/DialogCommonFullWidth";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";

export interface permissionAddModalProps {
  isOpenPermissionAddModal: any;
  setIsOpenPermissionAddModal: any;
  handleSubmit: any;
  register: any;
  errors: any;
  index: any;
  setIndex: any;
  setIsOpenErrorModal: any;
  setErrorMessage: any;
  setNewPassword: any;
  getAllRoleInfo: any;
  newPassword: any;
  updatedUserList: any;
  setSuccess: any;
  setError: any;
}

/**
 * PermissionAddModal Component:
 *
 * This React component represents a modal for adding permissions to a user in a specific location.
 * The modal has different steps, including user details, password addition, and role selection.
 *
 * Key Features:
 * - Handles the process of adding permissions to a user, including creating roles and assigning them.
 * - Displays different steps of the permission addition process based on the current index.
 * - Validates and checks for existing emails to prevent duplication of roles for the same account.
 *
 * Functions:
 * - handleGetUserDetails: Function to fetch user details based on the entered email.
 * - handleSubmitRole: Function to handle the submission of roles after the user details are fetched.
 * - handleExistingEmail: Function to check if an email already exists in the updatedUserList.
 * - handleSubmitPermission: Function to handle the submission of permissions based on the current index.
 * - handleSelectRole: Function to handle button clicks during role selection.
 */
const PermissionAddModal: React.FunctionComponent<permissionAddModalProps> = ({
  isOpenPermissionAddModal,
  setIsOpenPermissionAddModal,
  handleSubmit,
  register,
  errors,
  index,
  setIndex,
  setIsOpenErrorModal,
  setErrorMessage,
  setNewPassword,
  getAllRoleInfo,
  newPassword,
  updatedUserList,
  setSuccess,
  setError,
}) => {
  const [selectedRole, setSelectedRole] = useState<any>();
  const [uuid, setUuid] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const match: any = useRouteMatch();

  /**
   * handleGetUserDetails Function:
   *
   * This asynchronous function is responsible for fetching user details based on the entered data
   * (email, locationId, name). It utilizes the getUserDetails service and handles the response to
   * determine the next steps in the permission addition process.
   *
   * Key Steps:
   * - Creates a formData object with email, locationId, and name.
   * - Calls the getUserDetails service with the formData.
   * - If the response status is 404 (Not Found), it closes the permission add modal, opens an error modal,
   *   and sets the error message accordingly.
   * - If the user is a new user (isNewUser is true), it sets the index to 1 and sets the new password.
   * - If the user is not a new user, it sets the index to 2 and clears the new password.
   * - Sets the uuid state with the user's unique identifier.
   * - Sets isLoading to false when the process is complete.
   */
  const handleGetUserDetails = async (data: any) => {
    try {
      // Create formData object with email, locationId, and name
      const formData = {
        email: data.email,
        locationId: match.params.locationId,
        name: data.name,
        arc: process.env.REACT_APP_ARC
      };

      // Call the getUserDetails service with formData
      const res = await getUserDetails(formData);

      // If the response status is 404 (Not Found)
      if (res.data.status === 404) {
        setIsOpenPermissionAddModal(false);
        setIsOpenErrorModal(true);
        setErrorMessage(res.data.message);
      } else {
        // If the user is a new user, set index to 1 and set the new password
        if (res.data.data.isNewUser) {
          setIndex(1);
          setNewPassword(res.data.data.password);
        } else {
          // If the user is not a new user, set index to 2 and clear the new password
          setIndex(2);
          setNewPassword("");
        }
        // Set the uuid state with the user's unique identifier
        setUuid(res.data.data.id);
      }
      // Set isLoading to false when the process is complete
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * handleSubmitRole Function:
   *
   * This asynchronous function is responsible for handling the submission of the role
   * during the permission addition process. It creates a formData object with accountId and
   * authorities, calls the createRoles service, and performs additional actions based on
   * the response.
   *
   * Key Steps:
   * - Creates a formData object with accountId and authorities using uuid and selectedRole.id.
   * - Calls the createRoles service with match.params.locationId and formData.
   * - Calls getAllRoleInfo with the updated role information from the response.
   * - Closes the permission add modal and resets the index to 0.
   */
  const handleSubmitRole = async () => {
    try {
      // Create formData object with accountId and authorities
      const formData = {
        accountId: uuid,
        authorities: selectedRole.id,
      };

      // Call the createRoles service with locationId and formData
      const res = await createRoles(match.params.locationId, formData);

      // Call getAllRoleInfo with the updated role information from the response
      getAllRoleInfo(res.data.data);

      // Close the permission add modal and reset the index to 0
      setIsOpenPermissionAddModal(false);
      setIndex(0);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * handleExistingEmail Function:
   *
   * This function checks if the email in userDetails already exists in the updatedUserList.
   *
   * Key Steps:
   * - Filters the updatedUserList based on whether the email matches the one in userDetails.
   * - Returns true if there is at least one matching email; otherwise, returns false.
   */

  const handleExistingEmail = (userDetails: any) => {
    // Filter updatedUserList based on whether the email matches the one in userDetails
    const isExistEmail = updatedUserList?.filter(
      (data: any) =>
        data.email.toLowerCase() === userDetails.email.toLowerCase(),
    );

    // Return true if there is at least one matching email; otherwise, return false
    return isExistEmail?.length >= 1;
  };

  /**
   * handleSubmitPermission Function:
   *
   * This function handles the submission of user permissions. The behavior depends on the current index.
   *
   * Key Steps:
   * - If the index is 0, checks if the email already has an assigned role. If yes, shows an error.
   *   Otherwise, proceeds to fetch user details.
   * - If the index is 1, sets the index to 2.
   * - If the index is 2, handles the submission of the role.
   */
  const handleSubmitPermission = handleSubmit(async (data: any) => {
    // Check the current index to determine the behavior
    if (index === 0) {
      // Check if the email already has an assigned role
      const isExistEmail = handleExistingEmail(data);
      // If the email already has an assigned role, show an error
      if (isExistEmail) {
        setIsOpenPermissionAddModal(false);
        setIsOpenErrorModal(true);
        setErrorMessage(
          "This account already has an assigned role for the specified location.",
        );
      } else {
        // If not, proceed to fetch user details
        setIsLoading(true);
        handleGetUserDetails(data);
      }
    } else if (index === 1) {
      // If the index is 1, show the password
      setIndex(2);
    } else if (index === 2) {
      // If the index is 2, handle the submission of the role
      handleSubmitRole();
    }
  });

  /**
   * handleSelectRole Function:
   *
   * This function is responsible for handling the selection of a role. It updates the state
   * with the selected role.
   */
  const handleSelectRole = (e: any, role: any) => {
    // Update the state with the selected role
    setSelectedRole(role);
  };

  return (
    <>
      <DialogCommonFullWidth
        open={isOpenPermissionAddModal}
        setOpen={setIsOpenPermissionAddModal}
      >
        <DialogContent>
          {index === 0 && <UserDetails register={register} errors={errors} />}

          {index === 1 && (
            <PasswordAdd
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              setSuccess={setSuccess}
            />
          )}
          {index === 2 && (
            <RoleSelection
              handleSelectRole={handleSelectRole}
              selectedRole={selectedRole}
            />
          )}
        </DialogContent>
        <DialogActions>
          <ButtonCommon
            onClick={() => {
              setIsOpenPermissionAddModal(false);
              setIndex(0);
            }}
            disabled={isLoading}
            variant="contained"
            style={{
              fontSize: 11,
              width: "120px",
              marginRight: "14px",
            }}
            color={buttonColors.CANCEL_BUTTON_COLOR}
          >
            Cancel
          </ButtonCommon>

          <ButtonCommon
            disabled={!errors || isLoading}
            isLoadingPage={isLoading}
            onClick={handleSubmitPermission}
            variant="contained"
            style={{
              fontSize: 11,
              width: "120px",
              marginRight: "14px",
            }}
            color={buttonColors.CREATE_BUTTON_COLOR}
          >
            {index === 2 ? "Save" : "Next"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonFullWidth>
    </>
  );
};

export default PermissionAddModal;
