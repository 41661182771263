import {
  Box,
  Button,
  Card,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import _ from "lodash";

import { CustomTheme } from "../../../../types/customTheme";
import { getLocalStore } from "../../../../utils/store/localStore";
import { handleImageUrl } from "../../../../utils/ChangePixelImgUrl";
import { hexToRgb } from "../../../../utils/ConvertColorCodeHexToRgb";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import zIndex from "@material-ui/core/styles/zIndex";
import SwitchCommon from "../../../../components/switches/SwitchCommon";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    imgBoxStyle: {
      margin: "7px",
      marginLeft: "10px",
      borderRadius: "10px",
      height: "120px",
      [theme.breakpoints.down("sm")]: {
        height: "92px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
    titleStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",

      [theme.breakpoints.down("sm")]: {
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    imgBoxNotAvailableStyle: {
      marginRight: "7px",
      marginTop: "10px",
      marginLeft: "10px",
      borderRadius: "10px",
      marginBottom: "10px",
      height: "114px",
      width: "114px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
        width: "80px",
      },
      backgroundColor: theme.palette.background.imgBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      objectFit: "cover",
      width: "114px",
      height: "114px",
      [theme.breakpoints.down("sm")]: {
        width: "88px",
        height: "84px",
      },
    },
    boxStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    },
    priceStyle: {
      marginBottom: "4px",
    },
  }),
);

export interface CategoryMenuListProps {
  item: any;
  removedLinkedIdList: any;
  handleUpdateIdList: any;
  index: any;
  isColorChange: any;
  data: any;
  revertExpand: any;
  handleSelectProduct: any;
  category: any;
  selectedWrapperIdList: any;
  setSelectedWrapperIdList: any;
  isOwner: any;
}

/**
 * CategoryMenuList Component: Category Menu List
 *
 * This component represents a list item within a category menu. It displays information about an item, including its name,
 * image (if available), and description. Users can interact with the item to view more details or perform actions.
 */
const CategoryMenuList: React.FunctionComponent<CategoryMenuListProps> = ({
  item,
  removedLinkedIdList,
  handleUpdateIdList,
  index,
  isColorChange,
  data,
  revertExpand,
  handleSelectProduct,
  category,
  selectedWrapperIdList,
  setSelectedWrapperIdList,
  isOwner,
}) => {
  const [color, setColor] = useState("");
  const matchesLargeScreen = useMediaQuery("(max-width: 960px)");
  const [rotation, setRotation] = useState(matchesLargeScreen ? 2 : 3);

  const isDarkMode = getLocalStore("isDarkMode");

  /**
   * Effect: Rotate Element Animation
   *
   * This effect animates the rotation of an element over a specified duration.
   */
  useEffect(() => {
    // Calculate the start time of the animation
    const startTime = performance.now();

    // Determine the initial rotation value based on screen size
    const initialRotation = matchesLargeScreen ? 2 : 3;

    // Define the function to handle animation
    function rotateElement(timestamp: any) {
      // Calculate the progress of the animation
      const progress = timestamp - startTime;

      // Calculate the duration of the animation
      const duration = data?.wrapper ? data.wrapper.length * 100 : 0; // 3 seconds

      // Calculate the rotation step based on progress and duration
      const rotationStep =
        initialRotation - (initialRotation / duration) * progress;
      // Set the rotation state
      setRotation(rotationStep);

      // Request the next animation frame if progress is less than duration
      if (progress < duration) {
        requestAnimationFrame(rotateElement);
      }
    }

    // Start the animation
    requestAnimationFrame(rotateElement);

    // Cleanup function to reset rotation if component unmounts before animation completes
    return () => {
      setRotation(matchesLargeScreen ? 2 : 3);
    };
  }, [isColorChange]);

  /**
   * Effect: Change Color Animation
   *
   * This effect animates the color change of an element from one color to another over a specified duration.
   */
  useEffect(() => {
    // Define variables for start time, duration, start color, and end color
    let startTime: any;
    const duration = data.length * 400; // 3 seconds
    const startColor = hexToRgb(
      theme.palette.background.menu_entity_background,
    );
    const endColor = hexToRgb(theme.palette.background.entity_background);

    // Define the function to handle color animation
    function changeColor(timestamp: any) {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;

      // Calculate the new color value based on the progress
      const newColor = startColor.map((startValue, index) => {
        const endValue = endColor[index];
        const difference = endValue - startValue;
        return startValue + (progress / duration) * difference;
      });

      // Update the color state
      setColor(`rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`);

      // Request the next animation frame if progress is less than duration
      if (progress < duration) {
        requestAnimationFrame(changeColor);
      }
    }

    // Start the color animation
    requestAnimationFrame(changeColor);

    // Set a timeout to reset the color state after 3 seconds
    const timeoutId = setTimeout(() => {
      setColor("");
    }, 2000);

    // Cleanup function clears the timeout and resets the color state
    return () => {
      clearTimeout(timeoutId);
      if (!_.isEmpty(color)) {
        setColor("");
      }
    };
  }, [isColorChange]);

  /**
   * Effect: Reset Color on Dark Mode Change
   *
   * This effect resets the color state when the dark mode setting changes.
   */
  useEffect(() => {
    // Check if the color state is not empty
    if (!_.isEmpty(color)) {
      // Reset the color state to an empty string
      setColor("");
    }
  }, [isDarkMode]);

  const handleChangeSurcharge = (e: any) => {
    e.nativeEvent.stopImmediatePropagation(); // Stop immediate event propagation

    const { name, value } = e.target;

    const cloneSelectedWrapperIdList = { ...selectedWrapperIdList }; // Shallow clone using spread operator

    cloneSelectedWrapperIdList[item.id] = {
      ...cloneSelectedWrapperIdList[item.id], // Preserve existing properties
      surcharge: value, // Update surcharge
    };

    setSelectedWrapperIdList(cloneSelectedWrapperIdList);
  };

  const handleSelectIncludeModifiers = () => {
    const cloneSelectedWrapperIdList = { ...selectedWrapperIdList }; // Shallow clone using spread operator

    cloneSelectedWrapperIdList[item.id] = {
      ...cloneSelectedWrapperIdList[item.id], // Preserve existing properties
      include_modifiers: !selectedWrapperIdList[item.id]?.include_modifiers, // Toggle include_modifiers
    };

    setSelectedWrapperIdList(cloneSelectedWrapperIdList);
  };

  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  return (
    <div
      style={{ overflow: "hidden", zIndex: 0 }}
      onClick={(event) => isOwner && handleSelectProduct(event, item)}
    >
      <Card
        style={{
          overflowY: "hidden",
          cursor: "pointer",
          borderRadius: "10px",
          border: `1px solid ${theme.palette.background.entity_border}`,
          boxShadow: "none",
          zIndex: 0,
          backgroundColor:
            item?.valid === false
              ? theme.palette.custom.red.main
              : selectedWrapperIdList[item.id]
              ? theme.palette.custom.blue.main
              : theme.palette.background.entity_background,
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ display: "flex" }}>
            {item?.img ? (
              <Box className={classes.imgBoxStyle}>
                <img
                  className={classes.img}
                  src={handleImageUrl(item?.img)}
                  alt="not available images"
                  style={{
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  }}
                />
              </Box>
            ) : (
              <Box className={classes.imgBoxNotAvailableStyle}>
                <ImageOutlinedIcon style={{ fontSize: "52px" }} />
              </Box>
            )}
            <Grid
              item
              xs
              container
              direction="column"
              style={{ paddingLeft: "8px", paddingTop: "12px" }}
            >
              <Grid item xs>
                <Typography
                  style={
                    item?.valid === false
                      ? { fontWeight: "bold", color: "white" }
                      : { fontWeight: "bold" }
                  }
                  align="left"
                  className={classes.titleStyle}
                >
                  {item?.name}
                </Typography>
                {selectedWrapperIdList[item.id] ? (
                  <div
                    style={{
                      display: "flex",
                      placeItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextfieldDefaultNew
                      style={{ zIndex: 5 }}
                      id="surcharge"
                      name="surcharge"
                      label={"Surcharge"}
                      type="number"
                      value={selectedWrapperIdList[item.id].surcharge}
                      onKeyDown={(e: any) => {
                        e.stopPropagation();
                      }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                      }}
                      disabled={!isOwner}
                      onChange={handleChangeSurcharge}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <FormControlLabel
                        style={{ marginLeft: "0px", marginRight: "0px" }}
                        value="dinein"
                        control={<SwitchCommon />}
                        disabled={!isOwner}
                        label={
                          <Typography variant="caption" color="textSecondary">
                            Modifiers
                          </Typography>
                        }
                        name="dinein"
                        checked={
                          selectedWrapperIdList[item.id].include_modifiers
                        }
                        labelPlacement="bottom"
                        onChange={handleSelectIncludeModifiers}
                      />
                    </div>
                  </div>
                ) : (
                  <Typography
                    className={classes.boxStyle}
                    style={
                      item?.valid === false
                        ? { textTransform: "lowercase", color: "white" }
                        : { textTransform: "lowercase" }
                    }
                    variant="body2"
                    align="left"
                  >
                    {item?.desc}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default CategoryMenuList;
