/* Get the min price in the array. */
const getMinPriceFromArray = (category: any) => {
  const min: any = Math.min.apply(
    null,
    category.data.map((item: any) => item.price),
  );
  return min;
};

/* Get the max price in the array. */
const getMaxPriceFromArray = (category: any) => {
  const max: any = Math.max.apply(
    null,
    category.data.map((item: any) => item.price),
  );
  return max;
};

/* Get the min price in the menu sub products. */
const getMinPriceFromSubProductArray = (category: any) => {
  const min: any = Math.min.apply(
    null,
    category.subProducts.map((item: any) => item.surcharge),
  );
  return min;
};

/* Get the max price in the menu sub products. */
const getMaxPriceFromASubProductsArray = (category: any) => {
  const max: any = Math.max.apply(
    null,
    category.subProducts.map((item: any) => item.surcharge),
  );
  return max;
};

/* Get the min price and max price in the category menu list. */
export const getCategoryMenuPrice = (category: any) => {
  // If category 'itemType' is 'direct',
  if (category.itemType === "direct") {
    // If the category 'itemType' length is one, Only that category price is given.
    if (category.data.length === 1) {
      return `£${parseFloat(category.data[0].price).toFixed(2)}`;
    } else {
      // If the category 'itemType' length is more then one, get the min price and max price.
      const min: any = getMinPriceFromArray(category);
      const max: any = getMaxPriceFromArray(category);
      // If the min and max price in not equal, Show the min and max price.
      if (min !== max) {
        return `£${parseFloat(min).toFixed(2)} - ${parseFloat(max).toFixed(2)}`;
      } else {
        // If the min and max prices in equal, Only show the min price.
        return `£${parseFloat(min).toFixed(2)}`;
      }
    }
  } else {
    // If category 'itemType' is 'byo',
    // If the category price is not equal to '0.00' and the subProducts length is greater than zero, Show the given price.
    if (
      category.data[0].price !== "0.00" &&
      category.data[0].subProducts.length !== 0
    ) {
      return `£${parseFloat(category.data[0].price).toFixed(2)}`;
    } else {
      // If the category price is equal to '0.00' and the subProducts length is  zero, Calculate the min price and max price.
      const min: any = getMinPriceFromSubProductArray(
        category.data[0].subProducts[0],
      );
      const max: any = getMaxPriceFromASubProductsArray(
        category.data[0].subProducts[0],
      );

      // If the min and max price in not equal, Show the min and max price.
      if (min !== max) {
        return `£${parseFloat(min).toFixed(2)} - ${parseFloat(max).toFixed(2)}`;
      } else {
        // If the min and max prices in equal, Only show the min price.
        return `£${parseFloat(min).toFixed(2)}`;
      }
    }
  }
};
