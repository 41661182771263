import { createStyles, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import _ from "lodash";

import CategoryMenuListWrapper from "./CategoryMenuListWrapper";

export interface CategoryMenuListProps {
  removedLinkedIdLists: any;
  wrapper: any;
  item: any;
  handleClickLinkedId: any;
  category: any;
  data: any;
}

/**
 * WrapperDirectory Component: Category Menu List
 *
 * This component represents a list item within a category menu. It displays information about an item, including its name,
 * image (if available), and description. Users can interact with the item to view more details or perform actions.
 */
const WrapperDirectory: React.FunctionComponent<CategoryMenuListProps> = ({
  removedLinkedIdLists,
  wrapper,
  item,
  handleClickLinkedId,
  category,
  data,
}) => {
  return (
    <>
      {removedLinkedIdLists[wrapper.id.toString()]?.directoryLevel ===
        item.directoryLevel &&
        removedLinkedIdLists[wrapper.id.toString()]?.id === item.id && (
          <Grid item md={6} xs={12} lg={4}>
            <CategoryMenuListWrapper
              item={data}
              handleClickLinkedId={handleClickLinkedId}
              category={wrapper}
              categoryId={category.id}
            />
          </Grid>
        )}
    </>
  );
};

export default WrapperDirectory;
