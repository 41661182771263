import { createMuiTheme } from "@material-ui/core/styles";
import commonThemeDark from "../../../root/theme/dark";
import commonThemeLight from "../../../root/theme/light";
import { CustomColors } from "../../../types/customTheme";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    custom: CustomColors;
  }

  interface PaletteOptions {
    custom: CustomColors;
  }
}

export function initTheme({ isDark }: { isDark: boolean }) {
  return isDark
    ? createMuiTheme({
        palette: {
          custom: commonThemeDark.palette.custom,
          background: commonThemeDark.palette.background,
          type: commonThemeDark.palette.type,
          primary: {
            main: "#2C3341",
          },
          secondary: {
            main: "#39d388",
          },
        },
        typography: {
          fontFamily: "Lato, sans-serif",
          h1: {
            color: "#D7D7D7",
          },
          h2: {
            color: "#D7D7D7",
          },
          h3: {
            color: "#D7D7D7",
          },
          h4: {
            color: "#D7D7D7",
          },
          h5: {
            color: "#D7D7D7",
          },
          h6: {
            color: "#D7D7D7",
          },
          body1: {
            color: "#D7D7D7",
          },
          body2: {
            color: "#D7D7D7",
          },
          caption: {
            color: "#D7D7D7",
          },
        },
      })
    : createMuiTheme({
        palette: {
          custom: commonThemeLight.palette.custom,
          background: commonThemeLight.palette.background,
          type: commonThemeLight.palette.type,
          primary: {
            main: "#F4F5F7",
          },
          secondary: {
            main: "#39d388",
          },
        },
        typography: {
          fontFamily: "Lato, sans-serif",
          h1: {
            color: "#253858",
          },
          h2: {
            color: "#253858",
          },
          h3: {
            color: "#253858",
          },
          h4: {
            color: "#253858",
          },
          h5: {
            color: "#253858",
          },
          h6: {
            color: "#253858",
          },
          body1: {
            color: "#253858",
          },
          body2: {
            color: "#253858",
          },
          caption: {
            color: "#253858",
          },
        },
      });
}
