import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import queryString from "query-string";

import {
  DELIVERY_TYPE_DINEIN,
  ERROR_MESSAGE_UNEXPECTED_ERROR,
} from "../../../../utils/consts";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import AvailabilitySelection from "../../../../components/time/AvailabilitySelection";
import {
  isEqualArrayObject,
  isEqualArrays,
} from "../../../../utils/checkArrayEqual";
import { useForm } from "react-hook-form";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import {
  fetchGlobalDepartmentInfo,
  fetchGlobalGroupInfo,
  fetchProductWrapperInfo,
} from "../../../../services/menuApp/printerMenuService";
import { useRouteMatch } from "react-router-dom";
import { CustomTheme } from "../../../../types/customTheme";
import CategoryMenuDropDown from "./CategoryMenuDropDown";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import ProductWrapper from "./ProductWrapper";

interface Props {
  isAppEmbedded: any;
}

const useStyles = makeStyles<any, Props>((theme: CustomTheme) =>
  createStyles({
    root: {
      marginTop: "12px",
    },
    categoryTitle: {
      backgroundColor: theme.palette.background.entity_highlight_background,
      position: "sticky",
      zIndex: 1,
      fontSize: "20px",
      top: "-0.5px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "32px",
      },
      [theme.breakpoints.up("1280")]: {
        fontSize: "32px",
      },
    },
    boxStyleOffline: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "transparent",
      right: 48,
      left: 32,
      zIndex: 50,
    },
    boxStyleOfflineLargeScreen: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.default,
      right: 10,
      zIndex: 50,
    },
  }),
);
export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  handleSelectProduct: any;
  selectedWrapperIdList: any;
  setSelectedWrapperIdList: any;
  handleSubmitRewardsTypePoints: any;
  isEdit: any;
  rewardId: any;
  selectedWrapperIdListInitial: any;
  isLoadingButton: any;
  isOwner: any;
}

/**
 * PointsModalContent Component
 *
 * This component is responsible for rendering a modal dialog for adding, editing, or duplicating a strategy.
 * It includes fields for strategy name, delivery types (Dine In, Delivery, Takeout), and availability.
 * The user can select delivery types using switches and set availability using an availability selection component.
 * Additionally, the component provides options for canceling, saving, or creating the strategy.
 */
const PointsModalContent: React.FunctionComponent<
  StrategyAddEditModalProps
> = ({
  isOpen,
  setIsOpen,
  handleSelectProduct,
  selectedWrapperIdList,
  setSelectedWrapperIdList,
  handleSubmitRewardsTypePoints,
  isEdit,
  rewardId,
  selectedWrapperIdListInitial,
  isLoadingButton,
  isOwner,
}) => {
  const [menuNodeList, setMenuNodeList] = useState<any>([]);
  const [linkedIdList, setLinkedIdList] = useState<any>([]);
  const [isColorChange, setIsColorChange] = useState("");
  const [error, setError] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>([]);
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [selectedCatMenuTitle, setSelectedCatMenuTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [removedLinkedIdList, setRemovedLinkedIdList] = useState<any>([]);
  const [removedLinkedIdLists, setRemovedLinkedIdLists] = useState<any>({});
  const [revertExpand, setRevertExpand] = useState("");

  const match: any = useRouteMatch();

  /**
   * Function: handleChangeCatMenu
   *
   * This function is responsible for handling the change event when selecting a category menu.
   * It updates the selected category menu title state and filters the department list based on the selected menu.
   */

  const handleChangeCatMenu = (event: any) => {
    // Set the selected category menu title
    setSelectedCatMenuTitle(event.name);

    // Filter the department list based on the selected menu ID
    const filterDepartment = menuNodeList.filter(
      (data: any) => data.id === event.id,
    );

    // Set the filtered department list
    setDepartmentList(filterDepartment[0].department);
  };

  /**
   * Function: getGroupInfo
   *
   * This function is responsible for fetching global group information from the backend API using the provided location ID.
   * It then calls the getDepartmentInfo function to proceed with fetching department information.
   */
  const getGroupInfo = async () => {
    try {
      // Fetch global group information from the backend
      const res = await fetchGlobalGroupInfo(match.params.locationId);
      // Proceed with fetching department information
      getDepartmentInfo(res.data.data);
    } catch (err) {
      // Handle unexpected errors by setting the error state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function: getDepartmentInfo
   *
   * This function is responsible for fetching global department information from the backend API using the provided location ID.
   * It then calls the getProductInfo function to proceed with fetching product information.
   */
  const getDepartmentInfo = async (groupNode: any) => {
    try {
      // Fetch global department information from the backend
      const res = await fetchGlobalDepartmentInfo(match.params.locationId);
      // Proceed with fetching product information
      getProductInfo(groupNode, res.data.data);
    } catch (err) {
      // Handle unexpected errors by setting the error state and isLoading state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * Function: getProductInfo
   *
   * This function is responsible for fetching global product information from the backend API using the provided location ID.
   * It then calls the handleMenuRestructure function to restructure the menu data accordingly.
   */
  const getProductInfo = async (groupNode: any, departmentNode: any) => {
    try {
      // Fetch global product information from the backend
      const res = await fetchProductWrapperInfo(match.params.locationId);
      // // Restructure the menu data based on group, department, and product information
      const menu = await handleMenuRestructure(
        // group,
        // department,
        // productWrapper,
        groupNode,
        departmentNode,
        res.data.data,
      );

      // // Update the menuNodeList state with the restructured menu data
      setMenuNodeList(menu);
      // Set isLoading to false to indicate the loading process has ended
      setIsLoading(false);
    } catch (err) {
      // Handle unexpected errors by setting the error state and isLoading state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  function convertInputToOutput(productWrapper: any) {
    const output = [];
    const directories: any = {};

    // Group items by their directoryId
    productWrapper.forEach((item: any) => {
      if (item.directoryId in directories) {
        directories[item.directoryId].push(item);
      } else {
        directories[item.directoryId] = [item];
      }
    });

    // Recursive function to build wrapper structure
    function buildWrapper(directoryId: any) {
      if (directoryId in directories) {
        const items = directories[directoryId];
        return items.map((item: any) => {
          const {
            directoryId,
            directoryLevel,
            id,
            name,
            allYouCanEat,
            categoryId,
            colour,
            cover,
            created,
            desc,
            directory,
            img,
            itemType,
            kiosk,
            locationId,
            nameShort,
            template,
            timeFrom,
            timeTo,
            updated,
            valid,
            version,
            viewOrder,
            weekFrom,
            weekTo,
          } = item;
          const wrapper = buildWrapper(id);
          if (wrapper.length > 0) {
            return {
              directoryId,
              directoryLevel,
              id,
              name,
              wrapper,
              allYouCanEat,
              categoryId,
              colour,
              cover,
              created,
              desc,
              directory,
              img,
              itemType,
              kiosk,
              locationId,
              nameShort,
              template,
              timeFrom,
              timeTo,
              updated,
              valid,
              version,
              viewOrder,
              weekFrom,
              weekTo,
            };
          } else {
            return {
              directoryId,
              directoryLevel,
              id,
              name,
              allYouCanEat,
              categoryId,
              colour,
              cover,
              created,
              desc,
              directory,
              img,
              itemType,
              kiosk,
              locationId,
              nameShort,
              template,
              timeFrom,
              timeTo,
              updated,
              valid,
              version,
              viewOrder,
              weekFrom,
              weekTo,
            };
          }
        });
      } else {
        return [];
      }
    }

    // Build wrapper structure for items with directoryId -99
    output.push(...buildWrapper(-99));

    return output;
  }

  /**
   * Function: handleMenuRestructure
   *
   * This function is responsible for restructuring the menu data based on the provided group, department, and product nodes.
   * It filters and sorts the nodes and organizes them into a hierarchical structure.
   */
  const handleMenuRestructure = (
    groupNode: any,
    departmentNode: any,
    productNode: any,
  ) => {
    // Check if the group, department, and product nodes are not empty
    if (
      !_.isEmpty(groupNode) &&
      !_.isEmpty(departmentNode) &&
      !_.isEmpty(productNode)
    ) {
      // Sort department, product, and group nodes based on viewOrder property
      const filterDepartment = departmentNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const filterProduct = productNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const filterGroup = groupNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );

      // Initialize variables to store linked IDs and restructured menu data
      const linkedId: any = [];
      const output: any = [];
      let groupObject: any = {};
      // Iterate over each group node
      filterGroup.forEach((groupItem: any) => {
        if (groupItem.id > 0) {
          groupObject = {
            ...groupItem,
            department: [],
          };
        }

        // Filter matching departments for the current group
        const matchingDepartments = filterDepartment.filter(
          (departmentItem: any) =>
            departmentItem.id > 0 &&
            departmentItem.groupId === groupItem.id &&
            departmentItem.name !== "Online Hidden" &&
            departmentItem.name !== "Templates" &&
            departmentItem.name !== "Hidden Food" &&
            departmentItem.name !== "Hidden Drinks",
        );

        // Iterate over each matching department
        matchingDepartments.forEach((departmentItem: any) => {
          // Create a department object with product object
          const departmentObject: any = {
            ...departmentItem,
            product: [],
          };

          // Filter matching products for the current department
          const matchingProducts = filterProduct.filter(
            (productItem: any) =>
              productItem.categoryId === departmentItem.id &&
              productItem.id > 0,
          );

          if (convertInputToOutput(matchingProducts).length > 0) {
            departmentObject.product.push(
              ...convertInputToOutput(matchingProducts),
            );
          }

          // // Add department to the group
          if (departmentObject?.product?.length !== 0) {
            groupObject.department.push(departmentObject);
          }
        });
        if (
          groupObject &&
          groupObject?.department &&
          groupObject?.department?.length !== 0
        ) {
          // Add group to the output
          output.push(groupObject);
        }
      });
      // Update linkedIdList state with the linked IDs
      setLinkedIdList(linkedId);
      // Return the restructured menu data
      return output;
    }
  };

  /**
   * useEffect Hook: useEffect to Update Selected Category Menu and Department List
   *
   * This useEffect hook is responsible for updating the selected category menu title and department list
   * when the menuNodeList changes. It runs whenever the menuNodeList state is updated.
   */
  useEffect(() => {
    // Check if the menuNodeList is not empty
    if (!_.isEmpty(menuNodeList)) {
      // Extract the first menu object from the menuNodeLis
      const menu: any = Object.values(menuNodeList);

      // Set the selected category menu title to the name of the first menu
      setSelectedCatMenuTitle(menu[0].name);

      // Extract the department list from the first menu object
      const filterDepartment = menu[0].department;

      // Set the department list state variable
      setDepartmentList(filterDepartment);
    }
  }, [menuNodeList]);

  useEffect(() => {
    getGroupInfo();
  }, []);

  const handleClearStrip = (product: any) => {
    const cloneRemovedLinkedIdLists = _.cloneDeep(removedLinkedIdLists);
    delete cloneRemovedLinkedIdLists[product.id];
    setRemovedLinkedIdLists(cloneRemovedLinkedIdLists);
  };

  /**
   * handleUpdateIdList Function: Handle Update of Linked ID List
   *
   * This function is responsible for updating the list of removed linked IDs when a product is updated.
   * It filters out the ID of the updated product from the removed linked ID list and updates the necessary state variables.
   */
  const handleUpdateIdList = (event: any, product: any) => {
    // Check if an event exists and stop its propagation to prevent further event handling
    if (event) {
      event.stopPropagation();
    }

    // Filter out the ID of the updated product from the removed linked ID list
    const removeId = removedLinkedIdList.filter(
      (data: any) => data !== product.directoryId,
    );

    const removeIds = Object.keys(removedLinkedIdList).filter((data: any) => {
      return data !== product.directoryId;
    });

    // Update the removedLinkedIdList state with the filtered list of IDs
    setRemovedLinkedIdList(removeId);
    setRemovedLinkedIdLists(removeIds);

    // Set the color change state to indicate the change in the updated product
    setIsColorChange(product.directoryId);
  };

  /**
   * handleClickLinkedId Function: Handle Click Event for Linked IDs
   *
   * This function is responsible for handling the click event when linked IDs are selected.
   * It filters the selected IDs from the event data, compares them with the existing linked ID list,
   * and updates the state variables removedLinkedIdList and revertExpand accordingly.
   */

  const handleClickLinkedId = (e: any, category: any) => {
    const cloneIdLiST = _.cloneDeep(removedLinkedIdList);
    setRevertExpand(e.id);
    cloneIdLiST.push(e.id);
    const cloneIdLiSTs = _.cloneDeep(removedLinkedIdLists);

    if (e.directoryLevel === 1) {
      cloneIdLiSTs[e.id] = {
        id: e.id,
        directoryLevel: e.directoryLevel,
      };
    } else {
      cloneIdLiSTs[category.directoryId] = {
        id: e.id,
        directoryLevel: e.directoryLevel,
      };
    }
    // Update removedLinkedIdList by concatenating existing removed IDs with the new ones
    setRemovedLinkedIdList(cloneIdLiST);
    setRemovedLinkedIdLists(cloneIdLiSTs);
  };

  const handleNavigate = (product: any, level: any, data: any) => {
    const cloneRemovedLinkedIdLists = _.cloneDeep(removedLinkedIdLists);
    cloneRemovedLinkedIdLists[data.directoryId].id = product.id;
    cloneRemovedLinkedIdLists[data.directoryId].directoryLevel = level;
    setRemovedLinkedIdLists(cloneRemovedLinkedIdLists);
  };

  const isAppEmbedded = queryString.parse(window.location.search).isAppEmbedded;

  const classes = useStyles({ isAppEmbedded });
  const maxWidthCustom = useMediaQuery("(max-width: 1280px)");

  const deepEqual = (obj1: any, obj2: any) => {
    // Check if both are objects
    if (
      typeof obj1 === "object" &&
      obj1 !== null &&
      typeof obj2 === "object" &&
      obj2 !== null
    ) {
      // Check if they have the same number of keys
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      // Check if all keys and values are equal
      for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
      return true;
    } else {
      // If not objects, check for strict equality
      return obj1 === obj2;
    }
  };

  // Map to store elements by title
  const elementsByTitle: any = {};

  const handleButtonClickMobile = (title: any) => {
    // Scroll to the target element when the button is clicked
    const targetElement = elementsByTitle[title];
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth", // You can use 'auto' for instant scrolling
        top: 0, // Scroll to the top of the element
      });
    }
  };

  // Function to register elements with their titles in the map
  const registerElementWithTitle = (title: any, elementRef: any) => {
    elementsByTitle[title] = elementRef;
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const opens = Boolean(anchorEl);
  const theme: CustomTheme = useTheme();

  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={true}
        maxWidth={"xl"}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "14%",
            }}
          >
            <CircularProgress color="secondary" disableShrink />
          </div>
        ) : (
          <>
            <div style={{ display: "block" }}>
              <div
                style={{
                  position: "fixed",
                  top: "32px",
                  paddingTop: "20px",
                  zIndex: 1000,
                  right: "50px",
                  left: "57px",
                  backgroundColor:
                    theme.palette.background.entity_highlight_background,
                }}
              >
                <div style={{ marginRight: "28px" }}>
                  <Typography variant="h4">
                    {isEdit ? "Edit rewards" : "Add rewards"}
                  </Typography>
                  <Grid container spacing={2} className={classes.root}>
                    {!_.isEmpty(menuNodeList) && (
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {selectedCatMenuTitle && (
                              <CategoryMenuDropDown
                                categoryMenuList={menuNodeList}
                                selectedCatMenuTitle={selectedCatMenuTitle}
                                handleChangeCatMenu={handleChangeCatMenu}
                              />
                            )}

                            {departmentList && (
                              <>
                                <IconButton
                                  id="basic-button"
                                  style={{
                                    fontSize: 11,
                                    marginLeft: "12px",
                                    backgroundColor:
                                      theme.palette.background
                                        .entity_background,
                                  }}
                                  onClick={handleClick}
                                >
                                  {opens ? <CloseIcon /> : <MenuIcon />}
                                </IconButton>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={opens}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                  PaperProps={{
                                    elevation: 0,
                                    style: {
                                      borderRadius: "10px",
                                      border: `1px solid ${theme.palette.background.entity_border}`,
                                      marginTop: "60px",
                                      marginLeft: "40px",
                                      top: "320px",
                                      left: "70x",
                                      bottom: "34px",
                                    },
                                  }}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  {!_.isEmpty(departmentList) &&
                                    departmentList.map((department: any) => (
                                      <MenuItem
                                        onClick={() => {
                                          handleButtonClickMobile(
                                            `department ${department.id}`,
                                          );
                                          handleClose();
                                        }}
                                        style={{
                                          textTransform: "none",
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Typography
                                        // style={
                                        //   selectedDepartmentId === department.id
                                        //     ? { fontWeight: "bold" }
                                        //     : { fontWeight: "normal" }
                                        // }
                                        >
                                          {department.name}
                                        </Typography>
                                      </MenuItem>
                                    ))}
                                </Menu>
                              </>
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            <ButtonCommon
                              variant="contained"
                              style={{
                                fontSize: 11,
                                width: "100px",
                                marginRight: 8,
                                height: "32px",
                              }}
                              disabled={isLoadingButton}
                              color="yellow"
                              onClick={() => setIsOpen(false)}
                            >
                              Cancel
                            </ButtonCommon>
                            <ButtonCommon
                              variant="contained"
                              style={{
                                fontSize: 11,
                                marginLeft: 4,
                                width: "100px",
                                height: "32px",
                              }}
                              isLoadingPage={isLoadingButton}
                              disabled={isLoadingButton || !isOwner}
                              color={isEdit ? "orange" : "green"}
                              onClick={() =>
                                handleSubmitRewardsTypePoints(
                                  selectedWrapperIdList,
                                  isEdit,
                                  rewardId,
                                )
                              }
                            >
                              {isEdit ? "Save" : "Create"}
                            </ButtonCommon>
                          </div>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </div>
            </div>
            <DialogContent style={{ padding: "0px 24px", marginTop: "160px" }}>
              {!_.isEmpty(menuNodeList) ? (
                departmentList.map((category: any, index: any) => {
                  return (
                    <div>
                      {console.log("sfsdf45asdfsdgfdgfdgdfg", category.name)}
                      <Typography
                        align="left"
                        className={classes.categoryTitle}
                        ref={(ref) =>
                          registerElementWithTitle(
                            `department ${category.id}`,
                            ref,
                          )
                        }
                      >
                        {category.name}
                      </Typography>
                      <Grid
                        container
                        style={{
                          marginTop:
                            index === 0 &&
                            maxWidthCustom &&
                            isAppEmbedded !== "true"
                              ? "12px"
                              : "0px",
                        }}
                      >
                        <ProductWrapper
                          wrapperProduct={category.product}
                          removedLinkedIdList={removedLinkedIdList}
                          handleUpdateIdList={handleUpdateIdList}
                          index={index}
                          isColorChange={isColorChange}
                          revertExpand={revertExpand}
                          category={category}
                          handleClickLinkedId={handleClickLinkedId}
                          removedLinkedIdLists={removedLinkedIdLists}
                          handleNavigate={handleNavigate}
                          handleClearStrip={handleClearStrip}
                          handleSelectProduct={handleSelectProduct}
                          selectedWrapperIdList={selectedWrapperIdList}
                          setSelectedWrapperIdList={setSelectedWrapperIdList}
                          isOwner={isOwner}
                        />
                      </Grid>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: "16%",
                  }}
                >
                  <Typography variant="h3">No Menus Available.</Typography>
                </div>
              )}
            </DialogContent>
          </>
        )}
      </DialogCommonDefault>
    </>
  );
};

export default PointsModalContent;
