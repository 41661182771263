import React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";

import { CustomTheme } from "../../../../../types/customTheme";
import { purchaseUnitList } from "../../../../../utils/consts/list";
import SwitchCommon from "../../../../../components/switches/SwitchCommon";
import TextFieldCustom from "../../../../../components/textField/TextFieldCustom";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    groupLabel: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: `1px solid ${theme.palette.background.entity_border}`,
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    labelStyle: {
      fontSize: "17px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "15px",
      },
    },
    boxTitleColor: {
      "&:hover": {
        color: "white",
      },
    },
    boxSubTitleColor: {
      "&:hover": {
        color: "white",
      },
    },
    boxesStyleSelected: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      width: "100%",
      textTransform: "none",
      height: "100%",
      color: "white",
      cursor: "pointer",
      backgroundColor: theme.palette.custom.green.main,
      "&:hover": {
        backgroundColor: theme.palette.custom.green.main,
        color: "white",
      },
    },
    boxesStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      width: "100%",
      textTransform: "none",
      height: "100%",
      cursor: "pointer",
      backgroundColor:
        theme.palette.background.entity_highlight_background_current_footer,
      "&:hover": {
        backgroundColor: theme.palette.custom.green.main,
        color: "white",
      },
    },
  }),
);

export interface StepThreeProps {
  handleChangePurchaseUnit: any;
  purchaseUnitObj: any;
  purchaseUnit: any;
  setPurchaseUnit: any;
  baseUnit: any;
  setBaseUnit: any;
  inputMultiplier: any;
  setInputMultiplier: any;
  handleChangeAutoConversion: any;
  isShowSeparate: any;
  isAutoConversion: any;
  handleChangeShowSeparate: any;
  isClickCustom: any;
}

/**
 * StepThree Component
 *
 * This component represents the third step of a wizard modal.
 * It prompts the user to select the measurement unit in which they purchase stock.
 */
const StepThree: React.FunctionComponent<StepThreeProps> = ({
  handleChangePurchaseUnit,
  purchaseUnitObj,
  purchaseUnit,
  setPurchaseUnit,
  baseUnit,
  setBaseUnit,
  inputMultiplier,
  setInputMultiplier,
  handleChangeAutoConversion,
  isShowSeparate,
  isAutoConversion,
  handleChangeShowSeparate,
  isClickCustom,
}) => {
  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  const tabletScreenSize = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Typography variant="h6">
        Please select the measurement unit in which you purchase stock
      </Typography>
      <Typography variant="body1">
        This unit will be utilised for creating stock purchase orders
      </Typography>
      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          {Object.entries(purchaseUnitList).map(([key, value]: any) => (
            <>
              <Typography
                variant="h6"
                style={{
                  color: theme.palette.custom.yellow.main,
                  marginTop: "12px",
                }}
              >
                {key}
              </Typography>

              {tabletScreenSize ? (
                <Grid container>
                  {value.map((data: any) => (
                    <Grid item xs={4} sm={2}>
                      <Box
                        gridColumn="span 2"
                        onClick={() => handleChangePurchaseUnit(data)}
                        style={{
                          height: "80px",
                          width: "80px",
                          marginTop: "12px",
                        }}
                      >
                        <Button
                          className={
                            purchaseUnit === data.label ||
                            (isClickCustom && data.label === "Custom")
                              ? classes.boxesStyleSelected
                              : classes.boxesStyle
                          }
                        >
                          <div>
                            <div
                              style={{ fontWeight: "bold", fontSize: "20px" }}
                            >
                              {data.label}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "12px",
                              }}
                            >
                              {data.name}
                            </div>
                          </div>
                        </Button>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
                  {value.map((data: any) => (
                    <Box
                      gridColumn="span 2"
                      onClick={() => handleChangePurchaseUnit(data)}
                      style={{
                        height: "80px",
                        width: "80px",
                        marginTop: "12px",
                      }}
                    >
                      <Button
                        className={
                          purchaseUnit === data.label ||
                          (isClickCustom && data.label === "Custom")
                            ? classes.boxesStyleSelected
                            : classes.boxesStyle
                        }
                      >
                        <div>
                          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {data.label}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "12px",
                            }}
                          >
                            {data.name}
                          </div>
                        </div>
                      </Button>
                    </Box>
                  ))}
                </Box>
              )}
            </>
          ))}
        </Grid>
        {purchaseUnitObj && purchaseUnitObj.value === "PurchaseUnitCustom" && (
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <TextFieldCustom
              id="purchaseUnit"
              name="purchaseUnit"
              type="text"
              label={
                <Typography className={classes.labelStyle}>
                  Purchase Unit
                </Typography>
              }
              disabled={false}
              value={purchaseUnit}
              onChange={(e: any) => setPurchaseUnit(e.target.value)}
            />
          </Grid>
        )}
        {purchaseUnitObj && purchaseUnitObj.value === "PurchaseUnitCustom" && (
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <TextFieldCustom
              id="baseUnit"
              name="baseUnit"
              type="text"
              label={
                <Typography className={classes.labelStyle}>
                  Selling Unit
                </Typography>
              }
              disabled={false}
              value={baseUnit}
              onChange={(e: any) => setBaseUnit(e.target.value)}
            />
          </Grid>
        )}
        {purchaseUnitObj && purchaseUnitObj.value === "PurchaseUnitCustom" && (
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <TextFieldCustom
              id="inputMultiplier"
              name="inputMultiplier"
              type="number"
              label={
                <Typography className={classes.labelStyle}>
                  {`How many '${baseUnit ? baseUnit : "Selling Unit"}' in a '${
                    purchaseUnit ? purchaseUnit : "Purchase Unit"
                  }'`}
                </Typography>
              }
              disabled={false}
              value={inputMultiplier}
              onChange={(e: any) => setInputMultiplier(e.target.value)}
            />
          </Grid>
        )}

        {purchaseUnitObj && purchaseUnitObj.value === "PurchaseUnitCustom" && (
          <Grid item xs={6} style={{ marginTop: "8px" }}>
            <FormControlLabel
              style={{ marginLeft: "4px", marginRight: "0px" }}
              value="autoConversion"
              control={<SwitchCommon />}
              label={
                <Typography variant="body2" color="textSecondary">
                  Auto Conversion
                </Typography>
              }
              name="autoConversion"
              checked={isAutoConversion}
              labelPlacement="start"
              onChange={handleChangeAutoConversion}
            />
          </Grid>
        )}
        {purchaseUnitObj && purchaseUnitObj.value === "PurchaseUnitCustom" && (
          <Grid item xs={6} style={{ marginTop: "8px" }}>
            <FormControlLabel
              style={{ marginLeft: "0px", marginRight: "0px" }}
              value="showSeparate"
              control={<SwitchCommon />}
              disabled={!isAutoConversion}
              label={
                <Typography variant="body2" color="textSecondary">
                  Show Separate
                </Typography>
              }
              name="showSeparate"
              checked={isShowSeparate}
              labelPlacement="start"
              onChange={handleChangeShowSeparate}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default StepThree;
