import { axiosClient } from "../client";

const queryString = require("query-string");

const CUSTOMER_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CUSTOMER}/location/${locationId}/customers/list`;

const API_GET_LOYALTY_PROGRAM = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/getProgramInfo`;

const API_GET_REWARDS = (locationId, strategyId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/getRewards/${strategyId}`;

const API_GET_LOYALTY_STRATEGY = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/getStrategy`;

const API_CREATE_STRATEGY = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/addStrategy`;

const API_CREATE_REWARDS = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/addRewards`;

const API_UPDATE_REWARDS = (locationId, rewardId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/updateRewards/${rewardId}`;

const API_UPDATE_LOYALTY_PROGRAM = (locationId, programId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/program/${programId}`;

const API_UPDATE_STRATEGY_PROGRAM = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/updateStrategy`;

const API_UPDATE_STRATEGY_STATUS = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/activeStrategy`;

const API_UPDATE_LOCATION_PROGRAM = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOYALTY}/location/${locationId}/changeLocationProgram`;

export const fetchRewardsInfo = (locationId, strategyId) =>
  axiosClient({
    method: "GET",
    url: API_GET_REWARDS(locationId, strategyId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchLoyaltyProgramInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: API_GET_LOYALTY_PROGRAM(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchLoyaltyStrategyInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: API_GET_LOYALTY_STRATEGY(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const createNewStrategyInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: API_CREATE_STRATEGY(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const createNewRewardsInfo = (locationId, data) =>
    axiosClient({
      method: "POST",
      url: API_CREATE_REWARDS(locationId),
      data,
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
      },
    });

    export const updateRewardsInfo = (locationId, rewardId, data) =>
      axiosClient({
        method: "PUT",
        url: API_UPDATE_REWARDS(locationId, rewardId),
        data,
        headers: {
          "X-Role-Location": locationId,
          "Content-Type": "application/json",
        },
      });
  

  export const updateStrategyInfo = (locationId, data) =>
    axiosClient({
      method: "PUT",
      data,
      url: API_UPDATE_STRATEGY_PROGRAM(locationId),
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
      },
    });

    export const updateStrategyStatus= (locationId, data) =>
      axiosClient({
        method: "PUT",
        data,
        url: API_UPDATE_STRATEGY_STATUS(locationId),
        headers: {
          "X-Role-Location": locationId,
          "Content-Type": "application/json",
        },
      });

export const updateProgramStatus = (locationId, programId, data) =>
  axiosClient({
    method: "PUT",
    data,
    url: API_UPDATE_LOYALTY_PROGRAM(locationId, programId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateLocationProgramStatus = (locationId, programId, data) =>
  axiosClient({
    method: "PUT",
    data,
    url: API_UPDATE_LOYALTY_PROGRAM(locationId, programId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateLocationProgram = (locationId, data) =>
  axiosClient({
    method: "PUT",
    data,
    url: API_UPDATE_LOCATION_PROGRAM(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchAllCustomerInfo = (
  token,
  locationId,
  size,
  currentPage,
  shortingMethod,
  shortingHeaderName,
  filterData,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: CUSTOMER_INFO_GET_ALL(locationId),
        query: {
          size: size,
          page: currentPage,
          sort: [shortingHeaderName, shortingMethod],
          where: filterData,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
