import {
  Box,
  createStyles,
  Dialog,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import CloseIcon from "@material-ui/icons/Close";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import CardCommon from "../../../../components/card/CardCommon";
import _ from "lodash";
import { HttpStatus } from "../../../../utils/enum";
import {
  combineMinuteOfWeekArrays,
  normalizeTimeSelections,
} from "../../../../utils/time-conversions";
import {
  ERROR_MESSAGE_UPDATING_ERROR,
  ERROR_MESSAGE_VERSION_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../utils/consts";
import { updateMenuOptionInfo } from "../../../../services/menuApp/categoryMenuService";
import { useRouteMatch } from "react-router-dom";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import CatMenuBasicInfoModal from "./Modal/CatMenuBasicInfoModal";
import AvailabilityModal from "./Modal/AvailabilityModal";
import MenuBasicInfoModal from "./Modal/MenuBasicInfoModal";
import ByoModal from "./Modal/ByoModal";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    boxStyle: {
      backgroundColor: theme.palette.custom.purple.main,
      color: theme.palette.custom.green.contrastText,
      minHeight: "60px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    imgBoxNotAvailableStyle: {
      marginTop: "10px",
      marginLeft: "16px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      height: "200px",
      width: "200px",
      backgroundColor: theme.palette.background.imgBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      marginTop: "10px",
      width: "200px",
      height: "200px",
      marginLeft: "16px",
      objectFit: "cover",
    },
    imgPaper: {
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: theme.palette.background.default,
    },
    tabPanel: {
      marginTop: "4px",
      marginLeft: "16px",
      marginRight: "16px",
      backgroundColor: theme.palette.background.default,
    },
    tabStyle: {
      marginLeft: "16px",
      marginRight: "16px",
    },
    rootTab: {
      backgroundColor: theme.palette.background.default,
    },
    replayIcon: {
      fontSize: "24px",
      color: theme.palette.custom["yellow"].main,
    },
    replayIconDisable: {
      fontSize: "24px",
    },
  }),
);

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`discount-type-tab-${index}`}
      aria-labelledby={`discount-type-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ padding: "16px 0" }}>{children}</div>}
    </div>
  );
};

const allDeliveryTypesList = ["dinein", "delivery", "takeout"];

export interface MenuOptionProps {
  openMenuOption: boolean;
  setOpenMenuOption: any;
  category: any;
  menuOptionNodeList: any;
  getMenuOptionInfo: any;
  handleStageOneModifierEditPageOpen: any;
  handleOpenBasicInfoRevertModal: any;
  isLoadingPage: any;
  isLoadingPageCatMenu: any;
  isOpenCatMenuModal: any;
  isOpenCatModal: boolean;
  setIsOpenCatMenuRevertModal: any;
  handleSubmitCatMenu: any;
  reset: any;
  register: any;
  setIsOpenCatModal: any;
  errors: any;
  isDirty: any;
  changeState: any;
  handleStageTwoModifierEditPageOpen: any;
  handleSubmitMenuBasicInfo: any;
}

const MenuOption: React.FunctionComponent<MenuOptionProps> = ({
  openMenuOption,
  setOpenMenuOption,
  category,
  menuOptionNodeList,
  getMenuOptionInfo,
  handleStageOneModifierEditPageOpen,
  handleOpenBasicInfoRevertModal,
  isLoadingPage,
  isLoadingPageCatMenu,
  isOpenCatMenuModal,
  isOpenCatModal,
  setIsOpenCatMenuRevertModal,
  handleSubmitCatMenu,
  setIsOpenCatModal,
  reset,
  register,
  errors,
  isDirty,
  changeState,
  handleStageTwoModifierEditPageOpen,
  handleSubmitMenuBasicInfo,
}) => {
  const [value, setValue] = useState(0);
  const [entityId, setEntityId] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isActiveInitial, setIsActiveInitial] = useState(true);
  const [deliveryTypesListInitial, setDeliveryTypesListInitial] = useState<any>(
    [],
  );
  const [deliveryTypesList, setDeliveryTypesList] = useState<any>([]);
  const [isDeliveryTypeEmpty, setIsDeliveryTypeEmpty] = useState(false);
  const [availabilityList, setAvailabilityList] = useState<any>([]);
  const [availabilityListInitial, setAvailabilityListInitial] = useState<any>(
    [],
  );
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [allStageIndex, setAllStageIndex] = useState<any>([]);
  const [originalNodeData, setOriginalNodeData] = useState<any>({
    entityId: "",
    isActive: false,
    availability: [],
    deliveryTypes: [],
  });

  const match: any = useRouteMatch();

  /* Switch the tab to be displayed. */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  /* Update states after creating delete or update menu information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const { entityId, isActive, deliveryTypes } = nodeValues;
    setEntityId(entityId);
    setIsActive(isActive);
    setIsActiveInitial(isActive);
    // If there is not 'deliveryType' for menu, all 'deliveryType' are activated.
    if (_.isEmpty(deliveryTypes)) {
      setDeliveryTypesList(allDeliveryTypesList);
      setDeliveryTypesListInitial(allDeliveryTypesList);
    } else {
      // If there is 'deliveryType' for menu, Only that 'deliveryType' is activated.
      setDeliveryTypesList(deliveryTypes);
      setDeliveryTypesListInitial(deliveryTypes);
    }
  }, []);

  useEffect(() => {
    reset({
      title: category.title,
    });
    let menuId = "";

    // If the cat modal is opened, the letter 'C' is added before the title for 'menuId'.
    if (isOpenCatModal) {
      menuId = "CM" + category.catMenuId;
    } else if (isOpenCatMenuModal) {
      // If the cat menu modal is opened, the letter 'CM' is added before the title for 'menuId'.
      menuId = "C" + category.catId;
    } else {
      // If the menu modal is opened, the letter 'M' is added before the title for 'menuId'.
      menuId = "M" + category.menuId;

      // Reset the form data using API data.
      let sizePriceLisObject: any = {};
      category.data.map((data: any, index: number) => {
        if (index === 0) {
          sizePriceLisObject["title"] = category.title;
          sizePriceLisObject["desc"] = category.desc;
        }
        sizePriceLisObject[`size${index}`] = data.size;
        sizePriceLisObject[`price${index}`] = data.price;
      });
      reset(sizePriceLisObject);
    }

    // Filter menu details using the selected menuId
    const filteredMenu = Object.keys(menuOptionNodeList.options)
      .filter((menu) => menu === menuId)
      .reduce((obj: any, key: any) => {
        obj[key] = menuOptionNodeList.options[key];
        return obj;
      }, {});
    if (!_.isEmpty(filteredMenu)) {
      const menuDetails = Object.values(filteredMenu);
      setOriginalNodeData(menuDetails[0]);
      setNodeDataToState(menuDetails[0]);
      setAvailabilityList(menuDetails[0]);
      setAvailabilityListInitial(menuDetails[0]);
    } else {
      setEntityId(menuId);
      setAvailabilityList(originalNodeData);
      setAvailabilityListInitial(originalNodeData);
      setDeliveryTypesList(allDeliveryTypesList);
    }
  }, [changeState]);

  /* Active switch status change using switch */
  const handleSwitchActivate = (e: any) => {
    const { checked } = e.target;
    setIsActive(checked);
  };

  /* Update the deliveryTypeList after selecting the delivery options using switches (dine-in, takeout, and delivery). */
  const handleSelectDeliveryTypes = (e: any) => {
    const { value, name, checked } = e.target;
    // When a delivery type option is selected, that delivery type option is entered into a list.
    if (checked) {
      const selectedList: any = new Set([...deliveryTypesList, value]);
      setDeliveryTypesList([...selectedList]);
      // Check the deliveryTypeList is empty
      if (_.isEmpty(selectedList)) {
        setIsDeliveryTypeEmpty(true);
      } else {
        setIsDeliveryTypeEmpty(false);
      }
    } else {
      // When a delivery type option is not selected, that delivery type option is removed into a list.
      const selectedList: any = deliveryTypesList.filter(
        (amountType: String) => name !== amountType,
      );
      setDeliveryTypesList(selectedList);
      // Check the deliveryTypeList is empty
      if (_.isEmpty(selectedList)) {
        setIsDeliveryTypeEmpty(true);
      } else {
        setIsDeliveryTypeEmpty(false);
      }
    }
  };

  /* Entering availability data into a state.*/
  const handleSubmitAvailability = async (
    availabilitySelectionList: Array<any>,
  ) => {
    const combinedMowArrays: any = combineMinuteOfWeekArrays(
      availabilitySelectionList,
    );
    const normalizedArray = _.cloneDeep(combinedMowArrays);

    // Remove select same date range in same same date.
    Object.keys(combinedMowArrays).forEach((day) => {
      normalizedArray[day] = normalizeTimeSelections(normalizedArray[day]);
    });
    const nowArray: any = Object.values(normalizedArray).flat();
    const data = _.cloneDeep(originalNodeData);

    // Update data
    data.availability = nowArray;
    setAvailabilityList(data);
  };

  /* Update availability information */
  const handleSubmitData = async () => {
    const data = _.cloneDeep(originalNodeData);
    data.entityId = entityId;
    data.isActive = isActive;
    data.availability = availabilityList.availability;

    // When delivery, takeout, and dine-in are selected for the deliveryType, send '[]' to the backend as the 'deliveryTypes'.
    if (deliveryTypesList.length === 3) {
      data.deliveryTypes = [];
    } else {
      data.deliveryTypes = deliveryTypesList;
    }

    let menu = menuOptionNodeList;
    var options = Object.assign({}, menuOptionNodeList.options);
    options[entityId] = data;
    menu.options = options;
    if (_.isEmpty(deliveryTypesList)) {
      setIsDeliveryTypeEmpty(true);
    } else {
      await updateMenuOptionInfo(match.params.locationId, menu.id, menu)
        .then((res) => {
          setSuccess(SUCCESSFULLY_CREATED);
          getMenuOptionInfo();
          let menuId = "";
          if (isOpenCatModal) {
            menuId = "CM" + category.catMenuId;
          } else if (isOpenCatMenuModal) {
            menuId = "C" + category.catId;
          } else {
            menuId = "M" + category.menuId;
          }
          const filteredMenu = Object.keys(res.data.data.options)
            .filter((menu) => menu === menuId)
            .reduce((obj: any, key: any) => {
              obj[key] = res.data.data.options[key];
              return obj;
            }, {});

          let val = Object.values(filteredMenu);
          setIsDeliveryTypeEmpty(false);
          setOriginalNodeData(val[0]);
          setNodeDataToState(val[0]);
          setAvailabilityList(val[0]);
          setAvailabilityListInitial(val[0]);
        })

        .catch((err) => {
          setIsDeliveryTypeEmpty(false);
          if (err.response.status === HttpStatus.CONFLICT_409) {
            setError(ERROR_MESSAGE_VERSION_ERROR);
          } else {
            setError(ERROR_MESSAGE_UPDATING_ERROR);
          }
        });
    }
  };

  /* Check the delivery type, availability, or active button is changed or not. */
  const handleDisable = () => {
    const isEqual = _.isEqual(deliveryTypesList, deliveryTypesListInitial);
    if (
      isActive === isActiveInitial &&
      isEqual &&
      _.isEqual(availabilityList, availabilityListInitial)
    ) {
      return true;
    } else {
      return false;
    }
  };

  /* Change the image pixel size */
  const handleImageSizeReduce = (url: any) => {
    if (url.includes("cloudinary")) {
      const d = url.split("upload");
      const c = d[0] + "upload/c_fill,g_center,h_524,w_768";
      const t = c + d[1];

      return t;
    } else {
      return url;
    }
  };

  const handleItemDropDownOpen = (index: any) => {
    const selectedList: any = new Set([...allStageIndex, index]);
    setAllStageIndex([...selectedList]);
  };

  const handleItemDropDownClose = (index: any) => {
    const selectedList: any = allStageIndex.filter(
      (paymentType: String) => index !== paymentType,
    );
    setAllStageIndex(selectedList);
  };

  /* If there is an error, Show it. */
  const handleError = () => {
    const error: any = Object.values(errors)[0];
    if (error) {
      return <ValidationMessage message={error.message} />;
    } else {
      return "";
    }
  };

  /* Change the revert icon color. */
  const replayIconStyle = (origin: any) => {
    /* If the origin value is null, show the disable icon. */
    if (origin === null) {
      return classes.replayIconDisable;
    } else {
      /* If the origin value is not null, show the yellow color icon. */
      return classes.replayIcon;
    }
  };

  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
            border: `1px solid ${theme.palette.background.entity_border}`,
          },
        }}
        fullWidth
        maxWidth="md"
        open={openMenuOption}
        onClose={() => setOpenMenuOption(false)}
        disableBackdropClick
      >
        <Box className={classes.boxStyle}>
          <Typography
            style={{ color: theme.palette.custom.purple.contrastText }}
          >
            Edit Product "{category.title}"
          </Typography>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenMenuOption(false);
              setIsOpenCatModal(false);
            }}
          />
        </Box>
        <div className={classes.rootTab}>
          <div className={classes.imgPaper}>
            {!isOpenCatModal &&
            !isOpenCatMenuModal &&
            !_.isEmpty(category) &&
            category.data[0].imageUrl ? (
              <img
                className={classes.img}
                src={handleImageSizeReduce(category.data[0].imageUrl)}
                alt="not available images"
                style={{
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_border}`,
                }}
              />
            ) : (
              <Box className={classes.imgBoxNotAvailableStyle}>
                <ImageOutlinedIcon style={{ fontSize: "80px" }} />
              </Box>
            )}
          </div>

          <div className={classes.tabStyle}>
            <CardCommon>
              <Tabs
                centered
                value={value}
                onChange={handleChange}
                aria-label="discount-types-tabs"
                variant="fullWidth"
              >
                <Tab
                  label="BASIC INFO"
                  id="discount-type-tab-0"
                  aria-controls="discount-type-tab-0"
                />

                {!isOpenCatModal &&
                  !isOpenCatMenuModal &&
                  category.itemType === "byo" && (
                    <Tab
                      label="MODIFIERS"
                      id="discount-type-tab-1"
                      aria-controls="discount-type-tab-1"
                    />
                  )}
                <Tab
                  label="AVAILABILITY"
                  id="discount-type-tab-1"
                  aria-controls="discount-type-tab-1"
                />
              </Tabs>
            </CardCommon>
          </div>
          {isOpenCatModal || isOpenCatMenuModal ? (
            <TabPanel value={value} index={0}>
              <CatMenuBasicInfoModal
                register={register}
                errors={errors}
                replayIconStyle={replayIconStyle}
                category={category}
                setIsOpenCatMenuRevertModal={setIsOpenCatMenuRevertModal}
                isDirty={isDirty}
                isLoadingPageCatMenu={isLoadingPageCatMenu}
                handleSubmitCatMenu={handleSubmitCatMenu}
                isLoadingPage={isLoadingPage}
              />
            </TabPanel>
          ) : (
            <TabPanel value={value} index={0}>
              <MenuBasicInfoModal
                register={register}
                errors={errors}
                replayIconStyle={replayIconStyle}
                category={category}
                handleOpenBasicInfoRevertModal={handleOpenBasicInfoRevertModal}
                isDirty={isDirty}
                handleError={handleError}
                isLoadingPage={isLoadingPage}
                handleSubmitMenuBasicInfo={handleSubmitMenuBasicInfo}
              />
            </TabPanel>
          )}
          {!isOpenCatModal &&
            !isOpenCatMenuModal &&
            category.itemType === "byo" && (
              <TabPanel value={value} index={1}>
                <ByoModal
                  handleStageOneModifierEditPageOpen={
                    handleStageOneModifierEditPageOpen
                  }
                  handleItemDropDownOpen={handleItemDropDownOpen}
                  handleItemDropDownClose={handleItemDropDownClose}
                  allStageIndex={allStageIndex}
                  category={category}
                  handleStageTwoModifierEditPageOpen={
                    handleStageTwoModifierEditPageOpen
                  }
                />
              </TabPanel>
            )}
          <TabPanel value={value} index={category.itemType === "byo" ? 2 : 1}>
            <AvailabilityModal
              deliveryTypesList={deliveryTypesList}
              handleSelectDeliveryTypes={handleSelectDeliveryTypes}
              isDeliveryTypeEmpty={isDeliveryTypeEmpty}
              availabilityList={availabilityList}
              handleSubmitAvailability={handleSubmitAvailability}
              isActive={isActive}
              handleSwitchActivate={handleSwitchActivate}
              handleDisable={handleDisable}
              handleSubmitData={handleSubmitData}
            />
          </TabPanel>
        </div>
      </Dialog>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity={"error"}
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
    </>
  );
};

export default withAuthority(MenuOption, Authorities.MENU_READ);
