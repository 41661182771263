import * as React from "react";
import ShapeButton from "../../shapes/ShapeButton";
import ShapeCircle from "../../shapes/ShapeCircle";
import ShapeLine from "../../shapes/ShapeLine";
import ShapeTextField from "../../shapes/ShapeTextField";

export interface PreviewCheckoutProps {
  backgroundColor: string;
  textColor: string;
  stepperColor: string;
  buttonColor: string;
  buttonTextColor: string;
}

/* Show user selected color for color preview box.
(backgroundColor, textColor, stepperColor, buttonColor, buttonTextColor) */
const PreviewCheckout: React.FunctionComponent<PreviewCheckoutProps> = ({
  backgroundColor,
  textColor,
  stepperColor,
  buttonColor,
  buttonTextColor,
}) => {

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ShapeCircle radius={3} color={stepperColor} />
        <div
          style={{
            flex: 1,
            backgroundColor: textColor,
            height: 0.5,
            margin: 4,
          }}
        ></div>
        <ShapeCircle radius={3} color={stepperColor} />
        <div
          style={{
            flex: 1,
            backgroundColor: textColor,
            height: 0.5,
            margin: 4,
          }}
        ></div>
        <ShapeCircle radius={3} color={stepperColor} />
      </div>
      <div style={{ margin: 40 }}></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ShapeLine length={5} height={2} color={textColor} />
      </div>
      <div style={{ margin: 32 }}></div>
      <ShapeTextField bgColor={backgroundColor} />
      <div style={{ margin: 16 }}></div>
      <ShapeTextField bgColor={backgroundColor} />
      <div style={{ margin: 16 }}></div>
      <ShapeTextField bgColor={backgroundColor} />
      <div style={{ margin: 16 }}></div>
      <ShapeTextField bgColor={backgroundColor} />
      <div style={{ margin: 16 }}></div>
      <ShapeTextField bgColor={backgroundColor} />
      <div style={{ margin: 16 }}></div>
      <ShapeButton
        length={4}
        color={buttonColor}
        height={2}
        textColor={buttonTextColor}
      />
    </>
  );
};

export default PreviewCheckout;
