/* Return the order status color. */
export const getColorByStatus = (status: any) => {
  switch (status) {
    case "started":
      return "#7A7A7A";
      break;
    case "checkout":
      return "#BFA136";
      break;
    case "pending":
      return "#FFC700";
      break;
    case "new":
      return "#3179D6";
      break;
    case "pos":
      return "#8720B0";
      break;
    case "pre":
      return "#8D61C7";
      break;
    case "accepted":
      return "#E38D36";
      break;
    case "rejected":
      return "#DB3939";
      break;
    case "ready":
      return "#74BA4E";
      break;
    case "out_for_delivery":
      return "#7ABD6A";
      break;
    case "delivered":
      return "#368F48";
      break;
    case "cancel":
      return "#B53859";
      break;
    case "cancelled":
      return "#E34F63";
      break;
    default:
      break;
  }
};

export const getFontColorByStatus = (status: any) => {
  switch (status) {
    case "started":
      return "#ffffff";
      break;
    case "checkout":
      return "#000000";
      break;
    case "pending":
      return "#000000";
      break;
    case "new":
      return "#ffffff";
      break;
    case "pos":
      return "#ffffff";
      break;
    case "pre":
      return "#ffffff";
      break;
    case "accepted":
      return "#000000";
      break;
    case "rejected":
      return "#ffffff";
      break;
    case "ready":
      return "#000000";
      break;
    case "out_for_delivery":
      return "#000000";
      break;
    case "delivered":
      return "#ffffff";
      break;
    case "cancel":
      return "#ffffff";
      break;
    case "cancelled":
      return "#ffffff";
      break;
    default:
      break;
  }
};

/* Return the delivery channel color. */
export const getColorByDeliveryChannel = (status: any) => {
  switch (status) {
    case "eatpresto":
      return "#ff4d4d";
      break;
    case "deliverect":
      return "#33cc33";
      break;
    case "justeat":
      return "#ff6600";
      break;
    case "ubereats":
      return "#47d147";
      break;
    case "deliveroo":
      return "#00b3b3";
      break;
    case "other":
      return "#1a66ff";
      break;
    default:
      break;
  }
};
