import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 16,
      width: "75%",
    },
  }),
);

export interface ShapeLineProps {
  length?: number;
  color?: string;
  height?: number;
}

/* Changing the text color preview UI. */
const ShapeLine: React.FunctionComponent<ShapeLineProps> = ({
  length,
  color,
  height,
}) => {
  const classes = useStyles();

  let width = "100%";
  let lineHeight = 12;

  // Changing the preview width
  switch (length) {
    case 1:
      width = "20%";
      break;
    case 2:
      width = "40%";
      break;
    case 3:
      width = "60%";
      break;
    case 4:
      width = "80%";
      break;
    case 5:
      width = "100%";
      break;
    default:
      break;
  }

  // Changing the preview height
  switch (height) {
    case 1:
      lineHeight = 8;
      break;
    case 2:
      lineHeight = 16;
      break;
    case 3:
      lineHeight = 20;
      break;
    case 4:
      lineHeight = 24;
      break;
    default:
      break;
  }

  return (
    <div
      className={classes.root}
      style={{
        width: width,
        backgroundColor: color || "#fff",
        height: lineHeight,
      }}
    ></div>
  );
};

export default ShapeLine;
