import { CircularProgress } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import ChartSkeletonLoading from "../components/common/Loading/ChartSkeletonLoading";
import TextSkeletonLoading from "../components/common/Loading/TextSkeletonLoading";

const handleLoadingMethod = (loadingMethod: string) => {
  if (_.isEmpty(loadingMethod)) {
    return <CircularProgress color="secondary" size={50} />;
  } else if (loadingMethod === "text") {
    return <TextSkeletonLoading color="secondary" />;
  } else if (loadingMethod === "chart") {
    return <ChartSkeletonLoading color="secondary" />;
  }
};

/* To display a loader until data is retrieved from the API call. */
const WithLoading = (Component: any, loadingMethod?: any) => (props: any) => {
  if (!props.isLoading) {
    return <Component {...props} />;
  } else {
    return (
      <div
        style={
          _.isEmpty(loadingMethod)
            ? {
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "16%",
              }
            : {
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }
        }
      >
        {handleLoadingMethod(loadingMethod)}
      </div>
    );
  }
};

export default WithLoading;
