import React from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import _ from "lodash";
import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import SalesInfoNode from "./SalesInfoNode";

export interface SalesHeaderProps {
  nodeData: any;
  handleChangeOrderListSorting: any;
  handleRemoveOrderListSorting: any;
  disableButton: any;
  isLoading: any;
  locationSelectorList: any;
  userList: any;
  numberOfLocations: any;
  getUserInfo: any;
  isCancelledSale: any;
  isModal?: any;
  getSaleFilterInfo: any;
  isPaymentTypeLoading: any;
  setIsPaymentTypeLoading: any;
  setIsDiscountLoading: any;
  isDiscountLoading: any;
  isSort: any;
  headerName: any;
  sortingMethod: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      marginTop: "1px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "16px",
      },
    },
    headerContainerStyle: {
      marginTop: "16px",
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    headerModalContainerStyle: {
      marginTop: "0px",
      position: "sticky",
      top: "0",
      zIndex: 5,
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      paddingLeft: "12px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
    headerLeftAlignStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
    headerItemStyle: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
    },
    headerItemStyleLast: {
      display: "flex",
      justifyContent: "end",
      placeItems: "center",
      paddingRight: "44px",
    },
  }),
);

/* This shows the header of the sale and cancel sale table. */
const SalesHeader: React.FunctionComponent<SalesHeaderProps> = ({
  nodeData,
  handleChangeOrderListSorting,
  handleRemoveOrderListSorting,
  disableButton,
  isLoading,
  locationSelectorList,
  userList,
  numberOfLocations,
  getUserInfo,
  isCancelledSale,
  isModal,
  getSaleFilterInfo,
  isPaymentTypeLoading,
  setIsPaymentTypeLoading,
  setIsDiscountLoading,
  isDiscountLoading,
  isSort,
  headerName,
  sortingMethod,
}) => {
  const classes = useStyles();
  const maxWidth = useMediaQuery("(max-width: 960px)");
  return (
    <>
      {!maxWidth && (
        <div
          className={
            isModal
              ? classes.headerModalContainerStyle
              : classes.headerContainerStyle
          }
        >
          {/* header */}
          <CardCommon backgroundColor={"table_header_background"}>
            <Grid container style={{ minHeight: "36px" }}>
              {numberOfLocations > 1 && (
                <Grid item xs={3} className={classes.headerFirstItemStyle}>
                  <SortingMethod
                    handleChangeOrderListSorting={handleChangeOrderListSorting}
                    handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                    headerName={"Location"}
                    headerId={"locationId"}
                    disableButton={disableButton}
                    color={"orange"}
                    isSort={headerName === "locationId" ? isSort : false}
                    sortingMethod={headerName === "locationId" ? sortingMethod : ""}
                  />
                </Grid>
              )}
              <Grid item xs={1} className={classes.headerFirstItemStyle}>
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Channel"}
                  headerId={"orderChannel"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "orderChannel" ? isSort : false}
                  sortingMethod={headerName === "orderChannel" ? sortingMethod : ""}
                />
              </Grid>
              <Grid item xs={1} className={classes.headerFirstItemStyle}>
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Platform"}
                  headerId={"platform"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "platform" ? isSort : false}
                  sortingMethod={headerName === "platform" ? sortingMethod : ""}
                />
              </Grid>
              <Grid item xs={1} className={classes.headerLeftAlignStyle}>
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Mode"}
                  headerId={"mode"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "mode" ? isSort : false}
                  sortingMethod={headerName === "mode" ? sortingMethod : ""}
                />
              </Grid>
              <Grid item xs={1} className={classes.headerFirstItemStyle}>
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Order Ref"}
                  headerId={"queOrderNo"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "queOrderNo" ? isSort : false}
                  sortingMethod={headerName === "queOrderNo" ? sortingMethod : ""}
                />
              </Grid>
              <Grid item xs={1} className={classes.headerFirstItemStyle}>
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Order No"}
                  headerId={"orderNo"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "orderNo" ? isSort : false}
                  sortingMethod={headerName === "orderNo" ? sortingMethod : ""}
                />
              </Grid>
              <Grid
                item
                xs={numberOfLocations > 1 ? 1 : 2}
                className={classes.headerFirstItemStyle}
              >
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Channel Order No"}
                  headerId={"extQueOrderNo"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "extQueOrderNo" ? isSort : false}
                  sortingMethod={headerName === "extQueOrderNo" ? sortingMethod : ""}
                />
              </Grid>
              <Grid
                item
                xs={numberOfLocations > 1 ? 2 : 3}
                className={classes.headerItemStyle}
              >
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Date"}
                  headerId={"date"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "date" ? isSort : false}
                  sortingMethod={headerName === "date" ? sortingMethod : ""}
                />
              </Grid>
              <Grid
                item
                xs={numberOfLocations > 1 ? 1 : 2}
                className={classes.headerItemStyleLast}
              >
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName="Amount"
                  headerId="totalAmount"
                  disableButton={disableButton}
                  color="orange"
                  isSort={headerName === "totalAmount" ? isSort : false}
                  sortingMethod={headerName === "totalAmount" ? sortingMethod : ""}
                />
              </Grid>
            </Grid>
          </CardCommon>
        </div>
      )}
      {/* body */}
      <Grid container className={classes.root}>
        {!_.isEmpty(nodeData) ? (
          nodeData.map((nodeData: any) => (
            <Grid
              item
              xs={12}
              key={nodeData.id}
              style={{ placeItems: "center", marginTop: "4px" }}
            >
              <SalesInfoNode
                nodeData={nodeData}
                locationSelectorList={locationSelectorList}
                userList={userList}
                numberOfLocations={numberOfLocations}
                getUserInfo={getUserInfo}
                isCancelledSale={isCancelledSale}
                getSaleFilterInfo={getSaleFilterInfo}
                isPaymentTypeLoading={isPaymentTypeLoading}
                setIsPaymentTypeLoading={setIsPaymentTypeLoading}
                setIsDiscountLoading={setIsDiscountLoading}
                isDiscountLoading={isDiscountLoading}
              />
            </Grid>
          ))
        ) : (
          <div
            style={
              isModal && _.isEmpty(nodeData)
                ? { width: "100%", margin: "auto", marginTop: "2%" }
                : { width: "100%", margin: "auto", marginTop: "5%" }
            }
          >
            <Typography variant="h4" align="center">
              No sales available.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};

export default WithLoading(SalesHeader);
