/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { makeStyles, createStyles, useTheme } from "@material-ui/core";
import { DateRange } from "react-date-range";
import React from "react";
import { CustomTheme } from "../../../types/customTheme";
import { checkDateRangeFromTwoDates } from "../../../utils/checkDateRangeFromTwoDates";

export interface SortingMethodProps {
  handleChangeDateRange: any;
  dateSelectionRange: any;
  setOpen: any;
}

const useStyles = makeStyles<any>((theme: CustomTheme) =>
  createStyles({
    mobileCalendarWrapper: {
      "& .rdrDateDisplayItem": {
        backgroundColor: theme.palette.background.entity_highlight_background,
      },
      "& .rdrStartEdge": {
        backgroundColor: theme.palette.background.default,
      },
      "& .rdrInRange": {
        backgroundColor: theme.palette.background.default,
      },
      "& .rdrEndEdge": {
        backgroundColor: theme.palette.background.default,
      },
      "& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span":
        {
          color: theme.palette.text.primary,
          fontWeight: "bold",
        },
      "& .rdrDateDisplay": {
        backgroundColor: theme.palette.background.entity_highlight_background,
      },
      "& .rdrDateDisplayItem input": {
        backgroundColor: theme.palette.background.entity_highlight_background,
        color: theme.palette.text.primary,
      },
      "& .rdrCalendarWrapper": {
        backgroundColor: theme.palette.background.entity_highlight_background,
      },
      "& .rdrMonthAndYearWrapper": {
        backgroundColor: theme.palette.background.entity_highlight_background,
      },
      "& .rdrMonthAndYearPickers select": {
        color: theme.palette.text.primary,
      },
      "& .rdrNextPrevButton": {
        backgroundColor: theme.palette.grey,
      },
      "& .rdrDateDisplayWrapper": {
        backgroundColor: theme.palette.background.entity_highlight_background,
      },
      "& .rdrMonth": {
        backgroundColor: theme.palette.background.entity_highlight_background,
      },
      "& .rdrDayPassive .rdrDayNumber span": {
        color: theme.palette.text.hint,
      },
      "& .rdrDayNumber span": {
        color: theme.palette.text.primary,
      },
      "& .rdrWeekDay": {
        color: theme.palette.text.primary,
      },
    },
  }),
);

/* Mobile date picker */
const MobileDatePicker: React.FunctionComponent<SortingMethodProps> = ({
  handleChangeDateRange,
  dateSelectionRange,
  setOpen,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <DateRange
      className={classes.mobileCalendarWrapper}
      editableDateInputs={true}
      onChange={(ranges: any) => {
        handleChangeDateRange(ranges);
        if (ranges.selection.startDate !== ranges.selection.endDate) {
          /* Returns true if the selected date range is today, yesterday, this week, 
             last week, this month, last month, this year, or last year, otherwise returns false. */
          const result = checkDateRangeFromTwoDates(ranges);
          /* Close the modal if the selected date range is today, yesterday, this week, 
             last week, this month, last month, this year, or last year. */
          if (result.specificTimeSpan === true) {
            setOpen(false);
          }
        }
      }}
      moveRangeOnFirstSelection={false}
      ranges={dateSelectionRange}
      rangeColors={[theme.palette.primary.main]}
    />
  );
};

export default MobileDatePicker;
