import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";

import _ from "lodash";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../../utils/consts";
import CardCommon from "../../../../../components/card/CardCommon";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { HttpStatus, buttonColors } from "../../../../../utils/enum";
import PrinterNode from "./PrinterNode";
import { AxiosError } from "axios";
import { CustomTheme } from "../../../../../types/customTheme";
import CategoryMenuList from "../CategoryMenuList";
import { handleImageUrl } from "../../../../../utils/ChangePixelImgUrl";
import { convertByoName } from "../../../../../utils/convertByoName";

export interface MappingModalProps {
  isOpen: any;
  setIsOpen: any;
  stackNode: any;
  handleOpenMappingModal: any;
  category: any;
  setIsOpenModal: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    discountTypeSection: {
      borderRadius: 8,
    },
    tabPanel: {
      backgroundColor: theme.palette.background.paper,
    },
    circleStyle: {
      fontSize: "16px",
      marginTop: "4px",
      color: theme.palette.custom.green.main,
    },
    imgBoxStyle: {
      margin: "7px",
      marginLeft: "10px",
      borderRadius: "10px",
      height: "120px",
      [theme.breakpoints.down("sm")]: {
        height: "92px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
    titleStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",

      [theme.breakpoints.down("sm")]: {
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    imgBoxNotAvailableStyle: {
      marginRight: "7px",
      marginTop: "10px",
      marginLeft: "10px",
      borderRadius: "10px",
      marginBottom: "10px",
      height: "114px",
      width: "114px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
        width: "80px",
      },
      backgroundColor: theme.palette.background.imgBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      objectFit: "cover",
      width: "114px",
      height: "114px",
      [theme.breakpoints.down("sm")]: {
        width: "88px",
        height: "84px",
      },
    },
    boxStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    },
    priceStyle: {
      marginBottom: "4px",
    },
  }),
);

/**
 * StackModal Component
 *
 * This component renders a modal for mapping printers. It displays tabs for different discount types
 * and allows users to map printers accordingly.
 */
const StackModal: React.FunctionComponent<MappingModalProps> = ({
  isOpen,
  setIsOpen,
  stackNode,
  handleOpenMappingModal,
  category,
  setIsOpenModal,
}) => {
  const theme: CustomTheme = useTheme();
  const classes: any = useStyles();

  return (
    <>
      {
        <DialogCommonDefault
          open={isOpen}
          setOpen={setIsOpen}
          isNeedFixedHeight={true}
          maxWidth={"xl"}
        >
          <DialogTitle>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5">Select Product</Typography>
              <ButtonCommon
                variant="contained"
                style={{ fontSize: 11, width: "100px" }}
                color={buttonColors.CANCEL_BUTTON_COLOR}
                onClick={() => {
                  setIsOpen(false);
                  setIsOpenModal(false);
                }}
              >
                Cancel
              </ButtonCommon>
            </div>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {!_.isEmpty(stackNode) &&
                stackNode.map((product: any) => (
                  <Grid
                    item
                    md={6}
                    xs={12}
                    lg={4}
                    style={{ marginTop: "10px", marginBottom: "6px" }}
                  >
                    <CardCommon
                      backgroundColor={"default"}
                      style={{ borderRadius: "10px" }}
                    >
                      <div
                        style={{ overflow: "hidden", zIndex: 0 }}
                        onClick={(event) =>
                          handleOpenMappingModal(
                            event,
                            category,
                            product,
                            "item",
                            true,
                          )
                        }
                      >
                        <Card
                          style={{
                            overflowY: "hidden",
                            cursor: "pointer",
                            borderRadius: "10px",
                            border: `1px solid ${theme.palette.background.entity_border}`,
                            boxShadow: "none",
                            backgroundColor:
                              product?.valid === false
                                ? theme.palette.custom.red.main
                                : theme.palette.background.entity_background,
                          }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={9}
                              sm={10}
                              md={10}
                              lg={10}
                              xl={10}
                              style={{ display: "flex" }}
                            >
                              {product?.img ? (
                                <Box className={classes.imgBoxStyle}>
                                  <img
                                    className={classes.img}
                                    src={handleImageUrl(product?.img)}
                                    alt="not available images"
                                    style={{
                                      borderRadius: "10px",
                                      border: `1px solid ${theme.palette.background.entity_border}`,
                                    }}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  className={classes.imgBoxNotAvailableStyle}
                                >
                                  <ImageOutlinedIcon
                                    style={{ fontSize: "52px" }}
                                  />
                                </Box>
                              )}
                              <Grid
                                item
                                xs
                                container
                                direction="column"
                                style={{
                                  paddingLeft: "8px",
                                  paddingTop: "12px",
                                }}
                              >
                                <Grid item xs>
                                  <Typography
                                    style={
                                      product?.valid === false
                                        ? { fontWeight: "bold", color: "white" }
                                        : { fontWeight: "bold" }
                                    }
                                    align="left"
                                    className={classes.titleStyle}
                                  >
                                    {convertByoName(product?.name, product?.size, product?.isByo)}
                                  </Typography>
                                  <Typography
                                    className={classes.boxStyle}
                                    style={
                                      product?.valid === false
                                        ? {
                                            textTransform: "lowercase",
                                            color: "white",
                                          }
                                        : { textTransform: "lowercase" }
                                    }
                                    variant="body2"
                                    align="left"
                                  >
                                    {product?.desc}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </div>
                    </CardCommon>
                  </Grid>
                ))}
            </Grid>
          </DialogContent>
        </DialogCommonDefault>
      }
    </>
  );
};

export default StackModal;
