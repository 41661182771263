import React from "react";
import { Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import _ from "lodash";

import { CustomTheme } from "../../../../../types/customTheme";
import TextFieldCustom from "../../../../../components/textField/TextFieldCustom";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    labelStyle: {
      fontSize: "17px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "15px",
      },
    },
  }),
);

export interface StepSixProps {
  criticalThreshold: any;
  setCriticalThreshold: any;
  baseUnit: any
}

/**
 * StepSix Component
 *
 * This component represents the sixth step of a wizard modal.
 * It prompts the user to choose their preferred critical threshold level.
 */
const StepSix: React.FunctionComponent<StepSixProps> = ({
  criticalThreshold,
  setCriticalThreshold,
  baseUnit
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6">
        Please choose your preferred critical threshold level
      </Typography>
      <Typography variant="body1">
        Establish a critical threshold to flag extremely low stock levels during
        purchase orders.
      </Typography>
      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <TextFieldCustom
            id="criticalThreshold"
            name="criticalThreshold"
            type="number"
            label={
              <Typography className={classes.labelStyle}>
                {`Critical Threshold in ${baseUnit}`}
              </Typography>
            }
            value={criticalThreshold}
            disabled={false}
            onChange={(e: any) => setCriticalThreshold(e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StepSix;
