import React from "react";
import {
  createStyles,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";

import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    textField: {
      marginLeft: "4px",
      overflowWrap: "break-word",
      borderRadius: "10px",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      height: "40px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface DeliveryAreaProps {
  area: any;
  setArea: any;
  value: any;
}

/**
 * `DeliveryArea` component represents the input field for specifying the delivery area radius or radius cutoff.
 * It utilizes Material-UI's `TextField` component for user input and displays either "Radius" or "Radius Cutoff"
 * based on the provided `value` prop.
 */
const DeliveryArea: React.FunctionComponent<DeliveryAreaProps> = ({
  area,
  setArea,
  value,
}) => {
  /**
   * Handles the change event when the user inputs the delivery area radius.
   * Limits the input length to 3 characters and updates the delivery area information accordingly.
   */
  const handleRadiusChange = (e: any) => {
    // Extract the value from the input event
    const { value } = e.target;

    // Check if the input length exceeds 3 characters, and return if so
    if (value.length > 3) return;

    // Create a copy of the current delivery area object
    const areaUpdated = { ...area };

    // Update the 'data' property of the delivery area with the new distance value
    areaUpdated.data = { distance: value };

    // Call the 'setArea' function to update the state with the modified delivery area
    setArea(areaUpdated);
  };

  const classes = useStyles();

  return (
    <TextField
      label={
        <Typography variant="body2">
          {value === "postcode" ? "Radius Cutoff" : "Radius"}
        </Typography>
      }
      variant="outlined"
      className={classes.textField}
      type="number"
      value={area.data.distance}
      size="small"
      onChange={handleRadiusChange}
      inputProps={{ min: 0 }}
    />
  );
};

export default DeliveryArea;
