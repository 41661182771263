import { axiosClient } from "../client";

const TASK_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_TASKS}/location/${locationId}/task`;

const TASK_INFO_UPDATE_ALL = (locationId, taskOptionId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_TASKS}/location/${locationId}/task/${taskOptionId}`;

const TASK_INFO_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_TASKS}/location/${locationId}/task`;

const TASK_INFO_DELETE = (locationId, taskOptionId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_TASKS}/location/${locationId}/task/${taskOptionId}`;

export const fetchAllTaskInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: TASK_INFO_GET_ALL(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateTaskInfo = (locationId, taskOptionId, data) =>
  axiosClient({
    method: "PUT",
    url: TASK_INFO_UPDATE_ALL(locationId, taskOptionId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const createTaskInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: TASK_INFO_CREATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const deleteTaskInfo = (locationId, taskOptionId) =>
  axiosClient({
    method: "DELETE",
    url: TASK_INFO_DELETE(locationId, taskOptionId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
