import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { updateOpeningTimeSchedule } from "../../../services/locationApp/openingTimesService";
import { ERROR_MESSAGE_UPDATING_ERROR } from "../../../utils/consts";
import {
  combineMinuteOfWeekArrays,
  deconstructMinuteOfWeekArray,
  getOpenCloseTimesList,
  normalizeTimeSelections,
} from "../../../utils/time-conversions";
import DefaultAlert from "../../alerts/DefaultAlert";
import CardCommon from "../../card/CardCommon";
import OpeningTimeSlot from "./OpeningTimeSlot";

export interface EditOpeningTimesModalProps {
  openingTimesSchedule: any;
  locationId: any;
  fetchOpeningTimes: any;
}

/* Create and update opening time slot */
const EditOpeningTimesModal: React.FunctionComponent<
  EditOpeningTimesModalProps
> = ({ openingTimesSchedule, locationId, fetchOpeningTimes }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState("");
  const [openingTimesSelectionList, setOpeningTimesSelectionList] =
    useState<any>([]);

  /* If there are opening hours details then change the UI using data. */
  useEffect(() => {
    if (!open) return;
    const openCloseTimes = getOpenCloseTimesList(openingTimesSchedule.weekly);
    const deconstructedMinuteOfWeekArray = deconstructMinuteOfWeekArray(
      openingTimesSchedule.weekly,
      openCloseTimes,
    );
    setOpeningTimesSelectionList(deconstructedMinuteOfWeekArray);
  }, [open, openingTimesSchedule.weekly]);

  /* Open the opening time selection modal */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /* Close the opening time selection modal */
  const handleClose = () => {
    setOpen(false);
  };

  /* Update the opening time shedule */
  const handleSubmit = async () => {
    try {
      /* availabilitySelectionList = [{openTime: '11:49', closeTime: '11:49', id: 2, 
                                         nodeList: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                                    {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]}]
           combinedMowArrays = {tue: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                thu:  {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]} */
      const combinedMowArrays: any = combineMinuteOfWeekArrays(
        openingTimesSelectionList,
      );
      let normalizedArray = _.cloneDeep(combinedMowArrays);

      // Remove select same date range in same same date.
      Object.keys(combinedMowArrays).forEach((day) => {
        normalizedArray[day] = normalizeTimeSelections(normalizedArray[day]);
      });

      // Convert array to minute of week array
      const mowArray = Object.values(normalizedArray).flat();
      const data = _.cloneDeep(openingTimesSchedule);
      data.weekly = mowArray;
      await updateOpeningTimeSchedule(
        locationId,
        openingTimesSchedule.id,
        data,
      );
      fetchOpeningTimes();
      setOpen(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UPDATING_ERROR);
    }
  };

  /* Create new time slot UI using initial data. */
  const handleAdd = () => {
    setOpeningTimesSelectionList([
      ...openingTimesSelectionList,
      {
        closeTime: "22:00",
        id: Math.random(),
        nodeList: [],
        openTime: "08:00",
      },
    ]);
  };

  return (
    <div>
      <Button variant={"text"} color="secondary" onClick={handleClickOpen}>
        Edit
      </Button>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
      >
        <CardCommon backgroundColor={"entity_background"}>
          <div>
            <DialogTitle id="alert-dialog-title">{"Set Hours"}</DialogTitle>
            <div style={{ padding: 16 }}>
              {openingTimesSelectionList.map(
                (openingTimeSelection: any, i: number) => (
                  <OpeningTimeSlot
                    openingTimesSelectionList={openingTimesSelectionList}
                    openingTimeSelection={openingTimeSelection}
                    setOpeningTimesSelectionList={setOpeningTimesSelectionList}
                  />
                ),
              )}
              <Button onClick={handleAdd} color="secondary">
                Add +
              </Button>
            </div>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="secondary" autoFocus>
                Apply
              </Button>
            </DialogActions>
          </div>
        </CardCommon>
      </Dialog>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </div>
  );
};

export default EditOpeningTimesModal;
