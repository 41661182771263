import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import CardCommon from "../../../../components/card/CardCommon";
import locationDashboard from "../../../../assets/images/locationDashboard.png";
import { Icon } from "@iconify/react";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgStyle: {
      width: "340px",
      height: "340px",
      [theme.breakpoints.down("sm")]: {
        width: "280px",
        height: "280px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "320px",
        height: "320px",
      },
    },
    imgStyleHidden: {
      paddingRight: "32px",
      display: "flex",
      justifyContent: "end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        paddingRight: "0px",
      },
    },
  }),
);

export interface LocationDetailsProps {
  locationData: any;
}

/* This component displays 'email', 'contact number', 'website', 
   and 'address', and get the location details using API call. */
const LocationDetails: React.FunctionComponent<LocationDetailsProps> = ({
  locationData,
}) => {
  const [locationId, setLocationId] = useState("");
  const [businessDisplayName, setBusinessDisplayName] = useState("");
  const [businessRegName, setBusinessRegName] = useState("");
  const [businessTradingName, setBusinessTradingName] = useState("");
  const [success, setSuccess] = useState("");

  /* Update the state using 'locationData' */
  useEffect(() => {
    const { id, businessDisplayName, businessRegName, businessTradingName } =
      locationData;
    // If there is an id, the id value will be entered into the state.
    if (!_.isEmpty(id)) {
      setLocationId(id);
    } else {
      // If there is no id, the state will be entered as 'N/A'.
      setLocationId("N/A");
    }

    // If there is an businessDisplayName, the businessDisplayName value will be entered into the state.
    if (!_.isEmpty(businessDisplayName)) {
      setBusinessDisplayName(businessDisplayName);
    } else {
      // If there is no businessDisplayName, the state will be entered as 'N/A'.
      setBusinessDisplayName("N/A");
    }

    // If there is an businessRegName, the businessRegName value will be entered into the state.
    if (!_.isEmpty(businessRegName)) {
      setBusinessRegName(businessRegName);
    } else {
      // If there is no businessRegName, the state will be entered as 'N/A'.
      setBusinessRegName("N/A");
    }

    // If there is an businessTradingName, the businessTradingName value will be entered into the state.
    if (!_.isEmpty(businessTradingName)) {
      setBusinessTradingName(businessTradingName);
    } else {
      // If there is no businessTradingName, the state will be entered as 'N/A'.
      setBusinessTradingName("N/A");
    }
  }, [locationData]);

  const classes = useStyles();
  return (
    <div>
      <CardCommon backgroundColor={"entity_background"}>
        <Grid container style={{ placeItems: "center" }}>
          <Grid
            item
            md={6}
            sm={7}
            xs={12}
            style={{ padding: "16px 0px 16px 40px" }}
          >
            <div>
              <div>
                <div style={{ display: "flex" }}>
                  <Typography align="left" variant="h6">
                    Location ID
                  </Typography>
                  <div
                    onClick={() => {
                      setSuccess("Copied!");
                      navigator.clipboard.writeText(locationId);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "12px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      align="left"
                      variant="body2"
                      style={{
                        fontWeight: "lighter",
                        display: "flex",
                        justifyContent: "Start",
                        textTransform: "none",
                      }}
                    >
                      Copy ID
                    </Typography>
                    <Typography
                      align="left"
                      variant="h6"
                      style={{
                        fontWeight: "lighter",
                        marginLeft: "2px",
                      }}
                    >
                      <Icon icon="clarity:copy-line" />
                    </Typography>
                  </div>
                </div>
                <Typography
                  align="left"
                  style={{
                    fontWeight: "lighter",
                    display: "flex",
                    justifyContent: "Start",
                  }}
                  variant="body1"
                >
                  {locationId}
                </Typography>
              </div>
              <div style={{ marginTop: "16px" }}>
                <Typography align="left" variant="h6">
                  Display Name
                </Typography>
                <Typography
                  align="left"
                  style={{
                    fontWeight: "lighter",
                    display: "flex",
                    justifyContent: "Start",
                  }}
                  variant="body1"
                >
                  {businessDisplayName}
                </Typography>
              </div>
              <div style={{ marginTop: "16px" }}>
                <Typography align="left" variant="h6">
                  Business Registration Name
                </Typography>
                <Typography
                  align="left"
                  variant="body1"
                  style={{
                    fontWeight: "lighter",
                    display: "flex",
                    justifyContent: "Start",
                  }}
                >
                  {businessRegName}
                </Typography>
              </div>
              <div style={{ marginTop: "16px" }}>
                <Typography align="left" variant="h6">
                  Business Trading Name
                </Typography>
                <Typography
                  align="left"
                  variant="body1"
                  style={{
                    fontWeight: "lighter",
                    display: "flex",
                    justifyContent: "Start",
                  }}
                >
                  {businessTradingName}
                </Typography>
              </div>
            </div>
          </Grid>
          <Hidden xsDown={true}>
            <Grid item md={6} sm={5} xs={12} className={classes.imgStyleHidden}>
              <img
                src={locationDashboard}
                className={classes.imgStyle}
                alt="No location dashboard"
              />
            </Grid>
          </Hidden>
        </Grid>
      </CardCommon>
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
    </div>
  );
};

export default withAuthority(LocationDetails, Authorities.DASHBOARD_READ);
