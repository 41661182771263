import _ from "lodash";
import moment from "moment";

/* Replace '\' icon to '*' */
export const handleChangeFilterInOperator = (data: any) => {
  const newStr = data.toString().replace(/,/g, "*");
  return newStr;
};

/* Enter the filter information to be sent for the 'where query' in the API call. */
export const handleFilterData = (
  discountValueMin: any,
  discountValueMax: any,
  filterQtyMin: any,
  filterQtyMax: any,
  locationFilterList: any,
  receiptNo: any,
  queOrderNo: any,
  salesStartDate: any,
  salesEndDate: any,
  totalAmountMin: any,
  totalAmountMax: any,
  orderNo: any,
  typeFilterList: any,
  channelFilterList: any,
  partySize: any,
  paymentTypeFilterList: any,
  userFilterList: any,
  platformFilterList: any,
  filterAmountMin: any,
  filterAmountMax: any,
  vatPercentFilterList: any,
  payInOrOutFilterList: any,
  alTypeFilterList: any,
  isSale?: any,
  isSaleItem?: any,
  isShift?: any,
  isOrder?: any,
  orderStatusFilterList?: any,
  filterOrderTotalMin?: any,
  filterOrderTotalMax?: any,
  deliveryTypeFilterList?: any,
  orderAndDueTimeSwitch?: any,
  terminalFilterList?: any,
) => {
  let queOrderNumber: any = "";
  let location: any = "";
  let receiptNumber: any = "";
  let discountMax: any = "";
  let discountMin: any = "";
  let allFilteredList: any = "";
  let date: any = "";
  let totalAmtMin: any = "";
  let totalAmtMax: any = "";
  let orderNumber: any = "";
  let id: any = "";
  let type: any = "";
  let channel: any = "";
  let platform: any = "";
  let filterPartySize: any = "";
  let paymentTypeCard: any = "";
  let paymentTypeCash: any = "";
  let paymentTypeOther: any = "";
  let tableNo: any = "";
  let user: any = "";
  let qtyMin: any = "";
  let qtyMax: any = "";
  let amountMin: any = "";
  let amountMax: any = "";
  let vatPercent: any = "";
  let payInOut: any = "";
  let allType: any = "";
  let orderStatus: any = "";
  let orderTotalMin: any = "";
  let orderTotalMax: any = "";
  let deliveryType: any = "";
  let paymentType: any = "";
  let terminalList: any = "";

  // If the paymentAmount is not empty, add the paymentAmount details.
  if (!_.isEmpty(filterOrderTotalMin)) {
    orderTotalMin = "paymentAmount>=" + filterOrderTotalMin;
  }

  // If the filterOrderTotalMax is not empty, add the paymentAmount details.
  if (!_.isEmpty(filterOrderTotalMax)) {
    orderTotalMax = "paymentAmount<=" + filterOrderTotalMax;
  }

  // If the discountValueMin is not empty, add the discount details.
  if (!_.isEmpty(discountValueMin)) {
    // If the page is 'sale item', add the discountValueMin details to discount.
    if (isSaleItem) {
      discountMin = "discount>" + discountValueMin;
    } else {
      // If the page is not 'sale item', add the discountValueMin details to discountValue.
      discountMin = "discountValue>" + discountValueMin;
    }
  }

  // If the discountValueMax is not empty, add the discount details.
  if (!_.isEmpty(discountValueMax)) {
    // If the page is 'sale item', add the discountValueMax details to discount.
    if (isSaleItem) {
      discountMax = "discount<" + discountValueMax;
    } else {
      // If the page is not 'sale item', add the discountValueMax details to discountValue.
      discountMax = "discountValue<" + discountValueMax;
    }
  }

  // If the filterQtyMin is not empty, add the qty details.
  if (!_.isEmpty(filterQtyMin)) {
    qtyMin = "qty>=" + filterQtyMin;
  }

  // If the filterQtyMax is not empty, add the qty details.
  if (!_.isEmpty(filterQtyMax)) {
    qtyMax = "qty<=" + filterQtyMax;
  }

  // If the filterAmountMin is not empty, add the amount details.
  if (!_.isEmpty(filterAmountMin)) {
    amountMin = "amount>=" + filterAmountMin;
  }

  // If the filterAmountMax is not empty, add the amount details.
  if (!_.isEmpty(filterAmountMax)) {
    amountMax = "amount<=" + filterAmountMax;
  }

  // If the totalAmountMin is not empty, add the totalAmount details.
  if (!_.isEmpty(totalAmountMin)) {
    // If the page is 'sale' or 'shift', add the totalAmountMin details to totalAmountMin totalAmount
    if (isSale || isShift) {
      totalAmtMin = "totalAmount>" + totalAmountMin;
    } else {
      // If the page is not 'sale' or 'shift', add the totalAmountMin details to amount
      totalAmtMin = "amount>" + totalAmountMin;
    }
  }

  // If the payInOrOutFilterList is not empty, add the payInOrOut details.
  if (!_.isEmpty(payInOrOutFilterList)) {
    const newStr = handleChangeFilterInOperator(payInOrOutFilterList);
    payInOut = "payInOrOut!(" + newStr + ")";
  }

  // If the alTypeFilterList is not empty, add the type details.
  if (!_.isEmpty(alTypeFilterList)) {
    const newStr = handleChangeFilterInOperator(alTypeFilterList);
    allType = "type!(" + newStr + ")";
  }

  // If the paymentTypeFilterList is not empty, add the payment details.
  if (!_.isEmpty(paymentTypeFilterList)) {
    // If the page is 'order', add the paymentTypeFilterList details to paymentType.
    if (isOrder) {
      paymentType = "paymentType=in=(" + paymentTypeFilterList + ")";
    } else {
      // If the page is not 'order',
      // If the paymentTypeFilterList included 'cardPayment', add the 'cardPayment>1' to paymentTypeDetails.
      if (paymentTypeFilterList.includes("cardPayment")) {
        paymentTypeCard = "cardPayment>1";
      }
      // If the paymentTypeFilterList included 'cashPayment', add the 'cashPayment>1' to paymentTypeDetails.
      if (paymentTypeFilterList.includes("cashPayment")) {
        paymentTypeCash = "cashPayment>1";
      }
      // If the paymentTypeFilterList included 'otherPayment', add the 'otherPayment>1' to paymentTypeDetails.
      if (paymentTypeFilterList.includes("otherPayment")) {
        paymentTypeOther = "otherPayment>1";
      }
    }
  }

  // If the partySize is not empty, add the partySize details.
  if (!_.isEmpty(partySize)) {
    filterPartySize = "partySize:'" + partySize + "'";
  }

  // If the vatPercentFilterList is not empty, add the vatPercent details.
  if (!_.isEmpty(vatPercentFilterList)) {
    const newStr = handleChangeFilterInOperator(vatPercentFilterList);
    vatPercent = "vatPercent!(" + newStr + ")";
  }

  // If the userFilterList is not empty, add the userId details.
  if (!_.isEmpty(userFilterList)) {
    const newStr = handleChangeFilterInOperator(userFilterList);
    user = "userId!(" + newStr + ")";
  }

  // If the totalAmountMax is not empty, add the totalAmount details.
  if (!_.isEmpty(totalAmountMax)) {
    // If the page is 'sale' or 'shift', add the totalAmountMax details to totalAmount.
    if (isSale || isShift) {
      totalAmtMax = "totalAmount<" + totalAmountMax;
    } else {
      // If the page is not 'sale' or 'shift', add the totalAmountMax details to amount.
      totalAmtMax = "amount<" + totalAmountMax;
    }
  }

  // If the locationFilterList is not empty, add the location details.
  if (!_.isEmpty(locationFilterList)) {
    // If the page is 'order', add the locationFilterList details to location.
    if (isOrder) {
      const newStr = locationFilterList.toString().replace(/,/g, ",");
      location = "locationId=in=(" + newStr + ")";
    } else {
      // If the page is not 'order', add the locationFilterList details to location.
      const newStr = locationFilterList.toString().replace(/,/g, "*");
      location = "locationId!(" + newStr + ")";
    }
  }

  // If the receiptNo is not empty, add the receiptNo details.
  if (!_.isEmpty(receiptNo)) {
    receiptNumber = "receiptNo:" + receiptNo;
  }

  // If the queOrderNo is not empty, add the queOrderNo details.
  if (!_.isEmpty(queOrderNo)) {
    queOrderNumber = "queOrderNo:" + queOrderNo;
  }

  // If the typeFilterList is not empty, add the mode details.
  if (!_.isEmpty(typeFilterList)) {
    const newStr = handleChangeFilterInOperator(typeFilterList);
    type = "mode!(" + newStr + ")";
  }

  // If the channelFilterList is not empty, add the channel details.
  if (!_.isEmpty(channelFilterList)) {
    // If the page is 'order', add the channelFilterList details to orderChannel.
    if (isOrder) {
      channel = "orderChannel=in=(" + channelFilterList + ")";
    } else {
      // If the page is not 'order', add the channelFilterList details to deliveryType.
      const newStr = handleChangeFilterInOperator(channelFilterList);
      channel = "orderChannel!(" + newStr + ")";
    }
  }

  // If the platformFilterList is not empty, add the platform details.
  if (!_.isEmpty(platformFilterList)) {
    const newStr = handleChangeFilterInOperator(platformFilterList);
    platform = "platform!(" + newStr + ")";
  }

  // If the platformFilterList is not empty, add the platform details.
  if (!_.isEmpty(terminalFilterList)) {
    const newStr = handleChangeFilterInOperator(terminalFilterList);
    terminalList = "deviceId!(" + newStr + ")";
  }

  // If the salesEndDate is not empty, add the date details.
  if (!_.isEmpty(salesEndDate)) {
    // If the page is 'shift', change the format of the date query.
    if (isShift) {
      date =
        "startDate>=" +
        moment(salesStartDate, "YYYY-MM-DD").format("YYYY-MM-DD") +
        ",startDate<=" +
        moment(salesEndDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    } else if (isOrder) {
      // If the page is 'order', change the format of the date query.
      // If the orderAndDueTimeSwitch is on, change the format of the date query.
      if (orderAndDueTimeSwitch) {
        date =
          "orderTime>=" +
          moment(salesStartDate, "YYYY-MM-DD").format("YYYY-MM-DDT00:00:00") +
          ";orderTime<=" +
          moment(salesEndDate, "YYYY-MM-DD").format("YYYY-MM-DDT23:59:59");
      } else {
        // If the orderAndDueTimeSwitch is off, change the format of the date query.
        date =
          "dueTime>=" +
          moment(salesStartDate, "YYYY-MM-DD").format("YYYY-MM-DDT00:00:00") +
          ";dueTime<=" +
          moment(salesEndDate, "YYYY-MM-DD").format("YYYY-MM-DDT23:59:59");
      }
    } else {
      // If the page is not 'order' or 'shift', change the format of the date query.
      date =
        "date>=" +
        moment(salesStartDate, "YYYY-MM-DD").format("YYYY-MM-DD") +
        ",date<=" +
        moment(salesEndDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    }
  }

  // If the page is not 'order',
  if (isOrder) {
    // If the orderStatusFilterList is not empty, add the status details.
    if (!_.isEmpty(orderStatusFilterList)) {
      orderStatus = "status=in=(" + orderStatusFilterList + ")";
    }

    // If the orderStatusFilterList is empty, Add status details like 'start', 'pending', 'checkout'.
    if (_.isEmpty(orderStatusFilterList)) {
      orderStatus = "status=out=(started,pending,checkout)";
    }
  }

  // If the deliveryTypeFilterList is not empty, add the deliveryType details.
  if (!_.isEmpty(deliveryTypeFilterList)) {
    deliveryType = "deliveryType=in=(" + deliveryTypeFilterList + ")";
  }

  // If the orderNo is not empty,
  if (!_.isEmpty(orderNo)) {
    // If the page is 'order', add the orderNo details to displayRef.
    if (isOrder) {
      orderNumber = "displayRef==" + orderNo + "*";
    } else {
      // If the page is not 'order', add the orderNo details to orderNo.
      orderNumber = "orderNo:" + orderNo;
    }
  }

  allFilteredList = [
    orderStatus,
    paymentTypeCard,
    paymentTypeCash,
    paymentTypeOther,
    receiptNumber,
    date,
    totalAmtMin,
    totalAmtMax,
    discountMin,
    discountMax,
    qtyMin,
    qtyMax,
    queOrderNumber,
    orderNumber,
    filterPartySize,
    allType,
    payInOut,
    vatPercent,
    tableNo,
    user,
    channel,
    platform,
    type,
    location,
    id,
    amountMin,
    amountMax,
    orderTotalMin,
    orderTotalMax,
    deliveryType,
    paymentType,
    terminalList,
  ];

  // Remove the empty data.
  const filtered = allFilteredList.filter((data: any) => data !== "");
  const filterCount = filtered.length;
  // If the page is 'order', Items in the filtered array connect by using ';'.
  if (isOrder) {
    const filterQuery = filtered.join(";");
    return { filterCount, filterQuery };
  } else {
    const filterQuery = filtered.join(",");
    // If the page is not 'order', Items in the filtered array connect by using '1'.
    return { filterCount, filterQuery };
  }
};
