  /* Change the pixel size of image */
 export const handleImageUrl = (url: any) => {
    // If 'imgUrl' has 'cloudinary', that 'imgUrl' edit.
    if (url.includes("cloudinary")) {
      const urlSplit = url.split("upload");
      const inputString = urlSplit[0] + "upload/c_fill,g_center,h_240,w_320";
      const mergeUrl = inputString + urlSplit[1];
      return mergeUrl;
    } else {
      // If 'imgUrl' has not 'cloudinary', that 'imgUrl' does not edit.
      return url;
    }
  };