import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import SaveIcon from "@material-ui/icons/Save";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    dropdownStyle: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      backgroundColor: theme.palette.background.entity_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface InputBoxDefaultProps {
  selectedPaymentType: any;
  paymentType: any;
  handleUpdatePaymentType: any;
}

/* Display payment type selector dop down */
const PaymentTypeSelector: React.FunctionComponent<InputBoxDefaultProps> = ({
  selectedPaymentType,
  paymentType,
  handleUpdatePaymentType,
}) => {
  const [updatedPaymentType, setUpdatedPaymentType] = useState<any>();

  /* When the component mounts or the payment type changes, update the state for the updated payment type */
  useEffect(() => {
    setUpdatedPaymentType(paymentType);
  }, [selectedPaymentType, paymentType]);

  const menuItems = useMemo(() => {
    return [
      { value: "cash", label: "Cash" },
      { value: "card", label: "Card" },
      { value: "voucher", label: "Voucher" },
      { value: "online", label: "Online" },
      { value: "deposit", label: "Deposit" },
    ];
  }, []);

  /* Handle changes to the payment type selection */
  const handleSelectPaymentType = (e: any) => {
    const { value } = e.target;
    const clonePaymentType: any = _.cloneDeep(updatedPaymentType);
    clonePaymentType.typeAfter = value;
    setUpdatedPaymentType(clonePaymentType);
  };

  const classes = useStyles();
  return (
    <>
      <Grid item xs={4} style={{ marginBottom: "12px" }}>
        <TextField
          fullWidth
          id={selectedPaymentType}
          name={selectedPaymentType}
          select
          size="small"
          label="Payment type"
          value={updatedPaymentType?.typeAfter || ""}
          onChange={handleSelectPaymentType}
          className={classes.dropdownStyle}
          variant="outlined"
        >
          {menuItems.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        {updatedPaymentType && (
          <Button
            disabled={
              updatedPaymentType.typeBefore === updatedPaymentType.typeAfter
            }
            onClick={() => handleUpdatePaymentType(updatedPaymentType)}
          >
            <SaveIcon />
          </Button>
        )}
      </Grid>
    </>
  );
};

export default PaymentTypeSelector;
