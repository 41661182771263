import { Button, Grid, Tooltip, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { Theme } from "../../../../types/customTheme";
import ListIcon from "@material-ui/icons/List";
import moment from "moment";
import CardCommon from "../../../../components/card/CardCommon";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import StockItemDetailsType from "./StockItemDetailsType";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { Icon } from "@iconify/react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "10px",
      width: "10px",
      height: "10px,",
      backgroundColor: theme.palette.custom["blue"].main,
      "&:hover": {
        backgroundColor: theme.palette.custom["blue"].dark,
      },
      color: "white",
    },
    gridField: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      minHeight: "36px",
      textTransform: "none",
      paddingLeft: "14px",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        paddingLeft: "0px",
      },
    },
    gridFieldLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "4px",
      marginBottom: "4px",
      alignItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "36px",
      paddingRight: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
      },
    },
    gridFieldLastColumnDisplayView: {
      display: "flex",
      alignItems: "center",
      minHeight: "36px",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        marginBottom: "16px",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "36px",
      paddingLeft: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        marginTop: "12px",
        justifyContent: "center",
        paddingLeft: "0px",
      },
    },
    editIcon: { fontSize: "24px", color: "black" },
    editIconStyle: {
      paddingRight: "12px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        marginBottom: "12px",
      },
    },
  }),
);

export interface StockItemInfoNodeProps {
  nodeData: any;
  locationSelectorList: any;
  handleOpenEditStockItemsModal: any;
  handleOpenEditMapProductsItemsModal: any;
  departmentName: any;
  groupName: any;
}

/**
 * StockItemInfoNode Component
 *
 * This component displays information related to a stock item, such as its name, SKU, barcode, and department.
 * It provides options to edit the stock item and map products, based on user permissions.
 */
const StockItemInfoNode: React.FunctionComponent<StockItemInfoNodeProps> = ({
  nodeData,
  locationSelectorList,
  handleOpenEditStockItemsModal,
  handleOpenEditMapProductsItemsModal,
  departmentName,
  groupName,
}) => {
  const [updateTime, setUpdateTime] = useState("");
  const [barcode, setBarcode] = useState("");
  const [name, setName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [sku, setSku] = useState("");
  const [createdTime, setCreatedTime] = useState("");
  const [openOrderDetailsCard, setOpenOrderDetailsCard] = useState(false);

  /* Update states after getting the order information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const { created, barcode, updated, primaryLocationId, name, sku } =
      nodeValues;
    setSku(sku);
    setName(name);
    setBarcode(barcode);
    setCreatedTime(moment(created).format("MMM D, YYYY HH:mm"));
    setUpdateTime(moment(updated).format("MMM D, YYYY HH:mm"));
    // Get the location name using locationId
    const locationDetails = getFilterListFromArrayObject(
      locationSelectorList,
      primaryLocationId,
    );
    // Only if there is a detail of the location, A location name entered into a state.
    if (!_.isEmpty(locationDetails)) {
      setLocationName(locationDetails[0].label);
    } else {
      // If there is no description of the location, the state will be entered as 'Unknown location'.
      setLocationName("Unknown location");
    }
  }, []);

  /**
   * handleChangeRowColor function
   *
   * This function determines the background color of the card based on the state of openOrderDetailsCard.
   * If openOrderDetailsCard is true, it returns "green" to indicate that the card is open.
   */
  const handleChangeRowColor = () => {
    if (openOrderDetailsCard) {
      return "green";
    }
  };

  /**
   * useEffect Hook
   *
   * This useEffect hook is responsible for updating the component state with nodeData whenever nodeData changes.
   * It invokes the setNodeDataToState callback function with the updated nodeData.
   */
  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const classes = useStyles();

  return (
    <>
      <CardCommon
        color={handleChangeRowColor()}
        backgroundColor={"entity_background"}
      >
        <Button
          style={{ width: "100%", margin: "0px", padding: "0px", zIndex: 0 }}
          onClick={() => handleOpenEditStockItemsModal(nodeData)}
        >
          <Grid container>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={classes.gridFieldFirstColumn}
            >
              {groupName}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={classes.gridField}
            >
              {departmentName.name}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={classes.gridField}
            >
              {name}
            </Grid>
            {maxWidth ? (
              sku && (
                <Grid
                  style={{ textAlign: "left" }}
                  item
                  xs={12}
                  md={2}
                  className={classes.gridField}
                >
                  {sku}
                </Grid>
              )
            ) : (
              <Grid
                style={{ textAlign: "left" }}
                item
                xs={12}
                md={2}
                className={classes.gridField}
              >
                {sku}
              </Grid>
            )}
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={classes.gridField}
            >
              {barcode}
            </Grid>

            <Grid item xs={12} md={2} className={classes.gridFieldLastColumn}>
              <div className={classes.gridFieldLastColumnDisplayView}>
                <div className={classes.editIconStyle}>
                  <Tooltip title="Update Stock Item">
                    <div>
                      <ButtonCommon
                        onClick={() => handleOpenEditStockItemsModal(nodeData)}
                        color="yellow"
                      >
                        <Icon className={classes.editIcon} icon="mdi:cog" />
                      </ButtonCommon>
                    </div>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Add Map Stock">
                    <div>
                      <Button
                        onClick={(event: any) =>
                          handleOpenEditMapProductsItemsModal(nodeData, event)
                        }
                        className={classes.root}
                        style={{ padding: "6px", zIndex: 4 }}
                        // color="blue"
                      >
                        <ListIcon
                          style={{ fontSize: "28px", color: "white" }}
                        />
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </Grid>
          </Grid>
        </Button>
      </CardCommon>
      {openOrderDetailsCard && (
        <StockItemDetailsType
          nodeData={nodeData}
          locationName={locationName}
          updateTime={updateTime}
          createdTime={createdTime}
          handleOpenEditStockItemsModal={handleOpenEditStockItemsModal}
          handleOpenEditMapProductsItemsModal={
            handleOpenEditMapProductsItemsModal
          }
        />
      )}
    </>
  );
};

export default withAuthority(
  StockItemInfoNode,
  Authorities.INVENTORY_ADMIN_READ,
);
