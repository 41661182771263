import React, { useEffect, useState } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { makeStyles } from "@material-ui/core";
import { CustomTheme } from "../../../types/customTheme";
import { getWindowDimensions } from "../../../utils/windowDimension";

export interface DaySelectProps {
  handleSelectDays: any;
  selectedDays: any;
} 

const useStyles = (width: any) =>
  makeStyles((theme: CustomTheme) => ({
    root: {
      backgroundColor: theme.palette.background.entity_highlight_background,
    },
    toggleButtonColor: {
      "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: theme.palette.custom.blue.main,
      },
      width: width < 600 ? `${(width - 148) / 7}px` : "100%"
    },
  }));

/* Date selection UI */
const DaySelect: React.FunctionComponent<DaySelectProps> = ({
  handleSelectDays,
  selectedDays,
}) => {
  const hasWindow = typeof window !== "undefined";
  const [width, setWidth] = useState(0);

  /* Get the screen size on the website after screen change. */
  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        const { width } = getWindowDimensions();
        setWidth(width);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  /* Get the screen size on the website in initial load. */
  useEffect(() => {
    const { width } = getWindowDimensions();
    setWidth(width);
  }, []);
  
  const classes = useStyles(width)();
  return (
    <ToggleButtonGroup
      value={selectedDays}
      size={"large"}
      onChange={handleSelectDays}
      aria-label="select day"
      className={classes.root}
    >
      <ToggleButton
        value={1440}
        aria-label="mon"
        className={classes.toggleButtonColor}
      >
        M
      </ToggleButton>
      <ToggleButton
        value={2880}
        aria-label="tue"
        className={classes.toggleButtonColor}
      >
        T
      </ToggleButton>
      <ToggleButton
        value={4320}
        aria-label="wed"
        className={classes.toggleButtonColor}
      >
        W
      </ToggleButton>
      <ToggleButton
        value={5760}
        aria-label="thu"
        className={classes.toggleButtonColor}
      >
        T
      </ToggleButton>
      <ToggleButton
        value={7200}
        aria-label="fri"
        className={classes.toggleButtonColor}
      >
        F
      </ToggleButton>
      <ToggleButton
        value={8640}
        aria-label="sat"
        className={classes.toggleButtonColor}
      >
        S
      </ToggleButton>
      <ToggleButton
        value={0}
        aria-label="sun"
        className={classes.toggleButtonColor}
      >
        S
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default DaySelect;
