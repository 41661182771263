import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "50%",
    },
  }),
);

export interface ShapeCircleProps {
  radius?: number;
  color?: string;
}

/* Changing the checkout color preview UI. */
const ShapeCircle: React.FunctionComponent<ShapeCircleProps> = ({
  radius,
  color,
}) => {
  const classes = useStyles();

  let heightWidth = 8;

  // Changing the preview width
  switch (radius) {
    case 1:
      heightWidth = 8;
      break;
    case 2:
      heightWidth = 16;
      break;
    case 3:
      heightWidth = 24;
      break;
    case 4:
      heightWidth = 32;
      break;
    default:
      break;
  }

  return (
    <div
      className={classes.root}
      style={{
        width: heightWidth,
        height: heightWidth,
        backgroundColor: color || "white",
      }}
    ></div>
  );
};

export default ShapeCircle;
