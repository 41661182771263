import * as React from "react";
import { Popover } from "@material-ui/core";
import { SketchPicker } from "react-color";

export interface ColorPickerPopupProps {
  color?: string;
  setColor?: any;
  open: boolean;
  handleClose: any;
  anchorEl: any;
}

/* Color selected pop up */
const ColorPickerPopup: React.FunctionComponent<ColorPickerPopupProps> = ({
  color,
  setColor,
  open,
  handleClose,
  anchorEl,
}) => {
  const id = open ? "color-picker-popover" : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <SketchPicker color={color} onChange={setColor} />
    </Popover>
  );
};

const areEqual = (prevState: any, nextState: any) => {
  return (
    prevState.open === nextState.open && prevState.color === nextState.color
  );
};

export default React.memo(ColorPickerPopup, areEqual);
