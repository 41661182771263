export const UK_PHONE_NUMBER_PATTERN =
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/g;
// export const EMAIL_PATTERN = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
export const EMAIL_PATTERN =
  /^\w+@([a-zA-Z_]+)\.[a-zA-Z]{2,3}(\.[a-zA-Z]{2,3})?$/;

  export const EMAIL_PATTERN_INVENTORY =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

export const INTEGER_NUMBER_PATTERN = /^[0-9\b]+$/;

export const PHONE_NUMBER_PATTERN = /^[0][0-9\b]{10}$/

export const REMOVE_PARAGRAPH_AND_BREAK_HTML_TAG = /<\/p>\n<p>|<br>/g;
export const REMOVE_PARAGRAPH_HTML_TAG = /<\/p>\n<p>|<br>/g;
export const REMOVE_BREAK_HTML_TAG = /<br>/g;
export const REMOVE_ALL_HTML_TAG = /(<([^>]+)>)/gi;
export const REMOVE_BREAK_AND_FONT_SIZE_HTML_TAG = /<br>|font-size:[^;']*(;)?/g;
export const REMOVE_ORDER_CHANNEL = /,orderChannel!\([\w\*\|]+\)/;

export const ADD_HTML_SPACE = "&nbsp;";
export const REQUIRED_FIELD_PATTERN = /^\s*/;

// moment
export const MOMENT_WEEK = "week";
export const MOMENT_MINUTES = "minutes";
export const TIME_FORMAT_YYYYMMDD_HHMMSS = "YYYY-MM-DD HH:mm:ss";

export const APP_PRESTO_EATPRESTO = "PRESTO_EATPRESTO";
export const APP_PRESTO_LOCATION = "PRESTO_LOCATION";
export const APP_PRESTO_SALES = "PRESTO_SALES";
export const APP_PRESTO_MENU = "PRESTO_MENU";
export const APP_PRESTO_TASK = "PRESTO_TASK";
export const APP_PRESTO_INVENTORY = "PRESTO_INVENTORY";
export const APP_PRESTO_CUSTOMER = "PRESTO_CUSTOMER";

export const URL_EATPRESTO = "eatpresto";
export const URL_PRESTO_LOCATION = "presto-locations";
export const URL_PRESTO_SALES = "presto-sales";
export const URL_PRESTO_MENUS = "presto-menus";
export const URL_PRESTO_TASK = "presto-task";
export const URL_PRESTO_CUSTOMER = "presto-customer";

export const ACCOUNT_ORIGIN = "/accounts";

export const DELIVERY_TYPE_DINEIN = "dinein";
export const DELIVERY_TYPE_DELIVER = "delivery";
export const DELIVERY_TYPE_PICKUP = "takeout";

export const DISCOUNT_TYPE_PERCENT = "percent";
export const DISCOUNT_TYPE_AMOUNT = "amount";

export const DISCOUNT_TYPE_ALL = "all";
export const DISCOUNT_TYPE_CATEGORY = "categories";
export const DISCOUNT_TYPE_ITEM = "items";

export const EATPRESTO_QUERY_PARAMS = "eatpresto";
export const LOCATION_QUERY_PARAMS = "location";
export const MENU_QUERY_PARAMS = "menu";
export const TASK_QUERY_PARAMS = "task";
export const SALE_QUERY_PARAMS = "sale";
export const INVENTORY_QUERY_PARAMS = "inventory";
export const CUSTOMER_QUERY_PARAMS = "customer";

export const SORTING_TYPE_ASC = "ASC";
export const SORTING_TYPE_DESC = "DESC";

export const PASSWORD_ERROR_CODE = "auth/wrong-password";
export const USER_ERROR_CODE = "auth/user-not-found";
export const ERROR_MESSAGE_UNEXPECTED_ERROR =
  "Unexpected error. Please check you connection and try again.";

export const NODE_STATUS_CREATE = "create";

export const ERROR_MESSAGE_UPDATING_ERROR =
  "Error updating option! please check your connection.";
export const ERROR_MESSAGE_VERSION_ERROR =
  "Another user has changed data so please try again.";
export const ERROR_MESSAGE_CREATING_ERROR =
  "Error creating option! please check your connection.";
export const ERROR_MESSAGE_DELETING_ERROR =
  "Error deleting option! please check your connection.";
export const ERROR_MESSAGE_NOT_AVAILABLE_DISCOUNT_ERROR =
  "Please add discount information.";
export const ERROR_MESSAGE_NOT_AVAILABLE_BANNER_ERROR =
  "Please add banner information.";
  export const ERROR_MESSAGE_TIME_ERROR =
  "“From” time should be less than “To” time";

export const SUCCESSFULLY_CREATED = "Changes successfully created.";
export const SUCCESSFULLY_DELETED = "Successfully deleted.";
export const SUCCESSFULLY_UPDATED = "Changes successfully updated.";
