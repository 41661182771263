import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import CardCommon from "../../card/CardCommon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "12px",
      [theme.breakpoints.up("sm")]: {
        padding: "20px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "32px",
      },
    },
  }),
);

/* Used to display title and other information in a card. */
export interface RoundBoxWithHeaderProps {
  header: string;
  children: any;
}

const RoundBoxWithHeader: React.SFC<RoundBoxWithHeaderProps> = ({
  header,
  children,
}) => {
  const classes = useStyles();
  return (
    <div>
      <CardCommon backgroundColor={"entity_background"}>
        <div className={classes.root}>
          <Typography variant={"h4"} align={"left"} gutterBottom>
            {header}
          </Typography>
          {children}
        </div>
      </CardCommon>
    </div>
  );
};

export default RoundBoxWithHeader;
