import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useRouteMatch } from "react-router";
import _ from "lodash";
import moment from "moment";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../utils/consts";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { fetchStockItemInfoGetAll } from "../../../../services/inventory/stockItems";
import StockItemsHeader from "./StockItemsHeader";
import InventoryDetails from "../../../../components/common/InventoryDetails/InventoryDetails";
import {
  fetchSingleStockLevelInfo,
  fetchStockLevelInfoGetAll,
  stockLevelCreateOrUpdate,
} from "../../../../services/inventory/stockLevel";
import StockLevelModal from "./StockLevelModal";
import Toast from "../../../../components/alerts/Toast";
import { HttpStatus } from "../../../../utils/enum";
import { fetchAllMasterLocations } from "../../../../services/inventory/masterLocations";
import { getIsAuthorized } from "../../../../utils/authorities";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * This component represents the Stock Take page, where stock levels and items are managed.
 * It allows users to view, update, and save stock levels for different stock items at various locations.
 */
const StockTake: React.FunctionComponent = (props: any) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [createdId, setCreatedId] = useState([]);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [isOpenStockLevelModal, setIsOpenStockLevelModal] = useState(false);
  const [success, setSuccess] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [stockLevelName, setStockLevelName] = useState("");
  const [
    stockItemGroupDepartmentNodeInSelector,
    setStockItemGroupDepartmentNodeInSelector,
  ] = useState<any>([]);
  const [
    stockItemGroupDepartmentNodeInSelectorInitial,
    setStockItemGroupDepartmentNodeInSelectorInitial,
  ] = useState<any>([]);
  const [activeGroupId, setActiveGroupId] = useState(0);
  const [stockLevelNode, setStockLevelNode] = useState<any>([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [stockLevel, setStockLevel] = useState(0);
  const [stockItemId, setStockItemId] = useState("");
  const [version, setVersion] = useState(0);
  const [stockLevelInitial, setStockLevelInitial] = useState(0);
  const [searchNameTab, setSearchNameTab] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [isLoadingToast, setIsLoadingToast] = useState(false);
  const [stockLevelId, setStockLevelId] = useState("");
  const [displaySuffix, setDisplaySuffix] = useState("");
  const [searchedDepartmentList, setSearchedDepartmentList] = useState<any>([]);
  const [isActiveLocationId, setIsActiveLocationId] = useState(true);

  const match: any = useRouteMatch();
  const elementsByTitle: any = {}; // Map to store elements by title

  /**
   * Transforms the response data into a structured stock order item format.
   * @param {Array} response - The response data containing stock order items. format (item->department->group)
   * @returns {Array} - Transformed stock order item structure. format (group->department->item)
   */
  const handleStockOrderItemStructure = (response: any) => {
    // Initialize an empty array to store the transformed structure
    const transformedStructure = response.reduce(
      (accumulator: any, item: any) => {
        if (item?.stockDepartmentId?.stockGroupId?.id) {
          // Extract the stock group ID from the response item
          const stockGroupId = item.stockDepartmentId.stockGroupId.id;

          // Check if the stock group already exists in the accumulator
          const existingGroup = accumulator.find(
            (group: any) => group.id === stockGroupId,
          );

          if (existingGroup) {
            // Check if the department within the stock group exists
            const existingDepartment = existingGroup.stockDepartmentId.find(
              (dept: any) => dept.id === item.stockDepartmentId.id,
            );

            if (existingDepartment) {
              // If department exists, push the item details to its item array
              existingDepartment.item.push({
                id: item.id,
                name: item.name,
                barcode: item.barcode,
                active: item.active,
                created: item.created,
                criticalThreshold: item.criticalThreshold,
                displaySuffix: item.displaySuffix,
                inputMultiplier: item.inputMultiplier,
                inputSuffix: item.inputSuffix,
                primaryLocationId: item.primaryLocationId,
                sku: item.sku,
                updated: item.updated,
                version: item.version,
                warningThreshold: item.warningThreshold,
                autoUnitConvert: item.autoUnitConvert,
                showRemainderSep: item.showRemainderSep,
              });
            } else {
              // If department doesn't exist, create a new department and item
              existingGroup.stockDepartmentId.push({
                id: item.stockDepartmentId.id,
                name: item.stockDepartmentId.name,
                locationId: item.stockDepartmentId.locationId,
                created: item.stockDepartmentId.created,
                updated: item.stockDepartmentId.updated,
                version: item.stockDepartmentId.version,
                item: [
                  {
                    id: item.id,
                    name: item.name,
                    barcode: item.barcode,
                    active: item.active,
                    created: item.created,
                    criticalThreshold: item.criticalThreshold,
                    displaySuffix: item.displaySuffix,
                    inputMultiplier: item.inputMultiplier,
                    inputSuffix: item.inputSuffix,
                    primaryLocationId: item.primaryLocationId,
                    sku: item.sku,
                    updated: item.updated,
                    version: item.version,
                    warningThreshold: item.warningThreshold,
                    autoUnitConvert: item.autoUnitConvert,
                    showRemainderSep: item.showRemainderSep,
                  },
                ],
              });
            }
          } else {
            // If stock group doesn't exist, create a new stock group, department, and item
            accumulator.push({
              id: item.stockDepartmentId.stockGroupId.id,
              name: item.stockDepartmentId.stockGroupId.name,
              version: item.stockDepartmentId.stockGroupId.version,
              updated: item.stockDepartmentId.stockGroupId.updated,
              created: item.stockDepartmentId.stockGroupId.created,
              locationId: item.stockDepartmentId.stockGroupId.locationId,
              stockDepartmentId: [
                {
                  id: item.stockDepartmentId.id,
                  name: item.stockDepartmentId.name,
                  locationId: item.stockDepartmentId.locationId,
                  created: item.stockDepartmentId.created,
                  updated: item.stockDepartmentId.updated,
                  version: item.stockDepartmentId.version,
                  item: [
                    {
                      id: item.id,
                      name: item.name,
                      barcode: item.barcode,
                      active: item.active,
                      created: item.created,
                      criticalThreshold: item.criticalThreshold,
                      displaySuffix: item.displaySuffix,
                      inputMultiplier: item.inputMultiplier,
                      inputSuffix: item.inputSuffix,
                      primaryLocationId: item.primaryLocationId,
                      sku: item.sku,
                      updated: item.updated,
                      version: item.version,
                      warningThreshold: item.warningThreshold,
                      autoUnitConvert: item.autoUnitConvert,
                      showRemainderSep: item.showRemainderSep,
                    },
                  ],
                },
              ],
            });
          }
        }

        return accumulator;
      },
      [],
    );

    // Return the transformed structure
    return transformedStructure;
  };

  /**
   * Function to fetch stock item information for a specific location, organize it into a structured format,
   * and set it in the component's state.
   */
  const handleGetProductDepartmentGroup = async () => {
    try {
      // Fetch stock item information for the specified location
      const res = await fetchStockItemInfoGetAll(match.params.locationId);

      if (res.data.data) {
        // Transform the stock item data into a structured format
        const transformedStructure = handleStockOrderItemStructure(
          res.data.data,
        );
        // Set the transformed data as the stock item group and department information
        setStockItemGroupDepartmentNodeInSelector(transformedStructure);
        setStockItemGroupDepartmentNodeInSelectorInitial(transformedStructure);
      }

      // Fetch stock level information after processing stock items
      getAllStockLevel();
    } catch (err) {
      // Handle errors if they occur during the API call
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch stock level information for a specific location and set it in the component's state.
   */
  const getAllStockLevel = async () => {
    try {
      // Fetch stock level information for the specified location
      const res = await fetchStockLevelInfoGetAll(match.params.locationId);

      // Set the fetched stock level data in the component's state
      setStockLevelNode(res.data.data);

      // Update loading state variables to indicate data loading is complete
      setIsOpenSkeletonLoading(false);
      setIsLoading(false);
    } catch (err) {
      // Handle errors if they occur during the API call
      setIsOpenSkeletonLoading(false);
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch stock level information for a specific location and set it in the component's state.
   */
  const getSingleStockLevelGivenId = async () => {
    try {
      // Fetch stock level information for the specified location
      const res = await fetchSingleStockLevelInfo(
        match.params.locationId,
        stockLevelId,
      );
      // const filter = res.data.data.filter((data: any) => data.stockItemId.id===stockItemId)
      setVersion(res.data.data?.version);
      handleSaveStockLevelConflict409(res?.data?.data?.version);
    } catch (err) {
      // Handle errors if they occur during the API call
      setIsOpenSkeletonLoading(false);
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleGetMasterLocations = async () => {
    try {
      const res = await fetchAllMasterLocations(match.params.locationId);
      if (
        (res.data && res.data.data && res.data.data.active) ||
        getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ)
      ) {
        getAllFilterLocation("");
        setIsActiveLocationId(true);
      } else {
        setIsActiveLocationId(false);
        setIsLoading(false);
        setIsOpenSkeletonLoading(false);
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Effect to set the document title and fetch location information on component mount.
   */
  useEffect(() => {
    // Set the document title to "Eat Presto - Stock Item"
    document.title = "Inventory - Stock Take";

    // Fetch location information with an empty searchNameTab
    handleGetMasterLocations();
  }, []);

  /**
   * Function to fetch location information and initialize stock items loading.
   * @param {string} searchNameTab - Name to search for locations.
   */
  const getAllFilterLocation = async (searchNameTab: string) => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations(searchNameTab);

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);

      // Set the 'isGetLocationInfo' state to true to indicate location data has been fetched
      setIsGetLocationInfo(true);
      // Trigger the function to fetch stock items information with appropriate parameters
      handleGetProductDepartmentGroup();
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to open the stock level modal with the specified stock item details.
   * @param {string} stockId - The ID of the selected stock item.
   * @param {any} stockLevel - The current stock level.
   * @param {any} version - The version of the stock item.
   * * @param {any} name - The name of the stock item.
   * @param {React.MouseEvent<HTMLLIElement>} event - The event object for the click (optional).
   */
  const handleOpenStockLevelModal = (
    stockId: string,
    stockLevel: any,
    version: any,
    name: any,
    stockLevelId: any,
    displaySuffix: any,
    event?: React.MouseEvent<HTMLLIElement>,
  ) => {
    // Set the stock level and related details in the component's state
    setStockLevel(stockLevel);
    setStockLevelInitial(stockLevel);
    setStockItemId(stockId);
    setVersion(version);
    setStockLevelName(name);
    setStockLevelId(stockLevelId);
    setDisplaySuffix(displaySuffix);
    // Check if an event object is provided and stop its propagation to prevent unintended side effects.
    if (event) {
      event.stopPropagation();
    }

    // Open the stock level modal
    setIsOpenStockLevelModal(true);
  };

  /**
   * Function to handle search by filtering stock items based on their names.
   * @param {string} value - The search value.
   */
  const handleChangeSearch = (value: string) => {
    setSearchNameTab(value);

    if (!value) {
      // If the search value is empty, reset to the initial data
      setStockItemGroupDepartmentNodeInSelector(
        stockItemGroupDepartmentNodeInSelectorInitial,
      );
      return;
    }

    // Create a function to filter stock items based on their names
    const filterItemsByName = (item: any) =>
      item.name
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase());
    const ids: any = [];
    // Use map and filter to create a new filtered structure
    const filteredOutput = stockItemGroupDepartmentNodeInSelectorInitial
      .map(({ stockDepartmentId, ...group }: any) => ({
        ...group, // Spread the group properties
        stockDepartmentId: stockDepartmentId
          .map(({ item, ...dept }: any) => ({
            ...dept, // Spread the department properties
            item: item.filter(filterItemsByName), // Filter items within the department
          }))
          .filter((dept: any) => {
            if (!ids.includes(dept.id) && dept.item.length > 0) {
              ids.push(dept.id);
            }
            return dept.item.length > 0;
          }), // Remove departments with no items
      }))
      .filter((group: any) => group.stockDepartmentId.length > 0); // Remove groups with no departments with items
    // Update the state with the filtered data
    setStockItemGroupDepartmentNodeInSelector(filteredOutput);

    setSearchedDepartmentList(ids);
  };

  /**
   * Register an element reference by title in a map.
   * @param {string} title - The title associated with the element.
   * @param {React.RefObject} elementRef - The reference to the element.
   */
  const registerElementWithTitle = (title: string, elementRef: any) => {
    // Check if both title and elementRef are not empty or undefined
    if (title && elementRef && elementRef.current) {
      // Store the element reference in a map using the title as the key
      elementsByTitle[title] = elementRef.current;
    }
  };

  /**
   * Handle changes in the stock level input.
   * @param {Object} e - The event object.
   */
  const handleChangeStockLevel = (e: any) => {
    // Update the stock level state with the new value from the input field
    setStockLevel(e.target.value);
  };

  /**
   * Handle saving the stock level.
   */
  const handleSaveStockLevel = async () => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");

    // Initialize the formData object
    let formData: {
      locationId: any;
      stockItemId: string;
      stockLevel: number;
      lastStockTakeDate: string;
      version: number; // Make 'version' property optional
      lastUpdateType: string;
    } = {
      locationId: match.params.locationId,
      stockItemId: stockItemId,
      stockLevel: stockLevel,
      lastStockTakeDate: moment().format("YYYY-MM-DD"),
      version: version,
      lastUpdateType: "adjustment",
    };

    // Check if the stock level is being updated (not a new entry)
    if (stockLevelInitial !== 0) {
      // Add the 'version' field for updates
      formData.version = version;
    }
    try {
      // Send a request to create or update the stock level
      const res = await stockLevelCreateOrUpdate(
        match.params.locationId,
        formData,
      );

      if (res.data.status === HttpStatus.CONFLICT_409) {
        getSingleStockLevelGivenId();
      } else {
        // Refresh the list of stock levels
        getAllStockLevel();

        // Close the stock level modal
        setIsOpenStockLevelModal(false);

        setIsLoadingToast(false);
        setToastMessage(SUCCESSFULLY_CREATED);
        setToastType("success");
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Handle saving the stock level.
   */
  const handleSaveStockLevelConflict409 = async (updatedVersion: number) => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");

    // Initialize the formData object
    let formData: {
      locationId: any;
      stockItemId: string;
      stockLevel: number;
      lastStockTakeDate: string;
      version: number; // Make 'version' property optional
      lastUpdateType: string;
    } = {
      locationId: match.params.locationId,
      stockItemId: stockItemId,
      stockLevel: stockLevel,
      lastStockTakeDate: moment().format("YYYY-MM-DD"),
      version: updatedVersion,
      lastUpdateType: "adjustment",
    };
    // Check if the stock level is being updated (not a new entry)
    if (stockLevelInitial !== 0) {
      // Add the 'version' field for updates
      formData.version = updatedVersion;
    }

    try {
      // Send a request to create or update the stock level
      const res = await stockLevelCreateOrUpdate(
        match.params.locationId,
        formData,
      );

      if (res.data.status === HttpStatus.CONFLICT_409) {
        getSingleStockLevelGivenId();
      } else {
        // Refresh the list of stock levels
        getAllStockLevel();

        // Close the stock level modal
        setIsOpenStockLevelModal(false);

        setIsLoadingToast(false);
        setToastMessage(SUCCESSFULLY_CREATED);
        setToastType("success");
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * This function, handleDecrement, is responsible for decrementing the stockLevel state
   * in a safe and controlled manner. It ensures that the stockLevel is treated as a number,
   * prevents decrementing below zero, and updates the stockLevel state accordingly.
   */
  const handleDecrement = () => {
    // Ensure that stockLevel is a number
    const numericStockLevel = Number(stockLevel);

    // Check if numericStockLevel is a valid number and greater than or equal to 1
    if (!isNaN(numericStockLevel)) {
      // If the conditions are met, decrement the stockLevel by 1 (rounded down)
      setStockLevel(Math.floor(numericStockLevel) - 1);
    }
  };

  /**
   * This function, handleIncrement, is responsible for incrementing the stockLevel state.
   * It checks if the stockLevel has decimal places and rounds it up to the nearest integer.
   * If stockLevel is an integer, it increments it by 1.
   */
  const handleIncrement = () => {
    // Check if stockLevel has decimal places
    if (!_.isEmpty(stockLevel.toString().split(".")[1])) {
      // If it has decimal places, round up to the nearest integer
      setStockLevel(Math.ceil(stockLevel));
    } else {
      // If it's an integer, increment by 1
      setStockLevel(Number(stockLevel) + 1);
    }
  };

  return (
    <>
      <Toast message={toastMessage} type={toastType} loading={isLoadingToast} />
      {/* Main container */}
      {/* InventoryDetails component */}
      {isOpenStockLevelModal && (
        <StockLevelModal
          isOpenStockLevelModal={isOpenStockLevelModal}
          setIsOpenStockLevelModal={setIsOpenStockLevelModal}
          handleChangeStockLevel={handleChangeStockLevel}
          stockLevel={stockLevel}
          handleSaveStockLevel={handleSaveStockLevel}
          stockLevelInitial={stockLevelInitial}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          isLoadingToast={isLoadingToast}
          stockLevelName={stockLevelName}
          displaySuffix={displaySuffix}
        />
      )}
      <InventoryDetails
        locationSelectorList={locationSelectorList}
        isOpenSkeletonLoading={isOpenSkeletonLoading}
        isGetLocationInfo={isGetLocationInfo}
        topic="Stock Items"
        buttonName=""
        handleChangeSearch={handleChangeSearch}
        searchNameTab={searchNameTab}
        setSearchNameTab={setSearchNameTab}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeGroupId={activeGroupId}
        setActiveGroupId={setActiveGroupId}
        selectedDepartmentId={selectedDepartmentId}
        stockItemGroupDepartmentNodeInSelector={
          stockItemGroupDepartmentNodeInSelector
        }
        isOpenDesktopModal={props.additionalProp}
        setSelectedDepartmentId={setSelectedDepartmentId}
        stockItemGroupDepartmentNodeInSelectorInitial={
          stockItemGroupDepartmentNodeInSelectorInitial
        }
        createdId={createdId}
        setCreatedId={setCreatedId}
        isStockTake={true}
        searchedDepartmentList={searchedDepartmentList}
        isActiveLocationId={isActiveLocationId}
      >
        {/* Render AddStockItemsModal if isOpenCreateStockItemsModal is true */}
        {/* StockTake table component */}
        <Grid container>
          <Grid item xs={12} style={{ display: "flex", marginTop: "16px" }}>
            <div style={{ display: "block", width: "100%" }}>
              <StockItemsHeader
                isLoading={isLoading}
                locationSelectorList={locationSelectorList}
                handleOpenStockLevelModal={handleOpenStockLevelModal}
                registerElementWithTitle={registerElementWithTitle}
                stockItemGroupDepartmentNodeInSelector={
                  stockItemGroupDepartmentNodeInSelector
                }
                activeGroupId={activeGroupId}
                stockLevelNode={stockLevelNode}
                searchNameTab={searchNameTab}
              />
            </div>
          </Grid>
        </Grid>
      </InventoryDetails>
      {/* DefaultAlert components for success and error messages */}
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(StockTake, Authorities.INVENTORY_READ);
