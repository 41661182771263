import { Button, Grid, Tooltip, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React from "react";

import CardCommon from "../../../../components/card/CardCommon";
import { Theme } from "../../../../types/customTheme";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import { convertDateTimeFormat } from "../../../../utils/ConvertDateTimeFormat";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridField: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      fontSize: "12px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
      },
    },
    gridFieldLast: {
      display: "flex",
      justifyContent: "end",
      placeItems: "center",
      fontSize: "12px",
      paddingRight: "6px",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        paddingRight: "0px",
        marginBottom: "12px",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      fontSize: "12px",
      placeItems: "center",
      paddingLeft: "18px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        fontSize: "14px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
  }),
);

export interface RolesDetailsNodeProps {
  nodeData: any;
  handleResetPassword: any;
}

/**
 * RolesDetailsNode component displays information about a user role in a card format.
 * It includes the user's name, email, assigned roles, creation date, and last update date.
 * Additionally, it provides an option to reset the user's password, if applicable.
 */
const RolesDetailsNode: React.FunctionComponent<RolesDetailsNodeProps> = ({
  nodeData,
  handleResetPassword,
}) => {
  const maxWidth = useMediaQuery("(max-width: 960px)");
  const classes = useStyles();

  /**
   * Determines whether to show the Reset Password button based on specific conditions.
   * The button is hidden if the user's email includes "@peff.co",
   * has the role "ROLE_MANAGER", and the name ends with "api".
   */
  const handleShowResetButton = () => {
    // Check if conditions for hiding the button are met
    if (
      nodeData.email.includes("@peff.co") &&
      nodeData.authorities.includes("ROLE_MANAGER") &&
      nodeData?.name?.toLowerCase().endsWith("api")
    ) {
      // Hide the button if conditions are met
      return false;
    } else {
      // Show the button if conditions are not met
      return true;
    }
  };

  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Grid container style={{ minHeight: "44px" }}>
          <Grid
            style={{ textAlign: "left" }}
            item
            xs={12}
            md={3}
            className={classes.gridFieldFirstColumn}
          >
            {nodeData.name}
          </Grid>
          <Grid
            style={{ textAlign: "left" }}
            item
            xs={12}
            md={3}
            className={classes.gridField}
          >
            {nodeData.email.toLowerCase()}
          </Grid>

          <Grid
            style={{ textAlign: "left" }}
            item
            xs={12}
            md={2}
            className={classes.gridField}
          >
            {nodeData?.authorities
              ? nodeData?.authorities.replace("ROLE_", "")
              : nodeData?.authorities?.replace("ROLE_", "")}
          </Grid>
          <Grid
            style={{ textAlign: "left" }}
            item
            xs={12}
            md={2}
            className={classes.gridField}
          >
            {convertDateTimeFormat(nodeData.created)}
          </Grid>
          <Grid
            style={{ textAlign: "left" }}
            item
            xs={12}
            md={2}
            className={classes.gridFieldLast}
          >
            {!maxWidth ? (
              <div
                style={{
                  display: "block",
                  marginRight: handleShowResetButton() ? "0px" : "46px",
                }}
              >
                {convertDateTimeFormat(nodeData.updated)}
                {handleShowResetButton() && (
                  <Tooltip title="Reset Password">
                    <Button
                      style={{
                        marginLeft: "8px",
                        padding: "4px",
                        minWidth: "40px",
                      }}
                      onClick={() => handleResetPassword(nodeData.email)}
                    >
                      <span className="material-symbols-outlined">
                        lock_reset
                      </span>
                    </Button>
                  </Tooltip>
                )}
              </div>
            ) : (
              <div style={{ display: "block" }}>
                <div>{convertDateTimeFormat(nodeData.updated)}</div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {handleShowResetButton() && (
                    <Tooltip title="Reset Password">
                      <Button
                        style={{
                          marginLeft: "8px",
                          padding: "4px",
                          minWidth: "40px",
                        }}
                        onClick={() => handleResetPassword(nodeData.email)}
                      >
                        <span className="material-symbols-outlined">
                          lock_reset
                        </span>
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </CardCommon>
    </>
  );
};

export default withAuthority(RolesDetailsNode, Authorities.SALE_READ);
