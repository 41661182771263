import { axiosClient } from "../client";

const queryString = require("query-string");

const API_GET_MENUS_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/menu`;

const API_GET_CATEGORIES_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/category`;

  const API_GET_SINGLE_MENU_INFO = (locationId, categoryId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/menu/id/${categoryId}`;

const API_GET_SINGLE_CATEGORIES_INFO = (locationId, categoryId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/category/id/${categoryId}`;

  const API_GET_SINGLE_PRODUCT_WRAPPER_INFO = (locationId, categoryId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/productWrapper/id/${categoryId}`;
  

const API_GET_SINGLE_PRODUCT_INFO = (locationId, productId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/product/id/${productId}`;

const API_GET_PRODUCT_INFO_BY_WRAPPER_ID = (locationId, productId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/product/${productId}`;

const API_UPDATE_MENUS_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/menu`;

const API_CREATE_MENUS_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/menu`;

const API_CREATE_CATEGORY_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/category`;

const API_UPDATE_CATEGORY_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/category`;

const API_CREATE_PRODUCT_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/product`;

const API_UPDATE_PRODUCT_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/product`;

  const API_UPDATE_PRODUCT_WRAPPER_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/product-wrapper`;

  const API_UPDATE_WRAPPER_INFO = (locationId) =>
    `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/productWrapper`;

const API_CREATE_MENU_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/menu`;

  const API_UPDATE_MENU_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/menu`;

const API_CREATE_PRODUCT_WRAPPER = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/product-wrapper`;

const API_CREATE_WRAPPER = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/productWrapper`;

export const fetchMenusInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_MENUS_INFO(locationId),
        query: { size: 20000, page: 1 },
      },
      { arrayFormat: "comma" },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateMenuSInfo = (locationId, data) =>
  axiosClient({
    method: "PATCH",
    url: queryString.stringifyUrl(
      {
        url: API_UPDATE_MENUS_INFO(locationId),
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    data,
  });

export const createMenusInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    data,
    url: API_CREATE_MENUS_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchCategoriesInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_CATEGORIES_INFO(locationId),
        query: { size: 20000, page: 1 },
      },
      { arrayFormat: "comma" },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchSingleCategoryInfo = (locationId, categoryId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_SINGLE_CATEGORIES_INFO(locationId, categoryId),
      },
      { arrayFormat: "comma" },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const fetchSingleMenuInfo = (locationId, categoryId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_SINGLE_MENU_INFO(locationId, categoryId),
      },
      { arrayFormat: "comma" },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const fetchSingleProductWrapper = (locationId, productWrapperId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_SINGLE_PRODUCT_WRAPPER_INFO(locationId, productWrapperId),
      },
      { arrayFormat: "comma" },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchSingleProductInfo = (locationId, categoryId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: API_GET_SINGLE_PRODUCT_INFO(locationId, categoryId),
      },
      { arrayFormat: "comma" },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const fetchProductGetByWrapperInfo = (locationId, categoryId) =>
    axiosClient({
      method: "GET",
      url: queryString.stringifyUrl(
        {
          url: API_GET_PRODUCT_INFO_BY_WRAPPER_ID(locationId, categoryId),
        },
        { arrayFormat: "comma" },
      ),
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
      },
    });

export const createCategoryInfo = (
  locationId,
  data,
  message,
  summery,
  logsAppName,
) =>
  axiosClient({
    method: "POST",
    data,
    url: API_CREATE_CATEGORY_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    message,
    summery,
    logsAppName,
    locationId,
  });

export const updateCategoryInfo = (
  locationId,
  data,
  message,
  summery,
  logsAppName,
) =>
  axiosClient({
    method: "PATCH",
    data,
    url: API_UPDATE_CATEGORY_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    message,
    summery,
    logsAppName,
    locationId,
  });

export const createProductInfo = (
  locationId,
  data,
  message,
  summery,
  logsAppName,
) =>
  axiosClient({
    method: "POST",
    data,
    url: API_CREATE_PRODUCT_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    message,
    summery,
    logsAppName,
    locationId,
  });

export const updateProductInfo = (
  locationId,
  data,
  message,
  summery,
  logsAppName,
) =>
  axiosClient({
    method: "PATCH",
    data,
    url: API_UPDATE_PRODUCT_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    message,
    summery,
    logsAppName,
    locationId,
  });

  export const updateMenuInfo = (
    locationId,
    data,
    message,
    summery,
    logsAppName,
  ) =>
    axiosClient({
      method: "PATCH",
      data,
      url: API_UPDATE_MENU_INFO(locationId),
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
      },
      message,
      summery,
      logsAppName,
      locationId,
    });

    export const updateWrapperInfo = (
      locationId,
      data,
      message,
      summery,
      logsAppName,
    ) =>
      axiosClient({
        method: "PATCH",
        data,
        url: API_UPDATE_WRAPPER_INFO(locationId),
        headers: {
          "X-Role-Location": locationId,
          "Content-Type": "application/json",
        },
        message,
        summery,
        logsAppName,
        locationId,
      });

    export const updateProductWrapperInfo = (
      locationId,
      data,
      message,
      summery,
      logsAppName,
    ) =>
      axiosClient({
        method: "PATCH",
        data,
        url: API_UPDATE_PRODUCT_WRAPPER_INFO(locationId),
        headers: {
          "X-Role-Location": locationId,
          "Content-Type": "application/json",
        },
        message,
        summery,
        logsAppName,
        locationId,
      });

export const createMenuInfo = (
  locationId,
  data,
  message,
  summery,
  logsAppName,
) =>
  axiosClient({
    method: "POST",
    data,
    url: API_CREATE_MENU_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    message,
    summery,
    logsAppName,
    locationId,
  });

export const createProductWrapper = (
  locationId,
  data,
  message,
  summery,
  logsAppName,
) =>
  axiosClient({
    method: "POST",
    data,
    url: API_CREATE_PRODUCT_WRAPPER(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    message,
    summery,
    logsAppName,
    locationId,
  });

  export const createWrapper = (
    locationId,
    data,
    message,
    summery,
    logsAppName,
  ) =>
    axiosClient({
      method: "POST",
      data,
      url: API_CREATE_WRAPPER(locationId),
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
      },
      message,
      summery,
      logsAppName,
      locationId,
    });
