import * as React from "react";
import {
  Button,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import DialogCommon from "../../../../components/dialogs/DialogCommon";
import { SketchPicker   } from "react-color";

export interface ColorPickerDialogProps {
  open: boolean;
  setOpen: any;
  color: string;
  setColor: any;
  setInitialData: any;
}

const ColorPickerDialog: React.FunctionComponent<ColorPickerDialogProps> = ({
  open,
  setOpen,
  color,
  setColor,
  setInitialData,
}) => {

  return (
    <DialogCommon open={open} setOpen={setOpen}>
      <DialogTitle id="alert-dialog-title">Change Color</DialogTitle>
      <SketchPicker    color={color} onChangeComplete={setColor} />
      <DialogActions>
        <Button
          onClick={() => {
            setInitialData();
            setOpen(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button onClick={() => setOpen(false)} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </DialogCommon>
  );
};

export default ColorPickerDialog;
