import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { CustomTheme } from "../../../../types/customTheme";
import {
  fetchSingleCategoryInfo,
  updateCategoryInfo,
} from "../../../../services/menuApp/menuService";
import { useRouteMatch } from "react-router-dom";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { AxiosError } from "axios";
import _ from "lodash";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      zIndex: 5,
      [theme.breakpoints.up("sm")]: {
        marginTop: "62px",
      },
      [theme.breakpoints.up("1280")]: {
        marginTop: "0px",
      },
    },
    button: {
      position: "relative",
      overflow: "hidden",
      borderBottomRightRadius: "10px",

    },
    categoryStyle: {
      backgroundColor: theme.palette.background.default,
      position: "sticky",
      zIndex: 1,
      display: "flex",
      top: 126,
      paddingTop: "28px",
      [theme.breakpoints.up("600")]: {
        top: 200,
      },
      [theme.breakpoints.up("960")]: {
        top: 126,
      },
      [theme.breakpoints.up("1280")]: {
        top: 63,
        paddingTop: "41px",
        // paddingTop: "52px",
      },
    },
    categoryTitle: {
      fontSize: "20px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "32px",
      },
      [theme.breakpoints.up("1280")]: {
        fontSize: "32px",
      },
    },
  }),
);

export interface LoadingProps {
  category: any;
  setError: any;
  setIsValidError: any;
  setSuccess: any;
  menuNodeList: any;
  setMenuNodeList: any;
  menuId: any;
}

/* CategoryValidIn the page until data is retrieved from the API call. */
const CategoryValidIn: React.FunctionComponent<LoadingProps> = ({
  category,
  setError,
  setIsValidError,
  setSuccess,
  menuNodeList,
  setMenuNodeList,
  menuId,
}) => {
  const match: any = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeValidInCategory = async (category: any, version?: any) => {
    setIsValidError({});
    const body = {
      id: category.id,
      valid: !category.valid,
      version: version || category.version,
    };
    setIsLoading(true);
    try {
      let summary = [];
      if (category.valid !== !category.valid) {
        summary.push(
          `Category id ${
            category.id
          } valid changed from ${!category.valid} to ${category.valid}`,
        );
      }

      const summaryString = summary.join("\\n");

      const res = await updateCategoryInfo(
        match.params.locationId,
        body,
        "Menus Changed",
        summaryString,
        "menu",
      );
      if (res.data.status === 409) {
        handleGetSingleCategoryWhenValidChange(category);
      } else {
        const cloneMenuNodeList = _.cloneDeep(menuNodeList);

        if (!cloneMenuNodeList[menuId].category) {
          cloneMenuNodeList[menuId].category = {};
        }

        cloneMenuNodeList[menuId].category[res.data.data.id].valid =
          res.data.data.valid;
        cloneMenuNodeList[menuId].category[res.data.data.id].version =
          res.data.data.version;
        setMenuNodeList(cloneMenuNodeList);
        setIsLoading(false);
        setSuccess(SUCCESSFULLY_UPDATED);
      }
    } catch (error) {
      const err: any = error as AxiosError;

      const errorMessage = err.response ? err.response.data : err.message;
      setIsValidError({ id: category.id, error: errorMessage.error });
      setIsLoading(false);
    }
  };

  const handleGetSingleCategoryWhenValidChange = async (category: any) => {
    try {
      const res = await fetchSingleCategoryInfo(
        match.params.locationId,
        category.id,
      );
      handleChangeValidInCategory(category, res.data.data.version);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };
  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  return (
    <Button
      size={"small"}
      className={classes.button}
      style={{
        minWidth: "28px",
        height: "32px",
        backgroundColor: category.valid
          ? theme.palette.custom.green.main
          : theme.palette.custom.red.main,
      }}
      onClick={() => handleChangeValidInCategory(category)}
    >
      {isLoading ? (
        <CircularProgress color="inherit" size={16} />
      ) : category.valid ? (
        <VisibilityIcon
          style={{
            fontSize: "16px",
            color: theme.palette.custom.red.contrastText,
          }}
        />
      ) : (
        <VisibilityOffIcon
          style={{
            fontSize: "16px",
            color: theme.palette.custom.red.contrastText,
          }}
        />
      )}
    </Button>
  );
};

export default CategoryValidIn;
