/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  createStyles,
  Divider,
  FormControlLabel,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import React from "react";
import { HeaderCellSort } from "@table-library/react-table-library/sort";
import SwitchCommon from "../../../../../components/switches/SwitchCommon";
import CardCommon from "../../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../../types/customTheme";

const useStyles = (
  nodesLength: number,
  nodesHeadersLength: number,
  firstColumnWidth: string,
  columnWidth: string,
) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        paddingLeft: "16px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
      },
      textStyle: {
        display: "flex",
        justifyContent: "center",
      },
      endTextStyle: {
        display: "flex",
        justifyContent: "end",
        paddingRight: "16px",
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.table_header_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      headerRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      baseCellRow: {
        placeItems: "center",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: firstColumnWidth,
          width: firstColumnWidth,
        },
        [theme.breakpoints.down("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
        },
        [theme.breakpoints.down("lg")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
            [`&:nth-of-type(${nodesHeadersLength})`]: {
              minWidth: columnWidth,
              width: columnWidth,
              "& .css-16gtl2w": {
                display: "flex",
                justifyContent: "end",
                fontWeight: "bold",
                paddingRight: "12px",
              },
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: firstColumnWidth,
            width: firstColumnWidth,
          },
        },

        [theme.breakpoints.down("md")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: columnWidth,
            width: columnWidth,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: firstColumnWidth,
            width: firstColumnWidth,
          },
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: "32%",
            width: "32%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: "40%",
            width: "40%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "40%",
            width: "40%",
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: "55%",
            width: "55%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: "70%",
            width: "70%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "55%",
            width: "55%",
          },
        },
      },
    }),
  );

/* This component displays the BreakDownOfShift table. */
export interface AvailabilitySelectionModalProps {
  nodesLength: any;
  nodesHeadersLength: any;
  firstColumnWidth: any;
  columnWidth: any;
  reportName: any;
  nodes: any;
  sort: any;
  headersList: any;
  handleBreakdownShift: any;
  isShowBreakdownShift: any;
}

const BreakDownOfShiftGenerateTable: React.FunctionComponent<
  AvailabilitySelectionModalProps
> = ({
  nodesLength,
  nodesHeadersLength,
  firstColumnWidth,
  columnWidth,
  reportName,
  nodes,
  sort,
  headersList,
  handleBreakdownShift,
  isShowBreakdownShift,
}) => {
  const maxWidth = useMediaQuery("(max-width: 960px)");
  const maxWidthCustom = useMediaQuery("(max-width: 1600px)");
  const theme: CustomTheme = useTheme();
  const classes: any = useStyles(
    nodesLength,
    nodesHeadersLength,
    firstColumnWidth,
    columnWidth,
  )();

  return (
    <>
      <Divider style={{ margin: "16px", marginTop: "24px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          marginBottom: "12px",
          marginTop: "24px",
        }}
      >
        <FormControlLabel
          control={<SwitchCommon />}
          checked={isShowBreakdownShift}
          onChange={handleBreakdownShift}
          style={{ marginRight: "4px" }}
          label={
            <Typography align="left" variant="h6">
              {reportName}
            </Typography>
          }
          labelPlacement="start"
        />
      </div>
      <div style={{ margin: "16px" }}>
        <CardCommon>
          <Table
            data={nodes}
            sort={sort}
            layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
          >
            {(tableList) => (
              <>
                <Header style={{ zIndex: 0 }}>
                  <HeaderRow className={classes.headerStyle}>
                    {headersList.map((data: any, index: any) => {
                      return (
                        <HeaderCellSort
                          sortKey={data}
                          pinLeft={index === 0 ? true : false}
                          className={classes.baseCellRow}
                        >
                          <Typography
                            variant="caption"
                            className={
                              index === 0
                                ? classes.startTextStyle
                                : classes.textStyleHeader
                            }
                            style={{
                              fontWeight: "bold",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                          >
                            {data}
                          </Typography>
                        </HeaderCellSort>
                      );
                    })}
                  </HeaderRow>
                </Header>

                <Body className={classes.bodyStyle}>
                  {tableList.map((item, bodyIndex) => (
                    <>
                      <Row
                        key={item.gross20}
                        item={item}
                        className={classes.headerRow}
                      >
                        {Object.keys(item).map((data: any, index: any) => {
                          return (
                            <>
                              <Cell
                                pinLeft={index === 0 ? true : false}
                                className={classes.baseCellRow}
                              >
                                <Typography
                                  variant="caption"
                                  className={
                                    index === 0
                                      ? classes.startTextStyle
                                      : index + 1 === Object.keys(item).length
                                      ? classes.endTextStyle
                                      : classes.textStyle
                                  }
                                >
                                  {item[data]}
                                </Typography>
                              </Cell>
                            </>
                          );
                        })}
                      </Row>
                    </>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </CardCommon>
      </div>
    </>
  );
};

export default BreakDownOfShiftGenerateTable;
