import { createStyles, makeStyles } from "@material-ui/core";
import * as React from "react";
import { CustomTheme } from "../../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    previewBox: {
      maxWidth: 220,
      marginLeft: "auto",
      height: 480,
      borderRadius: 8,
      border: `1px solid ${theme.palette.background.entity_border}`,
      padding: 20,
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
      },
    },
  }),
);

export interface PreviewBoxProps {
  children: React.ReactNode;
  bgColor?: string;
}

/* Used to show the color preview box. */
const PreviewBox: React.FunctionComponent<PreviewBoxProps> = ({
  children,
  bgColor,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.previewBox} style={{ backgroundColor: bgColor }}>
      {children}
    </div>
  );
};

export default PreviewBox;
