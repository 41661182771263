import { axiosClient } from "../client";

const queryString = require("query-string");

const PAYMENT_GATEWAY_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/payment-gateway/prex-admin`;

const PAYMENT_GATEWAY_INFO_UPDATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_EATPRESTO}/location/${locationId}/payment-gateway/prex-admin`;

export const fetchPaymentGatewayInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
      url: PAYMENT_GATEWAY_INFO_GET_ALL(locationId),
    }),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updatePaymentGatewayInfo = (locationId, data) =>
  axiosClient({
    method: "PUT",
    url: PAYMENT_GATEWAY_INFO_UPDATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
