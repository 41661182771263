import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React from "react";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type Severity = "success" | "info" | "warning" | "error";

export interface DefaultAlertProps {
  open: boolean;
  handleClose: (event?: React.SyntheticEvent, reason?: string) => void;
  message: string;
  severity: Severity;
}

/* Show the warning, success, and error notification using snackbar. */
const DefaultAlert: React.FunctionComponent<DefaultAlertProps> = ({
  open,
  handleClose,
  message,
  severity,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default DefaultAlert;
