import * as React from "react";
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";

import { buttonColors } from "../../../../utils/enum";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefault from "../../../../components/textField/TextfieldDefault";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) => ({
  textField: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    backgroundColor: theme.palette.background.entity_highlight_background,
    borderRadius: "10px",
    border: "none",
    [`& fieldset`]: {
      borderRadius: "10px",
      border: "none",
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  autoComplete: {
    marginTop: "4px",
    "& .MuiAutocomplete-input": {
      color: theme.palette.custom.orange.contrastText,
    },
    "& .MuiInputBase-root": {
      color: "inherit",
      backgroundColor: theme.palette.background.entity_background,
      borderRadius: 10,
    },
    [`& fieldset`]: {
      borderRadius: 10,
      border: `1px solid ${theme.palette.background.entity_border}`,
      color: theme.palette.custom.orange.contrastText,
    },
  },
  titleColor: {
    color: theme.palette.custom.orange.contrastText,
  },
}));

export interface TableNoAddModalProps {
  isOpenTableNoAddModal: boolean;
  setIsOpenTableNoAddModal: any;
  handleUpdateTableNo: any;
  handleChangeTable: any;
  handleInputTableNo: any;
  selectedOptionsTableModal: any;
  handleCloseTableNoAddModal: any;
}

/**
 * TableNoAddModal Component
 *
 * This component represents a modal for adding a new department to a stock group.
 * Users can select an existing group or create a new one, then enter the department name.
 */
const TableNoAddModal: React.FunctionComponent<TableNoAddModalProps> = ({
  isOpenTableNoAddModal,
  setIsOpenTableNoAddModal,
  handleUpdateTableNo,
  handleChangeTable,
  handleInputTableNo,
  selectedOptionsTableModal,
  handleCloseTableNoAddModal,
}) => {
  const classes = useStyles();

  return (
    <DialogCommonDefault
      open={isOpenTableNoAddModal}
      setOpen={setIsOpenTableNoAddModal}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
    >
      <DialogTitle>Set Table Numbers</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                width: "100%",
                overflowX: "auto",
              }}
            >
              <Autocomplete
                options={[]}
                multiple
                size={"small"}
                color="inherit"
                freeSolo
                style={{ width: "100%" }}
                disableClearable
                fullWidth
                classes={{ root: classes.autoComplete }}
                value={selectedOptionsTableModal}
                onChange={(event, newValue) => {
                  handleChangeTable(newValue);
                }}
                renderTags={(value, props) =>
                  value.map((option, index) => (
                    <Chip label={option} {...props({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    className={classes.textField}
                    multiline
                    label="Table Numbers"
                    onInput={handleInputTableNo}
                    {...params}
                    size="medium"
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px", marginRight: "16px" }}>
        <ButtonCommon
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "12px",
          }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
          onClick={handleCloseTableNoAddModal}
        >
          Cancel
        </ButtonCommon>

        <ButtonCommon
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
          }}
          color={buttonColors.CREATE_BUTTON_COLOR}
          onClick={handleUpdateTableNo}
        >
          Save
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default TableNoAddModal;
