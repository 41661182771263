import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MobileStepper,
  Typography,
  useTheme,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import DialogCommon from "../../dialogs/DialogCommon";
import GoogleMap from "../googleMap.tsx";
import BusinessInformation from "./BusinessInformation";
import BusinessContactInformation from "./BusinessContactInformation";
import { CustomTheme } from "../../../types/customTheme";

export interface HomeProps {
  open: boolean;
  setOpen: any;
  handleCloseModal: any;
  locationData: any;
  isValidated: any;
  handleChangeLocationData: any;
  handleChangeLocationAddress: any;
  address: any;
  isOpenModelCloseConfirmationModel: any;
  activeStep: any;
  handleSubmitData: any;
  handleBack: any;
  setIsOpenModelCloseConfirmationModel: any;
  handleResetModelData: any;
}

/* This component show the UI of location create modal */
const LocationCreate: React.FunctionComponent<HomeProps> = ({
  open,
  setOpen,
  handleCloseModal,
  locationData,
  isValidated,
  handleChangeLocationData,
  handleChangeLocationAddress,
  isOpenModelCloseConfirmationModel,
  address,
  activeStep,
  handleSubmitData,
  handleBack,
  setIsOpenModelCloseConfirmationModel,
  handleResetModelData,
}) => {
  const getStepContent = (step: any) => {
    switch (step) {
      case 0:
        return (
          <BusinessInformation
            handleCloseModal={handleCloseModal}
            locationData={locationData}
            isValidated={isValidated}
            handleChangeLocationData={handleChangeLocationData}
          />
        );
      case 1:
        return (
          <BusinessContactInformation
            handleCloseModal={handleCloseModal}
            locationData={locationData}
            isValidated={isValidated}
            handleChangeLocationData={handleChangeLocationData}
          />
        );
      case 2:
        return (
          <Grid container style={{ padding: "16px" }}>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "8px",
                marginLeft: "4px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">Business Trading Address</Typography>
              <CancelIcon
                style={{ marginRight: "4px", cursor: "pointer" }}
                onClick={handleCloseModal}
              />
            </Grid>
            <GoogleMap
              address={address}
              isValidated={isValidated}
              handleChangeLocationAddress={handleChangeLocationAddress}
              isNeedTextBox={true}
              height={320}
            />
          </Grid>
        );
      default:
        break;
    }
  };

  const maxSteps = 3;
  const theme: CustomTheme = useTheme();

  return (
    <>
      <Dialog
        hidden={isOpenModelCloseConfirmationModel}
        PaperProps={{
          style: {
            borderRadius: "10px",
            border: `1px solid ${theme.palette.background.entity_border}`,
            backgroundColor: theme.palette.background.entity_background,
          },
        }}
        open={open}
        fullWidth
        disableBackdropClick
        onClose={() => setOpen(false)}
        style={{ zIndex: 5 }}
      >
        {getStepContent(activeStep)}
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleSubmitData}
              disabled={activeStep === maxSteps}
            >
              {activeStep === 2 ? "Finish" : "Next"}
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            activeStep !== 0 && (
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            )
          }
        />
      </Dialog>

      <DialogCommon
        open={isOpenModelCloseConfirmationModel}
        setOpen={setIsOpenModelCloseConfirmationModel}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Are you sure you want to cancel?
        </DialogTitle>
        <DialogContent>
          Once confirmed all data entered will be lost.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpenModelCloseConfirmationModel(false);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleResetModelData();
            }}
            color="secondary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </DialogCommon>
    </>
  );
};

export default LocationCreate;
