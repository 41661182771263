import {
  InputAdornment,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import { CustomTheme } from "../../../../../types/customTheme";


const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    "& .MuiTabs-root": {
      maxWidth: "calc(100vw - 320px)",
    },
  },
  rootCenterTabs: {
    "& .MuiTabs-root": {},
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
}));

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export interface DesktopNavProps {
  allStockOrderItemNodeInitial: any;
  activeGroupId: any;
  handleChange: any;
  searchValue: any;
  handleChangeSearchUsingSearchTextbox: any;
  handleClearSearch: any;
  isDisable: any;
}

/**
 * This component represents the desktop navigation bar for selecting departments and searching.
 * It contains tabs for different groups and a search input field.
 */
const DesktopNav: React.FunctionComponent<DesktopNavProps> = ({
  allStockOrderItemNodeInitial,
  activeGroupId,
  handleChange,
  searchValue,
  handleChangeSearchUsingSearchTextbox,
  handleClearSearch,
  isDisable,
}) => {
  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
        <Tabs
          classes={{
            root:
               classes.root,
          }}
          value={activeGroupId}
          onChange={handleChange}
          variant="scrollable"
          selectionFollowsFocus
          scrollButtons={matchesSmallScreen?"on":"auto"}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="scrollable groups list"
        >
          {!_.isEmpty(allStockOrderItemNodeInitial) &&
            allStockOrderItemNodeInitial.map((group: any, idx: number) => (
              <Tab
                key={String(idx)}
                disabled={isDisable}
                label={
                  <Typography style={{ fontSize: "14px" }}>
                    {group.name}
                  </Typography>
                }
                {...a11yProps(group)}
              />
            ))}
        </Tabs>
  );
};

export default DesktopNav;
