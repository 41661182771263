import {
  Chip,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import RemoveIcon from "@material-ui/icons/Remove";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Icon } from "@iconify/react";
import SignalCellularAltIcon from "@material-ui/icons/SignalCellularAlt";

import Chart from "./Chart/Chart";
import { convertCurrentPreviousValuePercentage } from "../../../../../utils/convertCurrentPreviousValuePercentage";
import {
  CapitalizeFirstLetter,
  replaceCommon,
} from "../../../../../utils/ReplaceIcon";
import StackedAreaChart from "../StackedAreaChart";
import StackedBarChart from "../StackedBarChart";
import AreaChart from "../BarChart";
import {
  getLocalStore,
  setLocalStore,
} from "../../../../../utils/store/localStore";
import area from "../../../../../assets/images/area.png";
import bar from "../../../../../assets/images/bar.png";
import stacked_area from "../../../../../assets/images/stacked_area.png";
import line from "../../../../../assets/images/line.png";
import { CustomTheme } from "../../../../../types/customTheme";

const useStyles = makeStyles<any>((theme: CustomTheme) =>
  createStyles({
    currentPeriodStyle: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      color: "#25ACE3",
      fontSize: "16px",
      paddingLeft: "16px",
      height: "40px",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "16px",
        marginTop: "4px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        paddingLeft: "6px",
      },
    },
    totalCurrentPeriodStyle: {
      display: "flex",
      justifyContent: "start",
      color: "#25ACE3",
      fontSize: "36px",
      paddingLeft: "16px",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "10x",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
        paddingLeft: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "22px",
        paddingLeft: "6px",
      },
    },
    totalPreviousPeriodStyle: {
      display: "flex",
      justifyContent: "end",
      color: "#25ACE3",
      fontSize: "36px",
      paddingRight: "16px",
      [theme.breakpoints.down("md")]: {
        paddingRight: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
        paddingRight: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "22px",
        paddingRight: "6px",
      },
    },
    previousPeriodStyle: {
      display: "flex",
      justifyContent: "end",
      color: "#25ACE3",
      fontSize: "16px",
      paddingRight: "16px",
      height: "40px",
      marginTop: "8px",
      [theme.breakpoints.down("md")]: {
        paddingRight: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        paddingRight: "6px",
      },
    },
    titleStyle: {
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    iconStyle: {
      paddingRight: "8px",
      marginTop: "1px",
      fontSize: "28px",
      [theme.breakpoints.down("md")]: {
        paddingRight: "4px",
        fontSize: "22px",
        marginTop: "2px",
      },
    },
    chipStyle: {
      fontSize: "12px",
      marginLeft: "16px",
      color: "white",
      padding: "4px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "8px",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: "4px",
        padding: "0px",
        fontSize: "10px",
        margin: "0px",
      },
    },
    radioButtonStyle: {
      "& .MuiSvgIcon-fontSizeSmall ": {
        fontSize: "18px",
      },
    },
  }),
);

export interface DashboardProps {
  previousStartDate: any;
  previousEndDate: any;
  currentStartDate: any;
  currentEndDate: any;
  shortPreviousDateRange: any;
  shortCurrentDateRange: any;
  previousDashboardNodeList: any;
  dashboardNodeList: any;
  isEmpty?: any;
  order: any;
  setChecked: any;
  checked: any;
  setCheckedCurrentPreviousToggle: any;
  checkedCurrentPreviousToggle: any;
  value: any;
  setValue: any;
  isChannel?: any;
  isAverage?: any;
  currentSalesTotal?: any;
  previousSalesTotal?: any;
}

/*
  DashboardChart Component:
  This component renders a dashboard chart with various chart types (line, area, stacked area, and stacked bar).
  It provides UI controls for selecting different chart types, toggling between current and previous periods,
  and displays values for the current and previous periods.

*/
const DashboardChart: React.FunctionComponent<DashboardProps> = ({
  previousStartDate,
  previousEndDate,
  currentStartDate,
  currentEndDate,
  shortPreviousDateRange,
  shortCurrentDateRange,
  previousDashboardNodeList,
  dashboardNodeList,
  isEmpty,
  order,
  setChecked,
  checked,
  setCheckedCurrentPreviousToggle,
  checkedCurrentPreviousToggle,
  value,
  setValue,
  isChannel,
  isAverage,
  currentSalesTotal,
  previousSalesTotal,
}) => {
  const [dateDifference, setDateDifference] = useState(0);
  const [data, setData] = useState<any>({ datasets: [] });
  const [dataSetStackedAreaGraphCurrent, setDataStackedAreaGraphCurrent] =
    useState<any>({
      datasets: [],
    });
  const [dataSetStackedBarGraph, setDataStackedBarGraph] = useState<any>({
    datasets: [],
  });
  const [dataSetStackedAreaGraphPrevious, setDataStackedAreaGraphPrevious] =
    useState<any>({
      datasets: [],
    });
  const [previousDataTotal, setPreviousDateListTotal] = useState("");
  const [currentDataTotal, setCurrentDateListTotal] = useState("");
  const [currentData, setCurrentData] = useState("0");
  const [currentDataInitial, setCurrentPercentageInitial] = useState("0");
  const [currentPeriodHoverValue, setCurrentPeriodHoverValue] = useState("");
  const [previousPeriodHoverValue, setPreviousPeriodHoverValue] = useState("");
  const [dataSetLength, setDataSetLength] = useState(0);
  const [dataIndex, setDataIndex] = useState("");
  const [currentDataConvertObject, setCurrentDataConvertObject] = useState<any>(
    {},
  );
  const [previousDataConvertObject, setPreviousDataConvertObject] =
    useState<any>({});
  const [allPercentageObject, setAllPercentageObject] = useState<any>({});
  const isDarkMode = getLocalStore("isDarkMode");

  const theme: CustomTheme = useTheme();

  /* set the data object format. 
      ex:- data = [97, 79, 73, 60, 59]
      currentDataConvertObject = {0: 97, 1: 79, 2: 73, 3: 60, 4: 59}*/
  const handleConvertObjectFormat = (dataConvertObject: any, data: any) => {
    data.map((data2: any, index1: any) => {
      if (dataConvertObject[index1] === undefined) {
        dataConvertObject[index1] = 0;
      }
      dataConvertObject[index1] = dataConvertObject[index1] + data2;
    });
    return dataConvertObject;
  };

  /* Get the key of mergePreviousAndCurrentDashboardNodeList. 
     This will get the desired values ​​for the tooltip of the previous and current charts.
     ex:- mergePreviousAndCurrentDashboardNodeList = 
              {2022-11-08: {total_amount: 59}, 2022-11-09: {total_amount: 64}, 2022-11-10: {total_amount: 7}}
          output = ['total_amount']  */
  const handleTooltipKey = (mergePreviousAndCurrentDashboardNodeList: any) => {
    let dashboardKey: any = [];
    if (!_.isEmpty(mergePreviousAndCurrentDashboardNodeList)) {
      const dashboardValue: any = Object.values(
        mergePreviousAndCurrentDashboardNodeList,
      );

      if (!_.isEmpty(dashboardValue)) {
        dashboardValue.map((value: any, key: any) => {
          Object.keys(value).map((data: any) => {
            const selectedList: any = new Set([...dashboardKey, data]);

            dashboardKey = [...selectedList];
          });
        });
      }
    }
    return dashboardKey;
  };

  /* Entering the value corresponding to the relevant date into the object. Using a previous date range.
     Here values ​​related to dates are obtained.
     Here, the previous day's list data length, 
     the sum of the list values, the list data as a list [{total_amount: [66, 9, 73, 14, 14]}], 
     and the list data as an object are returned {total_amount: [66, 9, 73, 14, 14]}. */
  const handelChartData = (dashboardKey: any) => {
    // sum of the list values
    let previousDataTotal = 0;
    // list data as a list
    let previousDataList: any = [];
    // list data as an object
    let previousDataObject: any = {};
    // previous day's list data length
    let previousDateListLength = 0;
    dashboardKey.map((data: any) => {
      let previousDateList: any = [];
      // Create a new Date object
      let dateObj = new Date();
      // Set the hour to 22
      dateObj.setUTCHours(0);

      // Get an array of all the keys in the object

      shortPreviousDateRange.map((date: any, index: number) => {
        if (data !== "wix") {
          if (
            moment(currentStartDate).format("D MMM") ===
              moment(currentEndDate).format("D MMM") &&
            moment(previousStartDate).format("D MMM") ===
              moment(previousEndDate).format("D MMM")
          ) {
            // Get the hour
            let hour = dateObj.getHours();

            if (index === 0) {
              if (
                moment(dateObj).format("YYYY-MM-DD") ===
                moment(new Date()).format("YYYY-MM-DD")
              ) {
                for (let i = 0; i < hour; i++) {
                  previousDateList.push(0);
                }
              }
            }
          }

          if (!_.isEmpty(previousDashboardNodeList[date])) {
            if (previousDashboardNodeList[date][data] !== undefined) {
              previousDateList.push(previousDashboardNodeList[date][data]);
              previousDataTotal =
                previousDataTotal + previousDashboardNodeList[date][data];
            } else {
              previousDateList.push(0);
            }
          } else {
            previousDateList.push(0);
          }
        }
      });
      if (
        moment(currentStartDate).format("D MMM") ===
          moment(currentEndDate).format("D MMM") &&
        moment(previousStartDate).format("D MMM") ===
          moment(previousEndDate).format("D MMM")
      ) {
        let hour = dateObj.getHours();

        if (
          moment(dateObj).format("YYYY-MM-DD") !==
          moment(new Date()).format("YYYY-MM-DD")
        ) {
          for (let i = 0; i < 24 - hour; i++) {
            previousDateList.push(0);
          }
        }
      }

      if (shortPreviousDateRange.length !== shortCurrentDateRange.length) {
        previousDateList.push(0);
      }
      previousDateListLength = previousDateList.length;
      previousDataList.push({ [`${data}P`]: previousDateList });
      previousDataObject[`${data}P`] = previousDateList;
    });

    return {
      previousDataTotal,
      previousDateListLength,
      previousDataList,
      previousDataObject,
    };
  };

  /* Entering the value corresponding to the relevant date into the object. Using a current date range.
      Here values ​​related to dates are obtained.
      Here, the current day's list data length, 
      the sum of the list values, the list data as a list [{total_amount: [66, 9, 73, 14, 14]}], 
      and the list data as an object are returned {total_amount: [66, 9, 73, 14, 14]}. */
  const handleCurrentChartData = (dashboardKey: any) => {
    let currentDataList: any = [];
    let currentDataTotal = 0;
    let currentDataObject: any = {};
    dashboardKey.map((data: any, index: number) => {
      let currentDateList: any = [];
      // Create a new Date object
      let dateObj = new Date();
      // Set the hour to 22
      dateObj.setUTCHours(0);

      shortCurrentDateRange.map((date: any, index: number) => {
        if (data !== "wix") {
          if (
            moment(currentStartDate).format("D MMM") ===
              moment(currentEndDate).format("D MMM") &&
            moment(previousStartDate).format("D MMM") ===
              moment(previousEndDate).format("D MMM")
          ) {
            let hour = dateObj.getHours();
            if (index === 0) {
              if (
                moment(dateObj).format("YYYY-MM-DD") ===
                moment(new Date()).format("YYYY-MM-DD")
              ) {
                for (let i = 0; i < hour; i++) {
                  currentDateList.push(0);
                }
              }
            }
          }
          if (!_.isEmpty(dashboardNodeList[date])) {
            if (dashboardNodeList[date][data] !== undefined) {
              currentDateList.push(dashboardNodeList[date][data]);
              currentDataTotal =
                currentDataTotal + dashboardNodeList[date][data];
            } else {
              currentDateList.push(0);
            }
          } else {
            currentDateList.push(0);
          }
        }
      });
      if (
        moment(currentStartDate).format("D MMM") ===
          moment(currentEndDate).format("D MMM") &&
        moment(previousStartDate).format("D MMM") ===
          moment(previousEndDate).format("D MMM")
      ) {
        let hour = dateObj.getHours();
        if (
          moment(dateObj).format("YYYY-MM-DD") !==
          moment(new Date()).format("YYYY-MM-DD")
        ) {
          for (let i = 0; i < 24 - hour; i++) {
            currentDateList.push(0);
          }
        }
      }
      currentDataList.push({ [`${data}C`]: currentDateList });
      currentDataObject[`${data}C`] = currentDateList;
    });

    return {
      currentDataTotal,
      currentDataList,
      currentDataObject,
    };
  };

  useEffect(() => {
    const cloneDashboardNodeList = _.cloneDeep(dashboardNodeList);
    const clonePreviousDashboardNodeList = _.cloneDeep(
      previousDashboardNodeList,
    );

    /* Merge dashboardNodeList and previousDashboardNodeList. */
    const mergePreviousAndCurrentDashboardNodeList = Object.assign(
      cloneDashboardNodeList,
      clonePreviousDashboardNodeList,
    );

    const dashboardKey = handleTooltipKey(
      mergePreviousAndCurrentDashboardNodeList,
    );

    const {
      previousDataTotal,
      previousDateListLength,
      previousDataList,
      previousDataObject,
    } = handelChartData(dashboardKey);

    const { currentDataTotal, currentDataList, currentDataObject } =
      handleCurrentChartData(dashboardKey);

    /* Get the date difference between current date range first date and previous date range last date. */
    const dateDifference = moment(shortCurrentDateRange[0]).diff(
      moment(shortPreviousDateRange[shortPreviousDateRange.length - 1]),
      "days",
    );

    /* Checking whether the selected date range matches the date range of this month. */
    if (
      shortCurrentDateRange[0] ===
        moment(new Date()).startOf("month").format("YYYY-MM-DD") &&
      shortCurrentDateRange[shortCurrentDateRange.length - 1] ===
        moment(new Date()).endOf("month").format("YYYY-MM-DD")
    ) {
      setDateDifference(dateDifference - 1 + previousDateListLength);
    } else {
      setDateDifference(dateDifference - 1 + shortCurrentDateRange.length);
    }

    // The total sum of the order chart values ​​is displayed as a string.
    if (order) {
      if (isAverage && currentSalesTotal && previousSalesTotal) {
        setCurrentDateListTotal(
          parseFloat(currentSalesTotal["avgAmount"]).toFixed(0),
        );
        setPreviousDateListTotal(
          parseFloat(previousSalesTotal["avgAmount"]).toFixed(0),
        );
      } else {
        setCurrentDateListTotal(
          parseFloat(currentDataTotal.toString()).toFixed(0),
        );
        setPreviousDateListTotal(
          parseFloat(previousDataTotal.toString()).toFixed(0),
        );
      }
    } else {
      //  The total sum of the other chart values ​​is displayed as a decimal format.
      if (isAverage && currentSalesTotal && previousSalesTotal) {
        setPreviousDateListTotal(
          parseFloat(previousSalesTotal["avgAmount"]).toFixed(2),
        );
        setCurrentDateListTotal(
          parseFloat(currentSalesTotal["avgAmount"]).toFixed(2),
        );
      } else {
        setPreviousDateListTotal(
          parseFloat(previousDataTotal.toString()).toFixed(2),
        );
        setCurrentDateListTotal(
          parseFloat(currentDataTotal.toString()).toFixed(2),
        );
      }
    }

    // This works if the sum of the current data and the previous data is greater than 0.
    if (currentDataTotal > 0 && previousDataTotal > 0) {
      const percentage = convertCurrentPreviousValuePercentage(
        currentDataTotal,
        previousDataTotal,
      );
      setCurrentData(parseFloat(percentage.toString()).toFixed());
      setCurrentPercentageInitial(parseFloat(percentage.toString()).toFixed());
    } else {
      // Otherwise, the percentage value is displayed as 0.
      setCurrentData("0");
      setCurrentPercentageInitial("0");
    }

    let dataSet: any = [];
    let dataSetStackedAreaGraphCurrent: any = [];
    let dataSetStackedBarGraph: any = [];
    let dataSetStackedAreaGraphPrevious: any = [];
    let currentDataConvertObject: any = {};
    let previousDataConvertObject: any = {};

    const handleChartBackgroundColorCurrentPeriod = (key: string) => {
      // theme.palette.background[`g_line_${index + 1}`]
      if (key === "total amount") {
        return theme.palette.background[`g_line_1`];
      } else if (key === "orders count") {
        return theme.palette.background[`g_line_1`];
      } else if (key === "average amount") {
        return theme.palette.background[`g_line_1`];
      } else if (key === "cash payments") {
        return theme.palette.background[`g_line_1`];
      } else if (key === "card payments") {
        return theme.palette.background[`g_line_2`];
      } else if (key === "other payments") {
        return theme.palette.background[`g_line_3`];
      } else if (key === "walk in") {
        return theme.palette.background[`g_line_1`];
      } else if (key === "online") {
        return theme.palette.background[`g_line_2`];
      } else if (key === "kiosk") {
        return theme.palette.background[`g_line_3`];
      } else if (key === "phone") {
        return theme.palette.background[`g_line_4`];
      } else if (key === "walk in") {
        return theme.palette.background[`g_line_1`];
      } else if (key === "dine in") {
        return theme.palette.background[`g_line_2`];
      } else if (key === "delivery") {
        return theme.palette.background[`g_line_3`];
      } else if (key === "collection") {
        return theme.palette.background[`g_line_4`];
      } else if (key === "future table") {
        return theme.palette.background[`g_line_5`];
      } else {
        return theme.palette.background[`g_line_1`];
      }
    };

    const handleChartBackgroundColorPreviousPeriod = (key: string) => {
      if (key === "total amount") {
        if (value === "line") {
          return theme.palette.background[`g_line_lighter_6`];
        } else {
          return theme.palette.background[`g_line_lighter_1`];
        }
      } else if (key === "orders count") {
        if (value === "line") {
          return theme.palette.background[`g_line_lighter_6`];
        } else {
          return theme.palette.background[`g_line_lighter_1`];
        }
      } else if (key === "average amount") {
        if (value === "line") {
          return theme.palette.background[`g_line_lighter_6`];
        } else {
          return theme.palette.background[`g_line_lighter_1`];
        }
      } else if (key === "cash payments") {
        if (value === "line") {
          return theme.palette.background[`g_line_lighter_6`];
        } else {
          return theme.palette.background[`g_line_lighter_1`];
        }
      } else if (key === "card payments") {
        if (value === "line") {
          return theme.palette.background[`g_line_2`];
        } else {
          return theme.palette.background[`g_line_lighter_2`];
        }
      } else if (key === "other payments") {
        if (value === "line") {
          return theme.palette.background[`g_line_3`];
        } else {
          return theme.palette.background[`g_line_lighter_3`];
        }
      } else if (key === "walk in") {
        if (value === "line") {
          return theme.palette.background[`g_line_lighter_6`];
        } else {
          return theme.palette.background[`g_line_lighter_1`];
        }
      } else if (key === "online") {
        if (value === "line") {
          return theme.palette.background[`g_line_2`];
        } else {
          return theme.palette.background[`g_line_lighter_2`];
        }
      } else if (key === "kiosk") {
        if (value === "line") {
          return theme.palette.background[`g_line_3`];
        } else {
          return theme.palette.background[`g_line_lighter_3`];
        }
      } else if (key === "phone") {
        if (value === "line") {
          return theme.palette.background[`g_line_4`];
        } else {
          return theme.palette.background[`g_line_lighter_4`];
        }
      } else if (key === "walk in") {
        if (value === "line") {
          return theme.palette.background[`g_line_lighter_6`];
        } else {
          return theme.palette.background[`g_line_lighter_1`];
        }
      } else if (key === "dine in") {
        if (value === "line") {
          return theme.palette.background[`g_line_2`];
        } else {
          return theme.palette.background[`g_line_lighter_2`];
        }
      } else if (key === "delivery") {
        if (value === "line") {
          return theme.palette.background[`g_line_3`];
        } else {
          return theme.palette.background[`g_line_lighter_3`];
        }
      } else if (key === "collection") {
        if (value === "line") {
          return theme.palette.background[`g_line_4`];
        } else {
          return theme.palette.background[`g_line_lighter_4`];
        }
      } else if (key === "future table") {
        if (value === "line") {
          return theme.palette.background[`g_line_5`];
        } else {
          return theme.palette.background[`g_line_lighter_5`];
        }
      } else {
        if (value === "line") {
          return theme.palette.background[`g_line_lighter_6`];
        } else {
          return theme.palette.background[`g_line_lighter_1`];
        }
      }
    };

    const handleChannelBorderColor = (channelName: string) => {
      if (channelName === "eatpresto") {
        return "#CF5B6A";
      }
      if (channelName === "justeat") {
        return "#F08F3A";
      }
      if (channelName === "deliveroo") {
        return "#00b3b3";
      }
      if (channelName === "ubereats") {
        return "#3A9D76";
      }
    };
    const handleChannelBorderColorLighter = (channelName: string) => {
      if (isDarkMode === "true") {
        if (channelName === "eatpresto") {
          return "#9b2e3b";
        }
        if (channelName === "justeat") {
          return "#cc6810";
        }
        if (channelName === "deliveroo") {
          return "#239090";
        }
        if (channelName === "ubereats") {
          return "#316c54";
        }
      } else {
        if (channelName === "eatpresto") {
          return "#f3adb1";
        }
        if (channelName === "justeat") {
          return "#f9c187";
        }
        if (channelName === "deliveroo") {
          return "#82dddd";
        }
        if (channelName === "ubereats") {
          return "#80c8aa";
        }
      }
    };

    /* For ChartJs, processing the data of the current chart and setting the current data in the object format. */
    currentDataList.map((currentDataObject: any, index: number) => {
      //Get the key of the current data object
      const currentDataKey = Object.keys(currentDataObject).toString();
      // Remove last character it.
      const removeLastCharacter = currentDataKey.slice(0, -1);
      // Replace _ with a space.
      const key = replaceCommon(removeLastCharacter, "_", " ");

      if (key !== "wix") {
        Object.values(currentDataObject).map((data: any) => {
          const text = key.split(" ");

          // Capitalize first letter in the more than word.
          let capitalizeFirstLetterInMoreThanWord = "";
          text.map((word: string) => {
            capitalizeFirstLetterInMoreThanWord =
              capitalizeFirstLetterInMoreThanWord +
              " " +
              CapitalizeFirstLetter(word);
          });

          // Set the chartJs current data
          dataSet.push({
            label: "Current Period",
            data: data,
            borderColor: isChannel
              ? handleChannelBorderColor(key)
              : handleChartBackgroundColorCurrentPeriod(key),
            backgroundColor: isChannel
              ? handleChannelBorderColor(key)
              : handleChartBackgroundColorCurrentPeriod(key),
            hoverBackgroundColor: isChannel
              ? handleChannelBorderColor(key)
              : handleChartBackgroundColorCurrentPeriod(key),
            cubicInterpolationMode: "monotone",
            tension: 0.4,
            fill: false,
            borderWidth: 2,
            key: capitalizeFirstLetterInMoreThanWord,
          });

          dataSetStackedAreaGraphCurrent.push({
            label: "Current Period",
            data: data,
            borderColor: isChannel
              ? handleChannelBorderColor(key)
              : handleChartBackgroundColorCurrentPeriod(key),
            backgroundColor: isChannel
              ? handleChannelBorderColor(key)
              : handleChartBackgroundColorCurrentPeriod(key),
            hoverBackgroundColor: isChannel
              ? handleChannelBorderColor(key)
              : handleChartBackgroundColorCurrentPeriod(key),
            cubicInterpolationMode: "monotone",
            tension: 0.4,
            fill: true,
            borderWidth: 1,
            key: capitalizeFirstLetterInMoreThanWord,
          });

          dataSetStackedBarGraph.push({
            label: "Current Period",
            data: data,
            borderColor: isChannel
              ? handleChannelBorderColor(key)
              : handleChartBackgroundColorCurrentPeriod(key),
            backgroundColor: isChannel
              ? handleChannelBorderColor(key)
              : handleChartBackgroundColorCurrentPeriod(key),
            hoverBackgroundColor: isChannel
              ? handleChannelBorderColor(key)
              : handleChartBackgroundColorCurrentPeriod(key),
            cubicInterpolationMode: "monotone",
            tension: 0.4,
            fill: true,
            borderWidth: 1,
            key: capitalizeFirstLetterInMoreThanWord,
            stack: "Stack 1",
          });

          currentDataConvertObject = handleConvertObjectFormat(
            currentDataConvertObject,
            data,
          );
        });
      }
    });

    /* For ChartJs, processing the data of the previous chart and setting the previous data in the object format. */
    previousDataList.map((previousDataObject: any, index: number) => {
      //Get the key of the previous data object
      const currentDataKey = Object.keys(previousDataObject).toString();
      // Remove last character it.
      const removeLastCharacter = currentDataKey.slice(0, -1);
      // Replace _ with a space.
      const key = replaceCommon(removeLastCharacter, "_", " ");

      if (key !== "wix") {
        Object.values(previousDataObject).map((data: any) => {
          const text = key.split(" ");
          // Capitalize first letter in the more than word.
          let capitalizeFirstLetterInMoreThanWord = "";
          text.map((word: string) => {
            capitalizeFirstLetterInMoreThanWord =
              capitalizeFirstLetterInMoreThanWord +
              " " +
              CapitalizeFirstLetter(word);
          });

          // Set the chartJs previous data
          dataSet.push({
            label: "Previous Period",
            data: data,
            borderColor: isChannel
              ? handleChannelBorderColorLighter(key)
              : handleChartBackgroundColorPreviousPeriod(key),
            backgroundColor: isChannel
              ? handleChannelBorderColorLighter(key)
              : handleChartBackgroundColorPreviousPeriod(key),
            hoverBackgroundColor: isChannel
              ? handleChannelBorderColorLighter(key)
              : handleChartBackgroundColorPreviousPeriod(key),
            cubicInterpolationMode: "monotone",
            tension: 0.4,
            fill: false,
            borderDash: [5, 5],
            borderWidth: 0.8,
            key: capitalizeFirstLetterInMoreThanWord,
          });

          dataSetStackedAreaGraphPrevious.push({
            label: "Previous Period",
            data: data,
            borderColor: isChannel
              ? handleChannelBorderColorLighter(key)
              : handleChartBackgroundColorPreviousPeriod(key),
            backgroundColor: isChannel
              ? handleChannelBorderColorLighter(key)
              : handleChartBackgroundColorPreviousPeriod(key),
            hoverBackgroundColor: isChannel
              ? handleChannelBorderColorLighter(key)
              : handleChartBackgroundColorPreviousPeriod(key),
            cubicInterpolationMode: "monotone",
            tension: 0.4,
            fill: true,
            borderWidth: 1,
            key: capitalizeFirstLetterInMoreThanWord,
          });

          dataSetStackedBarGraph.push({
            label: "Previous Period",
            data: data,
            borderColor: isChannel
              ? handleChannelBorderColorLighter(key)
              : handleChartBackgroundColorPreviousPeriod(key),
            backgroundColor: isChannel
              ? handleChannelBorderColorLighter(key)
              : handleChartBackgroundColorPreviousPeriod(key),
            hoverBackgroundColor: isChannel
              ? handleChannelBorderColorLighter(key)
              : handleChartBackgroundColorPreviousPeriod(key),
            // : theme.palette.background.entity_background,
            cubicInterpolationMode: "monotone",
            tension: 0.4,
            fill: true,
            borderWidth: 1,
            key: capitalizeFirstLetterInMoreThanWord,
            stack: "Stack 2",
          });

          previousDataConvertObject = handleConvertObjectFormat(
            previousDataConvertObject,
            data,
          );
        });
      }
    });

    /* Merge current object and previous object. */
    const merge = {
      ...currentDataObject,
      ...previousDataObject,
    };

    let percentageList: any = [];

    /* Calculate the percentage of the current and previous data */
    dashboardKey.map((data: any, index: any) => {
      let current = merge[`${data}C`];
      let previous = merge[`${data}P`];

      // Return the percentage of previous and current data.
      percentageList = current.map((num: any, idx: any) => {
        if (previous[idx] === undefined || previous[idx] === 0) {
          return 0;
        } else {
          return convertCurrentPreviousValuePercentage(num, previous[idx]);
        }
      });
    });

    // Convert percentage list to object.
    let allPercentageObject: any = {};
    percentageList.map((data: any, index: number) => {
      allPercentageObject[index] = parseFloat(data).toFixed(2);
    });

    setAllPercentageObject(allPercentageObject);
    setCurrentDataConvertObject(currentDataConvertObject);
    setPreviousDataConvertObject(previousDataConvertObject);
    setDataSetLength(dataSet.length / 2);

    /* Change the time format if only one date is selected, and
       Set the chart data in the state  */
    if (currentStartDate === currentEndDate) {
      let timeFormat: any = [];
      let timeFormatStackedAreaChart: any = [];
      shortCurrentDateRange.map((data: any, index: number) => {
        if (!_.isEmpty(dataSet) && index === 0) {
          let dateObj = new Date();

          dateObj.setUTCHours(0);
          if (
            moment(currentStartDate).format("D MMM") ===
              moment(currentEndDate).format("D MMM") &&
            moment(previousStartDate).format("D MMM") ===
              moment(previousEndDate).format("D MMM")
          ) {
            if (
              dataSet[0].data.length > 24 &&
              moment(dateObj).format("YYYY-MM-DD") !==
                moment(new Date()).format("YYYY-MM-DD")
            ) {
              let hour = dateObj.getHours();
              for (let i = hour; i < 24; i++) {
                if (i === 0) {
                  timeFormat.push(12 + " PM");
                  timeFormatStackedAreaChart.push(12 + " PM");
                  timeFormatStackedAreaChart.push(12 + " PM");
                } else if (i < 12) {
                  timeFormat.push(i + " AM");
                  timeFormatStackedAreaChart.push(i + " AM");
                  timeFormatStackedAreaChart.push(i + " AM");
                } else {
                  timeFormat.push(i + " PM");
                  timeFormatStackedAreaChart.push(i + " PM");
                  timeFormatStackedAreaChart.push(i + " PM");
                }
              }
            }
          }
        }
        if (data === "0") {
          moment("12:00 PM", "h:mm A");
          timeFormat.push(12 + " AM");
          timeFormatStackedAreaChart.push(12 + " AM");
          timeFormatStackedAreaChart.push(12 + " AM");
        } else if (parseFloat(data) < 11) {
          timeFormat.push(data + " AM");
          timeFormatStackedAreaChart.push(data + " AM");
          timeFormatStackedAreaChart.push(data + " AM");
        } else {
          timeFormat.push(data + " PM");
          timeFormatStackedAreaChart.push(data + " PM");
          timeFormatStackedAreaChart.push(data + " PM");
        }
      });
      let dateObj = new Date();
      // Set the hour to 22
      dateObj.setUTCHours(0);
      if (!_.isEmpty(dataSet)) {
        let dateObj = new Date();

        dateObj.setUTCHours(0);
        if (
          moment(currentStartDate).format("D MMM") ===
            moment(currentEndDate).format("D MMM") &&
          moment(previousStartDate).format("D MMM") ===
            moment(previousEndDate).format("D MMM")
        ) {
          if (
            (dataSet[0].data.length > 24 || dataSet[1].data.length > 24) &&
            moment(dateObj).format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD")
          ) {
            for (let i = 0; i < dataSet[0].data.length - 24; i++) {
              if (i === 0) {
                timeFormat.push(12 + " AM");
                timeFormatStackedAreaChart.push(12 + " AM");
                timeFormatStackedAreaChart.push(12 + " AM");
              } else if (i < 12) {
                timeFormat.push(i + " AM");
                timeFormatStackedAreaChart.push(i + " AM");
                timeFormatStackedAreaChart.push(i + " AM");
              } else {
                timeFormat.push(i + " PM");
                timeFormatStackedAreaChart.push(i + " PM");
                timeFormatStackedAreaChart.push(i + " PM");
              }
            }
          }
        }
      }
      setData({
        labels: timeFormat,
        datasets: dataSet,
      });
      setDataStackedAreaGraphCurrent({
        labels: timeFormat,
        datasets: dataSetStackedAreaGraphCurrent,
      });
      setDataStackedBarGraph({
        labels: timeFormat,
        datasets: dataSetStackedBarGraph,
      });
      setDataStackedAreaGraphPrevious({
        labels: timeFormat,
        datasets: dataSetStackedAreaGraphPrevious,
      });
    } else {
      setData({
        labels: shortCurrentDateRange,
        datasets: dataSet,
      });
      setDataStackedAreaGraphCurrent({
        labels: shortCurrentDateRange,
        datasets: dataSetStackedAreaGraphCurrent,
      });
      setDataStackedBarGraph({
        labels: shortCurrentDateRange,
        datasets: dataSetStackedBarGraph,
      });
      setDataStackedAreaGraphPrevious({
        labels: shortCurrentDateRange,
        datasets: dataSetStackedAreaGraphPrevious,
      });
    }
  }, [
    previousDashboardNodeList,
    dashboardNodeList,
    shortCurrentDateRange,
    previousSalesTotal,
    currentSalesTotal,
    getLocalStore("isDarkMode"),
    value,
  ]);

  /* Set the percentage and current data after hovering over the chart. */
  useEffect(() => {
    /* It's work after hovering. */
    if (currentDataConvertObject[dataIndex] !== undefined) {
      if (order) {
        setCurrentPeriodHoverValue(
          parseFloat(currentDataConvertObject[dataIndex]).toFixed(0),
        );
        setPreviousPeriodHoverValue(
          parseFloat(previousDataConvertObject[dataIndex]).toFixed(0),
        );
      } else {
        setCurrentPeriodHoverValue(
          parseFloat(currentDataConvertObject[dataIndex]).toFixed(2),
        );
        setPreviousPeriodHoverValue(
          parseFloat(previousDataConvertObject[dataIndex]).toFixed(2),
        );
      }

      /* Set the hover current data */
      if (previousDataConvertObject[dataIndex] > 0) {
        setCurrentData(parseFloat(allPercentageObject[dataIndex]).toFixed(2));
      } else {
        setCurrentData("0");
      }
    } else {
      // To show initial data when not hovering.
      setCurrentData(currentDataInitial);
    }
  }, [dataIndex, currentDataInitial]);

  /* Returning the chip by percentage value. */
  const handleChip = (currentData: any) => {
    if (parseFloat(currentData) > 0) {
      return (
        <Chip
          style={{
            backgroundColor: theme.palette.custom.green.main,
            color: "white",
          }}
          className={classes.chipStyle}
          label={`+${currentData}%`}
        />
      );
    } else if (parseFloat(currentData) === 0) {
      return "";
    } else {
      return (
        <Chip
          style={{
            backgroundColor: theme.palette.custom.red.main,
            color: "white",
          }}
          className={classes.chipStyle}
          label={`${currentData}%`}
        />
      );
    }
  };

  /* Change the chart title using the current start date, current end date
     previous start date, and previous end date */
  const currentAndPreviousPeriodTitle = () => {
    return (
      <Typography className={classes.titleStyle}>
        {`Current Period ( 
        ${moment(currentStartDate).format("D MMM")} 
         - 
        ${moment(currentEndDate).format("D MMM")} 
        ) - Previous Period (
        ${moment(previousStartDate).format("D MMM")} 
        - 
        ${moment(previousEndDate).format("D MMM")} 
        )`}
      </Typography>
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setCheckedCurrentPreviousToggle(true);
  };

  useEffect(() => {
    if (getLocalStore("chartType")) {
      setValue(getLocalStore("chartType"));
    } else {
      setLocalStore("chartType", "line");
      setValue("line");
    }
  }, []);

  const handleChangeCurrentPeriodToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    setCheckedCurrentPreviousToggle(checked);
  };

  const handleChangec = (event: any) => {
    setValue(event.target.value);
    setLocalStore("chartType", event.target.value);
  };
  const classes = useStyles();
  const screenWidth = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <FormControl component="fieldset" style={{ width: "100%" }}>
            {/* <FormLabel component="legend">Gender</FormLabel> */}
            <RadioGroup
              aria-label="gender"
              name="chartGroup"
              value={value}
              onChange={handleChangec}
            >
              <Grid container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                  <Tooltip title="Line Chart">
                    <FormControlLabel
                      value="line"
                      control={
                        <Radio
                          color="secondary"
                          className={classes.radioButtonStyle}
                          size="small"
                          style={{
                            height: "1px",
                            width: "1px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "2px",
                          }}
                        />
                      }
                      label={
                        <img
                          src={line}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          height="24px"
                          width="24px"
                        />
                      }
                      labelPlacement="start"
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    />
                  </Tooltip>

                  <Tooltip title="Stacked Bar Chart">
                    <FormControlLabel
                      value="stackedBar"
                      control={
                        <Radio
                          color="secondary"
                          size="small"
                          className={classes.radioButtonStyle}
                          style={{
                            height: "1px",
                            width: "1px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "3px",
                          }}
                        />
                      }
                      label={
                        <img
                          src={bar}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "16px",
                          }}
                          height="24px"
                          width="24px"
                        />
                      }
                      labelPlacement="start"
                    />
                  </Tooltip>
                  <Tooltip title="Area Chart">
                    <FormControlLabel
                      value="area"
                      control={
                        <Radio
                          color="secondary"
                          size="small"
                          className={classes.radioButtonStyle}
                          style={{
                            height: "1px",
                            width: "1px",
                            marginLeft: "1px",
                          }}
                        />
                      }
                      label={
                        <img
                          src={area}
                          style={{
                            marginLeft: "16px",
                            marginBottom: "4px",
                          }}
                          height="34px"
                          width="34px"
                        />
                      }
                      labelPlacement="start"
                    />
                  </Tooltip>
                  <Tooltip title="Stacked Area Chart">
                    <FormControlLabel
                      value="stackedArea"
                      control={
                        <Radio
                          color="secondary"
                          size="small"
                          className={classes.radioButtonStyle}
                          style={{
                            height: "1px",
                            width: "1px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "6px",
                            fontSize: "14px",
                          }}
                        />
                      }
                      label={
                        <img
                          src={stacked_area}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "16px",
                          }}
                          height="24px"
                          width="24px"
                        />
                      }
                      labelPlacement="start"
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {(value === "stackedArea" || value === "area") && (
                    <div
                      style={
                        !screenWidth
                          ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                              paddingRight: "16px",
                            }
                          : {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              paddingLeft: "16px",
                            }
                      }
                    >
                      <Typography variant="caption">Previous Period</Typography>
                      <Switch
                        checked={checkedCurrentPreviousToggle}
                        onChange={handleChangeCurrentPeriodToggle}
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                      />
                      <Typography variant="caption">Current Period</Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={7} sm={6} md={3}>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "16px",
            }}
          >
            <Typography>Stacked Area Chart</Typography>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography>Line chart</Typography>
          </div> */}
          <Typography
            className={classes.currentPeriodStyle}
            style={{ marginTop: "4px" }}
          >
            <RemoveIcon className={classes.iconStyle} /> Current Period
            {handleChip(currentData)}
          </Typography>
          <Typography
            className={classes.totalCurrentPeriodStyle}
            style={{ marginTop: "4px" }}
          >
            {!_.isEmpty(dataIndex.toString())
              ? currentPeriodHoverValue
              : currentDataTotal}
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Hidden only={["sm", "xs"]}>{currentAndPreviousPeriodTitle()}</Hidden>
        </Grid>
        <Grid item xs={5} sm={6} md={3}>
          <Typography
            className={classes.previousPeriodStyle}
            style={{ marginTop: "4px" }}
          >
            <MoreHorizIcon className={classes.iconStyle} />{" "}
            <Typography style={{ marginTop: "2px" }}>
              Previous Period{" "}
            </Typography>
          </Typography>
          <Typography
            className={classes.totalPreviousPeriodStyle}
            style={{ marginTop: "4px" }}
          >
            {!_.isEmpty(dataIndex.toString())
              ? previousPeriodHoverValue
              : previousDataTotal}
          </Typography>
        </Grid>
      </Grid>
      {value === "line" && (
        <Chart
          data={data}
          dateDifference={dateDifference}
          dataSetLength={dataSetLength}
          setDataIndex={setDataIndex}
          isEmpty={isEmpty}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          order={order}
          isChannel={isChannel}
          value="line"
        />
      )}
      {value === "area" && (
        <AreaChart
          data={
            checkedCurrentPreviousToggle
              ? dataSetStackedAreaGraphCurrent
              : dataSetStackedAreaGraphPrevious
          }
          dateDifference={dateDifference}
          dataSetLength={dataSetLength}
          setDataIndex={setDataIndex}
          isEmpty={isEmpty}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          order={order}
        />
      )}
      {value === "stackedArea" && (
        <StackedAreaChart
          data={
            checkedCurrentPreviousToggle
              ? dataSetStackedAreaGraphCurrent
              : dataSetStackedAreaGraphPrevious
          }
          dateDifference={dateDifference}
          dataSetLength={dataSetLength}
          setDataIndex={setDataIndex}
          isEmpty={isEmpty}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          order={order}
          checkedCurrentPreviousToggle={checkedCurrentPreviousToggle}
        />
      )}
      {value === "stackedBar" && (
        <StackedBarChart
          data={dataSetStackedBarGraph}
          dateDifference={dateDifference}
          dataSetLength={dataSetLength}
          setDataIndex={setDataIndex}
          isEmpty={isEmpty}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          order={order}
        />
      )}
      {/* {checked ? (
        <Chart
          data={data}
          dateDifference={dateDifference}
          dataSetLength={dataSetLength}
          setDataIndex={setDataIndex}
          isEmpty={isEmpty}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          order={order}
        />
      ) : (
        <StackedAreaChart
          data={
            checkedCurrentPreviousToggle
              ? dataSetStackedAreaGraphCurrent
              : dataSetStackedAreaGraphPrevious
          }
          dateDifference={dateDifference}
          dataSetLength={dataSetLength}
          setDataIndex={setDataIndex}
          isEmpty={isEmpty}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          order={order}
        />
        <StackedBarChart
          data={
            checkedCurrentPreviousToggle
              ? dataSetStackedAreaGraphCurrent
              : dataSetStackedAreaGraphPrevious
          }
          dateDifference={dateDifference}
          dataSetLength={dataSetLength}
          setDataIndex={setDataIndex}
          isEmpty={isEmpty}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          order={order}
        />
      )} */}
    </>
  );
};

export default DashboardChart;
