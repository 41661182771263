import { axiosClient } from "../client";

const queryString = require("query-string");

const STOCK_LEVEL_INFO_GET_SINGLE = (locationId, stockLevelId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockLevel/id/${stockLevelId}`;

const STOCK_LEVEL_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockLevel`;

const STOCK_LEVEL_CREATE_OR_UPDATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockLevel/createOrUpdate`;

export const fetchSingleStockLevelInfo = (locationId, stockLevelId) =>
  axiosClient({
    method: "GET",
    url: STOCK_LEVEL_INFO_GET_SINGLE(locationId, stockLevelId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchStockLevelInfoGetAll = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: STOCK_LEVEL_INFO_GET_ALL(locationId),
        query: {
          size: 100000,
          page: 1,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const stockLevelCreateOrUpdate = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: STOCK_LEVEL_CREATE_OR_UPDATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
