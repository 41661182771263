import { createStyles, Grid, makeStyles } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import CardCommon from "../../../../components/card/CardCommon";
import {
  DELIVERY_TYPE_DELIVER,
  DELIVERY_TYPE_DINEIN,
  DELIVERY_TYPE_PICKUP,
} from "../../../../utils/consts";
import CreateNewNode from "./CreateNewNode";
import DeliveryInfoNode from "./DeliveryInfoNode";
import WithLoading from "../../../../utils/WithLoading";

function a11yProps(index: any) {
  return {
    id: `delivery-type-tab-${index}`,
    "aria-controls": `delivery-type-tab-${index}`,
  };
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    deliveryTypeSection: {
      borderRadius: 8,
    },
    tabPanel: {
      backgroundColor: theme.palette.background.default,
    },
  }),
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`delivery-type-tab-${index}`}
      aria-labelledby={`delivery-type-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ padding: "16px 0" }}>{children}</div>}
    </div>
  );
};

const DEFAULT_AREA_CONFIG = {
  title: "",
  data: {
    distance: "",
  },
};

export interface DeliveryTypesProps {
  nodes: Array<any>;
  getDeliveryInfo: any;
  setIsLoadingButton: any;
  isLoadingButton: any;
  isLoading: any;
}

/* This page has 3 tab panels. They are pickup, delivery, dine-in. 
Information related to those panels is designed to be visible to the user using cards */
const DeliveryTypes: React.FunctionComponent<DeliveryTypesProps> = ({
  nodes,
  getDeliveryInfo,
  setIsLoadingButton,
  isLoadingButton,
  isLoading,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [deliveryNodes, setDeliveryNodes] = useState<Array<any>>([]);
  const [pickupNodes, setPickupNodes] = useState<Array<any>>([]);
  const [dineInNodes, setDineInNodes] = useState<Array<any>>([]);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  /* Data allocation for 3 tabs. (delivery, pick up, dine in) */
  useEffect(() => {
    /* Objects with similar types, combine all those similar types into a single object list. */
    const groupedNodes: any = _.groupBy(nodes, (node) => node.type);
    setDeliveryNodes(groupedNodes[DELIVERY_TYPE_DELIVER] || []);
    setPickupNodes(groupedNodes[DELIVERY_TYPE_PICKUP] || []);
    setDineInNodes(groupedNodes[DELIVERY_TYPE_DINEIN] || []);
  }, [nodes]);

  /* Switch the tab to be displayed. */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  /* When creating new delivery information, 
  the basic information required for that is entered into the state. */
  const createNode = (nodeData: any, type: string) => {
    switch (type) {
      case DELIVERY_TYPE_DELIVER:
        setDeliveryNodes([...deliveryNodes, { ...nodeData }]);
        break;

      case DELIVERY_TYPE_DINEIN:
        setDineInNodes([...dineInNodes, { ...nodeData }]);
        break;

      case DELIVERY_TYPE_PICKUP:
        setPickupNodes([...pickupNodes, { ...nodeData }]);
        break;
      default:
        break;
    }
  };

  /* Used to obtain a copy of one delivery information. */
  const handleCopyNode = (nodeData: any) => {
    const nodeDataCopy = {
      newId: uuidv4(),
      status: "create",
      availability: nodeData.availability,
      area: {
        ...nodeData.area,
        title: `Copy of ${nodeData.area.title}`,
        type: nodeData.area.type,
      },
      charge: nodeData.charge,
      delayMins: nodeData.delayMins,
      isActive: nodeData.isActive,
      minOrderPrice: nodeData.minOrderPrice,
      paymentTypes: nodeData.paymentTypes,
      type: nodeData.type,
    };
    createNode(nodeDataCopy, nodeData.type);
  };

  /* Used to create a new delivery create */
  const handleCreateNode = (type: string) => {
    // Delivery initial information
    const nodeData = {
      newId: uuidv4(),
      status: "create",
      availability: [],
      area:
        type === "delivery"
          ? { ...DEFAULT_AREA_CONFIG, type: "distance" }
          : DEFAULT_AREA_CONFIG,
      charge: 0,
      delayMins: 0,
      isActive: true,
      minOrderPrice: 0,
      paymentTypes: ["cash"],
      type,
    };
    createNode(nodeData, type);
  };

  /* Remove delivery information before updating the backend. The status is used for that. 
  If the status is "create", it will be removed from the state. */
  const handleRemoveNode = (type: string, uuid: string) => {
    switch (type) {
      case DELIVERY_TYPE_DELIVER:
        setDeliveryNodes(
          deliveryNodes.filter((node: any) => node.newId !== uuid),
        );
        break;

      case DELIVERY_TYPE_DINEIN:
        setDineInNodes(dineInNodes.filter((node: any) => node.newId !== uuid));
        break;

      case DELIVERY_TYPE_PICKUP:
        setPickupNodes(pickupNodes.filter((node: any) => node.newId !== uuid));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <CardCommon>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          aria-label="delivery-types-tabs"
          variant="fullWidth"
        >
          <Tab label="PICKUP" {...a11yProps(0)} />
          <Tab label="DELIVERY" {...a11yProps(1)} />
          <Tab label="DINE IN" {...a11yProps(2)} />
        </Tabs>
      </CardCommon>
      <div className={classes.root}>
        <TabPanel value={value} index={0}>
          <div className={classes.deliveryTypeSection}>
            <Typography variant="h4" component="div"></Typography>
            <Grid
              container
              spacing={2}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {!_.isEmpty(pickupNodes) &&
                pickupNodes.map((nodeData: any) => (
                  <DeliveryInfoNode
                    nodeData={nodeData}
                    getDeliveryInfo={getDeliveryInfo}
                    handleRemoveNode={handleRemoveNode}
                    type={DELIVERY_TYPE_PICKUP}
                    handleCopyNode={handleCopyNode}
                    isLoadingButton={isLoadingButton}
                    setIsLoadingButton={setIsLoadingButton}
                    isRemovedCopyButton={false}
                  />
                ))}
              <CreateNewNode
                handleCreateNode={handleCreateNode}
                type={DELIVERY_TYPE_PICKUP}
              />
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={classes.deliveryTypeSection}>
            <Typography
              variant="h4"
              component="div"
              align="left"
              style={{ margin: 4 }}
            ></Typography>
            <Grid
              container
              spacing={2}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {!_.isEmpty(deliveryNodes) &&
                deliveryNodes.map((nodeData: any) => (
                  <DeliveryInfoNode
                    nodeData={nodeData}
                    getDeliveryInfo={getDeliveryInfo}
                    handleRemoveNode={handleRemoveNode}
                    type={DELIVERY_TYPE_DELIVER}
                    handleCopyNode={handleCopyNode}
                    openDeleteConfirm={openDeleteConfirm}
                    setOpenDeleteConfirm={setOpenDeleteConfirm}
                    setIsLoadingButton={setIsLoadingButton}
                    isLoadingButton={isLoadingButton}
                    isRemovedCopyButton={false}
                  />
                ))}
              <CreateNewNode
                handleCreateNode={handleCreateNode}
                type={DELIVERY_TYPE_DELIVER}
              />
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className={classes.deliveryTypeSection}>
            <Typography variant="h4" component="div"></Typography>
            <Grid
              container
              spacing={2}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {!_.isEmpty(dineInNodes) &&
                dineInNodes.map((nodeData: any) => (
                  <DeliveryInfoNode
                    nodeData={nodeData}
                    getDeliveryInfo={getDeliveryInfo}
                    handleRemoveNode={handleRemoveNode}
                    type={DELIVERY_TYPE_DINEIN}
                    handleCopyNode={handleCopyNode}
                    openDeleteConfirm={openDeleteConfirm}
                    setOpenDeleteConfirm={setOpenDeleteConfirm}
                    setIsLoadingButton={setIsLoadingButton}
                    isLoadingButton={isLoadingButton}
                  />
                ))}
              <CreateNewNode
                handleCreateNode={handleCreateNode}
                type={DELIVERY_TYPE_DINEIN}
                disabled={dineInNodes.length >= 1}
              />
            </Grid>
          </div>
        </TabPanel>
      </div>
    </>
  );
};

export default WithLoading(DeliveryTypes);
