import React from "react";
import {
  CircularProgress,
} from "@material-ui/core";

import _ from "lodash";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import MappingModal from "./MappingModal";
import StackModal from "./StackModal";

export interface MappingModalProps {
  isOpen: any;
  setIsOpen: any;
  isLoading: any;
  isOpenStackProductModal: any;
  setIsOpenStackProductModal: any;
  stackNode: any;
  handleOpenMappingModal: any;
  categoryName: any;
  setIsLoading: any;
  isOpenMappingModal: any;
  setIsOpenMappingModal: any;
  setError: any;
  printerMappingItemName: any;
  selectedPrinterMappingItemOrCategoryId: any;
  type: any;
  selectedCatMenuTitle: any;isStack: any
}


/**
 * EmptyModal Component
 *
 * This component renders a modal for mapping printers. It displays tabs for different discount types
 * and allows users to map printers accordingly.
 */
const EmptyModal: React.FunctionComponent<MappingModalProps> = ({
  isOpen,
  setIsOpen,
  isLoading,
  isOpenStackProductModal,
  setIsOpenStackProductModal,
  stackNode,
  handleOpenMappingModal,
  categoryName,
  setIsLoading,
  isOpenMappingModal,
  setIsOpenMappingModal,
  setError,
  printerMappingItemName,
  selectedPrinterMappingItemOrCategoryId,
  type,
  selectedCatMenuTitle,isStack
}) => {
  return (
    <>
      {
        <DialogCommonDefault
          open={isOpen}
          setOpen={setIsOpen}
          isNeedFixedHeight={true}
          maxWidth={"xl"}
        >
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "10%",
                }}
              >
                <CircularProgress size={50} color="secondary" />
              </div>
            ) : 
            (isOpenStackProductModal )? (
              <StackModal
                isOpen={isOpenStackProductModal}
                setIsOpen={setIsOpenStackProductModal}
                stackNode={stackNode}
                handleOpenMappingModal={handleOpenMappingModal}
                category={{ name: categoryName }}
                setIsOpenModal={setIsOpen}
              />
            ) : (
              <MappingModal
                isOpen={isOpenMappingModal}
                setIsOpen={setIsOpenMappingModal}
                setError={setError}
                printerMappingItemName={printerMappingItemName}
                selectedPrinterMappingItemOrCategoryId={
                  selectedPrinterMappingItemOrCategoryId
                }
                type={type}
                selectedCatMenuTitle={selectedCatMenuTitle}
                categoryName={categoryName}
                setIsOpenModal={setIsOpen}
                isStack={isStack}
                setIsOpenStackProductModal={setIsOpenStackProductModal}
              />
            )}
        </DialogCommonDefault>
      }
    </>
  );
};

export default EmptyModal;
