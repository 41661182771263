import * as React from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import RestoreIcon from "@material-ui/icons/Restore";
import CardCommon from "../../../../../components/card/CardCommon";
import { buttonColors } from "../../../../../utils/enum";
import { CustomTheme } from "../../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    SectionBoxWrapper: {
      padding: 12,
      borderRadius: 8,
      border: `1px solid ${theme.palette.background.entity_border}`,
      height: "100%",
    },
    title: {
      marginBottom: 32,
      fontWeight: "bold",
      marginLeft: 8,
    },
    colorChangeSection: {
      [theme.breakpoints.up("sm")]: {
        minHeight: 320,
      },
    },
  }),
);

export interface SectionBoxWrapperProps {
  sectionTitle: string;
  colorChangeSection: React.ReactChild;
  previewSection: React.ReactChild;
  onSubmit?: any;
  onCancel?: any;
}

/* Used to show the categories, menu, basket, and checkout sections. */
const SectionBoxWrapper: React.FunctionComponent<SectionBoxWrapperProps> = ({
  sectionTitle,
  colorChangeSection,
  previewSection,
  onSubmit,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <CardCommon backgroundColor={"entity_background"}>
      <div className={classes.SectionBoxWrapper}>
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography align="left" variant="h4" className={classes.title}>
              {sectionTitle}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className={classes.colorChangeSection}>
                {colorChangeSection}
              </div>
              <div style={{ flex: 1 }}></div>
              <div style={{ display: "flex" }}>
                <ButtonCommon
                  color={buttonColors.UPDATE_BUTTON_COLOR}
                  onClick={onSubmit}
                >
                  Save
                </ButtonCommon>
                <div style={{ margin: 2 }}></div>
                <ButtonCommon
                  variant="contained"
                  style={{
                    fontSize: 11,
                    flex: 1,
                    marginLeft: 4,
                  }}
                  color={buttonColors.CANCEL_BUTTON_COLOR}
                  startIcon={<RestoreIcon />}
                  onClick={onCancel}
                >
                  Cancel
                </ButtonCommon>
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            {previewSection}
          </Grid>
        </Grid>
      </div>
    </CardCommon>
  );
};

export default SectionBoxWrapper;
