import * as React from "react";
import {
  createStyles,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import ColorPickerDialog from "../../../components/dialogs/ColorPickerDialog";
import ColorPickerPopup from "../../../components/popovers/ColorPickerPopup";
import { useMediaQuery } from "@material-ui/core";
import { CustomTheme } from "../../../../../types/customTheme";

interface Props {
  colorCode: string;
}

const useStyles = makeStyles<any, Props>((theme: CustomTheme) =>
  createStyles({
    root: {
      display: "flex",
      borderRadius: 4,
      margin: 2,
      padding: 4,
    },
    colorCircle: {
      borderRadius: "50%",
      width: 40,
      height: 40,
      backgroundColor: ({ colorCode }) => colorCode,
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
  }),
);

export interface ColorSelectionProps {
  title: string;
  colorCode: string;
  setColor: any;
  setInitialData: any;
}

/* Color select page */
const ColorSelection: React.FunctionComponent<ColorSelectionProps> = ({
  title,
  colorCode,
  setColor,
  setInitialData,
}) => {
  const [openPicker, setOpenPicker] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  /* Open color selection pop up */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close color selection pop up */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles({ colorCode });

  const open = Boolean(anchorEl);

  return (
    <>
      {matches ? (
        <ColorPickerDialog
          open={openPicker}
          setOpen={setOpenPicker}
          color={colorCode}
          setColor={setColor}
          setInitialData={setInitialData}
        />
      ) : (
        <ColorPickerPopup
          open={open}
          color={colorCode}
          setColor={setColor}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
      )}

      <IconButton
        className={classes.root}
        onClick={matches ? () => setOpenPicker(true) : handleClick}
      >
        <div style={{ flex: 1 }}>
          <div className={classes.colorCircle}></div>
        </div>
        <div style={{ flex: 5, marginLeft: 16 }}>
          <Typography align="left" variant="subtitle1">
            {title}
          </Typography>
          <Typography align="left" variant={"subtitle2"}>
            {colorCode}
          </Typography>
        </div>
      </IconButton>
    </>
  );
};

export default ColorSelection;
