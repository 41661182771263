import { Grid, Typography } from "@material-ui/core";
import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import ValidationMessage from "../../../components/validation/ValidationMessage";
import TextfieldCommon from "../../textField/TextfieldCommon";

export interface HomeProps {
  handleCloseModal: any;
  locationData: any;
  isValidated: any;
  handleChangeLocationData: any;
}

/* This component displays text fields used for Business Display Name, Business Registration Number, 
   Business Trading Name, Location Identifier, Description, and Terms. */
const BusinessInformation: React.FunctionComponent<HomeProps> = ({
  handleCloseModal,
  locationData,
  isValidated,
  handleChangeLocationData,
}) => {
  return (
    <Grid container style={{ padding: "12px" }}>
      <Grid
        item
        xs={12}
        style={{
          marginBottom: "8px",
          marginLeft: "4px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Business Information</Typography>
        <CancelIcon
          style={{ marginRight: "4px", cursor: "pointer" }}
          onClick={handleCloseModal}
        />
      </Grid>
      <Grid item xs={12}>
        <TextfieldCommon
          id="businessDisplayName"
          label="Business Display Name"
          type="text"
          name="businessDisplayName"
          autoComplete="off"
          error={!locationData.businessDisplayName && isValidated}
          value={locationData.businessDisplayName}
          onChange={handleChangeLocationData}
        />
        <div style={{ marginLeft: "2px" }}>
          <ValidationMessage
            message={
              !locationData.businessDisplayName &&
              isValidated &&
              "Business display name  is required."
            }
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextfieldCommon
          id="businessRegName"
          label="Business Registration Name"
          type="text"
          name="businessRegName"
          autoComplete="off"
          value={locationData.businessRegName}
          onChange={handleChangeLocationData}
        />
      </Grid>
      <Grid item xs={12}>
        <TextfieldCommon
          id="businessTradingName"
          name="businessTradingName"
          label="Business Trading Name"
          type="text"
          autoComplete="off"
          value={locationData.businessTradingName}
          onChange={handleChangeLocationData}
        />
      </Grid>
      <Grid item xs={12}>
        <TextfieldCommon
          id="locationIdentifier"
          name="locationIdentifier"
          label="Location Identifier"
          type="text"
          autoComplete="off"
          value={locationData.locationIdentifier}
          onChange={handleChangeLocationData}
        />
      </Grid>

      <Grid item xs={12}>
        <TextfieldCommon
          id="description"
          name="description"
          label="Description"
          type="text"
          autoComplete="off"
          multiline={true}
          rows={3}
          value={locationData.description}
          onChange={handleChangeLocationData}
        />
      </Grid>
      <Grid item xs={12}>
        <TextfieldCommon
          id="terms"
          name="terms"
          label="Terms"
          type="text"
          autoComplete="off"
          multiline={true}
          rows={3}
          value={locationData.terms}
          onChange={handleChangeLocationData}
        />
      </Grid>
    </Grid>
  );
};

export default BusinessInformation;
