import React, { useState } from "react";
import {
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import CardCommon from "../../../../components/card/CardCommon";
import { Autocomplete } from "@material-ui/lab";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import { useRouteMatch } from "react-router-dom";

export interface StockConfigurationNodeProps {
  locationSelectorList: any;
  setSelectedDepartmentObj: any;
  selectedDepartmentObj: any;
  stockConfiguration: any;
  handleGetMasterLocationSpecificLocation: any;
  selectedDepartmentObjInitial: any;
  setIsEnableMasterLocation: any;
  isEnableMasterLocation: any;
  printerMappingsVersion: any;
  productsVersion: any;
  printersVersion: any;
  setPrinterMappingsVersion: any;
  setProductsVersion: any;
  setPrintersVersion: any;
  printerMappingsVersionInitial: any;
  productsVersionInitial: any;
  printersVersionInitial: any;
  isLoadingButton: any
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
  }),
);

/**
 * Component: ProductConfigurationNode
 *
 * Description:
 * This component represents a node in the Stock Configuration page.
 * It provides controls for managing stock-related configurations, such as enabling/disabling stock,
 * enabling/disabling master location, selecting the department, and saving changes.
 **/

const ProductConfigurationNode: React.FunctionComponent<
  StockConfigurationNodeProps
> = ({
  locationSelectorList,
  setSelectedDepartmentObj,
  selectedDepartmentObj,
  stockConfiguration,
  handleGetMasterLocationSpecificLocation,
  selectedDepartmentObjInitial,
  setIsEnableMasterLocation,
  isEnableMasterLocation,
  printerMappingsVersion,
  productsVersion,
  printersVersion,
  setPrinterMappingsVersion,
  setProductsVersion,
  setPrintersVersion,
  printerMappingsVersionInitial,
  productsVersionInitial,
  printersVersionInitial,isLoadingButton
}) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const match: any = useRouteMatch();

  /**
   * Function: handleChangeMasterLocation
   *
   * Description:
   * This function handles the change event when selecting a master location in the Autocomplete component.
   * It checks if the selected master location's ID matches the current location ID. If so, it disables the master location
   * and sets a default value for the selected department. Otherwise, it sets the selected department based on the
   * chosen master location.
   **/
  const handleChangeMasterLocation = (e: any, masterLocationData: any) => {
    // Check if the selected master location's ID matches the current location ID
    if (masterLocationData.id === match.params.locationId) {
      // Disable master location and set a default value for the selected department
      setIsEnableMasterLocation(false);
      setSelectedDepartmentObj({
        id: match.params.locationId,
        label: "Master Location Not Required",
      });
    } else {
      // Set the selected department based on the chosen master location
      setSelectedDepartmentObj(masterLocationData);
    }
  };

  /**
   * Function: handleBackgroundColor
   *
   * Description:
   * This function determines the background color based on the presence or absence of stock configuration.
   * If the stock configuration is not empty, it returns 'green', indicating that stock is enabled.
   * Otherwise, it returns 'red', indicating that stock is not enabled.
   **/
  const handleBackgroundColor = () => {
    // Check if the stock configuration is not empty
    if (!_.isEmpty(stockConfiguration)) {
      // Return 'green' if stock is enabled
      return "green";
    } else {
      // Return 'red' if stock is not enabled
      return "red";
    }
  };

  /**
   * Function: handleEnableMasterLocation
   *
   * Description:
   * This function handles the enabling or disabling of the Master Location based on the user's interaction with the switch.
   * It extracts the 'checked' property from the event object and sets the 'isEnableMasterLocation' state accordingly.
   * If Master Location is being disabled, it sets the 'selectedDepartmentObj' state to indicate that Master Location is not required.
   **/
  const handleEnableMasterLocation = (e: any) => {
    // Extract the 'checked' property from the event object
    const { checked } = e.target;

    // Set the 'isEnableMasterLocation' state based on the user's interaction
    setIsEnableMasterLocation(checked);

    // If Master Location is being disabled, update the 'selectedDepartmentObj' state
    if (!checked) {
      setSelectedDepartmentObj({
        id: match.params.locationId,
        label: "Master Location Not Required",
      });
    }
  };

  /**
   * Function: handleSubmit
   *
   * Description:
   * This function is triggered when the user clicks the "Save" button. It calls the 'handleGetMasterLocationSpecificLocation' function,
   * passing the 'id' of the selected department as an argument.
   **/
  const handleSubmit = () => {
    handleGetMasterLocationSpecificLocation(selectedDepartmentObj.id);
  };

  const handleChangePrinterMappingVersion = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPrinterMappingsVersion(
      parseInt((event.target as HTMLInputElement).value),
    );
  };

  const handleChangeProductVersion = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProductsVersion(parseInt((event.target as HTMLInputElement).value));
  };

  const handleChangePrinterVersion = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPrintersVersion(parseInt((event.target as HTMLInputElement).value));
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={5} xl={4}>
          <CardCommon backgroundColor={"entity_background"}>
            <div style={{ marginTop: "20px" }}></div>
            <CardCommon
              backgroundColor={"entity_highlight_background"}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 20px 20px 20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "12px 4px",
                  width: "100%",
                }}
              >
                <FormControlLabel
                  style={{ marginRight: "0px" }}
                  control={<SwitchCommon />}
                  checked={isEnableMasterLocation}
                  onChange={handleEnableMasterLocation}
                  label="Enable Master Location"
                  labelPlacement="start"
                />
              </div>
            </CardCommon>
            <div style={{ margin: "20px" }}>
              <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                size={"small"}
                id="locationSelectGlobal"
                color="inherit"
                options={locationSelectorList}
                value={selectedDepartmentObj}
                getOptionLabel={(option: any) => option.label || ""}
                disableClearable
                disabled={!isEnableMasterLocation}
                onChange={handleChangeMasterLocation}
                classes={{ root: classes.autoComplete }}
                popupIcon={<ArrowDropDownIcon color={"inherit"} />}
                renderOption={(props: any) => {
                  return (
                    <Grid
                      container
                      {...props}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        zIndex: 0,
                        height: "20px",
                        marginBottom: "4px",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          className={classes.titleColor}
                        >
                          {props.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
                renderInput={(params: any) => (
                  <TextField
                    color="inherit"
                    {...params}
                    label=""
                    placeholder="Select Department"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div style={{ margin: "24px" }}>
              <Grid container>
                <Grid item xs={6}></Grid>
                <Grid item xs={3}>
                  <Typography>Version 1</Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Typography>Version 2</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginTop: "12px",
                  }}
                >
                  <Typography>Printer Mappings Version</Typography>
                </Grid>
                <Grid item xs={3} style={{ marginTop: "12px" }}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleChangePrinterMappingVersion}
                      defaultValue={printerMappingsVersion}
                    >
                      <FormControlLabel
                        value={1}
                        style={{ padding: "0px", margin: "0px" }}
                        control={
                          <Radio
                            size="small"
                            style={{ padding: "0px", margin: "0px" }}
                          />
                        }
                        label={<Typography variant="caption"></Typography>}
                        checked={printerMappingsVersion === 1}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "12px",
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleChangePrinterMappingVersion}
                      defaultValue={printerMappingsVersion}
                    >
                      <FormControlLabel
                        value={2}
                        style={{ padding: "0px", margin: "0px" }}
                        control={
                          <Radio
                            size="small"
                            style={{ padding: "0px", margin: "0px" }}
                          />
                        }
                        label={<Typography variant="caption"></Typography>}
                        checked={printerMappingsVersion === 2}
                        labelPlacement="end"
                      />{" "}
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginTop: "12px",
                  }}
                >
                  <Typography>Products Version</Typography>
                </Grid>
                <Grid item xs={3} style={{ marginTop: "12px" }}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleChangeProductVersion}
                      defaultValue={productsVersion}
                    >
                      <FormControlLabel
                        value={1}
                        style={{ padding: "0px", margin: "0px" }}
                        control={
                          <Radio
                            size="small"
                            style={{ padding: "0px", margin: "0px" }}
                          />
                        }
                        label={<Typography variant="caption"></Typography>}
                        checked={productsVersion === 1}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "12px",
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleChangeProductVersion}
                      defaultValue={productsVersion}
                    >
                      <FormControlLabel
                        value={2}
                        style={{ padding: "0px", margin: "0px" }}
                        control={
                          <Radio
                            size="small"
                            style={{ padding: "0px", margin: "0px" }}
                          />
                        }
                        label={<Typography variant="caption"></Typography>}
                        checked={productsVersion === 2}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginTop: "12px",
                  }}
                >
                  <Typography>Printer Version</Typography>
                </Grid>
                <Grid item xs={3} style={{ marginTop: "12px" }}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleChangePrinterVersion}
                      defaultValue={printersVersion}
                    >
                      <FormControlLabel
                        value={1}
                        style={{ padding: "0px", margin: "0px" }}
                        control={
                          <Radio
                            size="small"
                            style={{ padding: "0px", margin: "0px" }}
                          />
                        }
                        label={<Typography variant="caption"></Typography>}
                        checked={printersVersion === 1}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "12px",
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleChangePrinterVersion}
                      defaultValue={printersVersion}
                    >
                      <FormControlLabel
                        value={2}
                        style={{ padding: "0px", margin: "0px" }}
                        control={
                          <Radio
                            size="small"
                            style={{ padding: "0px", margin: "0px" }}
                          />
                        }
                        label={<Typography variant="caption"></Typography>}
                        checked={printersVersion === 2}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <CardCommon
              backgroundColor={"entity_highlight_background"}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 20px 20px 20px",
                border: "none",
              }}
            >
              <ButtonCommon
                onClick={handleSubmit}
                variant="contained"
                style={{
                  fontSize: 11,
                  width: "100%",
                }}
                isLoadingPage={isLoadingButton}
                disabled={
                  (_.isEmpty(selectedDepartmentObj) ||
                    selectedDepartmentObjInitial.id ===
                      selectedDepartmentObj.id ||
                    (isEnableMasterLocation &&
                      selectedDepartmentObj.id === match.params.locationId)) &&
                  printerMappingsVersionInitial === printerMappingsVersion &&
                  productsVersionInitial === productsVersion &&
                  printersVersionInitial === printersVersion
                }
                color={
                  !_.isEmpty(stockConfiguration)
                    ? buttonColors.UPDATE_BUTTON_COLOR
                    : buttonColors.CREATE_BUTTON_COLOR
                }
              >
                {"Save"}
              </ButtonCommon>
            </CardCommon>
          </CardCommon>
        </Grid>
      </Grid>
    </>
  );
};

// Apply the WithLoading higher-order component to the ProductConfigurationNode component
export default ProductConfigurationNode;
