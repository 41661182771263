import {
  Box,
  Button,
  createStyles,
  fade,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PrintIcon from "@material-ui/icons/Print";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import CategoryMenuDropDown from "./CategoryMenuDropDown";
import CatMenuPopUp from "./CatMenuPopUp";
import CardCommon from "../../../../components/card/CardCommon";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import CategoryMenuList from "./CategoryMenuList";
import {
  createCategoryInfo,
  createMenuInfo,
  createProductInfo,
  createProductWrapper,
  createWrapper,
  fetchSingleCategoryInfo,
  fetchSingleMenuInfo,
  fetchSingleProductInfo,
  fetchSingleProductWrapper,
  updateCategoryInfo,
  updateMenuInfo,
  updateProductInfo,
  updateProductWrapperInfo,
  updateWrapperInfo,
} from "../../../../services/menuApp/menuService";
import { useRouteMatch } from "react-router-dom";
import CategoryAddModal from "./Modal/CategoryAddModal";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import ProductAddModal from "./Modal/ProductAddModal";
import { useForm } from "react-hook-form";
import MenuAddModal from "./Modal/MenuAddModal";
import StockDepartmentAddModal from "./Modal/StockDepartmentAddModal";
import StockGroupAddModal from "./Modal/StockGroupAddModal";
import {
  createProductDepartment,
  createProductGroup,
  fetchProductGetByWrappedId,
} from "../../../../services/menuApp/printerMenuService";
import CategoryEditModal from "./Modal/CategoryEditModal";
import { Icon } from "@iconify/react";
import ProductEditModal from "./Modal/ProductEditModal";
import MenuEditModal from "./Modal/MenuEditModal";
import { convertByoName } from "../../../../utils/convertByoName";
import EmptyModal from "./Modal/EmptyModal";
import { CustomTheme } from "../../../../types/customTheme";
import { AxiosError } from "axios";
import CategoryValidIn from "./CategoryValidIn";
import DirectoryOrProductSelectionModal from "./Modal/DirectoryOrProductSelectionModal";
import DirectoryAddModal from "./Modal/DirectoryAddModal";
import CategoryMenuListWrapper from "./CategoryMenuListWrapper";
import WrapperNode from "./WrapperNode";
import ProductWrapperEditModal from "./Modal/ProductWrapperEditModal";
import DirectoryEditModal from "./Modal/DirectoryEditModal";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      zIndex: 5,
      [theme.breakpoints.up("sm")]: {
        marginTop: "62px",
      },
      [theme.breakpoints.up("1280")]: {
        marginTop: "0px",
      },
    },
    button: {
      position: "relative",
      overflow: "hidden",
      borderBottomRightRadius: "10px",
    },
    categoryStyle: {
      backgroundColor: theme.palette.background.default,
      position: "sticky",
      zIndex: 1,
      display: "flex",
      top: 126,
      paddingTop: "28px",
      [theme.breakpoints.up("600")]: {
        top: 200,
      },
      [theme.breakpoints.up("960")]: {
        top: 131,
      },
      [theme.breakpoints.up("1280")]: {
        top: 63,
        paddingTop: "41px",
        // paddingTop: "52px",
      },
    },
    categoryTitle: {
      fontSize: "20px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "32px",
      },
      [theme.breakpoints.up("1280")]: {
        fontSize: "32px",
      },
    },
    categoryEditIconStyle: {
      marginLeft: "12px",
      marginTop: "4px",
    },
    boxStyleOffline: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.default,
      left: 10,
      right: 10,
      zIndex: 50,
    },
    boxStyleOfflineLargeScreen: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.default,
      right: 10,
      zIndex: 50,
    },
    addNodeButton: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "none",
      backgroundColor: theme.palette.background.entity_background,
      "&:hover": {
        backgroundColor: fade(
          theme.palette.background.paper,
          theme.palette.action.hoverOpacity,
        ),
      },
    },
  }),
);

interface FormData {
  name: string;
  plu: string;
  barcode: string;
  upcBarcode: string;
  nameShort: string;
}

export interface MenuInfoPageProps {
  menuNodeList: any;
  getCategoryInfo: any;
  menuNode: any;
  categoryNode: any;
  getProductWrapperInfo: any;
  getMenuInfo: any;
  productVersion: any;
  selectedGroupObj: any;
  stockGroupSelectedNode: any;
  setSelectedGroupObj: any;
  getProductGroupInfo: any;
  stockDepartmentSelectedNode: any;
  getAllStockDepartments: any;
  isOpenCategoryEditModal: any;
  setIsOpenCategoryEditModal: any;
  isOpenProductEditModal: any;
  setIsOpenProductEditModal: any;
  isOpenMenuEditModal: any;
  setIsOpenMenuEditModal: any;
  isOpenProductModal: any;
  setIsOpenProductModal: any;
  isOpenMenuAddModal: any;
  setIsOpenMenuAddModal: any;
  isOpenCategoryModal: any;
  setIsOpenCategoryModal: any;
  isLoadingButton: any;
  setIsLoadingButton: any;
  selectedDepartmentObj: any;
  setSelectedDepartmentObj: any;
  menuName: any;
  setMenuName: any;
  categoryName: any;
  setCategoryName: any;
  isLoadProduct: any;
  setIsLoadProduct: any;
  isOpenDirectoryOrProductSelectionModal: any;
  setIsOpenDirectoryOrProductSelectionModal: any;
  setIsOpenProductWrapperEditModal: any;
  isOpenProductWrapperEditModal: any;
  setIsOpenDirectoryModal: any;
  isOpenDirectoryModal: any;
  setMenuNodeList: any;
}

/**
 * Component: MenuInfoPageNew
 *
 * This component is responsible for rendering the menu information page. It displays category menus along with their products,
 * allows users to select a category menu, view its products, and perform actions such as printing and mapping.
 * It also handles error states and displays an alert in case of unexpected errors.
 */
const MenuInfoPageNew: React.SFC<MenuInfoPageProps> = ({
  menuNodeList,
  getCategoryInfo,
  menuNode,
  categoryNode,
  getProductWrapperInfo,
  getMenuInfo,
  productVersion,
  selectedGroupObj,
  stockGroupSelectedNode,
  setSelectedGroupObj,
  getProductGroupInfo,
  stockDepartmentSelectedNode,
  getAllStockDepartments,
  isOpenCategoryEditModal,
  setIsOpenCategoryEditModal,
  isOpenProductEditModal,
  setIsOpenProductEditModal,
  isOpenMenuEditModal,
  setIsOpenMenuEditModal,
  isOpenProductModal,
  setIsOpenProductModal,
  isOpenMenuAddModal,
  setIsOpenMenuAddModal,
  isOpenCategoryModal,
  setIsOpenCategoryModal,
  isLoadingButton,
  setIsLoadingButton,
  selectedDepartmentObj,
  setSelectedDepartmentObj,
  menuName,
  setMenuName,
  categoryName,
  setCategoryName,
  isLoadProduct,
  setIsLoadProduct,
  isOpenDirectoryOrProductSelectionModal,
  setIsOpenDirectoryOrProductSelectionModal,
  setIsOpenProductWrapperEditModal,
  isOpenProductWrapperEditModal,
  setIsOpenDirectoryModal,
  isOpenDirectoryModal,
  setMenuNodeList,
}) => {
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [selectedCatMenuTitle, setSelectedCatMenuTitle] = useState("");
  const [selectedMenuId, setSelectedMenuId] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [error, setError] = useState("");
  const [productName, setProductName] = useState("");
  const [isCategoryNameEmpty, setIsCategoryNameEmpty] = useState(false);
  const [isStack, setIsStack] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [isMenuNameEmpty, setIsMenuNameEmpty] = useState(false);
  const [success, setSuccess] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [isOpenDepartmentAddModal, setIsOpenDepartmentAddModal] =
    useState(false);
  const [isDepartmentEmpty, setIsDepartmentEmpty] = useState(false);
  const [isValidCategory, setIsValidCategory] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [openDepartmentDropdown, setOpenDepartmentDropdown] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [isLoadingGroupDepartmentButton, setIsLoadingGroupDepartmentButton] =
    useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [selectedWrapperInfo, setSelectedWrapperInfo] = useState<any>({});
  const [isOpenStackProductModal, setIsOpenStackProductModal] = useState(false);
  const [isValidProduct, setIsValidProduct] = useState(false);
  const [buttonSize, setButtonSize] = useState<any>({});
  const [color, setColor] = useState("");
  const [kiosk, setKiosk] = useState("");
  const [openKioskDropDown, setOpenKioskDropDown] = useState(false);
  const [isValidKiosk, setIsValidKiosk] = useState(true);
  const [inTax, setInTax] = useState<any>({});
  const [isByo, setIsByo] = useState(false);
  const [isCustomisationPriority, setIsCustomisationPriority] = useState(false);
  const [isMirrored, setIsMirrored] = useState(false);
  const [isSpicySelectionEnabled, setIsSpicySelectionEnabled] = useState(false);
  const [outTax, setOutTax] = useState<any>({});
  const [allYouCanEat, setAllYouCanEat] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [menu, setMenu] = useState<any>({});
  const [typeInitial, setTypeInitial] = useState("");
  const [brandInitial, setBrandInitial] = useState("");
  const [menuNameInitial, setMenuNameInitial] = useState("");
  const [isOpenGroupAddModal, setIsOpenGroupAddModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [printerMappingItemName, setPrinterMappingItemName] = useState(true);
  const [
    selectedPrinterMappingItemOrCategoryId,
    setSelectedPrinterMappingItemOrCategoryId,
  ] = useState("");
  const [isOpenMappingModal, setIsOpenMappingModal] = useState(false);
  const [stackNode, setStackNode] = useState<any>([]);

  const [directoryName, setDirectoryName] = useState("");
  const [isDirectoryNameEmpty, setIsDirectoryNameEmpty] = useState(false);
  const [url, setUrl] = useState("");
  const [removedLinkedIdList, setRemovedLinkedIdList] = useState<any>([]);
  const [removedLinkedIdLists, setRemovedLinkedIdLists] = useState<any>({});
  const [revertExpand, setRevertExpand] = useState("");
  const [isProductAdd, setIsProductAdd] = useState<any>(false);
  const [isOpenWrapperEditModal, setIsOpenWrapperEditModal] = useState(false);
  const [wrapperId, setWrapperId] = useState("");
  const [isOpenDirectoryEditModal, setIsOpenDirectoryEditModal] =
    useState(false);

  const match: any = useRouteMatch();

  /* Use a react form hooks */
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      plu: "",
      barcode: "",
      upcBarcode: "",
      nameShort: "",
    },
  });

  const handleCreateNewDepartment = async () => {
    setIsLoadingGroupDepartmentButton(true);
    const departmentData = {
      name: departmentName,
      locationId: match.params.locationId,
      valid: true,
      groupId: selectedGroupObj.id,
    };

    try {
      const summaryString = `${departmentName} Department is added`;
      const response = await createProductDepartment(
        match.params.locationId,
        departmentData,
        "Menus Changed",
        summaryString,
        "menu",
      );
      // Set the group ID based on the API response
      setGroupId(response.data.data.groupId.id);
      setSelectedDepartmentObj(response.data.data);

      getAllStockDepartments();

      setIsOpenDepartmentAddModal(false);
      setIsLoadingGroupDepartmentButton(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingGroupDepartmentButton(false);
    }
  };

  const handleChangeCatMenu = (event: any) => {
    if (event.id === "menu") {
      handleOpenMenuAddModal();
    } else {
      // Set the selected category menu title
      setSelectedCatMenuTitle(event.name);
      setSelectedMenuId(event.id);
      const filterDepartment: any = Object.values(menuNodeList).filter(
        (data: any) => data.id === event.id,
      );

      if (!_.isEmpty(filterDepartment[0].category)) {
        const cat: any = [filterDepartment[0]];
        cat.push({ id: "category", name: "Add New Category" });
        setDepartmentList(cat);
        setIsEmptyDepartmentList(false);
      } else {
        setDepartmentList([]);
        setIsEmptyDepartmentList(true);
      }
    }
  };
  const [isEmptyDepartmentList, setIsEmptyDepartmentList] = useState(false);

  useEffect(() => {
    // Check if the menuNodeList is not empty
    if (_.isEmpty(selectedCatMenuTitle)) {
      if (Object.values(menuNodeList).length > 0) {
        const { name, category, id }: any = Object.values(menuNodeList)[0];
        const { id: categoryId, name: categoryName }: any =
          Object.values(category)[0];
        // Object.values(menuNodeList)
        // Set the selected category menu title to the name of the first menu
        setSelectedCatMenuTitle(name);

        // Extract the category list from the first menu object
        const filterDepartment: any = [
          { id: categoryId, name: categoryName, category: category },
        ];
        if (!_.isEmpty(filterDepartment) && productVersion !== 1) {
          filterDepartment.push({ id: "category", name: "Add New Category" });
          setIsEmptyDepartmentList(false);
        } else {
          setIsEmptyDepartmentList(true);
        }
        // Set the category list state variable
        setDepartmentList(filterDepartment);

        setSelectedMenuId(id);
      }
    } else {
      if (Object.values(menuNodeList).length > 0) {
        const menuList = Object.values(menuNodeList);
        const filterDepartment: any = menuList.filter((data: any) => {
          return data.id === selectedMenuId;
        });

        // Set the selected category menu title to the name of the first menu
        setSelectedCatMenuTitle(filterDepartment[0]?.name);

        if (!_.isEmpty(filterDepartment[0].category)) {
          const cat: any = [filterDepartment[0]];
          cat.push({ id: "category", name: "Add New Category" });
          setDepartmentList(cat);
          setIsEmptyDepartmentList(false);
        } else {
          setIsEmptyDepartmentList(true);
          setDepartmentList([]);
        }
        // Set the category list state variable
        // setDepartmentList(filterDepartment);

        setSelectedMenuId(filterDepartment[0]?.id);
      }
    }
  }, [menuNodeList]);

  const [isValidError, setIsValidError] = useState<any>({});

  const handleGetSingleProductWrapper409 = async (data: any) => {
    try {
      const res = await fetchSingleProductWrapper(
        match.params.locationId,
        selectedWrapper.id,
      );
      handleSubmitProductWrapperEditInfo409(data, res.data.data.version);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  };

  // const handleEditProduct = async (data: any, version?: any) => {
  //   const {
  //     name,
  //     plu,
  //     barcode,
  //     upcBarcode,
  //     cost,
  //     desc,
  //     price,
  //     printName,
  //     size,
  //     slavePrintType,
  //     cover,
  //     itemType,
  //     nameShort,
  //   } = data;

  //   const body = {
  //     name: name,
  //     locationId: match.params.locationId,
  //     valid: isValidProduct,
  //     plu: plu,
  //     barcode: barcode,
  //     upcBarcode: upcBarcode,
  //     departmentId: selectedDepartmentObj.id,
  //     id: selectedProduct.id,
  //     extId: selectedProduct.extId,
  //     version: version || selectedProduct.version,
  //     cost: cost,
  //     desc: desc,
  //     inTax: inTax?.id >= 0 ? inTax?.id.toString() : "",
  //     isByo: isByo,
  //     isCustomisationPriority: isCustomisationPriority,
  //     isSpicySelectionEnabled: isSpicySelectionEnabled,
  //     outTax: outTax?.id >= 0 ? outTax?.id : "",
  //     price: price,
  //     printName,
  //     size,
  //     slavePrintType,
  //   };

  //   try {
  //     let summary = [];
  //     if (selectedProduct.name !== name) {
  //       summary.push(
  //         `Product name changed from ${selectedProduct.name} to ${name}`,
  //       );
  //     }
  //     if (selectedProduct.plu !== plu) {
  //       summary.push(`PLU changed from ${selectedProduct.plu} to ${plu}`);
  //     }
  //     if (selectedProduct.valid !== isValidProduct) {
  //       summary.push(
  //         `Valid changed from ${selectedProduct.valid} to ${isValidProduct}`,
  //       );
  //     }
  //     if (selectedProduct.barcode !== barcode) {
  //       summary.push(
  //         `Barcode changed from ${selectedProduct.barcode} to ${barcode}`,
  //       );
  //     }
  //     if (selectedProduct.upcBarcode !== upcBarcode) {
  //       summary.push(
  //         `UPC Barcode changed from ${selectedProduct.upcBarcode} to ${upcBarcode}`,
  //       );
  //     }

  //     if (selectedProduct.departmentId !== selectedDepartmentObj.id) {
  //       summary.push(
  //         `Department Id changed from ${selectedProduct.departmentId} to ${selectedDepartmentObj.id}`,
  //       );
  //     }

  //     if (selectedProduct.cost?.toString() !== cost?.toString()) {
  //       summary.push(`Cost changed from ${selectedProduct.cost} to ${cost}`);
  //     }

  //     if (selectedProduct.desc !== desc) {
  //       summary.push(
  //         `Description changed from ${selectedProduct.desc} to ${desc}`,
  //       );
  //     }

  //     if (selectedProduct.inTax !== inTax?.id) {
  //       summary.push(
  //         `In Tax changed from ${selectedProduct.inTax} to ${inTax?.id}`,
  //       );
  //     }

  //     if (selectedProduct.outTax !== outTax?.id) {
  //       summary.push(
  //         `Out Tax changed from ${selectedProduct.outTax} to ${outTax?.id}`,
  //       );
  //     }

  //     if (selectedProduct.isByo !== isByo) {
  //       summary.push(`BYO changed from ${selectedProduct.isByo} to ${isByo}`);
  //     }

  //     if (selectedProduct.isCustomisationPriority !== isCustomisationPriority) {
  //       summary.push(
  //         `Customisation Priority changed from ${selectedProduct.isCustomisationPriority} to ${isCustomisationPriority}`,
  //       );
  //     }

  //     if (selectedProduct.isSpicySelectionEnabled !== isSpicySelectionEnabled) {
  //       summary.push(
  //         `Spicy Selection Enabled changed from ${selectedProduct.isSpicySelectionEnabled} to ${isSpicySelectionEnabled}`,
  //       );
  //     }

  //     if (selectedProduct.price?.toString() !== price?.toString()) {
  //       summary.push(`Price changed from ${selectedProduct.price} to ${price}`);
  //     }

  //     if (selectedProduct.printName !== printName) {
  //       summary.push(
  //         `Print Name changed from ${selectedProduct.printName} to ${printName}`,
  //       );
  //     }

  //     if (selectedProduct.size !== size) {
  //       summary.push(
  //         `Size Name changed from ${selectedProduct.size} to ${size}`,
  //       );
  //     }

  //     if (selectedProduct.slavePrintType !== slavePrintType) {
  //       summary.push(
  //         `Slave Print Type Name changed from ${selectedProduct.slavePrintType} to ${slavePrintType}`,
  //       );
  //     }

  //     const summaryString = summary.join("\\n");
  //     const res = await updateProductInfo(
  //       match.params.locationId,
  //       body,
  //       "Menus Changed",
  //       summaryString,
  //       "menu",
  //     );
  //     if (res.data.status === 409) {
  //       handleGetSingleProduct409(data, selectedProduct.id);
  //     } else {
  //       // setIsValidProduct(false);
  //       getProductWrapperInfo(menuNode, categoryNode, productVersion);
  //     }
  //   } catch (err) {
  //     setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
  //     setIsLoadingButton(false);
  //   }
  // };

  // const handleGetSingleProduct409 = async (data: any, productId: any) => {
  //   try {
  //     const res = await fetchSingleProductInfo(
  //       match.params.locationId,
  //       productId,
  //     );
  //     handleEditProduct(data, res.data.data.version);
  //   } catch (err) {
  //     setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
  //     setIsLoadingButton(false);
  //   }
  // };

  // const handleSubmitProduct = async (data: any, productWrapperId: any) => {
  //   const { plu, barcode, upcBarcode } = data;
  //   const body = {
  //     name: data.name,
  //     locationId: match.params.locationId,
  //     valid: true,
  //     plu: plu,
  //     barcode: barcode,
  //     upcBarcode: upcBarcode,
  //     departmentId: selectedDepartmentObj.id,
  //     itemWrapperId: productWrapperId,
  //     extId: "",
  //   };

  //   try {
  //     const summaryString = `${data.name} Product is added`;
  //     const res = await createProductInfo(
  //       match.params.locationId,
  //       body,
  //       "Menus Changed",
  //       summaryString,
  //       "menu",
  //     );
  //     setCategoryId("");
  //     getProductWrapperInfo(menuNode, categoryNode, productVersion);
  //   } catch (err) {
  //     setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
  //     setIsLoadingButton(false);
  //   }
  // };

  const handleSubmitProductDetailsInfo = async (
    data: any,
    directoryId?: any,
    directoryLevel?: any,
  ) => {
    const { plu, barcode, upcBarcode } = data;

    const body = {
      name: data.name,
      locationId: match.params.locationId,
      categoryId: categoryId,
      valid: true,
      plu: plu,
      barcode: barcode,
      upcBarcode: upcBarcode,
      departmentId: selectedDepartmentObj.id,
      extId: "",
      itemWrapperId: wrapperId,
    };

    try {
      const summaryString = `${data.name} Product is added`;

      const res = await createProductInfo(
        match.params.locationId,
        body,
        "Menus Changed",
        summaryString,
        "menu",
      );
      setCategoryId("");

      setSuccess(SUCCESSFULLY_CREATED);
    } catch (err) {
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleOpenCategoryModal = () => {
    setIsOpenCategoryModal(true);
  };

  const [isRoot, setIsRoot] = useState(false);

  const handleOpenProductOrDirectoryModal = (category: any) => {
    const { id } = category;
    setCategoryId(id);
    setDirectoryLevel("");
    setDirectoryId("");
    setIsOpenDirectoryOrProductSelectionModal(true);
    setIsRoot(true);
  };

  const [firstWrapperId, setFirstWrapperId] = useState("");

  const handleOpenProductOrDirectoryModals = (category: any, wrapper?: any) => {
    const { id } = category;
    setCategoryId(id);
    setIsOpenDirectoryOrProductSelectionModal(true);
    setDirectoryId(removedLinkedIdLists[wrapper.id].id);
    setDirectoryLevel(removedLinkedIdLists[wrapper.id].directoryLevel + 1);
    setWrapperId(removedLinkedIdLists[wrapper.id].id);
    setFirstWrapperId(wrapper.id);
    setIsRoot(false);
    // setIsOpenProductModal(true);
  };

  const handleOpenProductModal = (category: any) => {
    setIsOpenDirectoryOrProductSelectionModal(false);
    setIsOpenProductModal(true);
  };

  const [directoryId, setDirectoryId] = useState("");
  const [directoryLevel, setDirectoryLevel] = useState("");

  const handleOpenMenuAddModal = () => {
    setIsOpenMenuAddModal(true);
  };

  const handleOpenMenuEditModal = (menuDetails: any) => {
    setIsOpenMenuEditModal(true);
    setMenuName(menuDetails.name);
    setMenu(menuDetails);
    setType(menuDetails.type);
    setBrand(menuDetails.brand);
    setTypeInitial(menuDetails.type);
    setBrandInitial(menuDetails.brand);
    setMenuNameInitial(menuDetails.name);
  };

  const handleCloseMenuAddModal = () => {
    setIsOpenMenuAddModal(false);
  };

  const handleCloseMenuEditModal = () => {
    setIsOpenMenuEditModal(false);
    setMenuName("");
    setType("");
    setBrand("");
  };

  const handleCloseCategoryAddModal = () => {
    setIsCategoryNameEmpty(false);
    setCategoryName("");
    setIsOpenCategoryModal(false);
  };

  const handleCloseCategoryEditModal = () => {
    reset();
    setIsOpenCategoryEditModal(false);
  };

  const handleCloseProductAddModal = () => {
    setIsOpenProductModal(false);
    setIsDepartmentEmpty(false);
    setSelectedDepartmentObj([]);
    reset();
  };

  const handleCloseProductEditModal = () => {
    setIsOpenProductEditModal(false);
    setSelectedDepartmentObj({});
  };

  const handleCloseProductWrapperEditModal = () => {
    setIsOpenProductWrapperEditModal(false);
  };

  /**
   * Handle the opening of the category add modal.
   */
  const handleOpenDepartmentAddModal = () => {
    // Clear the category name input and set the modal state to open
    setDepartmentName("");
    setIsOpenDepartmentAddModal(true);
  };

  /**
   * Handles the change of selected group and updates the selected group object and ID.
   *
   * @param {Object} e - The event object from the change event.
   * @param {Object} groupData - The data of the selected group.
   */
  const handleGroupChange = (e: any, groupData: any) => {
    // Update the selected group object
    setSelectedGroupObj(groupData);

    // Set the selected group ID
    setGroupId(groupData.id);
  };

  /**
   * Handle the opening of the group add modal.
   */
  const handleOpenGroupAddModal = () => {
    setGroupName("");
    setIsOpenGroupAddModal(true);
  };

  /**
   * Handle category name change.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
   */
  const handleChangeDepartmentName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Set the category name based on the input value
    const newDepartmentName: string = event.target.value;
    setDepartmentName(newDepartmentName);
  };

  /**
   * Handle group name change.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
   */
  const handleChangeGroupName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Set the group name based on the input value
    const newGroupName: string = event.target.value;
    setGroupName(newGroupName);
  };

  /**
   * Handles the creation of a new group by making an API call to create the group
   * with the provided data. If successful, it sets the group ID and fetches all stock groups
   * for the newly created group. Closes the group add modal upon completion.
   */
  const handleCreateNewGroup = async () => {
    setIsLoadingGroupDepartmentButton(true);
    // Prepare the data for creating a new group
    const groupData = {
      name: groupName,
      locationId: match.params.locationId,
      valid: true,
    };
    try {
      const summaryString = `${groupName} Group is added`;
      // Attempt to create a new group via an API call
      const response = await createProductGroup(
        match.params.locationId,
        groupData,
        "Menus Changed",
        summaryString,
        "menu",
      );
      setGroupId(response.data.data.id);
      getProductGroupInfo(response.data.data.id);
      setIsOpenGroupAddModal(false);
      setIsLoadingGroupDepartmentButton(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingGroupDepartmentButton(false);
    }
  };

  const handleOpenCategoryEditModal = (category: any) => {
    setSelectedCategory(category);
    setIsOpenCategoryEditModal(true);
  };

  const [selectedWrapper, setSelectedWrapper] = useState<any>();
  const [productId, setProductId] = useState<any>();

  const handleOpenProductEditModal = (
    product: any,
    wrapper: any,
    category: any,
    isOpenWrapperEditModal: any,
    isRoot: any,
  ) => {
    setCategoryId(category.id);
    setWrapperId(product.id);
    setProductId(product.id);
    setFirstWrapperId(wrapper.id);
    setIsOpenProductEditModal(true);
    setSelectedWrapper(product);
    setIsRoot(isRoot);
  };

  const handleOpenDirectoryEditModal = (
    product: any,
    wrapper: any,
    category: any,
    isOpenWrapperEditModal: any,
    isRoot: any,
  ) => {
    setCategoryId(category.id);
    setWrapperId(product.id);
    setProductId(product.id);
    setFirstWrapperId(wrapper.id);
    setIsOpenDirectoryEditModal(true);
    setSelectedWrapper(product);
    setIsRoot(isRoot);
  };

  const handleProductGetByWrappedId = async (wrappedId: any) => {
    try {
      // Fetch global product information from the backend
      const res = await fetchProductGetByWrappedId(
        match.params.locationId,
        wrappedId,
      );

      if (res?.data?.data?.length === 1) {
        // // Set the selected printer mapping item or category ID state variable
        setSelectedPrinterMappingItemOrCategoryId(res.data.data[0].id);

        // // Open the mapping modal by setting the isOpenMappingModal state variable to true
        setIsOpenMappingModal(true);
      } else {
        if (res?.data?.data && res?.data?.data[0]?.isByo) {
          // // Set the selected printer mapping item or category ID state variable
          setSelectedPrinterMappingItemOrCategoryId(res.data.data[0].id);

          // // Open the mapping modal by setting the isOpenMappingModal state variable to true
          setIsOpenMappingModal(true);
        } else {
          setIsOpenStackProductModal(true);
          setStackNode(res.data.data);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      // Handle unexpected errors by setting the error state and isLoading state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * handleOpenMappingModal Function: Handle Opening Mapping Modal
   *
   * This function is responsible for opening the mapping modal when triggered by an event.
   * It sets the necessary state variables such as category name, printer mapping item name,
   * selected printer mapping item or category ID, and modal type.
   * The modal is then displayed to the user.
   */
  const handleOpenMappingModal = (
    event: any,
    category: any,
    item: any,
    categoryOrItem: string,
    isMap?: any,
  ) => {
    // Check if an event exists and stop its propagation to prevent further event handling
    if (event) {
      event.stopPropagation();
    }
    setIsOpenModal(true);
    setIsLoading(true);
    // // Set the category name state variable
    setCategoryName(category.name);

    // // Set the printer mapping item name state variable
    setPrinterMappingItemName(
      convertByoName(item.name, item?.size, item?.isByo),
    );

    // // Set the modal type state variable
    setType(categoryOrItem);
    if (categoryOrItem === "item" && isMap) {
      setIsStack(true);
    } else {
      setIsStack(false);
    }
    if (categoryOrItem === "item" && !isMap) {
      handleProductGetByWrappedId(item.id);
    } else {
      // // Set the selected printer mapping item or category ID state variable
      setSelectedPrinterMappingItemOrCategoryId(item.id);
      setIsOpenModal(true);
      setIsLoading(false);
      setIsOpenStackProductModal(false);
      // // Open the mapping modal by setting the isOpenMappingModal state variable to true
      setIsOpenMappingModal(true);
    }
  };

  const [errorTimeout, setErrorTimeout] = useState<any>(null);
  const wrapperRef: any = useRef(null); // Ref for the component wrapper element

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // Clear the timeout when there's a click outside the component
        clearTimeout(errorTimeout);
        setIsValidError({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [errorTimeout, setIsValidError]);

  const handleOpenDirectoryModal = () => {
    setIsOpenDirectoryOrProductSelectionModal(false);
    setDirectoryName("");
    setIsOpenDirectoryModal(true);
  };

  const handleCloseDirectoryAddModal = () => {
    setIsDepartmentEmpty(false);
    setUrl("");
    setIsOpenDirectoryModal(false);
  };

  const handleCloseDirectoryEditModal = () => {
    setIsDepartmentEmpty(false);
    setUrl("");
    setIsOpenDirectoryEditModal(false);
  };

  const [isEmptyProductList, setIsEmptyProductList] = useState<any>([]);

  const handleClickLinkedId = (e: any, category: any, categoryId: any) => {
    if(removedLinkedIdLists[e.id]) {
      handleClearStrip(e)
    } else {
      setDirectoryLevel(e.directoryLevel + 1);

      const cloneIdLiST = _.cloneDeep(removedLinkedIdList);
      setRevertExpand(e.id);
      cloneIdLiST.push(e.id);
      const cloneIdLiSTs = _.cloneDeep(removedLinkedIdLists);
  
      // if (e.directoryLevel === 1) {
      cloneIdLiSTs[category.id] = {
        id: e.id,
        directoryLevel: e.directoryLevel,
      };
  
      setRemovedLinkedIdList(cloneIdLiST);
      setRemovedLinkedIdLists(cloneIdLiSTs);
    }
    
  };

  const handleClearEmptyList = (wrapperId: any) => {
    const cloneIdLiST = _.cloneDeep(isEmptyProductList);
    const filterWrapperId = cloneIdLiST.filter(
      (data: any) => data !== wrapperId,
    );
    setIsEmptyProductList(filterWrapperId);
  };

  const handleClearStrip = (product: any) => {
    const cloneRemovedLinkedIdLists = _.cloneDeep(removedLinkedIdLists);
    delete cloneRemovedLinkedIdLists[product.id];
    setRemovedLinkedIdLists(cloneRemovedLinkedIdLists);
  };

  const handleNavigate = (product: any, level: any, data: any) => {
    const cloneRemovedLinkedIdLists = _.cloneDeep(removedLinkedIdLists);
    cloneRemovedLinkedIdLists[data.id].id = product.id;
    cloneRemovedLinkedIdLists[data.id].directoryLevel = level;
    setRemovedLinkedIdLists(cloneRemovedLinkedIdLists);
  };

  const handleCloseDirectoryOrProductSelectionModal = () => {
    setIsOpenDirectoryOrProductSelectionModal(false);
  };

  const handleOpenProductEditModalDirectory = (
    e: any,
    categoryId: any,
    wrapper: any,
  ) => {
    // e.preventDefault();
    setSelectedWrapperInfo(wrapper);
    setCategoryId(categoryId);
    setIsOpenProductWrapperEditModal(true);
  };

  const handleSubmitProductWrapperEditInfo409 = async (
    data: any,
    version?: any,
  ) => {
    const { cover, itemType, nameShort, name, desc, size, img } = data;

    const body = {
      allYouCanEat: allYouCanEat,
      colour: color,
      cover: cover,
      nameShort: nameShort,
      locationId: match.params.locationId,
      name: name,
      desc: desc,
      id: selectedProduct.id,
      img: img,
      version: version,
    };

    try {
      let summary = [];

      if (selectedProduct.name !== name) {
        summary.push(
          `Product name changed from ${selectedProduct.name} to ${name}`,
        );
      }

      if (selectedProduct.desc !== desc) {
        summary.push(
          `Description changed from ${selectedProduct.desc} to ${desc}`,
        );
      }

      if (selectedProduct.size !== size) {
        summary.push(
          `Size Name changed from ${selectedProduct.size} to ${size}`,
        );
      }

      if (selectedWrapper.cover !== cover && !_.isEmpty(cover)) {
        summary.push(`Cover changed from ${selectedWrapper.cover} to ${cover}`);
      }

      if (selectedWrapper.itemType !== itemType) {
        summary.push(
          `Item Type changed from ${selectedWrapper.itemType} to ${itemType}`,
        );
      }

      if (selectedWrapper.nameShort !== nameShort) {
        summary.push(
          `Name Short changed from ${selectedWrapper.nameShort} to ${nameShort}`,
        );
      }

      if (selectedWrapper.colour !== color && !_.isEmpty(color)) {
        summary.push(
          `Color changed from ${selectedWrapper.colour} to ${color}`,
        );
      }

      if (selectedWrapper.allYouCanEat !== allYouCanEat) {
        summary.push(
          `All You Can Eat changed from ${selectedWrapper.allYouCanEat} to ${allYouCanEat}`,
        );
      }

      const summaryString = summary.join("\\n");

      const res = await updateWrapperInfo(
        match.params.locationId,
        body,
        "Menus Changed",
        summaryString,
        "menu",
      );
      if (res.data.status === 409) {
        handleGetSingleProductWrapper409(data);
      } else {
        getProductWrapperInfo(menuNode, categoryNode, productVersion);
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  };

  const handleSubmitProductWrapperEditInfo = handleSubmit(async (data: any) => {
    setIsLoadingButton(true);

    const { cover, itemType, nameShort, name, desc, size, img } = data;

    const body = {
      allYouCanEat: allYouCanEat,
      colour: color,
      cover: cover,
      nameShort: nameShort,
      locationId: match.params.locationId,
      name: name,
      desc: desc,
      id: selectedWrapperInfo.id,
      img: img,
      version: selectedWrapperInfo.version,
    };

    try {
      let summary = [];

      if (selectedWrapperInfo.name !== name) {
        summary.push(
          `Product name changed from ${selectedProduct.name} to ${name}`,
        );
      }

      if (selectedWrapperInfo.desc !== desc) {
        summary.push(
          `Description changed from ${selectedProduct.desc} to ${desc}`,
        );
      }

      if (selectedWrapperInfo.size !== size) {
        summary.push(
          `Size Name changed from ${selectedProduct.size} to ${size}`,
        );
      }

      if (selectedWrapperInfo.cover !== cover && !_.isEmpty(cover)) {
        summary.push(
          `Cover changed from ${selectedWrapperInfo.cover} to ${cover}`,
        );
      }

      if (selectedWrapperInfo.itemType !== itemType) {
        summary.push(
          `Item Type changed from ${selectedWrapperInfo.itemType} to ${itemType}`,
        );
      }

      if (selectedWrapperInfo.nameShort !== nameShort) {
        summary.push(
          `Name Short changed from ${selectedWrapperInfo.nameShort} to ${nameShort}`,
        );
      }

      if (selectedWrapperInfo.colour !== color && !_.isEmpty(color)) {
        summary.push(
          `Color changed from ${selectedWrapperInfo.colour} to ${color}`,
        );
      }

      if (selectedWrapperInfo.allYouCanEat !== allYouCanEat) {
        summary.push(
          `All You Can Eat changed from ${selectedWrapperInfo.allYouCanEat} to ${allYouCanEat}`,
        );
      }

      const summaryString = summary.join("\\n");

      const res = await updateWrapperInfo(
        match.params.locationId,
        body,
        "Menus Changed",
        summaryString,
        "menu",
      );
      if (res.data.status === 409) {
        handleGetSingleProductWrapper409(data);
      } else {
        getProductWrapperInfo(menuNode, categoryNode, productVersion);
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  });

  const handleOpenMappingModalDirectory = () => {};

  const handleChangeValidInWrapperDirectory = () => {};

  const classes = useStyles();
  const maxWidthCustom = useMediaQuery("(max-width: 1280px)");
  const mobileScreen = useMediaQuery("(max-width: 960px)");
  const theme: CustomTheme = useTheme();
  return (
    <>
      <div style={{ position: "fixed", top: "40px", marginTop: "40px" }}></div>
      <Grid container spacing={2} className={classes.root} ref={wrapperRef}>
        {!_.isEmpty(menuNodeList) && (
          <Grid
            item
            xs={12}
            className={
              mobileScreen
                ? classes.boxStyleOffline
                : classes.boxStyleOfflineLargeScreen
            }
          >
            <div
              style={
                mobileScreen
                  ? {
                      display: "flex",
                      justifyContent: "end",
                      paddingTop: "18px",
                      width: "100%",
                    }
                  : {
                      display: "flex",
                      justifyContent: "end",
                      paddingTop: "20px",
                    }
              }
            >
              {selectedCatMenuTitle && (
                <CategoryMenuDropDown
                  categoryMenuList={menuNodeList}
                  selectedCatMenuTitle={selectedCatMenuTitle}
                  handleChangeCatMenu={handleChangeCatMenu}
                  handleOpenMenuEditModal={handleOpenMenuEditModal}
                  productVersion={productVersion}
                />
              )}

              {departmentList?.length > 0 && (
                <CatMenuPopUp
                  setAnchorEl={setAnchorEl}
                  anchorEl={anchorEl}
                  departmentList={departmentList}
                  handleOpenCategoryModal={handleOpenCategoryModal}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
      {departmentList?.length === 0 &&
        isEmptyDepartmentList &&
        !_.isEmpty(menuNodeList) &&
        productVersion !== 1 && (
          <Grid container style={{ marginTop: "100px" }}>
            <Grid item sm={12} md={6} xs={12} lg={4}>
              <CardCommon backgroundColor={"entity_background"}>
                <Button
                  variant="contained"
                  className={classes.addNodeButton}
                  onClick={handleOpenCategoryModal}
                  style={{ height: "134px", borderRadius: "10px" }}
                >
                  <Typography>
                    <AddIcon fontSize="large" />
                  </Typography>
                  <Typography style={{ marginLeft: "12px" }}>
                    Add New Category
                  </Typography>
                </Button>
              </CardCommon>
            </Grid>
          </Grid>
        )}
      {!_.isEmpty(menuNodeList) && departmentList ? (
        departmentList &&
        departmentList?.map(
          (category: any, index: any) =>
            category?.category &&
            Object.values(category?.category)?.length > 0 &&
            Object.values(category?.category).map(
              (cat: any) =>
                category.id !== "category" && (
                  <div>
                    <Grid
                      container
                      className={classes.categoryStyle}
                      style={{
                        marginBottom:
                          index === 0 && mobileScreen ? "90px" : "10px",
                        marginTop: mobileScreen ? "10px" : "0px",
                      }}
                    >
                      <Grid
                        item
                        style={{
                          width: mobileScreen
                            ? "100%"
                            : maxWidthCustom
                            ? "calc(100vw - 410px)"
                            : "calc(100vw - 670px)",
                          position: "relative",
                        }}
                      >
                        <Box
                          style={{
                            width: "100%",
                            height: "56px",
                            backgroundColor:
                              theme.palette.background.entity_background,
                            borderRadius: "10px",
                            marginBottom: "12px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "4px 12px",
                            border: `1px solid ${theme.palette.background.entity_border}`,
                          }}
                        >
                          {isValidError?.id === cat.id && (
                            <div
                              className="warning"
                              style={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                left: "0px",
                                backgroundColor: "red",
                                color: "white",
                                padding: "5px",
                                borderRadius: "10px",
                                height: "30px",
                                zIndex: 3,
                              }}
                            >
                              {isValidError?.error}
                            </div>
                          )}
                          <div style={{ display: "flex" }}>
                            <Box
                              style={{
                                width: "4px",
                                height: "40px",
                                borderRadius: "10px",
                                backgroundColor: cat.colour,
                                marginRight: "12px",
                                marginTop: "4px",
                              }}
                            ></Box>
                            <Typography
                              align="left"
                              id={cat.name}
                              className={classes.categoryTitle}
                            >
                              {cat.name}
                            </Typography>
                          </div>

                          {productVersion !== 1 && (
                            <div>
                              <Button
                                size={"small"}
                                onClick={() => handleOpenCategoryEditModal(cat)}
                                className={classes.button}
                                style={{
                                  minWidth: "28px",
                                  height: "32px",
                                  backgroundColor:
                                    theme.palette.custom.yellow.main,
                                  marginRight: "8px",
                                }}
                              >
                                <EditIcon
                                  style={{
                                    fontSize: "16px",
                                    color:
                                      theme.palette.custom.yellow.contrastText,
                                  }}
                                />
                              </Button>

                              <Button
                                size={"small"}
                                className={classes.button}
                                style={{
                                  minWidth: "28px",
                                  height: "32px",
                                  backgroundColor:
                                    theme.palette.custom.orange.main,
                                  marginRight: "8px",
                                }}
                                onClick={(event: any) =>
                                  handleOpenMappingModal(
                                    event,
                                    cat,
                                    cat,
                                    "category",
                                    false,
                                  )
                                }
                              >
                                <PrintIcon
                                  style={{
                                    fontSize: "16px",
                                    color:
                                      theme.palette.custom.orange.contrastText,
                                  }}
                                />
                              </Button>

                              <Button
                                size={"small"}
                                className={classes.button}
                                style={{
                                  minWidth: "28px",
                                  height: "32px",
                                  backgroundColor:
                                    theme.palette.custom.blue.main,
                                  marginRight: "8px",
                                }}
                              >
                                <FileCopyIcon
                                  style={{
                                    fontSize: "16px",
                                    color:
                                      theme.palette.custom.blue.contrastText,
                                  }}
                                />
                              </Button>

                              <CategoryValidIn
                                category={cat}
                                setError={setError}
                                setIsValidError={setIsValidError}
                                setSuccess={setSuccess}
                                menuNodeList={menuNodeList}
                                setMenuNodeList={setMenuNodeList}
                                menuId={selectedMenuId}
                              />
                            </div>
                          )}
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <>
                        {Object.values(cat?.wrapper)?.map(
                          (wrapper: any, index: any) => {
                            return (
                              <>
                                <WrapperNode
                                  wrapper={wrapper}
                                  handleClickLinkedId={handleClickLinkedId}
                                  category={cat}
                                  isEmptyProductList={isEmptyProductList}
                                  handleClearEmptyList={handleClearEmptyList}
                                  handleOpenProductEditModal={
                                    handleOpenProductEditModal
                                  }
                                  handleOpenMappingModal={
                                    handleOpenMappingModal
                                  }
                                  isLoadProduct={isLoadProduct}
                                  getProductWrapperInfo={getProductWrapperInfo}
                                  menuNode={menuNode}
                                  categoryNode={categoryNode}
                                  setSuccess={setSuccess}
                                  removedLinkedIdLists={removedLinkedIdLists}
                                  handleClearStrip={handleClearStrip}
                                  productVersion={productVersion}
                                  handleNavigate={handleNavigate}
                                  handleOpenProductOrDirectoryModals={
                                    handleOpenProductOrDirectoryModals
                                  }
                                  setIsProductAdd={setIsProductAdd}
                                  handleOpenProductEditModalDirectory={
                                    handleOpenProductEditModalDirectory
                                  }
                                  handleOpenMappingModalDirectory={
                                    handleOpenMappingModalDirectory
                                  }
                                  handleChangeValidInWrapperDirectory={
                                    handleChangeValidInWrapperDirectory
                                  }
                                  index={index}
                                  menuNodeList={menuNodeList}
                                  setMenuNodeList={setMenuNodeList}
                                  wrapperId={wrapperId}
                                  menuId={selectedMenuId}
                                  categoryId={categoryId}
                                  firstWrapperId={firstWrapperId}
                                  isRoot={isRoot}
                                  isOpenWrapperEditModal={
                                    isOpenWrapperEditModal
                                  }
                                  setError={setError}
                                  handleOpenDirectoryEditModal={
                                    handleOpenDirectoryEditModal
                                  }
                                />
                              </>
                            );
                          },
                        )}
                        {productVersion !== 1 && (
                          <Grid item md={6} xs={12} lg={4}>
                            <Button
                              variant="contained"
                              className={classes.addNodeButton}
                              onClick={() => {
                                handleOpenProductOrDirectoryModal(cat);
                              }}
                              style={{
                                height: "134px",
                                marginTop: "16px",
                                borderRadius: "10px",
                              }}
                            >
                              <Typography>
                                <AddIcon fontSize="large" />
                              </Typography>
                              <Typography style={{ marginLeft: "12px" }}>
                                Add New
                              </Typography>
                            </Button>
                          </Grid>
                        )}
                      </>
                    </Grid>
                  </div>
                ),
            ),
        )
      ) : productVersion !== 1 ? (
        <Grid container style={{ marginTop: "60px" }}>
          <Grid item sm={12} md={6} xs={12} lg={4}>
            <CardCommon backgroundColor={"entity_background"}>
              <Button
                variant="contained"
                className={classes.addNodeButton}
                onClick={handleOpenMenuAddModal}
                style={{ height: "134px", borderRadius: "10px" }}
              >
                <Typography>
                  <AddIcon fontSize="large" />
                </Typography>
                <Typography style={{ marginLeft: "12px" }}>
                  Add New Menu
                </Typography>
              </Button>
            </CardCommon>
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "16%",
          }}
        >
          <Typography variant="h3">No Menus Available.</Typography>
        </div>
      )}

      <CategoryAddModal
        isOpen={isOpenCategoryModal}
        setIsOpen={setIsOpenCategoryModal}
        categoryName={categoryName}
        setCategoryName={setCategoryName}
        isCategoryNameEmpty={isCategoryNameEmpty}
        setIsCategoryNameEmpty={setIsCategoryNameEmpty}
        handleCloseCategoryAddModal={handleCloseCategoryAddModal}
        isLoadingButton={isLoadingButton}
        setIsLoadingButton={setIsLoadingButton}
        selectedMenuId={selectedMenuId}
        setSuccess={setSuccess}
        setError={setError}
        selectedCategory={selectedCategory}
        isValidCategory={isValidCategory}
        buttonSize={buttonSize}
        isValidKiosk={isValidKiosk}
        color={color}
        menuNodeList={menuNodeList}
        setMenuNodeList={setMenuNodeList}
      />
      <CategoryEditModal
        isOpen={isOpenCategoryEditModal}
        setIsOpen={setIsOpenCategoryEditModal}
        errors={errors}
        register={register}
        setValue={setValue}
        reset={reset}
        handleCloseCategoryEditModal={handleCloseCategoryEditModal}
        isLoadingButton={isLoadingButton}
        selectedCategory={selectedCategory}
        setIsValidCategory={setIsValidCategory}
        isValidCategory={isValidCategory}
        buttonSize={buttonSize}
        setButtonSize={setButtonSize}
        color={color}
        setColor={setColor}
        kiosk={kiosk}
        setKiosk={setKiosk}
        setOpenKioskDropDown={setOpenKioskDropDown}
        openKioskDropDown={openKioskDropDown}
        isValidKiosk={isValidKiosk}
        setIsValidKiosk={setIsValidKiosk}
        isDirty={isDirty}
        setError={setError}
        setIsLoadingButton={setIsLoadingButton}
        handleSubmit={handleSubmit}
        selectedMenuId={selectedMenuId}
        setSuccess={setSuccess}
        menuNodeList={menuNodeList}
        setMenuNodeList={setMenuNodeList}
        setIsCategoryNameEmpty={setIsCategoryNameEmpty}
      />

      <ProductAddModal
        isOpen={isOpenProductModal}
        setIsOpen={setIsOpenProductModal}
        productName={productName}
        setProductName={setProductName}
        isCategoryNameEmpty={isCategoryNameEmpty}
        setIsCategoryNameEmpty={setIsCategoryNameEmpty}
        handleCloseProductAddModal={handleCloseProductAddModal}
        isLoadingButton={isLoadingButton}
        errors={errors}
        register={register}
        handleOpenDepartmentAddModal={handleOpenDepartmentAddModal}
        stockDepartmentSelectedNode={stockDepartmentSelectedNode}
        selectedDepartmentObj={selectedDepartmentObj}
        setSelectedDepartmentObj={setSelectedDepartmentObj}
        setDepartmentName={setDepartmentName}
        isDepartmentEmpty={isDepartmentEmpty}
        setIsDepartmentEmpty={setIsDepartmentEmpty}
        reset={reset}
        wrapperId={wrapperId}
        handleSubmit={handleSubmit}
        setIsLoadingButton={setIsLoadingButton}
        categoryId={categoryId}
        directoryId={directoryId}
        directoryLevel={directoryLevel}
        setCategoryId={setCategoryId}
        menuNodeList={menuNodeList}
        selectedMenuId={selectedMenuId}
        firstWrapperId={firstWrapperId}
        setSuccess={setSuccess}
        setMenuNodeList={setMenuNodeList}
        setError={setError}
        isRoot={isRoot}
        menuId={selectedMenuId}
        setIsOpenDirectoryOrProductSelectionModal={
          setIsOpenDirectoryOrProductSelectionModal
        }
      />

      <ProductEditModal
        isOpen={isOpenProductEditModal}
        setIsOpen={setIsOpenProductEditModal}
        handleCloseProductEditModal={handleCloseProductEditModal}
        isLoadingButton={isLoadingButton}
        errors={errors}
        register={register}
        handleOpenDepartmentAddModal={handleOpenDepartmentAddModal}
        stockDepartmentSelectedNode={stockDepartmentSelectedNode}
        selectedDepartmentObj={selectedDepartmentObj}
        setSelectedDepartmentObj={setSelectedDepartmentObj}
        setDepartmentName={setDepartmentName}
        isDepartmentEmpty={isDepartmentEmpty}
        setIsDepartmentEmpty={setIsDepartmentEmpty}
        reset={reset}
        setIsValidProduct={setIsValidProduct}
        isValidProduct={isValidProduct}
        inTax={inTax}
        setInTax={setInTax}
        isByo={isByo}
        setIsByo={setIsByo}
        isCustomisationPriority={isCustomisationPriority}
        setIsCustomisationPriority={setIsCustomisationPriority}
        setIsMirrored={setIsMirrored}
        isSpicySelectionEnabled={isSpicySelectionEnabled}
        setIsSpicySelectionEnabled={setIsSpicySelectionEnabled}
        outTax={outTax}
        setOutTax={setOutTax}
        allYouCanEat={allYouCanEat}
        setAllYouCanEat={setAllYouCanEat}
        setColor={setColor}
        color={color}
        setIsValidKiosk={setIsValidKiosk}
        isValidKiosk={isValidKiosk}
        isDirty={isDirty}
        selectedWrapper={selectedWrapper}
        setError={setError}
        productId={productId}
        menuNodeList={menuNodeList}
        selectedMenuId={selectedMenuId}
        categoryId={categoryId}
        wrapperId={wrapperId}
        setMenuNodeList={setMenuNodeList}
        setIsOpenProductEditModal={setIsOpenProductEditModal}
        setIsLoadingButton={setIsLoadingButton}
        handleSubmit={handleSubmit}
        menuId={selectedMenuId}
        firstWrapperId={firstWrapperId}
        isRoot={isRoot}
      />

      <ProductWrapperEditModal
        isOpen={isOpenProductWrapperEditModal}
        setIsOpen={setIsOpenProductWrapperEditModal}
        handleCloseProductWrapperEditModal={handleCloseProductWrapperEditModal}
        isLoadingButton={isLoadingButton}
        handleSubmitProductWrapperEditInfo={handleSubmitProductWrapperEditInfo}
        errors={errors}
        register={register}
        reset={reset}
        selectedProduct={selectedWrapperInfo}
        allYouCanEat={allYouCanEat}
        setAllYouCanEat={setAllYouCanEat}
        setColor={setColor}
        color={color}
        isDirty={isDirty}
        selectedWrapper={selectedWrapper}
      />

      <MenuAddModal
        isOpen={isOpenMenuAddModal}
        setIsOpen={setIsOpenMenuAddModal}
        setMenuName={setMenuName}
        menuName={menuName}
        isMenuNameEmpty={isMenuNameEmpty}
        setIsMenuNameEmpty={setIsMenuNameEmpty}
        handleCloseMenuAddModal={handleCloseMenuAddModal}
        isLoadingButton={isLoadingButton}
        setIsLoadingButton={setIsLoadingButton}
        setSelectedMenuId={setSelectedMenuId}
        setError={setError}
        menuNodeList={menuNodeList}
        setMenuNodeList={setMenuNodeList}
      />

      <MenuEditModal
        isOpen={isOpenMenuEditModal}
        setIsOpen={setIsOpenMenuEditModal}
        setMenuName={setMenuName}
        menuName={menuName}
        isMenuNameEmpty={isMenuNameEmpty}
        setIsMenuNameEmpty={setIsMenuNameEmpty}
        handleCloseMenuEditModal={handleCloseMenuEditModal}
        isLoadingButton={isLoadingButton}
        setType={setType}
        type={type}
        brand={brand}
        setBrand={setBrand}
        typeInitial={typeInitial}
        brandInitial={brandInitial}
        menuNameInitial={menuNameInitial}
        menu={menu}
        setError={setError}
        setIsLoadingButton={setIsLoadingButton}
        menuNodeList={menuNodeList}
        setMenuNodeList={setMenuNodeList}
      />

      {isOpenDepartmentAddModal && (
        <StockDepartmentAddModal
          isOpenDepartmentAddModal={isOpenDepartmentAddModal}
          setIsOpenDepartmentAddModal={setIsOpenDepartmentAddModal}
          setOpen={setOpenDepartmentDropdown}
          open={openDepartmentDropdown}
          stockGroupSelectedNode={stockGroupSelectedNode}
          selectedGroupObj={selectedGroupObj}
          isLoadingToast={false}
          handleGroupChange={handleGroupChange}
          handleOpenGroupAddModal={handleOpenGroupAddModal}
          departmentName={departmentName}
          handleChangeDepartmentName={handleChangeDepartmentName}
          handleCreateNewDepartment={handleCreateNewDepartment}
          isLoadingButton={isLoadingGroupDepartmentButton}
        />
      )}
      {isOpenGroupAddModal && (
        <StockGroupAddModal
          isOpenGroupAddModal={isOpenGroupAddModal}
          setIsOpenGroupAddModal={setIsOpenGroupAddModal}
          isLoadingToast={false}
          groupName={groupName}
          handleChangeGroupName={handleChangeGroupName}
          handleCreateNewGroup={handleCreateNewGroup}
          isLoadingButton={isLoadingGroupDepartmentButton}
        />
      )}

      <EmptyModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        isOpenMappingModal={isOpenMappingModal}
        setError={setError}
        printerMappingItemName={printerMappingItemName}
        selectedPrinterMappingItemOrCategoryId={
          selectedPrinterMappingItemOrCategoryId
        }
        type={type}
        selectedCatMenuTitle={selectedCatMenuTitle}
        categoryName={categoryName}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isOpenStackProductModal={isOpenStackProductModal}
        setIsOpenStackProductModal={setIsOpenStackProductModal}
        stackNode={stackNode}
        handleOpenMappingModal={handleOpenMappingModal}
        setIsOpenMappingModal={setIsOpenMappingModal}
        isStack={isStack}
      />

      <DirectoryOrProductSelectionModal
        isOpen={isOpenDirectoryOrProductSelectionModal}
        setIsOpen={setIsOpenDirectoryOrProductSelectionModal}
        handleOpenDirectoryModal={handleOpenDirectoryModal}
        handleOpenProductModal={handleOpenProductModal}
        handleCloseDirectoryOrProductSelectionModal={
          handleCloseDirectoryOrProductSelectionModal
        }
      />

      <DirectoryAddModal
        isOpen={isOpenDirectoryModal}
        setIsOpen={setIsOpenDirectoryModal}
        setDirectoryName={setDirectoryName}
        directoryName={directoryName}
        isDirectoryNameEmpty={isDirectoryNameEmpty}
        setIsDepartmentEmpty={setIsDepartmentEmpty}
        handleCloseDirectoryAddModal={handleCloseDirectoryAddModal}
        setUrl={setUrl}
        isLoadingButton={isLoadingButton}
        url={url}
        setIsLoadingButton={setIsLoadingButton}
        categoryId={categoryId}
        directoryId={directoryId}
        directoryLevel={directoryLevel}
        setSuccess={setSuccess}
        setError={setError}
        setIsDirectoryNameEmpty={setIsDirectoryNameEmpty}
        menuNodeList={menuNodeList}
        wrapperId={wrapperId}
        menuId={selectedMenuId}
        firstWrapperId={firstWrapperId}
        setMenuNodeList={setMenuNodeList}
        isRoot={isRoot}
        setIsOpenDirectoryOrProductSelectionModal={
          setIsOpenDirectoryOrProductSelectionModal
        }
        setColor={setColor}
        color={color}
      />

      <DirectoryEditModal
        isOpen={isOpenDirectoryEditModal}
        setIsOpen={setIsOpenDirectoryEditModal}
        setDirectoryName={setDirectoryName}
        directoryName={directoryName}
        isDirectoryNameEmpty={isDirectoryNameEmpty}
        setIsDepartmentEmpty={setIsDepartmentEmpty}
        handleCloseDirectoryEditModal={handleCloseDirectoryEditModal}
        setUrl={setUrl}
        isLoadingButton={isLoadingButton}
        url={url}
        setIsLoadingButton={setIsLoadingButton}
        categoryId={categoryId}
        directoryId={directoryId}
        directoryLevel={directoryLevel}
        setSuccess={setSuccess}
        setError={setError}
        setIsDirectoryNameEmpty={setIsDirectoryNameEmpty}
        menuNodeList={menuNodeList}
        wrapperId={wrapperId}
        menuId={selectedMenuId}
        firstWrapperId={firstWrapperId}
        setMenuNodeList={setMenuNodeList}
        isRoot={isRoot}
        setIsOpenDirectoryOrProductSelectionModal={
          setIsOpenDirectoryOrProductSelectionModal
        }
        selectedWrapper={selectedWrapper}
        setColor={setColor}
        color={color}
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
    </>
  );
};

export default withAuthority(MenuInfoPageNew, Authorities.MENU_READ);
