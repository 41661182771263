import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { CustomTheme } from "../../../../../types/customTheme";
import ColorSelection from "../ColorSelection";
import PreviewBox from "../SectionBox/PreviewBox";
import SectionBoxWrapper from "../SectionBox/SectionBoxWrapper";
import PreviewMenu from "./PreviewMenu";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: "none",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      backgroundColor: theme.palette.background.entity_highlight_background,
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface SectionBoxMenuProps {
  themeConfig: any;
  onSubmit: any;
}

/* Update menu information and users can view all menu information using card. */
const SectionBoxMenu: React.FunctionComponent<SectionBoxMenuProps> = ({
  themeConfig,
  onSubmit,
}) => {
  const [backgroundColor, setBackgroundColor] = useState("");
  const [menuTitleTextColor, setMenuTitleTextColor] = useState("");
  const [categoryTitleTextColor, setCategoryTitleTextColor] = useState("");
  const [itemTitleTextColor, setItemTitleTextColor] = useState("");
  const [itemDescriptionTextColor, setItemDescriptionTextColor] = useState("");

  const [type, setType] = useState("simple_item");

  const classes = useStyles();

  /* Update states initial load and after update menu information. */
  const setInitialData = useCallback(() => {
    if (backgroundColor) return;
    const {
      menuBackgroundColor,
      titleTextColor,
      subtitleTextColor,
      itemTextColor,
      itemDescColor,
      itemDisplayType
    } = themeConfig;

    setType(itemDisplayType)
    setBackgroundColor(menuBackgroundColor);
    setMenuTitleTextColor(titleTextColor);
    setCategoryTitleTextColor(subtitleTextColor);
    setItemTitleTextColor(itemTextColor);
    setItemDescriptionTextColor(itemDescColor);
  }, [backgroundColor, themeConfig]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  /* Send an API call to update menu information after click save button. */
  const handleSave = () => {
    onSubmit({
      menuBackgroundColor: backgroundColor,
      titleTextColor: menuTitleTextColor,
      subtitleTextColor: categoryTitleTextColor,
      itemTextColor: itemTitleTextColor,
      itemDescColor: itemDescriptionTextColor,
      itemDisplayType: type,
    });
  };

  return (
    <>
      <SectionBoxWrapper
        sectionTitle={"Menu"}
        onSubmit={handleSave}
        onCancel={setInitialData}
        colorChangeSection={
          <>
            <ColorSelection
              title={"Background Color"}
              colorCode={backgroundColor}
              setColor={(color: any) => setBackgroundColor(color.hex)}
              setInitialData={() =>
                setBackgroundColor(themeConfig.menuBackgroundColor)
              }
            />
            <ColorSelection
              title={"Menu Text Color"}
              colorCode={menuTitleTextColor}
              setColor={(color: any) => setMenuTitleTextColor(color.hex)}
              setInitialData={() =>
                setMenuTitleTextColor(themeConfig.titleTextColor)
              }
            />
            <ColorSelection
              title={"Category Text Color"}
              colorCode={categoryTitleTextColor}
              setColor={(color: any) => setCategoryTitleTextColor(color.hex)}
              setInitialData={() =>
                setCategoryTitleTextColor(themeConfig.subtitleTextColor)
              }
            />
            <ColorSelection
              title={"Item Text Color"}
              colorCode={itemTitleTextColor}
              setColor={(color: any) => setItemTitleTextColor(color.hex)}
              setInitialData={() =>
                setItemTitleTextColor(themeConfig.itemTextColor)
              }
            />
            <ColorSelection
              title={"Description Text Color"}
              colorCode={itemDescriptionTextColor}
              setColor={(color: any) => setItemDescriptionTextColor(color.hex)}
              setInitialData={() =>
                setItemDescriptionTextColor(themeConfig.itemDescColor)
              }
            />
            <FormControl className={classes.formControl} size="small" fullWidth>
              <InputLabel id="menuItemViewType" style={{ margin: 4, border: "none" }}>
                Menu view type
              </InputLabel>
              <Select
                variant="outlined"
                labelId="menuItemViewType"
                id="menuItemViewSelect"
                className={classes.textField}
                value={type}
                onChange={(e: any) => setType(e.target.value)}
              >
                <MenuItem value={"simple_item"}>Simple List</MenuItem>
                <MenuItem value={"simple_grid"}>Simple Grid</MenuItem>
                <MenuItem value={"horiz_grid"}>Horizontal Grid</MenuItem>
              </Select>
            </FormControl>
          </>
        }
        previewSection={
          <PreviewBox bgColor={backgroundColor}>
            <PreviewMenu
              menuTitleTextColor={menuTitleTextColor}
              categoryTitleTextColor={categoryTitleTextColor}
              itemTitleTextColor={itemTitleTextColor}
              itemDescriptionTextColor={itemDescriptionTextColor}
            />
          </PreviewBox>
        }
      />
    </>
  );
};

export default SectionBoxMenu;
