import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import firebase from "firebase";
import _ from "lodash";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import queryString from "query-string";

import { verifyAuthTokenWithServer } from "../../../../services/accountApp/authService";
import { createCustomToken } from "../../../../services/globalService";
import { useHistory, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "400px",
  },
}));

export interface ManageAppsProps {}

/* Get all location information using API call at initial load. */
const MobileAppLogin: React.FunctionComponent<ManageAppsProps> = () => {
  const history = useHistory();
  const match: any = useRouteMatch();

  const handleRedirect = () => {
    const page = queryString.parse(window.location.search).page;
    const newUrl = {
      pathname: `/${match.params.locationId}/presto-menus/${page}`,
    };
    // Update the browser's URL to the new URL constructed above
    const queryParams = 'isAppEmbedded=true'; // Replace with your actual query parameters

    setTimeout(() => {
      if (window.location.href.includes("http://localhost")) {
        window.location.href = `http://localhost:3021/${match.params.locationId}/presto-menus/${page}?${queryParams}`;
      } else {
        window.location.href = `${process.env.REACT_APP_PRESTO_MENU_DOMAIN}/${match.params.locationId}/presto-menus/${page}?${queryParams}`;
      }
    }, 3000);
  };

  const handleLogin = (firebaseUser: any) => {
    firebaseUser.getIdToken(true).then((idToken: any) => {
      // Get the login details
      verifyAuthTokenWithServer(
        idToken,
        firebaseUser,
        firebaseUser.refreshToken,
      );
      handleRedirect();
    });
  };

  const handleAuthentication = async () => {
    try {
      const authFirebase = firebase.auth();

      const token = queryString.parse(window.location.search).t;

      const res = await createCustomToken(token);
      const customToken = res.data.customToken;
      const userCredential = await authFirebase.signInWithCustomToken(
        customToken,
      );
      if (userCredential) {
        handleLogin(userCredential);
      } else {
      }
    } catch {}
  };

  useEffect(() => {
    const onAuthStateChange = async () => {
      const authFirebase = firebase.auth();
      authFirebase.onAuthStateChanged((firebaseUser: any) => {
        if (firebaseUser) {
          handleLogin(firebaseUser);
        } else {
          handleAuthentication();
        }
      });
    };
    onAuthStateChange();
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress color= "secondary" disableShrink />
    </div>
  );
};

export default MobileAppLogin;
