import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import _, { values } from "lodash";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import { DeliveryTpe } from "../../../../utils/enum";
import {
  DELIVERY_TYPE_DINEIN,
  ERROR_MESSAGE_UNEXPECTED_ERROR,
} from "../../../../utils/consts";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import AvailabilitySelection from "../../../../components/time/AvailabilitySelection";
import {
  isEqualArrayObject,
  isEqualArrays,
} from "../../../../utils/checkArrayEqual";
import { useForm } from "react-hook-form";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import { fetchRewardsInfo } from "../../../../services/customerApp/customerDetails";
import { useRouteMatch } from "react-router-dom";
import RewardsDetailsNode from "./RewardsDetailsNode";
import StepLevelOne from "./StepLevelOne";
import StepLevelTwo from "./StepLevelTwo";
import StepLevelThree from "./StepLevelThree";
import PointsModalContent from "./PointsModalContent";

interface FormData {
  title: string;
  description: string;
  img: string;
  units_per_cash: string;
  units_to_redeem?: any;
  isLoadingButton: any;
}

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  handleCreateRewards: any;
  rewardNode: any;
  selectedStrategyNode: any;
  isLoadingPage: any;
  handleSubmitRewardsTypePoints: any;
  setRewardDetails: any;
  setIsPointsReset: any;
  isPointsReset: any;
  handleEditRewards: any;
  rewardDetails: any;
  setError: any;
  isLoadingButton: any;
  isOwner: any;
}

/**
 * RewardsAddModal Component
 *
 * This component is responsible for rendering a modal dialog for adding, editing, or duplicating a strategy.
 * It includes fields for strategy name, delivery types (Dine In, Delivery, Takeout), and availability.
 * The user can select delivery types using switches and set availability using an availability selection component.
 * Additionally, the component provides options for canceling, saving, or creating the strategy.
 */
const RewardsAddModal: React.FunctionComponent<StrategyAddEditModalProps> = ({
  isOpen,
  setIsOpen,
  handleCreateRewards,
  rewardNode,
  selectedStrategyNode,
  isLoadingPage,
  handleSubmitRewardsTypePoints,
  setRewardDetails,
  setIsPointsReset,
  isPointsReset,
  handleEditRewards,
  rewardDetails,
  setError,
  isLoadingButton,
  isOwner,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [level, setLevel] = useState(0);
  const [isSelectCash, setIsSelectCash] = useState(false);
  const [selectedWrapperIdList, setSelectedWrapperIdList] = useState<any>([]);
  const [selectedWrapperIdListInitial, setSelectedWrapperIdListInitial] =
    useState<any>([]);
  const [rewardId, setRewardId] = useState("");
  const [isOpenLoadingModal, setIsOpenLoadingModal] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      title: "",
      description: "",
      img: "",
      units_per_cash: "",
      units_to_redeem: "",
    },
  });
  const match: any = useRouteMatch();

  const handleGetRewardsDetails = async () => {
    try {
      const res = await fetchRewardsInfo(
        match.params.locationId,
        selectedStrategyNode.strategyId,
      );
      if (res.data.data) {
        const rewardDetails: any = Object.values(res.data.data);

        if (rewardDetails[0]?.type === "product") {
          setLevel(2);
          setRewardDetails(res.data.data);
          setIsSelectCash(false);
        } else {
          setLevel(3);
          setRewardDetails(rewardDetails[0]);
          setIsSelectCash(true);
          setIsPointsReset(rewardDetails[0]?.is_points_reset);
          reset({
            title: rewardDetails[0]?.title,
            description: rewardDetails[0]?.description,
            img: rewardDetails[0]?.img,
            units_to_redeem: rewardDetails[0]?.units_to_redeem,
            units_per_cash: rewardDetails[0]?.units_per_cash,
          });
        }
        setRewardId(Object.keys(res.data.data)[0]);
        setIsEdit(true);
        setIsOpenLoadingModal(false);
      } else {
        setIsEdit(false);
        setLevel(1);
        setIsOpenLoadingModal(false);
        setRewardDetails({});
      }
    } catch (e) {
      setIsOpenLoadingModal(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleEditRewardDetails = (rewardId: any, rewardDetails: any) => {
    setIsEdit(true);
    setIsPointsReset(rewardDetails?.is_points_reset);
    setSelectedWrapperIdList(
      rewardDetails?.products ? rewardDetails?.products : {},
    );
    setRewardId(rewardId);
    reset({
      title: rewardDetails?.title,
      description: rewardDetails?.description,
      img: rewardDetails?.img,
      units_to_redeem: rewardDetails?.units_to_redeem,
    });
    setLevel(3);
    setSelectedWrapperIdListInitial(
      rewardDetails?.products ? rewardDetails?.products : {},
    );
  };

  useEffect(() => {
    // handleGetRewardsDetails()
    reset();
    setIsActive(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsOpenLoadingModal(true);

      if (_.isEmpty(rewardNode)) {
        setIsEdit(false);
        setLevel(1);
        setIsOpenLoadingModal(false);
        setRewardDetails({});
      } else {
        handleGetRewardsDetails();
      }
    }
  }, [rewardNode]);

  const handleSubmitData = handleSubmit(async (data) => {
    if (level === 1) {
      setLevel(3);
    } else {
      const formData: any = {};
      formData.title = data.title;
      formData.description = data.description;
      formData.img = data.img;
      formData.product_id = selectedStrategyNode.programId;
      formData.strategy_id = selectedStrategyNode.strategyId;
      if (isSelectCash) {
        formData.units_per_cash = data.units_per_cash;
        formData.type = "cash";
        if (isEdit) {
          handleEditRewards(formData, rewardId);
        } else {
          handleCreateRewards(formData);
        }
      } else {
        formData.units_to_redeem = data.units_to_redeem;
        setRewardDetails(formData);
        setLevel(4);
      }
    }
  });

  const handleSelectProductOrCash = () => {
    setIsSelectCash(!isSelectCash);
    // setLevel(level + 1);
  };

  const handleSelectProduct = (
    event: Event,
    productWrapper: { id: string },
  ) => {
    // Create a shallow copy of the selectedWrapperIdList object
    const cloneSelectedWrapperIdList = { ...selectedWrapperIdList };

    // Check if the productWrapper.id exists in the cloned object
    if (cloneSelectedWrapperIdList[productWrapper.id]) {
      // If it exists, delete the property from the cloned object
      delete cloneSelectedWrapperIdList[productWrapper.id];
    } else {
      // If it does not exist, add it with default values
      cloneSelectedWrapperIdList[productWrapper.id] = {
        surcharge: 0,
        include_modifiers: false,
      };
    }

    // Update the state with the modified object
    setSelectedWrapperIdList(cloneSelectedWrapperIdList);
  };

  const handleDisable = () => {};
  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={level === 1 || level === 3 ? false : true}
        maxWidth={level === 1 || level === 3 ? "sm" : "xl"}
      >
        {level === 1 && (
          <StepLevelOne
            isSelectCash={isSelectCash}
            handleSelectProductOrCash={handleSelectProductOrCash}
            setIsOpen={setIsOpen}
            handleDisable={handleDisable}
            level={level}
            handleSubmitData={handleSubmitData}
            isOwner={isOwner}
          />
        )}
        {level === 2 && (
          <StepLevelTwo
            setIsEdit={setIsEdit}
            setLevel={setLevel}
            setIsOpen={setIsOpen}
            rewardDetails={rewardDetails}
            handleEditRewardDetails={handleEditRewardDetails}
          />
        )}

        {level === 3 && (
          <StepLevelThree
            isSelectCash={isSelectCash}
            register={register}
            setIsOpen={setIsOpen}
            isPointsReset={isPointsReset}
            setIsPointsReset={setIsPointsReset}
            errors={errors}
            isLoadingPage={isLoadingPage}
            handleSubmitData={handleSubmitData}
            isEdit={isEdit}
            isOwner={isOwner}
            selectedStrategyNode={selectedStrategyNode}
          />
        )}

        {level === 0 && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ marginTop: "14%" }}>
              <CircularProgress color="secondary" disableShrink />
            </div>
          </div>
        )}
      </DialogCommonDefault>

      {level === 4 && (
        <PointsModalContent
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleSelectProduct={handleSelectProduct}
          selectedWrapperIdList={selectedWrapperIdList}
          setSelectedWrapperIdList={setSelectedWrapperIdList}
          handleSubmitRewardsTypePoints={handleSubmitRewardsTypePoints}
          isEdit={isEdit}
          rewardId={rewardId}
          selectedWrapperIdListInitial={selectedWrapperIdListInitial}
          isLoadingButton={isLoadingButton}
          isOwner={isOwner}
        />
      )}
    </>
  );
};

export default RewardsAddModal;
