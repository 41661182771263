import React, { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import ValidationMessage from "../../../../../components/validation/ValidationMessage";
import { CustomTheme } from "../../../../../types/customTheme";
import SwitchCommon from "../../../../../components/switches/SwitchCommon";
import ColorSelection from "../../../../PrestoExpressEatprestoApp/pages/ThemeCustomizationPage/ColorSelection";
import { inOutTax } from "../../../../../utils/consts/list";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    labelStyle: {
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
  }),
);

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  handleCloseProductWrapperEditModal: any;
  isLoadingButton: any;
  handleSubmitProductWrapperEditInfo: any;
  errors: any;
  register: any;
  reset: any;
  selectedProduct: any;
  setAllYouCanEat: any;
  setColor: any;
  color: any;
  isDirty: any;
  selectedWrapper: any;
  allYouCanEat: any;
}

const ProductEditModal: React.FunctionComponent<StrategyAddEditModalProps> = ({
  isOpen,
  setIsOpen,
  handleCloseProductWrapperEditModal,
  isLoadingButton,
  handleSubmitProductWrapperEditInfo,
  errors,
  register,
  reset,
  selectedProduct,
  allYouCanEat,
  setAllYouCanEat,
  setColor,
  color,
  isDirty,
  selectedWrapper,
}) => {
  const handleDisable = () => {
    const { allYouCanEat: allYouCanEatInitial, colour: colourInitial } =
      selectedProduct;
    if (
      !isDirty &&
      allYouCanEatInitial === allYouCanEat &&
      colourInitial === color
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    // Set default values from selectedCategory when it changes
    if (Object.values(selectedProduct)?.length > 0 && isOpen) {
      const {
        allYouCanEat,
        colour,
        cover,
        itemType,
        nameShort,
        name,
        valid,
        version,
        desc,
        img,
        size,
      } = selectedProduct;

      setColor(colour);
      setAllYouCanEat(allYouCanEat);
      reset({
        name,
        valid,
        version,
        desc,
        size,
        cover,
        itemType,
        nameShort,
        img,
      });
    }
  }, [selectedProduct, isOpen]);

  const handleAllYouCanEat = (e: any) => {
    const { checked } = e.target;
    setAllYouCanEat(checked);
  };

  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>Edit Product of {selectedProduct?.name}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>Name</Typography>
              <TextfieldDefaultNew
                id="name"
                name="name"
                type="text"
                inputRef={register({
                  required: "Name is required",
                })}
              />
              {errors.name ? (
                <ValidationMessage message={errors.name.message} />
              ) : (
                ""
              )}
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginTop: "4px",
              }}
            >
              <FormControlLabel
                style={{ marginLeft: "0px", marginRight: "0px" }}
                control={<SwitchCommon />}
                checked={allYouCanEat}
                onChange={handleAllYouCanEat}
                label={
                  <Typography variant="caption" color="textSecondary">
                    All You Can Eat
                  </Typography>
                }
                labelPlacement="bottom"
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <Typography style={{ paddingLeft: "4px" }}>
                Description
              </Typography>
              <TextfieldDefaultNew
                id="desc"
                name="desc"
                type="text"
                inputRef={register({
                  required: false,
                })}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <Typography style={{ paddingLeft: "4px" }}>Size</Typography>
              <TextfieldDefaultNew
                id="size"
                name="size"
                type="text"
                inputRef={register({
                  required: false,
                })}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <Typography style={{ paddingLeft: "4px" }}>
                Slave Print Type
              </Typography>
              <TextfieldDefaultNew
                id="slavePrintType"
                name="slavePrintType"
                type="text"
                inputRef={register({
                  required: false,
                })}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <Typography style={{ paddingLeft: "4px" }}>Cover</Typography>
              <TextfieldDefaultNew
                id="cover"
                name="cover"
                type="text"
                inputRef={register({
                  required: false,
                })}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <Typography style={{ paddingLeft: "4px" }}>Item Type</Typography>
              <TextfieldDefaultNew
                id="itemType"
                name="itemType"
                type="text"
                inputRef={register({
                  required: false,
                })}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <Typography style={{ paddingLeft: "4px" }}>Name Short</Typography>
              <TextfieldDefaultNew
                id="nameShort"
                name="nameShort"
                type="text"
                inputRef={register({
                  required: false,
                })}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <Typography style={{ paddingLeft: "4px" }}>Img</Typography>
              <TextfieldDefaultNew
                id="img"
                name="img"
                type="text"
                inputRef={register({
                  required: false,
                })}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <Typography style={{ paddingLeft: "4px" }}>Color</Typography>
              <ColorSelection
                title={"Color"}
                colorCode={color}
                setColor={(color: any) => setColor(color.hex)}
                setInitialData={() => setColor(selectedWrapper.color)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleCloseProductWrapperEditModal}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={handleDisable()}
            isLoadingPage={isLoadingButton}
            color={"orange"}
            onClick={handleSubmitProductWrapperEditInfo}
          >
            {"Save"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default ProductEditModal;
