import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { getColorByStatus } from "../../utils/Color";

interface styleProps {
  status: any;
}

const useStyles = makeStyles<any, styleProps>((theme: Theme) =>
  createStyles({
    statusBox: {
      height: "100%",
      width: "5px",
      borderRadius: "30px",
      backgroundColor: ({ status }) => getColorByStatus(status),
      [theme.breakpoints.down("md")]: {
        visibility: "hidden",
      },
    },
  }),
);

export interface DefaultStatusProps {
  status?: any;
}

/* Show the order status box with the status color. */
const DefaultStatus: React.FunctionComponent<DefaultStatusProps> = ({
  status,
}) => {
  const classes = useStyles({ status });
  return <Box className={classes.statusBox}></Box>;
};

export default DefaultStatus;
