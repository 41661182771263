import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import styled from "styled-components";

import { CustomTheme } from "../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) => ({
  rootLoading: {
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: "unset",
      },
    },
    "& .MuiPaper-root": {
      borderRadius: 10,
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.entity_background,
    },
  },
  root: {
    overflowX: "hidden",
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: "unset",
      },
    },
    "& .MuiPaper-root": {
      borderRadius: 10,
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.entity_background,
    },
  },
  paperFullWidth: {
    "&.MuiDialog-paperFullWidth": {
      borderRadius: 10,
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.entity_background,
    },
  },
}));

const MenuItemDisplayModal = styled(Dialog)`
  .MuiDialog-container {
    @media (max-width: 600px) {
    }
  }
  .MuiDialog-paperWidthSm {
    @media (max-width: 600px) {
    }
  }
  .MuiDialog-paper {
    /* max-height: 568px;   */
    overflow: hidden;
  }
  .MuiDialog-scrollBody:after {
    /* display: block */
  }
  .MuiDialog-scrollBody:after {
    /* display: block */
  }
`;

export interface DialogCommonDefaultProps {
  open: boolean;
  setOpen: any;
  children: any;
  isLoading?: any;
}

const DialogOverflowContainer: React.FunctionComponent<
  DialogCommonDefaultProps
> = ({ open, setOpen, children, isLoading }) => {
  const classes = useStyles();
  const theme: CustomTheme = useTheme();

  const dialogClasses = [classes.root];

  if (!isLoading) {
    // Add a class when not loading
    dialogClasses.push("not-loading");
  }

  return (
    <MenuItemDisplayModal
      disableEscapeKeyDown
      disableBackdropClick
      fullWidth
      keepMounted={false}
      maxWidth="lg"
      id="menuItemViewModal"
      onClose={() => setOpen(false)}
      open={open}
      scroll="body"
      classes={{
        root: classes.root,
        paperFullWidth: classes.paperFullWidth,
      }}
      PaperProps={{
        style: {
          borderRadius: 10,
          border: `1px solid ${theme.palette.background.entity_border}`,
          backgroundColor: theme.palette.background.entity_background,
        },
      }}
    >
      {children}
    </MenuItemDisplayModal>
  );
};

export default DialogOverflowContainer;
