import React from "react";
import { Route, Switch } from "react-router-dom";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import Task from "../pages/Task";
import Home from "../pages/HomePage";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Location from "../../PrestoExpressLocationApp/pages/Location";
import DashboardIcon from "@material-ui/icons/Dashboard";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "Dashboard",
      title: "Dashboard",
      path: "",
      icon: <DashboardIcon />,
      isAuthorized: getIsAuthorized(Authorities.TASKS_READ),
    },
    {
      id: "Task",
      title: "Task",
      path: "/task",
      icon: <ListAltIcon />,
      isAuthorized: getIsAuthorized(Authorities.TASKS_READ),
    },
  ];
  return sidebarRoute;
};
export interface TasksAppRoutesProps {}

const TasksAppRoutes: React.FunctionComponent<TasksAppRoutesProps> = () => {
  return (
    <Switch>
      <Route path="/:locationId/presto-task/task" component={Task} exact />
      <Route path="/:locationId/presto-task" component={Home} exact />
      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default TasksAppRoutes;
