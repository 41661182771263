export const getLocalStore = (key) => {
  try {
    const data = localStorage.getItem(key);
    if (typeof data === "string") {
      return data;
    }
    return JSON.parse(data);
  } catch (error) {
    console.error(`error`, error);
    return null;
  }
};

export const setLocalStore = (key, value) => {
  localStorage.setItem(key, value);
};
