import moment from "moment";

/* The dates between the start date and the end date are given in the form of a list.
    ex:- input = startDate = 2022/11/10 and endDate = 2022/11/14
         output [2022/11/10, 2022/11/11, 2022/11/12, 2022/11/13, 2022/11/14] */
export const enumerateDaysBetweenDates = (startDate: any, endDate: any) => {
  const dates = [];

  const currDate = moment(startDate).startOf("day");
  const lastDate = moment(endDate).startOf("day").add(1, "days");

  while (currDate.diff(lastDate) < 0) {
    dates.push(currDate.clone().format("YYYY-MM-DD"));
    currDate.add(1, "days");
  }

  return dates;
};
